import Layout from "../../Containers/Layout";
import styles from "./Home.module.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import image1 from "../../Media/UPB-Connect.png";
import image2 from "../../Media/home.svg";
import { theme } from "../../theme";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Col, Row } from "antd";
import { isUserLogged } from "../../utils/utilFunctions";
import {
	getStatisticsForLandingPage,
	getRoles,
} from "../../utils/reactQueriesConstants";
import { getLandingPageStatistics } from "../../Requests/statistics-requests";

const Home = () => {
	const { t } = useTranslation();
	const [boolInfo, setBoolInfo] = useState(false);
	const search = useLocation().search;
	const queryClient = useQueryClient();

	const openExpire = (message: string, description: string) =>
		setTimeout(() => {
			openNotification(t(message), t(description), NOTIFICATION_TYPES.ERROR);
		}, 1000);

	const { data: statistics } = useQuery(
		[getStatisticsForLandingPage],
		() => getLandingPageStatistics(),
		{
			refetchOnWindowFocus: false,
			onError: () =>
				openNotification(
					t("reports.errorTexts.loadingFailed"),
					t("reports.errorTexts.loadingFailedMessage"),
					NOTIFICATION_TYPES.ERROR
				),
		}
	);

	useEffect(() => {
		if (!boolInfo) {
			const role = new URLSearchParams(search).get("role") as any;
			const token = new URLSearchParams(search).get("token") as any;
			const refreshToken = new URLSearchParams(search).get(
				"refreshToken"
			) as any;
			const expired = new URLSearchParams(search).get("expired") as any;

			if (expired) {
				queryClient.invalidateQueries(getRoles);
				openExpire(
					"home.messages.sessionExpired",
					"home.messages.sessionExpiredMessage"
				);
			}

			//TO DO: complete fetch info for profile

			if (token && role) {
				localStorage.setItem("token", token);
				localStorage.setItem("refreshToken", refreshToken);
			} else {
				const error = new URLSearchParams(search).get("error");
				if (error) {
					openExpire("home.messages.loginFailure", "home.messages.noAccount");
				}
			}
			setBoolInfo(true);
		}
	}, [search, boolInfo, t]);

	useEffect(() => {
		const nextUrl = localStorage.getItem("nextUrl");
		if (isUserLogged() && nextUrl !== null) {
			localStorage.removeItem("nextUrl");
			window.location.replace(nextUrl!);
		}
	}, [isUserLogged]);

	return (
		<Layout>
			<div className={styles.wrapper}>
				<div
					className={styles.flexBox}
					style={{ display: "flex", margin: "0 auto" }}
				>
					<div className={styles.container}>
						<h1>{t("home.welcomeText")}</h1>
						<div className={styles.fadeinimage}>
							<img alt={"img1"} src={image1} width={"1.8rem"} />
						</div>
						{!isUserLogged() && <h2>{t("home.loginRequired")}</h2>}
					</div>
					<div className={styles.imageContainer}>
						<Row
							style={{
								marginBottom: "2em",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<img
								className={styles.svg1}
								alt={"img2"}
								src={image2}
								style={{ width: "90%", marginTop: "2em" }}
							/>
						</Row>
						<Row
							className={styles.countersWrapper}
							justify="space-evenly"
							gutter={[16, 10]}
						>
							<Col className={styles.countContainer}>
								<FontAwesomeIcon
									icon={solid("graduation-cap")}
									style={{ color: theme.secondColor, height: "1.5em" }}
								/>
								<CountUp
									end={statistics?.studentsCount ?? 0}
									duration={2}
									style={{ fontWeight: "bold", fontSize: "large" }}
								/>
								<span style={{ fontSize: "16px" }}>
									{t("home.enrolledStudents")}
								</span>
							</Col>
							<Col className={styles.countContainer}>
								<FontAwesomeIcon
									icon={solid("building")}
									style={{ color: theme.secondColor, height: "1.5em" }}
								/>
								<CountUp
									end={statistics?.partnerCompaniesCount ?? 0}
									duration={2}
									style={{ fontWeight: "bold", fontSize: "large" }}
								/>
								<span style={{ fontSize: "16px" }}>
									{t("home.partnerCompanies")}
								</span>
							</Col>
							<Col className={styles.countContainer}>
								<FontAwesomeIcon
									icon={solid("file")}
									style={{ color: theme.secondColor, height: "1.5em" }}
								/>
								<CountUp
									end={statistics?.proposalsCount ?? 0}
									duration={2}
									style={{ fontWeight: "bold", fontSize: "large" }}
								/>
								<span style={{ fontSize: "16px" }}>{t("home.offers")}</span>
							</Col>
							<Col className={styles.countContainer}>
								<FontAwesomeIcon
									icon={solid("chalkboard-user")}
									style={{ color: theme.secondColor, height: "1.5em" }}
								/>
								<CountUp
									end={statistics?.professorsCount ?? 0}
									duration={2}
									style={{ fontWeight: "bold", fontSize: "large" }}
								/>
								<span style={{ fontSize: "16px" }}>{t("home.professors")}</span>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</Layout>
	);
};
export default Home;
