import Layout from "../../Containers/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import Search from "antd/lib/input/Search";
import styles from "./Students.module.scss";
import { useEffect, useState } from "react";
import { studentIdSet } from "../Student/StudentSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Drawer, Row, Table, TablePaginationConfig } from "antd";
import { theme } from "../../theme";
import CustomFilter from "../../CustomComponents/CustomFilter";
import { getAllKeys, getKey } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getAllStudentsWhoAcceptGDPR,
	getStudentFiltersForNewCompany,
} from "../../Requests/academic-user-requests";
import { Filter } from "../../Api";
import { Key } from "antd/lib/table/interface";

const CompanyStudentsTable = () => {
	const { t, i18n } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [data, setData] = useState({
		data: [],
		totalCount: 100,
		pageSize: 10,
		page: 1,
	});
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [faculties, setFaculties] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [studyPrograms, setStudyPrograms] = useState([]);
	const [years, setYears] = useState([]);
	const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
	const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
	const [filters, setFilters] = useState(new Array<Filter>());
	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const FilterByNameInput = (
		<Search
			placeholder={t("students.name")}
			allowClear
			onSearch={(value) => {
				setSearchTerm(value);
				setCurrent(1);
			}}
			className={styles.search}
		/>
	);

	const openNotificationFetchError = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
		setLoading(() => false);
	};

	const onClose = () => {
		setVisible(false);
	};

	const showDrawer = () => {
		setVisible(true);
	};

	const getStudents = (
		page: number,
		size: number,
		search: string,
		years: string[],
		faculties: string[],
		departments: string[],
		studyPrograms: string[]
	) => {
		setLoading(() => true);

		getAllStudentsWhoAcceptGDPR(
			search,
			page,
			size,
			years,
			faculties,
			departments,
			studyPrograms
		)
			.then((response: any) => {
				setData(() => {
					return response;
				});
				setLoading(() => false);
			})
			.catch((ex: any) => {
				openNotificationFetchError(ex);
			});

		return data;
	};

	const handleTableChange = (pagination: TablePaginationConfig) => {
		setCurrent(() => pagination.current ?? 1);
		setPageSize(() => pagination.pageSize ?? 5);
	};

	useEffect(() => {
		setData(
			getStudents(
				currentPage,
				pageSize,
				searchTerm,
				years,
				faculties,
				departments,
				studyPrograms
			)
		);
		setFilters(getFilters());
	}, [
		currentPage,
		pageSize,
		searchTerm,
		faculties,
		departments,
		years,
		i18n.language,
		studyPrograms,
	]);

	const getFilters = () => {
		getStudentFiltersForNewCompany(i18n.language)
			.then((response: Filter[]) => {
				setFilters(() => {
					return response;
				});
				setExpandedKeys(() => {
					return getAllKeys(response);
				});
			})
			.catch((_error) => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("usersText.errorTexts.filterDataError"),
					NOTIFICATION_TYPES.ERROR
				);
			});

		return filters;
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrent(1);
		setFaculties(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Faculty@"))
				.map((e: string) => e.split("@")[1])
		);

		setYears(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Year@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters.map((x) => {
			if (x.key === "Faculty") {
				return {
					key: x.key,
					title: t("students.filters." + x.title),
					children: x.children,
				};
			} else {
				return {
					key: x.key,
					title: t("students.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("students.filters." + y.title),
						}))
					),
				};
			}
		});
	};

	const columns = [
		{
			title: FilterByNameInput,
			dataIndex: "name",
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("students.faculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
		},
		{
			title: t("students.year"),
			dataIndex: "year",
			key: "year",
		},
		// {
		//     title: t('students.cvUrl'),
		//     dataIndex: 'cvUrl',
		//     key: 'cvUrl',
		//     hidden: window.innerWidth < 650,
		//     render: (text: string | undefined) => <a href={text} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={solid('download')} title={t('students.downloadCv')}/></a>
		// },
		// {
		//     title: <FontAwesomeIcon icon={brands('linkedin')} title={t('students.linkedinUrl')}/>,
		//     dataIndex: 'linkedinUrl',
		//     key: 'linkedinUrl',
		//     hidden: window.innerWidth < 650,
		//     render: (text: string | undefined) => <a href={text} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={solid('arrow-right')} title={t('students.goToLinkedinUrl')}/></a>
		// },
	];

	return (
		<Layout>
			<Row className={styles.filterButton}>
				<Button
					onClick={showDrawer}
					style={{ outline: "none", border: "none", boxShadow: "none" }}
				>
					<FontAwesomeIcon
						icon={solid("filter")}
						style={{ color: theme.secondColor }}
					/>
					&nbsp;{t("internships.filters.filter")}
				</Button>
			</Row>
			<Row>
				<Col span={4} className={styles.filters}>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Col>
				<Col xxl={20} xl={24} lg={24} md={23} style={{ padding: "0 0 0 1%" }}>
					<Table
						dataSource={data?.data || []}
						columns={columns}
						pagination={{
							total: data.totalCount,
							current: currentPage,
							pageSize: data.pageSize,
							pageSizeOptions: ["10", "20", "50"],
							defaultPageSize: 10,
							hideOnSinglePage: data.totalCount ? data.totalCount <= 10 : true,
							showSizeChanger: true,
							locale: { items_per_page: t("configuration.pagination") },
							position: ["topRight", "bottomRight"],
						}}
						rowKey={getKey}
						loading={loading}
						onChange={handleTableChange}
					/>
					<Drawer
						className={styles.opened}
						placement={"left"}
						closable={true}
						onClose={onClose}
						visible={visible}
						key={"left"}
						maskClosable={true}
						title={t("internships.filters.filters")}
					>
						<CustomFilter
							filters={filters}
							onCheck={onCheck}
							checkedKeys={checkedKeys}
							expandedKeys={expandedKeys}
							onExpand={onExpand}
							translateFilters={translateFilters}
						/>
					</Drawer>
				</Col>
			</Row>
		</Layout>
	);
};

export default CompanyStudentsTable;
