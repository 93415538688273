/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Answer,
    AnswerFromJSON,
    AnswerFromJSONTyped,
    AnswerToJSON,
} from './Answer';
import {
    FormTypeEnum,
    FormTypeEnumFromJSON,
    FormTypeEnumFromJSONTyped,
    FormTypeEnumToJSON,
} from './FormTypeEnum';
import {
    QuestionPrerequisite,
    QuestionPrerequisiteFromJSON,
    QuestionPrerequisiteFromJSONTyped,
    QuestionPrerequisiteToJSON,
} from './QuestionPrerequisite';
import {
    QuestionTypeEnum,
    QuestionTypeEnumFromJSON,
    QuestionTypeEnumFromJSONTyped,
    QuestionTypeEnumToJSON,
} from './QuestionTypeEnum';

/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Question
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Question
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    orderNumber?: number;
    /**
     * 
     * @type {QuestionTypeEnum}
     * @memberof Question
     */
    questionType?: QuestionTypeEnum;
    /**
     * 
     * @type {FormTypeEnum}
     * @memberof Question
     */
    formType?: FormTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Question
     */
    answers?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    initialValue?: string | null;
    /**
     * 
     * @type {Array<QuestionPrerequisite>}
     * @memberof Question
     */
    prerequisites?: Array<QuestionPrerequisite> | null;
    /**
     * 
     * @type {Array<Answer>}
     * @memberof Question
     */
    recivedAnswers?: Array<Answer> | null;
}

export function QuestionFromJSON(json: any): Question {
    return QuestionFromJSONTyped(json, false);
}

export function QuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Question {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'questionType': !exists(json, 'questionType') ? undefined : QuestionTypeEnumFromJSON(json['questionType']),
        'formType': !exists(json, 'formType') ? undefined : FormTypeEnumFromJSON(json['formType']),
        'answers': !exists(json, 'answers') ? undefined : json['answers'],
        'initialValue': !exists(json, 'initialValue') ? undefined : json['initialValue'],
        'prerequisites': !exists(json, 'prerequisites') ? undefined : (json['prerequisites'] === null ? null : (json['prerequisites'] as Array<any>).map(QuestionPrerequisiteFromJSON)),
        'recivedAnswers': !exists(json, 'recivedAnswers') ? undefined : (json['recivedAnswers'] === null ? null : (json['recivedAnswers'] as Array<any>).map(AnswerFromJSON)),
    };
}

export function QuestionToJSON(value?: Question | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'text': value.text,
        'orderNumber': value.orderNumber,
        'questionType': QuestionTypeEnumToJSON(value.questionType),
        'formType': FormTypeEnumToJSON(value.formType),
        'answers': value.answers,
        'initialValue': value.initialValue,
        'prerequisites': value.prerequisites === undefined ? undefined : (value.prerequisites === null ? null : (value.prerequisites as Array<any>).map(QuestionPrerequisiteToJSON)),
        'recivedAnswers': value.recivedAnswers === undefined ? undefined : (value.recivedAnswers === null ? null : (value.recivedAnswers as Array<any>).map(AnswerToJSON)),
    };
}

