import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	ExtendedViewStudentDTO,
	InternshipEnrollmentDTO,
	SkillEndorsement,
} from "../../Api";

interface StudentState {
	loading: boolean;
	studentId?: string;
	internshipEnrollmentId?: string;
	internshipDetails?: InternshipEnrollmentDTO;
	student?: ExtendedViewStudentDTO;
	message?: string;
}

const name = "student";

export const studentSlice = createSlice({
	name,
	initialState: {
		loading: false,
	},
	reducers: {
		studentIdSet: (state: StudentState, action: PayloadAction<string>) => {
			return {
				...state,
				studentId: action.payload,
				loading: false,
				student: null,
				message: null,
			};
		},
		studentInternshipEnrollmentIdSet: (
			state: StudentState,
			action: PayloadAction<string>
		) => {
			return {
				...state,
				internshipEnrollmentId: action.payload,
				loading: false,
				message: null,
			};
		},
		studentInternshipDetailsSet: (
			state: StudentState,
			action: PayloadAction<string>
		) => {
			return {
				...state,
				internshipDetails: action.payload,
				loading: false,
				student: null,
				message: null,
			};
		},
		studentLoad: (state: StudentState) => {
			return {
				...state,
				loading: true,
				message: null,
			};
		},
		studentGetSucceeded: (
			state: StudentState,
			action: PayloadAction<ExtendedViewStudentDTO>
		) => {
			return {
				...state,
				loading: false,
				student: action.payload,
				message: null,
			};
		},
		studentAddSkillEndorsement: (
			state: StudentState,
			action: PayloadAction<SkillEndorsement>
		) => {
			if (state.student) {
				state.student.userSkills
					?.filter((x) => x.id === action.payload.userSkillId)
					?.forEach((x) => {
						x.skillEndorsements?.push(action.payload);
					});
			}

			return state;
		},
		studentDeleteSkillEndorsement: (
			state: StudentState,
			action: PayloadAction<SkillEndorsement>
		) => {
			if (state.student) {
				state.student.userSkills
					?.filter((x) => x.id === action.payload.userSkillId)
					?.forEach((x) => {
						x.skillEndorsements = x.skillEndorsements?.filter(
							(y) => y.id !== action.payload.id
						);
					});
			}

			return state;
		},
	},
});

export const {
	studentIdSet,
	studentInternshipEnrollmentIdSet,
	studentInternshipDetailsSet,
	studentLoad,
	studentGetSucceeded,
	studentAddSkillEndorsement,
	studentDeleteSkillEndorsement,
} = studentSlice.actions;

export const selectLoading = (state: { student: StudentState }) =>
	state.student.loading;
export const selectStudent = (state: { student: StudentState }) =>
	state.student.student;
export const selectStudentId = (state: { student: StudentState }) =>
	state.student.studentId;
export const selectMessage = (state: { student: StudentState }) =>
	state.student.studentId;
export const selectStudentInternshipEnrollmentId = (state: {
	student: StudentState;
}) => state.student.internshipEnrollmentId;
export const selectStudentInternshipDetails = (state: {
	student: StudentState;
}) => state.student.internshipDetails;

export default studentSlice.reducer;
