/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternshipEngagementType,
    InternshipEngagementTypeFromJSON,
    InternshipEngagementTypeFromJSONTyped,
    InternshipEngagementTypeToJSON,
} from './InternshipEngagementType';

/**
 * 
 * @export
 * @interface InternshipLimitsDTO
 */
export interface InternshipLimitsDTO {
    /**
     * 
     * @type {string}
     * @memberof InternshipLimitsDTO
     */
    id?: string;
    /**
     * 
     * @type {InternshipEngagementType}
     * @memberof InternshipLimitsDTO
     */
    internshipType?: InternshipEngagementType;
    /**
     * 
     * @type {number}
     * @memberof InternshipLimitsDTO
     */
    maximumApplicants?: number;
}

export function InternshipLimitsDTOFromJSON(json: any): InternshipLimitsDTO {
    return InternshipLimitsDTOFromJSONTyped(json, false);
}

export function InternshipLimitsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipLimitsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'internshipType': !exists(json, 'internshipType') ? undefined : InternshipEngagementTypeFromJSON(json['internshipType']),
        'maximumApplicants': !exists(json, 'maximumApplicants') ? undefined : json['maximumApplicants'],
    };
}

export function InternshipLimitsDTOToJSON(value?: InternshipLimitsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'internshipType': InternshipEngagementTypeToJSON(value.internshipType),
        'maximumApplicants': value.maximumApplicants,
    };
}

