import { Table, TablePaginationConfig, Row, Progress } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import CustomButton from "../../CustomComponents/CustomButton";
import { removeDiacritics } from "../../utils/dataUtils";
import { downloadExcel, PageCallBack } from "../../utils/downloadUtils";
import { ColumnType } from "../../utils/downloadReportUtils.types";

const openErrorNotification = (error: string, message: string) => {
	openNotification(error, message, NOTIFICATION_TYPES.ERROR);
};

async function downloadAll<T>(
	getCall: PageCallBack<T>,
	setProgress: (progress: number) => void,
	onError: () => void
) {
	let data = new Array<T>();
	let hasData = true;
	let pageIndex = 1;
	setProgress(0);

	do {
		const page = await getCall(pageIndex, 100).catch(onError);

		++pageIndex;

		if (page?.data === undefined || page?.data === null) {
			onError();

			return;
		}

		data = data.concat(page.data);

		if ((pageIndex - 1) * 100 >= (page.totalCount ?? 0)) {
			hasData = false;
		}

		setProgress(
			hasData
				? Math.round((((pageIndex - 1) * 100) / (page.totalCount ?? 1)) * 100)
				: 100
		);
	} while (hasData);

	return data;
}

const getId = (e: { id?: string }) => e.id || "";

function ReportTable<T extends { id?: string }>(props: {
	queryKey: string;
	getCall: PageCallBack<T>;
	columns: ColumnType<T>[];
	reportColumns: ColumnType<T>[];
	watchChanges?: any[];
	fileName: string;
}) {
	const { t } = useTranslation();
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const handleTableChange = useCallback(
		(pagination: TablePaginationConfig) => {
			setCurrentPage(pagination.current ?? 1);
			setPageSize(pagination.pageSize ?? 5);
		},
		[setCurrentPage, setPageSize]
	);
	const [progress, setProgress] = useState(0);
	const [downloading, setDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState(false);

	let locale = {
		emptyText: t("tableText.noReports"),
	};

	const downloadReport = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			props.fileName,
			props.reportColumns.map((e) => {
				return { header: removeDiacritics(e.title), key: e.key.toString() };
			}),
			await downloadAll(
				props.getCall,
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage")
					);
				}
			)
		);
	}, [setDownloading, props.reportColumns, props.getCall, props.fileName, t]);

	const { data: page, isLoading } = useQuery(
		[props.queryKey, currentPage, pageSize, ...(props.watchChanges ?? [])],
		() => props.getCall(currentPage, pageSize),
		{
			refetchOnWindowFocus: false,
			onError: () =>
				openErrorNotification(
					t("reports.errorTexts.loadingFailed"),
					t("reports.errorTexts.loadingFailedMessage")
				),
		}
	);

	return (
		<div style={{ width: "100%" }}>
			<Row style={{ width: "100%", marginTop: "1em" }}>
				<CustomButton
					onClick={downloadReport}
					marginbottom={"1em"}
					margintop={"0"}
				>
					{t("reports.download")}
				</CustomButton>
				{downloading && (
					<Progress
						percent={progress}
						status={downloadError ? "exception" : undefined}
					/>
				)}
			</Row>
			<Row style={{ width: "100%" }}>
				<Table
					style={{ width: "100%" }}
					locale={locale}
					dataSource={page?.data === null ? undefined : page?.data}
					columns={props.columns.map((e) => {
						return {
							title: e.title,
							key: e.key.toString(),
							dataIndex: e.key.toString(),
							render: e.render,
						};
					})}
					pagination={{
						total: page?.totalCount,
						current: currentPage,
						pageSize: page?.pageSize,
						pageSizeOptions: ["10", "20", "50"],
						defaultPageSize: 10,
						hideOnSinglePage: true,
						showSizeChanger: true,
						locale: { items_per_page: t("configuration.pagination") },
						position: ["topRight"],
					}}
					rowKey={getId}
					loading={isLoading}
					onChange={handleTableChange}
					scroll={{ x: 400 }}
				/>
			</Row>
		</div>
	);
}

export default ReportTable;
