/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResearchProposalEnrollmentStatus,
    ResearchProposalEnrollmentStatusFromJSON,
    ResearchProposalEnrollmentStatusFromJSONTyped,
    ResearchProposalEnrollmentStatusToJSON,
} from './ResearchProposalEnrollmentStatus';
import {
    ResearchProposalEnrollmentType,
    ResearchProposalEnrollmentTypeFromJSON,
    ResearchProposalEnrollmentTypeFromJSONTyped,
    ResearchProposalEnrollmentTypeToJSON,
} from './ResearchProposalEnrollmentType';
import {
    ResearchProposalType,
    ResearchProposalTypeFromJSON,
    ResearchProposalTypeFromJSONTyped,
    ResearchProposalTypeToJSON,
} from './ResearchProposalType';

/**
 * 
 * @export
 * @interface ResearchPartnersForProfessorOrCompanyDTO
 */
export interface ResearchPartnersForProfessorOrCompanyDTO {
    /**
     * 
     * @type {ResearchProposalEnrollmentType}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    enrollmentType?: ResearchProposalEnrollmentType;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    professorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    researchProposalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorPersonalEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorCvUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorLinkedinUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorDepartmentNameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorDepartmentNameEn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorFacultyNameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorFacultyNameEn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly professorAvatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly applicantId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly applicantName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly applicantEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly companyAvatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly companyLinkedInUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly companyContactPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly companyEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly companyUserEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly companyUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly researchProposalName?: string | null;
    /**
     * 
     * @type {ResearchProposalType}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    researchProposalType?: ResearchProposalType;
    /**
     * 
     * @type {Date}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    applicationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly researchProposalProposerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly researchProposalProposerName?: string | null;
    /**
     * 
     * @type {ResearchProposalEnrollmentStatus}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    status?: ResearchProposalEnrollmentStatus;
    /**
     * 
     * @type {string}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly researchProposalEnrollmentId?: string;
    /**
     * 
     * @type {number}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    availablePositions?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPartnersForProfessorOrCompanyDTO
     */
    readonly isEnrollmentViewed?: boolean;
}

export function ResearchPartnersForProfessorOrCompanyDTOFromJSON(json: any): ResearchPartnersForProfessorOrCompanyDTO {
    return ResearchPartnersForProfessorOrCompanyDTOFromJSONTyped(json, false);
}

export function ResearchPartnersForProfessorOrCompanyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResearchPartnersForProfessorOrCompanyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enrollmentType': !exists(json, 'enrollmentType') ? undefined : ResearchProposalEnrollmentTypeFromJSON(json['enrollmentType']),
        'professorId': !exists(json, 'professorId') ? undefined : json['professorId'],
        'researchProposalId': !exists(json, 'researchProposalId') ? undefined : json['researchProposalId'],
        'professorName': !exists(json, 'professorName') ? undefined : json['professorName'],
        'professorEmail': !exists(json, 'professorEmail') ? undefined : json['professorEmail'],
        'professorPersonalEmail': !exists(json, 'professorPersonalEmail') ? undefined : json['professorPersonalEmail'],
        'professorPhone': !exists(json, 'professorPhone') ? undefined : json['professorPhone'],
        'professorCvUrl': !exists(json, 'professorCvUrl') ? undefined : json['professorCvUrl'],
        'professorLinkedinUrl': !exists(json, 'professorLinkedinUrl') ? undefined : json['professorLinkedinUrl'],
        'professorDepartmentNameRo': !exists(json, 'professorDepartmentNameRo') ? undefined : json['professorDepartmentNameRo'],
        'professorDepartmentNameEn': !exists(json, 'professorDepartmentNameEn') ? undefined : json['professorDepartmentNameEn'],
        'professorFacultyNameRo': !exists(json, 'professorFacultyNameRo') ? undefined : json['professorFacultyNameRo'],
        'professorFacultyNameEn': !exists(json, 'professorFacultyNameEn') ? undefined : json['professorFacultyNameEn'],
        'professorAvatar': !exists(json, 'professorAvatar') ? undefined : json['professorAvatar'],
        'applicantId': !exists(json, 'applicantId') ? undefined : json['applicantId'],
        'applicantName': !exists(json, 'applicantName') ? undefined : json['applicantName'],
        'applicantEmail': !exists(json, 'applicantEmail') ? undefined : json['applicantEmail'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyAvatar': !exists(json, 'companyAvatar') ? undefined : json['companyAvatar'],
        'companyLinkedInUrl': !exists(json, 'companyLinkedInUrl') ? undefined : json['companyLinkedInUrl'],
        'companyContactPhone': !exists(json, 'companyContactPhone') ? undefined : json['companyContactPhone'],
        'companyEmail': !exists(json, 'companyEmail') ? undefined : json['companyEmail'],
        'companyUserEmail': !exists(json, 'companyUserEmail') ? undefined : json['companyUserEmail'],
        'companyUserName': !exists(json, 'companyUserName') ? undefined : json['companyUserName'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'researchProposalName': !exists(json, 'researchProposalName') ? undefined : json['researchProposalName'],
        'researchProposalType': !exists(json, 'researchProposalType') ? undefined : ResearchProposalTypeFromJSON(json['researchProposalType']),
        'applicationDate': !exists(json, 'applicationDate') ? undefined : (new Date(json['applicationDate'])),
        'researchProposalProposerId': !exists(json, 'researchProposalProposerId') ? undefined : json['researchProposalProposerId'],
        'researchProposalProposerName': !exists(json, 'researchProposalProposerName') ? undefined : json['researchProposalProposerName'],
        'status': !exists(json, 'status') ? undefined : ResearchProposalEnrollmentStatusFromJSON(json['status']),
        'researchProposalEnrollmentId': !exists(json, 'researchProposalEnrollmentId') ? undefined : json['researchProposalEnrollmentId'],
        'availablePositions': !exists(json, 'availablePositions') ? undefined : json['availablePositions'],
        'isEnrollmentViewed': !exists(json, 'isEnrollmentViewed') ? undefined : json['isEnrollmentViewed'],
    };
}

export function ResearchPartnersForProfessorOrCompanyDTOToJSON(value?: ResearchPartnersForProfessorOrCompanyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enrollmentType': ResearchProposalEnrollmentTypeToJSON(value.enrollmentType),
        'professorId': value.professorId,
        'researchProposalId': value.researchProposalId,
        'researchProposalType': ResearchProposalTypeToJSON(value.researchProposalType),
        'applicationDate': value.applicationDate === undefined ? undefined : (value.applicationDate.toISOString()),
        'status': ResearchProposalEnrollmentStatusToJSON(value.status),
        'availablePositions': value.availablePositions,
    };
}

