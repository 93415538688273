import { Modal, Spin, Typography } from "antd";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import CustomModalFooter from "./CustomModalFooter";
import { ConfirmationModalProps } from "../PropsLists/ConfirmationModalProps";

const ConfirmationModal = (
	props: PropsWithChildren<ConfirmationModalProps>
) => {
	const { t } = useTranslation();

	const handleClose = () => {
		props.changeModalVisibility();
	};

	return (
		<div style={props.divStyle ?? { paddingBottom: 20 }}>
			<Modal
				visible={props.modalVisibility}
				maskClosable={!props.spinning}
				onOk={props.handleFunction}
				onCancel={handleClose}
				title={props.title}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={props.spinning}
						handleSave={props.handleFunction}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={props.spinning}>
					<div>
						<Typography>{props.modalText}</Typography>
					</div>
				</Spin>
			</Modal>
		</div>
	);
};

export default ConfirmationModal;
