import { useEffect, useState } from "react";
import styles from "./Layout.module.scss";
import NavBar from "./Header/NavBar";
import Footer from "./Footer/Footer";
import { BackTop } from "antd";

const Layout = (props: any) => {
	const [state, setState] = useState({
		mounted: false,
	});

	useEffect(() => {
		if (!state.mounted) {
			setState((prevState) => ({
				...prevState,
				mounted: true,
			}));
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [state.mounted]);

	return (
		<div className={styles.container}>
			<NavBar />
			<div className={styles.content}>{props.children}</div>
			<BackTop />
			<Footer />
		</div>
	);
};

export default Layout;
