import { Button, Modal, Select, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getUserId, useIsStudent } from "../../utils/utilFunctions";
import { getStudentById } from "../../Requests/academic-user-requests";
import { theme } from "../../theme";
import {
	getCurrentStudentStudyProgramInfo,
	getStudentAccountInfo,
	getStudyProgramsInfo,
} from "../../utils/reactQueriesConstants";
import {
	getAvailableStudyProgramsForStudent,
	getCurrentStudentStudyProgram,
	getStudyPrograms,
	setCurrentStudentStudyProgram,
} from "../../Requests/student-study-requests";
import CustomSelect from "../../CustomComponents/CustomSelect";
import styles from "./SelectStudyProgramModal.module.scss";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { StudentStudyProgramDTO, StudentYearEnum } from "../../Api";

const { Option } = Select;

const SelectStudyProgramModal = () => {
	const { t } = useTranslation();
	const [spinning, setSpinning] = useState(false);
	const isStudent = useIsStudent();
	const [studyProgram, setStudyProgram] = useState("");
	const [currentStudyProgram, setCurrentStudyProgram] = useState<
		StudentStudyProgramDTO | undefined | "NONE"
	>(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const openDataErrorNotification = (_error: any) => {
		openNotification(
			t("account.error"),
			t("account.fetchUserDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const saveChangesError = () => {
		openNotification(
			t("account.saveError"),
			t("account.saveChangesError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const successMessage = () => {
		openNotification(
			t("account.success"),
			t("account.successSaveMessage"),
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const { data: studentState } = useQuery(
		[getStudentAccountInfo, isStudent],
		() => {
			if (isStudent) return getStudentById(getUserId());
		},
		{
			onError: openDataErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: studyPrograms } = useQuery(
		[getStudyProgramsInfo, isStudent, studentState?.facultyId, isModalVisible],
		async () =>
			isStudent && isModalVisible && !!studentState
				? await getAvailableStudyProgramsForStudent()
				: null,
		{
			onError: (err) => {
				openDataErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	useQuery(
		[getCurrentStudentStudyProgramInfo, isStudent],
		() => {
			if (isStudent) return getCurrentStudentStudyProgram();
		},
		{
			onSuccess(data) {
				setCurrentStudyProgram(data);
			},
			retry(failureCount, error: any) {
				if (error.status === 404) {
					setCurrentStudyProgram("NONE");
					return false;
				}
				return failureCount < 3;
			},

			refetchOnWindowFocus: false,
		}
	);

	const handleOk = async () => {
		setSpinning(true);

		if (studyProgram === undefined || studyProgram === "") {
			saveChangesError();
			setSpinning(false);
			return;
		}

		await setCurrentStudentStudyProgram(studyProgram)
			.then(() => {
				setIsModalVisible(false);
				successMessage();
			})
			.catch(saveChangesError);
		setSpinning(false);
	};

	const changeSelectedStudyProgram = async (studyProgramId: string) => {
		setStudyProgram(studyProgramId);
	};

	useEffect(() => {
		const modalVisibility =
			!!isStudent &&
			isStudent &&
			currentStudyProgram === "NONE" &&
			!!studentState &&
			!!studentState.year &&
			![
				StudentYearEnum.D1,
				StudentYearEnum.D2,
				StudentYearEnum.D3,
				StudentYearEnum.D4,
				StudentYearEnum.D5,
			].includes(studentState?.year);

		setIsModalVisible(modalVisibility);
	}, [currentStudyProgram, isStudent, studentState?.year]);

	return isStudent ? (
		<div style={{ display: "flex", alignItems: "center" }}>
			<Modal
				visible={isModalVisible}
				onOk={handleOk}
				title={t("studyProgramModal.studyProgram")}
				closable={false}
				width={"50%"}
				footer={
					<Button
						key="submit"
						type="primary"
						loading={spinning}
						disabled={spinning}
						onClick={handleOk}
						style={{
							background: theme.secondColor,
							border: theme.secondColor,
							borderRadius: "10px",
						}}
					>
						{t("studyProgramModal.save")}
					</Button>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<Typography>{t("studyProgramModal.text")}</Typography>

						<CustomSelect
							allowClear
							className={styles.customInput}
							style={{ width: "100%", textAlign: "left", marginTop: "15px" }}
							placeholder={t("account.studyProgram")}
							onChange={changeSelectedStudyProgram}
						>
							{studyPrograms?.map((studyProgram) => (
								<Option key={studyProgram.id} value={studyProgram.id}>
									{studyProgram.name}
								</Option>
							))}
						</CustomSelect>
					</div>
				</Spin>
			</Modal>
		</div>
	) : null;
};

export default SelectStudyProgramModal;
