import Layout from "../../Containers/Layout";
import styles from "./InternshipInfo.module.scss";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import { Col, List, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import {
	deleteProfessorInternship,
	getProfessorInternshipById,
} from "../../Requests/professor-internship-requests";
import {
	deleteCompanyInternship,
	getCompanyInternshipById,
} from "../../Requests/company-internship-requests";
import { canApply, apply } from "../../Requests/internship-requests";
import {
	getUserId,
	internshipStatus,
	isUserLogged,
	useIsCompany,
	useIsProfessor,
	useIsStudent,
} from "../../utils/utilFunctions";
import {
	CompanyInternshipState,
	ErrorCodes,
	InternshipDTO,
	InternshipEngagementType,
	InternshipParticipationIntentDTO,
	InternshipParticipationType,
	InternshipType,
} from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import InternshipActions from "../Internships/InternshipActions";
import { useQueryClient, useQuery } from "react-query";
import {
	getErrorFromResponse,
	useErrorCodeTranslation,
} from "../../utils/responseUtils";
import {
	getAllApplicationTypesCount,
	getCompanyInternships,
	getCoordinatedInternships,
	getInternshipData,
	getInternshipsFilters,
	getProfessorInternships,
} from "../../utils/reactQueriesConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { setInternshipParticipationType } from "../../Requests/internship-type-event-requests";
import EventActions from "../Events/EventActions";

const InternshipInfo = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const location = useLocation<
		| {
				origin: string;
				filters: any;
				searchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
		  }
		| null
		| undefined
	>();
	const [internship, setInternship] = useState<InternshipDTO>();
	const [applicable, setApplicable] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const isStudent = useIsStudent();
	const isCompany = useIsCompany();
	const isProfessor = useIsProfessor();
	const history = useHistory();
	const queryClient = useQueryClient();
	const search = useLocation().search;
	const type = new URLSearchParams(search).get("type") as any;
	const [errorCode, setErrorCode] = useState<ErrorCodes | null>(
		ErrorCodes.CannotApplyAgain
	);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

	if (!id) {
		history.push("/");
	}

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	useQuery(
		[getInternshipData, isProfessor, isCompany, id],
		() =>
			type === InternshipType.ByProfessor
				? getProfessorInternshipById(id)
				: getCompanyInternshipById(id),
		{
			onError: (err) => {
				history.push("/404");
				openGetErrorNotification(err);
			},
			onSuccess: (response) => {
				setInternship(() => {
					return response;
				});
				setInterestedState(
					internship?.participationIntents?.find(
						(e: InternshipParticipationIntentDTO) => e.user?.id === getUserId()
					)?.participationType ?? InternshipParticipationType.None
				);
			},
		}
	);

	const canApplyToInternship = () => {
		if (!internship) return false;

		canApply(internship?.internshipId)
			.then(() => {
				setApplicable(() => {
					return true;
				});
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);
				setApplicable(false);
				setErrorCode(errorMessage?.code || null);
			});

		return applicable;
	};

	const applyToInternship = () => {
		if (!internship) return;

		setSpinning(true);

		apply(internship?.internshipId)
			.then(async () => {
				setApplicable(false);
				await queryClient.invalidateQueries(getInternshipData);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("internships.applyToInternshipSuccess"),
					t("internships.applyToInternshipSuccessText"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((error) => {
				console.error(error);

				if (error) {
					if (error.status === StatusCodes.CONFLICT) {
						openNotification(
							t("internships.applyToInternshipError"),
							t("internships.applyToInternshipConflict"),
							NOTIFICATION_TYPES.ERROR
						);
					} else {
						openNotification(
							t("internships.applyToInternshipError"),
							t("internships.applyToInternshipOtherError"),
							NOTIFICATION_TYPES.ERROR
						);
					}
				}
			})
			.finally(() => {
				setSpinning(false);
				setModalVisibility(false);
			});
	};

	useEffect(() => {
		if (isUserLogged() && isStudent) {
			setApplicable(canApplyToInternship());
		}
	}, [internship?.internshipId, isStudent]);

	const applicationMessage = useErrorCodeTranslation(errorCode);

	const isEditAllowed = (internship: InternshipDTO) => {
		return (
			internship?.state === CompanyInternshipState.Draft ||
			internship?.state === CompanyInternshipState.Approved
		);
	};

	const isDeleteAllowed = (internship: InternshipDTO) => {
		return (
			internship?.state !== CompanyInternshipState.Approved ||
			(internship?.engagementType !==
				InternshipEngagementType.BachelorsInternship &&
				internship?.engagementType !==
					InternshipEngagementType.MastersInternship)
		);
	};

	const handleDelete = () => {
		(isUserLogged() && isProfessor
			? deleteProfessorInternship(internship?.id!)
			: deleteCompanyInternship(internship?.id!)
		)
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					t("internships.deleteInternship"),
					t("internships.deleteInternshipSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.deleteInternship"),
					t("internships.deleteInternshipError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setDeleteModalVisibility(false);

				let state = {
					existingFilters: location.state?.filters,
					searchTerm: location.state?.searchTerm,
					sortField: location.state?.sortField,
					sortDirection: location.state?.sortDirection,
					currentPage: location.state?.currentPage,
					pageSize: location.state?.pageSize,
					activeTab: location.state?.activeTab,
				};

				history.push(location.state!.origin, state);
			});
	};

	const invalidateInternshipsQueries = async () => {
		await queryClient.invalidateQueries(getInternshipsFilters);
		await queryClient.invalidateQueries(getCompanyInternships);
		await queryClient.invalidateQueries(getProfessorInternships);
		await queryClient.invalidateQueries(getCoordinatedInternships);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	const [interestedState, setInterestedState] =
		useState<InternshipParticipationType>(
			internship?.participationIntents?.find(
				(e: InternshipParticipationIntentDTO) => e.user?.id === getUserId()
			)?.participationType ?? InternshipParticipationType.None
		);

	const handleParticipationIntentButtonClick = async (
		participationType: InternshipParticipationType
	) => {
		const obj: InternshipParticipationIntentDTO = {
			internshipId: internship?.internshipId,
			participationType:
				interestedState === participationType
					? InternshipParticipationType.None
					: participationType,
		};

		await setInternshipParticipationType(obj);

		// update the counter for going and interested
		setInterestedState(obj.participationType!);
	};

	useEffect(() => {
		if (!isUserLogged()) {
			localStorage.setItem("nextUrl", window.location.href);
			history.push("/autentificare");
		}
	}, []);

	return (
		<Layout>
			<Spin size="large" spinning={!internship} tip={t("internships.loading")}>
				{location.state && (
					<Row>
						<Col xs={17} sm={20} md={21} lg={22} xl={22} />
						<Col xs={7} sm={4} md={3} lg={2} xl={2}>
							<Link
								to={{
									pathname: location.state.origin,
									state: {
										existingFilters: location.state.filters,
										searchTerm: location.state.searchTerm,
										sortField: location.state.sortField,
										sortDirection: location.state.sortDirection,
										currentPage: location.state.currentPage,
										pageSize: location.state.pageSize,
										activeTab: location.state?.activeTab,
									},
								}}
							>
								<CustomButton
									paddingvertical={"19px"}
									boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
								>
									{t("profile.goBack")}
								</CustomButton>
							</Link>
						</Col>
					</Row>
				)}
				<div className={styles.center}>
					<div className={styles.title}>{internship?.internshipName}</div>
					{internship?.engagementType === InternshipEngagementType.Event && (
						<List.Item
							className={styles.ListItem}
							key={internship?.id + "ev"}
							actions={[
								<EventActions
									item={internship!}
									activeTab={location.state?.activeTab}
								/>,
							]}
						/>
					)}
					{internship?.engagementType !== InternshipEngagementType.Event && (
						<List.Item
							className={styles.ListItem}
							key={internship?.id + "internship"}
							actions={[
								<InternshipActions
									item={internship!}
									activeTab={location.state?.activeTab}
								/>,
							]}
						/>
					)}
					{location.state && (
						<Row>
							<Col xs={0} sm={5} md={6} lg={8} xl={9} />
							<Col xs={7} sm={4} md={3} lg={3} xl={2}>
								{(isProfessor && internship?.recruiterId === getUserId()) ||
								(isCompany && isEditAllowed(internship!)) ? (
									<div>
										<CustomButton
											shape="circle"
											type="text"
											paddingvertical={"19px"}
											title={t("internships.edit")}
											style={{
												padding: "8px 0px",
												background: "none",
												color: theme.black,
												marginRight: "10%",
												marginTop: "0",
												boxShadow: "none",
												fontSize: "14px",
												height: "100%",
											}}
										>
											<Link
												to={{
													pathname: "/editare-propunere/" + internship?.id,
													state: {
														filters: location.state.filters,
														activeTab: location.state.activeTab,
														currentPage: location.state.currentPage,
														pageSize: location.state.pageSize,
														searchTerm: location.state.searchTerm,
														sortDirection: location.state.sortDirection,
														sortField: location.state.sortField,
													},
												}}
											>
												<FontAwesomeIcon icon={solid("edit")} />
												&nbsp;{t("internships.edit")}
											</Link>
										</CustomButton>
									</div>
								) : null}
							</Col>
							<Col xs={11} sm={6} md={5} lg={4} xl={3}>
								{(isProfessor && internship?.recruiterId === getUserId()) ||
								(isCompany && isDeleteAllowed(internship!)) ? (
									<div>
										<CustomButton
											style={{
												padding: "8px 0px",
												background: "none",
												color: theme.black,
												marginRight: "10%",
												marginTop: "0",
												boxShadow: "none",
												fontSize: "14px",
												height: "100%",
											}}
											onClick={() => setDeleteModalVisibility(true)}
											title={
												internship && internship.enrolledStudentsCount > 0
													? t("internships.deletionNotAllowed")
													: t("internships.deleteInternship")
											}
											icon={
												<FontAwesomeIcon
													icon={solid("trash")}
													style={{ paddingRight: "5%" }}
												/>
											}
											shape={"circle"}
											type={"text"}
											disabled={
												internship && internship.enrolledStudentsCount > 0
											}
										>
											{t("internships.deleteInternship")}
										</CustomButton>
										<ConfirmationModal
											modalText={t("internships.deleteInternshipMessage")}
											handleFunction={() => {
												handleDelete();
											}}
											modalVisibility={deleteModalVisibility}
											title=""
											changeModalVisibility={() =>
												setDeleteModalVisibility(false)
											}
											spinning={spinning}
										/>
									</div>
								) : null}
							</Col>
							<Col xs={6} sm={4} md={3} lg={2} xl={2}>
								{(isProfessor && internship?.recruiterId === getUserId()) ||
								(isCompany &&
									internship?.state === CompanyInternshipState.Approved) ? (
									<div>
										<CustomButton
											paddingvertical={"19px"}
											shape="circle"
											type="text"
											style={{
												padding: "8px 0px",
												background: "none",
												color: theme.black,
												marginRight: "10%",
												marginTop: "0",
												boxShadow: "none",
												fontSize: "14px",
												height: "100%",
											}}
											title={t("internships.applicants")}
										>
											<Link
												to={{
													pathname: "/aplicanti",
													state: {
														internshipId: internship!.internshipId,
														internshipName: internship!.internshipName,
													},
												}}
											>
												<FontAwesomeIcon icon={solid("users")} /> &nbsp;
												{t("internships.applicants")}
											</Link>
										</CustomButton>
									</div>
								) : null}
							</Col>
						</Row>
					)}
				</div>
				{internship?.skillsRequired && internship?.skillsRequired.length > 0 ? (
					<>
						<div className={styles.subtitle}>
							{t("internships.requiredSkills")}
						</div>
						<List
							grid={{
								gutter: 30,
							}}
							dataSource={internship?.skillsRequired}
							renderItem={(item: string) => (
								<List.Item>
									<div className={styles.skill}>{item}</div>
								</List.Item>
							)}
						/>
					</>
				) : null}
				<div className={styles.subtitle}>{t("internships.description")}</div>
				<div
					className={styles.description}
					dangerouslySetInnerHTML={{ __html: internship?.description || "" }}
				/>
				{!internshipStatus(
					internship?.availableFrom || new Date(),
					internship?.availableTo || new Date()
				) ? (
					<div className={styles.message}>
						{t("internships.applicationsClosed")}
					</div>
				) : !isUserLogged() ? (
					<div className={styles.center}>
						<Link to="/autentificare">
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								fontSize={"1rem"}
								width={"10rem"}
								paddingvertical={"1.2rem"}
								paddinghorizontal={"1.4rem"}
								marginleft={"3rem"}
							>
								{t("internships.loginToApply")}
							</CustomButton>
						</Link>
					</div>
				) : isStudent &&
				  internship?.engagementType === InternshipEngagementType.Event ? (
					<></>
				) : isStudent && !applicable ? (
					<div className={styles.message}>{applicationMessage}</div>
				) : (
					isStudent &&
					applicable && (
						<div className={styles.center}>
							<div>
								<Row
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "baseline",
									}}
								>
									<CustomButton
										fontSize={"0.9rem"}
										style={{
											background: theme.secondColor,
											color: theme.white,
											marginRight: "2em",
											marginTop: "5%",
											boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
										}}
										onClick={() => setModalVisibility(true)}
									>
										{t("internships.apply")}
									</CustomButton>
									{internship?.internshipLink && (
										<a
											href={internship.internshipLink}
											target="_blank"
											rel="noopener noreferrer"
										>
											<CustomButton
												fontSize={"0.9rem"}
												style={{
													background: theme.secondColor,
													color: theme.white,
													marginRight: "2em",
													marginTop: "5%",
													boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
												}}
											>
												{t("internships.internshipLink")}
											</CustomButton>
										</a>
									)}
								</Row>
								<ConfirmationModal
									modalText={t("internships.applyInternshipMessage")}
									handleFunction={applyToInternship}
									modalVisibility={modalVisibility}
									title=""
									changeModalVisibility={() => setModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						</div>
					)
				)}
			</Spin>
		</Layout>
	);
};

export default InternshipInfo;
