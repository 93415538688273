/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyResearchProposal,
    CompanyResearchProposalFromJSON,
    CompanyResearchProposalFromJSONTyped,
    CompanyResearchProposalToJSON,
} from './CompanyResearchProposal';
import {
    ProfessorResearchProposal,
    ProfessorResearchProposalFromJSON,
    ProfessorResearchProposalFromJSONTyped,
    ProfessorResearchProposalToJSON,
} from './ProfessorResearchProposal';
import {
    ResearchProposalEnrollment,
    ResearchProposalEnrollmentFromJSON,
    ResearchProposalEnrollmentFromJSONTyped,
    ResearchProposalEnrollmentToJSON,
} from './ResearchProposalEnrollment';
import {
    ResearchProposalType,
    ResearchProposalTypeFromJSON,
    ResearchProposalTypeFromJSONTyped,
    ResearchProposalTypeToJSON,
} from './ResearchProposalType';

/**
 * 
 * @export
 * @interface ResearchProposal
 */
export interface ResearchProposal {
    /**
     * 
     * @type {string}
     * @memberof ResearchProposal
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ResearchProposal
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ResearchProposal
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposal
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposal
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposal
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposal
     */
    url?: string | null;
    /**
     * 
     * @type {ResearchProposalType}
     * @memberof ResearchProposal
     */
    type?: ResearchProposalType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResearchProposal
     */
    keywords?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchProposal
     */
    isArchived?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ResearchProposal
     */
    availableFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ResearchProposal
     */
    availableTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof ResearchProposal
     */
    numberOfPartners?: number;
    /**
     * 
     * @type {number}
     * @memberof ResearchProposal
     */
    availableNumberOfPartners?: number;
    /**
     * 
     * @type {ProfessorResearchProposal}
     * @memberof ResearchProposal
     */
    professorResearchProposal?: ProfessorResearchProposal;
    /**
     * 
     * @type {CompanyResearchProposal}
     * @memberof ResearchProposal
     */
    companyResearchProposal?: CompanyResearchProposal;
    /**
     * 
     * @type {Array<ResearchProposalEnrollment>}
     * @memberof ResearchProposal
     */
    enrollments?: Array<ResearchProposalEnrollment> | null;
}

export function ResearchProposalFromJSON(json: any): ResearchProposal {
    return ResearchProposalFromJSONTyped(json, false);
}

export function ResearchProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResearchProposal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'type': !exists(json, 'type') ? undefined : ResearchProposalTypeFromJSON(json['type']),
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'isArchived': !exists(json, 'isArchived') ? undefined : json['isArchived'],
        'availableFrom': !exists(json, 'availableFrom') ? undefined : (new Date(json['availableFrom'])),
        'availableTo': !exists(json, 'availableTo') ? undefined : (new Date(json['availableTo'])),
        'numberOfPartners': !exists(json, 'numberOfPartners') ? undefined : json['numberOfPartners'],
        'availableNumberOfPartners': !exists(json, 'availableNumberOfPartners') ? undefined : json['availableNumberOfPartners'],
        'professorResearchProposal': !exists(json, 'professorResearchProposal') ? undefined : ProfessorResearchProposalFromJSON(json['professorResearchProposal']),
        'companyResearchProposal': !exists(json, 'companyResearchProposal') ? undefined : CompanyResearchProposalFromJSON(json['companyResearchProposal']),
        'enrollments': !exists(json, 'enrollments') ? undefined : (json['enrollments'] === null ? null : (json['enrollments'] as Array<any>).map(ResearchProposalEnrollmentFromJSON)),
    };
}

export function ResearchProposalToJSON(value?: ResearchProposal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'description': value.description,
        'shortDescription': value.shortDescription,
        'url': value.url,
        'type': ResearchProposalTypeToJSON(value.type),
        'keywords': value.keywords,
        'isArchived': value.isArchived,
        'availableFrom': value.availableFrom === undefined ? undefined : (value.availableFrom.toISOString()),
        'availableTo': value.availableTo === undefined ? undefined : (value.availableTo.toISOString()),
        'numberOfPartners': value.numberOfPartners,
        'availableNumberOfPartners': value.availableNumberOfPartners,
        'professorResearchProposal': ProfessorResearchProposalToJSON(value.professorResearchProposal),
        'companyResearchProposal': CompanyResearchProposalToJSON(value.companyResearchProposal),
        'enrollments': value.enrollments === undefined ? undefined : (value.enrollments === null ? null : (value.enrollments as Array<any>).map(ResearchProposalEnrollmentToJSON)),
    };
}

