import {
	ProfessorInternshipApi,
	ProfessorInternshipAddUpdateDTO,
	InternshipEngagementType,
	InternshipSortFieldEnum,
	SortDirectionEnum,
	InternshipStatus,
} from "../Api";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthProfessorInternshipApiFactory = () =>
	new ProfessorInternshipApi(getAuthConfiguration());

export const addProfessorInternship = async (
	professorInternshipAddUpdateDTO: ProfessorInternshipAddUpdateDTO
) => {
	await AuthProfessorInternshipApiFactory().apiProfessorInternshipAddPost({
		professorInternshipAddUpdateDTO,
	});
};

export const deleteProfessorInternship = async (id: string) => {
	await AuthProfessorInternshipApiFactory().apiProfessorInternshipDeleteIdDelete(
		{ id }
	);
};

export const duplicateProfessorInternship = async (id: string) => {
	await AuthProfessorInternshipApiFactory().apiProfessorInternshipDuplicateIdPost(
		{ id }
	);
};

export const getAllProfessorInternship = (
	skills?: Array<string>,
	categories?: Array<string>,
	types?: Array<string>,
	statuses?: Array<string>,
	applicabilityTypes?: Array<boolean>,
	validationStates?: Array<boolean>,
	universityYears?: Array<string>,
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetAllGet({
		skills: arrayParamAjust(skills),
		categories: arrayParamAjust(categories),
		types: arrayParamAjust(
			types?.map(
				(e) =>
					InternshipEngagementType[e as keyof typeof InternshipEngagementType]
			)
		),
		statuses: arrayParamAjust(
			statuses?.map((e) => InternshipStatus[e as keyof typeof InternshipStatus])
		),
		applicabilityTypes: arrayParamAjust(applicabilityTypes),
		validationStates: arrayParamAjust(validationStates),
		universityYears: arrayParamAjust(
			universityYears?.map((y) => Number(y.split("-")[0]))
		),
		searchTerm,
		page,
		pageSize,
		sortField,
		sortDirection,
	});
};

export const getAllProfessorInternshipsForFaculty = (
	skills?: Array<string>,
	categories?: Array<string>,
	types?: Array<string>,
	statuses?: Array<string>,
	validationStates?: Array<boolean>,
	universityYears?: Array<string>,
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetAllForFacultyGet(
		{
			skills: arrayParamAjust(skills),
			categories: arrayParamAjust(categories),
			types: arrayParamAjust(
				types?.map(
					(e) =>
						InternshipEngagementType[e as keyof typeof InternshipEngagementType]
				)
			),
			statuses: arrayParamAjust(
				statuses?.map(
					(e) => InternshipStatus[e as keyof typeof InternshipStatus]
				)
			),
			validationStates: arrayParamAjust(validationStates),
			universityYears: arrayParamAjust(
				universityYears?.map((y) => Number(y.split("-")[0]))
			),
			searchTerm,
			page,
			pageSize,
			sortField,
			sortDirection,
		}
	);
};

export const getAllProfessorInternshipsForDepartment = (
	skills?: Array<string>,
	categories?: Array<string>,
	types?: Array<string>,
	statuses?: Array<string>,
	validationStates?: Array<boolean>,
	universityYears?: Array<string>,
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetAllForDepartmentGet(
		{
			skills: arrayParamAjust(skills),
			categories: arrayParamAjust(categories),
			types: arrayParamAjust(
				types?.map(
					(e) =>
						InternshipEngagementType[e as keyof typeof InternshipEngagementType]
				)
			),
			statuses: arrayParamAjust(
				statuses?.map(
					(e) => InternshipStatus[e as keyof typeof InternshipStatus]
				)
			),
			validationStates: arrayParamAjust(validationStates),
			universityYears: arrayParamAjust(
				universityYears?.map((y) => Number(y.split("-")[0]))
			),
			searchTerm,
			page,
			pageSize,
			sortField,
			sortDirection,
		}
	);
};

export const getAllProfessorInternshipsForStudyProgram = (
	skills?: Array<string>,
	categories?: Array<string>,
	types?: Array<string>,
	statuses?: Array<string>,
	validationStates?: Array<boolean>,
	universityYears?: Array<string>,
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetAllForStudyProgramGet(
		{
			skills: arrayParamAjust(skills),
			categories: arrayParamAjust(categories),
			types: arrayParamAjust(
				types?.map(
					(e) =>
						InternshipEngagementType[e as keyof typeof InternshipEngagementType]
				)
			),
			statuses: arrayParamAjust(
				statuses?.map(
					(e) => InternshipStatus[e as keyof typeof InternshipStatus]
				)
			),
			validationStates: arrayParamAjust(validationStates),
			universityYears: arrayParamAjust(
				universityYears?.map((y) => Number(y.split("-")[0]))
			),
			searchTerm,
			page,
			pageSize,
			sortField,
			sortDirection,
		}
	);
};

export const getAllProfessorInternshipFilters = () => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetInternshipFiltersGet();
};

export const getFacultyProfessorInternshipFilters = () => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetFacultyInternshipFiltersGet();
};

export const getDepartmentProfessorInternshipFilters = () => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetDepartmentInternshipFiltersGet();
};

export const getStudyProgramProfessorInternshipFilters = () => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetStudyProgramInternshipFiltersGet();
};

export const getProfessorInternshipById = (id: string) => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetByIdIdGet(
		{ id }
	);
};

export const updateProfessorInternshipViewCountByInternshipId = async (
	id: string
) => {
	await AuthProfessorInternshipApiFactory().apiProfessorInternshipUpdateViewCountByInternshipIdIdPut(
		{ id }
	);
};

export const updateProfessorInternshipInfo = async (
	id: string,
	professorInternshipAddUpdateDTO?: ProfessorInternshipAddUpdateDTO
) => {
	await AuthProfessorInternshipApiFactory().apiProfessorInternshipUpdateIdPut({
		id,
		professorInternshipAddUpdateDTO,
	});
};

export const updateValidation = (internshipId: string, status: boolean) => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipChangeValidStatusPost(
		{ internshipId, status }
	);
};

export const changeStudyPrograms = (id: string, requestBody: Array<string>) => {
	return AuthProfessorInternshipApiFactory().apiProfessorInternshipChangeStudyProgramIdPut(
		{ id, requestBody }
	);
};
