/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternshipType,
    InternshipTypeFromJSON,
    InternshipTypeFromJSONTyped,
    InternshipTypeToJSON,
} from './InternshipType';
import {
    ProfessionalTitleEnum,
    ProfessionalTitleEnumFromJSON,
    ProfessionalTitleEnumFromJSONTyped,
    ProfessionalTitleEnumToJSON,
} from './ProfessionalTitleEnum';

/**
 * 
 * @export
 * @interface ProjectAllocationReportDTO
 */
export interface ProjectAllocationReportDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    id?: string;
    /**
     * 
     * @type {ProfessionalTitleEnum}
     * @memberof ProjectAllocationReportDTO
     */
    professorProfessionalTitle?: ProfessionalTitleEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    professorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    professorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    professorDepartment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    professorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    professorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    coTutorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    studentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    studentStudyProgram?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    studentDepartment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    studentEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    studentPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllocationReportDTO
     */
    projectName?: string | null;
    /**
     * 
     * @type {InternshipType}
     * @memberof ProjectAllocationReportDTO
     */
    projectType?: InternshipType;
}

export function ProjectAllocationReportDTOFromJSON(json: any): ProjectAllocationReportDTO {
    return ProjectAllocationReportDTOFromJSONTyped(json, false);
}

export function ProjectAllocationReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectAllocationReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'professorProfessionalTitle': !exists(json, 'professorProfessionalTitle') ? undefined : ProfessionalTitleEnumFromJSON(json['professorProfessionalTitle']),
        'professorId': !exists(json, 'professorId') ? undefined : json['professorId'],
        'professorName': !exists(json, 'professorName') ? undefined : json['professorName'],
        'professorDepartment': !exists(json, 'professorDepartment') ? undefined : json['professorDepartment'],
        'professorEmail': !exists(json, 'professorEmail') ? undefined : json['professorEmail'],
        'professorPhone': !exists(json, 'professorPhone') ? undefined : json['professorPhone'],
        'coTutorName': !exists(json, 'coTutorName') ? undefined : json['coTutorName'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'studentName': !exists(json, 'studentName') ? undefined : json['studentName'],
        'studentStudyProgram': !exists(json, 'studentStudyProgram') ? undefined : json['studentStudyProgram'],
        'studentDepartment': !exists(json, 'studentDepartment') ? undefined : json['studentDepartment'],
        'studentEmail': !exists(json, 'studentEmail') ? undefined : json['studentEmail'],
        'studentPhone': !exists(json, 'studentPhone') ? undefined : json['studentPhone'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'projectType': !exists(json, 'projectType') ? undefined : InternshipTypeFromJSON(json['projectType']),
    };
}

export function ProjectAllocationReportDTOToJSON(value?: ProjectAllocationReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'professorProfessionalTitle': ProfessionalTitleEnumToJSON(value.professorProfessionalTitle),
        'professorId': value.professorId,
        'professorName': value.professorName,
        'professorDepartment': value.professorDepartment,
        'professorEmail': value.professorEmail,
        'professorPhone': value.professorPhone,
        'coTutorName': value.coTutorName,
        'studentId': value.studentId,
        'studentName': value.studentName,
        'studentStudyProgram': value.studentStudyProgram,
        'studentDepartment': value.studentDepartment,
        'studentEmail': value.studentEmail,
        'studentPhone': value.studentPhone,
        'projectId': value.projectId,
        'projectName': value.projectName,
        'projectType': InternshipTypeToJSON(value.projectType),
    };
}

