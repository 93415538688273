import { useState } from "react";
import styles from "./MyAccount.module.scss";
import { Checkbox, Col, Row, Table } from "antd";
import { getUserId } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { CompanyUserDTO, ErrorCodes } from "../../Api";
import Search from "antd/lib/input/Search";
import {
	getAllCompanyUsers,
	deleteCompanyUser,
	getCompanyUserById,
	toggleCompanyUserNotifications,
	toggleAdminRole,
	activateAccountForContactUser,
} from "../../Requests/company-users-requests";
import { StatusCodes } from "http-status-codes";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { makeRepresentative } from "../../Requests/company-users-requests";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	getCompanyUser,
	getCompanyUsers,
} from "../../utils/reactQueriesConstants";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { useHistory } from "react-router-dom";
import { AddUpdateCompanyUserModalForm } from "../Forms";

const CompanyUserTable = () => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [
		changeRepresentativeModalVisibility,
		setChangeRepresentativeModalVisibility,
	] = useState(false);
	const [removeUserModalVisibility, setRemoveUserModalVisibility] =
		useState(false);
	const [activateAccountModalVisibility, setActivateAccountModalVisibility] =
		useState(false);
	const [selectedUserId, setSelectedUserId] = useState<string>("");
	const [spinning, setSpinning] = useState(false);
	const history = useHistory();

	let locale = {
		emptyText: t("tableText.noCompanyUsers"),
	};

	const queryClient = useQueryClient();
	const invalidateCompanyUserQuery = async () => {
		await queryClient.invalidateQueries(getCompanyUser);
	};
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getCompanyUsers);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteCompanyUser(id)
			.then(() => {
				invalidateUsersQuery();

				openNotification(
					t("account.userDeleted"),
					t("account.userDeletedMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
					openNotification(
						t("account.conflict"),
						t("account.notFoundUser"),
						NOTIFICATION_TYPES.ERROR
					);
				} else if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
					openNotification(
						t("account.error"),
						t("account.notAllowed"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
				setRemoveUserModalVisibility(false);
			});
	};

	const handleChangeRepresentative = (id: string) => {
		setSpinning(true);

		makeRepresentative(id)
			.then(() => {
				invalidateCompanyUserQuery();
				invalidateUsersQuery();

				openNotification(
					t("account.representativeChanged"),
					t("account.representativeChangedMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
					openNotification(
						t("account.error"),
						t("account.notAllowed"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
				setChangeRepresentativeModalVisibility(false);
			});
	};

	const { data: companyUser } = useQuery([getCompanyUser], () => {
		return getCompanyUserById(getUserId()).catch(() =>
			openNotification(
				t("account.error"),
				t("usersText.errorTexts.failedUserGet"),
				NOTIFICATION_TYPES.ERROR
			)
		);
	});

	const { data: users, isLoading } = useQuery(
		[getCompanyUsers, searchTerm, page, pageSize],
		() => {
			return getAllCompanyUsers(searchTerm, page, pageSize).catch(() =>
				openNotification(
					t("account.error"),
					t("usersText.errorTexts.failedUserGet"),
					NOTIFICATION_TYPES.ERROR
				)
			);
		}
	);

	const FilterByNameInput = (
		<Search
			placeholder={t("account.name")}
			allowClear
			onSearch={(value) => {
				setSearchTerm(value);
				setPage(1);
			}}
			style={{ width: 200 }}
		/>
	);

	const columns = [
		{
			title: FilterByNameInput,
			dataIndex: "name",
			key: "name",
		},
		{
			title: t("account.email"),
			dataIndex: "email",
			key: "email",
		},
		{
			title: t("account.phone"),
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: t("account.function"),
			dataIndex: "_function",
			key: "function",
		},
		{
			title: t("account.department"),
			dataIndex: "department",
			key: "department",
		},
		{
			title: t("account.notifications"),
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<Checkbox
						checked={record.sendNotifications}
						onChange={(e: CheckboxChangeEvent) =>
							onNotificationToggle(e, record)
						}
						disabled={record?.isContact === true}
					/>
				</div>
			),
		},
		{
			title: t("account.companyAdmin"),
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<Checkbox
						checked={record.isAdmin}
						onChange={(e: CheckboxChangeEvent) => onAdminRoleToggle(e, record)}
						disabled={record?.isContact === true}
					/>
				</div>
			),
		},
		{
			title: t("account.actions"),
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<div className={styles.modalBtnContainer}>
						{(companyUser?.isRepresentative ||
							(companyUser?.isAdmin && !record.isRepresentative)) &&
							companyUser.id !== record.id &&
							record?.isContact === true && (
								<div className={styles.modalContainer}>
									<CustomButton
										fontSize={"0.9rem"}
										style={{
											background: "transparent",
											border: "none",
											outline: "none",
											color: theme.black,
											boxShadow: "none",
											padding: 0,
											marginTop: 0,
										}}
										onClick={() => {
											setSelectedUserId(record.id);
											setActivateAccountModalVisibility(true);
										}}
										icon={<FontAwesomeIcon icon={solid("plus")} />}
										title={t("account.activateAccount")}
									/>
									<ConfirmationModal
										modalText={
											t("account.activateAccountMessage") + record.name + "? "
										}
										handleFunction={() => activateAccount(selectedUserId)}
										modalVisibility={activateAccountModalVisibility}
										title=""
										changeModalVisibility={() =>
											setActivateAccountModalVisibility(false)
										}
										spinning={spinning}
									/>
								</div>
							)}
						{(companyUser?.isRepresentative || companyUser?.isAdmin) && (
							<AddUpdateCompanyUserModalForm type={"edit"} userId={record.id} />
						)}
						{companyUser?.isRepresentative &&
							!record?.isRepresentative &&
							record?.isContact !== true && (
								<div className={styles.modalContainer}>
									<CustomButton
										fontSize={"0.9rem"}
										style={{
											background: "transparent",
											border: "none",
											outline: "none",
											color: theme.black,
											boxShadow: "none",
											padding: 0,
											marginTop: 0,
										}}
										onClick={() => {
											setSelectedUserId(record.id);
											setChangeRepresentativeModalVisibility(true);
										}}
										icon={<FontAwesomeIcon icon={solid("star")} />}
										title={t("account.changeRepresentative")}
									/>
									<ConfirmationModal
										modalText={
											t("account.changeRepresentativeMessage1") +
											record.name +
											t("account.changeRepresentativeMessage2")
										}
										handleFunction={() =>
											handleChangeRepresentative(selectedUserId)
										}
										modalVisibility={changeRepresentativeModalVisibility}
										title=""
										changeModalVisibility={() =>
											setChangeRepresentativeModalVisibility(false)
										}
										spinning={spinning}
									/>
								</div>
							)}
						{(companyUser?.isRepresentative ||
							(companyUser?.isAdmin && !record.isRepresentative)) &&
							companyUser.id !== record.id && (
								<div className={styles.modalContainer}>
									<CustomButton
										fontSize={"0.9rem"}
										style={{
											background: "transparent",
											border: "none",
											outline: "none",
											color: theme.black,
											boxShadow: "none",
											padding: 0,
											marginTop: 0,
										}}
										onClick={() => {
											setSelectedUserId(record.id);
											setRemoveUserModalVisibility(true);
										}}
										icon={<FontAwesomeIcon icon={solid("trash")} />}
										title={t("account.deleteUser")}
									/>
									<ConfirmationModal
										modalText={
											t("account.deleteUserMessage") +
											record.name +
											"? " +
											t("account.deleteUserMessage2")
										}
										handleFunction={() => handleDelete(selectedUserId)}
										modalVisibility={removeUserModalVisibility}
										title=""
										changeModalVisibility={() =>
											setRemoveUserModalVisibility(false)
										}
										spinning={spinning}
									/>
								</div>
							)}
					</div>
				</div>
			),
		},
	];

	const activateAccount = (id: string) => {
		setSpinning(true);

		activateAccountForContactUser(id)
			.then(() => {
				invalidateCompanyUserQuery();
				invalidateUsersQuery();

				openNotification(
					t("account.activatedAccount"),
					t("account.activatedAccountMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
					openNotification(
						t("account.error"),
						t("account.notAllowed"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
				setActivateAccountModalVisibility(false);
			});
	};

	const onNotificationToggle = (e: CheckboxChangeEvent, record: any) => {
		record.sendNotifications = e.target.checked;

		setSpinning(true);

		toggleCompanyUserNotifications(record.id)
			.then(() => {
				invalidateCompanyUserQuery();
				invalidateUsersQuery();

				if (e.target.checked) {
					openNotification(
						t("account.notificationsEnabled"),
						t("account.notificationsEnabledMessage"),
						NOTIFICATION_TYPES.SUCCESS
					);
				} else {
					openNotification(
						t("account.notificationsDisabled"),
						t("account.notificationsDisabledMessage"),
						NOTIFICATION_TYPES.SUCCESS
					);
				}
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
					openNotification(
						t("account.error"),
						t("account.notAllowed"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
			});
	};

	const onAdminRoleToggle = (e: CheckboxChangeEvent, record: any) => {
		record.isAdmin = e.target.checked;

		toggleAdminRole(record.id)
			.then(() => {
				invalidateCompanyUserQuery();
				invalidateUsersQuery();

				if (e.target.checked) {
					openNotification(
						t("account.companyAdminNominated"),
						t("account.companyAdminNominatedMessage"),
						NOTIFICATION_TYPES.SUCCESS
					);
				} else {
					openNotification(
						t("account.companyAdminRevoked"),
						t("account.companyAdminRevokedMessage"),
						NOTIFICATION_TYPES.SUCCESS
					);

					// exit edit profile page when revoking own admin role
					if (record.id === getUserId() && !companyUser?.isRepresentative) {
						history.goBack();
					}
				}
			})
			.catch(async (error: any) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.Forbidden:
						notificationMessage = t("errors.forbidden");
						break;
					case ErrorCodes.UserNotFound:
						notificationMessage = t("account.notFoundUser");
						break;
					default:
						notificationMessage = t("account.unknownError");
						break;
				}

				openNotification(
					t("account.error"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const handleTableChange = (pagination: any) => {
		setPage(() => pagination.current);
		setPageSize(() => pagination.pageSize);
	};

	return (
		<Col
			span={24}
			className={styles.container}
			style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}
		>
			<Row>
				<AddUpdateCompanyUserModalForm type={"add"} userId={getUserId()} />
			</Row>
			<Row>
				<Col span={24}>
					<Table
						locale={locale}
						className={styles.usersTable}
						dataSource={users?.data ?? new Array<CompanyUserDTO>()}
						columns={columns}
						pagination={{
							total: users?.totalCount,
							current: users?.page,
							pageSize: users?.pageSize,
							pageSizeOptions: ["10", "20", "50"],
							showSizeChanger: true,
							locale: { items_per_page: t("configuration.pagination") },
							position: ["topRight"],
							hideOnSinglePage: users?.totalCount
								? users?.totalCount <= 10
								: true,
						}}
						rowKey={(record) => record.id ?? ""}
						loading={isLoading}
						onChange={handleTableChange}
						scroll={{ x: 400 }}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default CompanyUserTable;
