import {
	InternshipSortFieldEnum,
	ProfessorResearchProposalAddUpdateDTO,
	ProfessorResearchProposalApi,
	SortDirectionEnum,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthProfessorResearchProposalApiFactory = () =>
	new ProfessorResearchProposalApi(getAuthConfiguration());

export const addProfessorResearchProposal = async (
	professorResearchProposalAddUpdateDTO: ProfessorResearchProposalAddUpdateDTO
) => {
	await AuthProfessorResearchProposalApiFactory().apiProfessorResearchProposalAddPost(
		{ professorResearchProposalAddUpdateDTO }
	);
};

export const getAllProfessorResearchPropsals = (
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthProfessorResearchProposalApiFactory().apiProfessorResearchProposalGetAllGet(
		{ page, pageSize }
	);
};

export const getProfessorResearchProposalById = (id: string) => {
	return AuthProfessorResearchProposalApiFactory().apiProfessorResearchProposalGetByIdIdGet(
		{ id }
	);
};

export const updateProfessorResearchProposalInfo = async (
	id: string,
	professorResearchProposalAddUpdateDTO?: ProfessorResearchProposalAddUpdateDTO
) => {
	await AuthProfessorResearchProposalApiFactory().apiProfessorResearchProposalUpdateIdPut(
		{ id, professorResearchProposalAddUpdateDTO }
	);
};

export const deleteProfessorResearchProposal = async (id: string) => {
	await AuthProfessorResearchProposalApiFactory().apiProfessorResearchProposalDeleteIdDelete(
		{ id }
	);
};

// export const getAllProfessorInternshipFilters = () => {
//     return AuthProfessorInternshipApiFactory().apiProfessorInternshipGetInternshipFiltersGet();
// }
