import {
	CompanyResearchProposalAddUpdateDTO,
	InternshipSortFieldEnum,
	ResearchProposalApi,
	ResearchProposalEnrollmentStatus,
	SortDirectionEnum,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthResearchProposalApiFactory = () =>
	new ResearchProposalApi(getAuthConfiguration());

export const getResearchPartnersForCompanyOrProfessor = async (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	enrollmentStatus?: Array<ResearchProposalEnrollmentStatus>,
	researchProposals?: Array<string>,
	viewStatuses?: Array<string>
) => {
	return await AuthResearchProposalApiFactory().apiResearchProposalGetPartnersForCompanyOrProfessorGet(
		{
			namePattern,
			researchProposals,
			enrollmentStatus,
			viewStatuses,
			page,
			pageSize,
		}
	);
};

export const getMyApplications = async (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	enrollmentStatus?: Array<ResearchProposalEnrollmentStatus>,
	researchProposals?: Array<string>,
	viewStatuses?: Array<string>
) => {
	return await AuthResearchProposalApiFactory().apiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGet(
		{
			namePattern,
			researchProposals,
			enrollmentStatus,
			viewStatuses,
			page,
			pageSize,
		}
	);
};

export const canApplyProfessor = async (researchProposalId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalCanApplyProfessorResearchProposalIdGet(
		{ researchProposalId }
	);
};

export const canApplyCompany = async (researchProposalId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalCanApplyCompanyResearchProposalIdGet(
		{ researchProposalId }
	);
};

export const applyResearchProposal = async (researchProposalId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalApplyResearchProposalIdPost(
		{ researchProposalId }
	);
};

export const acceptApplicant = async (researchProposalEnrollmentId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalAcceptApplicationPost(
		{ researchProposalAcceptDTO: { researchProposalEnrollmentId } }
	);
};

export const rejectApplicant = async (researchProposalEnrollmentId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalRejectApplicationPost(
		{ researchProposalAcceptDTO: { researchProposalEnrollmentId } }
	);
};

export const updateIsResearchProposalEnrollmentViewed = (
	id: string,
	isEnrollmentView: boolean
) => {
	return AuthResearchProposalApiFactory().apiResearchProposalUpdateIsEnrollmentViewedIdPut(
		{ id, isEnrollmentView }
	);
};
