/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyInternshipAddUpdateDTO,
    CompanyInternshipAddUpdateDTOFromJSON,
    CompanyInternshipAddUpdateDTOToJSON,
    CompanyInternshipRejectionDTO,
    CompanyInternshipRejectionDTOFromJSON,
    CompanyInternshipRejectionDTOToJSON,
    CompanyInternshipState,
    CompanyInternshipStateFromJSON,
    CompanyInternshipStateToJSON,
    EnrollmentStatus,
    EnrollmentStatusFromJSON,
    EnrollmentStatusToJSON,
    FacultyCoordinatorDTO,
    FacultyCoordinatorDTOFromJSON,
    FacultyCoordinatorDTOToJSON,
    Filter,
    FilterFromJSON,
    FilterToJSON,
    InternshipDTO,
    InternshipDTOFromJSON,
    InternshipDTOToJSON,
    InternshipDTOPagedResponse,
    InternshipDTOPagedResponseFromJSON,
    InternshipDTOPagedResponseToJSON,
    InternshipEngagementType,
    InternshipEngagementTypeFromJSON,
    InternshipEngagementTypeToJSON,
    InternshipSortFieldEnum,
    InternshipSortFieldEnumFromJSON,
    InternshipSortFieldEnumToJSON,
    InternshipStatus,
    InternshipStatusFromJSON,
    InternshipStatusToJSON,
    SortDirectionEnum,
    SortDirectionEnumFromJSON,
    SortDirectionEnumToJSON,
} from '../models';

export interface ApiCompanyInternshipAcceptCoordinationIdPutRequest {
    id: string;
}

export interface ApiCompanyInternshipAddPostRequest {
    companyInternshipAddUpdateDTO?: CompanyInternshipAddUpdateDTO;
}

export interface ApiCompanyInternshipApproveIdPutRequest {
    id: string;
}

export interface ApiCompanyInternshipChangeApproveInternshipStudyProgramIdPutRequest {
    id: string;
}

export interface ApiCompanyInternshipChangeStudyProgramIdPutRequest {
    id: string;
    requestBody?: Array<string>;
}

export interface ApiCompanyInternshipDeleteIdDeleteRequest {
    id: string;
}

export interface ApiCompanyInternshipDuplicateIdPostRequest {
    id: string;
}

export interface ApiCompanyInternshipGetAllGetRequest {
    companyId?: string;
    professorId?: string;
    skills?: Array<string>;
    categories?: Array<string>;
    types?: Array<InternshipEngagementType>;
    locations?: Array<string>;
    statuses?: Array<InternshipStatus>;
    states?: Array<CompanyInternshipState>;
    applicabilityTypes?: Array<boolean>;
    validationStates?: Array<boolean>;
    universityYears?: Array<number>;
    searchTerm?: string;
    sortField?: InternshipSortFieldEnum;
    sortDirection?: SortDirectionEnum;
    facultyCoordinatorId?: string;
    enableSearchByFacultyCoordinator?: boolean;
    studentStatus?: Array<EnrollmentStatus>;
    recruiterStatus?: Array<EnrollmentStatus>;
    page?: number;
    pageSize?: number;
}

export interface ApiCompanyInternshipGetByIdIdGetRequest {
    id: string;
}

export interface ApiCompanyInternshipGetCoordinatedProfessorInternshipsGetRequest {
    companyId?: string;
    professorId?: string;
    skills?: Array<string>;
    categories?: Array<string>;
    types?: Array<InternshipEngagementType>;
    locations?: Array<string>;
    statuses?: Array<InternshipStatus>;
    states?: Array<CompanyInternshipState>;
    applicabilityTypes?: Array<boolean>;
    validationStates?: Array<boolean>;
    universityYears?: Array<number>;
    searchTerm?: string;
    sortField?: InternshipSortFieldEnum;
    sortDirection?: SortDirectionEnum;
    facultyCoordinatorId?: string;
    enableSearchByFacultyCoordinator?: boolean;
    studentStatus?: Array<EnrollmentStatus>;
    recruiterStatus?: Array<EnrollmentStatus>;
    page?: number;
    pageSize?: number;
}

export interface ApiCompanyInternshipInvalidateIdPutRequest {
    id: string;
}

export interface ApiCompanyInternshipRejectCoordinationIdPutRequest {
    id: string;
}

export interface ApiCompanyInternshipRejectIdPutRequest {
    id: string;
    companyInternshipRejectionDTO?: CompanyInternshipRejectionDTO;
}

export interface ApiCompanyInternshipSendForApprovalIdPutRequest {
    id: string;
}

export interface ApiCompanyInternshipUpdateFacultyCoordinatorIdPutRequest {
    id: string;
    facultyCoordinatorDTO?: FacultyCoordinatorDTO;
}

export interface ApiCompanyInternshipUpdateIdPutRequest {
    id: string;
    companyInternshipAddUpdateDTO?: CompanyInternshipAddUpdateDTO;
}

export interface ApiCompanyInternshipUpdateViewCountByInternshipIdIdPutRequest {
    id: string;
}

/**
 * 
 */
export class CompanyInternshipApi extends runtime.BaseAPI {

    /**
     */
    async apiCompanyInternshipAcceptCoordinationIdPutRaw(requestParameters: ApiCompanyInternshipAcceptCoordinationIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipAcceptCoordinationIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/AcceptCoordination/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipAcceptCoordinationIdPut(requestParameters: ApiCompanyInternshipAcceptCoordinationIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipAcceptCoordinationIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipAddPostRaw(requestParameters: ApiCompanyInternshipAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyInternshipAddUpdateDTOToJSON(requestParameters.companyInternshipAddUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipAddPost(requestParameters: ApiCompanyInternshipAddPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipAddPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipApproveIdPutRaw(requestParameters: ApiCompanyInternshipApproveIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipApproveIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/Approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipApproveIdPut(requestParameters: ApiCompanyInternshipApproveIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipApproveIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipChangeApproveInternshipStudyProgramIdPutRaw(requestParameters: ApiCompanyInternshipChangeApproveInternshipStudyProgramIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipChangeApproveInternshipStudyProgramIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/ChangeApproveInternshipStudyProgram/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipChangeApproveInternshipStudyProgramIdPut(requestParameters: ApiCompanyInternshipChangeApproveInternshipStudyProgramIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipChangeApproveInternshipStudyProgramIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipChangeStudyProgramIdPutRaw(requestParameters: ApiCompanyInternshipChangeStudyProgramIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipChangeStudyProgramIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/ChangeStudyProgram/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipChangeStudyProgramIdPut(requestParameters: ApiCompanyInternshipChangeStudyProgramIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipChangeStudyProgramIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipDeleteIdDeleteRaw(requestParameters: ApiCompanyInternshipDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipDeleteIdDelete(requestParameters: ApiCompanyInternshipDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipDuplicateIdPostRaw(requestParameters: ApiCompanyInternshipDuplicateIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipDuplicateIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/Duplicate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipDuplicateIdPost(requestParameters: ApiCompanyInternshipDuplicateIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipDuplicateIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipGetAllGetRaw(requestParameters: ApiCompanyInternshipGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternshipDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['CompanyId'] = requestParameters.companyId;
        }

        if (requestParameters.professorId !== undefined) {
            queryParameters['ProfessorId'] = requestParameters.professorId;
        }

        if (requestParameters.skills) {
            queryParameters['Skills'] = requestParameters.skills;
        }

        if (requestParameters.categories) {
            queryParameters['Categories'] = requestParameters.categories;
        }

        if (requestParameters.types) {
            queryParameters['Types'] = requestParameters.types;
        }

        if (requestParameters.locations) {
            queryParameters['Locations'] = requestParameters.locations;
        }

        if (requestParameters.statuses) {
            queryParameters['Statuses'] = requestParameters.statuses;
        }

        if (requestParameters.states) {
            queryParameters['States'] = requestParameters.states;
        }

        if (requestParameters.applicabilityTypes) {
            queryParameters['ApplicabilityTypes'] = requestParameters.applicabilityTypes;
        }

        if (requestParameters.validationStates) {
            queryParameters['ValidationStates'] = requestParameters.validationStates;
        }

        if (requestParameters.universityYears) {
            queryParameters['UniversityYears'] = requestParameters.universityYears;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['SearchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['SortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['SortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.facultyCoordinatorId !== undefined) {
            queryParameters['FacultyCoordinatorId'] = requestParameters.facultyCoordinatorId;
        }

        if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
            queryParameters['EnableSearchByFacultyCoordinator'] = requestParameters.enableSearchByFacultyCoordinator;
        }

        if (requestParameters.studentStatus) {
            queryParameters['StudentStatus'] = requestParameters.studentStatus;
        }

        if (requestParameters.recruiterStatus) {
            queryParameters['RecruiterStatus'] = requestParameters.recruiterStatus;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternshipDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyInternshipGetAllGet(requestParameters: ApiCompanyInternshipGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<InternshipDTOPagedResponse> {
        const response = await this.apiCompanyInternshipGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyInternshipGetByIdIdGetRaw(requestParameters: ApiCompanyInternshipGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternshipDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipGetByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/GetById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternshipDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyInternshipGetByIdIdGet(requestParameters: ApiCompanyInternshipGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<InternshipDTO> {
        const response = await this.apiCompanyInternshipGetByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyInternshipGetCoordinatedProfessorInternshipsFiltersGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/GetCoordinatedProfessorInternshipsFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiCompanyInternshipGetCoordinatedProfessorInternshipsFiltersGet(initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiCompanyInternshipGetCoordinatedProfessorInternshipsFiltersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyInternshipGetCoordinatedProfessorInternshipsGetRaw(requestParameters: ApiCompanyInternshipGetCoordinatedProfessorInternshipsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternshipDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['CompanyId'] = requestParameters.companyId;
        }

        if (requestParameters.professorId !== undefined) {
            queryParameters['ProfessorId'] = requestParameters.professorId;
        }

        if (requestParameters.skills) {
            queryParameters['Skills'] = requestParameters.skills;
        }

        if (requestParameters.categories) {
            queryParameters['Categories'] = requestParameters.categories;
        }

        if (requestParameters.types) {
            queryParameters['Types'] = requestParameters.types;
        }

        if (requestParameters.locations) {
            queryParameters['Locations'] = requestParameters.locations;
        }

        if (requestParameters.statuses) {
            queryParameters['Statuses'] = requestParameters.statuses;
        }

        if (requestParameters.states) {
            queryParameters['States'] = requestParameters.states;
        }

        if (requestParameters.applicabilityTypes) {
            queryParameters['ApplicabilityTypes'] = requestParameters.applicabilityTypes;
        }

        if (requestParameters.validationStates) {
            queryParameters['ValidationStates'] = requestParameters.validationStates;
        }

        if (requestParameters.universityYears) {
            queryParameters['UniversityYears'] = requestParameters.universityYears;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['SearchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['SortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['SortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.facultyCoordinatorId !== undefined) {
            queryParameters['FacultyCoordinatorId'] = requestParameters.facultyCoordinatorId;
        }

        if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
            queryParameters['EnableSearchByFacultyCoordinator'] = requestParameters.enableSearchByFacultyCoordinator;
        }

        if (requestParameters.studentStatus) {
            queryParameters['StudentStatus'] = requestParameters.studentStatus;
        }

        if (requestParameters.recruiterStatus) {
            queryParameters['RecruiterStatus'] = requestParameters.recruiterStatus;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/GetCoordinatedProfessorInternships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternshipDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyInternshipGetCoordinatedProfessorInternshipsGet(requestParameters: ApiCompanyInternshipGetCoordinatedProfessorInternshipsGetRequest = {}, initOverrides?: RequestInit): Promise<InternshipDTOPagedResponse> {
        const response = await this.apiCompanyInternshipGetCoordinatedProfessorInternshipsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyInternshipGetInternshipFiltersGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/GetInternshipFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiCompanyInternshipGetInternshipFiltersGet(initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiCompanyInternshipGetInternshipFiltersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyInternshipInvalidateIdPutRaw(requestParameters: ApiCompanyInternshipInvalidateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipInvalidateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/Invalidate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipInvalidateIdPut(requestParameters: ApiCompanyInternshipInvalidateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipInvalidateIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipRejectCoordinationIdPutRaw(requestParameters: ApiCompanyInternshipRejectCoordinationIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipRejectCoordinationIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/RejectCoordination/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipRejectCoordinationIdPut(requestParameters: ApiCompanyInternshipRejectCoordinationIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipRejectCoordinationIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipRejectIdPutRaw(requestParameters: ApiCompanyInternshipRejectIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipRejectIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/Reject/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyInternshipRejectionDTOToJSON(requestParameters.companyInternshipRejectionDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipRejectIdPut(requestParameters: ApiCompanyInternshipRejectIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipRejectIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipSendForApprovalIdPutRaw(requestParameters: ApiCompanyInternshipSendForApprovalIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipSendForApprovalIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/SendForApproval/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipSendForApprovalIdPut(requestParameters: ApiCompanyInternshipSendForApprovalIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipSendForApprovalIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipUpdateFacultyCoordinatorIdPutRaw(requestParameters: ApiCompanyInternshipUpdateFacultyCoordinatorIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipUpdateFacultyCoordinatorIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/UpdateFacultyCoordinator/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FacultyCoordinatorDTOToJSON(requestParameters.facultyCoordinatorDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipUpdateFacultyCoordinatorIdPut(requestParameters: ApiCompanyInternshipUpdateFacultyCoordinatorIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipUpdateFacultyCoordinatorIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipUpdateIdPutRaw(requestParameters: ApiCompanyInternshipUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/Update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyInternshipAddUpdateDTOToJSON(requestParameters.companyInternshipAddUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipUpdateIdPut(requestParameters: ApiCompanyInternshipUpdateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipUpdateIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyInternshipUpdateViewCountByInternshipIdIdPutRaw(requestParameters: ApiCompanyInternshipUpdateViewCountByInternshipIdIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyInternshipUpdateViewCountByInternshipIdIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyInternship/UpdateViewCountByInternshipId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyInternshipUpdateViewCountByInternshipIdIdPut(requestParameters: ApiCompanyInternshipUpdateViewCountByInternshipIdIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyInternshipUpdateViewCountByInternshipIdIdPutRaw(requestParameters, initOverrides);
    }

}
