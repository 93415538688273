/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternshipEnrollment,
    InternshipEnrollmentFromJSON,
    InternshipEnrollmentFromJSONTyped,
    InternshipEnrollmentToJSON,
} from './InternshipEnrollment';
import {
    PracticeCertificateSignature,
    PracticeCertificateSignatureFromJSON,
    PracticeCertificateSignatureFromJSONTyped,
    PracticeCertificateSignatureToJSON,
} from './PracticeCertificateSignature';
import {
    PracticeCertificateState,
    PracticeCertificateStateFromJSON,
    PracticeCertificateStateFromJSONTyped,
    PracticeCertificateStateToJSON,
} from './PracticeCertificateState';
import {
    PracticeQualification,
    PracticeQualificationFromJSON,
    PracticeQualificationFromJSONTyped,
    PracticeQualificationToJSON,
} from './PracticeQualification';

/**
 * 
 * @export
 * @interface PracticeCertificate
 */
export interface PracticeCertificate {
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificate
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PracticeCertificate
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PracticeCertificate
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof PracticeCertificate
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PracticeCertificate
     */
    workHoursPerDay?: number;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificate
     */
    observations?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificate
     */
    studentGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificate
     */
    signingTutorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificate
     */
    signingTutorFunction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificate
     */
    signingTutorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificate
     */
    signingTutorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificate
     */
    internshipEnrollmentId?: string;
    /**
     * 
     * @type {InternshipEnrollment}
     * @memberof PracticeCertificate
     */
    internshipEnrollment?: InternshipEnrollment;
    /**
     * 
     * @type {PracticeCertificateState}
     * @memberof PracticeCertificate
     */
    state?: PracticeCertificateState;
    /**
     * 
     * @type {Array<PracticeQualification>}
     * @memberof PracticeCertificate
     */
    practiceQualifications?: Array<PracticeQualification> | null;
    /**
     * 
     * @type {Array<PracticeCertificateSignature>}
     * @memberof PracticeCertificate
     */
    practiceCertificateSignatures?: Array<PracticeCertificateSignature> | null;
}

export function PracticeCertificateFromJSON(json: any): PracticeCertificate {
    return PracticeCertificateFromJSONTyped(json, false);
}

export function PracticeCertificateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeCertificate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'number': !exists(json, 'number') ? undefined : json['number'],
        'workHoursPerDay': !exists(json, 'workHoursPerDay') ? undefined : json['workHoursPerDay'],
        'observations': !exists(json, 'observations') ? undefined : json['observations'],
        'studentGroup': !exists(json, 'studentGroup') ? undefined : json['studentGroup'],
        'signingTutorName': !exists(json, 'signingTutorName') ? undefined : json['signingTutorName'],
        'signingTutorFunction': !exists(json, 'signingTutorFunction') ? undefined : json['signingTutorFunction'],
        'signingTutorEmail': !exists(json, 'signingTutorEmail') ? undefined : json['signingTutorEmail'],
        'signingTutorPhone': !exists(json, 'signingTutorPhone') ? undefined : json['signingTutorPhone'],
        'internshipEnrollmentId': !exists(json, 'internshipEnrollmentId') ? undefined : json['internshipEnrollmentId'],
        'internshipEnrollment': !exists(json, 'internshipEnrollment') ? undefined : InternshipEnrollmentFromJSON(json['internshipEnrollment']),
        'state': !exists(json, 'state') ? undefined : PracticeCertificateStateFromJSON(json['state']),
        'practiceQualifications': !exists(json, 'practiceQualifications') ? undefined : (json['practiceQualifications'] === null ? null : (json['practiceQualifications'] as Array<any>).map(PracticeQualificationFromJSON)),
        'practiceCertificateSignatures': !exists(json, 'practiceCertificateSignatures') ? undefined : (json['practiceCertificateSignatures'] === null ? null : (json['practiceCertificateSignatures'] as Array<any>).map(PracticeCertificateSignatureFromJSON)),
    };
}

export function PracticeCertificateToJSON(value?: PracticeCertificate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'number': value.number,
        'workHoursPerDay': value.workHoursPerDay,
        'observations': value.observations,
        'studentGroup': value.studentGroup,
        'signingTutorName': value.signingTutorName,
        'signingTutorFunction': value.signingTutorFunction,
        'signingTutorEmail': value.signingTutorEmail,
        'signingTutorPhone': value.signingTutorPhone,
        'internshipEnrollmentId': value.internshipEnrollmentId,
        'internshipEnrollment': InternshipEnrollmentToJSON(value.internshipEnrollment),
        'state': PracticeCertificateStateToJSON(value.state),
        'practiceQualifications': value.practiceQualifications === undefined ? undefined : (value.practiceQualifications === null ? null : (value.practiceQualifications as Array<any>).map(PracticeQualificationToJSON)),
        'practiceCertificateSignatures': value.practiceCertificateSignatures === undefined ? undefined : (value.practiceCertificateSignatures === null ? null : (value.practiceCertificateSignatures as Array<any>).map(PracticeCertificateSignatureToJSON)),
    };
}

