/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SkillEndorsementAddDTO,
    SkillEndorsementAddDTOFromJSON,
    SkillEndorsementAddDTOToJSON,
    SkillEndorsementDTO,
    SkillEndorsementDTOFromJSON,
    SkillEndorsementDTOToJSON,
} from '../models';

export interface ApiSkillEndorsementAddPostRequest {
    skillEndorsementAddDTO?: SkillEndorsementAddDTO;
}

export interface ApiSkillEndorsementDeleteIdDeleteRequest {
    id: string;
}

/**
 * 
 */
export class SkillEndorsementApi extends runtime.BaseAPI {

    /**
     */
    async apiSkillEndorsementAddPostRaw(requestParameters: ApiSkillEndorsementAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SkillEndorsementDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/SkillEndorsement/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SkillEndorsementAddDTOToJSON(requestParameters.skillEndorsementAddDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkillEndorsementDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiSkillEndorsementAddPost(requestParameters: ApiSkillEndorsementAddPostRequest = {}, initOverrides?: RequestInit): Promise<SkillEndorsementDTO> {
        const response = await this.apiSkillEndorsementAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSkillEndorsementDeleteIdDeleteRaw(requestParameters: ApiSkillEndorsementDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSkillEndorsementDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/SkillEndorsement/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSkillEndorsementDeleteIdDelete(requestParameters: ApiSkillEndorsementDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSkillEndorsementDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

}
