/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyUser,
    CompanyUserFromJSON,
    CompanyUserFromJSONTyped,
    CompanyUserToJSON,
} from './CompanyUser';

/**
 * 
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof RefreshToken
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RefreshToken
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    token?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RefreshToken
     */
    expirationDate?: Date;
    /**
     * 
     * @type {CompanyUser}
     * @memberof RefreshToken
     */
    user?: CompanyUser;
}

export function RefreshTokenFromJSON(json: any): RefreshToken {
    return RefreshTokenFromJSONTyped(json, false);
}

export function RefreshTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
        'user': !exists(json, 'user') ? undefined : CompanyUserFromJSON(json['user']),
    };
}

export function RefreshTokenToJSON(value?: RefreshToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'userId': value.userId,
        'token': value.token,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
        'user': CompanyUserToJSON(value.user),
    };
}

