import { useState } from "react";
import styles from "./Profile.module.scss";
import { Col, Row, Table } from "antd";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButton from "../../CustomComponents/CustomButton";
import { getFacultiesOfInterestList } from "../../utils/reactQueriesConstants";
import {
	deleteFacultyOfInterest,
	getFacultiesOfInterest,
} from "../../Requests/company-requests";
import AddFacultyOfInterestModal from "./AddFacultyOfInterestModal";
import { useGetCompanyId } from "../../utils/utilFunctions";
import { ErrorCodes } from "../../Api";
import { getErrorFromResponse } from "../../utils/responseUtils";

const FacultiesOfInterestTable = (props: { companyId: string }) => {
	const { t, i18n } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [removeModalVisibility, setRemoveModalVisibility] = useState(false);
	const [selectedId, setSelectedId] = useState<string>("");
	const [spinning, setSpinning] = useState(false);
	const companyId = useGetCompanyId();

	let locale = {
		emptyText: t("tableText.noFacultyOfInterest"),
	};

	const queryClient = useQueryClient();
	const invalidateQuery = async () => {
		await queryClient.invalidateQueries(getFacultiesOfInterestList);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteFacultyOfInterest(id)
			.then(async () => {
				await invalidateQuery();

				openNotification(
					t("account.facultyOfInterestDelete"),
					t("account.facultyOfInterestDeleteSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (ex: any) => {
				const error = await getErrorFromResponse(ex);

				openNotification(
					t("account.supervisorDelete"),
					error?.code === ErrorCodes.Forbidden
						? t("account.noPermissions")
						: error?.code === ErrorCodes.AlreadyDeleted
						? t("account.facultyOfInterestAlreadyDeleted")
						: error?.code === ErrorCodes.CompanyNotFound
						? t("account.companyNotFound")
						: error?.code === ErrorCodes.FacultyNotFound
						? t("account.facultyNotFound")
						: t("account.facultyOfInterestDelete"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setRemoveModalVisibility(false);
			});
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: facultiesOfInterest, isLoading } = useQuery(
		[getFacultiesOfInterestList, searchTerm, page, pageSize],
		() => getFacultiesOfInterest(companyId, searchTerm, page, pageSize),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const columns = [
		{
			title: t("account.facultyOfInterest"),
			dataIndex: i18n.language === "ro" ? "facultyNameRo" : "facultyNameEn",
			key: i18n.language === "ro" ? "facultyNameRo" : "facultyNameEn",
		},
		{
			title: t("account.actions"),
			width: "100px",
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<div className={styles.modalBtnContainer}>
						<>
							<div className={styles.modalContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedId(record.id);
										setRemoveModalVisibility(true);
									}}
									icon={<FontAwesomeIcon icon={solid("trash")} />}
									title={t("account.deleteFacultyOfInterest")}
								/>
							</div>
						</>
					</div>
				</div>
			),
		},
	];

	const handleTableChange = (pagination: any) => {
		setPage(() => pagination.current);
		setPageSize(() => pagination.pageSize);
	};

	return (
		<Col span={24} className={styles.container}>
			<Row>
				<AddFacultyOfInterestModal
					facultiesOfInterestIds={
						facultiesOfInterest?.data?.map((e) => e.id!) ?? []
					}
				/>
			</Row>
			<Row>
				<ConfirmationModal
					modalText={t("account.deleteFacultyOfInterestMessage")}
					handleFunction={() => handleDelete(selectedId)}
					modalVisibility={removeModalVisibility}
					title=""
					changeModalVisibility={() => setRemoveModalVisibility(false)}
					spinning={spinning}
				/>
				<Col span={24}>
					<Table
						locale={locale}
						className={styles.usersTable}
						dataSource={facultiesOfInterest?.data ?? []}
						columns={columns}
						pagination={{
							total: facultiesOfInterest?.totalCount,
							current: facultiesOfInterest?.page,
							pageSize: facultiesOfInterest?.pageSize,
							pageSizeOptions: ["10", "20", "50"],
							showSizeChanger: true,
							locale: { items_per_page: t("configuration.pagination") },
							position: ["topRight"],
							hideOnSinglePage: facultiesOfInterest?.totalCount
								? facultiesOfInterest?.totalCount <= 10
								: true,
						}}
						rowKey={(record) => record.id ?? ""}
						loading={isLoading}
						onChange={handleTableChange}
						scroll={{ x: 400 }}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default FacultiesOfInterestTable;
