import Layout from "../../Containers/Layout";
import { Row, Col, Button, Drawer } from "antd";
import { useEffect, useState } from "react";
import {
	getAllCompanyInternship,
	getAllCompanyInternshipFilters,
} from "../../Requests/company-internship-requests";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./Internships.module.scss";
import Search from "antd/lib/input/Search";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import {
	capitalizeFirstLetterFromEachWordInASentence,
	getCurrentUniversityYear,
	useBeforeRender,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudent,
} from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { Filter, InternshipSortFieldEnum, SortDirectionEnum } from "../../Api";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQuery, useQueryClient } from "react-query";
import { getAllProfessors } from "../../Requests/academic-user-requests";
import InternshipsList from "./InternshipsList";
import CustomSort from "../../CustomComponents/CustomSort";
import CustomFilter from "../../CustomComponents/CustomFilter";
import {
	companyInternshipsFiltersKey,
	getAllApplicationTypesCount,
	getCompanyInternships,
	getCoordinatedInternships,
	getInternshipsFilters,
	getProfessorInternships,
} from "../../utils/reactQueriesConstants";
import { useDispatch, useSelector } from "react-redux";
import { FilterState, setCheckedFilters } from "../Filters/FiltersSlice";

const getAllKeys = (filterItems: any[]) => {
	const keys: any[] = [];

	filterItems.forEach((filter) => {
		keys.push(filter.key);

		filter.children.forEach((filterItem: any) => {
			keys.push(filterItem.key.split("/")[1]);
		});
	});

	return keys;
};

const CompanyInternships = (props: any) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState(
		InternshipSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [skills, setSkills] = useState([]);
	const [categories, setCategories] = useState([]);
	const [locations, setLocations] = useState([]);
	const [types, setTypes] = useState([] as any);
	const [statuses, setStatuses] = useState([]);
	const [states, setStates] = useState([]);
	const [universityYears, setUniversityYears] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState<any>([]);
	const [visible, setVisible] = useState(false);
	const history = useHistory();
	const [professorOptions, setProfessorOptions] = useState([] as any);
	const [faculties, setFaculties] = useState([]);
	const [departments, setDepartments] = useState([]);
	const isProfessor = useIsProfessor();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const queryClient = useQueryClient();

	const dispatch = useDispatch();
	const cachedFilters = useSelector(
		(state: { filters: FilterState }) =>
			state.filters[companyInternshipsFiltersKey]
	);

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getInternshipsFilters);
		await queryClient.invalidateQueries(getCompanyInternships);
		await queryClient.invalidateQueries(getProfessorInternships);
		await queryClient.invalidateQueries(getCoordinatedInternships);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrentPage(1);
		setTypes(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Type@"))
				.map((e: string) => e.split("@")[1])
		);
		setLocations(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Location@"))
				.map((e: string) => e.split("@")[1])
		);
		setStatuses(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Status@"))
				.map((e: string) => e.split("@")[1])
		);
		setStates(
			checkedKeysValue
				.filter((e: string) => e.startsWith("State@"))
				.map((e: string) => e.split("@")[1])
		);
		setUniversityYears(
			checkedKeysValue
				.filter((e: string) => e.startsWith("UniversityYear@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const setDefaultFilters = () => {
		onCheck([
			`UniversityYear@${getCurrentUniversityYear()}-${
				getCurrentUniversityYear() + 1
			}`,
		]);
	};

	const getOldValues = () => {
		if (!!cachedFilters) {
			let {
				filters,
				searchTerm,
				sortField,
				sortDirection,
				currentPage,
				pageSize,
			}: any = cachedFilters;

			onCheck(filters);
			setSearchTerm(searchTerm);
			setSortField(sortField);
			setSortDirection(sortDirection);
			setCurrentPage(currentPage);
			setPageSize(pageSize);
		} else {
			setDefaultFilters();
		}
	};
	useBeforeRender(() => getOldValues(), []);

	const updateCachedFilters = () => {
		var newCachedValue = {
			filters: checkedKeys,
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			currentPage: currentPage,
			pageSize: pageSize,
		};

		dispatch(
			setCheckedFilters({
				filterKey: companyInternshipsFiltersKey,
				value: newCachedValue,
			})
		);
	};

	useEffect(() => {
		updateCachedFilters();
	}, [
		checkedKeys,
		searchTerm,
		sortField,
		sortDirection,
		currentPage,
		pageSize,
	]);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const openGetErrorNotification = (_error: any) => {
		console.error(_error);
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openNotificationErrorFetchUsers = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: filters } = useQuery(
		[getInternshipsFilters],
		() => {
			return getAllCompanyInternshipFilters();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: companyInternships, isLoading: loadingCompanyInternships } =
		useQuery(
			[
				getCompanyInternships,
				currentPage,
				pageSize,
				sortField,
				sortDirection,
				skills,
				types,
				categories,
				locations,
				statuses,
				states,
				universityYears,
				searchTerm,
				isFacultyAdmin,
				faculties,
				departments,
			],
			() => {
				if (isFacultyAdmin) {
					getAllProfessors(
						searchTerm,
						currentPage,
						pageSize,
						faculties,
						departments
					)
						.then((response: any) => {
							setProfessorOptions(() => {
								return response.data;
							});
						})
						.catch((ex) => {
							openNotificationErrorFetchUsers(ex);
						});
					return getAllCompanyInternship(
						skills,
						categories,
						locations,
						types,
						statuses,
						states,
						universityYears,
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
					);
				} else {
					return getAllCompanyInternship(
						skills,
						categories,
						locations,
						types,
						statuses,
						states,
						universityYears,
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
					);
				}
			},
			{
				onError: openGetErrorNotification,
				refetchOnWindowFocus: false,
			}
		);

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters.map((x) => {
			if (x.key === "Location" || x.key === "UniversityYear") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: capitalizeFirstLetterFromEachWordInASentence(
								y.title?.toLowerCase()
							),
						}))
					),
				};
			} else {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("internships.filters." + y.title),
						}))
					),
				};
			}
		});
	};

	const changeSortField = (value: any) => {
		setSortField(value);
	};

	const changeSortDirection = () => {
		if (sortDirection === SortDirectionEnum.Ascending) {
			setSortDirection(SortDirectionEnum.Descending);
		} else {
			setSortDirection(SortDirectionEnum.Ascending);
		}
	};

	return (
		<Layout>
			<Row>
				<Col span={4} className={styles.filters}>
					{!isStudent && !isProfessor && (
						<CustomButton
							backgroundcolor={theme.primaryColor}
							textcolor={theme.white}
							fontSize={"1rem"}
							paddingvertical={"1.2rem"}
							paddinghorizontal={"1.4rem"}
							marginleft={"auto"}
							marginright={"auto"}
							margintop={"2rem"}
						>
							<Link
								to={{
									pathname: "/editare-propunere/",
									state: {
										filters: checkedKeys,
										searchTerm: searchTerm,
										sortField: sortField,
										sortDirection: sortDirection,
										currentPage: currentPage,
										pageSize: pageSize,
									},
								}}
							>
								{t("internships.addInternship")}
							</Link>
						</CustomButton>
					)}
					<Search
						defaultValue={searchTerm}
						className={styles.searchBox}
						placeholder={t("internships.search")}
						allowClear
						onSearch={setSearchTerm}
						style={{ width: 200 }}
					/>
					<CustomSort
						changeSortField={changeSortField}
						changeSortDirection={changeSortDirection}
						sortDirection={sortDirection}
						location={props.location}
						oldValue={sortField}
					/>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Col>
				<Drawer
					className={styles.opened}
					placement={"left"}
					closable={true}
					onClose={onClose}
					visible={visible}
					key={"left"}
					maskClosable={true}
					title={t("internships.filters.filters")}
				>
					<Search
						placeholder={t("internships.search")}
						allowClear
						onSearch={setSearchTerm}
					/>
					<CustomSort
						changeSortField={changeSortField}
						changeSortDirection={changeSortDirection}
						sortDirection={sortDirection}
						location={props.location}
					/>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Drawer>
				<Row justify="space-between" className={styles.filterButton}>
					{!isStudent && !isProfessor && (
						<Col
							span={window.innerWidth > 350 ? 12 : 24}
							style={{
								display: "flex",
								justifyContent:
									window.innerWidth > 350 ? "flex-start" : "center",
								marginBottom: window.innerWidth < 351 ? "1rem" : 0,
							}}
						>
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								fontSize={"1rem"}
								paddingvertical={"1.2rem"}
								paddinghorizontal={"1.4rem"}
								margintop={"0"}
							>
								<Link
									to={{
										pathname: "/editare-propunere/",
										state: {
											filters: checkedKeys,
											searchTerm: searchTerm,
											sortField: sortField,
											sortDirection: sortDirection,
											currentPage: currentPage,
											pageSize: pageSize,
										},
									}}
								>
									{t("internships.addInternship")}
								</Link>
							</CustomButton>
						</Col>
					)}
					<Col
						span={window.innerWidth > 350 ? 12 : 24}
						style={{
							display: "flex",
							justifyContent:
								window.innerWidth < 351
									? "center"
									: isStudent || isProfessor
									? "flex-start"
									: "flex-end",
						}}
					>
						<Button
							onClick={showDrawer}
							style={{ outline: "none", border: "none", boxShadow: "none" }}
						>
							<FontAwesomeIcon
								icon={solid("filter")}
								style={{ color: theme.secondColor }}
							/>
							&nbsp;{t("internships.filters.filter")}
						</Button>
					</Col>
				</Row>
				<Col
					span={window.innerWidth > 1100 ? 20 : 24}
					style={{ padding: "0 0 0 3%" }}
				>
					<InternshipsList
						data={companyInternships}
						professorOptions={professorOptions}
						checkedKeys={checkedKeys}
						loading={loadingCompanyInternships}
						location={props.location}
						activeTab={""}
						currentPage={currentPage}
						pageSize={pageSize}
						searchTerm={searchTerm}
						sortDirection={sortDirection}
						sortField={sortField}
						handleListChange={handleListChange}
					/>
				</Col>
			</Row>
		</Layout>
	);
};

export default CompanyInternships;
