import { StudentStudyApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthStudentStudyApiFactory = () =>
	new StudentStudyApi(getAuthConfiguration());

export const getStudentStudyPrograms = (studentId: string) => {
	return AuthStudentStudyApiFactory().apiStudentStudyGetStudentStudyProgramsGet(
		{ studentId }
	);
};

export const getCurrentStudentStudyProgram = () => {
	return AuthStudentStudyApiFactory().apiStudentStudyGetCurrentStudentStudyProgramGet();
};

export const setCurrentStudentStudyProgram = async (studyProgramId: string) => {
	await AuthStudentStudyApiFactory().apiStudentStudySetCurrentStudentStudyProgramPut(
		{ studyProgramId }
	);
};

export const getStudyPrograms = (facultyId: string) => {
	return AuthStudentStudyApiFactory().apiStudentStudyGetStudyProgramsGet({
		facultyIds: [facultyId],
	});
};

export const getAvailableStudyProgramsForStudent = () => {
	return AuthStudentStudyApiFactory().apiStudentStudyGetPossibleStudyProgramsForCurrnetStudentGet();
};

export const getAvailableStudyPrograms = (supervisorId: string) => {
	return AuthStudentStudyApiFactory().apiStudentStudyGetAvailableStudyProgramsSupervisorIdGet(
		{ supervisorId }
	);
};

export const getSupervisedStudyPrograms = (
	supervisorId: string,
	namePattern: string,
	page: number,
	pageSize: number
) => {
	return AuthStudentStudyApiFactory().apiStudentStudyGetSupervisedStudyProgramsSupervisorIdGet(
		{ supervisorId, namePattern, page, pageSize }
	);
};
