/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface StudentsPerDomainReportDTO
 */
export interface StudentsPerDomainReportDTO {
    /**
     * 
     * @type {string}
     * @memberof StudentsPerDomainReportDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentsPerDomainReportDTO
     */
    fieldOfStudy?: string | null;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof StudentsPerDomainReportDTO
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {number}
     * @memberof StudentsPerDomainReportDTO
     */
    totalStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentsPerDomainReportDTO
     */
    applyingStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentsPerDomainReportDTO
     */
    applicationAcceptedStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentsPerDomainReportDTO
     */
    offerAcceptedStudents?: number;
}

export function StudentsPerDomainReportDTOFromJSON(json: any): StudentsPerDomainReportDTO {
    return StudentsPerDomainReportDTOFromJSONTyped(json, false);
}

export function StudentsPerDomainReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentsPerDomainReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fieldOfStudy': !exists(json, 'fieldOfStudy') ? undefined : json['fieldOfStudy'],
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'totalStudents': !exists(json, 'totalStudents') ? undefined : json['totalStudents'],
        'applyingStudents': !exists(json, 'applyingStudents') ? undefined : json['applyingStudents'],
        'applicationAcceptedStudents': !exists(json, 'applicationAcceptedStudents') ? undefined : json['applicationAcceptedStudents'],
        'offerAcceptedStudents': !exists(json, 'offerAcceptedStudents') ? undefined : json['offerAcceptedStudents'],
    };
}

export function StudentsPerDomainReportDTOToJSON(value?: StudentsPerDomainReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fieldOfStudy': value.fieldOfStudy,
        'year': StudentYearEnumToJSON(value.year),
        'totalStudents': value.totalStudents,
        'applyingStudents': value.applyingStudents,
        'applicationAcceptedStudents': value.applicationAcceptedStudents,
        'offerAcceptedStudents': value.offerAcceptedStudents,
    };
}

