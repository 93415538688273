import Layout from "../../Containers/Layout";
import { CompaniesView } from "../Views";

export const CompaniesPage = (props: { location: any }) => {
	return (
		<Layout>
			<CompaniesView location={props.location} />
		</Layout>
	);
};
