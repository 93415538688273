import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = (props: {
	condition: any;
	path: string;
	component: any;
}) => {
	const [role, setRole] = useState(null);

	useEffect(() => {
		function waitForRole() {
			const result = props.condition();

			if (result !== undefined) {
				setRole(result);
			} else {
				setTimeout(waitForRole, 250);
			}
		}

		waitForRole();
	}, [role, props]);

	return role === null ? (
		<div />
	) : role ? (
		<Route path={props.path} component={props.component} />
	) : (
		<Redirect to="/404" />
	);
};

export default PrivateRoute;
