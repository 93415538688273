/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UniversityAccordSigningEnum {
    Company = 'Company',
    Rector = 'Rector',
    GeneralDirector = 'GeneralDirector',
    EconomicDirector = 'EconomicDirector',
    LegalCounselor = 'LegalCounselor',
    PreventiveFinancialControl = 'PreventiveFinancialControl',
    UniversityAccordResponsible = 'UniversityAccordResponsible',
    AdministrativeAccordsResponsible = 'AdministrativeAccordsResponsible'
}

export function UniversityAccordSigningEnumFromJSON(json: any): UniversityAccordSigningEnum {
    return UniversityAccordSigningEnumFromJSONTyped(json, false);
}

export function UniversityAccordSigningEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UniversityAccordSigningEnum {
    return json as UniversityAccordSigningEnum;
}

export function UniversityAccordSigningEnumToJSON(value?: UniversityAccordSigningEnum | null): any {
    return value as any;
}

