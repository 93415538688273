/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentsPerDomainReportDTO,
    StudentsPerDomainReportDTOFromJSON,
    StudentsPerDomainReportDTOFromJSONTyped,
    StudentsPerDomainReportDTOToJSON,
} from './StudentsPerDomainReportDTO';

/**
 * 
 * @export
 * @interface StudentsPerDomainReportDTOPagedResponse
 */
export interface StudentsPerDomainReportDTOPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof StudentsPerDomainReportDTOPagedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentsPerDomainReportDTOPagedResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentsPerDomainReportDTOPagedResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<StudentsPerDomainReportDTO>}
     * @memberof StudentsPerDomainReportDTOPagedResponse
     */
    data?: Array<StudentsPerDomainReportDTO> | null;
}

export function StudentsPerDomainReportDTOPagedResponseFromJSON(json: any): StudentsPerDomainReportDTOPagedResponse {
    return StudentsPerDomainReportDTOPagedResponseFromJSONTyped(json, false);
}

export function StudentsPerDomainReportDTOPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentsPerDomainReportDTOPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(StudentsPerDomainReportDTOFromJSON)),
    };
}

export function StudentsPerDomainReportDTOPagedResponseToJSON(value?: StudentsPerDomainReportDTOPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'totalCount': value.totalCount,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(StudentsPerDomainReportDTOToJSON)),
    };
}

