/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyUniversityAccordDTOPagedResponse,
    CompanyUniversityAccordDTOPagedResponseFromJSON,
    CompanyUniversityAccordDTOPagedResponseToJSON,
    Filter,
    FilterFromJSON,
    FilterToJSON,
    UniversityAccordSigningEnum,
    UniversityAccordSigningEnumFromJSON,
    UniversityAccordSigningEnumToJSON,
    UniversityAccordStateEnum,
    UniversityAccordStateEnumFromJSON,
    UniversityAccordStateEnumToJSON,
} from '../models';

export interface ApiUniversityAccordDownloadAccordReviewIdGetRequest {
    reviewId: string;
}

export interface ApiUniversityAccordDownloadFileSignatureIdGetRequest {
    signatureId: string;
}

export interface ApiUniversityAccordDownloadReviewFileReviewIdGetRequest {
    reviewId: string;
}

export interface ApiUniversityAccordGetAllGetRequest {
    namePattern?: string;
    status?: Array<UniversityAccordStateEnum>;
    page?: number;
    pageSize?: number;
}

export interface ApiUniversityAccordGetAllToSignGetRequest {
    page?: number;
    pageSize?: number;
}

export interface ApiUniversityAccordOverwriteSignedSignatureIdPutRequest {
    signatureId: string;
    file?: Blob;
}

export interface ApiUniversityAccordRequestSignersValidationAccordIdPutRequest {
    accordId: string;
}

export interface ApiUniversityAccordReviewChangesIdPutRequest {
    id: string;
    accordAccepted?: boolean;
    file?: Blob;
    message?: string;
}

export interface ApiUniversityAccordSendReminderIdPostRequest {
    id: string;
    signer?: UniversityAccordSigningEnum;
}

export interface ApiUniversityAccordSetAccordEndDatePostRequest {
    accordId?: string;
    endDate?: Date;
}

export interface ApiUniversityAccordUploadSignedIdPostRequest {
    id: string;
    file?: Blob;
}

/**
 * 
 */
export class UniversityAccordApi extends runtime.BaseAPI {

    /**
     */
    async apiUniversityAccordDownloadAccordReviewIdGetRaw(requestParameters: ApiUniversityAccordDownloadAccordReviewIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling apiUniversityAccordDownloadAccordReviewIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/DownloadAccord/{reviewId}`.replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordDownloadAccordReviewIdGet(requestParameters: ApiUniversityAccordDownloadAccordReviewIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiUniversityAccordDownloadAccordReviewIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUniversityAccordDownloadFileSignatureIdGetRaw(requestParameters: ApiUniversityAccordDownloadFileSignatureIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.signatureId === null || requestParameters.signatureId === undefined) {
            throw new runtime.RequiredError('signatureId','Required parameter requestParameters.signatureId was null or undefined when calling apiUniversityAccordDownloadFileSignatureIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/DownloadFile/{signatureId}`.replace(`{${"signatureId"}}`, encodeURIComponent(String(requestParameters.signatureId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordDownloadFileSignatureIdGet(requestParameters: ApiUniversityAccordDownloadFileSignatureIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiUniversityAccordDownloadFileSignatureIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUniversityAccordDownloadReviewFileReviewIdGetRaw(requestParameters: ApiUniversityAccordDownloadReviewFileReviewIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling apiUniversityAccordDownloadReviewFileReviewIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/DownloadReviewFile/{reviewId}`.replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordDownloadReviewFileReviewIdGet(requestParameters: ApiUniversityAccordDownloadReviewFileReviewIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiUniversityAccordDownloadReviewFileReviewIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUniversityAccordGenerateAccordGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/GenerateAccord`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordGenerateAccordGet(initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiUniversityAccordGenerateAccordGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUniversityAccordGenerateDocxAccordGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/GenerateDocxAccord`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordGenerateDocxAccordGet(initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiUniversityAccordGenerateDocxAccordGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUniversityAccordGetAccordsFiltersGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/GetAccordsFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiUniversityAccordGetAccordsFiltersGet(initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiUniversityAccordGetAccordsFiltersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUniversityAccordGetAllGetRaw(requestParameters: ApiUniversityAccordGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUniversityAccordDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.status) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUniversityAccordDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUniversityAccordGetAllGet(requestParameters: ApiUniversityAccordGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<CompanyUniversityAccordDTOPagedResponse> {
        const response = await this.apiUniversityAccordGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUniversityAccordGetAllToSignGetRaw(requestParameters: ApiUniversityAccordGetAllToSignGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUniversityAccordDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/GetAllToSign`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUniversityAccordDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUniversityAccordGetAllToSignGet(requestParameters: ApiUniversityAccordGetAllToSignGetRequest = {}, initOverrides?: RequestInit): Promise<CompanyUniversityAccordDTOPagedResponse> {
        const response = await this.apiUniversityAccordGetAllToSignGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUniversityAccordOverwriteSignedSignatureIdPutRaw(requestParameters: ApiUniversityAccordOverwriteSignedSignatureIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signatureId === null || requestParameters.signatureId === undefined) {
            throw new runtime.RequiredError('signatureId','Required parameter requestParameters.signatureId was null or undefined when calling apiUniversityAccordOverwriteSignedSignatureIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/UniversityAccord/OverwriteSigned/{signatureId}`.replace(`{${"signatureId"}}`, encodeURIComponent(String(requestParameters.signatureId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordOverwriteSignedSignatureIdPut(requestParameters: ApiUniversityAccordOverwriteSignedSignatureIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUniversityAccordOverwriteSignedSignatureIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUniversityAccordRequestSignersValidationAccordIdPutRaw(requestParameters: ApiUniversityAccordRequestSignersValidationAccordIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accordId === null || requestParameters.accordId === undefined) {
            throw new runtime.RequiredError('accordId','Required parameter requestParameters.accordId was null or undefined when calling apiUniversityAccordRequestSignersValidationAccordIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/RequestSignersValidation/{accordId}`.replace(`{${"accordId"}}`, encodeURIComponent(String(requestParameters.accordId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordRequestSignersValidationAccordIdPut(requestParameters: ApiUniversityAccordRequestSignersValidationAccordIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUniversityAccordRequestSignersValidationAccordIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUniversityAccordReviewChangesIdPutRaw(requestParameters: ApiUniversityAccordReviewChangesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUniversityAccordReviewChangesIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.accordAccepted !== undefined) {
            queryParameters['accordAccepted'] = requestParameters.accordAccepted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        if (requestParameters.message !== undefined) {
            formParams.append('message', requestParameters.message as any);
        }

        const response = await this.request({
            path: `/api/UniversityAccord/ReviewChanges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordReviewChangesIdPut(requestParameters: ApiUniversityAccordReviewChangesIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUniversityAccordReviewChangesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUniversityAccordSendReminderIdPostRaw(requestParameters: ApiUniversityAccordSendReminderIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUniversityAccordSendReminderIdPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.signer !== undefined) {
            queryParameters['signer'] = requestParameters.signer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/SendReminder/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordSendReminderIdPost(requestParameters: ApiUniversityAccordSendReminderIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUniversityAccordSendReminderIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUniversityAccordSetAccordEndDatePostRaw(requestParameters: ApiUniversityAccordSetAccordEndDatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.accordId !== undefined) {
            queryParameters['accordId'] = requestParameters.accordId;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UniversityAccord/SetAccordEndDate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordSetAccordEndDatePost(requestParameters: ApiUniversityAccordSetAccordEndDatePostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiUniversityAccordSetAccordEndDatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUniversityAccordUploadSignedIdPostRaw(requestParameters: ApiUniversityAccordUploadSignedIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUniversityAccordUploadSignedIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/UniversityAccord/UploadSigned/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUniversityAccordUploadSignedIdPost(requestParameters: ApiUniversityAccordUploadSignedIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUniversityAccordUploadSignedIdPostRaw(requestParameters, initOverrides);
    }

}
