/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    SkillEndorsement,
    SkillEndorsementFromJSON,
    SkillEndorsementFromJSONTyped,
    SkillEndorsementToJSON,
} from './SkillEndorsement';

/**
 * 
 * @export
 * @interface UserSkill
 */
export interface UserSkill {
    /**
     * 
     * @type {string}
     * @memberof UserSkill
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserSkill
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserSkill
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserSkill
     */
    skill?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSkill
     */
    userId?: string;
    /**
     * 
     * @type {AcademicUser}
     * @memberof UserSkill
     */
    user?: AcademicUser;
    /**
     * 
     * @type {Array<SkillEndorsement>}
     * @memberof UserSkill
     */
    skillEndorsements?: Array<SkillEndorsement> | null;
    /**
     * 
     * @type {number}
     * @memberof UserSkill
     */
    readonly skillEndorsementCount?: number;
}

export function UserSkillFromJSON(json: any): UserSkill {
    return UserSkillFromJSONTyped(json, false);
}

export function UserSkillFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSkill {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'skill': !exists(json, 'skill') ? undefined : json['skill'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : AcademicUserFromJSON(json['user']),
        'skillEndorsements': !exists(json, 'skillEndorsements') ? undefined : (json['skillEndorsements'] === null ? null : (json['skillEndorsements'] as Array<any>).map(SkillEndorsementFromJSON)),
        'skillEndorsementCount': !exists(json, 'skillEndorsementCount') ? undefined : json['skillEndorsementCount'],
    };
}

export function UserSkillToJSON(value?: UserSkill | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'skill': value.skill,
        'userId': value.userId,
        'user': AcademicUserToJSON(value.user),
        'skillEndorsements': value.skillEndorsements === undefined ? undefined : (value.skillEndorsements === null ? null : (value.skillEndorsements as Array<any>).map(SkillEndorsementToJSON)),
    };
}

