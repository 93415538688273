/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormTypeEnum,
    FormTypeEnumFromJSON,
    FormTypeEnumFromJSONTyped,
    FormTypeEnumToJSON,
} from './FormTypeEnum';
import {
    QuestionPrerequisiteDTO,
    QuestionPrerequisiteDTOFromJSON,
    QuestionPrerequisiteDTOFromJSONTyped,
    QuestionPrerequisiteDTOToJSON,
} from './QuestionPrerequisiteDTO';
import {
    QuestionTypeEnum,
    QuestionTypeEnumFromJSON,
    QuestionTypeEnumFromJSONTyped,
    QuestionTypeEnumToJSON,
} from './QuestionTypeEnum';

/**
 * 
 * @export
 * @interface QuestionDTO
 */
export interface QuestionDTO {
    /**
     * 
     * @type {string}
     * @memberof QuestionDTO
     */
    questionId?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionDTO
     */
    text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionDTO
     */
    orderNumber?: number;
    /**
     * 
     * @type {QuestionTypeEnum}
     * @memberof QuestionDTO
     */
    questionType?: QuestionTypeEnum;
    /**
     * 
     * @type {FormTypeEnum}
     * @memberof QuestionDTO
     */
    formType?: FormTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionDTO
     */
    answers?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionDTO
     */
    initialValue?: string | null;
    /**
     * 
     * @type {Array<QuestionPrerequisiteDTO>}
     * @memberof QuestionDTO
     */
    prerequisites?: Array<QuestionPrerequisiteDTO> | null;
}

export function QuestionDTOFromJSON(json: any): QuestionDTO {
    return QuestionDTOFromJSONTyped(json, false);
}

export function QuestionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': !exists(json, 'questionId') ? undefined : json['questionId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'questionType': !exists(json, 'questionType') ? undefined : QuestionTypeEnumFromJSON(json['questionType']),
        'formType': !exists(json, 'formType') ? undefined : FormTypeEnumFromJSON(json['formType']),
        'answers': !exists(json, 'answers') ? undefined : json['answers'],
        'initialValue': !exists(json, 'initialValue') ? undefined : json['initialValue'],
        'prerequisites': !exists(json, 'prerequisites') ? undefined : (json['prerequisites'] === null ? null : (json['prerequisites'] as Array<any>).map(QuestionPrerequisiteDTOFromJSON)),
    };
}

export function QuestionDTOToJSON(value?: QuestionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionId': value.questionId,
        'name': value.name,
        'text': value.text,
        'orderNumber': value.orderNumber,
        'questionType': QuestionTypeEnumToJSON(value.questionType),
        'formType': FormTypeEnumToJSON(value.formType),
        'answers': value.answers,
        'initialValue': value.initialValue,
        'prerequisites': value.prerequisites === undefined ? undefined : (value.prerequisites === null ? null : (value.prerequisites as Array<any>).map(QuestionPrerequisiteDTOToJSON)),
    };
}

