/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternshipType,
    InternshipTypeFromJSON,
    InternshipTypeFromJSONTyped,
    InternshipTypeToJSON,
} from './InternshipType';
import {
    PracticeCertificateState,
    PracticeCertificateStateFromJSON,
    PracticeCertificateStateFromJSONTyped,
    PracticeCertificateStateToJSON,
} from './PracticeCertificateState';
import {
    PracticeQualificationDTO,
    PracticeQualificationDTOFromJSON,
    PracticeQualificationDTOFromJSONTyped,
    PracticeQualificationDTOToJSON,
} from './PracticeQualificationDTO';

/**
 * 
 * @export
 * @interface PracticeCertificateDTO
 */
export interface PracticeCertificateDTO {
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificateDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof PracticeCertificateDTO
     */
    totalWorkHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PracticeCertificateDTO
     */
    workHoursPerDay?: number;
    /**
     * 
     * @type {Array<PracticeQualificationDTO>}
     * @memberof PracticeCertificateDTO
     */
    qualifiers?: Array<PracticeQualificationDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificateDTO
     */
    observations?: string | null;
    /**
     * 
     * @type {InternshipType}
     * @memberof PracticeCertificateDTO
     */
    internshipType?: InternshipType;
    /**
     * 
     * @type {PracticeCertificateState}
     * @memberof PracticeCertificateDTO
     */
    state?: PracticeCertificateState;
}

export function PracticeCertificateDTOFromJSON(json: any): PracticeCertificateDTO {
    return PracticeCertificateDTOFromJSONTyped(json, false);
}

export function PracticeCertificateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeCertificateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'totalWorkHours': !exists(json, 'totalWorkHours') ? undefined : json['totalWorkHours'],
        'workHoursPerDay': !exists(json, 'workHoursPerDay') ? undefined : json['workHoursPerDay'],
        'qualifiers': !exists(json, 'qualifiers') ? undefined : (json['qualifiers'] === null ? null : (json['qualifiers'] as Array<any>).map(PracticeQualificationDTOFromJSON)),
        'observations': !exists(json, 'observations') ? undefined : json['observations'],
        'internshipType': !exists(json, 'internshipType') ? undefined : InternshipTypeFromJSON(json['internshipType']),
        'state': !exists(json, 'state') ? undefined : PracticeCertificateStateFromJSON(json['state']),
    };
}

export function PracticeCertificateDTOToJSON(value?: PracticeCertificateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'totalWorkHours': value.totalWorkHours,
        'workHoursPerDay': value.workHoursPerDay,
        'qualifiers': value.qualifiers === undefined ? undefined : (value.qualifiers === null ? null : (value.qualifiers as Array<any>).map(PracticeQualificationDTOToJSON)),
        'observations': value.observations,
        'internshipType': InternshipTypeToJSON(value.internshipType),
        'state': PracticeCertificateStateToJSON(value.state),
    };
}

