import { Avatar, Button, Col, Row, Spin, Typography } from "antd";
import Layout from "../../Containers/Layout";
import SkillEndorsements from "./SkillEndorsements";
import Appreciations from "./Appreciations";
import { useDispatch, useSelector } from "react-redux";
import {
	selectLoading,
	selectStudent,
	selectStudentId,
	studentGetSucceeded,
	studentLoad,
} from "./StudentSlice";
import { useEffect, useState } from "react";
import { getStudentById } from "../../Requests/academic-user-requests";
import { StatusCodes } from "http-status-codes";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { Link } from "react-router-dom";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	getUserId,
	useIsCompany,
	useIsProfessor,
	useIsStudent,
} from "../../utils/utilFunctions";
import PersonalDataModal from "./PersonalDataModal";
import { useQuery } from "react-query";
import { getCurrentStudentStudyProgram } from "../../Requests/student-study-requests";
import { getStudentCurrentStudyProgram } from "../../utils/reactQueriesConstants";

const Student = () => {
	const { t, i18n } = useTranslation();
	const isStudent = useIsStudent();
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const student = useSelector(selectStudent);
	const studentId = useSelector(selectStudentId);
	const loading = useSelector(selectLoading);
	const dispatch = useDispatch();
	const [personalData, setPersonalData] = useState("");

	const { data: currentStudyProgram } = useQuery(
		[getStudentCurrentStudyProgram],
		() => getCurrentStudentStudyProgram(),
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (!loading && !student && studentId) {
			dispatch(studentLoad());
			getStudentById(studentId)
				.then((data) => {
					setPersonalData(data.studentPersonalDataId!);
					dispatch(studentGetSucceeded(data));
				})
				.catch((ex) => {
					if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
						openNotification(
							t("account.error"),
							t("account.notFoundStudent"),
							NOTIFICATION_TYPES.ERROR
						);
					} else {
						openNotification(
							t("account.error"),
							t("account.unknownError"),
							NOTIFICATION_TYPES.ERROR
						);
					}
				});
		}
	}, [loading, student, studentId, dispatch, t]);

	return (
		<Layout>
			<Spin size="large" spinning={loading} tip={t("account.loading")}>
				<Row gutter={[20, 20]} style={{ marginTop: "2%" }}>
					<Col span={window.innerWidth > 1215 ? 5 : 24}>
						{student?.fullResImage || student?.lowResImage ? (
							<Avatar
								size={250}
								src={student?.fullResImage ?? student?.lowResImage}
							/>
						) : (
							<Avatar
								size={250}
								icon={
									<FontAwesomeIcon
										icon={solid("user")}
										title={t("account.userPicture")}
									/>
								}
							/>
						)}
						{isStudent && (
							<Row justify={"center"}>
								<PersonalDataModal type={!personalData ? "add" : "edit"} />
							</Row>
						)}
					</Col>
					<Col span={window.innerWidth > 1215 ? 19 : 24}>
						<Row
							gutter={[20, 10]}
							justify="start"
							style={{ textAlign: "left" }}
						>
							<Col span={24}>
								<Typography
									style={{
										fontSize: 25,
										float: "left",
										marginRight: "5%",
										fontWeight: "bold",
									}}
								>
									<Typography.Text
										strong={true}
										style={{ color: theme.secondColor }}
									>
										{t("header.profile")} &nbsp;&nbsp;&nbsp;
										{isStudent ? (
											<Link to="/contul-meu" title={t("account.editProfile")}>
												<FontAwesomeIcon
													icon={solid("pen-to-square")}
													style={{
														width: "1.2em",
														height: "1.2em",
														color: theme.secondColor,
														cursor: "pointer",
													}}
												/>
											</Link>
										) : null}{" "}
									</Typography.Text>
								</Typography>
							</Col>
							<Col span={24}>
								<Typography
									style={{ fontSize: 23, float: "left", marginRight: "5%" }}
								>
									<Typography.Text strong={true}>
										{student?.name}
									</Typography.Text>
								</Typography>
							</Col>
						</Row>
						<div style={{ height: "5%" }} />
						<Row gutter={[10, 4]} justify="start" style={{ textAlign: "left" }}>
							{student?.cvUrl && (
								<Col span={window.innerWidth > 1215 ? 1 : 3}>
									<Button
										type="text"
										href={student?.cvUrl}
										target="_blank"
										rel="noreferrer"
										style={{
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										title={"CV"}
									>
										<FontAwesomeIcon
											icon={solid("file-lines")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
											}}
										/>
									</Button>
								</Col>
							)}
							{student?.linkedInUrl && (
								<Col span={window.innerWidth > 1215 ? 1 : 3}>
									<Button
										type="text"
										href={student?.linkedInUrl!}
										target="_blank"
										rel="noreferrer"
										style={{
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										title={t("students.linkedinUrl")}
									>
										<FontAwesomeIcon
											icon={brands("linkedin")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
											}}
										/>
									</Button>
								</Col>
							)}
							{student?.gitUrl && (
								<Col span={window.innerWidth > 1215 ? 1 : 3}>
									<Button
										type="text"
										href={student?.gitUrl}
										target="_blank"
										rel="noreferrer"
										style={{
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										title={t("students.githubUrl")}
									>
										<FontAwesomeIcon
											icon={solid("code-branch")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
											}}
										/>
									</Button>
								</Col>
							)}
							{student?.email && !isCompany && student.id !== getUserId() && (
								<Col span={window.innerWidth > 1215 ? 1 : 3}>
									<Button
										type="text"
										href={
											"https://teams.microsoft.com/l/chat/0/0?users=" +
											student?.email
										}
										target="_blank"
										rel="noreferrer"
										style={{
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										title={t("students.teams")}
									>
										<FontAwesomeIcon
											icon={solid("comment")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
											}}
										/>
									</Button>
								</Col>
							)}
							{student?.phone && (
								<Col span={24}>
									<Button
										type="text"
										href={"tel:" + student?.phone}
										style={{
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										title={t("students.phone")}
									>
										<FontAwesomeIcon
											icon={solid("phone")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
											}}
										/>
										<Typography style={{ fontSize: 20, float: "left" }}>
											<Typography.Text strong={true}>
												{student?.phone}
											</Typography.Text>
										</Typography>
									</Button>
								</Col>
							)}
							{student?.email && (
								<Col span={24}>
									<Button
										type="text"
										href={"mailto:" + student?.email}
										style={{
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										title={t("students.email")}
									>
										<FontAwesomeIcon
											icon={solid("envelope")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
											}}
										/>
										<Typography style={{ fontSize: 20, float: "left" }}>
											<Typography.Text strong={true}>
												{student?.email}
											</Typography.Text>
										</Typography>
									</Button>
									<Button
										type="text"
										style={{
											marginLeft: "10px",
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										onClick={() => {
											navigator.clipboard
												.writeText(student?.email || "")
												.then(() => {
													openNotification(
														t("profile.emailCopied"),
														t("profile.emailCopiedMessage"),
														NOTIFICATION_TYPES.SUCCESS
													);
												});
										}}
									>
										<FontAwesomeIcon
											icon={solid("copy")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
												marginBottom: "10px",
											}}
											title={t("account.copyToClipboard")}
										/>
									</Button>
								</Col>
							)}
							{student?.personalEmail && (
								<Col span={24}>
									<Button
										type="text"
										href={"mailto:" + student?.personalEmail}
										style={{
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										title={t("students.personalEmail")}
									>
										<FontAwesomeIcon
											icon={solid("envelope")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
											}}
										/>
										<Typography style={{ fontSize: 20, float: "left" }}>
											<Typography.Text strong={true}>
												{student?.personalEmail}
											</Typography.Text>
										</Typography>
									</Button>
									<Button
										type="text"
										style={{
											marginLeft: "10px",
											backgroundColor: "transparent",
											float: "left",
											padding: "0",
										}}
										onClick={() => {
											navigator.clipboard
												.writeText(student?.personalEmail || "")
												.then(() => {
													openNotification(
														t("profile.emailCopied"),
														t("profile.emailCopiedMessage"),
														NOTIFICATION_TYPES.SUCCESS
													);
												});
										}}
									>
										<FontAwesomeIcon
											icon={solid("copy")}
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "10px",
												marginBottom: "10px",
											}}
											title={t("account.copyToClipboard")}
										/>
									</Button>
								</Col>
							)}
						</Row>
						<Row gutter={[10, 20]} justify="start">
							<Col span={24}>
								<Typography style={{ fontSize: 20, float: "left" }}>
									<Typography.Text strong={true}>
										{i18n.language === "ro"
											? student?.facultyNameRo
											: student?.facultyNameEn}
									</Typography.Text>
								</Typography>
							</Col>
						</Row>
						<Row gutter={[10, 20]} justify="start">
							<Col span={24}>
								<Typography style={{ fontSize: 20, float: "left" }}>
									<Typography.Text strong={true}>
										{currentStudyProgram?.studyProgram?.name}
									</Typography.Text>
								</Typography>
							</Col>
						</Row>
						<Row gutter={[10, 20]} justify="start">
							<Col span={24}>
								<Typography style={{ fontSize: 20, float: "left" }}>
									<Typography.Text strong={true}>
										{student?.group}
									</Typography.Text>
								</Typography>
							</Col>
						</Row>
						<Row gutter={[10, 20]} justify="start">
							<Col span={24}>
								<Typography style={{ fontSize: 20, float: "left" }}>
									<Typography.Text strong={true}>
										{t("account.year") + ": " + t("account." + student?.year)}
									</Typography.Text>
								</Typography>
							</Col>
						</Row>
					</Col>
					{student?.userSkills && student?.userSkills.length > 0 && (
						<Col
							span={window.innerWidth > 1215 ? 10 : 24}
							style={{ alignContent: "center" }}
						>
							<Typography
								style={{
									fontSize: 25,
									float: "left",
									marginRight: "5%",
									paddingLeft: "15px",
								}}
							>
								<Typography.Text strong={true}>
									{t("profile.skills")}
								</Typography.Text>
							</Typography>
							<SkillEndorsements
								skills={student?.userSkills}
								enableButtons={isProfessor || false}
							/>
						</Col>
					)}
				</Row>
				<br />
				<br />
				<Row gutter={[0, 10]}>
					<div
						style={{ fontSize: 20, textAlign: "left", fontStyle: "italic" }}
						dangerouslySetInnerHTML={{ __html: student?.description || "" }}
					/>
				</Row>
				<Appreciations />
				<br />
			</Spin>
		</Layout>
	);
};

export default Student;
