/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudentTypeCountDTO
 */
export interface StudentTypeCountDTO {
    /**
     * 
     * @type {number}
     * @memberof StudentTypeCountDTO
     */
    applicants?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentTypeCountDTO
     */
    trainees?: number;
}

export function StudentTypeCountDTOFromJSON(json: any): StudentTypeCountDTO {
    return StudentTypeCountDTOFromJSONTyped(json, false);
}

export function StudentTypeCountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentTypeCountDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicants': !exists(json, 'applicants') ? undefined : json['applicants'],
        'trainees': !exists(json, 'trainees') ? undefined : json['trainees'],
    };
}

export function StudentTypeCountDTOToJSON(value?: StudentTypeCountDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicants': value.applicants,
        'trainees': value.trainees,
    };
}

