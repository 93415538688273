import { Avatar, Col, List, Row, Switch } from "antd";
import styles from "./ResearchOffers.module.scss";
import OfferActions from "./OfferActions";
import {
	isUserLogged,
	useIsCompany,
	useIsDepartmentDirector,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudent,
	useIsStudyProgramCoordinator,
} from "../../utils/utilFunctions";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import {
	CompanyInternshipState,
	InternshipDTO,
	InternshipEngagementType,
	InternshipType,
	ResearchProposalDTO,
	ResearchProposalType,
	Role,
} from "../../Api";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { PropsWithChildren } from "react";
import { OfferListProps } from "../../PropsLists/OfferListProps";
import { useOfferListController } from "./OfferList.controller";

const OffersList = (props: PropsWithChildren<OfferListProps>) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isCompany = useIsCompany();

	const { state, actions } = useOfferListController(props);

	let locale = {
		emptyText: t("tableText.noOffers"),
	};

	return (
		<List
			locale={locale}
			itemLayout="vertical"
			size="large"
			pagination={{
				total: props.data?.totalCount,
				current: props.currentPage,
				pageSize: props.pageSize,
				pageSizeOptions: ["10", "20", "50"],
				showSizeChanger: true,
				locale: { items_per_page: t("configuration.pagination") },
				position: "both",
				hideOnSinglePage: props.data?.totalCount
					? props.data?.totalCount <= 10
					: true,
				onChange: props.handleListChange,
			}}
			loading={props.loading && !props.data}
			dataSource={props.data?.data || []}
			renderItem={(item: ResearchProposalDTO) => (
				<List.Item
					className={styles.ListItem}
					style={{ border: "1px solid lightgray" }}
					key={item.id}
					actions={[
						<OfferActions item={item} activeTab={props.activeTab} />,
					].concat(
						isUserLogged()
							? [
									<div className={styles.buttonsContainer}>
										<Row>
											{(isProfessor || isCompany) && props.activeTab === "1" ? (
												<div className={styles.alignmentContainer}>
													<CustomButton
														shape="circle"
														type="text"
														paddingvertical={"19px"}
														title={t("internships.edit")}
														style={{
															padding: "8px 0px",
															background: "none",
															color: theme.black,
															marginRight: "10%",
															marginTop: "0",
															boxShadow: "none",
															fontSize: "14px",
															height: "100%",
														}}
													>
														<Link
															to={{
																pathname:
																	"/editare-propunere-cercetare/" + item.id,
																state: {
																	activeTab: props.activeTab,
																	currentPage: props.currentPage,
																	pageSize: props.pageSize,
																	filters: props.checkedKeys,
																	searchTerm: props.searchTerm,
																	sortDirection: props.sortDirection,
																	sortField: props.sortField,
																},
															}}
														>
															<FontAwesomeIcon icon={solid("edit")} /> &nbsp;
															{t("internships.edit")}
														</Link>
													</CustomButton>
												</div>
											) : null}
											{(isProfessor || isCompany) && props.activeTab === "1" ? (
												<div className={styles.alignmentContainer}>
													{/* <CustomButton
                                                fontSize={"0.9rem"}
                                                style={{ background: 'none', color: theme.black, marginRight: '1rem', marginTop: '0', boxShadow: 'none', paddingLeft: "3px", fontSize: '14px' }}
                                                onClick={() => {
                                                    actions.setSelectedInternshipId(item.id);
                                                    actions.setDeleteModalVisibility(true);
                                                }}
                                                title={item.enrolledStudentsCount > 0 ? t('internships.deletionNotAllowed') : t('internships.deleteInternship')}
                                                icon={<FontAwesomeIcon icon={solid('trash')} style={{ paddingRight: "5%" }}/>}
                                                shape={"circle"}
                                                type={"text"}
                                                disabled={item.enrolledStudentsCount > 0}
                                            >
                                                {t('internships.deleteInternship')}
                                            </CustomButton> */}
													{/* <ConfirmationModal
                                                modalText={t('internships.deleteInternshipMessage')}
                                                handleFunction={actions.deleteEntry}
                                                modalVisibility={state.deleteModalVisibility}
                                                title=''
                                                changeModalVisibility={() => actions.setDeleteModalVisibility(false)}
                                                spinning={state.spinning}
                                            /> */}
												</div>
											) : null}
											{/* {(isProfessor || isCompany) && props.activeTab === '1' ?
                                        <div className={styles.alignmentContainer}>
                                            <CustomButton
                                                paddingvertical={"19px"}
                                                shape="circle"
                                                type="text"
                                                style={{ padding: "8px 0px", background: 'none', color: theme.black, marginRight: '10%', marginTop: '0', boxShadow: 'none', fontSize: '14px', height: '100%' }}
                                                title={t('internships.applicants')}
                                            >
                                                <Link to={{ pathname: '/aplicanti', state: { internshipId: item.internshipId, internshipName: item.internshipName } }}>
                                                    <FontAwesomeIcon icon={solid('users')} /> &nbsp;{t('internships.applicants')}
                                                </Link>
                                            </CustomButton>
                                        </div> : null} */}
										</Row>
									</div>,
							  ]
							: []
					)}
					// adds content to the right of the list item, can be used for interaction buttons for professor
					// and company (i.e. delete, edit, view applicants)
					// extra={

					// }
				>
					<List.Item.Meta
						className={styles.listItemMeta}
						avatar={
							<Link
								to={{
									pathname: `/profil`,
									state: {
										id: isProfessor ? item.professorId : item.companyId,
										userType:
											item.type === ResearchProposalType.ByProfessor
												? Role.Professor
												: Role.Company, // TBD check here
										filters: props.checkedKeys,
										searchTerm: props.searchTerm,
										sortField: props.sortField,
										sortDirection: props.sortDirection,
										currentPage: props.currentPage,
										pageSize: props.pageSize,
										activeTab: props.activeTab,
										origin: props.location.pathname,
									},
								}}
							>
								<Row>
									{item.type === ResearchProposalType.ByCompany && (
										<Col span={24} className={styles.customCol}>
											<Avatar src={item.recruiterAvatar} />
										</Col>
									)}
									{item.type === ResearchProposalType.ByProfessor && (
										<Col
											span={24}
											className={styles.customCol}
											style={{ width: 90, textAlign: "center" }}
										>
											<p>
												{item.type === ResearchProposalType.ByProfessor
													? t("abbreviations." + item.professionalTitle) + " "
													: ""}{" "}
												{item.recruiterName}
											</p>
										</Col>
									)}
								</Row>
							</Link>
						}
						title={
							<Row style={{ width: "100%" }}>
								<Col span={22} style={{ display: "flex" }}>
									<Link
										to={{
											pathname: "/propunere-cercetare/" + item.id,
											search: "?type=" + item.type,
											state: {
												id: item.id,
												type: item.type,
												filters: props.checkedKeys,
												searchTerm: props.searchTerm,
												sortField: props.sortField,
												sortDirection: props.sortDirection,
												currentPage: props.currentPage,
												pageSize: props.pageSize,
												activeTab: props.activeTab,
												origin: props.location.pathname,
											},
										}}
										onClick={() => actions.updateViewCount(item.id, item.type)}
									>
										{item.researchProposalName}
									</Link>
								</Col>
							</Row>
						}
						description={item.keywords?.join(", ")}
					/>
					{item.shortDescription}
				</List.Item>
			)}
		/>
	);
};

export default OffersList;
