/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PracticeNotebook,
    PracticeNotebookFromJSON,
    PracticeNotebookFromJSONTyped,
    PracticeNotebookToJSON,
} from './PracticeNotebook';

/**
 * 
 * @export
 * @interface PracticalActivity
 */
export interface PracticalActivity {
    /**
     * 
     * @type {string}
     * @memberof PracticalActivity
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PracticalActivity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PracticalActivity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PracticalActivity
     */
    week?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticalActivity
     */
    activity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticalActivity
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticalActivity
     */
    practiceNotebookId?: string;
    /**
     * 
     * @type {PracticeNotebook}
     * @memberof PracticalActivity
     */
    practiceNotebook?: PracticeNotebook;
}

export function PracticalActivityFromJSON(json: any): PracticalActivity {
    return PracticalActivityFromJSONTyped(json, false);
}

export function PracticalActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticalActivity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'week': !exists(json, 'week') ? undefined : json['week'],
        'activity': !exists(json, 'activity') ? undefined : json['activity'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'practiceNotebookId': !exists(json, 'practiceNotebookId') ? undefined : json['practiceNotebookId'],
        'practiceNotebook': !exists(json, 'practiceNotebook') ? undefined : PracticeNotebookFromJSON(json['practiceNotebook']),
    };
}

export function PracticalActivityToJSON(value?: PracticalActivity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'week': value.week,
        'activity': value.activity,
        'description': value.description,
        'practiceNotebookId': value.practiceNotebookId,
        'practiceNotebook': PracticeNotebookToJSON(value.practiceNotebook),
    };
}

