/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FormTypeEnum {
    Default = 'Default',
    BachelorMasterStudentForm = 'BachelorMasterStudentForm',
    PhdStudentForm = 'PhdStudentForm',
    ProfessorForm = 'ProfessorForm',
    CompanyForm = 'CompanyForm',
    GraduateForm = 'GraduateForm'
}

export function FormTypeEnumFromJSON(json: any): FormTypeEnum {
    return FormTypeEnumFromJSONTyped(json, false);
}

export function FormTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormTypeEnum {
    return json as FormTypeEnum;
}

export function FormTypeEnumToJSON(value?: FormTypeEnum | null): any {
    return value as any;
}

