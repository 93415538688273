/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserToJSON,
    AcademicUserGDPRDataDTO,
    AcademicUserGDPRDataDTOFromJSON,
    AcademicUserGDPRDataDTOToJSON,
    AcademicUserUpdateDTO,
    AcademicUserUpdateDTOFromJSON,
    AcademicUserUpdateDTOToJSON,
    AcademicUserUpdateRoleDTO,
    AcademicUserUpdateRoleDTOFromJSON,
    AcademicUserUpdateRoleDTOToJSON,
    ExtendedViewStudentDTO,
    ExtendedViewStudentDTOFromJSON,
    ExtendedViewStudentDTOToJSON,
    Filter,
    FilterFromJSON,
    FilterToJSON,
    ProfessorDTO,
    ProfessorDTOFromJSON,
    ProfessorDTOToJSON,
    ProfessorDTOPagedResponse,
    ProfessorDTOPagedResponseFromJSON,
    ProfessorDTOPagedResponseToJSON,
    RequestResponse,
    RequestResponseFromJSON,
    RequestResponseToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
    SimpleViewAcademicUserDTO,
    SimpleViewAcademicUserDTOFromJSON,
    SimpleViewAcademicUserDTOToJSON,
    SimpleViewAcademicUserDTOPagedResponse,
    SimpleViewAcademicUserDTOPagedResponseFromJSON,
    SimpleViewAcademicUserDTOPagedResponseToJSON,
    SimpleViewStudentDTO,
    SimpleViewStudentDTOFromJSON,
    SimpleViewStudentDTOToJSON,
    SimpleViewStudentDTOPagedResponse,
    SimpleViewStudentDTOPagedResponseFromJSON,
    SimpleViewStudentDTOPagedResponseToJSON,
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumToJSON,
} from '../models';

export interface ApiAcademicUserAcceptGDPRPutRequest {
    hasAcceptedPublicProfile?: boolean;
}

export interface ApiAcademicUserAddSupervisedStudyProgramSupervisorIdPutRequest {
    supervisorId: string;
    body?: string;
}

export interface ApiAcademicUserChangeDepartmentForStudentIdPutRequest {
    id: string;
    body?: string;
}

export interface ApiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDeleteRequest {
    supervisorId: string;
    body?: string;
}

export interface ApiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRequest {
    departmentId: string;
}

export interface ApiAcademicUserGetAcademicUsersNonStudentsGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    studyPrograms?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAcceptGDPRDateForStudentIdGetRequest {
    id: string;
}

export interface ApiAcademicUserGetAllAcademicUsersGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    studyPrograms?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAllProfessorsGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    studyPrograms?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAllStudentsForNewCompanyGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    studyPrograms?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAllStudentsGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    studyPrograms?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAllSupervisorsGetRequest {
    namePattern?: string;
}

export interface ApiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGetRequest {
    companyId: string;
    namePattern?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGetRequest {
    companyId: string;
}

export interface ApiAcademicUserGetProfessorByIdIdGetRequest {
    id: string;
}

export interface ApiAcademicUserGetProfessorFiltersGetRequest {
    language?: string;
}

export interface ApiAcademicUserGetProfessorsByIdsGetRequest {
    ids?: Array<string>;
}

export interface ApiAcademicUserGetStudentByIdIdGetRequest {
    id: string;
}

export interface ApiAcademicUserGetStudentFiltersForCompanyGetRequest {
    language?: string;
}

export interface ApiAcademicUserGetStudentFiltersGetRequest {
    language?: string;
}

export interface ApiAcademicUserGetStudentsFromDepartmentGetRequest {
    educationCycle?: string;
}

export interface ApiAcademicUserToggleOverduePracticeIdPutRequest {
    id: string;
    year?: StudentYearEnum;
}

export interface ApiAcademicUserUpdateAcademicUserRoleIdPutRequest {
    id: string;
    academicUserUpdateRoleDTO?: AcademicUserUpdateRoleDTO;
}

export interface ApiAcademicUserUpdateProfessorIdPutRequest {
    id: string;
    academicUserUpdateDTO?: AcademicUserUpdateDTO;
}

export interface ApiAcademicUserUpdateStudentIdPutRequest {
    id: string;
    academicUserUpdateDTO?: AcademicUserUpdateDTO;
}

/**
 * 
 */
export class AcademicUserApi extends runtime.BaseAPI {

    /**
     */
    async apiAcademicUserAcceptGDPRPutRaw(requestParameters: ApiAcademicUserAcceptGDPRPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.hasAcceptedPublicProfile !== undefined) {
            queryParameters['hasAcceptedPublicProfile'] = requestParameters.hasAcceptedPublicProfile;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/AcceptGDPR`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAcademicUserAcceptGDPRPut(requestParameters: ApiAcademicUserAcceptGDPRPutRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiAcademicUserAcceptGDPRPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAcademicUserAddSupervisedStudyProgramSupervisorIdPutRaw(requestParameters: ApiAcademicUserAddSupervisedStudyProgramSupervisorIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.supervisorId === null || requestParameters.supervisorId === undefined) {
            throw new runtime.RequiredError('supervisorId','Required parameter requestParameters.supervisorId was null or undefined when calling apiAcademicUserAddSupervisedStudyProgramSupervisorIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/AddSupervisedStudyProgram/{supervisorId}`.replace(`{${"supervisorId"}}`, encodeURIComponent(String(requestParameters.supervisorId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserAddSupervisedStudyProgramSupervisorIdPut(requestParameters: ApiAcademicUserAddSupervisedStudyProgramSupervisorIdPutRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiAcademicUserAddSupervisedStudyProgramSupervisorIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserChangeDepartmentForStudentIdPutRaw(requestParameters: ApiAcademicUserChangeDepartmentForStudentIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserChangeDepartmentForStudentIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/ChangeDepartmentForStudent/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAcademicUserChangeDepartmentForStudentIdPut(requestParameters: ApiAcademicUserChangeDepartmentForStudentIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAcademicUserChangeDepartmentForStudentIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDeleteRaw(requestParameters: ApiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.supervisorId === null || requestParameters.supervisorId === undefined) {
            throw new runtime.RequiredError('supervisorId','Required parameter requestParameters.supervisorId was null or undefined when calling apiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/DeleteSupervisedStudyProgram/{supervisorId}`.replace(`{${"supervisorId"}}`, encodeURIComponent(String(requestParameters.supervisorId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDelete(requestParameters: ApiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDeleteRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRaw(requestParameters: ApiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AcademicUser>>> {
        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAcademicUserByDepartmentId/{departmentId}`.replace(`{${"departmentId"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AcademicUserFromJSON));
    }

    /**
     */
    async apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGet(requestParameters: ApiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRequest, initOverrides?: RequestInit): Promise<Array<AcademicUser>> {
        const response = await this.apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAcademicUsersNonStudentsGetRaw(requestParameters: ApiAcademicUserGetAcademicUsersNonStudentsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewAcademicUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.studyPrograms) {
            queryParameters['StudyPrograms'] = requestParameters.studyPrograms;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAcademicUsersNonStudents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewAcademicUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAcademicUsersNonStudentsGet(requestParameters: ApiAcademicUserGetAcademicUsersNonStudentsGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewAcademicUserDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAcademicUsersNonStudentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAcceptGDPRDateForStudentIdGetRaw(requestParameters: ApiAcademicUserGetAcceptGDPRDateForStudentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AcademicUserGDPRDataDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserGetAcceptGDPRDateForStudentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAcceptGDPRDateForStudent/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AcademicUserGDPRDataDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAcceptGDPRDateForStudentIdGet(requestParameters: ApiAcademicUserGetAcceptGDPRDateForStudentIdGetRequest, initOverrides?: RequestInit): Promise<AcademicUserGDPRDataDTO> {
        const response = await this.apiAcademicUserGetAcceptGDPRDateForStudentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllAcademicUsersGetRaw(requestParameters: ApiAcademicUserGetAllAcademicUsersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewAcademicUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.studyPrograms) {
            queryParameters['StudyPrograms'] = requestParameters.studyPrograms;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllAcademicUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewAcademicUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllAcademicUsersGet(requestParameters: ApiAcademicUserGetAllAcademicUsersGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewAcademicUserDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllAcademicUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllProfessorsGetRaw(requestParameters: ApiAcademicUserGetAllProfessorsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewAcademicUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.studyPrograms) {
            queryParameters['StudyPrograms'] = requestParameters.studyPrograms;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllProfessors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewAcademicUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllProfessorsGet(requestParameters: ApiAcademicUserGetAllProfessorsGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewAcademicUserDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllProfessorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllStudentsForNewCompanyGetRaw(requestParameters: ApiAcademicUserGetAllStudentsForNewCompanyGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewStudentDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.studyPrograms) {
            queryParameters['StudyPrograms'] = requestParameters.studyPrograms;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllStudentsForNewCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewStudentDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllStudentsForNewCompanyGet(requestParameters: ApiAcademicUserGetAllStudentsForNewCompanyGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewStudentDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllStudentsForNewCompanyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllStudentsGetRaw(requestParameters: ApiAcademicUserGetAllStudentsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewStudentDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.studyPrograms) {
            queryParameters['StudyPrograms'] = requestParameters.studyPrograms;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllStudents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewStudentDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllStudentsGet(requestParameters: ApiAcademicUserGetAllStudentsGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewStudentDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllStudentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllSupervisorsGetRaw(requestParameters: ApiAcademicUserGetAllSupervisorsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SimpleViewAcademicUserDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['namePattern'] = requestParameters.namePattern;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllSupervisors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimpleViewAcademicUserDTOFromJSON));
    }

    /**
     */
    async apiAcademicUserGetAllSupervisorsGet(requestParameters: ApiAcademicUserGetAllSupervisorsGetRequest = {}, initOverrides?: RequestInit): Promise<Array<SimpleViewAcademicUserDTO>> {
        const response = await this.apiAcademicUserGetAllSupervisorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGetRaw(requestParameters: ApiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessorDTOPagedResponse>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllocatedPracticeSupervisors/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessorDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGet(requestParameters: ApiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGetRequest, initOverrides?: RequestInit): Promise<ProfessorDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGetRaw(requestParameters: ApiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProfessorDTO>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAvailablePracticeSupervisors/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfessorDTOFromJSON));
    }

    /**
     */
    async apiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGet(requestParameters: ApiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGetRequest, initOverrides?: RequestInit): Promise<Array<ProfessorDTO>> {
        const response = await this.apiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetProfessorByIdIdGetRaw(requestParameters: ApiAcademicUserGetProfessorByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserGetProfessorByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetProfessorById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessorDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetProfessorByIdIdGet(requestParameters: ApiAcademicUserGetProfessorByIdIdGetRequest, initOverrides?: RequestInit): Promise<ProfessorDTO> {
        const response = await this.apiAcademicUserGetProfessorByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetProfessorFiltersGetRaw(requestParameters: ApiAcademicUserGetProfessorFiltersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetProfessorFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiAcademicUserGetProfessorFiltersGet(requestParameters: ApiAcademicUserGetProfessorFiltersGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiAcademicUserGetProfessorFiltersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetProfessorsByIdsGetRaw(requestParameters: ApiAcademicUserGetProfessorsByIdsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProfessorDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetProfessorsByIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfessorDTOFromJSON));
    }

    /**
     */
    async apiAcademicUserGetProfessorsByIdsGet(requestParameters: ApiAcademicUserGetProfessorsByIdsGetRequest = {}, initOverrides?: RequestInit): Promise<Array<ProfessorDTO>> {
        const response = await this.apiAcademicUserGetProfessorsByIdsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetRolesForUserGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Role>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetRolesForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }

    /**
     */
    async apiAcademicUserGetRolesForUserGet(initOverrides?: RequestInit): Promise<Array<Role>> {
        const response = await this.apiAcademicUserGetRolesForUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetStudentByIdIdGetRaw(requestParameters: ApiAcademicUserGetStudentByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtendedViewStudentDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserGetStudentByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetStudentById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedViewStudentDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetStudentByIdIdGet(requestParameters: ApiAcademicUserGetStudentByIdIdGetRequest, initOverrides?: RequestInit): Promise<ExtendedViewStudentDTO> {
        const response = await this.apiAcademicUserGetStudentByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetStudentFiltersForCompanyGetRaw(requestParameters: ApiAcademicUserGetStudentFiltersForCompanyGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetStudentFiltersForCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiAcademicUserGetStudentFiltersForCompanyGet(requestParameters: ApiAcademicUserGetStudentFiltersForCompanyGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiAcademicUserGetStudentFiltersForCompanyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetStudentFiltersGetRaw(requestParameters: ApiAcademicUserGetStudentFiltersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetStudentFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiAcademicUserGetStudentFiltersGet(requestParameters: ApiAcademicUserGetStudentFiltersGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiAcademicUserGetStudentFiltersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetStudentsFromDepartmentGetRaw(requestParameters: ApiAcademicUserGetStudentsFromDepartmentGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SimpleViewStudentDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.educationCycle !== undefined) {
            queryParameters['educationCycle'] = requestParameters.educationCycle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetStudentsFromDepartment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimpleViewStudentDTOFromJSON));
    }

    /**
     */
    async apiAcademicUserGetStudentsFromDepartmentGet(requestParameters: ApiAcademicUserGetStudentsFromDepartmentGetRequest = {}, initOverrides?: RequestInit): Promise<Array<SimpleViewStudentDTO>> {
        const response = await this.apiAcademicUserGetStudentsFromDepartmentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserToggleNotificationsPutRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/ToggleNotifications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAcademicUserToggleNotificationsPut(initOverrides?: RequestInit): Promise<void> {
        await this.apiAcademicUserToggleNotificationsPutRaw(initOverrides);
    }

    /**
     */
    async apiAcademicUserToggleOverduePracticeIdPutRaw(requestParameters: ApiAcademicUserToggleOverduePracticeIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserToggleOverduePracticeIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/ToggleOverduePractice/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserToggleOverduePracticeIdPut(requestParameters: ApiAcademicUserToggleOverduePracticeIdPutRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiAcademicUserToggleOverduePracticeIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserUpdateAcademicUserRoleIdPutRaw(requestParameters: ApiAcademicUserUpdateAcademicUserRoleIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateAcademicUserRoleIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateAcademicUserRole/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateRoleDTOToJSON(requestParameters.academicUserUpdateRoleDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAcademicUserUpdateAcademicUserRoleIdPut(requestParameters: ApiAcademicUserUpdateAcademicUserRoleIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAcademicUserUpdateAcademicUserRoleIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAcademicUserUpdateProfessorIdPutRaw(requestParameters: ApiAcademicUserUpdateProfessorIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateProfessorIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateProfessor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateDTOToJSON(requestParameters.academicUserUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserUpdateProfessorIdPut(requestParameters: ApiAcademicUserUpdateProfessorIdPutRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiAcademicUserUpdateProfessorIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserUpdateStudentIdPutRaw(requestParameters: ApiAcademicUserUpdateStudentIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateStudentIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateStudent/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateDTOToJSON(requestParameters.academicUserUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserUpdateStudentIdPut(requestParameters: ApiAcademicUserUpdateStudentIdPutRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiAcademicUserUpdateStudentIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
