/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyResearchProposalAddUpdateDTO,
    CompanyResearchProposalAddUpdateDTOFromJSON,
    CompanyResearchProposalAddUpdateDTOToJSON,
    ResearchProposalDTO,
    ResearchProposalDTOFromJSON,
    ResearchProposalDTOToJSON,
    ResearchProposalDTOPagedResponse,
    ResearchProposalDTOPagedResponseFromJSON,
    ResearchProposalDTOPagedResponseToJSON,
} from '../models';

export interface ApiCompanyResearchProposalAddPostRequest {
    companyResearchProposalAddUpdateDTO?: CompanyResearchProposalAddUpdateDTO;
}

export interface ApiCompanyResearchProposalDeleteIdDeleteRequest {
    id: string;
}

export interface ApiCompanyResearchProposalGetAllGetRequest {
    page?: number;
    pageSize?: number;
}

export interface ApiCompanyResearchProposalGetByIdIdGetRequest {
    id: string;
}

export interface ApiCompanyResearchProposalUpdateIdPutRequest {
    id: string;
    companyResearchProposalAddUpdateDTO?: CompanyResearchProposalAddUpdateDTO;
}

/**
 * 
 */
export class CompanyResearchProposalApi extends runtime.BaseAPI {

    /**
     */
    async apiCompanyResearchProposalAddPostRaw(requestParameters: ApiCompanyResearchProposalAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyResearchProposal/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyResearchProposalAddUpdateDTOToJSON(requestParameters.companyResearchProposalAddUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyResearchProposalAddPost(requestParameters: ApiCompanyResearchProposalAddPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyResearchProposalAddPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyResearchProposalDeleteIdDeleteRaw(requestParameters: ApiCompanyResearchProposalDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyResearchProposalDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyResearchProposal/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyResearchProposalDeleteIdDelete(requestParameters: ApiCompanyResearchProposalDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyResearchProposalDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyResearchProposalGetAllGetRaw(requestParameters: ApiCompanyResearchProposalGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResearchProposalDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyResearchProposal/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResearchProposalDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyResearchProposalGetAllGet(requestParameters: ApiCompanyResearchProposalGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<ResearchProposalDTOPagedResponse> {
        const response = await this.apiCompanyResearchProposalGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyResearchProposalGetByIdIdGetRaw(requestParameters: ApiCompanyResearchProposalGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResearchProposalDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyResearchProposalGetByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyResearchProposal/GetById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResearchProposalDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyResearchProposalGetByIdIdGet(requestParameters: ApiCompanyResearchProposalGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<ResearchProposalDTO> {
        const response = await this.apiCompanyResearchProposalGetByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyResearchProposalUpdateIdPutRaw(requestParameters: ApiCompanyResearchProposalUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyResearchProposalUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyResearchProposal/Update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyResearchProposalAddUpdateDTOToJSON(requestParameters.companyResearchProposalAddUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyResearchProposalUpdateIdPut(requestParameters: ApiCompanyResearchProposalUpdateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyResearchProposalUpdateIdPutRaw(requestParameters, initOverrides);
    }

}
