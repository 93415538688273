import { ApplicantsViewProps } from "./ApplicantsView.types";
import { Button, Col, Drawer, Progress, Row, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	getAllKeys,
	getUserId,
	useIsCompany,
	useIsInternshipAdmin,
} from "../../../utils/utilFunctions";
import { Filter, InternsForEmployerDTO, InternType } from "../../../Api";
import { studentIdSet } from "../../Student/StudentSlice";
import {
	getInternsFilters,
	getInternsForEmployer,
	updateIsEnrollmentViewed,
} from "../../../Requests/internship-requests";
import {
	getAllApplicationTypesCount,
	getInterns,
	getTraineesFilters,
} from "../../../utils/reactQueriesConstants";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import styles from "./ApplicantsView.module.scss";
import CustomButton from "../../../CustomComponents/CustomButton";
import { theme } from "../../../theme";
import { traineesFilters } from "../../../utils/constants";
import { downloadAll, downloadExcel } from "../../../utils/downloadUtils";
import { removeDiacritics } from "../../../utils/dataUtils";
import CustomFilter from "../../../CustomComponents/CustomFilter";
import { usePagination } from "../../../Hooks";
import { FilterByTextInput } from "../../BaseComponents";
import { WithdrawApplicationModal } from "./components/modals/WithdrawApplicationModal/WithdrawApplicationModal";
import { RemoveApplicantModal } from "./components/modals/RemoveApplicantModal/RemoveApplicantModal";
import { RejectApplicantModal } from "./components/modals/RejectApplicantModal/RejectApplicantModal";
import { FinalAcceptApplicantModal } from "./components/modals/FinalAcceptApplicantModal/FinalAcceptApplicantModal";
import { AcceptApplicantModal } from "./components/modals/AcceptApplicantModal/AcceptApplicantModal";
import { ApplicantActions } from "./components/actions/ApplicantActions/ApplicantActions";

export const ApplicantsView = (props: ApplicantsViewProps) => {
	const { t, i18n } = useTranslation();
	const { currentPage, setCurrentPage, pageSize, handleTablePaginationChange } =
		usePagination(1, 10);
	const [searchTerm, setSearchTerm] = useState("");
	const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
	const [checkedKeys, setCheckedKeys] = useState(
		props.initialInternshipName === undefined
			? []
			: ["Internship@" + props.initialInternshipName]
	);
	const [internships, setInternships] = useState(
		props.initialInternshipName === undefined
			? []
			: [props.initialInternshipName]
	);
	const [years, setYears] = useState([]);
	const [faculties, setFaculties] = useState([]);
	const [recruiterStatus, setRecruiterStatus] = useState([]);
	const [studyProgram, setStudyProgram] = useState([]);
	const [engagementType, setEngagementType] = useState([]);
	const [viewStatuses, setViewStatuses] = useState([]);
	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isCompany = useIsCompany();
	const [acceptModalVisibility, setAcceptModalVisibility] = useState(false);
	const [finalAcceptModalVisibility, setFinalAcceptModalVisibility] =
		useState(false);
	const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
	const [removeModalVisibility, setRemoveModalVisibility] = useState(false);
	const [withdrawModalVisibility, setWithdrawModalVisibility] = useState(false);
	const [selectedApplicant, setSelectedApplicant] =
		useState<InternsForEmployerDTO>({});
	const [progress, setProgress] = useState(0);
	const [downloading, setDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [selectedTutor, setSelectedTutor] = useState("");

	let locale = {
		emptyText: t("tableText.noApplicants"),
	};

	const goToStudentProfile = (id: any, enrollmentId: any) => {
		updateViewApplication(enrollmentId, true);
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const updateViewApplication = (id: any, viewed: boolean) => {
		updateIsEnrollmentViewed(id, viewed).then(async () => {
			await queryClient.invalidateQueries(getInterns);
			await queryClient.invalidateQueries(getTraineesFilters);
		});
	};

	const onClose = () => {
		setVisible(false);
	};

	const showDrawer = () => {
		setVisible(true);
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const allColumns = [
		{
			title: t("students.applicationDate"),
			key: "applicationDate",
			dataIndex: "applicationDate",
		},
		{
			title: (
				<FilterByTextInput
					placeholder={t("students.name")}
					onSearch={setSearchTerm}
					style={{ width: 130 }}
				/>
			),
			key: "name",
			dataIndex: "name",
			render: (text: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<a
						onClick={() =>
							goToStudentProfile(record.id, record.internshipEnrollmentId)
						}
					>
						<b>{text}</b>
					</a>
				);
			},
		},
		{
			title: (
				<FilterByTextInput
					placeholder={t("internships.searchProposal")}
					onSearch={setSearchTerm}
					style={{ width: 200 }}
				/>
			),
			key: "internshipName",
			dataIndex: "internshipName",
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			render: (text: string | undefined, record: InternsForEmployerDTO) => (
				<span>
					{record.personalEmail !== "" && record.personalEmail !== undefined
						? record.personalEmail
						: record.email}
				</span>
			),
			hidden: window.innerWidth < 550,
		},
		{
			title: t("students.studyProgram"),
			key: "studyProgram",
			dataIndex: "studyProgram",
			hidden: window.innerWidth < 550,
		},
		{
			title: t("students.year"),
			key: "year",
			dataIndex: "year",
			hidden: window.innerWidth < 550,
			render: (_text: string | undefined, record: InternsForEmployerDTO) => {
				return t("students.filters." + record.year);
			},
		},
		{
			title: t("internships.type"),
			key: "internshipEngagementType",
			dataIndex: "internshipEngagementType",
			hidden: window.innerWidth < 650,
			render: (_text: string | undefined, record: InternsForEmployerDTO) => {
				return t("internships.filters." + record.internshipEngagementType);
			},
		},
		{
			title: t("internships.employer"),
			key: "internshipEmployer",
			dataIndex: "internshipEmployer",
			hidden: window.innerWidth < 650,
			render: (_text: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<div>
						{record.internshipEmployerId !== getUserId()
							? record.internshipEmployerName
							: t("internships.you")}
					</div>
				);
			},
		},
		{
			title: t("students.cvUrl"),
			key: "cvUrl",
			dataIndex: "cvUrl",
			hidden: window.innerWidth < 800,
			render: (cvUrl: string | undefined, record: InternsForEmployerDTO) => {
				if (!cvUrl || cvUrl === "") {
					return (
						<FontAwesomeIcon
							icon={solid("download")}
							style={{ fontSize: "15px", opacity: 0.2 }}
							title={t("students.cvNotSet")}
						/>
					);
				}

				return (
					<a
						href={cvUrl}
						onClick={() =>
							updateViewApplication(record.internshipEnrollmentId, true)
						}
					>
						<FontAwesomeIcon
							icon={solid("download")}
							style={{ fontSize: "15px" }}
							title={t("students.downloadCv")}
						/>
					</a>
				);
			},
		},
		{
			title: (
				<FontAwesomeIcon
					icon={brands("linkedin")}
					style={{ fontSize: "15px" }}
				/>
			),
			key: "linkedinUrl",
			dataIndex: "linkedinUrl",
			hidden: window.innerWidth < 800,
			render: (
				linkedinUrl: string | undefined,
				record: InternsForEmployerDTO
			) => {
				if (!linkedinUrl || linkedinUrl === "") {
					return (
						<FontAwesomeIcon
							icon={solid("arrow-right")}
							style={{ fontSize: "15px", opacity: 0.2 }}
							title={t("students.linkedinUrlNotSet")}
						/>
					);
				}

				return (
					<a
						href={linkedinUrl}
						onClick={() =>
							updateViewApplication(record.internshipEnrollmentId, true)
						}
					>
						<FontAwesomeIcon
							icon={solid("arrow-right")}
							style={{ fontSize: "15px" }}
							title={t("students.goToLinkedinUrl")}
						/>
					</a>
				);
			},
		},
		{
			title: t("students.actions"),
			key: "actions",
			dataIndex: "actions",
			render: (text: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<ApplicantActions
						record={record}
						setSelectedApplicant={setSelectedApplicant}
						setSelectedTutor={setSelectedTutor}
						setAcceptModalVisibility={setAcceptModalVisibility}
						setFinalAcceptModalVisibility={setFinalAcceptModalVisibility}
						setRejectModalVisibility={setRejectModalVisibility}
						setRemoveModalVisibility={setRemoveModalVisibility}
						setWithdrawModalVisibility={setWithdrawModalVisibility}
					/>
				);
			},
		},
	].filter((item) => !item.hidden);
	const internshipAdminColumns = allColumns.filter(
		(column) => column.key !== "applicationDate"
	);
	const columns = isCompany
		? internshipAdminColumns.filter(
				(column) => column.key !== "internshipEmployer"
		  )
		: internshipAdminColumns;
	const excelColumns = isCompany
		? allColumns.filter((column) => column.key !== "internshipEmployer")
		: allColumns;

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		[
			getInterns,
			currentPage,
			pageSize,
			searchTerm,
			internships,
			studyProgram,
			engagementType,
			years,
			faculties,
			[],
			recruiterStatus,
			viewStatuses,
			i18n.language,
		],
		() => {
			return getInternsForEmployer(
				InternType.Applicant,
				searchTerm,
				currentPage,
				pageSize,
				internships,
				studyProgram,
				engagementType,
				years,
				faculties,
				undefined,
				recruiterStatus,
				undefined,
				undefined,
				viewStatuses
			);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const invalidateApplicantsQueries = async () => {
		await queryClient.invalidateQueries(getInterns);
		await queryClient.invalidateQueries(getTraineesFilters);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	useEffect(() => {
		//props.location.state is set with the internshipId when the user clicks View Applicants in Internship Component
		//we'll use this id for filtering
		//the next line clears the id from props.location.state
		window.history.replaceState({}, document.title);
	}, [
		currentPage,
		pageSize,
		searchTerm,
		internships,
		years,
		faculties,
		recruiterStatus,
		engagementType,
		studyProgram,
		i18n.language,
	]);

	const getKey = (record: any): string => {
		return record.id + record.internshipId;
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("usersText.errorTexts.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getTraineesFilters, traineesFilters, i18n.language],
		() => {
			return getInternsFilters(
				traineesFilters,
				i18n.language,
				InternType.Applicant,
				searchTerm,
				currentPage,
				pageSize,
				internships,
				studyProgram,
				engagementType,
				years,
				faculties,
				undefined,
				recruiterStatus,
				viewStatuses
			);
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response, "/"));
			},
		}
	);

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrentPage(1);
		setInternships(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Internship@"))
				.map((e: string) => e.split("@")[1])
		);
		setYears(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Year@"))
				.map((e: string) => e.split("@")[1])
		);

		setFaculties(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Faculty@"))
				.map((e: string) => e.split("@")[1])
		);

		setRecruiterStatus(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Status@"))
				.map((e: string) => e.split("@")[1])
		);

		setStudyProgram(
			checkedKeysValue
				.filter((e: string) => e.startsWith("StudyProgram@"))
				.map((e: string) => e.split("@")[1])
		);

		setEngagementType(
			checkedKeysValue
				.filter((e: string) => e.startsWith("EngagementType@"))
				.map((e: string) => e.split("@")[1])
		);

		setViewStatuses(
			checkedKeysValue
				.filter((e: string) => e.startsWith("View@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters.map((x) => {
			if (
				x.key === "Internship" ||
				x.key === "Faculty" ||
				x.key === "StudyProgram"
			) {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: x.children,
				};
			} else {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("internships.filters." + y.title),
						}))
					),
				};
			}
		});
	};

	const downloadApplicantsList = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			t("files.applicants"),
			excelColumns
				.filter((e) => e.key !== "actions")
				.map((e) => {
					return {
						header:
							typeof e.title === "string"
								? removeDiacritics(e.title)
								: removeDiacritics(t("applicants." + e.key)),
						key: e.key.toString(),
					};
				}),
			await downloadAll(
				(currentPage, pageSize) =>
					getInternsForEmployer(
						InternType.Applicant,
						searchTerm,
						currentPage,
						pageSize,
						internships,
						studyProgram,
						engagementType,
						years,
						faculties,
						undefined,
						recruiterStatus
					),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			)
		);
	}, [
		t,
		excelColumns,
		searchTerm,
		internships,
		studyProgram,
		engagementType,
		years,
		faculties,
		recruiterStatus,
	]);

	return (
		<>
			<Row className={styles.filterButton}>
				<Button
					onClick={showDrawer}
					style={{ outline: "none", border: "none", boxShadow: "none" }}
				>
					<FontAwesomeIcon
						icon={solid("filter")}
						style={{ color: theme.secondColor }}
					/>
					&nbsp;{t("internships.filters.filter")}
				</Button>
			</Row>
			<Row>
				<Col span={4} className={styles.filters}>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Col>
				<Drawer
					className={styles.opened}
					placement={"left"}
					closable={true}
					onClose={onClose}
					visible={visible}
					key={"left"}
					maskClosable={true}
					title={t("internships.filters.filters")}
				>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Drawer>

				<Col xxl={20} xl={24} style={{ padding: "0 0 0 1%" }}>
					{isCompany && (
						<div className={styles.container}>
							<CustomButton
								onClick={downloadApplicantsList}
								marginbottom={"1em"}
								margintop={"0"}
							>
								{t("reports.download")}
							</CustomButton>
							{downloading && (
								<Progress
									percent={progress}
									status={downloadError ? "exception" : undefined}
								/>
							)}
						</div>
					)}
					<div className={styles.container}>
						<Table
							locale={locale}
							dataSource={data?.data ?? []}
							columns={isInternshipAdmin ? internshipAdminColumns : columns}
							pagination={{
								total: data?.totalCount,
								current: currentPage,
								pageSize: data?.pageSize,
								pageSizeOptions: ["10", "20", "50"],
								defaultPageSize: 10,
								hideOnSinglePage: data?.totalCount
									? data?.totalCount <= 10
									: true,
								showSizeChanger: true,
								locale: { items_per_page: t("configuration.pagination") },
								position: ["topRight", "bottomRight"],
							}}
							rowKey={getKey}
							rowClassName={(record) =>
								!record.isEnrollmentViewed ? styles.customTableRow : ""
							}
							loading={loading && !data}
							onChange={handleTablePaginationChange}
							scroll={{ x: 400 }}
						/>
					</div>
				</Col>
				<AcceptApplicantModal
					selectedApplicant={selectedApplicant}
					selectedTutor={selectedTutor}
					modalVisibility={acceptModalVisibility}
					spinning={spinning}
					invalidateApplicantsQueries={invalidateApplicantsQueries}
					setSpinning={setSpinning}
					setModalVisibility={setAcceptModalVisibility}
					setSelectedTutor={setSelectedTutor}
				/>
				<FinalAcceptApplicantModal
					selectedApplicant={selectedApplicant}
					modalVisibility={finalAcceptModalVisibility}
					spinning={spinning}
					invalidateApplicantsQueries={invalidateApplicantsQueries}
					setSpinning={setSpinning}
					setModalVisibility={setFinalAcceptModalVisibility}
				/>
				<RejectApplicantModal
					selectedApplicant={selectedApplicant}
					modalVisibility={rejectModalVisibility}
					spinning={spinning}
					invalidateApplicantsQueries={invalidateApplicantsQueries}
					setSpinning={setSpinning}
					setModalVisibility={setRejectModalVisibility}
				/>
				<RemoveApplicantModal
					selectedApplicant={selectedApplicant}
					modalVisibility={removeModalVisibility}
					spinning={spinning}
					invalidateApplicantsQueries={invalidateApplicantsQueries}
					setSpinning={setSpinning}
					setModalVisibility={setRemoveModalVisibility}
				/>
				<WithdrawApplicationModal
					selectedApplicant={selectedApplicant}
					modalVisibility={withdrawModalVisibility}
					spinning={spinning}
					invalidateApplicantsQueries={invalidateApplicantsQueries}
					setSpinning={setSpinning}
					setModalVisibility={setWithdrawModalVisibility}
				/>
			</Row>
		</>
	);
};
