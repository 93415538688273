import { Tree } from "antd";
import { filtersToDataNodes } from "../utils/dataUtils";
import { PropsWithChildren } from "react";
import { CustomFilterProps } from "../PropsLists/CustomFilterProps";

const CustomFilter = (props: PropsWithChildren<CustomFilterProps>) => {
	return (
		<div>
			{props.filters && (
				<Tree
					checkable
					onCheck={props.onCheck}
					checkedKeys={props.checkedKeys}
					expandedKeys={props.expandedKeys}
					onExpand={props.onExpand}
					selectable={false}
					treeData={filtersToDataNodes(props.translateFilters(props.filters))}
				/>
			)}
		</div>
	);
};

export default CustomFilter;
