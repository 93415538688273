/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DepartmentDTO
 */
export interface DepartmentDTO {
    /**
     * 
     * @type {string}
     * @memberof DepartmentDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDTO
     */
    readonly departmentNameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDTO
     */
    readonly departmentNameEn?: string | null;
}

export function DepartmentDTOFromJSON(json: any): DepartmentDTO {
    return DepartmentDTOFromJSONTyped(json, false);
}

export function DepartmentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepartmentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'departmentNameRo': !exists(json, 'departmentNameRo') ? undefined : json['departmentNameRo'],
        'departmentNameEn': !exists(json, 'departmentNameEn') ? undefined : json['departmentNameEn'],
    };
}

export function DepartmentDTOToJSON(value?: DepartmentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}

