import { Table, Switch, Select, Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import {
	FeatureFlags,
	FeatureFlagsCategoryEnum,
	FeatureFlagsEnum,
	FormTypeEnum,
} from "../../Api";
import Layout from "../../Containers/Layout";
import {
	getFeatureFlagsValues,
	updateFeatureFlagValue,
} from "../../Requests/feedback-feature-flags-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import styles from "./Feedback.module.scss";
import { getAllFaculties } from "../../Requests/faculty-requests";
import {
	getFaculties,
	getFacultiesDepartments,
	getFacultiesStudyPrograms,
} from "../../utils/reactQueriesConstants";
import { getStudyPrograms } from "../../Requests/student-study";
import { ColumnType } from "antd/lib/table";
import { downloadAll, downloadExcel } from "../../utils/downloadUtils";
import { getQuestionsByForm } from "../../Requests/feedback-requests";
import {
	getCompanyAnswers,
	getPhdAnswers,
	getProfessorsAnswers,
	getStudentAnswers,
} from "../../Requests/feedback-report-requests";
import { getDepartmentsForFaculties } from "../../Requests/department-requests";

const { Option } = Select;

const AdminFeatureFlagsView = () => {
	const currentYear = new Date().getFullYear();
	const { t } = useTranslation();
	const [value, setValue] = useState<string>("");
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [selectedStudentFaculties, setSelectedStudentFaculties] = useState<
		string[]
	>(["00000000-0000-0000-0000-000000000000"]);
	const [selectedPhdFaculties, setSelectedPhdFaculties] = useState<string[]>([
		"00000000-0000-0000-0000-000000000000",
	]);
	const [selectedStydyPrograms, setSelectedStydyPrograms] = useState([
		"00000000-0000-0000-0000-000000000000",
	]);
	const [selectedProfessorsFaculties, setSelectedProfessorsFaculties] =
		useState<string[]>(["00000000-0000-0000-0000-000000000000"]);
	const [selectedDepartments, setSelectedDepartments] = useState([
		"00000000-0000-0000-0000-000000000000",
	]);
	const [selectedStudentYear, setSelectedStudentYear] = useState(currentYear);
	const [selectedPhdYear, setSelectedPhdYear] = useState(currentYear);
	const [selectedProfessorsYear, setSelectedProfessorsYear] =
		useState(currentYear);
	const [selectedCompanyYear, setSelectedCompanyYear] = useState(currentYear);

	const queryClient = useQueryClient();

	const invalidateQuery = () => {
		queryClient.invalidateQueries("GetAllFeatureFlags");
	};

	const openErrorNotification = (_error: any) => {
		openNotification(
			"Eroare!",
			"Datele nu au putut fi aduse cu succes!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading } = useQuery(
		["GetAllFeatureFlags"],
		() => {
			return getFeatureFlagsValues(FeatureFlagsCategoryEnum.Feedback);
		},
		{
			onError: openErrorNotification,
		}
	);

	const toggleFeatureFlag = async (
		flagName: FeatureFlagsEnum,
		currentValue: boolean
	) => {
		setIsUpdateLoading(true);
		await updateFeatureFlagValue(flagName, !currentValue).then(() => {
			invalidateQuery();
			setIsUpdateLoading(false);
		});
	};

	const handleSelectProgram = (value: any) => {
		setSelectedStydyPrograms(value);
	};

	const handleSelectStudentFaculty = (value: any) => {
		setSelectedStudentFaculties(value);
	};

	const handleSelectPhdFaculty = (value: any) => {
		setSelectedPhdFaculties(value);
	};

	const handleSelectProfessorsFaculty = (value: any) => {
		setSelectedProfessorsFaculties(value);
	};

	const handleSelectDepartments = (value: any) => {
		setSelectedDepartments(value);
	};

	const handleStudentSelectYear = (value: any) => {
		setSelectedStudentYear(value);
	};

	const handlePhdSelectYear = (value: any) => {
		setSelectedPhdYear(value);
	};

	const handleProfessorsSelectYear = (value: any) => {
		setSelectedProfessorsYear(value);
	};

	const handleCompanySelectYear = (value: any) => {
		setSelectedCompanyYear(value);
	};

	const { data: allFacultiesList } = useQuery(
		[getFaculties],
		() => getAllFaculties("", 1, 20),
		{
			onError: (err) => {
				openErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: allStudyPrograms } = useQuery(
		[getFacultiesStudyPrograms, selectedStudentFaculties],
		() => getStudyPrograms(selectedStudentFaculties),
		{
			onError: (err) => {
				openErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: allDepartments } = useQuery(
		[getFacultiesDepartments, selectedStudentFaculties],
		() => getDepartmentsForFaculties(selectedStudentFaculties),
		{
			onError: (err) => {
				openErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const columns = [
		{
			title: "Nume flag",
			dataIndex: "featureFlag",
			key: "featureFlag",
		},
		{
			title: "Descriere",
			dataIndex: "description",
			key: "description",
		},
		{
			title: "Activ",
			dataIndex: "value",
			render: (_: any, record: FeatureFlags) => {
				return (
					<span>
						<Switch
							loading={isUpdateLoading}
							checked={record.value!}
							onChange={() => {
								toggleFeatureFlag(record.featureFlag!, record.value!);
							}}
						/>
					</span>
				);
			},
		},
	];

	const studentReportColumns: ColumnType<any>[] = [
		{
			title: "Nume Student",
			key: "studentName",
		},
		{
			title: "Facultatea",
			key: "facultyName",
		},
		{
			title: "Domeniu",
			key: "domainName",
		},
		{
			title: "Anul",
			key: "studyYear",
		},
		{
			title: "Grupa",
			key: "groupName",
		},
		{
			title: "Programul de studi",
			key: "studyProgram",
		},
	];

	const phdReportColumns: ColumnType<any>[] = [
		{
			title: "Nume Student",
			key: "studentName",
		},
		{
			title: "Facultatea",
			key: "facultyName",
		},
		{
			title: "Domeniu",
			key: "domainName",
		},
		{
			title: "Anul",
			key: "studyYear",
		},
		{
			title: "Grupa",
			key: "groupName",
		},
	];

	const professorsReportColumns: ColumnType<any>[] = [
		{
			title: "Nume Profesor",
			key: "professorName",
		},
		{
			title: "Facultatea",
			key: "facultyName",
		},
		{
			title: "Departament",
			key: "depatmentName",
		},
		{
			title: "Domeniu de cercetare",
			key: "teachingGrade",
		},
		{
			title: "Email",
			key: "email",
		},
	];

	const companyReportColumns: ColumnType<any>[] = [
		{
			title: "Nume Companie",
			key: "companyName",
		},
		{
			title: "Nume Comercial",
			key: "commercialName",
		},
		{
			title: "Domeniu de activitate",
			key: "domainOfActivity",
		},
		{
			title: "Nume utilizator",
			key: "userName",
		},
		{
			title: "Email utilizator",
			key: "userEmail",
		},
		{
			title: "Telefon utilizator",
			key: "userPhone",
		},
		{
			title: "Site",
			key: "companySite",
		},
	];

	const { data: studentQuestions } = useQuery(
		["studentQuestions"],
		() => getQuestionsByForm(FormTypeEnum.BachelorMasterStudentForm),
		{
			onError: (err) => {
				openErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: phdQuestions } = useQuery(
		["phdQuestions"],
		() => getQuestionsByForm(FormTypeEnum.PhdStudentForm),
		{
			onError: (err) => {
				openErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: profesorQuestions } = useQuery(
		["profesorQuestions"],
		() => getQuestionsByForm(FormTypeEnum.ProfessorForm),
		{
			onError: (err) => {
				openErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: companyQuestions } = useQuery(
		["companyQuestions"],
		() => getQuestionsByForm(FormTypeEnum.CompanyForm),
		{
			onError: (err) => {
				openErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	if (studentQuestions) {
		studentQuestions.forEach((question) => {
			studentReportColumns.push({
				title: question.text,
				key: "r" + question.orderNumber,
			});
		});
	}

	if (phdQuestions) {
		phdQuestions.forEach((question) => {
			phdReportColumns.push({
				title: question.text,
				key: "r" + question.orderNumber,
			});
		});
	}

	if (profesorQuestions) {
		profesorQuestions.forEach((question) => {
			professorsReportColumns.push({
				title: question.text,
				key: "r" + question.orderNumber,
			});
		});
	}

	if (companyQuestions) {
		companyQuestions.forEach((question) => {
			companyReportColumns.push({
				title: question.text,
				key: "r" + question.orderNumber,
			});
		});
	}

	const [progress, setProgress] = useState(0);
	const [downloading, setDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState(false);

	const downloadStudentsFeedbackReport = async () => {
		setDownloading(true);
		let fileName =
			"Raport Feedback Studenti - " + new Date().toLocaleDateString("ro-RO");

		return downloadExcel(
			fileName,
			studentReportColumns.map((e) => {
				return {
					header: e.title?.toString() ?? "",
					key: e.key?.toString() ?? "",
				};
			}),
			await downloadAll(
				(page, size) =>
					getStudentAnswers(
						selectedStudentYear,
						selectedStudentFaculties.length === 0
							? ["00000000-0000-0000-0000-000000000000"]
							: selectedStudentFaculties,
						selectedStydyPrograms.length === 0
							? ["00000000-0000-0000-0000-000000000000"]
							: selectedStydyPrograms,
						page,
						size
					),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification("Raportul nu a putut fi descărcat cu succes!");
				}
			)
		);
	};

	const downloadPhdFeedbackReport = async () => {
		setDownloading(true);
		let fileName =
			"Raport Feedback Studenti Doctorat - " +
			new Date().toLocaleDateString("ro-RO");

		return downloadExcel(
			fileName,
			studentReportColumns.map((e) => {
				return {
					header: e.title?.toString() ?? "",
					key: e.key?.toString() ?? "",
				};
			}),
			await downloadAll(
				(page, size) =>
					getPhdAnswers(
						selectedPhdYear,
						selectedPhdFaculties.length === 0
							? ["00000000-0000-0000-0000-000000000000"]
							: selectedPhdFaculties,
						page,
						size
					),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification("Raportul nu a putut fi descărcat cu succes!");
				}
			)
		);
	};

	const downloadProfessorsFeedbackReport = async () => {
		setDownloading(true);
		let fileName =
			"Raport Feedback Profesori - " + new Date().toLocaleDateString("ro-RO");

		return downloadExcel(
			fileName,
			professorsReportColumns.map((e) => {
				return {
					header: e.title?.toString() ?? "",
					key: e.key?.toString() ?? "",
				};
			}),
			await downloadAll(
				(page, size) =>
					getProfessorsAnswers(
						selectedProfessorsYear,
						selectedProfessorsFaculties.length === 0
							? ["00000000-0000-0000-0000-000000000000"]
							: selectedProfessorsFaculties,
						selectedDepartments.length === 0
							? ["00000000-0000-0000-0000-000000000000"]
							: selectedDepartments,
						page,
						size
					),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification("Raportul nu a putut fi descărcat cu succes!");
				}
			)
		);
	};

	const downloadCompanyFeedbackReport = async () => {
		setDownloading(true);
		let fileName =
			"Raport Feedback Companii - " + new Date().toLocaleDateString("ro-RO");

		return downloadExcel(
			fileName,
			companyReportColumns.map((e) => {
				return {
					header: e.title?.toString() ?? "",
					key: e.key?.toString() ?? "",
				};
			}),
			await downloadAll(
				(page, size) => getCompanyAnswers(selectedCompanyYear, page, size),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification("Raportul nu a putut fi descărcat cu succes!");
				}
			)
		);
	};

	const reportYears = [currentYear];
	for (let i = 1; i < 10; i++) {
		reportYears.push(currentYear - i);
	}

	return (
		<Layout>
			<div
				className={styles.container}
				style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}
			>
				<Table
					style={{ marginTop: "3%", width: "75vw" }}
					dataSource={data ?? new Array<FeatureFlags>()}
					columns={columns}
					pagination={false}
					rowKey={(record) => record.id ?? ""}
					loading={isLoading}
				/>

				<div style={{ marginTop: "30px", width: "75vw" }}>
					<label style={{ display: "flex", marginBottom: "10px" }}>
						Raport studenti
					</label>
					<div style={{ display: "flex", marginBottom: "15px" }}>
						<Select
							mode="multiple"
							style={{ flex: 1, marginRight: "15px" }}
							placeholder="Selecteaza Facultatea"
							onChange={handleSelectStudentFaculty}
						>
							{/* { [<Option key={"00000000-0000-0000-0000-000000000000"}>{"Toate"}</Option>].concat(
                            allFacultiesList?.map((option: any,) => (
                                <Option key={option.id} value={option.id}>{option.nameRo}</Option>
                            )) ?? [] )} */}
							{allFacultiesList?.map((f) => (
								<Option key={f.id} value={f.id}>
									{f.nameRo}
								</Option>
							))}
						</Select>
						<Select
							mode="multiple"
							style={{ flex: 1, marginRight: "15px" }}
							placeholder="Selecteaza programul de studiu"
							onChange={handleSelectProgram}
						>
							{allStudyPrograms?.map((sp) => (
								<Option key={sp.id} value={sp.id}>
									{sp.name}
								</Option>
							))}
						</Select>
						<Select
							style={{ flex: 1, marginRight: "15px", maxWidth: "15%" }}
							placeholder="Selecteaza anul"
							onChange={handleStudentSelectYear}
						>
							{reportYears?.map((nr) => (
								<Option key={nr} value={nr.toString()}>
									{nr}
								</Option>
							))}
						</Select>
						<Button onClick={downloadStudentsFeedbackReport}>
							Descarca raport
						</Button>
					</div>
				</div>

				<div style={{ marginTop: "30px", width: "75vw" }}>
					<label style={{ display: "flex", marginBottom: "10px" }}>
						Raport studenti doctorat
					</label>
					<div style={{ display: "flex", marginBottom: "15px" }}>
						<Select
							mode="multiple"
							style={{ flex: 1, marginRight: "15px", width: "50%" }}
							placeholder="Selecteaza Facultatea"
							onChange={handleSelectPhdFaculty}
						>
							{allFacultiesList?.map((f) => (
								<Option key={f.id} value={f.id}>
									{f.nameRo}
								</Option>
							))}
						</Select>
						<Select
							style={{ flex: 1, marginRight: "15px", maxWidth: "15%" }}
							placeholder="Selecteaza anul"
							onChange={handlePhdSelectYear}
						>
							{reportYears?.map((nr) => (
								<Option key={nr} value={nr.toString()}>
									{nr}
								</Option>
							))}
						</Select>
						<Button onClick={downloadPhdFeedbackReport}>Descarca raport</Button>
					</div>
				</div>

				<div style={{ marginTop: "30px", width: "75vw" }}>
					<label style={{ display: "flex", marginBottom: "10px" }}>
						Raport profesori
					</label>
					<div style={{ display: "flex", marginBottom: "15px" }}>
						<Select
							mode="multiple"
							style={{ flex: 1, marginRight: "15px" }}
							placeholder="Selecteaza Facultatea"
							onChange={handleSelectProfessorsFaculty}
						>
							{allFacultiesList?.map((f) => (
								<Option key={f.id} value={f.id}>
									{f.nameRo}
								</Option>
							))}
						</Select>
						<Select
							mode="multiple"
							style={{ flex: 1, marginRight: "15px" }}
							placeholder="Selecteaza departamentul"
							onChange={handleSelectDepartments}
						>
							{allDepartments?.map((sp) => (
								<Option key={sp.id} value={sp.id}>
									{sp.departmentNameRo}
								</Option>
							))}
						</Select>
						<Select
							style={{ flex: 1, marginRight: "15px", maxWidth: "15%" }}
							placeholder="Selecteaza anul"
							onChange={handleProfessorsSelectYear}
							defaultValue={selectedProfessorsYear}
						>
							{reportYears?.map((nr) => (
								<Option key={nr} value={nr.toString()}>
									{nr}
								</Option>
							))}
						</Select>
						<Button onClick={downloadProfessorsFeedbackReport}>
							Descarca raport
						</Button>
					</div>
				</div>

				<div style={{ marginTop: "30px", width: "75vw" }}>
					<label style={{ display: "flex", marginBottom: "10px" }}>
						Raport companii
					</label>
					<div
						style={{
							display: "flex",
							marginBottom: "15px",
							marginLeft: "55.5vw",
						}}
					>
						<Select
							style={{ flex: 1, marginRight: "15px" }}
							placeholder="Selecteaza anul"
							onChange={handleCompanySelectYear}
						>
							{reportYears?.map((nr) => (
								<Option key={nr} value={nr.toString()}>
									{nr}
								</Option>
							))}
						</Select>
						<Button onClick={downloadCompanyFeedbackReport}>
							Descarca raport
						</Button>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default AdminFeatureFlagsView;
