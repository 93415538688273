/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternshipEngagementType,
    InternshipEngagementTypeFromJSON,
    InternshipEngagementTypeFromJSONTyped,
    InternshipEngagementTypeToJSON,
} from './InternshipEngagementType';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface ProfessorInternshipAddUpdateDTO
 */
export interface ProfessorInternshipAddUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    period: number;
    /**
     * 
     * @type {number}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    totalPracticeHours?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    availableFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    availableTo: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    startingDate: Date;
    /**
     * 
     * @type {number}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    initialAvailablePositions: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    skillsRequired?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    category?: string | null;
    /**
     * 
     * @type {InternshipEngagementType}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    engagementType: InternshipEngagementType;
    /**
     * 
     * @type {string}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    placeId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    faculties?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    departments?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    studyPrograms?: Array<string> | null;
    /**
     * 
     * @type {Array<StudentYearEnum>}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    years?: Array<StudentYearEnum> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    coTutorIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    coordinatorId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    viewCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    isViewOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfessorInternshipAddUpdateDTO
     */
    internshipLink?: string | null;
}

export function ProfessorInternshipAddUpdateDTOFromJSON(json: any): ProfessorInternshipAddUpdateDTO {
    return ProfessorInternshipAddUpdateDTOFromJSONTyped(json, false);
}

export function ProfessorInternshipAddUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfessorInternshipAddUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'period': json['period'],
        'totalPracticeHours': !exists(json, 'totalPracticeHours') ? undefined : json['totalPracticeHours'],
        'availableFrom': (new Date(json['availableFrom'])),
        'availableTo': (new Date(json['availableTo'])),
        'startingDate': (new Date(json['startingDate'])),
        'initialAvailablePositions': json['initialAvailablePositions'],
        'skillsRequired': !exists(json, 'skillsRequired') ? undefined : json['skillsRequired'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'engagementType': InternshipEngagementTypeFromJSON(json['engagementType']),
        'location': !exists(json, 'location') ? undefined : json['location'],
        'placeId': !exists(json, 'placeId') ? undefined : json['placeId'],
        'faculties': !exists(json, 'faculties') ? undefined : json['faculties'],
        'departments': !exists(json, 'departments') ? undefined : json['departments'],
        'studyPrograms': !exists(json, 'studyPrograms') ? undefined : json['studyPrograms'],
        'years': !exists(json, 'years') ? undefined : (json['years'] === null ? null : (json['years'] as Array<any>).map(StudentYearEnumFromJSON)),
        'coTutorIds': !exists(json, 'coTutorIds') ? undefined : json['coTutorIds'],
        'coordinatorId': !exists(json, 'coordinatorId') ? undefined : json['coordinatorId'],
        'viewCount': !exists(json, 'viewCount') ? undefined : json['viewCount'],
        'isViewOnly': !exists(json, 'isViewOnly') ? undefined : json['isViewOnly'],
        'internshipLink': !exists(json, 'internshipLink') ? undefined : json['internshipLink'],
    };
}

export function ProfessorInternshipAddUpdateDTOToJSON(value?: ProfessorInternshipAddUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'shortDescription': value.shortDescription,
        'period': value.period,
        'totalPracticeHours': value.totalPracticeHours,
        'availableFrom': (value.availableFrom.toISOString()),
        'availableTo': (value.availableTo.toISOString()),
        'startingDate': (value.startingDate.toISOString()),
        'initialAvailablePositions': value.initialAvailablePositions,
        'skillsRequired': value.skillsRequired,
        'category': value.category,
        'engagementType': InternshipEngagementTypeToJSON(value.engagementType),
        'location': value.location,
        'placeId': value.placeId,
        'faculties': value.faculties,
        'departments': value.departments,
        'studyPrograms': value.studyPrograms,
        'years': value.years === undefined ? undefined : (value.years === null ? null : (value.years as Array<any>).map(StudentYearEnumToJSON)),
        'coTutorIds': value.coTutorIds,
        'coordinatorId': value.coordinatorId,
        'viewCount': value.viewCount,
        'isViewOnly': value.isViewOnly,
        'internshipLink': value.internshipLink,
    };
}

