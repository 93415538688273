import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Key } from "antd/lib/table/interface";
import {
	getAllKeys,
	getKey,
	useBeforeRender,
	useIsAdministrativeAccordsResponsible,
	useIsDean,
	useIsFacultyContactsResponsible,
	useIsRector,
} from "../../../utils/utilFunctions";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CompanyDTO, Filter, Role } from "../../../Api";
import { useQuery, useQueryClient } from "react-query";
import {
	getAllCompanies,
	getCompaniesQuery,
	getCompanyFilters,
} from "../../../utils/reactQueriesConstants";
import {
	deleteCompany,
	getCompanies,
	getCompaniesFilter,
} from "../../../Requests/company-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { Col, Dropdown, Menu, MenuProps, Row, Table, Tree } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	downloadAllCompaniesContactsReport,
	downloadCompanyContactsReport,
	downloadCompanyFacultiesAccordReport,
} from "../../../utils/downloadReportUtils";
import { getAllCompaniesForFaculty } from "../../../Requests/faculty-requests";
import CustomButton from "../../../CustomComponents/CustomButton";
import { theme } from "../../../theme";
import styles from "../../Admin/Users.module.scss";
import { filtersToDataNodes } from "../../../utils/dataUtils";
import { ActionKeyEnum, CompaniesViewProps } from "./CompaniesView.types";
import { FilterByTextInput } from "../../BaseComponents";
import { usePagination } from "../../../Hooks";
import { AddUpdateCompanyModalForm } from "../../Forms";
import { UserOutlined } from "@ant-design/icons";

export const CompaniesView = (props: CompaniesViewProps) => {
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState("");
	const {
		currentPage,
		setCurrentPage,
		pageSize,
		setPageSize,
		handleTablePaginationChange,
	} = usePagination(1, 10);
	const [states, setStates] = useState([]);
	const [documentsStatus, setDocumentsStatus] = useState([]);
	const [facultiesOfInterest, setFacultiesOfInterest] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
	const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
	const isRector = useIsRector();
	const isDean = useIsDean();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const isFacultyContactsResponsible = useIsFacultyContactsResponsible();
	const location = useLocation();
	const history = useHistory();
	const [selectedCompany, setSelectedCompany] = useState<CompanyDTO | null>(
		null
	);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const queryClient = useQueryClient();
	const invalidateCompaniesQuery = async () => {
		await queryClient.invalidateQueries(getCompaniesQuery);
	};

	let locale = {
		emptyText: t("tableText.noCompanies"),
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrentPage(1);
		setStates(
			checkedKeysValue
				.filter((e: string) => e.startsWith("State@"))
				.map((e: string) => e.split("@")[1])
		);
		setDocumentsStatus(
			checkedKeysValue
				.filter((e: string) => e.startsWith("PracticeProtocolStatus@"))
				.map((e: string) => e.split("@")[1])
		);
		setFacultiesOfInterest(
			checkedKeysValue
				.filter((e: string) => e.startsWith("FacultyOfInterest@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const getOldValues = () => {
		if (props.location.state !== undefined) {
			let { existingFilters, oldSearchTerm, currentPage, pageSize }: any =
				location.state;

			onCheck(existingFilters || checkedKeys);

			setSearchTerm(oldSearchTerm);
			setCurrentPage(currentPage);
			setPageSize(pageSize);

			history.replace({ ...history.location, state: undefined });
		}
	};

	useBeforeRender(() => getOldValues(), [location]);

	const handleDelete = (id: string) => {
		deleteCompany(id)
			.then(() => {
				invalidateCompaniesQuery();

				openNotification(
					t("companies.companyDeleted"),
					t("companies.companyDeleted"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
					openNotification(
						t("account.conflict"),
						t("companies.notFoundCompany"),
						NOTIFICATION_TYPES.ERROR
					);
				} else if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
					openNotification(
						t("account.error"),
						t("account.notAllowed"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			});
	};

	const getActions = (record: CompanyDTO): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		if (record.isContact) {
			actions.push({
				label: t("companies.edit"),
				key: ActionKeyEnum.EditCompany + "Companies",
				icon: (
					<FontAwesomeIcon
						icon={solid("pen-to-square")}
						style={{ fontSize: "15px" }}
					/>
				),
				onClick: () => {
					setSelectedCompany(record);
					setIsModalVisible(true);
					setIsEdit(true);
				},
			});

			actions.push({
				label: t("companies.delete"),
				key: ActionKeyEnum.DeleteCompany + "Companies",
				icon: (
					<FontAwesomeIcon icon={solid("trash")} style={{ fontSize: "15px" }} />
				),
				onClick: () => {
					handleDelete(record?.id!);
				},
			});
		}

		actions.push({
			label: t("companies.contacts"),
			key: ActionKeyEnum.Contacts + "Companies",
			icon: (
				<FontAwesomeIcon
					icon={solid("user-group")}
					style={{ fontSize: "15px" }}
				/>
			),
			onClick: () =>
				history.push("contacte/" + record.id, {
					currentPage,
					pageSize,
					searchTerm,
					filters: checkedKeys,
				}),
		});

		actions.push({
			label: t("companies.contactsReport"),
			key: ActionKeyEnum.ContactsReport + "Companies",
			icon: (
				<FontAwesomeIcon
					icon={solid("download")}
					style={{ fontSize: "15px" }}
				/>
			),
			onClick: () =>
				downloadCompanyContactsReport(
					t("companies.contactsReport") + ` ${record.name}`,
					t,
					searchTerm,
					record.id
				),
		});

		return actions;
	};

	const companyColumns = [
		{
			title: (
				<FilterByTextInput
					placeholder={t("companies.name")}
					onSearch={setSearchTerm}
					style={{ width: 200 }}
				/>
			),
			dataIndex: "name",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Company,
							origin: location.pathname,
							oldSearchTerm: searchTerm,
							pageSize: pageSize,
							currentPage: currentPage,
							filters: checkedKeys,
						},
					}}
				>
					<b>{record.commercialName}</b>
				</Link>
			),
		},
		{
			title: t("companies.cui"),
			dataIndex: "cui",
			key: "cui",
		},
		{
			title: t("companies.facultiesOfInterest"),
			dataIndex: "facultiesOfInterestAcronyms",
			hidden: isDean || isFacultyContactsResponsible,
			render: (_text: string | undefined, record: any) => {
				return record.facultiesOfInterestAcronyms?.join(", ");
			},
		},
		{
			title: t("companies.actions"),
			dataIndex: "actions",
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={<Menu items={getActions(record)} />}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	].filter((item) => !item.hidden);

	const openNotificationErrorFetchUsers = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: companyData, isLoading: loadingCompanies } = useQuery(
		[
			getAllCompanies,
			states,
			documentsStatus,
			facultiesOfInterest,
			currentPage,
			pageSize,
			isAdministrativeAccordsResponsible,
			isRector,
			isDean,
			isFacultyContactsResponsible,
			searchTerm,
		],
		() => {
			if (isRector || isAdministrativeAccordsResponsible) {
				return getCompanies(
					searchTerm,
					states,
					documentsStatus,
					facultiesOfInterest,
					currentPage,
					pageSize
				);
			} else if (isDean || isFacultyContactsResponsible) {
				return getAllCompaniesForFaculty(searchTerm, currentPage, pageSize);
			}
		},
		{
			onError: openNotificationErrorFetchUsers,
		}
	);

	const onExpand = (expandedKeysValue: any) => {
		setExpandedKeys(expandedKeysValue);
	};

	const translateCompanyFilters = (filters: Filter[]): Filter[] => {
		return filters.map((x) => {
			if (x.title === "FacultyOfInterest") {
				return {
					key: x.key,
					title: t("admin." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: y.title,
						}))
					),
				};
			} else {
				return {
					key: x.key,
					title: t("admin." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("admin." + y.title),
						}))
					),
				};
			}
		});
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("usersText.errorTexts.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: companyFilters } = useQuery(
		[getCompanyFilters, isAdministrativeAccordsResponsible, isRector],
		() => {
			if (isRector || isAdministrativeAccordsResponsible)
				return getCompaniesFilter();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isRector || isAdministrativeAccordsResponsible)
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
			},
		}
	);

	return (
		<Row style={{ justifyContent: "center" }}>
			{(isRector ||
				isAdministrativeAccordsResponsible ||
				isDean ||
				isFacultyContactsResponsible) && (
				<Row>
					<Col span={4}>
						<AddUpdateCompanyModalForm
							visibility={isModalVisible}
							record={selectedCompany}
							isEdit={isEdit}
							onCloseCallBack={() => {
								setIsModalVisible(false);
								setIsEdit(false);
								setSelectedCompany(null);
								return;
							}}
						/>

						<CustomButton
							backgroundcolor={theme.primaryColor}
							textcolor={theme.white}
							paddingvertical={"19px"}
							fontSize={"0.9rem"}
							boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
							marginbottom={"1em"}
							marginleft={"auto"}
							onClick={() =>
								downloadCompanyFacultiesAccordReport(
									t("companies.companyFacultiesAccordReport"),
									t
								)
							}
							title={t("companies.companyFacultiesAccordReport")}
						>
							{t("companies.companyFacultiesAccordReport")}
						</CustomButton>
						<CustomButton
							backgroundcolor={theme.primaryColor}
							textcolor={theme.white}
							paddingvertical={"19px"}
							fontSize={"0.9rem"}
							boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
							marginbottom={"1em"}
							marginleft={"auto"}
							onClick={() =>
								downloadAllCompaniesContactsReport(
									t("companies.contactsReport"),
									t
								)
							}
							title={t("companies.contactsReport")}
						>
							{t("companies.contactsReport")}
						</CustomButton>

						<div className={styles.filters}>
							{companyFilters && (
								<Tree
									checkable
									onCheck={onCheck}
									onExpand={onExpand}
									expandedKeys={expandedKeys}
									checkedKeys={checkedKeys}
									selectable={false}
									treeData={filtersToDataNodes(
										translateCompanyFilters(companyFilters)
									)}
									style={{ display: "flex", textAlign: "left" }}
								/>
							)}
						</div>
					</Col>
					<Col span={20} style={{ padding: "0 0 0 3%" }}>
						<Table
							locale={locale}
							dataSource={companyData?.data || []}
							columns={companyColumns}
							pagination={{
								total: companyData?.totalCount,
								current: currentPage,
								pageSize: companyData?.pageSize,
								pageSizeOptions: ["10", "20", "50"],
								defaultPageSize: 10,
								hideOnSinglePage: companyData?.totalCount
									? companyData?.totalCount <= 10
									: true,
								showSizeChanger: true,
								locale: { items_per_page: t("configuration.pagination") },
								position: ["topRight", "bottomRight"],
							}}
							rowKey={getKey}
							loading={loadingCompanies && !companyData}
							onChange={handleTablePaginationChange}
							scroll={{ x: 400 }}
						/>
					</Col>
				</Row>
			)}
		</Row>
	);
};
