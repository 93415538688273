/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InternshipDocumentSigningEnum {
    Student = 'Student',
    Coordinator = 'Coordinator',
    Dean = 'Dean',
    DepartmentDirector = 'DepartmentDirector',
    StudyProgramCoordinator = 'StudyProgramCoordinator'
}

export function InternshipDocumentSigningEnumFromJSON(json: any): InternshipDocumentSigningEnum {
    return InternshipDocumentSigningEnumFromJSONTyped(json, false);
}

export function InternshipDocumentSigningEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipDocumentSigningEnum {
    return json as InternshipDocumentSigningEnum;
}

export function InternshipDocumentSigningEnumToJSON(value?: InternshipDocumentSigningEnum | null): any {
    return value as any;
}

