import { useState, useEffect } from "react";
import styles from "./NavBar.module.scss";
import logo from "../../Media/UPB-Connect.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import {
	geti18nLanguage,
	getUserId,
	isUserLogged,
	useIsCompany,
	useIsDean,
	useIsStudent,
} from "../../utils/utilFunctions";
import NavBarMobile from "./NavBarMobile";
import { useDispatch } from "react-redux";
import { logout } from "../../utils/utilFunctions";
import { studentIdSet } from "../../Components/Student/StudentSlice";
import { useTranslation } from "react-i18next";
import { Badge, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getCountNotificationsNumber } from "../../Requests/notification-requests";
import HeadersLinks from "./HeadersLinks";
import { useQuery } from "react-query";
import { getNotificationsNumber } from "../../utils/reactQueriesConstants";

const { Option } = Select;

const NavBar = () => {
	const { t, i18n } = useTranslation();
	const isStudent = useIsStudent();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const isCompany = useIsCompany();
	const isDean = useIsDean();

	const changeLanguage = (lng: any) => {
		i18n.changeLanguage(lng);
	};

	const [state, setState] = useState({
		mounted: false,
	});

	const { data: notificationsNumber } = useQuery(
		[getNotificationsNumber],
		() => {
			if (isUserLogged()) return getCountNotificationsNumber();
		}
	);

	const handleClick = () => {
		history.push("/");
	};

	useEffect(() => {
		if (!state.mounted) {
			setState((prevState) => ({
				...prevState,
				mounted: true,
			}));
		}
	}, [state.mounted]);

	return (
		<div className={styles.headerContainer}>
			<div className={styles.firstChild}>
				<img alt={"logo"} src={logo} onClick={handleClick} />
			</div>
			<div className={styles.secondChild}>
				<HeadersLinks />
			</div>
			<div className={styles.thirdChild}>
				{isUserLogged() && isDean ? (
					<Link
						to="/settings"
						style={{
							fontWeight: location.pathname === "/settings" ? "bold" : "normal",
							color:
								location.pathname === "/settings"
									? theme.primaryColor
									: "black",
							margin: "0 5% 0 0",
							padding: "5px",
						}}
						title={t("header.settings")}
					>
						<FontAwesomeIcon
							icon={solid("cog")}
							style={{ fontSize: theme.headerSizeIcons }}
						/>
					</Link>
				) : null}
				<Link
					to="/intrebari-frecvente"
					style={{
						fontWeight:
							location.pathname === "/intrebari-frecvente" ? "bold" : "normal",
						color:
							location.pathname === "/intrebari-frecvente"
								? theme.primaryColor
								: "black",
						margin: "0 5% 0 0",
						padding: "5px",
					}}
					title={t("footer.frequentQuestions")}
				>
					<FontAwesomeIcon
						icon={solid("question-circle")}
						style={{ fontSize: theme.headerSizeIcons }}
					/>
				</Link>
				{isUserLogged() ? (
					<Badge
						count={notificationsNumber}
						size="small"
						style={{ margin: "0 5% 0 0" }}
					>
						<Link
							to="/notificari"
							style={{
								fontWeight:
									location.pathname === "/notificari" ? "bold" : "normal",
								color:
									location.pathname === "/notificari"
										? theme.primaryColor
										: "black",
								margin: "0 0 0 10%",
								padding: "5px",
							}}
							title={t("header.notifications")}
						>
							<FontAwesomeIcon
								icon={solid("bell")}
								style={{ fontSize: theme.headerSizeIcons }}
							/>
						</Link>
					</Badge>
				) : null}
				{!isUserLogged() ? (
					<CustomButton
						backgroundcolor={theme.primaryColor}
						textcolor={theme.white}
						margintop={"0"}
					>
						<Link to="/autentificare">{t("header.login")}</Link>
					</CustomButton>
				) : !isStudent ? (
					<Link
						to="/profil"
						style={{
							fontWeight: location.pathname === "/profil" ? "bold" : "normal",
							color:
								location.pathname === "/profil" ? theme.primaryColor : "black",
							margin: "0 0 0 10%",
							padding: "5px",
						}}
						title={t("header.myAccount")}
					>
						<FontAwesomeIcon
							icon={solid("user")}
							style={{ fontSize: theme.headerSizeIcons }}
						/>
					</Link>
				) : (
					<Link
						onClick={() => dispatch(studentIdSet(getUserId()))}
						to="/student"
						style={{
							fontWeight: location.pathname === "/student" ? "bold" : "normal",
							color:
								location.pathname === "/student" ? theme.primaryColor : "black",
							margin: "0 0 0 10%",
							padding: "5px",
						}}
						title={t("header.profile")}
					>
						<FontAwesomeIcon
							icon={solid("user")}
							style={{ fontSize: theme.headerSizeIcons }}
						/>
					</Link>
				)}
				{!isUserLogged() ? (
					<CustomButton
						backgroundcolor={theme.secondColor}
						textcolor={theme.white}
						margintop={"0"}
					>
						<Link to="/inregistrare">{t("header.signup")}</Link>
					</CustomButton>
				) : (
					<Link
						to="/"
						onClick={() => logout(!isCompany, i18n)}
						style={{
							margin: "0 5% 0 10%",
							padding: "5px",
						}}
						title={t("header.signout")}
					>
						<FontAwesomeIcon
							icon={solid("right-from-bracket")}
							style={{ fontSize: theme.headerSizeIcons }}
						/>
					</Link>
				)}
				<Select
					className={styles.lngSelect}
					value={geti18nLanguage()}
					style={{ width: 120 }}
					onChange={changeLanguage}
					bordered={false}
				>
					<Option value="ro">RO</Option>
					<Option value="en">EN</Option>
				</Select>
			</div>
			<NavBarMobile />
		</div>
	);
};

export default NavBar;
