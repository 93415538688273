/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyFacultyAccordsDTO,
    CompanyFacultyAccordsDTOFromJSON,
    CompanyFacultyAccordsDTOToJSON,
    CompanyUserReportDTO,
    CompanyUserReportDTOFromJSON,
    CompanyUserReportDTOToJSON,
    CompanyUserReportDTOPagedResponse,
    CompanyUserReportDTOPagedResponseFromJSON,
    CompanyUserReportDTOPagedResponseToJSON,
    InternshipEngagementType,
    InternshipEngagementTypeFromJSON,
    InternshipEngagementTypeToJSON,
    InternshipType,
    InternshipTypeFromJSON,
    InternshipTypeToJSON,
    MissingStudentEnrollmentReportDTOPagedResponse,
    MissingStudentEnrollmentReportDTOPagedResponseFromJSON,
    MissingStudentEnrollmentReportDTOPagedResponseToJSON,
    PracticeInternshipAllocationReportDTOPagedResponse,
    PracticeInternshipAllocationReportDTOPagedResponseFromJSON,
    PracticeInternshipAllocationReportDTOPagedResponseToJSON,
    PracticeInternshipsPerDomainReportDTOPagedResponse,
    PracticeInternshipsPerDomainReportDTOPagedResponseFromJSON,
    PracticeInternshipsPerDomainReportDTOPagedResponseToJSON,
    ProfessorProposalReportDTOPagedResponse,
    ProfessorProposalReportDTOPagedResponseFromJSON,
    ProfessorProposalReportDTOPagedResponseToJSON,
    ProjectAllocationReportDTOPagedResponse,
    ProjectAllocationReportDTOPagedResponseFromJSON,
    ProjectAllocationReportDTOPagedResponseToJSON,
    StudentsPerDomainReportDTOPagedResponse,
    StudentsPerDomainReportDTOPagedResponseFromJSON,
    StudentsPerDomainReportDTOPagedResponseToJSON,
    SummerSchoolsReportDTOPagedResponse,
    SummerSchoolsReportDTOPagedResponseFromJSON,
    SummerSchoolsReportDTOPagedResponseToJSON,
} from '../models';

export interface ApiReportGetCompaniesFacultyAccordsReportGetRequest {
    page?: number;
    pageSize?: number;
}

export interface ApiReportGetCompanyContactsReportGetRequest {
    namePattern?: string;
    companyId?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiReportGetMissingStudentPracticeInternshipEnrollmentReportGetRequest {
    page?: number;
    pageSize?: number;
    studyProgram?: Array<string>;
}

export interface ApiReportGetMissingStudentProjectEnrollmentReportGetRequest {
    studyCycle?: string;
    studyProgram?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiReportGetPracticeInternshipAllocationReportGetRequest {
    internshipType?: InternshipType;
    studyProgram?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiReportGetPracticeInternshipsPerDomainReportGetRequest {
    internshipType?: InternshipType;
    page?: number;
    pageSize?: number;
}

export interface ApiReportGetProfessorProposalReportGetRequest {
    engagementType?: InternshipEngagementType;
    studyProgram?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiReportGetProjectAllocationReportGetRequest {
    engagementType?: InternshipEngagementType;
    studyProgram?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiReportGetStudentAllocationPerDomainReportGetRequest {
    page?: number;
    pageSize?: number;
}

export interface ApiReportGetSummerSchoolsReportGetRequest {
    engagementType?: InternshipEngagementType;
    studyProgram?: Array<string>;
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     */
    async apiReportGetAllCompaniesContactsReportGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CompanyUserReportDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetAllCompaniesContactsReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyUserReportDTOFromJSON));
    }

    /**
     */
    async apiReportGetAllCompaniesContactsReportGet(initOverrides?: RequestInit): Promise<Array<CompanyUserReportDTO>> {
        const response = await this.apiReportGetAllCompaniesContactsReportGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetCompaniesFacultyAccordsReportGetRaw(requestParameters: ApiReportGetCompaniesFacultyAccordsReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CompanyFacultyAccordsDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetCompaniesFacultyAccordsReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFacultyAccordsDTOFromJSON));
    }

    /**
     */
    async apiReportGetCompaniesFacultyAccordsReportGet(requestParameters: ApiReportGetCompaniesFacultyAccordsReportGetRequest = {}, initOverrides?: RequestInit): Promise<Array<CompanyFacultyAccordsDTO>> {
        const response = await this.apiReportGetCompaniesFacultyAccordsReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetCompanyContactsReportGetRaw(requestParameters: ApiReportGetCompanyContactsReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['CompanyId'] = requestParameters.companyId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetCompanyContactsReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetCompanyContactsReportGet(requestParameters: ApiReportGetCompanyContactsReportGetRequest = {}, initOverrides?: RequestInit): Promise<CompanyUserReportDTOPagedResponse> {
        const response = await this.apiReportGetCompanyContactsReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetMissingStudentPracticeInternshipEnrollmentReportGetRaw(requestParameters: ApiReportGetMissingStudentPracticeInternshipEnrollmentReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MissingStudentEnrollmentReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.studyProgram) {
            queryParameters['StudyProgram'] = requestParameters.studyProgram;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetMissingStudentPracticeInternshipEnrollmentReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MissingStudentEnrollmentReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetMissingStudentPracticeInternshipEnrollmentReportGet(requestParameters: ApiReportGetMissingStudentPracticeInternshipEnrollmentReportGetRequest = {}, initOverrides?: RequestInit): Promise<MissingStudentEnrollmentReportDTOPagedResponse> {
        const response = await this.apiReportGetMissingStudentPracticeInternshipEnrollmentReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetMissingStudentProjectEnrollmentReportGetRaw(requestParameters: ApiReportGetMissingStudentProjectEnrollmentReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MissingStudentEnrollmentReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.studyCycle !== undefined) {
            queryParameters['StudyCycle'] = requestParameters.studyCycle;
        }

        if (requestParameters.studyProgram) {
            queryParameters['StudyProgram'] = requestParameters.studyProgram;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetMissingStudentProjectEnrollmentReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MissingStudentEnrollmentReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetMissingStudentProjectEnrollmentReportGet(requestParameters: ApiReportGetMissingStudentProjectEnrollmentReportGetRequest = {}, initOverrides?: RequestInit): Promise<MissingStudentEnrollmentReportDTOPagedResponse> {
        const response = await this.apiReportGetMissingStudentProjectEnrollmentReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetPracticeInternshipAllocationReportGetRaw(requestParameters: ApiReportGetPracticeInternshipAllocationReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeInternshipAllocationReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.internshipType !== undefined) {
            queryParameters['InternshipType'] = requestParameters.internshipType;
        }

        if (requestParameters.studyProgram) {
            queryParameters['StudyProgram'] = requestParameters.studyProgram;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetPracticeInternshipAllocationReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeInternshipAllocationReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetPracticeInternshipAllocationReportGet(requestParameters: ApiReportGetPracticeInternshipAllocationReportGetRequest = {}, initOverrides?: RequestInit): Promise<PracticeInternshipAllocationReportDTOPagedResponse> {
        const response = await this.apiReportGetPracticeInternshipAllocationReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetPracticeInternshipsPerDomainReportGetRaw(requestParameters: ApiReportGetPracticeInternshipsPerDomainReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeInternshipsPerDomainReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.internshipType !== undefined) {
            queryParameters['InternshipType'] = requestParameters.internshipType;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetPracticeInternshipsPerDomainReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeInternshipsPerDomainReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetPracticeInternshipsPerDomainReportGet(requestParameters: ApiReportGetPracticeInternshipsPerDomainReportGetRequest = {}, initOverrides?: RequestInit): Promise<PracticeInternshipsPerDomainReportDTOPagedResponse> {
        const response = await this.apiReportGetPracticeInternshipsPerDomainReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetProfessorProposalReportGetRaw(requestParameters: ApiReportGetProfessorProposalReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessorProposalReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.engagementType !== undefined) {
            queryParameters['EngagementType'] = requestParameters.engagementType;
        }

        if (requestParameters.studyProgram) {
            queryParameters['StudyProgram'] = requestParameters.studyProgram;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetProfessorProposalReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessorProposalReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetProfessorProposalReportGet(requestParameters: ApiReportGetProfessorProposalReportGetRequest = {}, initOverrides?: RequestInit): Promise<ProfessorProposalReportDTOPagedResponse> {
        const response = await this.apiReportGetProfessorProposalReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetProjectAllocationReportGetRaw(requestParameters: ApiReportGetProjectAllocationReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectAllocationReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.engagementType !== undefined) {
            queryParameters['EngagementType'] = requestParameters.engagementType;
        }

        if (requestParameters.studyProgram) {
            queryParameters['StudyProgram'] = requestParameters.studyProgram;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetProjectAllocationReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectAllocationReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetProjectAllocationReportGet(requestParameters: ApiReportGetProjectAllocationReportGetRequest = {}, initOverrides?: RequestInit): Promise<ProjectAllocationReportDTOPagedResponse> {
        const response = await this.apiReportGetProjectAllocationReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetStudentAllocationPerDomainReportGetRaw(requestParameters: ApiReportGetStudentAllocationPerDomainReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentsPerDomainReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetStudentAllocationPerDomainReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentsPerDomainReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetStudentAllocationPerDomainReportGet(requestParameters: ApiReportGetStudentAllocationPerDomainReportGetRequest = {}, initOverrides?: RequestInit): Promise<StudentsPerDomainReportDTOPagedResponse> {
        const response = await this.apiReportGetStudentAllocationPerDomainReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReportGetSummerSchoolsReportGetRaw(requestParameters: ApiReportGetSummerSchoolsReportGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SummerSchoolsReportDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.engagementType !== undefined) {
            queryParameters['EngagementType'] = requestParameters.engagementType;
        }

        if (requestParameters.studyProgram) {
            queryParameters['StudyProgram'] = requestParameters.studyProgram;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Report/GetSummerSchoolsReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummerSchoolsReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiReportGetSummerSchoolsReportGet(requestParameters: ApiReportGetSummerSchoolsReportGetRequest = {}, initOverrides?: RequestInit): Promise<SummerSchoolsReportDTOPagedResponse> {
        const response = await this.apiReportGetSummerSchoolsReportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
