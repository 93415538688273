/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyDocumentDTOPagedResponse,
    CompanyDocumentDTOPagedResponseFromJSON,
    CompanyDocumentDTOPagedResponseToJSON,
    DocumentTypeEnum,
    DocumentTypeEnumFromJSON,
    DocumentTypeEnumToJSON,
    RequestResponse,
    RequestResponseFromJSON,
    RequestResponseToJSON,
} from '../models';

export interface ApiCompanyDocumentDeleteDocumentIdDeleteRequest {
    documentId: string;
}

export interface ApiCompanyDocumentDownloadDocumentDocumentIdGetRequest {
    documentId: string;
}

export interface ApiCompanyDocumentGetAllCompanyIdGetRequest {
    companyId: string;
    documentType?: Array<DocumentTypeEnum>;
    page?: number;
    pageSize?: number;
}

export interface ApiCompanyDocumentRejectDocumentDocumentIdPutRequest {
    documentId: string;
    body?: string;
}

export interface ApiCompanyDocumentUpdateSkipOfferValidationStatusDocumentIdPutRequest {
    documentId: string;
}

export interface ApiCompanyDocumentUploadDocumentCompanyIdPutRequest {
    companyId: string;
    facultyId?: string;
    documentId?: string;
    file?: Blob;
}

/**
 * 
 */
export class CompanyDocumentApi extends runtime.BaseAPI {

    /**
     */
    async apiCompanyDocumentDeleteDocumentIdDeleteRaw(requestParameters: ApiCompanyDocumentDeleteDocumentIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling apiCompanyDocumentDeleteDocumentIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyDocument/Delete/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyDocumentDeleteDocumentIdDelete(requestParameters: ApiCompanyDocumentDeleteDocumentIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyDocumentDeleteDocumentIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyDocumentDownloadDocumentDocumentIdGetRaw(requestParameters: ApiCompanyDocumentDownloadDocumentDocumentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling apiCompanyDocumentDownloadDocumentDocumentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyDocument/DownloadDocument/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiCompanyDocumentDownloadDocumentDocumentIdGet(requestParameters: ApiCompanyDocumentDownloadDocumentDocumentIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiCompanyDocumentDownloadDocumentDocumentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyDocumentGetAllCompanyIdGetRaw(requestParameters: ApiCompanyDocumentGetAllCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDocumentDTOPagedResponse>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiCompanyDocumentGetAllCompanyIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentType) {
            queryParameters['DocumentType'] = requestParameters.documentType;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyDocument/GetAll/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDocumentDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyDocumentGetAllCompanyIdGet(requestParameters: ApiCompanyDocumentGetAllCompanyIdGetRequest, initOverrides?: RequestInit): Promise<CompanyDocumentDTOPagedResponse> {
        const response = await this.apiCompanyDocumentGetAllCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyDocumentRejectDocumentDocumentIdPutRaw(requestParameters: ApiCompanyDocumentRejectDocumentDocumentIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling apiCompanyDocumentRejectDocumentDocumentIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyDocument/RejectDocument/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyDocumentRejectDocumentDocumentIdPut(requestParameters: ApiCompanyDocumentRejectDocumentDocumentIdPutRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiCompanyDocumentRejectDocumentDocumentIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyDocumentUpdateSkipOfferValidationStatusDocumentIdPutRaw(requestParameters: ApiCompanyDocumentUpdateSkipOfferValidationStatusDocumentIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling apiCompanyDocumentUpdateSkipOfferValidationStatusDocumentIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyDocument/UpdateSkipOfferValidationStatus/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyDocumentUpdateSkipOfferValidationStatusDocumentIdPut(requestParameters: ApiCompanyDocumentUpdateSkipOfferValidationStatusDocumentIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyDocumentUpdateSkipOfferValidationStatusDocumentIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyDocumentUploadDocumentCompanyIdPutRaw(requestParameters: ApiCompanyDocumentUploadDocumentCompanyIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiCompanyDocumentUploadDocumentCompanyIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.facultyId !== undefined) {
            queryParameters['facultyId'] = requestParameters.facultyId;
        }

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/CompanyDocument/UploadDocument/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyDocumentUploadDocumentCompanyIdPut(requestParameters: ApiCompanyDocumentUploadDocumentCompanyIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyDocumentUploadDocumentCompanyIdPutRaw(requestParameters, initOverrides);
    }

}
