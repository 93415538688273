/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyUserDTO
 */
export interface CompanyUserDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDTO
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDTO
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDTO
     */
    department?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDTO
     */
    _function?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUserDTO
     */
    isRepresentative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUserDTO
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUserDTO
     */
    isContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUserDTO
     */
    isManaging?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUserDTO
     */
    sendNotifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDTO
     */
    companyId?: string;
}

export function CompanyUserDTOFromJSON(json: any): CompanyUserDTO {
    return CompanyUserDTOFromJSONTyped(json, false);
}

export function CompanyUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'isRepresentative': !exists(json, 'isRepresentative') ? undefined : json['isRepresentative'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'isContact': !exists(json, 'isContact') ? undefined : json['isContact'],
        'isManaging': !exists(json, 'isManaging') ? undefined : json['isManaging'],
        'sendNotifications': !exists(json, 'sendNotifications') ? undefined : json['sendNotifications'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
    };
}

export function CompanyUserDTOToJSON(value?: CompanyUserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'department': value.department,
        'function': value._function,
        'isRepresentative': value.isRepresentative,
        'isAdmin': value.isAdmin,
        'isContact': value.isContact,
        'isManaging': value.isManaging,
        'sendNotifications': value.sendNotifications,
        'companyId': value.companyId,
    };
}

