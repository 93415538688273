import { useState } from "react";
import styles from "./Profile.module.scss";
import { Col, Row, Table } from "antd";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { ErrorCodes, ProfessorDTO } from "../../Api";
import Search from "antd/lib/input/Search";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	getAvailablePrograms,
	getSupervisedPrograms,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";
import AddSupervisedStudyProgramModal from "./AddSupervisedStudyProgramModal";
import { getSupervisedStudyPrograms } from "../../Requests/student-study-requests";
import { deleteSupervisedStudyProgram } from "../../Requests/academic-user-requests";

const SupervisedStudyPrograms = (props: { supervisorId: string }) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [modalVisibility, setModalVisibility] = useState(false);
	const [selectedStudyProgramId, setSelectedStudyProgramId] =
		useState<string>("");
	const [spinning, setSpinning] = useState(false);

	let locale = {
		emptyText: t("tableText.noSupervisedStudyPrograms"),
	};

	const queryClient = useQueryClient();
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getSupervisedPrograms);
		await queryClient.invalidateQueries(getAvailablePrograms);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteSupervisedStudyProgram(props.supervisorId, id)
			.then(async () => {
				await invalidateUsersQuery();

				openNotification(
					t("account.studyProgramDelete"),
					t("account.studyProgramDeleteSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (ex: any) => {
				const error = await getErrorFromResponse(ex);

				openNotification(
					t("account.studyProgramDelete"),
					error?.code === ErrorCodes.Forbidden
						? t("account.noPermissions")
						: error?.code === ErrorCodes.SupervisionsOngoing
						? t("account.supervisionsOngoing")
						: error?.code === ErrorCodes.StudyProgramNotFound
						? t("account.studyProgramNotFound")
						: error?.code === ErrorCodes.SupervisorNotFound
						? t("account.supervisorNotFound")
						: t("account.studyProgramDeleteError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setModalVisibility(false);
			});
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: supervisors, isLoading } = useQuery(
		[getSupervisedPrograms, searchTerm, page, pageSize],
		() =>
			getSupervisedStudyPrograms(
				props.supervisorId,
				searchTerm,
				page,
				pageSize
			),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const FilterByNameInput = (
		<Search
			placeholder={t("account.name")}
			allowClear
			onSearch={(value) => {
				setSearchTerm(value);
				setPage(1);
			}}
			style={{ width: 200 }}
		/>
	);

	const columns = [
		{
			title: FilterByNameInput,
			dataIndex: "name",
			key: "name",
		},
		{
			title: t("account.domain"),
			dataIndex: "domain",
			key: "domain",
		},
		{
			title: t("account.studyCycle"),
			dataIndex: "studyCycle",
			key: "studyCycle",
			render: (_text: string | undefined, record: any) =>
				t("account.studyCycles." + record.studyCycle),
		},
		{
			title: t("account.actions"),
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<div className={styles.modalBtnContainer}>
						<>
							<div className={styles.modalContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedStudyProgramId(record.id);
										setModalVisibility(true);
									}}
									icon={<FontAwesomeIcon icon={solid("trash")} />}
									title={t("account.deleteStudyProgram")}
								/>
								<ConfirmationModal
									modalText={
										t("account.deleteStudyProgramMessage") +
										record.name +
										t("account.deleteStudyProgramMessage2")
									}
									handleFunction={() => handleDelete(selectedStudyProgramId)}
									modalVisibility={modalVisibility}
									title=""
									changeModalVisibility={() => setModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						</>
					</div>
				</div>
			),
		},
	];

	const handleTableChange = (pagination: any) => {
		setPage(() => pagination.current);
		setPageSize(() => pagination.pageSize);
	};

	return (
		<Col
			span={24}
			className={styles.container}
			style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}
		>
			<Row>
				<AddSupervisedStudyProgramModal supervisorId={props.supervisorId} />
			</Row>
			<Row>
				<Col span={24}>
					<Table
						locale={locale}
						className={styles.usersTable}
						dataSource={supervisors?.data ?? new Array<ProfessorDTO>()}
						columns={columns}
						pagination={{
							total: supervisors?.totalCount,
							current: supervisors?.page,
							pageSize: supervisors?.pageSize,
							pageSizeOptions: ["10", "20", "50"],
							showSizeChanger: true,
							locale: { items_per_page: t("configuration.pagination") },
							position: ["topRight"],
							hideOnSinglePage: supervisors?.totalCount
								? supervisors?.totalCount <= 10
								: true,
						}}
						rowKey={(record) => record.id ?? ""}
						loading={isLoading}
						onChange={handleTableChange}
						scroll={{ x: 400 }}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default SupervisedStudyPrograms;
