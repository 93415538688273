import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import {
	CompanyInternshipState,
	InternshipDTO,
	InternshipEngagementType,
	InternshipParticipationIntent,
	InternshipParticipationIntentDTO,
	InternshipParticipationIntentDTOFromJSONTyped,
	InternshipParticipationType,
	InternshipType,
} from "../../Api";
import {
	capitalizeFirst,
	internshipStatus,
	useIsCompany,
	useIsStudent,
	isUserLogged,
	useIsFacultyAdmin,
	useIsInternshipAdmin,
	useIsProfessor,
	useIsDean,
	useIsDepartmentSecretary,
	useIsDepartmentDirector,
	getUserId,
} from "../../utils/utilFunctions";
import { useLocation } from "react-router-dom";
import styles from "./AllEvents.module.scss";
import { canApply } from "../../Requests/internship-requests";
import { useState, useEffect } from "react";
import { isNullOrUndefined } from "util";
import { setInternshipParticipationType } from "../../Requests/internship-type-event-requests";
import { theme } from "../../theme";
import moment from "moment";

const dateFormatListEvent = "YYYY-MM-DD hh:mm";

const EventActions = (props: { item: any; activeTab: any }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const search = useLocation().search;
	const type = new URLSearchParams(search).get("type") as any;
	const [applicable, setApplicable] = useState(false);

	const [interestedState, setInterestedState] =
		useState<InternshipParticipationType>(
			props.item?.participationIntents?.find(
				(e: InternshipParticipationIntentDTO) => e.user?.id === getUserId()
			)?.participationType ?? InternshipParticipationType.None
		);

	const [interestedCount, setInterestedCount] = useState(
		props.item?.participationIntents?.filter(
			(pi: InternshipParticipationIntent) =>
				pi.participationType === InternshipParticipationType.Interested
		).length ?? 0
	);
	const [goingCount, setGoingCount] = useState(
		props.item?.participationIntents?.filter(
			(pi: InternshipParticipationIntent) =>
				pi.participationType === InternshipParticipationType.Going
		).length ?? 0
	);

	const IconText = (
		{
			icon,
			text,
			tooltipText,
			onClickHandler,
		}: {
			icon: any;
			text: string;
			tooltipText: string;
			onClickHandler?: (event: React.MouseEvent) => void;
		},
		participationType: InternshipParticipationType
	) => (
		<Space title={tooltipText} onClick={onClickHandler}>
			{icon}
			{text}
		</Space>
	);

	const handleParticipationIntentButtonClick = async (
		participationType: InternshipParticipationType
	) => {
		const obj: InternshipParticipationIntentDTO = {
			internshipId: props.item.internshipId,
			participationType:
				interestedState === participationType
					? InternshipParticipationType.None
					: participationType,
		};

		await setInternshipParticipationType(obj);

		// update the counter for going and interested
		if (obj.participationType === interestedState) {
			if (interestedState === InternshipParticipationType.Going) {
				setGoingCount(goingCount - 1);
			} else if (interestedState === InternshipParticipationType.Interested) {
				setInterestedCount(interestedCount - 1);
			}
		} else {
			if (interestedState === InternshipParticipationType.Going) {
				setGoingCount(goingCount - 1);
			} else if (interestedState === InternshipParticipationType.Interested) {
				setInterestedCount(interestedCount - 1);
			}

			if (obj.participationType === InternshipParticipationType.Going) {
				setGoingCount(goingCount + 1);
			} else if (
				obj.participationType === InternshipParticipationType.Interested
			) {
				setInterestedCount(interestedCount + 1);
			}
		}

		setInterestedState(obj.participationType!);
	};
	return (
		<>
			{props.item && (
				<div className={styles.actionsContainer}>
					{props.item.startingDate && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("calendar")} />}
							text={
								props.item.startingDate
									? moment(props.item.startingDate).format(dateFormatListEvent)
									: "N/A"
							}
							tooltipText={t("internships.startDate")}
							key="list-CalendarOutlined"
						/>
					)}
					{(props.item.engagementType === InternshipEngagementType.Internship ||
						props.item.engagementType === InternshipEngagementType.Job) && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("clock")} />}
							text={
								props.item.period?.toString() +
								" " +
								(props.item.period?.toString() === "1"
									? t("internships.month")
									: t("internships.months") ?? "N/A")
							}
							tooltipText={t("internships.period")}
							key="list-ClockCircleOutlined"
						/>
					)}
					{props.item.engagementType ===
						InternshipEngagementType.Internship && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("business-time")} />}
							text={
								props.item.totalPracticeHours?.toString() +
									" " +
									t("internships.hours") ?? "N/A"
							}
							tooltipText={t("internships.totalPracticeHours")}
							key="list-BusinessTimeOutlined"
						/>
					)}
					{props.item.category && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("table-cells")} />}
							text={props.item.category?.toString()}
							tooltipText={t("internships.category")}
							key="list-AppstoreOutlined"
						/>
					)}
					{props.item?.participationIntents && (
						<span
							style={{
								background: theme.lightGray,
								borderRadius: "10px",
								padding: "0px 0.9rem",
								cursor: "pointer",
							}}
							onClick={() =>
								handleParticipationIntentButtonClick(
									InternshipParticipationType.Interested
								)
							}
						>
							<IconText
								icon={
									<FontAwesomeIcon
										icon={
											interestedState === InternshipParticipationType.Interested
												? solid("star")
												: regular("star")
										}
									/>
								}
								text={interestedCount}
								tooltipText={t("internships.interested")}
								key="list-InterestedCount"
							/>
						</span>
					)}
					{props.item?.participationIntents && (
						<span style={{ width: "10px" }}></span>
					)}
					{props.item?.participationIntents && (
						<span
							style={{
								background: theme.lightGray,
								borderRadius: "10px",
								padding: "0px 0.9rem",
								cursor: "pointer",
							}}
							onClick={() =>
								handleParticipationIntentButtonClick(
									InternshipParticipationType.Going
								)
							}
						>
							<IconText
								icon={
									<FontAwesomeIcon
										icon={
											interestedState === InternshipParticipationType.Going
												? solid("circle-check")
												: regular("circle-check")
										}
									/>
								}
								text={goingCount}
								tooltipText={t("internships.going")}
								key="list-GoingCount"
							/>
						</span>
					)}
					<br />
				</div>
			)}
		</>
	);
};

export default EventActions;
