import { Link } from "react-router-dom";
import image from "../../Media/not-found.jpg";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./ErrorPage.module.scss";
import { Col, Row } from "antd";

const NotFoundPage = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<Row>
				<Col
					span={window.innerWidth > 1215 ? 11 : 24}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<div className={styles.container}>
						<h1>{t("home.messages.notFoundPage")}</h1>
						<CustomButton
							backgroundcolor={theme.secondColor}
							textcolor={theme.white}
							fontSize={"1rem"}
							paddingvertical={"1.2rem"}
							paddinghorizontal={"1.4rem"}
							margintop={"2%"}
						>
							<Link to="/">{t("header.home")}</Link>
						</CustomButton>
					</div>
				</Col>
				<Col
					span={window.innerWidth > 1215 ? 11 : 24}
					style={{ display: "flex" }}
				>
					<div>
						<img
							alt={"img2"}
							src={image}
							style={{
								width: window.innerWidth > 1215 ? "40rem" : "20rem",
								height: window.innerWidth > 1215 ? "40rem" : "20rem",
							}}
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
};
export default NotFoundPage;
