import { EmailListItemProps } from "./EmailListItem.types";
import { Col, List, Row } from "antd";
import styles from "./EmailListItem.module.scss";
import EmailActions from "./EmailActions";
import CustomButton from "../../../../../../CustomComponents/CustomButton";
import { theme } from "../../../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
	deleteNewsletterEmail,
	toggleEmailExpeditionEnabled,
} from "../../../../../../Requests/newsletter-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";

export const EmailListItem = (props: EmailListItemProps) => {
	const { t } = useTranslation();
	const [
		updateEmailExpeditionModalVisibility,
		setUpdateEmailExpeditionModalVisibility,
	] = useState(false);
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const queryClient = useQueryClient();

	const invalidateNewsletterQueries = useCallback(async () => {
		await queryClient.invalidateQueries("getNewsletterEmails");
	}, [queryClient]);

	const handleToggleExpeditionEnabled = () => {
		toggleEmailExpeditionEnabled(props.item.id!)
			.then(async () => {
				await invalidateNewsletterQueries();
				openNotification(
					t("emails.updateEmail"),
					t("emails.updateExpeditionStatusSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error: any) => {
				openNotification(
					t("emails.updateEmail"),
					t("emails.updateExpeditionStatusError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setUpdateEmailExpeditionModalVisibility(false);
			});
	};

	const deleteEntry = useCallback(() => {
		if (props.item.id) {
			setSpinning(true);

			deleteNewsletterEmail(props.item.id)
				.then(async () => {
					await invalidateNewsletterQueries();
					openNotification(
						t("emails.deleteEmail"),
						t("emails.deleteEmailSuccess"),
						NOTIFICATION_TYPES.SUCCESS
					);
				})
				.catch((_error: any) => {
					openNotification(
						t("emails.deleteEmail"),
						t("emails.deleteEmailError"),
						NOTIFICATION_TYPES.ERROR
					);
				})
				.finally(() => {
					setSpinning(false);
					setDeleteModalVisibility(false);
				});
		}
	}, [invalidateNewsletterQueries, props.item.id, t]);

	return (
		<List.Item
			className={styles.ListItem}
			style={{ border: "1px solid lightgray" }}
			title={""}
			key={props.item.id}
			actions={[
				<EmailActions item={props.item} />,
				<div className={styles.buttonsContainer}>
					<Row>
						<div className={styles.alignmentContainer}>
							<div
								style={{
									display: "inline-flex",
									justifyContent: "center",
									alignItems: "baseline",
								}}
							>
								<CustomButton
									shape="circle"
									type="text"
									title={t("emails.edit")}
									style={{
										background: "none",
										color: theme.black,
										boxShadow: "none",
										fontSize: "14px",
									}}
								>
									<Link
										to={{
											pathname: "/editare-email/" + props.item.id,
											state: {
												currentPage: props.currentPage,
												pageSize: props.pageSize,
												searchTerm: props.searchTerm,
												sortField: props.sortField,
												sortDirection: props.sortDirection,
											},
										}}
									>
										<FontAwesomeIcon icon={solid("edit")} /> &nbsp;
										{t("emails.edit")}
									</Link>
								</CustomButton>
								{props.item.currentRecipientsCount! > 0 && (
									<>
										<CustomButton
											shape="circle"
											type="text"
											title={
												props.item?.expeditionEnabled
													? t("emails.stopExpedition")
													: t("emails.restartExpedition")
											}
											style={{
												background: "none",
												color: theme.black,
												boxShadow: "none",
												fontSize: "14px",
											}}
											onClick={() =>
												setUpdateEmailExpeditionModalVisibility(true)
											}
											icon={
												<FontAwesomeIcon
													icon={
														props.item?.expeditionEnabled
															? solid("stop")
															: solid("play")
													}
													style={{ paddingRight: "5%" }}
												/>
											}
										>
											{props.item?.expeditionEnabled
												? t("emails.stopExpedition")
												: t("emails.restartExpedition")}
										</CustomButton>
										<ConfirmationModal
											modalText={
												props.item?.expeditionEnabled
													? t("emails.stopEmailExpeditionMessage")
													: t("emails.restartEmailExpeditionMessage")
											}
											handleFunction={() => {
												handleToggleExpeditionEnabled();
											}}
											modalVisibility={updateEmailExpeditionModalVisibility}
											title=""
											changeModalVisibility={() =>
												setUpdateEmailExpeditionModalVisibility(false)
											}
											spinning={spinning}
										/>
									</>
								)}
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "none",
										color: theme.black,
										marginRight: "1rem",
										marginTop: "0",
										boxShadow: "none",
										paddingLeft: "3px",
										fontSize: "14px",
									}}
									onClick={() => {
										setDeleteModalVisibility(true);
									}}
									title={t("emails.deleteEmail")}
									icon={
										<FontAwesomeIcon
											icon={solid("trash")}
											style={{ paddingRight: "5%" }}
										/>
									}
									shape={"circle"}
									type={"text"}
								>
									{t("emails.deleteEmail")}
								</CustomButton>
								<ConfirmationModal
									modalText={t("emails.deleteEmailMessage")}
									handleFunction={deleteEntry}
									modalVisibility={deleteModalVisibility}
									title=""
									changeModalVisibility={() => setDeleteModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						</div>
					</Row>
				</div>,
			]}
		>
			<List.Item.Meta
				className={styles.listItemMeta}
				avatar={
					<FontAwesomeIcon
						icon={solid("envelope")}
						style={{ paddingRight: "5%" }}
					/>
				}
				title={
					<Link
						to={{
							pathname: "/newsletter-email/" + props.item.id,
							state: {
								id: props.item.id,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								origin: props.location.pathname,
							},
						}}
					>
						<Row style={{ width: "100%" }}>
							<Col span={22} style={{ display: "flex" }}>
								{props.item.subject}
							</Col>
						</Row>
					</Link>
				}
				description={props.item.newsletterChannel}
			/>
		</List.Item>
	);
};
