/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailSendStatus,
    EmailSendStatusFromJSON,
    EmailSendStatusFromJSONTyped,
    EmailSendStatusToJSON,
} from './EmailSendStatus';

/**
 * 
 * @export
 * @interface EmailRecipientDTO
 */
export interface EmailRecipientDTO {
    /**
     * 
     * @type {string}
     * @memberof EmailRecipientDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailRecipientDTO
     */
    recipientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailRecipientDTO
     */
    recipientName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailRecipientDTO
     */
    recipientEmail?: string | null;
    /**
     * 
     * @type {EmailSendStatus}
     * @memberof EmailRecipientDTO
     */
    status?: EmailSendStatus;
}

export function EmailRecipientDTOFromJSON(json: any): EmailRecipientDTO {
    return EmailRecipientDTOFromJSONTyped(json, false);
}

export function EmailRecipientDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailRecipientDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recipientId': !exists(json, 'recipientId') ? undefined : json['recipientId'],
        'recipientName': !exists(json, 'recipientName') ? undefined : json['recipientName'],
        'recipientEmail': !exists(json, 'recipientEmail') ? undefined : json['recipientEmail'],
        'status': !exists(json, 'status') ? undefined : EmailSendStatusFromJSON(json['status']),
    };
}

export function EmailRecipientDTOToJSON(value?: EmailRecipientDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recipientId': value.recipientId,
        'recipientName': value.recipientName,
        'recipientEmail': value.recipientEmail,
        'status': EmailSendStatusToJSON(value.status),
    };
}

