/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ANAFCompanyDetailsDTO
 */
export interface ANAFCompanyDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ANAFCompanyDetailsDTO
     */
    denumire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ANAFCompanyDetailsDTO
     */
    adresa?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ANAFCompanyDetailsDTO
     */
    telefon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ANAFCompanyDetailsDTO
     */
    fax?: string | null;
}

export function ANAFCompanyDetailsDTOFromJSON(json: any): ANAFCompanyDetailsDTO {
    return ANAFCompanyDetailsDTOFromJSONTyped(json, false);
}

export function ANAFCompanyDetailsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ANAFCompanyDetailsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denumire': !exists(json, 'denumire') ? undefined : json['denumire'],
        'adresa': !exists(json, 'adresa') ? undefined : json['adresa'],
        'telefon': !exists(json, 'telefon') ? undefined : json['telefon'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
    };
}

export function ANAFCompanyDetailsDTOToJSON(value?: ANAFCompanyDetailsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denumire': value.denumire,
        'adresa': value.adresa,
        'telefon': value.telefon,
        'fax': value.fax,
    };
}

