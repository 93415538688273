import Layout from "../../Containers/Layout";
import { Row, Col, Button, Drawer, Tabs, Progress } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
	getAllProfessorInternship,
	getAllProfessorInternshipFilters,
	getAllProfessorInternshipsForDepartment,
	getAllProfessorInternshipsForFaculty,
	getAllProfessorInternshipsForStudyProgram,
	getDepartmentProfessorInternshipFilters,
	getFacultyProfessorInternshipFilters,
	getStudyProgramProfessorInternshipFilters,
} from "../../Requests/professor-internship-requests";
import {
	getAllCompanyInternship,
	getAllCompanyInternshipFilters,
	getCoordinatedProfessorInternships,
	getCoordinatedProfessorInternshipsFilters,
} from "../../Requests/company-internship-requests";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./Internships.module.scss";
import Search from "antd/lib/input/Search";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import {
	getCurrentUniversityYear,
	getUserId,
	useBeforeRender,
	useIsDean,
	useIsDepartmentDirector,
	useIsDepartmentSecretary,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudent,
	useIsStudyProgramCoordinator,
} from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	Filter,
	InternshipSortFieldEnum,
	ProfessorProposalReportDTO,
	Role,
	SortDirectionEnum,
} from "../../Api";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQuery, useQueryClient } from "react-query";
import InternshipsList from "./InternshipsList";
import CustomFilter from "../../CustomComponents/CustomFilter";
import CustomSort from "../../CustomComponents/CustomSort";
import {
	getAllProfessors,
	getProfessorById,
} from "../../Requests/academic-user-requests";
import {
	facultyInternshipsFiltersKey,
	getAllApplicationTypesCount,
	getCompanyInternships,
	getCoordinatedInternships,
	getCoordinatedInternshipsFilters,
	getDepartmentInternships,
	getDepartmentInternshipsFiltersForProfessor,
	getFacultiesInternshipsFiltersForProfessor,
	getFacultyInternships,
	getInternshipsFilters,
	getProfessorInternships,
	getStudyProgramInternships,
	getStudyProgramInternshipsFiltersForProfessor,
} from "../../utils/reactQueriesConstants";
import { getStudyProgramsForFaculties } from "../../Requests/student-study-request";
import {
	PageCallBack,
	downloadAll,
	downloadExcel,
	downloadFile,
} from "../../utils/downloadUtils";
import { removeDiacritics } from "../../utils/dataUtils";
import { ColumnType } from "../../utils/downloadReportUtils.types";
import { FilterState, setCheckedFilters } from "../Filters/FiltersSlice";
import { useDispatch, useSelector } from "react-redux";

const { TabPane } = Tabs;

const getAllKeys = (filterItems: any[]) => {
	const keys: any[] = [];

	filterItems?.forEach((filter) => {
		keys.push(filter.key);

		filter.children.forEach((filterItem: any) => {
			keys.push(filterItem.key.split("/")[1]);
		});
	});

	return keys;
};

const FacultyInternships = (props: any) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState(
		InternshipSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [skills, setSkills] = useState([]);
	const [categories, setCategories] = useState([]);
	const [locations, setLocations] = useState([]);
	const [types, setTypes] = useState([] as any);
	const [applicabilityTypes, setApplicabilityTypes] = useState([]);
	const [validationStates, setValidationStates] = useState([]);
	const [universityYears, setUniversityYears] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [states, setStates] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState<any>([]);
	const [visible, setVisible] = useState(false);
	const location = useLocation();
	const [activeTab, setActiveTab] = useState("1");
	const isProfessor = useIsProfessor();
	const isDepartmentSecretary = useIsDepartmentSecretary();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDean = useIsDean();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const [professorOptions, setProfessorOptions] = useState([] as any);
	const queryClient = useQueryClient();
	const [faculties, setFaculties] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [downloading, setDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState(false);
	const [progress, setProgress] = useState(0);

	const dispatch = useDispatch();
	const cachedFilters = useSelector(
		(state: { filters: FilterState }) =>
			state.filters[facultyInternshipsFiltersKey]
	);

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getInternshipsFilters);
		await queryClient.invalidateQueries(getCompanyInternships);
		await queryClient.invalidateQueries(getProfessorInternships);
		await queryClient.invalidateQueries(getCoordinatedInternships);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	const openErrorNotification = (error: string, message: string) => {
		openNotification(error, message, NOTIFICATION_TYPES.ERROR);
	};

	const downloadReportFaculty = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			t("reports.proposalsReport"),
			proposalColumns(t).map((e) => {
				return { header: removeDiacritics(e.title), key: e.key.toString() };
			}),
			await downloadAll(
				(page, pageSize) =>
					getAllProfessorInternshipsForFaculty(
						skills,
						categories,
						types,
						statuses,
						validationStates,
						universityYears,
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
					),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage")
					);
				}
			)
		);
	}, [setDownloading]);

	const downloadReportDepartment = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			t("reports.proposalsReport"),
			proposalColumns(t).map((e) => {
				return { header: removeDiacritics(e.title), key: e.key.toString() };
			}),
			await downloadAll(
				(page, pageSize) =>
					getAllProfessorInternshipsForDepartment(
						skills,
						categories,
						types,
						statuses,
						validationStates,
						universityYears,
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
					),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage")
					);
				}
			)
		);
	}, [setDownloading]);

	const proposalColumns = (
		t: (text: string) => string
	): ColumnType<ProfessorProposalReportDTO>[] => [
		{
			title: t("reports.professorName"),
			key: "recruiterName",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Professor,
							origin: location.pathname,
						},
					}}
				>
					<b>{record.recruiterName}</b>
				</Link>
			),
		},
		{
			title: t("reports.proposalName"),
			key: "internshipName",
		},
		{
			title: t("reports.initialAvailablePositions"),
			key: "initialAvailablePositions",
		},
		{
			title: t("reports.availablePositions"),
			key: "availablePositions",
		},
		{
			title: t("reports.applicantsCount"),
			key: "applicantsCount",
		},
		{
			title: t("reports.viewCount"),
			key: "viewCount",
		},
	];

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrentPage(1);
		setTypes(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Type@"))
				.map((e: string) => e.split("@")[1])
		);
		setLocations(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Location@"))
				.map((e: string) => e.split("@")[1])
		);
		setStatuses(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Status@"))
				.map((e: string) => e.split("@")[1])
		);
		setStates(
			checkedKeysValue
				.filter((e: string) => e.startsWith("State@"))
				.map((e: string) => e.split("@")[1])
		);
		setApplicabilityTypes(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Applicability@"))
				.map((e: string) => e.split("@")[1])
		);
		setValidationStates(
			checkedKeysValue
				.filter((e: string) => e.startsWith("ValidationState@"))
				.map((e: string) => e.split("@")[1])
		);
		setUniversityYears(
			checkedKeysValue
				.filter((e: string) => e.startsWith("UniversityYear@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const setDefaultFilters = () => {
		onCheck([
			`UniversityYear@${getCurrentUniversityYear()}-${
				getCurrentUniversityYear() + 1
			}`,
		]);
	};

	const getOldValues = () => {
		if (!!cachedFilters) {
			let {
				filters,
				searchTerm,
				sortField,
				sortDirection,
				currentPage,
				pageSize,
				activeTab,
			}: any = cachedFilters;

			onCheck(filters);
			setSearchTerm(searchTerm);
			setSortField(sortField);
			setSortDirection(sortDirection);
			setCurrentPage(currentPage);
			setPageSize(pageSize);

			if (isProfessor) setActiveTab(activeTab);
		} else {
			setDefaultFilters();
		}
	};

	useEffect(() => {
		getOldValues();
	}, [activeTab]);

	const updateCachedFilters = () => {
		var newCachedValue = {
			filters: checkedKeys,
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			currentPage: currentPage,
			pageSize: pageSize,
			activeTab: activeTab,
		};
		dispatch(
			setCheckedFilters({
				filterKey: facultyInternshipsFiltersKey + activeTab,
				value: newCachedValue,
			})
		);
	};

	useEffect(() => {
		updateCachedFilters();
	}, [
		checkedKeys,
		searchTerm,
		sortField,
		sortDirection,
		currentPage,
		pageSize,
		activeTab,
	]);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getInternshipsFilters],
		() => {
			return getAllProfessorInternshipFilters();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: coordinatedInternshipsFilters } = useQuery(
		[getCoordinatedInternshipsFilters, isProfessor],
		() => {
			if (isProfessor) {
				return getCoordinatedProfessorInternshipsFilters();
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isProfessor) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: facultyInternshipsFilters } = useQuery(
		[getFacultiesInternshipsFiltersForProfessor, isDean, isFacultyAdmin],
		() => {
			if (isDean || isFacultyAdmin) {
				return getFacultyProfessorInternshipFilters();
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isDean) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: departmentInternshipsFilters } = useQuery(
		[
			getDepartmentInternshipsFiltersForProfessor,
			isProfessor,
			isDepartmentDirector,
		],
		() => {
			if (isProfessor || isDepartmentDirector) {
				return getDepartmentProfessorInternshipFilters();
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isProfessor || isDepartmentDirector) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: studyProgramInternshipsFilters } = useQuery(
		[getStudyProgramInternshipsFiltersForProfessor, isStudyProgramCoordinator],
		() => {
			if (isStudyProgramCoordinator) {
				return getStudyProgramProfessorInternshipFilters();
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isStudyProgramCoordinator) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: companyInternshipsFilters } = useQuery(
		[getInternshipsFilters, isProfessor],
		() => {
			return getAllCompanyInternshipFilters();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: professorInternships, isLoading: loadingProfessorInternships } =
		useQuery(
			[
				getProfessorInternships,
				isProfessor,
				isStudent,
				props.location.pathname,
				skills,
				types,
				categories,
				locations,
				statuses,
				searchTerm,
				currentPage,
				pageSize,
				sortField,
				sortDirection,
				applicabilityTypes,
				validationStates,
				universityYears,
			],
			() => {
				if (
					isProfessor ||
					(isStudent && props.location.pathname === "/propuneri-facultati")
				) {
					return getAllProfessorInternship(
						skills,
						categories,
						types,
						statuses,
						applicabilityTypes,
						validationStates,
						universityYears,
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
					);
				}
			},
			{
				onError: openGetErrorNotification,
				refetchOnWindowFocus: false,
			}
		);

	const {
		data: coordinatedProfessorInternships,
		isLoading: loadingCoordinatedProfessorInternships,
	} = useQuery(
		[
			getCoordinatedInternships,
			isProfessor,
			isFacultyAdmin,
			props.location.pathname,
			skills,
			types,
			categories,
			locations,
			statuses,
			universityYears,
			searchTerm,
			currentPage,
			pageSize,
			sortField,
			sortDirection,
		],
		() => {
			if (isFacultyAdmin) {
				getAllProfessors(searchTerm, currentPage, pageSize)
					.then((response: any) => {
						setProfessorOptions(() => {
							return response.data;
						});
					})
					.catch((ex) => {
						openGetErrorNotification(ex);
					});
			}
			if (isProfessor) {
				return getCoordinatedProfessorInternships(
					skills,
					categories,
					locations,
					types,
					statuses,
					universityYears,
					searchTerm,
					currentPage,
					pageSize,
					sortField,
					sortDirection
				);
			}
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: facultyInternships, isLoading: loadingFacultyInternships } =
		useQuery(
			[
				getFacultyInternships,
				isDean,
				isFacultyAdmin,
				props.location.pathname,
				skills,
				types,
				categories,
				statuses,
				validationStates,
				universityYears,
				searchTerm,
				currentPage,
				pageSize,
				sortField,
				sortDirection,
			],
			() => {
				if (isDean || isFacultyAdmin) {
					return getAllProfessorInternshipsForFaculty(
						skills,
						categories,
						types,
						statuses,
						validationStates,
						universityYears,
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
					);
				}
			},
			{
				onError: openGetErrorNotification,
				refetchOnWindowFocus: false,
			}
		);

	const {
		data: departmentInternships,
		isLoading: loadingDepartmentInternships,
	} = useQuery(
		[
			getDepartmentInternships,
			isProfessor,
			isDepartmentDirector,
			props.location.pathname,
			skills,
			types,
			categories,
			statuses,
			validationStates,
			universityYears,
			searchTerm,
			currentPage,
			pageSize,
			sortField,
			sortDirection,
		],
		() => {
			if (isProfessor || isDepartmentDirector) {
				return getAllProfessorInternshipsForDepartment(
					skills,
					categories,
					types,
					statuses,
					validationStates,
					universityYears,
					searchTerm,
					currentPage,
					pageSize,
					sortField,
					sortDirection
				);
			}
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const {
		data: studyProgramInternships,
		isLoading: loadingStudyProgramInternships,
	} = useQuery(
		[
			getStudyProgramInternships,
			isStudyProgramCoordinator,
			props.location.pathname,
			skills,
			types,
			categories,
			statuses,
			validationStates,
			universityYears,
			searchTerm,
			currentPage,
			pageSize,
			sortField,
			sortDirection,
		],
		() => {
			if (isStudyProgramCoordinator) {
				return getAllProfessorInternshipsForStudyProgram(
					skills,
					categories,
					types,
					statuses,
					validationStates,
					universityYears,
					searchTerm,
					currentPage,
					pageSize,
					sortField,
					sortDirection
				);
			}
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: companyInternships, isLoading: loadingCompanyInternships } =
		useQuery(
			[
				getCompanyInternships,
				currentPage,
				pageSize,
				sortField,
				sortDirection,
				skills,
				types,
				categories,
				locations,
				statuses,
				states,
				universityYears,
				searchTerm,
				props.location.pathname,
				isFacultyAdmin,
				isProfessor,
				faculties,
				departments,
			],
			() => {
				if (isFacultyAdmin) {
					getAllProfessors(
						searchTerm,
						currentPage,
						pageSize,
						faculties,
						departments
					)
						.then((response: any) => {
							setProfessorOptions(() => {
								return response.data;
							});
						})
						.catch((ex) => {
							openGetErrorNotification(ex);
						});
					return getAllCompanyInternship(
						skills,
						categories,
						locations,
						types,
						statuses,
						states,
						universityYears,
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
					);
				} else if (isProfessor) {
					return getAllCompanyInternship(
						skills,
						categories,
						locations,
						types,
						statuses,
						states,
						universityYears,
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
					);
				}
			},
			{
				onError: openGetErrorNotification,
				refetchOnWindowFocus: false,
			}
		);

	const { data: dean } = useQuery(
		[isDean],
		() => {
			if (isDean) {
				return getProfessorById(getUserId());
			}
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: studyProgramsList } = useQuery(
		[getStudyProgramsForFaculties, isDean, dean],
		() => {
			if (isDean) {
				return getStudyProgramsForFaculties([dean?.faculty?.id!]);
			}
		},
		{
			onError: (err) => {
				openNotification(
					t("internships.addInternshipForm.studyProgramsError"),
					t("internships.addInternshipForm.notFoundContent"),
					NOTIFICATION_TYPES.ERROR
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters?.map((x) => {
			if (x.key === "Location" || x.key === "UniversityYear") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: y.title,
						}))
					),
				};
			} else if (x.key === "Applicability") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title:
								y.title === "True"
									? t("internships.filters.isViewOnly")
									: t("internships.filters.canApply"),
						}))
					),
				};
			} else if (x.key === "ValidationState") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title:
								y.title === "True"
									? t("internships.filters.valid")
									: t("internships.filters.invalid"),
						}))
					),
				};
			} else {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("internships.filters." + y.title),
						}))
					),
				};
			}
		});
	};

	const changeSortField = (value: any) => {
		setSortField(value);
	};

	const changeSortDirection = () => {
		if (sortDirection === SortDirectionEnum.Ascending) {
			setSortDirection(SortDirectionEnum.Descending);
		} else {
			setSortDirection(SortDirectionEnum.Ascending);
		}
	};

	const changeTab = (activeKey: any) => {
		setActiveTab(activeKey);
		setCheckedKeys([]);
		setTypes([]);
		setLocations([]);
		setApplicabilityTypes([]);
		setValidationStates([]);
		setStatuses([]);
		setStates([]);
	};

	return (
		<Layout>
			<Row>
				<Col span={4} className={styles.filters}>
					{!isStudent && (
						<CustomButton
							backgroundcolor={theme.primaryColor}
							textcolor={theme.white}
							fontSize={"1rem"}
							paddingvertical={"1.2rem"}
							paddinghorizontal={"1.4rem"}
							marginleft={"auto"}
							marginright={"auto"}
							margintop={"2rem"}
						>
							<Link
								to={{
									pathname: "/editare-propunere/",
									state: {
										filters: checkedKeys,
										searchTerm: searchTerm,
										sortField: sortField,
										sortDirection: sortDirection,
										currentPage: currentPage,
										pageSize: pageSize,
										activeTab: activeTab,
									},
								}}
							>
								{t("internships.addInternship")}
							</Link>
						</CustomButton>
					)}
					<Search
						defaultValue={searchTerm}
						className={styles.searchBox}
						placeholder={t("internships.search")}
						allowClear
						onSearch={setSearchTerm}
						style={{ width: 200 }}
					/>
					<CustomSort
						changeSortField={changeSortField}
						changeSortDirection={changeSortDirection}
						sortDirection={sortDirection}
						location={props.location}
						oldValue={sortField}
					/>
					<CustomFilter
						filters={
							activeTab === "1"
								? filters!
								: activeTab === "2"
								? coordinatedInternshipsFilters!
								: activeTab === "3"
								? facultyInternshipsFilters
								: activeTab === "4"
								? departmentInternshipsFilters
								: activeTab === "5"
								? studyProgramInternshipsFilters
								: companyInternshipsFilters
						}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Col>
				<Drawer
					className={styles.opened}
					placement={"left"}
					closable={true}
					onClose={onClose}
					visible={visible}
					key={"left"}
					maskClosable={true}
					title={t("internships.filters.filters")}
				>
					<Search
						defaultValue={searchTerm}
						placeholder={t("internships.search")}
						allowClear
						onSearch={setSearchTerm}
					/>
					<CustomSort
						changeSortField={changeSortField}
						changeSortDirection={changeSortDirection}
						sortDirection={sortDirection}
						location={props.location}
					/>
					<CustomFilter
						filters={
							activeTab === "2" ? coordinatedInternshipsFilters : filters
						}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Drawer>
				<Row justify="space-between" className={styles.filterButton}>
					{!isStudent && (
						<Col
							span={window.innerWidth > 350 ? 12 : 24}
							style={{
								display: "flex",
								justifyContent:
									window.innerWidth > 350 ? "flex-start" : "center",
								marginBottom: window.innerWidth < 351 ? "1rem" : 0,
							}}
						>
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								fontSize={"1rem"}
								paddingvertical={"1.2rem"}
								paddinghorizontal={"1.4rem"}
								margintop={"0"}
							>
								<Link
									to={{
										pathname: "/editare-propunere/",
										state: {
											filters: checkedKeys,
											searchTerm: searchTerm,
											sortField: sortField,
											sortDirection: sortDirection,
											currentPage: currentPage,
											pageSize: pageSize,
											activeTab: activeTab,
										},
									}}
								>
									{t("internships.addInternship")}
								</Link>
							</CustomButton>
						</Col>
					)}
					<Col
						span={window.innerWidth > 350 ? 12 : 24}
						style={{
							display: "flex",
							justifyContent:
								window.innerWidth < 351
									? "center"
									: isStudent
									? "flex-start"
									: "flex-end",
						}}
					>
						<Button
							onClick={showDrawer}
							style={{ outline: "none", border: "none", boxShadow: "none" }}
						>
							<FontAwesomeIcon
								icon={solid("filter")}
								style={{ color: theme.secondColor }}
							/>
							&nbsp;{t("internships.filters.filter")}
						</Button>
					</Col>
				</Row>
				<Col
					span={window.innerWidth > 1100 ? 20 : 24}
					style={{ padding: "0 0 0 3%", marginTop: "20px" }}
				>
					{isProfessor && props.location.pathname === "/propuneri" ? (
						<Tabs
							activeKey={activeTab}
							centered
							animated={{ inkBar: false }}
							tabBarStyle={{
								display: "flex",
								justifyContent: "space-evenly",
								alignItems: "center",
							}}
							className={styles.displayFlex}
							onChange={changeTab}
						>
							<TabPane tab={t("header.professorInternships")} key="1">
								<InternshipsList
									data={professorInternships}
									checkedKeys={checkedKeys}
									loading={loadingProfessorInternships}
									location={props.location}
									activeTab={"1"}
									currentPage={currentPage}
									pageSize={pageSize}
									searchTerm={searchTerm}
									sortDirection={sortDirection}
									sortField={sortField}
									handleListChange={handleListChange}
								/>
							</TabPane>
							<TabPane tab={t("internships.coordinatedByMe")} key="2">
								<InternshipsList
									data={coordinatedProfessorInternships}
									professorOptions={professorOptions}
									checkedKeys={checkedKeys}
									loading={loadingCoordinatedProfessorInternships}
									location={props.location}
									activeTab={"2"}
									currentPage={currentPage}
									pageSize={pageSize}
									searchTerm={searchTerm}
									sortDirection={sortDirection}
									sortField={sortField}
									handleListChange={handleListChange}
									studyProgramsList={studyProgramsList}
								/>
							</TabPane>
							{(isDean || isFacultyAdmin) && (
								<TabPane tab={t("internships.facultyProposals")} key="3">
									<Row style={{ width: "100%", marginTop: "1em" }}>
										<CustomButton
											onClick={downloadReportFaculty}
											marginbottom={"1em"}
											margintop={"0"}
										>
											{t("reports.download")}
										</CustomButton>
										{downloading && (
											<Progress
												percent={progress}
												status={downloadError ? "exception" : undefined}
											/>
										)}
									</Row>
									<InternshipsList
										data={facultyInternships}
										checkedKeys={checkedKeys}
										loading={loadingFacultyInternships}
										location={props.location}
										activeTab={"3"}
										currentPage={currentPage}
										pageSize={pageSize}
										searchTerm={searchTerm}
										sortDirection={sortDirection}
										sortField={sortField}
										handleListChange={handleListChange}
										studyProgramsList={studyProgramsList}
									/>
								</TabPane>
							)}
							{(isProfessor ||
								isDepartmentDirector ||
								isDepartmentSecretary) && (
								<TabPane tab={t("internships.departmentProposals")} key="4">
									<Row style={{ width: "100%", marginTop: "1em" }}>
										<CustomButton
											onClick={downloadReportDepartment}
											marginbottom={"1em"}
											margintop={"0"}
										>
											{t("reports.download")}
										</CustomButton>
										{downloading && (
											<Progress
												percent={progress}
												status={downloadError ? "exception" : undefined}
											/>
										)}
									</Row>
									<InternshipsList
										data={departmentInternships}
										checkedKeys={checkedKeys}
										loading={loadingDepartmentInternships}
										location={props.location}
										activeTab={"4"}
										currentPage={currentPage}
										pageSize={pageSize}
										searchTerm={searchTerm}
										sortDirection={sortDirection}
										sortField={sortField}
										handleListChange={handleListChange}
										studyProgramsList={studyProgramsList}
									/>
								</TabPane>
							)}
							{isStudyProgramCoordinator && (
								<TabPane tab={t("internships.studyProgramProposals")} key="5">
									<InternshipsList
										data={studyProgramInternships}
										checkedKeys={checkedKeys}
										loading={loadingStudyProgramInternships}
										location={props.location}
										activeTab={"5"}
										currentPage={currentPage}
										pageSize={pageSize}
										searchTerm={searchTerm}
										sortDirection={sortDirection}
										sortField={sortField}
										handleListChange={handleListChange}
										studyProgramsList={studyProgramsList}
									/>
								</TabPane>
							)}
							{isProfessor && (
								<TabPane tab={t("internships.companyProposals")} key="6">
									<InternshipsList
										data={companyInternships}
										professorOptions={professorOptions}
										checkedKeys={checkedKeys}
										loading={loadingCompanyInternships}
										location={props.location}
										activeTab={"6"}
										currentPage={currentPage}
										pageSize={pageSize}
										searchTerm={searchTerm}
										sortDirection={sortDirection}
										sortField={sortField}
										handleListChange={handleListChange}
										studyProgramsList={studyProgramsList}
									/>
								</TabPane>
							)}
						</Tabs>
					) : (
						<InternshipsList
							data={professorInternships}
							checkedKeys={checkedKeys}
							loading={loadingProfessorInternships}
							location={props.location}
							activeTab={"2"}
							currentPage={currentPage}
							pageSize={pageSize}
							searchTerm={searchTerm}
							sortDirection={sortDirection}
							sortField={sortField}
							handleListChange={handleListChange}
						/>
					)}
				</Col>
			</Row>
		</Layout>
	);
};

export default FacultyInternships;

function dispatch(arg0: any) {
	throw new Error("Function not implemented.");
}
