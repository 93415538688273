/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    CompanyUser,
    CompanyUserFromJSON,
    CompanyUserFromJSONTyped,
    CompanyUserToJSON,
} from './CompanyUser';
import {
    EnrollmentStatus,
    EnrollmentStatusFromJSON,
    EnrollmentStatusFromJSONTyped,
    EnrollmentStatusToJSON,
} from './EnrollmentStatus';
import {
    Internship,
    InternshipFromJSON,
    InternshipFromJSONTyped,
    InternshipToJSON,
} from './Internship';
import {
    InternshipDocument,
    InternshipDocumentFromJSON,
    InternshipDocumentFromJSONTyped,
    InternshipDocumentToJSON,
} from './InternshipDocument';
import {
    PracticeCertificate,
    PracticeCertificateFromJSON,
    PracticeCertificateFromJSONTyped,
    PracticeCertificateToJSON,
} from './PracticeCertificate';
import {
    PracticeConvention,
    PracticeConventionFromJSON,
    PracticeConventionFromJSONTyped,
    PracticeConventionToJSON,
} from './PracticeConvention';
import {
    PracticeNotebook,
    PracticeNotebookFromJSON,
    PracticeNotebookFromJSONTyped,
    PracticeNotebookToJSON,
} from './PracticeNotebook';

/**
 * 
 * @export
 * @interface InternshipEnrollment
 */
export interface InternshipEnrollment {
    /**
     * 
     * @type {string}
     * @memberof InternshipEnrollment
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InternshipEnrollment
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InternshipEnrollment
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InternshipEnrollment
     */
    internshipId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternshipEnrollment
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternshipEnrollment
     */
    tutorId?: string | null;
    /**
     * 
     * @type {EnrollmentStatus}
     * @memberof InternshipEnrollment
     */
    studentStatus?: EnrollmentStatus;
    /**
     * 
     * @type {EnrollmentStatus}
     * @memberof InternshipEnrollment
     */
    recruiterStatus?: EnrollmentStatus;
    /**
     * 
     * @type {number}
     * @memberof InternshipEnrollment
     */
    preference?: number;
    /**
     * 
     * @type {Internship}
     * @memberof InternshipEnrollment
     */
    internship?: Internship;
    /**
     * 
     * @type {AcademicUser}
     * @memberof InternshipEnrollment
     */
    student?: AcademicUser;
    /**
     * 
     * @type {CompanyUser}
     * @memberof InternshipEnrollment
     */
    tutor?: CompanyUser;
    /**
     * 
     * @type {PracticeConvention}
     * @memberof InternshipEnrollment
     */
    practiceConvention?: PracticeConvention;
    /**
     * 
     * @type {PracticeNotebook}
     * @memberof InternshipEnrollment
     */
    practiceNotebook?: PracticeNotebook;
    /**
     * 
     * @type {InternshipDocument}
     * @memberof InternshipEnrollment
     */
    internshipDocument?: InternshipDocument;
    /**
     * 
     * @type {PracticeCertificate}
     * @memberof InternshipEnrollment
     */
    practiceCertificate?: PracticeCertificate;
    /**
     * 
     * @type {string}
     * @memberof InternshipEnrollment
     */
    laboratoryPracticeRoom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof InternshipEnrollment
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof InternshipEnrollment
     */
    notificationSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InternshipEnrollment
     */
    isEnrollmentViewed?: boolean;
}

export function InternshipEnrollmentFromJSON(json: any): InternshipEnrollment {
    return InternshipEnrollmentFromJSONTyped(json, false);
}

export function InternshipEnrollmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipEnrollment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'internshipId': !exists(json, 'internshipId') ? undefined : json['internshipId'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'tutorId': !exists(json, 'tutorId') ? undefined : json['tutorId'],
        'studentStatus': !exists(json, 'studentStatus') ? undefined : EnrollmentStatusFromJSON(json['studentStatus']),
        'recruiterStatus': !exists(json, 'recruiterStatus') ? undefined : EnrollmentStatusFromJSON(json['recruiterStatus']),
        'preference': !exists(json, 'preference') ? undefined : json['preference'],
        'internship': !exists(json, 'internship') ? undefined : InternshipFromJSON(json['internship']),
        'student': !exists(json, 'student') ? undefined : AcademicUserFromJSON(json['student']),
        'tutor': !exists(json, 'tutor') ? undefined : CompanyUserFromJSON(json['tutor']),
        'practiceConvention': !exists(json, 'practiceConvention') ? undefined : PracticeConventionFromJSON(json['practiceConvention']),
        'practiceNotebook': !exists(json, 'practiceNotebook') ? undefined : PracticeNotebookFromJSON(json['practiceNotebook']),
        'internshipDocument': !exists(json, 'internshipDocument') ? undefined : InternshipDocumentFromJSON(json['internshipDocument']),
        'practiceCertificate': !exists(json, 'practiceCertificate') ? undefined : PracticeCertificateFromJSON(json['practiceCertificate']),
        'laboratoryPracticeRoom': !exists(json, 'laboratoryPracticeRoom') ? undefined : json['laboratoryPracticeRoom'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (json['expirationDate'] === null ? null : new Date(json['expirationDate'])),
        'notificationSent': !exists(json, 'notificationSent') ? undefined : json['notificationSent'],
        'isEnrollmentViewed': !exists(json, 'isEnrollmentViewed') ? undefined : json['isEnrollmentViewed'],
    };
}

export function InternshipEnrollmentToJSON(value?: InternshipEnrollment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'internshipId': value.internshipId,
        'studentId': value.studentId,
        'tutorId': value.tutorId,
        'studentStatus': EnrollmentStatusToJSON(value.studentStatus),
        'recruiterStatus': EnrollmentStatusToJSON(value.recruiterStatus),
        'preference': value.preference,
        'internship': InternshipToJSON(value.internship),
        'student': AcademicUserToJSON(value.student),
        'tutor': CompanyUserToJSON(value.tutor),
        'practiceConvention': PracticeConventionToJSON(value.practiceConvention),
        'practiceNotebook': PracticeNotebookToJSON(value.practiceNotebook),
        'internshipDocument': InternshipDocumentToJSON(value.internshipDocument),
        'practiceCertificate': PracticeCertificateToJSON(value.practiceCertificate),
        'laboratoryPracticeRoom': value.laboratoryPracticeRoom,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
        'notificationSent': value.notificationSent,
        'isEnrollmentViewed': value.isEnrollmentViewed,
    };
}

