/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PracticeConventionDTO,
    PracticeConventionDTOFromJSON,
    PracticeConventionDTOToJSON,
    RequestResponse,
    RequestResponseFromJSON,
    RequestResponseToJSON,
} from '../models';

export interface ApiPracticeConventionAddSignatureIdPostRequest {
    id: string;
    file?: Blob;
}

export interface ApiPracticeConventionChangeSupervisionStatusIdPostRequest {
    id: string;
    supervise?: boolean;
}

export interface ApiPracticeConventionDownloadConventionEnrollmentIdGetRequest {
    enrollmentId: string;
}

export interface ApiPracticeConventionDownloadFileSignatureIdGetRequest {
    signatureId: string;
}

export interface ApiPracticeConventionGenerateConventionIdGetRequest {
    conventionId: string;
}

export interface ApiPracticeConventionGetForEnrollmentInternshipEnrollmentIdGetRequest {
    internshipEnrollmentId: string;
}

export interface ApiPracticeConventionValidateIdPutRequest {
    id: string;
}

/**
 * 
 */
export class PracticeConventionApi extends runtime.BaseAPI {

    /**
     */
    async apiPracticeConventionAddSignatureIdPostRaw(requestParameters: ApiPracticeConventionAddSignatureIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeConventionAddSignatureIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/PracticeConvention/AddSignature/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeConventionAddSignatureIdPost(requestParameters: ApiPracticeConventionAddSignatureIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeConventionAddSignatureIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeConventionChangeSupervisionStatusIdPostRaw(requestParameters: ApiPracticeConventionChangeSupervisionStatusIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeConventionChangeSupervisionStatusIdPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.supervise !== undefined) {
            queryParameters['supervise'] = requestParameters.supervise;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeConvention/ChangeSupervisionStatus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPracticeConventionChangeSupervisionStatusIdPost(requestParameters: ApiPracticeConventionChangeSupervisionStatusIdPostRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiPracticeConventionChangeSupervisionStatusIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeConventionDownloadConventionEnrollmentIdGetRaw(requestParameters: ApiPracticeConventionDownloadConventionEnrollmentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.enrollmentId === null || requestParameters.enrollmentId === undefined) {
            throw new runtime.RequiredError('enrollmentId','Required parameter requestParameters.enrollmentId was null or undefined when calling apiPracticeConventionDownloadConventionEnrollmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeConvention/DownloadConvention/{enrollmentId}`.replace(`{${"enrollmentId"}}`, encodeURIComponent(String(requestParameters.enrollmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeConventionDownloadConventionEnrollmentIdGet(requestParameters: ApiPracticeConventionDownloadConventionEnrollmentIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeConventionDownloadConventionEnrollmentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeConventionDownloadFileSignatureIdGetRaw(requestParameters: ApiPracticeConventionDownloadFileSignatureIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.signatureId === null || requestParameters.signatureId === undefined) {
            throw new runtime.RequiredError('signatureId','Required parameter requestParameters.signatureId was null or undefined when calling apiPracticeConventionDownloadFileSignatureIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeConvention/DownloadFile/{signatureId}`.replace(`{${"signatureId"}}`, encodeURIComponent(String(requestParameters.signatureId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeConventionDownloadFileSignatureIdGet(requestParameters: ApiPracticeConventionDownloadFileSignatureIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeConventionDownloadFileSignatureIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeConventionGenerateConventionIdGetRaw(requestParameters: ApiPracticeConventionGenerateConventionIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.conventionId === null || requestParameters.conventionId === undefined) {
            throw new runtime.RequiredError('conventionId','Required parameter requestParameters.conventionId was null or undefined when calling apiPracticeConventionGenerateConventionIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeConvention/Generate/{conventionId}`.replace(`{${"conventionId"}}`, encodeURIComponent(String(requestParameters.conventionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeConventionGenerateConventionIdGet(requestParameters: ApiPracticeConventionGenerateConventionIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeConventionGenerateConventionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeConventionGetForEnrollmentInternshipEnrollmentIdGetRaw(requestParameters: ApiPracticeConventionGetForEnrollmentInternshipEnrollmentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeConventionDTO>> {
        if (requestParameters.internshipEnrollmentId === null || requestParameters.internshipEnrollmentId === undefined) {
            throw new runtime.RequiredError('internshipEnrollmentId','Required parameter requestParameters.internshipEnrollmentId was null or undefined when calling apiPracticeConventionGetForEnrollmentInternshipEnrollmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeConvention/GetForEnrollment/{internshipEnrollmentId}`.replace(`{${"internshipEnrollmentId"}}`, encodeURIComponent(String(requestParameters.internshipEnrollmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeConventionDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiPracticeConventionGetForEnrollmentInternshipEnrollmentIdGet(requestParameters: ApiPracticeConventionGetForEnrollmentInternshipEnrollmentIdGetRequest, initOverrides?: RequestInit): Promise<PracticeConventionDTO> {
        const response = await this.apiPracticeConventionGetForEnrollmentInternshipEnrollmentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeConventionValidateIdPutRaw(requestParameters: ApiPracticeConventionValidateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeConventionValidateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeConvention/Validate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeConventionValidateIdPut(requestParameters: ApiPracticeConventionValidateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeConventionValidateIdPutRaw(requestParameters, initOverrides);
    }

}
