/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PracticeCertificate,
    PracticeCertificateFromJSON,
    PracticeCertificateFromJSONTyped,
    PracticeCertificateToJSON,
} from './PracticeCertificate';
import {
    PracticeCertificateSigningEnum,
    PracticeCertificateSigningEnumFromJSON,
    PracticeCertificateSigningEnumFromJSONTyped,
    PracticeCertificateSigningEnumToJSON,
} from './PracticeCertificateSigningEnum';

/**
 * 
 * @export
 * @interface PracticeCertificateSignature
 */
export interface PracticeCertificateSignature {
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificateSignature
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PracticeCertificateSignature
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PracticeCertificateSignature
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificateSignature
     */
    practiceCertificateId?: string;
    /**
     * 
     * @type {PracticeCertificateSigningEnum}
     * @memberof PracticeCertificateSignature
     */
    signer?: PracticeCertificateSigningEnum;
    /**
     * 
     * @type {string}
     * @memberof PracticeCertificateSignature
     */
    fileName?: string | null;
    /**
     * 
     * @type {PracticeCertificate}
     * @memberof PracticeCertificateSignature
     */
    practiceCertificate?: PracticeCertificate;
}

export function PracticeCertificateSignatureFromJSON(json: any): PracticeCertificateSignature {
    return PracticeCertificateSignatureFromJSONTyped(json, false);
}

export function PracticeCertificateSignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeCertificateSignature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'practiceCertificateId': !exists(json, 'practiceCertificateId') ? undefined : json['practiceCertificateId'],
        'signer': !exists(json, 'signer') ? undefined : PracticeCertificateSigningEnumFromJSON(json['signer']),
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'practiceCertificate': !exists(json, 'practiceCertificate') ? undefined : PracticeCertificateFromJSON(json['practiceCertificate']),
    };
}

export function PracticeCertificateSignatureToJSON(value?: PracticeCertificateSignature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'practiceCertificateId': value.practiceCertificateId,
        'signer': PracticeCertificateSigningEnumToJSON(value.signer),
        'fileName': value.fileName,
        'practiceCertificate': PracticeCertificateToJSON(value.practiceCertificate),
    };
}

