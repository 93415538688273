/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternshipLimitsAddUpdateDTO,
    InternshipLimitsAddUpdateDTOFromJSON,
    InternshipLimitsAddUpdateDTOFromJSONTyped,
    InternshipLimitsAddUpdateDTOToJSON,
} from './InternshipLimitsAddUpdateDTO';
import {
    PracticeInternshipPeriodAddDTO,
    PracticeInternshipPeriodAddDTOFromJSON,
    PracticeInternshipPeriodAddDTOFromJSONTyped,
    PracticeInternshipPeriodAddDTOToJSON,
} from './PracticeInternshipPeriodAddDTO';

/**
 * 
 * @export
 * @interface FacultySettingsAddDTO
 */
export interface FacultySettingsAddDTO {
    /**
     * 
     * @type {string}
     * @memberof FacultySettingsAddDTO
     */
    decisionNumber?: string | null;
    /**
     * 
     * @type {Array<PracticeInternshipPeriodAddDTO>}
     * @memberof FacultySettingsAddDTO
     */
    practiceInternshipPeriods?: Array<PracticeInternshipPeriodAddDTO> | null;
    /**
     * 
     * @type {Array<InternshipLimitsAddUpdateDTO>}
     * @memberof FacultySettingsAddDTO
     */
    internshipLimits?: Array<InternshipLimitsAddUpdateDTO> | null;
}

export function FacultySettingsAddDTOFromJSON(json: any): FacultySettingsAddDTO {
    return FacultySettingsAddDTOFromJSONTyped(json, false);
}

export function FacultySettingsAddDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacultySettingsAddDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decisionNumber': !exists(json, 'decisionNumber') ? undefined : json['decisionNumber'],
        'practiceInternshipPeriods': !exists(json, 'practiceInternshipPeriods') ? undefined : (json['practiceInternshipPeriods'] === null ? null : (json['practiceInternshipPeriods'] as Array<any>).map(PracticeInternshipPeriodAddDTOFromJSON)),
        'internshipLimits': !exists(json, 'internshipLimits') ? undefined : (json['internshipLimits'] === null ? null : (json['internshipLimits'] as Array<any>).map(InternshipLimitsAddUpdateDTOFromJSON)),
    };
}

export function FacultySettingsAddDTOToJSON(value?: FacultySettingsAddDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decisionNumber': value.decisionNumber,
        'practiceInternshipPeriods': value.practiceInternshipPeriods === undefined ? undefined : (value.practiceInternshipPeriods === null ? null : (value.practiceInternshipPeriods as Array<any>).map(PracticeInternshipPeriodAddDTOToJSON)),
        'internshipLimits': value.internshipLimits === undefined ? undefined : (value.internshipLimits === null ? null : (value.internshipLimits as Array<any>).map(InternshipLimitsAddUpdateDTOToJSON)),
    };
}

