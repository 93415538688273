/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Answer,
    AnswerFromJSON,
    AnswerFromJSONTyped,
    AnswerToJSON,
} from './Answer';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import {
    CompanyInternship,
    CompanyInternshipFromJSON,
    CompanyInternshipFromJSONTyped,
    CompanyInternshipToJSON,
} from './CompanyInternship';
import {
    EmailRecipient,
    EmailRecipientFromJSON,
    EmailRecipientFromJSONTyped,
    EmailRecipientToJSON,
} from './EmailRecipient';
import {
    InternshipEnrollment,
    InternshipEnrollmentFromJSON,
    InternshipEnrollmentFromJSONTyped,
    InternshipEnrollmentToJSON,
} from './InternshipEnrollment';
import {
    PasswordAction,
    PasswordActionFromJSON,
    PasswordActionFromJSONTyped,
    PasswordActionToJSON,
} from './PasswordAction';
import {
    PushNotificationSubscription,
    PushNotificationSubscriptionFromJSON,
    PushNotificationSubscriptionFromJSONTyped,
    PushNotificationSubscriptionToJSON,
} from './PushNotificationSubscription';
import {
    RefreshToken,
    RefreshTokenFromJSON,
    RefreshTokenFromJSONTyped,
    RefreshTokenToJSON,
} from './RefreshToken';

/**
 * 
 * @export
 * @interface CompanyUser
 */
export interface CompanyUser {
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyUser
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    companyId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    isRepresentative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    isContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    isManaging?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    _function?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    department?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    sendNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    requirePasswordChange?: boolean;
    /**
     * 
     * @type {Company}
     * @memberof CompanyUser
     */
    company?: Company;
    /**
     * 
     * @type {RefreshToken}
     * @memberof CompanyUser
     */
    token?: RefreshToken;
    /**
     * 
     * @type {Array<PushNotificationSubscription>}
     * @memberof CompanyUser
     */
    pushNotificationSubscriptions?: Array<PushNotificationSubscription> | null;
    /**
     * 
     * @type {Array<PasswordAction>}
     * @memberof CompanyUser
     */
    passwordActions?: Array<PasswordAction> | null;
    /**
     * 
     * @type {Array<CompanyInternship>}
     * @memberof CompanyUser
     */
    companyInternshipsAsTutor?: Array<CompanyInternship> | null;
    /**
     * 
     * @type {Array<EmailRecipient>}
     * @memberof CompanyUser
     */
    receivedNewsletterEmails?: Array<EmailRecipient> | null;
    /**
     * 
     * @type {Array<InternshipEnrollment>}
     * @memberof CompanyUser
     */
    coordinatedStudentEnrollments?: Array<InternshipEnrollment> | null;
    /**
     * 
     * @type {Array<Answer>}
     * @memberof CompanyUser
     */
    feedbackAnswers?: Array<Answer> | null;
}

export function CompanyUserFromJSON(json: any): CompanyUser {
    return CompanyUserFromJSONTyped(json, false);
}

export function CompanyUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'isRepresentative': !exists(json, 'isRepresentative') ? undefined : json['isRepresentative'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'isContact': !exists(json, 'isContact') ? undefined : json['isContact'],
        'isManaging': !exists(json, 'isManaging') ? undefined : json['isManaging'],
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'sendNotifications': !exists(json, 'sendNotifications') ? undefined : json['sendNotifications'],
        'requirePasswordChange': !exists(json, 'requirePasswordChange') ? undefined : json['requirePasswordChange'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'token': !exists(json, 'token') ? undefined : RefreshTokenFromJSON(json['token']),
        'pushNotificationSubscriptions': !exists(json, 'pushNotificationSubscriptions') ? undefined : (json['pushNotificationSubscriptions'] === null ? null : (json['pushNotificationSubscriptions'] as Array<any>).map(PushNotificationSubscriptionFromJSON)),
        'passwordActions': !exists(json, 'passwordActions') ? undefined : (json['passwordActions'] === null ? null : (json['passwordActions'] as Array<any>).map(PasswordActionFromJSON)),
        'companyInternshipsAsTutor': !exists(json, 'companyInternshipsAsTutor') ? undefined : (json['companyInternshipsAsTutor'] === null ? null : (json['companyInternshipsAsTutor'] as Array<any>).map(CompanyInternshipFromJSON)),
        'receivedNewsletterEmails': !exists(json, 'receivedNewsletterEmails') ? undefined : (json['receivedNewsletterEmails'] === null ? null : (json['receivedNewsletterEmails'] as Array<any>).map(EmailRecipientFromJSON)),
        'coordinatedStudentEnrollments': !exists(json, 'coordinatedStudentEnrollments') ? undefined : (json['coordinatedStudentEnrollments'] === null ? null : (json['coordinatedStudentEnrollments'] as Array<any>).map(InternshipEnrollmentFromJSON)),
        'feedbackAnswers': !exists(json, 'feedbackAnswers') ? undefined : (json['feedbackAnswers'] === null ? null : (json['feedbackAnswers'] as Array<any>).map(AnswerFromJSON)),
    };
}

export function CompanyUserToJSON(value?: CompanyUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'email': value.email,
        'password': value.password,
        'name': value.name,
        'companyId': value.companyId,
        'isRepresentative': value.isRepresentative,
        'isAdmin': value.isAdmin,
        'isContact': value.isContact,
        'isManaging': value.isManaging,
        'function': value._function,
        'phone': value.phone,
        'department': value.department,
        'sendNotifications': value.sendNotifications,
        'requirePasswordChange': value.requirePasswordChange,
        'company': CompanyToJSON(value.company),
        'token': RefreshTokenToJSON(value.token),
        'pushNotificationSubscriptions': value.pushNotificationSubscriptions === undefined ? undefined : (value.pushNotificationSubscriptions === null ? null : (value.pushNotificationSubscriptions as Array<any>).map(PushNotificationSubscriptionToJSON)),
        'passwordActions': value.passwordActions === undefined ? undefined : (value.passwordActions === null ? null : (value.passwordActions as Array<any>).map(PasswordActionToJSON)),
        'companyInternshipsAsTutor': value.companyInternshipsAsTutor === undefined ? undefined : (value.companyInternshipsAsTutor === null ? null : (value.companyInternshipsAsTutor as Array<any>).map(CompanyInternshipToJSON)),
        'receivedNewsletterEmails': value.receivedNewsletterEmails === undefined ? undefined : (value.receivedNewsletterEmails === null ? null : (value.receivedNewsletterEmails as Array<any>).map(EmailRecipientToJSON)),
        'coordinatedStudentEnrollments': value.coordinatedStudentEnrollments === undefined ? undefined : (value.coordinatedStudentEnrollments === null ? null : (value.coordinatedStudentEnrollments as Array<any>).map(InternshipEnrollmentToJSON)),
        'feedbackAnswers': value.feedbackAnswers === undefined ? undefined : (value.feedbackAnswers === null ? null : (value.feedbackAnswers as Array<any>).map(AnswerToJSON)),
    };
}

