/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudentsReportDTO
 */
export interface StudentsReportDTO {
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    studentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    facultyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    groupName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    domainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    studyYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    studyProgram?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r4?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r5?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r6?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r7?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r8?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r9?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r10?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r11?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r12?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r14?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r15?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r16?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r17?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r18?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r19?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r20?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r21?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r22?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r23?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r24?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r25?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r26?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r27?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r28?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r29?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r30?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r31?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r32?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r33?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r34?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r35?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r36?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r37?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r38?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r39?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r40?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r41?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r42?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r43?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r44?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r45?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r46?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r47?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r48?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r49?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r50?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r51?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r52?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r53?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r54?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r55?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r56?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r57?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r58?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r59?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r60?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r61?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r62?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentsReportDTO
     */
    r63?: string | null;
}

export function StudentsReportDTOFromJSON(json: any): StudentsReportDTO {
    return StudentsReportDTOFromJSONTyped(json, false);
}

export function StudentsReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentsReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentName': !exists(json, 'studentName') ? undefined : json['studentName'],
        'facultyName': !exists(json, 'facultyName') ? undefined : json['facultyName'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'domainName': !exists(json, 'domainName') ? undefined : json['domainName'],
        'studyYear': !exists(json, 'studyYear') ? undefined : json['studyYear'],
        'studyProgram': !exists(json, 'studyProgram') ? undefined : json['studyProgram'],
        'r1': !exists(json, 'r1') ? undefined : json['r1'],
        'r2': !exists(json, 'r2') ? undefined : json['r2'],
        'r3': !exists(json, 'r3') ? undefined : json['r3'],
        'r4': !exists(json, 'r4') ? undefined : json['r4'],
        'r5': !exists(json, 'r5') ? undefined : json['r5'],
        'r6': !exists(json, 'r6') ? undefined : json['r6'],
        'r7': !exists(json, 'r7') ? undefined : json['r7'],
        'r8': !exists(json, 'r8') ? undefined : json['r8'],
        'r9': !exists(json, 'r9') ? undefined : json['r9'],
        'r10': !exists(json, 'r10') ? undefined : json['r10'],
        'r11': !exists(json, 'r11') ? undefined : json['r11'],
        'r12': !exists(json, 'r12') ? undefined : json['r12'],
        'r13': !exists(json, 'r13') ? undefined : json['r13'],
        'r14': !exists(json, 'r14') ? undefined : json['r14'],
        'r15': !exists(json, 'r15') ? undefined : json['r15'],
        'r16': !exists(json, 'r16') ? undefined : json['r16'],
        'r17': !exists(json, 'r17') ? undefined : json['r17'],
        'r18': !exists(json, 'r18') ? undefined : json['r18'],
        'r19': !exists(json, 'r19') ? undefined : json['r19'],
        'r20': !exists(json, 'r20') ? undefined : json['r20'],
        'r21': !exists(json, 'r21') ? undefined : json['r21'],
        'r22': !exists(json, 'r22') ? undefined : json['r22'],
        'r23': !exists(json, 'r23') ? undefined : json['r23'],
        'r24': !exists(json, 'r24') ? undefined : json['r24'],
        'r25': !exists(json, 'r25') ? undefined : json['r25'],
        'r26': !exists(json, 'r26') ? undefined : json['r26'],
        'r27': !exists(json, 'r27') ? undefined : json['r27'],
        'r28': !exists(json, 'r28') ? undefined : json['r28'],
        'r29': !exists(json, 'r29') ? undefined : json['r29'],
        'r30': !exists(json, 'r30') ? undefined : json['r30'],
        'r31': !exists(json, 'r31') ? undefined : json['r31'],
        'r32': !exists(json, 'r32') ? undefined : json['r32'],
        'r33': !exists(json, 'r33') ? undefined : json['r33'],
        'r34': !exists(json, 'r34') ? undefined : json['r34'],
        'r35': !exists(json, 'r35') ? undefined : json['r35'],
        'r36': !exists(json, 'r36') ? undefined : json['r36'],
        'r37': !exists(json, 'r37') ? undefined : json['r37'],
        'r38': !exists(json, 'r38') ? undefined : json['r38'],
        'r39': !exists(json, 'r39') ? undefined : json['r39'],
        'r40': !exists(json, 'r40') ? undefined : json['r40'],
        'r41': !exists(json, 'r41') ? undefined : json['r41'],
        'r42': !exists(json, 'r42') ? undefined : json['r42'],
        'r43': !exists(json, 'r43') ? undefined : json['r43'],
        'r44': !exists(json, 'r44') ? undefined : json['r44'],
        'r45': !exists(json, 'r45') ? undefined : json['r45'],
        'r46': !exists(json, 'r46') ? undefined : json['r46'],
        'r47': !exists(json, 'r47') ? undefined : json['r47'],
        'r48': !exists(json, 'r48') ? undefined : json['r48'],
        'r49': !exists(json, 'r49') ? undefined : json['r49'],
        'r50': !exists(json, 'r50') ? undefined : json['r50'],
        'r51': !exists(json, 'r51') ? undefined : json['r51'],
        'r52': !exists(json, 'r52') ? undefined : json['r52'],
        'r53': !exists(json, 'r53') ? undefined : json['r53'],
        'r54': !exists(json, 'r54') ? undefined : json['r54'],
        'r55': !exists(json, 'r55') ? undefined : json['r55'],
        'r56': !exists(json, 'r56') ? undefined : json['r56'],
        'r57': !exists(json, 'r57') ? undefined : json['r57'],
        'r58': !exists(json, 'r58') ? undefined : json['r58'],
        'r59': !exists(json, 'r59') ? undefined : json['r59'],
        'r60': !exists(json, 'r60') ? undefined : json['r60'],
        'r61': !exists(json, 'r61') ? undefined : json['r61'],
        'r62': !exists(json, 'r62') ? undefined : json['r62'],
        'r63': !exists(json, 'r63') ? undefined : json['r63'],
    };
}

export function StudentsReportDTOToJSON(value?: StudentsReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentName': value.studentName,
        'facultyName': value.facultyName,
        'groupName': value.groupName,
        'domainName': value.domainName,
        'studyYear': value.studyYear,
        'studyProgram': value.studyProgram,
        'r1': value.r1,
        'r2': value.r2,
        'r3': value.r3,
        'r4': value.r4,
        'r5': value.r5,
        'r6': value.r6,
        'r7': value.r7,
        'r8': value.r8,
        'r9': value.r9,
        'r10': value.r10,
        'r11': value.r11,
        'r12': value.r12,
        'r13': value.r13,
        'r14': value.r14,
        'r15': value.r15,
        'r16': value.r16,
        'r17': value.r17,
        'r18': value.r18,
        'r19': value.r19,
        'r20': value.r20,
        'r21': value.r21,
        'r22': value.r22,
        'r23': value.r23,
        'r24': value.r24,
        'r25': value.r25,
        'r26': value.r26,
        'r27': value.r27,
        'r28': value.r28,
        'r29': value.r29,
        'r30': value.r30,
        'r31': value.r31,
        'r32': value.r32,
        'r33': value.r33,
        'r34': value.r34,
        'r35': value.r35,
        'r36': value.r36,
        'r37': value.r37,
        'r38': value.r38,
        'r39': value.r39,
        'r40': value.r40,
        'r41': value.r41,
        'r42': value.r42,
        'r43': value.r43,
        'r44': value.r44,
        'r45': value.r45,
        'r46': value.r46,
        'r47': value.r47,
        'r48': value.r48,
        'r49': value.r49,
        'r50': value.r50,
        'r51': value.r51,
        'r52': value.r52,
        'r53': value.r53,
        'r54': value.r54,
        'r55': value.r55,
        'r56': value.r56,
        'r57': value.r57,
        'r58': value.r58,
        'r59': value.r59,
        'r60': value.r60,
        'r61': value.r61,
        'r62': value.r62,
        'r63': value.r63,
    };
}

