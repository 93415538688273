import Layout from "../../Containers/Layout";
import { ApplicantsView } from "../Views/ApplicantsView";

export const ApplicantsPage = (props: { location: any }) => {
	const initialInternshipName = props.location.state?.internshipName;

	return (
		<Layout>
			<ApplicantsView initialInternshipName={initialInternshipName} />
		</Layout>
	);
};
