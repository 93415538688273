/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';
import {
    InternshipDocumentState,
    InternshipDocumentStateFromJSON,
    InternshipDocumentStateFromJSONTyped,
    InternshipDocumentStateToJSON,
} from './InternshipDocumentState';

/**
 * 
 * @export
 * @interface InternshipDocumentDTO
 */
export interface InternshipDocumentDTO {
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentDTO
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentDTO
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentDTO
     */
    bibliography?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentDTO
     */
    studentGroup?: string | null;
    /**
     * 
     * @type {Faculty}
     * @memberof InternshipDocumentDTO
     */
    faculty?: Faculty;
    /**
     * 
     * @type {InternshipDocumentState}
     * @memberof InternshipDocumentDTO
     */
    state?: InternshipDocumentState;
}

export function InternshipDocumentDTOFromJSON(json: any): InternshipDocumentDTO {
    return InternshipDocumentDTOFromJSONTyped(json, false);
}

export function InternshipDocumentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipDocumentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'bibliography': !exists(json, 'bibliography') ? undefined : json['bibliography'],
        'studentGroup': !exists(json, 'studentGroup') ? undefined : json['studentGroup'],
        'faculty': !exists(json, 'faculty') ? undefined : FacultyFromJSON(json['faculty']),
        'state': !exists(json, 'state') ? undefined : InternshipDocumentStateFromJSON(json['state']),
    };
}

export function InternshipDocumentDTOToJSON(value?: InternshipDocumentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'bibliography': value.bibliography,
        'studentGroup': value.studentGroup,
        'faculty': FacultyToJSON(value.faculty),
        'state': InternshipDocumentStateToJSON(value.state),
    };
}

