import { Row, Tabs, Select, Col } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	InternshipEngagementType,
	InternshipType,
	MissingStudentEnrollmentReportDTO,
	PracticeInternshipAllocationReportDTO,
	PracticeInternshipsPerDomainReportDTO,
	ProfessorProposalReportDTO,
	ProjectAllocationReportDTO,
	Role,
	StudentYearEnum,
	StudentsPerDomainReportDTO,
	StudyProgramDTO,
	SummerSchoolsReportDTO,
} from "../../Api";
import Layout from "../../Containers/Layout";
import {
	getMissingStudentPracticeInternshipEnrollmentReport,
	getMissingStudentProjectEnrollmentReport,
	getPracticeInternshipAllocationReport,
	getPracticeInternshipPerDomainReport,
	getProfessorProposalReport,
	getProjectAllocationReport,
	getStudentAllocationPerDomainReport,
	getSummerSchoolsReport,
} from "../../Requests/report-requests";
import ReportTable from "./ReportTable";
import { useQuery } from "react-query";
import { getReportStudyPrograms } from "../../Requests/student-study";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getMissingStudentPracticeInternshipEnrollmentReportInfo,
	getMissingStudentProjectEnrollmentReportInfo,
	getPracticeInternshipAllocationReportInfo,
	getPracticeInternshipPerDomainReportInfo,
	getProfessorProposalReportInfo,
	getPrograms,
	getProjectAllocationReportInfo,
	getStudentAllocationPerDomainReportInfo,
	getSummerSchoolReportInfo,
} from "../../utils/reactQueriesConstants";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { studentIdSet } from "../Student/StudentSlice";
import { useBeforeRender, useIsRector } from "../../utils/utilFunctions";
import { ColumnType } from "../../utils/downloadReportUtils.types";

const { TabPane } = Tabs;
const { Option } = Select;

type StudyCycle = "Bachelor" | "Master";

const Report = () => {
	const { t } = useTranslation();
	const [tab, setTab] = useState("1");
	const [studyCycle, setStudyCycle] = useState<StudyCycle>("Bachelor");
	const [studyProgram, setStudyProgram] = useState([]);
	const [internshipType, setInternshipType] = useState<InternshipType | null>(
		tab === "5" ? null : InternshipType.ByProfessor
	);
	const [filteredPrograms, setFilteredPrograms] = useState<StudyProgramDTO[]>(
		[]
	);
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const professorReportColumns = (
		t: (text: string) => string
	): ColumnType<ProfessorProposalReportDTO>[] => [
		{
			title: t("reports.professionalTitle"),
			key: "professionalTitle",
			hidden: window.innerWidth < 550,
			render: (
				text: string | undefined,
				record: ProfessorProposalReportDTO
			) => {
				return (
					<span>{t("professionalTitle." + record.professionalTitle)}</span>
				);
			},
		},
		{
			title: t("reports.name"),
			key: "name",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Professor,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.name}</b>
				</Link>
			),
		},
		{
			title: t("reports.department"),
			key: "department",
			hidden: window.innerWidth < 430,
		},
		{
			title: t("reports.email"),
			key: "email",
			hidden: window.innerWidth < 975,
		},
		{
			title: t("reports.totalAvailablePositions"),
			key: "totalAvailablePositions",
		},
		{
			title: t("reports.totalAllocatedPositions"),
			key: "totalAllocatedPositions",
		},
		{
			title: t("reports.totalAvailablePositionsAsCoTutor"),
			key: "totalAvailablePositionsAsCoTutor",
			hidden: window.innerWidth < 740,
		},
		{
			title: t("reports.totalAllocatedPositionsAsCoTutor"),
			key: "totalAllocatedPositionsAsCoTutor",
			hidden: window.innerWidth < 740,
		},
	];

	const missingStudentProjectEnrollmentReportColumns = (
		t: (text: string) => string
	): ColumnType<MissingStudentEnrollmentReportDTO>[] => [
		{
			title: t("reports.name"),
			key: "name",
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("reports.studyProgram"),
			key: "studyProgram",
		},
		{
			title: t("reports.email"),
			key: "email",
			hidden: window.innerWidth < 650,
		},
		{
			title: t("reports.applications"),
			key: "applications",
		},
	];

	const missingStudentPracticeEnrollmentReportColumns = (
		t: (text: string) => string
	): ColumnType<MissingStudentEnrollmentReportDTO>[] => [
		{
			title: t("reports.name"),
			key: "name",
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("reports.studyProgram"),
			key: "studyProgram",
		},
		{
			title: t("reports.group"),
			key: "group",
		},
		{
			title: t("reports.phone"),
			key: "phone",
		},
		{
			title: t("reports.email"),
			key: "email",
			hidden: window.innerWidth < 650,
		},
		{
			title: t("reports.applications"),
			key: "applications",
		},
	];

	const projectAllocationReportColumns = (
		t: (text: string) => string
	): ColumnType<ProjectAllocationReportDTO>[] => [
		{
			title: t("reports.studentName"),
			key: "studentName",
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.studentId)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("reports.professorName"),
			key: "professorName",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.professorId,
							userType: Role.Professor,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.professorName}</b>
				</Link>
			),
		},
		{
			title: t("reports.projectName"),
			key: "projectName",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/propunere/" + record.projectId,
						search: "?type=" + record.projectType,
						state: {
							id: record.projectId,
							type: record.projectType,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.projectName}</b>
				</Link>
			),
		},
		{
			title: t("reports.studentStudyProgram"),
			key: "studentStudyProgram",
			hidden: window.innerWidth < 440,
		},
		{
			title: t("reports.studentEmail"),
			key: "studentEmail",
			hidden: window.innerWidth < 700,
		},
		{
			title: t("reports.professorDepartment"),
			key: "professorDepartment",
			hidden: window.innerWidth < 880,
		},
		{
			title: t("reports.professorEmail"),
			key: "professorEmail",
			hidden: window.innerWidth < 700,
		},
		{
			title: t("reports.coTutorName"),
			key: "coTutorName",
			hidden: window.innerWidth < 880,
		},
	];

	const summerSchoolsReportColumns = (
		t: (text: string) => string
	): ColumnType<SummerSchoolsReportDTO>[] => [
		{
			title: t("reports.summerSchoolName"),
			key: "summerSchoolName",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/propunere/" + record.summerSchoolId,
						search: "?type=" + record.summerSchoolType,
						state: {
							id: record.summerSchoolId,
							type: record.summerSchoolType,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.summerSchoolName}</b>
				</Link>
			),
		},
		{
			title: t("reports.coTutorName"),
			key: "coTutorName",
		},
		{
			title: t("reports.totalAvailablePositions"),
			key: "totalAvailablePositions",
		},
		{
			title: t("reports.totalAllocatedPositions"),
			key: "totalAllocatedPositions",
		},
	];

	const practiceInternshipAllocationReportColumns = (
		t: (text: string) => string
	): ColumnType<PracticeInternshipAllocationReportDTO>[] => [
		{
			title: t("reports.studentName"),
			key: "studentName",
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.studentId)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("reports.employerName"),
			key: "employerName",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.employerId,
							userType:
								record.internshipType === InternshipType.ByProfessor
									? Role.Professor
									: Role.Company,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.employerName}</b>
				</Link>
			),
		},
		{
			title: t("reports.internshipName"),
			key: "internshipName",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/propunere/" + record.internshipId,
						search: "?type=" + record.internshipType,
						state: {
							id: record.internshipId,
							type: record.internshipType,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.internshipName}</b>
				</Link>
			),
		},
		{
			title: t("reports.studentStudyProgram"),
			key: "studentStudyProgram",
			hidden: window.innerWidth < 440,
		},
		{
			title: t("reports.studentEmail"),
			key: "studentEmail",
			hidden: window.innerWidth < 700,
		},
		{
			title: t("reports.tutorEmail"),
			key: "tutorEmail",
			hidden: window.innerWidth < 700,
		},
	];

	const studentAllocationPerDomainReportColumns = (
		t: (text: string) => string
	): ColumnType<StudentsPerDomainReportDTO>[] => [
		{
			title: t("reports.studyProgram"),
			key: "fieldOfStudy",
		},
		{
			title: t("reports.year"),
			key: "year",
		},
		{
			title: t("reports.totalStudents"),
			key: "totalStudents",
		},
		{
			title: t("reports.applyingStudents"),
			key: "applyingStudents",
		},
		{
			title: t("reports.applicationAcceptedStudents"),
			key: "applicationAcceptedStudents",
		},
		{
			title: t("reports.offerAcceptedStudents"),
			key: "offerAcceptedStudents",
		},
	];

	const practiceInternshipsPerDomainReportColumns = (
		t: (text: string) => string
	): ColumnType<PracticeInternshipsPerDomainReportDTO>[] => [
		{
			title: t("reports.studyProgram"),
			key: "fieldOfStudy",
		},
		{
			title: t("reports.totalInternships"),
			key: "totalInternships",
		},
		{
			title: t("reports.totalInitialPositions"),
			key: "totalInitialPositions",
		},
		{
			title: t("reports.totalAvailablePositions"),
			key: "totalAvailablePositions",
		},
		{
			title: t("reports.averageNumberPositionsPerEmployer"),
			key: "averageNumberPositionsPerEmployer",
		},
	];

	const openErrorNotification = (error: string, message: string) => {
		openNotification(error, message, NOTIFICATION_TYPES.ERROR);
	};

	const { data: programs } = useQuery(
		[getPrograms],
		() => getReportStudyPrograms(),
		{
			refetchOnWindowFocus: false,
			onError: () =>
				openErrorNotification(
					t("reports.errorTexts.loadingFailed"),
					t("reports.errorTexts.loadingFailedMessage")
				),
		}
	);

	useEffect(() => {
		setFilteredPrograms(
			programs?.filter(
				(program) =>
					program.studyCycle?.toLowerCase() === studyCycle?.toLowerCase()
			) ?? []
		);
		setStudyProgram([]);
	}, [studyCycle, programs]);

	useEffect(() => {
		if (tab === "4" || tab === "5") {
			setStudyCycle("Bachelor");
		}
	}, [tab]);

	const getOldValues = () => {
		if (location.state !== undefined) {
			let { activeTab }: any = location.state;

			setTab(activeTab);

			history.replace({ ...history.location, state: undefined });
		}
	};

	useBeforeRender(() => getOldValues(), [location]);

	return (
		<Layout>
			<Row gutter={[10, 10]}>
				{tab !== "4" && tab !== "5" && tab !== "6" && tab !== "7" && (
					<Col>
						<Select
							defaultValue={studyCycle}
							options={[
								{ label: t("reports.bachelors"), value: "Bachelor" },
								{ label: t("reports.masters"), value: "Master" },
							]}
							onChange={setStudyCycle}
						/>
					</Col>
				)}
				{tab === "5" && (
					<Col>
						<Select
							defaultValue={internshipType}
							options={[
								{ label: t("reports.allOffers"), value: null },
								{
									label: t("reports.facultyOffers"),
									value: InternshipType.ByProfessor,
								},
								{
									label: t("reports.companyOffers"),
									value: InternshipType.ByCompany,
								},
							]}
							onChange={setInternshipType}
						/>
					</Col>
				)}
				{tab === "7" && (
					<Col>
						<Select
							defaultValue={internshipType}
							options={[
								{
									label: t("reports.facultyOffers"),
									value: InternshipType.ByProfessor,
								},
								{
									label: t("reports.companyOffers"),
									value: InternshipType.ByCompany,
								},
							]}
							onChange={setInternshipType}
						/>
					</Col>
				)}
				{tab !== "1" &&
					tab !== "6" &&
					tab !== "7" &&
					programs &&
					programs.length > 1 && (
						<Col>
							<Select
								showSearch
								allowClear
								onChange={setStudyProgram}
								style={{ width: "12em" }}
								placeholder={t("reports.studyProgram")}
								value={studyProgram}
							>
								{filteredPrograms?.map((program) => (
									<Option key={program.id} value={program.name}>
										{program.name}
									</Option>
								))}
							</Select>
						</Col>
					)}
			</Row>
			<Row>
				<Tabs
					activeKey={tab}
					centered
					tabBarStyle={{
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}
					onChange={setTab}
					style={{ width: "100%" }}
				>
					<TabPane tab={t("reports.professorReport")} key="1">
						<ReportTable
							getCall={(page, pageSize) =>
								getProfessorProposalReport(
									page,
									pageSize,
									studyCycle === "Bachelor"
										? InternshipEngagementType.BachelorsInternship
										: InternshipEngagementType.MastersInternship,
									studyProgram
								)
							}
							columns={professorReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={professorReportColumns(t)}
							queryKey={getProfessorProposalReportInfo}
							watchChanges={[studyCycle]}
							fileName={t("files.professorReport")}
						/>
					</TabPane>
					<TabPane
						tab={t("reports.missingStudentProjectEnrollmentReport")}
						key="2"
					>
						<ReportTable
							getCall={(page, pageSize) =>
								getMissingStudentProjectEnrollmentReport(
									page,
									pageSize,
									studyProgram
								)
							}
							columns={missingStudentProjectEnrollmentReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={missingStudentProjectEnrollmentReportColumns(t)}
							queryKey={getMissingStudentProjectEnrollmentReportInfo}
							watchChanges={[studyCycle, studyProgram]}
							fileName={t("files.missingStudentProjectEnrollmentReport")}
						/>
					</TabPane>
					<TabPane tab={t("reports.allocatedProjectsReport")} key="3">
						<ReportTable
							getCall={(page, pageSize) =>
								getProjectAllocationReport(
									page,
									pageSize,
									studyCycle === "Bachelor"
										? InternshipEngagementType.BachelorsInternship
										: InternshipEngagementType.MastersInternship,
									studyProgram
								)
							}
							columns={projectAllocationReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={projectAllocationReportColumns(t)}
							queryKey={getProjectAllocationReportInfo}
							watchChanges={[studyCycle, studyProgram]}
							fileName={t("files.allocatedProjectsReport")}
						/>
					</TabPane>
					<TabPane
						tab={t("reports.missingStudentPracticeInternshipEnrollmentReport")}
						key="4"
					>
						<ReportTable
							getCall={(page, pageSize) =>
								getMissingStudentPracticeInternshipEnrollmentReport(
									page,
									pageSize,
									studyProgram
								)
							}
							columns={missingStudentPracticeEnrollmentReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={missingStudentPracticeEnrollmentReportColumns(t)}
							queryKey={getMissingStudentPracticeInternshipEnrollmentReportInfo}
							watchChanges={[studyProgram]}
							fileName={t(
								"files.missingStudentPracticeInternshipEnrollmentReport"
							)}
						/>
					</TabPane>
					<TabPane
						tab={t("reports.allocatedPracticeInternshipsReport")}
						key="5"
					>
						<ReportTable
							getCall={(page, pageSize) =>
								getPracticeInternshipAllocationReport(
									page,
									pageSize,
									studyProgram,
									internshipType !== null ? internshipType : undefined
								)
							}
							columns={practiceInternshipAllocationReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={practiceInternshipAllocationReportColumns(t)}
							queryKey={getPracticeInternshipAllocationReportInfo}
							watchChanges={[studyProgram, internshipType]}
							fileName={t("files.allocatedPracticeInternshipsReport")}
						/>
					</TabPane>
					<TabPane tab={t("reports.studentAllocationPerDomainReport")} key="6">
						<ReportTable
							getCall={(page, pageSize) =>
								getStudentAllocationPerDomainReport(page, pageSize)
							}
							columns={studentAllocationPerDomainReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={studentAllocationPerDomainReportColumns(t)}
							queryKey={getStudentAllocationPerDomainReportInfo}
							watchChanges={[]}
							fileName={t("files.studentAllocationPerDomainReport")}
						/>
					</TabPane>
					<TabPane
						tab={t("reports.practiceInternshipsPerDomainReport")}
						key="7"
					>
						<ReportTable
							getCall={(page, pageSize) =>
								getPracticeInternshipPerDomainReport(
									page,
									pageSize,
									internshipType!
								)
							}
							columns={practiceInternshipsPerDomainReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={practiceInternshipsPerDomainReportColumns(t)}
							queryKey={getPracticeInternshipPerDomainReportInfo}
							watchChanges={[internshipType]}
							fileName={t("files.practiceInternshipsPerDomainReport")}
						/>
					</TabPane>
					<TabPane tab={t("reports.summerSchoolsReport")} key="8">
						<ReportTable
							getCall={(page, pageSize) =>
								getSummerSchoolsReport(
									page,
									pageSize,
									InternshipEngagementType.SummerSchool,
									studyProgram
								)
							}
							columns={summerSchoolsReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={summerSchoolsReportColumns(t)}
							queryKey={getSummerSchoolReportInfo}
							watchChanges={[studyCycle, studyProgram]}
							fileName={t("files.summerSchoolReport")}
						/>
					</TabPane>
				</Tabs>
			</Row>
		</Layout>
	);
};

export default Report;
