import { InternshipListItemProps } from "./InternshipListItem.types";
import { Avatar, Checkbox, Col, List, Row, Select, Switch } from "antd";
import styles from "../../../Internships.module.scss";
import {
	CompanyInternshipState,
	InternshipDTO,
	InternshipEngagementType,
	InternshipType,
	Role,
} from "../../../../../Api";
import InternshipActions from "../../../InternshipActions";
import { getUserId, isUserLogged } from "../../../../../utils/utilFunctions";
import CustomButton from "../../../../../CustomComponents/CustomButton";
import { theme } from "../../../../../theme";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ConfirmationModal from "../../../../../Containers/ConfirmationModal";
import RejectInternship from "../../../RejectInternship";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { InternshipApplicantsTable } from "../../tables/InternshipApplicantsTable/InternshipApplicantsTable";
import { useState } from "react";
import { InternshipMyStudentsTable } from "../../tables/InternshipMyStudentsTable/InternshipMyStudentsTable";

const { Option } = Select;

export const InternshipListItem = (props: InternshipListItemProps) => {
	const { t } = useTranslation();
	const [showApplicantsTable, setShowApplicantsTable] = useState(false);
	const [showMyStudentsTable, setShowMyStudentsTable] = useState(false);

	const onChange = (event: any, id: any) => {
		props.actions.updateInternshipCoordinator(event, id);
	};

	const onChangeStudent = (event: any, id: any) => {
		props.actions.enrollStudent(event, id);
	};

	const isEditAllowed = (internship: InternshipDTO) => {
		return (
			internship.state === CompanyInternshipState.Draft ||
			internship.state === CompanyInternshipState.Approved
		);
	};

	const isCompanyTrusted = (internship: InternshipDTO) => {
		const faculty = internship.faculties[0];
		const companyProtocols = internship.companyDocuments;

		return (
			companyProtocols?.filter(
				(e) => e.facultyId === faculty.id && e.skipOfferValidation
			).length === 1
		);
	};

	const onChangeStudyPrograms = (event: any, id: any, type: string) => {
		props.actions.updateStudyPrograms(event, id, type);
	};

	return (
		<List.Item
			className={styles.ListItem}
			style={{
				border:
					(props.isProfessor &&
						props.activeTab === "1" &&
						!props.item.isValid) ||
					(props.isFacultyAdmin &&
						props.activeTab === "3" &&
						!props.item.isValid) ||
					(props.isDepartmentDirector &&
						props.activeTab === "4" &&
						!props.item.isValid) ||
					(props.isStudyProgramCoordinator &&
						props.activeTab === "5" &&
						!props.item.isValid) ||
					(props.isFacultyAdmin &&
						props.activeTab === "6" &&
						props.item.state === CompanyInternshipState.Invalid) ||
					(props.isCompany &&
						props.item.state === CompanyInternshipState.Invalid)
						? "1px solid #EF6873"
						: "1px solid lightgray",
			}}
			title={
				(props.isProfessor && props.activeTab === "1" && !props.item.isValid) ||
				(props.isFacultyAdmin &&
					props.activeTab === "3" &&
					!props.item.isValid) ||
				(props.isDepartmentDirector &&
					props.activeTab === "4" &&
					!props.item.isValid) ||
				(props.isStudyProgramCoordinator &&
					props.activeTab === "5" &&
					!props.item.isValid) ||
				(props.isFacultyAdmin &&
					props.activeTab === "6" &&
					props.item.state === CompanyInternshipState.Invalid) ||
				(props.isCompany && props.item.state === CompanyInternshipState.Invalid)
					? t("internships.invalidProposal")
					: ""
			}
			key={props.item.id}
			actions={[
				<InternshipActions item={props.item} activeTab={props.activeTab} />,
			].concat(
				isUserLogged() && !props.isStudent
					? [
							<div className={styles.buttonsContainer}>
								<Row>
									{(props.isProfessor &&
										props.location.pathname !== "/propuneri-companii" &&
										props.activeTab === "1") ||
									(props.isDepartmentSecretary && props.activeTab === "4") ||
									(props.isCompany && isEditAllowed(props.item)) ? (
										<div className={styles.alignmentContainer}>
											<CustomButton
												shape="circle"
												type="text"
												paddingvertical={"19px"}
												title={t("internships.edit")}
												style={{
													padding: "8px 0px",
													background: "none",
													color: theme.black,
													marginRight: "10%",
													marginTop: "0",
													boxShadow: "none",
													fontSize: "14px",
													height: "100%",
												}}
											>
												<Link
													to={{
														pathname: "/editare-propunere/" + props.item.id,
														state: {
															activeTab: props.activeTab,
															currentPage: props.currentPage,
															pageSize: props.pageSize,
															filters: props.checkedKeys,
															searchTerm: props.searchTerm,
															sortDirection: props.sortDirection,
															sortField: props.sortField,
														},
													}}
												>
													<FontAwesomeIcon icon={solid("edit")} /> &nbsp;
													{t("internships.edit")}
												</Link>
											</CustomButton>
										</div>
									) : null}
									{props.isCompany &&
									props.item.state === CompanyInternshipState.Draft ? (
										<div className={styles.alignmentContainer}>
											<CustomButton
												fontSize={"0.9rem"}
												style={{
													background: "none",
													color: theme.black,
													marginRight: "1rem",
													marginTop: "0",
													boxShadow: "none",
													paddingLeft: "3px",
													fontSize: "14px",
												}}
												onClick={() => {
													props.actions.setSelectedInternshipId(props.item.id);
													props.actions.setMarkCompleteModalVisibility(true);
													props.actions.setIsCompanyTrusted(
														isCompanyTrusted(props.item)
													);
												}}
												title={
													isCompanyTrusted(props.item)
														? t("internships.finalize")
														: t("internships.markComplete")
												}
												icon={
													<FontAwesomeIcon
														icon={solid("circle-check")}
														style={{ paddingRight: "5%" }}
													/>
												}
												shape={"circle"}
												type={"text"}
											>
												{isCompanyTrusted(props.item)
													? t("internships.finalize")
													: t("internships.markComplete")}
											</CustomButton>
											<ConfirmationModal
												modalText={
													isCompanyTrusted(props.item)
														? t("internships.finalizeMessage")
														: t("internships.markCompleteMessage")
												}
												handleFunction={() => props.actions.sendForApproval()}
												modalVisibility={
													props.state.markCompleteModalVisibility
												}
												title=""
												changeModalVisibility={() =>
													props.actions.setMarkCompleteModalVisibility(false)
												}
												spinning={props.state.spinning}
											/>
										</div>
									) : null}
									{(props.isProfessor &&
										props.location.pathname !== "/propuneri-companii" &&
										props.activeTab === "1") ||
									(props.isCompany &&
										(props.item.state !== CompanyInternshipState.Approved ||
											(props.item.engagementType !==
												InternshipEngagementType.BachelorsInternship &&
												props.item.engagementType !==
													InternshipEngagementType.MastersInternship))) ? (
										<div className={styles.alignmentContainer}>
											<CustomButton
												fontSize={"0.9rem"}
												style={{
													background: "none",
													color: theme.black,
													marginRight: "1rem",
													marginTop: "0",
													boxShadow: "none",
													paddingLeft: "3px",
													fontSize: "14px",
												}}
												onClick={() => {
													props.actions.setSelectedInternshipId(props.item.id);
													props.actions.setDeleteModalVisibility(true);
												}}
												title={
													props.item.enrolledStudentsCount > 0
														? t("internships.deletionNotAllowed")
														: t("internships.deleteInternship")
												}
												icon={
													<FontAwesomeIcon
														icon={solid("trash")}
														style={{ paddingRight: "5%" }}
													/>
												}
												shape={"circle"}
												type={"text"}
												disabled={props.item.enrolledStudentsCount > 0}
											>
												{t("internships.deleteInternship")}
											</CustomButton>
											<ConfirmationModal
												modalText={t("internships.deleteInternshipMessage")}
												handleFunction={props.actions.deleteEntry}
												modalVisibility={props.state.deleteModalVisibility}
												title=""
												changeModalVisibility={() =>
													props.actions.setDeleteModalVisibility(false)
												}
												spinning={props.state.spinning}
											/>
										</div>
									) : null}
									{(props.isProfessor && props.activeTab === "1") ||
									props.isCompany ? (
										<div className={styles.alignmentContainer}>
											<CustomButton
												shape="circle"
												type="text"
												paddingvertical={"19px"}
												title={t("internships.duplicateInternship")}
												style={{
													padding: "8px 0px",
													background: "none",
													color: theme.black,
													marginRight: "10%",
													marginTop: "0",
													boxShadow: "none",
													fontSize: "14px",
													height: "100%",
												}}
												icon={
													<FontAwesomeIcon
														icon={solid("clone")}
														style={{ paddingRight: "5%" }}
													/>
												}
											>
												<Link
													to={{
														pathname: "/editare-propunere/" + props.item.id,
														state: {
															activeTab: props.activeTab,
															currentPage: props.currentPage,
															pageSize: props.pageSize,
															filters: props.checkedKeys,
															searchTerm: props.searchTerm,
															sortDirection: props.sortDirection,
															sortField: props.sortField,
															isDuplicating: true,
														},
													}}
												>
													{t("internships.duplicateInternship")}
												</Link>
											</CustomButton>
										</div>
									) : null}
									{(props.isProfessor &&
										props.location.pathname !== "/propuneri-companii" &&
										props.activeTab === "1") ||
									(props.isCompany &&
										props.item.state === CompanyInternshipState.Approved) ||
									(props.isDepartmentSecretary && props.activeTab === "4") ? (
										<div className={styles.alignmentContainer}>
											<CustomButton
												paddingvertical={"19px"}
												shape="circle"
												type="text"
												style={{
													padding: "8px 0px",
													background: "none",
													color: theme.black,
													marginRight: "10%",
													marginTop: "0",
													boxShadow: "none",
													fontSize: "14px",
													height: "100%",
												}}
												title={t("internships.applicants")}
												onClick={() => {
													setShowApplicantsTable((v) => !v);
													setShowMyStudentsTable(false);
												}}
											>
												<FontAwesomeIcon icon={solid("users")} /> &nbsp;
												{t("internships.applicants")}
											</CustomButton>
										</div>
									) : null}

									{(props.isProfessor &&
										props.location.pathname !== "/propuneri-companii" &&
										props.activeTab === "1") ||
									(props.isCompany &&
										props.item.state === CompanyInternshipState.Approved) ||
									(props.isDepartmentSecretary && props.activeTab === "4") ? (
										<div className={styles.alignmentContainer}>
											<CustomButton
												paddingvertical={"19px"}
												shape="circle"
												type="text"
												style={{
													padding: "8px 0px",
													background: "none",
													color: theme.black,
													marginRight: "10%",
													marginTop: "0",
													boxShadow: "none",
													fontSize: "14px",
													height: "100%",
												}}
												title={t("header.myStudents")}
												onClick={() => {
													setShowApplicantsTable(false);
													setShowMyStudentsTable((v) => !v);
												}}
											>
												<FontAwesomeIcon icon={solid("users")} /> &nbsp;
												{t("header.myStudents")}
											</CustomButton>
										</div>
									) : null}

									{props.isDepartmentSecretary &&
									props.activeTab === "4" &&
									props.item.enrolledStudentsCount <
										props.item.initialAvailablePositions &&
									(props.item.engagementType ===
										InternshipEngagementType.BachelorsInternship ||
										props.item.engagementType ===
											InternshipEngagementType.MastersInternship) ? (
										<div className={styles.alignmentContainer}>
											<Select
												placeholder={t("admin.selectStudent")}
												style={{ width: "15em" }}
												showSearch
												value={null}
												optionFilterProp="children"
												filterOption={(input, option) =>
													option?.props.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0 ||
													option?.props.value
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
												onChange={(event) =>
													onChangeStudent(event, props.item.internshipId)
												}
											>
												{props.item.engagementType ===
												InternshipEngagementType.BachelorsInternship
													? props.state.availableBachelorStudents?.map(
															(option: any) => (
																<Option key={option.id}>{option.name}</Option>
															)
													  )
													: props.state.availableMasterStudents?.map(
															(option: any) => (
																<Option key={option.id}>{option.name}</Option>
															)
													  )}
											</Select>
										</div>
									) : null}

									{props.item.isViewOnly &&
									props.activeTab === "1" &&
									props.item.enrolledStudentsCount <
										props.item.initialAvailablePositions &&
									(props.item.engagementType ===
										InternshipEngagementType.BachelorsInternship ||
										props.item.engagementType ===
											InternshipEngagementType.MastersInternship) ? (
										<div className={styles.alignmentContainer}>
											<Select
												placeholder={t("admin.selectStudent")}
												style={{ width: "15em" }}
												showSearch
												value={null}
												optionFilterProp="children"
												filterOption={(input, option) =>
													option?.props.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0 ||
													option?.props.value
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
												onChange={(event) =>
													onChangeStudent(event, props.item.internshipId)
												}
											>
												{props.item.engagementType ===
												InternshipEngagementType.BachelorsInternship
													? props.state.availableBachelorStudents?.map(
															(option: any) => (
																<Option key={option.id}>{option.name}</Option>
															)
													  )
													: props.state.availableMasterStudents?.map(
															(option: any) => (
																<Option key={option.id}>{option.name}</Option>
															)
													  )}
											</Select>
										</div>
									) : null}

									{props.isFacultyAdmin &&
										(props.item.engagementType ===
											InternshipEngagementType.Internship ||
											props.item.engagementType ===
												InternshipEngagementType.Scholarship ||
											props.item.engagementType ===
												InternshipEngagementType.Job) &&
										props.item.state ===
											CompanyInternshipState.PendingApproval && (
											<div className={styles.alignmentContainer}>
												<CustomButton
													fontSize={"0.9rem"}
													style={{
														background: "none",
														color: theme.black,
														marginRight: "1rem",
														marginTop: "0",
														boxShadow: "none",
														paddingLeft: "3px",
														fontSize: "14px",
													}}
													onClick={() => {
														props.actions.setSelectedInternshipId(
															props.item.id
														);
														props.actions.setApproveModalVisibility(true);
													}}
													title={t("internships.approveInternship")}
													icon={
														<FontAwesomeIcon
															icon={solid("circle-check")}
															style={{ paddingRight: "5%" }}
														/>
													}
													shape={"circle"}
													type={"text"}
												>
													{t("internships.approveInternship")}
												</CustomButton>
												<ConfirmationModal
													modalText={t("internships.approveInternshipMessage")}
													handleFunction={props.actions.approve}
													modalVisibility={props.state.approveModalVisibility}
													title=""
													changeModalVisibility={() =>
														props.actions.setApproveModalVisibility(false)
													}
													spinning={props.state.spinning}
												/>
												<RejectInternship
													nameButton={"  " + t("internships.rejectInternship")}
													modalText={t("internships.rejectInternshipMessage")}
													title={t("internships.rejectInternship")}
													handleFunction={(description: string) =>
														props.actions.reject(props.item, description)
													}
													modalVisibility={props.state.rejectModalVisibility}
													iconButton={
														<FontAwesomeIcon
															icon={solid("ban")}
															style={{ paddingRight: "5%" }}
														/>
													}
													shapeButton={"circle"}
													typeButton={"text"}
													styleProp={{
														background: "none",
														color: theme.black,
														marginRight: "1rem",
														marginTop: "0",
														boxShadow: "none",
														paddingLeft: "3px",
														fontSize: "14px",
													}}
													spinning={props.state.spinning}
												/>
											</div>
										)}
									{props.isFacultyAdmin &&
									props.item.type === InternshipType.ByCompany &&
									(props.item.engagementType ===
										InternshipEngagementType.BachelorsInternship ||
										props.item.engagementType ===
											InternshipEngagementType.MastersInternship) ? (
										<div className={styles.alignmentContainer}>
											<Select
												placeholder={t("admin.selectInternshipResponsible")}
												style={{ width: "15em" }}
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) =>
													option?.props.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0 ||
													option?.props.value
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
												defaultValue={props.item.facultyCoordinatorId}
												disabled={
													props.item.facultyCoordinatorId !== null &&
													props.item.facultyCoordinatorId !== undefined
												}
												onChange={(event) => onChange(event, props.item.id)}
											>
												{props.professorOptions?.map((option: any) => (
													<Option key={option.id}>{option.name}</Option>
												))}
											</Select>
										</div>
									) : null}
									{props.isProfessor &&
										!props.isFacultyAdmin &&
										props.item.type === InternshipType.ByCompany &&
										(props.item.engagementType ===
											InternshipEngagementType.BachelorsInternship ||
											props.item.engagementType ===
												InternshipEngagementType.MastersInternship) &&
										props.item.state ===
											CompanyInternshipState.PendingApproval &&
										props.item.facultyCoordinatorId === getUserId() && (
											<div className={styles.alignmentContainer}>
												<CustomButton
													fontSize={"0.9rem"}
													style={{
														background: "none",
														color: theme.black,
														marginRight: "1rem",
														marginTop: "0",
														boxShadow: "none",
														paddingLeft: "3px",
														fontSize: "14px",
													}}
													onClick={() => {
														props.actions.setSelectedInternshipId(
															props.item.id
														);
														props.actions.setAcceptCoordinationModalVisibility(
															true
														);
													}}
													title={t("internships.acceptCoordination")}
													icon={
														<FontAwesomeIcon
															icon={solid("circle-check")}
															style={{ paddingRight: "5%" }}
														/>
													}
													shape={"circle"}
													type={"text"}
												>
													{t("internships.acceptCoordination")}
												</CustomButton>
												<ConfirmationModal
													modalText={t("internships.acceptCoordinationMessage")}
													handleFunction={
														props.actions.acceptInternshipCoordination
													}
													modalVisibility={
														props.state.acceptCoordinationModalVisibility
													}
													title=""
													changeModalVisibility={() =>
														props.actions.setAcceptCoordinationModalVisibility(
															false
														)
													}
													spinning={props.state.spinning}
												/>
												<CustomButton
													fontSize={"0.9rem"}
													style={{
														background: "none",
														color: theme.black,
														marginRight: "1rem",
														marginTop: "0",
														boxShadow: "none",
														paddingLeft: "3px",
														fontSize: "14px",
													}}
													onClick={() => {
														props.actions.setSelectedInternshipId(
															props.item.id
														);
														props.actions.setRejectCoordinationModalVisibility(
															true
														);
													}}
													title={t("internships.rejectCoordination")}
													icon={
														<FontAwesomeIcon
															icon={solid("ban")}
															style={{ paddingRight: "5%" }}
														/>
													}
													shape={"circle"}
													type={"text"}
												>
													{t("internships.rejectCoordination")}
												</CustomButton>
												<ConfirmationModal
													modalText={t("internships.rejectCoordinationMessage")}
													handleFunction={
														props.actions.rejectInternshipCoordination
													}
													modalVisibility={
														props.state.rejectCoordinationModalVisibility
													}
													title=""
													changeModalVisibility={() =>
														props.actions.setRejectCoordinationModalVisibility(
															false
														)
													}
													spinning={props.state.spinning}
												/>
											</div>
										)}
									{props.isInternshipAdmin &&
									props.activeTab === "6" &&
									props.item.needsApproval === true &&
									props.item.engagementType ===
										InternshipEngagementType.Internship &&
									props.item.state !== CompanyInternshipState.Approved ? (
										<div className={styles.alignmentContainer}>
											<CustomButton
												fontSize={"0.9rem"}
												style={{
													background: "none",
													color: theme.black,
													marginRight: "1rem",
													marginTop: "0",
													boxShadow: "none",
													paddingLeft: "3px",
													fontSize: "14px",
												}}
												onClick={() => {
													props.actions.setSelectedInternshipId(props.item.id);
													props.actions.setApproveInternshipModalVisibility(
														true
													);
												}}
												title={t(
													"internships.approveInternshipForStudyPrograms"
												)}
												icon={
													<FontAwesomeIcon
														icon={solid("circle-check")}
														style={{ paddingRight: "5%" }}
													/>
												}
												shape={"circle"}
												type={"text"}
											>
												{t("internships.approveInternshipForStudyPrograms")}
											</CustomButton>
											<ConfirmationModal
												modalText={t(
													"internships.approveInternshipForStudyProgramsMessage"
												)}
												handleFunction={
													props.actions.approveInternshipForStudyPrograms
												}
												modalVisibility={
													props.state.approveInternshipModalVisibility
												}
												title=""
												changeModalVisibility={() =>
													props.actions.setApproveInternshipModalVisibility(
														false
													)
												}
												spinning={props.state.spinning}
											/>
										</div>
									) : null}
									{props.isProfessor &&
										!props.isFacultyAdmin &&
										props.item.type === InternshipType.ByCompany &&
										(props.item.engagementType ===
											InternshipEngagementType.BachelorsInternship ||
											props.item.engagementType ===
												InternshipEngagementType.MastersInternship) &&
										props.item.state ===
											CompanyInternshipState.PendingApproval &&
										!props.item.facultyCoordinatorId && (
											<div className={styles.alignmentContainer}>
												<Checkbox
													onChange={() => onChange(getUserId(), props.item.id)}
												>
													{t("internships.becomeProfessorCoordinator")}
												</Checkbox>
											</div>
										)}
									{props.isDean && props.activeTab !== "1" ? (
										<div className={styles.alignmentContainer}>
											<Select
												className={styles.antSelect}
												mode="tags"
												maxTagCount={2}
												placeholder={t(
													"internships.addInternshipForm.studyPrograms"
												)}
												defaultValue={props.item.studyPrograms?.map(
													(e: any) => e.id
												)}
												style={{
													width: "100%",
													minWidth:
														props.item.studyPrograms!.length > 0
															? "100px"
															: "230px",
												}}
												onChange={(event) =>
													onChangeStudyPrograms(
														event,
														props.item.id,
														props.activeTab === "6" ? "company" : "faculty"
													)
												}
												notFoundContent={t(
													"internships.addInternshipForm.notFoundContent"
												)}
											>
												{props.studyProgramsList?.map((option: any) => (
													<Option key={option.id}>{option.name}</Option>
												))}
											</Select>
										</div>
									) : null}
								</Row>
							</div>,
							<div>
								{showApplicantsTable && (
									<InternshipApplicantsTable internship={props.item} />
								)}
								{showMyStudentsTable && (
									<InternshipMyStudentsTable internship={props.item} />
								)}
							</div>,
					  ]
					: []
			)}
			// adds content to the right of the list item, can be used for interaction buttons for professor
			// and company (i.e. delete, edit, view applicants)
			// extra={

			// }
		>
			<List.Item.Meta
				className={styles.listItemMeta}
				avatar={
					<Link
						to={{
							pathname: `/profil`,
							state: {
								id:
									props.location.pathname === "/propuneri-facultati"
										? props.item.professorId
										: props.location.pathname === "/propuneri-companii"
										? props.item.companyId
										: props.isProfessor
										? props.activeTab === "2" || props.activeTab === "6"
											? props.item.companyId
											: props.item.professorId
										: props.item.companyId,
								type:
									props.location.pathname === "/propuneri-facultati"
										? InternshipType.ByProfessor
										: props.location.pathname === "/propuneri-companii"
										? InternshipType.ByCompany
										: props.isProfessor
										? props.activeTab === "2" || props.activeTab === "6"
											? InternshipType.ByCompany
											: InternshipType.ByProfessor
										: InternshipType.ByCompany,
								userType: props.areFacultyInternships
									? Role.Professor
									: Role.Company,
								filters: props.checkedKeys,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								activeTab: props.activeTab,
								origin: props.location.pathname,
							},
						}}
					>
						<Row>
							{props.item.type === InternshipType.ByCompany && (
								<Col span={24} className={styles.customCol}>
									<Avatar src={props.item.recruiterAvatar} />
								</Col>
							)}
							{((props.isProfessor && props.activeTab !== "1") ||
								!props.isProfessor) && (
								<Col
									span={24}
									className={styles.customCol}
									style={{ width: 90, textAlign: "center" }}
								>
									<p>
										{props.item.type === InternshipType.ByProfessor
											? t("abbreviations." + props.item.professionalTitle) + " "
											: ""}{" "}
										{props.item.recruiterName}
									</p>
								</Col>
							)}
						</Row>
					</Link>
				}
				title={
					<Row style={{ width: "100%" }}>
						<Col span={22} style={{ display: "flex" }}>
							<Link
								to={{
									pathname: "/propunere/" + props.item.id,
									search: "?type=" + props.item.type,
									state: {
										id: props.item.id,
										type: props.item.type,
										filters: props.checkedKeys,
										searchTerm: props.searchTerm,
										sortField: props.sortField,
										sortDirection: props.sortDirection,
										currentPage: props.currentPage,
										pageSize: props.pageSize,
										activeTab: props.activeTab,
										origin: props.location.pathname,
									},
								}}
								onClick={() =>
									props.actions.updateViewCount(props.item.id, props.item.type)
								}
							>
								{props.item.internshipName}
							</Link>
						</Col>
						{((props.isFacultyAdmin && props.activeTab === "3") ||
							(props.isDepartmentDirector && props.activeTab === "4") ||
							(props.isStudyProgramCoordinator && props.activeTab === "5")) &&
						props.item.engagementType !==
							InternshipEngagementType.ResearchInternship ? (
							<Col span={2}>
								<div className={styles.alignmentContainer}>
									<Switch
										checked={props.item.isValid}
										onChange={(checked: boolean) =>
											props.actions.onChangeProfessorInternshipValidation(
												checked,
												props.item
											)
										}
										checkedChildren={<CheckOutlined />}
										unCheckedChildren={<CloseOutlined />}
										title={
											props.item.isValid
												? t("internships.invalidationToggle")
												: t("internships.validationToggle")
										}
									/>
								</div>
							</Col>
						) : null}
						{props.isFacultyAdmin &&
						props.activeTab === "6" &&
						(props.item.state === CompanyInternshipState.Approved ||
							props.item.state === CompanyInternshipState.Invalid) ? (
							<Col span={2}>
								<div className={styles.alignmentContainer}>
									<Switch
										checked={
											props.item.state === CompanyInternshipState.Approved
										}
										onChange={(checked: boolean) =>
											props.actions.onChangeCompanyInternshipValidation(
												checked,
												props.item
											)
										}
										checkedChildren={<CheckOutlined />}
										unCheckedChildren={<CloseOutlined />}
										title={
											props.item.isValid
												? t("internships.invalidationToggle")
												: t("internships.validationToggle")
										}
									/>
								</div>
							</Col>
						) : null}
					</Row>
				}
				description={props.item.skillsRequired?.join(", ")}
			/>
			{props.item.shortDescription}
		</List.Item>
	);
};
