/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResearchProposalEnrollmentType {
    ByCompany = 'ByCompany',
    ByProfessor = 'ByProfessor'
}

export function ResearchProposalEnrollmentTypeFromJSON(json: any): ResearchProposalEnrollmentType {
    return ResearchProposalEnrollmentTypeFromJSONTyped(json, false);
}

export function ResearchProposalEnrollmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResearchProposalEnrollmentType {
    return json as ResearchProposalEnrollmentType;
}

export function ResearchProposalEnrollmentTypeToJSON(value?: ResearchProposalEnrollmentType | null): any {
    return value as any;
}

