import { Row, Col, Button, Drawer, Tabs } from "antd";
import Layout from "../../Containers/Layout";
import CustomButton from "../../CustomComponents/CustomButton";
import { Link } from "react-router-dom";
import CustomFilter from "../../CustomComponents/CustomFilter";
import CustomSort from "../../CustomComponents/CustomSort";
import styles from "./AllEvents.module.scss";
import Search from "antd/lib/input/Search";
import { useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";
import InternshipsList from "../Internships/InternshipsList";
import {
	Filter,
	InternshipEngagementType,
	InternshipSortFieldEnum,
	SortDirectionEnum,
} from "../../Api";
import { useQuery, useQueryClient } from "react-query";
import {
	getFacultyInternships,
	getInternshipsFilters,
} from "../../utils/reactQueriesConstants";
import {
	getAllProfessorInternship,
	getAllProfessorInternshipFilters,
	getAllProfessorInternshipsForFaculty,
} from "../../Requests/professor-internship-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getAllInternshipTypeEventFilters,
	getAllInternshipsTypeEvent,
} from "../../Requests/internship-type-event-requests";
import EventList from "./EventList";

const getAllKeys = (filterItems: any[]) => {
	const keys: any[] = [];

	filterItems?.forEach((filter) => {
		keys.push(filter.key);

		filter.children.forEach((filterItem: any) => {
			keys.push(filterItem.key.split("/")[1]);
		});
	});

	return keys;
};

const AllEvents = (props: any) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [expandedKeys, setExpandedKeys] = useState<any>([]);
	const [sortField, setSortField] = useState(
		InternshipSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [types, setTypes] = useState([] as any);
	const queryClient = useQueryClient();
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [locations, setLocations] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [applicabilityTypes, setApplicabilityTypes] = useState([]);
	const [visible, setVisible] = useState(false);
	const [skills, setSkills] = useState([]);
	const [categories, setCategories] = useState([]);

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getInternshipsFilters);
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrentPage(1);
		setTypes(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Type@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const changeSortField = (value: any) => {
		setSortField(value);
	};
	const changeSortDirection = () => {
		if (sortDirection === SortDirectionEnum.Ascending) {
			setSortDirection(SortDirectionEnum.Descending);
		} else {
			setSortDirection(SortDirectionEnum.Ascending);
		}
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getInternshipsFilters, props.location.pathname],
		() => {
			return getAllInternshipTypeEventFilters();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
		}
	);

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters?.map((x) => {
			if (x.key === "Location") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: y.title,
						}))
					),
				};
			} else if (x.key === "Applicability") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title:
								y.title === "True"
									? t("internships.filters.isViewOnly")
									: t("internships.filters.canApply"),
						}))
					),
				};
			} else {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("internships.filters." + y.title),
						}))
					),
				};
			}
		});
	};

	const { data: events, isLoading: loadingEvents } = useQuery(
		[
			getFacultyInternships,
			false,
			props.location.pathname,
			skills,
			types,
			categories,
			statuses,
			searchTerm,
			currentPage,
			pageSize,
			sortField,
			sortDirection,
		],
		() => {
			return getAllInternshipsTypeEvent(
				skills,
				categories,
				types,
				searchTerm,
				currentPage,
				pageSize,
				sortField,
				sortDirection
			);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	return (
		<Layout>
			<Row>
				<Col span={4} className={styles.filters}>
					<Search
						defaultValue={searchTerm}
						className={styles.searchBox}
						placeholder={t("internships.search")}
						allowClear
						onSearch={setSearchTerm}
						style={{ width: 200 }}
					/>
					<CustomSort
						changeSortField={changeSortField}
						changeSortDirection={changeSortDirection}
						sortDirection={sortDirection}
						location={props.location}
						oldValue={sortField}
					/>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Col>
				<Drawer
					className={styles.opened}
					placement={"left"}
					closable={true}
					onClose={onClose}
					visible={visible}
					key={"left"}
					maskClosable={true}
					title={t("internships.filters.filters")}
				>
					<Search
						defaultValue={searchTerm}
						placeholder={t("internships.search")}
						allowClear
						onSearch={setSearchTerm}
					/>
					<CustomSort
						changeSortField={changeSortField}
						changeSortDirection={changeSortDirection}
						sortDirection={sortDirection}
						location={props.location}
					/>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Drawer>
				<Row justify="space-between" className={styles.filterButton}>
					<Col
						span={window.innerWidth > 350 ? 12 : 24}
						style={{
							display: "flex",
							justifyContent: window.innerWidth < 351 ? "center" : "flex-start",
						}}
					>
						<Button
							onClick={showDrawer}
							style={{ outline: "none", border: "none", boxShadow: "none" }}
						>
							<FontAwesomeIcon
								icon={solid("filter")}
								style={{ color: theme.secondColor }}
							/>
							&nbsp;{t("internships.filters.filter")}
						</Button>
					</Col>
				</Row>
				<Col
					span={window.innerWidth > 1100 ? 20 : 24}
					style={{ padding: "0 0 0 3%", marginTop: "20px" }}
				>
					<EventList
						data={events}
						checkedKeys={checkedKeys}
						loading={loadingEvents}
						location={props.location}
						currentPage={currentPage}
						pageSize={pageSize}
						searchTerm={searchTerm}
						sortDirection={sortDirection}
						sortField={sortField}
						handleListChange={handleListChange}
					/>
				</Col>
			</Row>
		</Layout>
	);
};

export default AllEvents;
