/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProfessorResearchProposalAddUpdateDTO,
    ProfessorResearchProposalAddUpdateDTOFromJSON,
    ProfessorResearchProposalAddUpdateDTOToJSON,
    ResearchProposalDTO,
    ResearchProposalDTOFromJSON,
    ResearchProposalDTOToJSON,
    ResearchProposalDTOPagedResponse,
    ResearchProposalDTOPagedResponseFromJSON,
    ResearchProposalDTOPagedResponseToJSON,
} from '../models';

export interface ApiProfessorResearchProposalAddPostRequest {
    professorResearchProposalAddUpdateDTO?: ProfessorResearchProposalAddUpdateDTO;
}

export interface ApiProfessorResearchProposalDeleteIdDeleteRequest {
    id: string;
}

export interface ApiProfessorResearchProposalGetAllGetRequest {
    page?: number;
    pageSize?: number;
}

export interface ApiProfessorResearchProposalGetByIdIdGetRequest {
    id: string;
}

export interface ApiProfessorResearchProposalUpdateIdPutRequest {
    id: string;
    professorResearchProposalAddUpdateDTO?: ProfessorResearchProposalAddUpdateDTO;
}

/**
 * 
 */
export class ProfessorResearchProposalApi extends runtime.BaseAPI {

    /**
     */
    async apiProfessorResearchProposalAddPostRaw(requestParameters: ApiProfessorResearchProposalAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ProfessorResearchProposal/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfessorResearchProposalAddUpdateDTOToJSON(requestParameters.professorResearchProposalAddUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProfessorResearchProposalAddPost(requestParameters: ApiProfessorResearchProposalAddPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiProfessorResearchProposalAddPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiProfessorResearchProposalDeleteIdDeleteRaw(requestParameters: ApiProfessorResearchProposalDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProfessorResearchProposalDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ProfessorResearchProposal/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProfessorResearchProposalDeleteIdDelete(requestParameters: ApiProfessorResearchProposalDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiProfessorResearchProposalDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiProfessorResearchProposalGetAllGetRaw(requestParameters: ApiProfessorResearchProposalGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResearchProposalDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ProfessorResearchProposal/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResearchProposalDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiProfessorResearchProposalGetAllGet(requestParameters: ApiProfessorResearchProposalGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<ResearchProposalDTOPagedResponse> {
        const response = await this.apiProfessorResearchProposalGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProfessorResearchProposalGetByIdIdGetRaw(requestParameters: ApiProfessorResearchProposalGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResearchProposalDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProfessorResearchProposalGetByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ProfessorResearchProposal/GetById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResearchProposalDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiProfessorResearchProposalGetByIdIdGet(requestParameters: ApiProfessorResearchProposalGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<ResearchProposalDTO> {
        const response = await this.apiProfessorResearchProposalGetByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProfessorResearchProposalUpdateIdPutRaw(requestParameters: ApiProfessorResearchProposalUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProfessorResearchProposalUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ProfessorResearchProposal/Update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProfessorResearchProposalAddUpdateDTOToJSON(requestParameters.professorResearchProposalAddUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProfessorResearchProposalUpdateIdPut(requestParameters: ApiProfessorResearchProposalUpdateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiProfessorResearchProposalUpdateIdPutRaw(requestParameters, initOverrides);
    }

}
