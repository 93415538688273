/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PracticeNotebookAddDTO,
    PracticeNotebookAddDTOFromJSON,
    PracticeNotebookAddDTOToJSON,
    PracticeNotebookDTO,
    PracticeNotebookDTOFromJSON,
    PracticeNotebookDTOToJSON,
    PracticeNotebookUpdateDTO,
    PracticeNotebookUpdateDTOFromJSON,
    PracticeNotebookUpdateDTOToJSON,
} from '../models';

export interface ApiPracticeNotebookAddPostRequest {
    practiceNotebookAddDTO?: PracticeNotebookAddDTO;
}

export interface ApiPracticeNotebookAddSignatureNotebookIdPostRequest {
    notebookId: string;
}

export interface ApiPracticeNotebookApproveNotebookIdPutRequest {
    id: string;
}

export interface ApiPracticeNotebookDeleteIdDeleteRequest {
    id: string;
}

export interface ApiPracticeNotebookDownloadFileSignatureIdGetRequest {
    signatureId: string;
}

export interface ApiPracticeNotebookGenerateNotebookIdGetRequest {
    id: string;
}

export interface ApiPracticeNotebookGetForEnrollmentInternshipEnrollmentIdGetRequest {
    internshipEnrollmentId: string;
}

export interface ApiPracticeNotebookRejectNotebookIdPutRequest {
    id: string;
    comment?: string;
}

export interface ApiPracticeNotebookUpdateIdPutRequest {
    id: string;
    practiceNotebookUpdateDTO?: PracticeNotebookUpdateDTO;
}

export interface ApiPracticeNotebookUploadFinalizedInternshipEnrollmentIdPostRequest {
    internshipEnrollmentId: string;
    file?: Blob;
}

/**
 * 
 */
export class PracticeNotebookApi extends runtime.BaseAPI {

    /**
     */
    async apiPracticeNotebookAddPostRaw(requestParameters: ApiPracticeNotebookAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PracticeNotebookAddDTOToJSON(requestParameters.practiceNotebookAddDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookAddPost(requestParameters: ApiPracticeNotebookAddPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeNotebookAddPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeNotebookAddSignatureNotebookIdPostRaw(requestParameters: ApiPracticeNotebookAddSignatureNotebookIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notebookId === null || requestParameters.notebookId === undefined) {
            throw new runtime.RequiredError('notebookId','Required parameter requestParameters.notebookId was null or undefined when calling apiPracticeNotebookAddSignatureNotebookIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/AddSignature/{notebookId}`.replace(`{${"notebookId"}}`, encodeURIComponent(String(requestParameters.notebookId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookAddSignatureNotebookIdPost(requestParameters: ApiPracticeNotebookAddSignatureNotebookIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeNotebookAddSignatureNotebookIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeNotebookApproveNotebookIdPutRaw(requestParameters: ApiPracticeNotebookApproveNotebookIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeNotebookApproveNotebookIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/ApproveNotebook/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookApproveNotebookIdPut(requestParameters: ApiPracticeNotebookApproveNotebookIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeNotebookApproveNotebookIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeNotebookDeleteIdDeleteRaw(requestParameters: ApiPracticeNotebookDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeNotebookDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookDeleteIdDelete(requestParameters: ApiPracticeNotebookDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeNotebookDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeNotebookDownloadFileSignatureIdGetRaw(requestParameters: ApiPracticeNotebookDownloadFileSignatureIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.signatureId === null || requestParameters.signatureId === undefined) {
            throw new runtime.RequiredError('signatureId','Required parameter requestParameters.signatureId was null or undefined when calling apiPracticeNotebookDownloadFileSignatureIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/DownloadFile/{signatureId}`.replace(`{${"signatureId"}}`, encodeURIComponent(String(requestParameters.signatureId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookDownloadFileSignatureIdGet(requestParameters: ApiPracticeNotebookDownloadFileSignatureIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeNotebookDownloadFileSignatureIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeNotebookGenerateNotebookIdGetRaw(requestParameters: ApiPracticeNotebookGenerateNotebookIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeNotebookGenerateNotebookIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/GenerateNotebook/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookGenerateNotebookIdGet(requestParameters: ApiPracticeNotebookGenerateNotebookIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeNotebookGenerateNotebookIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeNotebookGetForEnrollmentInternshipEnrollmentIdGetRaw(requestParameters: ApiPracticeNotebookGetForEnrollmentInternshipEnrollmentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeNotebookDTO>> {
        if (requestParameters.internshipEnrollmentId === null || requestParameters.internshipEnrollmentId === undefined) {
            throw new runtime.RequiredError('internshipEnrollmentId','Required parameter requestParameters.internshipEnrollmentId was null or undefined when calling apiPracticeNotebookGetForEnrollmentInternshipEnrollmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/GetForEnrollment/{internshipEnrollmentId}`.replace(`{${"internshipEnrollmentId"}}`, encodeURIComponent(String(requestParameters.internshipEnrollmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeNotebookDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiPracticeNotebookGetForEnrollmentInternshipEnrollmentIdGet(requestParameters: ApiPracticeNotebookGetForEnrollmentInternshipEnrollmentIdGetRequest, initOverrides?: RequestInit): Promise<PracticeNotebookDTO> {
        const response = await this.apiPracticeNotebookGetForEnrollmentInternshipEnrollmentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeNotebookRejectNotebookIdPutRaw(requestParameters: ApiPracticeNotebookRejectNotebookIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeNotebookRejectNotebookIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.comment !== undefined) {
            queryParameters['comment'] = requestParameters.comment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/RejectNotebook/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookRejectNotebookIdPut(requestParameters: ApiPracticeNotebookRejectNotebookIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeNotebookRejectNotebookIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeNotebookUpdateIdPutRaw(requestParameters: ApiPracticeNotebookUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeNotebookUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/Update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PracticeNotebookUpdateDTOToJSON(requestParameters.practiceNotebookUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookUpdateIdPut(requestParameters: ApiPracticeNotebookUpdateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeNotebookUpdateIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeNotebookUploadFinalizedInternshipEnrollmentIdPostRaw(requestParameters: ApiPracticeNotebookUploadFinalizedInternshipEnrollmentIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.internshipEnrollmentId === null || requestParameters.internshipEnrollmentId === undefined) {
            throw new runtime.RequiredError('internshipEnrollmentId','Required parameter requestParameters.internshipEnrollmentId was null or undefined when calling apiPracticeNotebookUploadFinalizedInternshipEnrollmentIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/PracticeNotebook/UploadFinalized/{internshipEnrollmentId}`.replace(`{${"internshipEnrollmentId"}}`, encodeURIComponent(String(requestParameters.internshipEnrollmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeNotebookUploadFinalizedInternshipEnrollmentIdPost(requestParameters: ApiPracticeNotebookUploadFinalizedInternshipEnrollmentIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeNotebookUploadFinalizedInternshipEnrollmentIdPostRaw(requestParameters, initOverrides);
    }

}
