import { Avatar, Col, Divider, Row, Typography } from "antd";
import AddUpdateAppreciationModal from "./AddUpdateAppreciationModal";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsProfessor } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { selectStudentId } from "./StudentSlice";
import { getAppreciations } from "../../Requests/appreciations-requests";
import { getStudentAppreciations } from "../../utils/reactQueriesConstants";

const Appreciations = () => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const studentId = useSelector(selectStudentId);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: studentAppreciations } = useQuery(
		[getStudentAppreciations, studentId],
		() => getAppreciations(studentId!),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	return (
		<div>
			{isProfessor && <AddUpdateAppreciationModal />}
			{studentAppreciations?.map((item) => {
				return (
					<div key={item.id}>
						<Divider style={{ borderTop: "2px solid black" }} />
						<Row gutter={[8, 8]}>
							{window.innerWidth > 1215 && (
								<Col span={2}>
									<Row justify="center">
										{item.professorImage ? (
											<Avatar size={100} src={item.professorImage} />
										) : (
											<Avatar
												size={100}
												icon={
													<FontAwesomeIcon
														icon={solid("user")}
														title={t("account.userPicture")}
													/>
												}
											/>
										)}
									</Row>
									<Row justify="center">
										<Typography style={{ fontSize: 15, textAlign: "center" }}>
											<Typography.Text strong={true}>
												{item.professorName}
											</Typography.Text>
										</Typography>
									</Row>
								</Col>
							)}
							<Col span={window.innerWidth > 1215 ? 22 : 24}>
								<Row gutter={10}>
									{item.title && (
										<Col>
											<Typography style={{ fontSize: 20 }}>
												<Typography.Text strong={true}>
													{item.title}
												</Typography.Text>
											</Typography>
										</Col>
									)}
									{item.receiveDate && (
										<Col>
											<Typography
												style={{
													fontSize: 14,
													color: theme.forthColor,
													fontStyle: "italic",
												}}
											>
												<Typography.Text strong={true}>
													{item.receiveDate.getDate() +
														"." +
														(item.receiveDate.getMonth() + 1) +
														"." +
														item.receiveDate.getFullYear()}
												</Typography.Text>
											</Typography>
										</Col>
									)}
								</Row>
								<Row>
									<Row>
										<div
											style={{
												fontSize: 15,
												textAlign: "left",
												fontStyle: "italic",
											}}
											dangerouslySetInnerHTML={{ __html: item.message || "" }}
										/>
									</Row>
								</Row>
							</Col>
							{window.innerWidth <= 1215 && (
								<Col
									span={24}
									style={{ display: "flex", justifyContent: "flex-start" }}
								>
									<Typography style={{ fontSize: 15, textAlign: "center" }}>
										<Typography.Text strong={true}>
											{item.professorName}
										</Typography.Text>
									</Typography>
								</Col>
							)}
						</Row>
					</div>
				);
			})}
		</div>
	);
};

export default Appreciations;
