/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PracticeConventionSigningEnum {
    Supervisor = 'Supervisor',
    Tutor = 'Tutor',
    Student = 'Student',
    Representative = 'Representative',
    Dean = 'Dean'
}

export function PracticeConventionSigningEnumFromJSON(json: any): PracticeConventionSigningEnum {
    return PracticeConventionSigningEnumFromJSONTyped(json, false);
}

export function PracticeConventionSigningEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeConventionSigningEnum {
    return json as PracticeConventionSigningEnum;
}

export function PracticeConventionSigningEnumToJSON(value?: PracticeConventionSigningEnum | null): any {
    return value as any;
}

