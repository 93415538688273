/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtendedViewStudentDTO,
    ExtendedViewStudentDTOFromJSON,
    ExtendedViewStudentDTOFromJSONTyped,
    ExtendedViewStudentDTOToJSON,
} from './ExtendedViewStudentDTO';
import {
    InternshipDTO,
    InternshipDTOFromJSON,
    InternshipDTOFromJSONTyped,
    InternshipDTOToJSON,
} from './InternshipDTO';
import {
    InternshipParticipationType,
    InternshipParticipationTypeFromJSON,
    InternshipParticipationTypeFromJSONTyped,
    InternshipParticipationTypeToJSON,
} from './InternshipParticipationType';

/**
 * 
 * @export
 * @interface InternshipParticipationIntentDTO
 */
export interface InternshipParticipationIntentDTO {
    /**
     * 
     * @type {string}
     * @memberof InternshipParticipationIntentDTO
     */
    internshipId?: string;
    /**
     * 
     * @type {InternshipParticipationType}
     * @memberof InternshipParticipationIntentDTO
     */
    participationType?: InternshipParticipationType;
    /**
     * 
     * @type {ExtendedViewStudentDTO}
     * @memberof InternshipParticipationIntentDTO
     */
    user?: ExtendedViewStudentDTO;
    /**
     * 
     * @type {InternshipDTO}
     * @memberof InternshipParticipationIntentDTO
     */
    internship?: InternshipDTO;
}

export function InternshipParticipationIntentDTOFromJSON(json: any): InternshipParticipationIntentDTO {
    return InternshipParticipationIntentDTOFromJSONTyped(json, false);
}

export function InternshipParticipationIntentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipParticipationIntentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'internshipId': !exists(json, 'internshipId') ? undefined : json['internshipId'],
        'participationType': !exists(json, 'participationType') ? undefined : InternshipParticipationTypeFromJSON(json['participationType']),
        'user': !exists(json, 'user') ? undefined : ExtendedViewStudentDTOFromJSON(json['user']),
        'internship': !exists(json, 'internship') ? undefined : InternshipDTOFromJSON(json['internship']),
    };
}

export function InternshipParticipationIntentDTOToJSON(value?: InternshipParticipationIntentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internshipId': value.internshipId,
        'participationType': InternshipParticipationTypeToJSON(value.participationType),
        'user': ExtendedViewStudentDTOToJSON(value.user),
        'internship': InternshipDTOToJSON(value.internship),
    };
}

