import Layout from "../../Containers/Layout";
import { Avatar, Col, List, Row, Spin } from "antd";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Internships.module.scss";
import { isUserLogged } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	EnrollmentStatus,
	ErrorCodes,
	InternshipEngagementType,
	InternshipType,
	Role,
} from "../../Api";
import {
	accept,
	cancel,
	getAllApplications,
} from "../../Requests/internship-requests";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import InternshipActions from "./InternshipActions";
import { theme } from "../../theme";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../../CustomComponents/CustomButton";
import { useQuery, useQueryClient } from "react-query";
import {
	getAllApplicationTypesCount,
	getApplicationsData,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";

const StudentInternships = (props: any) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [selectedInternshipId, setSelectedInternshipId] = useState<string>("");
	const [skills, setSkills] = useState([]);
	const [categories, setCategories] = useState([]);
	const [types, setTypes] = useState([]);
	const [locations, setLocations] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const location = useLocation();
	const queryClient = useQueryClient();
	const [acceptInternshipModalVisibility, setAcceptInternshipModalVisibility] =
		useState(false);
	const [
		cancelApplicationModalVisibility,
		setCancelApplicationModalVisibility,
	] = useState(false);
	const [spinning, setSpinning] = useState(false);

	let locale = {
		emptyText: t("tableText.noApplications"),
	};

	const openGetApplicationsErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchApplicationsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[
			getApplicationsData,
			skills,
			categories,
			types,
			locations,
			searchTerm,
			currentPage,
			pageSize,
			props.location.pathname,
		],
		() => {
			if (props.location.pathname === "/aplicarile-mele") {
				return getAllApplications(
					skills,
					categories,
					types,
					locations,
					searchTerm,
					[EnrollmentStatus.Waiting],
					undefined,
					currentPage,
					pageSize
				);
			} else if (props.location.pathname === "/bursele-mele") {
				return getAllApplications(
					skills,
					categories,
					[InternshipEngagementType.Scholarship],
					locations,
					searchTerm,
					[EnrollmentStatus.Accepted],
					[EnrollmentStatus.Accepted],
					currentPage,
					pageSize
				);
			} else {
				return getAllApplications(
					skills,
					categories,
					[
						InternshipEngagementType.Job,
						InternshipEngagementType.ResearchInternship,
					],
					locations,
					searchTerm,
					[EnrollmentStatus.Accepted],
					[EnrollmentStatus.Accepted],
					currentPage,
					pageSize
				);
			}
		},
		{
			onError: openGetApplicationsErrorNotification,
		}
	);

	const handleListChange = (current: number, pageSize: number) => {
		setCurrent(() => current);
		setPageSize(() => pageSize);
	};

	const cancelApplication = (internshipId: string) => {
		setSpinning(true);

		cancel(internshipId)
			.then(async () => {
				await queryClient.invalidateQueries(getApplicationsData);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("internships.withdrawApplication"),
					t("internships.applicationWithdrawalSuccessMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				console.error(_error);

				openNotification(
					t("internships.withdrawApplication"),
					t("internships.applicationWithdrawalErrorMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setCancelApplicationModalVisibility(false);
			});
	};

	const acceptOffer = (internshipId: string) => {
		setSpinning(true);

		accept(internshipId)
			.then(async () => {
				//dispatch(studentInternshipEnrollmentIdSet(item.internshipId));
				await queryClient.invalidateQueries(getApplicationsData);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("internships.acceptOffer"),
					t("internships.acceptOfferSuccessMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.InternshipEnrollmentNotFound:
						notificationMessage = t("internships.enrollmentNotFound");
						break;
					case ErrorCodes.InvalidEnrollmentStatus:
						notificationMessage = t("internships.invalidEnrollmentStatus");
						break;
					case ErrorCodes.MissingStudentGroup:
						notificationMessage = t("internships.groupMissing");
						break;
					case ErrorCodes.MissingStudentStudyProgram:
						notificationMessage = t("internships.studyProgramMissing");
						break;
					case ErrorCodes.MissingStudentPersonalData:
						notificationMessage = t("internships.personalDataMissing");
						break;
					default:
						notificationMessage = t("internships.acceptOfferErrorMessage");
						break;
				}

				openNotification(
					t("internships.acceptOffer"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setAcceptInternshipModalVisibility(false);
			});
	};

	return (
		<Layout>
			<Row>
				<Col span={24}>
					<Spin size="large" spinning={loading}>
						{data && (
							<List
								locale={locale}
								itemLayout="vertical"
								size="large"
								pagination={{
									total: data?.totalCount,
									current: currentPage,
									pageSize: data?.pageSize,
									pageSizeOptions: ["10", "20", "50"],
									showSizeChanger: true,
									locale: { items_per_page: t("configuration.pagination") },
									position: "both",
									hideOnSinglePage: data?.totalCount
										? data.totalCount <= 10
										: true,
									onChange: handleListChange,
								}}
								loading={loading}
								dataSource={data?.data || []}
								renderItem={(item: any) => (
									<List.Item
										className={styles.ListItem}
										key={item.id}
										actions={[
											<InternshipActions item={item} activeTab={""} />,
										].concat([
											<div className={styles.buttonsContainer}>
												<Row>
													{isUserLogged() &&
													item.recruiterStatus === EnrollmentStatus.Accepted &&
													item.studentStatus !== EnrollmentStatus.Accepted ? (
														<>
															<CustomButton
																fontSize={"0.9rem"}
																style={{
																	background: "none",
																	color: theme.black,
																	marginRight: "3rem",
																	marginTop: "0",
																	boxShadow: "none",
																	padding: 0,
																	fontSize: "14px",
																}}
																onClick={() => {
																	setSelectedInternshipId(item.internshipId);
																	setAcceptInternshipModalVisibility(true);
																}}
																title={t("internships.acceptInternshipOffer")}
																icon={
																	<FontAwesomeIcon
																		icon={solid("circle-check")}
																		style={{ paddingRight: "5%" }}
																	/>
																}
																shape={"circle"}
															>
																{t("internships.acceptInternshipOffer")}
															</CustomButton>
															<ConfirmationModal
																modalText={t(
																	"internships.acceptInternshipMessage"
																)}
																handleFunction={() =>
																	acceptOffer(selectedInternshipId)
																}
																modalVisibility={
																	acceptInternshipModalVisibility
																}
																changeModalVisibility={() =>
																	setAcceptInternshipModalVisibility(false)
																}
																title=""
																spinning={spinning}
															/>
														</>
													) : null}
													{isUserLogged() &&
													item.studentStatus !== EnrollmentStatus.Accepted ? (
														<>
															<CustomButton
																fontSize={"0.9rem"}
																style={{
																	background: "none",
																	color: theme.black,
																	marginRight: "1rem",
																	marginTop: "0",
																	boxShadow: "none",
																	padding: 0,
																	fontSize: "14px",
																}}
																onClick={() => {
																	setSelectedInternshipId(item.internshipId);
																	setCancelApplicationModalVisibility(true);
																}}
																title={t("internships.reject")}
																icon={
																	<FontAwesomeIcon
																		icon={solid("ban")}
																		style={{ paddingRight: "5%" }}
																	/>
																}
																shape={"circle"}
															>
																{t("internships.reject")}
															</CustomButton>
															<ConfirmationModal
																modalText={t(
																	"internships.withdrawInternshipMessage"
																)}
																handleFunction={() =>
																	cancelApplication(selectedInternshipId)
																}
																modalVisibility={
																	cancelApplicationModalVisibility
																}
																title=""
																changeModalVisibility={() =>
																	setCancelApplicationModalVisibility(false)
																}
																spinning={spinning}
															/>
														</>
													) : null}
												</Row>
											</div>,
										])}
									>
										<List.Item.Meta
											className={styles.listItemMeta}
											avatar={
												<Link
													to={{
														pathname: `/profil`,
														state: {
															id: item.companyName
																? item.companyId
																: item.professorId,
															type: item.companyName
																? InternshipType.ByCompany
																: InternshipType.ByProfessor,
															userType: item.companyName
																? Role.Company
																: Role.Professor,
															origin: location.pathname,
														},
													}}
												>
													<Row>
														<Col span={24} className={styles.customCol}>
															<Avatar
																src={item.companyLogo || item.professorHeadshot}
															/>
														</Col>
														<Col span={24} className={styles.customCol}>
															<p>{item.companyName || item.professorName}</p>
														</Col>
													</Row>
												</Link>
											}
											title={
												<Link
													to={{
														pathname: "/propunere/" + item.id,
														search:
															"?type=" +
															(item.companyName
																? InternshipType.ByCompany
																: InternshipType.ByProfessor),
														state: {
															id: item.id,
															type: item.companyName
																? InternshipType.ByCompany
																: InternshipType.ByProfessor,
															origin: location.pathname,
														},
													}}
												>
													{item.internshipName}
												</Link>
											}
											description={item.skillsRequired?.join(", ")}
										/>
										{item.shortDescription}
									</List.Item>
								)}
							/>
						)}
					</Spin>
				</Col>
			</Row>
		</Layout>
	);
};

export default StudentInternships;
