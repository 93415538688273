/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PreferredLanguageEnum {
    Ro = 'RO',
    En = 'EN'
}

export function PreferredLanguageEnumFromJSON(json: any): PreferredLanguageEnum {
    return PreferredLanguageEnumFromJSONTyped(json, false);
}

export function PreferredLanguageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreferredLanguageEnum {
    return json as PreferredLanguageEnum;
}

export function PreferredLanguageEnumToJSON(value?: PreferredLanguageEnum | null): any {
    return value as any;
}

