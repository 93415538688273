/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    CompanyUser,
    CompanyUserFromJSON,
    CompanyUserFromJSONTyped,
    CompanyUserToJSON,
} from './CompanyUser';
import {
    FormTypeEnum,
    FormTypeEnumFromJSON,
    FormTypeEnumFromJSONTyped,
    FormTypeEnumToJSON,
} from './FormTypeEnum';
import {
    Question,
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';

/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Answer
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Answer
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    questionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    academicUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    companyUserId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Answer
     */
    answers?: Array<string> | null;
    /**
     * 
     * @type {FormTypeEnum}
     * @memberof Answer
     */
    formType?: FormTypeEnum;
    /**
     * 
     * @type {Question}
     * @memberof Answer
     */
    question?: Question;
    /**
     * 
     * @type {AcademicUser}
     * @memberof Answer
     */
    academicUser?: AcademicUser;
    /**
     * 
     * @type {CompanyUser}
     * @memberof Answer
     */
    companyUser?: CompanyUser;
}

export function AnswerFromJSON(json: any): Answer {
    return AnswerFromJSONTyped(json, false);
}

export function AnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Answer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'questionId': !exists(json, 'questionId') ? undefined : json['questionId'],
        'academicUserId': !exists(json, 'academicUserId') ? undefined : json['academicUserId'],
        'companyUserId': !exists(json, 'companyUserId') ? undefined : json['companyUserId'],
        'answers': !exists(json, 'answers') ? undefined : json['answers'],
        'formType': !exists(json, 'formType') ? undefined : FormTypeEnumFromJSON(json['formType']),
        'question': !exists(json, 'question') ? undefined : QuestionFromJSON(json['question']),
        'academicUser': !exists(json, 'academicUser') ? undefined : AcademicUserFromJSON(json['academicUser']),
        'companyUser': !exists(json, 'companyUser') ? undefined : CompanyUserFromJSON(json['companyUser']),
    };
}

export function AnswerToJSON(value?: Answer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'questionId': value.questionId,
        'academicUserId': value.academicUserId,
        'companyUserId': value.companyUserId,
        'answers': value.answers,
        'formType': FormTypeEnumToJSON(value.formType),
        'question': QuestionToJSON(value.question),
        'academicUser': AcademicUserToJSON(value.academicUser),
        'companyUser': CompanyUserToJSON(value.companyUser),
    };
}

