import Layout from "../../Containers/Layout";
import { useState } from "react";
import { CompanyStateEnum, Filter, Role } from "../../Api";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { Col, Row, Table, TablePaginationConfig, Tabs, Tree } from "antd";
import Search from "antd/lib/input/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	getAllAcademicUsersNonStudents,
	getAllProfessors,
	getProfessorFilters,
	updateAcademicUserRole,
} from "../../Requests/academic-user-requests";
import AddUserRolesModal from "./AddUserRolesModal";
import {
	activateCompany,
	validateCompany,
	getCompanies,
	getCompaniesFilter,
	invalidateCompany,
} from "../../Requests/company-requests";
import styles from "../Admin/Users.module.scss";
import { Key } from "antd/lib/table/interface";
import {
	getAllKeys,
	getKey,
	useBeforeRender,
	useIsAdmin,
	useIsDean,
	useIsDepartmentDirector,
	useIsFacultyAdmin,
	useIsRector,
} from "../../utils/utilFunctions";
import StudentsTable from "../Students/StudentsTable";
import { filtersToDataNodes } from "../../utils/dataUtils";
import { theme } from "../../theme";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useQuery, useQueryClient } from "react-query";
import MyStudentsTable from "../Students/MyStudentsTable";
import { Link, useHistory, useLocation } from "react-router-dom";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	getAllCompanies,
	getCompanyFilters,
	getFilterForProfessor,
	getUsers,
} from "../../utils/reactQueriesConstants";

const { TabPane } = Tabs;

const Users = () => {
	const { t, i18n } = useTranslation();
	const [searchTerm, setSearchTerm] = useState("");
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [faculties, setFaculties] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [states, setStates] = useState([]);
	const [documentsStatus, setDocumentsStatus] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
	const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
	const [expandedKeys2, setExpandedKeys2] = useState<any[]>([]);
	const [checkedKeys2, setCheckedKeys2] = useState<Key[]>([]);
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDean = useIsDean();
	const isAdmin = useIsAdmin();
	const isRector = useIsRector();
	const location = useLocation();
	const history = useHistory();
	const [companyId, setCompanyId] = useState("");
	const [activateCompanyModalVisibility, setActivateCompanyModalVisibility] =
		useState(false);
	const [validateCompanyModalVisibility, setValidateCompanyModalVisibility] =
		useState(false);
	const [
		invalidateCompanyModalVisibility,
		setInvalidateCompanyModalVisibility,
	] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [activeTab, setActiveTab] = useState(
		isFacultyAdmin || isDepartmentDirector ? "1" : "2"
	);

	let locale = {
		emptyText: t("tableText.noCompanies"),
	};

	const FilterByNameInput = (
		<Search
			placeholder={t("students.name")}
			allowClear
			onSearch={(value) => {
				setSearchTerm(value);
				setCurrent(1);
			}}
			style={{ width: 200 }}
			defaultValue={searchTerm}
		/>
	);

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrent(1);
		setFaculties(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Faculty@"))
				.map((e: string) => e.split("@")[1])
		);

		setDepartments(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Department@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const onCheck2 = (checkedKeysValue: any) => {
		setCheckedKeys2(checkedKeysValue);
		setCurrent(1);
		setStates(
			checkedKeysValue
				.filter((e: string) => e.startsWith("State@"))
				.map((e: string) => e.split("@")[1])
		);
		setDocumentsStatus(
			checkedKeysValue
				.filter((e: string) => e.startsWith("PracticeProtocolStatus@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const getOldValues = () => {
		if (location.state !== undefined) {
			let {
				existingFilters,
				oldSearchTerm,
				currentPage,
				pageSize,
				activeTab,
			}: any = location.state;

			if (activeTab !== "3") {
				onCheck2(existingFilters || checkedKeys2);
			} else {
				onCheck(existingFilters || checkedKeys);
			}

			setActiveTab(activeTab);
			setSearchTerm(oldSearchTerm);
			setCurrent(currentPage);
			setPageSize(pageSize);

			history.replace({ ...history.location, state: undefined });
		}
	};

	useBeforeRender(() => getOldValues(), [location]);

	const columns = [
		{
			title: FilterByNameInput,
			dataIndex: "name",
			key: "name",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Professor,
							origin: location.pathname,
							activeTab: activeTab,
							oldSearchTerm: searchTerm,
							pageSize: pageSize,
							currentPage: currentPage,
							filters: checkedKeys,
						},
					}}
				>
					<b>{record.name}</b>
				</Link>
			),
		},
		{
			title: t("students.faculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
			hidden: isFacultyAdmin || isDean,
		},
		{
			title: t("admin.department"),
			dataIndex: "departmentNameRo",
			key: "departmentNameRo",
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
		},
		{
			title: t("students.phone"),
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: t("admin.roles"),
			dataIndex: "roles",
			key: "roles",
			render: (text: string | undefined, record: any) => (
				<AddUserRolesModal
					roles={record.roles}
					icon={
						<FontAwesomeIcon
							icon={solid("users-gear")}
							title={t("admin.editRoles")}
						/>
					}
					userId={record.id}
				/>
			),
			hidden: !isAdmin && !isDean && !isFacultyAdmin,
		},
	].filter((item) => !item.hidden);

	const companyColumns = [
		{
			title: FilterByNameInput,
			dataIndex: "name",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Company,
							origin: location.pathname,
							activeTab: activeTab,
							oldSearchTerm: searchTerm,
							pageSize: pageSize,
							currentPage: currentPage,
							filters: checkedKeys2,
						},
					}}
				>
					<b>{record.commercialName}</b>
				</Link>
			),
		},
		{
			title: t("admin.cui"),
			dataIndex: "cui",
			key: "cui",
		},
		{
			title: t("admin.domainOfActivity"),
			dataIndex: "domainOfActivity",
			key: "domainOfActivity",
		},
		{
			title: t("students.phone"),
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: t("admin.PracticeProtocolStatus"),
			dataIndex: "companyDocumentStatus",
			key: "companyDocumentStatus",
			render: (text: string | undefined, record: any) => (
				<span>{t("admin." + record.companyDocumentStatus)}</span>
			),
		},
		// {
		//     title: t('profile.address'),
		//     dataIndex: 'address',
		//     key: 'address',
		//     render: (text: string | undefined, record: any) =>
		//         <span>{record.country ? record.country + ', ' : ''}{record.city ? record.city + ', ' : ''}{record.address ? record.address : '-'}</span>
		// },
		// {
		//     title: t('students.email'),
		//     dataIndex: 'emailContact',
		//     key: 'emailContact',
		// },
		{
			title: t("admin.State"),
			dataIndex: "state",
			render: (_text: string | undefined, record: any) =>
				record.state === CompanyStateEnum.AdminApproved ? (
					<div style={{ whiteSpace: "nowrap" }}>
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								onClick={() => {
									setCompanyId(record.id);
									setActivateCompanyModalVisibility(true);
								}}
								icon={<FontAwesomeIcon icon={solid("user-check")} />}
								title={t("admin.activateCompany")}
							/>
							<ConfirmationModal
								modalText={t("admin.activateCompanyMessage")}
								handleFunction={() =>
									changeCompanyState(CompanyStateEnum.Active, companyId)
								}
								modalVisibility={activateCompanyModalVisibility}
								changeModalVisibility={() =>
									setActivateCompanyModalVisibility(false)
								}
								title=""
								spinning={spinning}
							/>
						</div>
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								onClick={() => {
									setCompanyId(record.id);
									setInvalidateCompanyModalVisibility(true);
								}}
								icon={<FontAwesomeIcon icon={solid("ban")} />}
								title={t("admin.invalidateCompany")}
							/>
							<ConfirmationModal
								modalText={t("admin.invalidateCompanyMessage")}
								handleFunction={() =>
									changeCompanyState(
										CompanyStateEnum.PendingAdminVerification,
										companyId
									)
								}
								modalVisibility={invalidateCompanyModalVisibility}
								changeModalVisibility={() =>
									setInvalidateCompanyModalVisibility(false)
								}
								title=""
								spinning={spinning}
							/>
						</div>
					</div>
				) : record.state === CompanyStateEnum.PendingAdminVerification ? (
					<div style={{ whiteSpace: "nowrap" }}>
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								onClick={() => {
									setCompanyId(record.id);
									setValidateCompanyModalVisibility(true);
								}}
								icon={<FontAwesomeIcon icon={solid("circle-check")} />}
								title={t("admin.validateCompany")}
							/>
							<ConfirmationModal
								modalText={t("admin.validateCompanyMessage")}
								handleFunction={() =>
									changeCompanyState(CompanyStateEnum.AdminApproved, companyId)
								}
								modalVisibility={validateCompanyModalVisibility}
								changeModalVisibility={() =>
									setValidateCompanyModalVisibility(false)
								}
								title=""
								spinning={spinning}
							/>
						</div>
					</div>
				) : (
					<div>{t("admin." + record.state)}</div>
				),
		},
		{
			title: t("students.documents"),
			dataIndex: "documents",
			key: "documents",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/documente-companie",
						state: {
							activeTab: activeTab,
							oldSearchTerm: searchTerm,
							pageSize: pageSize,
							currentPage: currentPage,
							filters: checkedKeys2,
							company: record.commercialName,
							companyId: record.id,
						},
					}}
				>
					<FontAwesomeIcon
						icon={solid("arrow-right")}
						title={t("students.viewDocuments")}
					/>
				</Link>
			),
			hidden: !isFacultyAdmin,
		},
	];

	const openNotificationErrorFetchUsers = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		[
			getUsers,
			currentPage,
			pageSize,
			faculties,
			departments,
			searchTerm,
			isFacultyAdmin,
			isAdmin,
			isRector,
		],
		() => {
			if (isAdmin || isRector) {
				return getAllAcademicUsersNonStudents(
					searchTerm,
					currentPage,
					pageSize,
					faculties,
					departments
				);
			}

			if (isFacultyAdmin || isDean || isDepartmentDirector) {
				return getAllProfessors(
					searchTerm,
					currentPage,
					pageSize,
					faculties,
					departments
				);
			}
		},
		{
			onError: openNotificationErrorFetchUsers,
		}
	);

	const { data: companyData, isLoading: loadingCompanies } = useQuery(
		[
			getAllCompanies,
			states,
			documentsStatus,
			currentPage,
			pageSize,
			isAdmin,
			isRector,
			isFacultyAdmin,
			isDean,
			isDepartmentDirector,
			searchTerm,
		],
		() => {
			if (
				isAdmin ||
				isRector ||
				isFacultyAdmin ||
				isDean ||
				isDepartmentDirector
			) {
				return getCompanies(
					searchTerm,
					states,
					documentsStatus,
					undefined,
					currentPage,
					pageSize
				);
			}
		},
		{
			onError: openNotificationErrorFetchUsers,
		}
	);

	const onChangeState = (id: string, roles: any) => {
		if (!roles.includes("InternshipAdmin")) {
			roles.push("InternshipAdmin");
		} else {
			const index = roles.indexOf("InternshipAdmin");
			roles.splice(index, 1);
		}

		updateAcademicUserRole(id, { roles: roles })
			.then(() => {
				openNotification(
					t("admin.editRoles"),
					t("admin.rolesUpdatedSuccessfully"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("usersText.errorTexts.rolesUpdateError"),
					t("usersText.errorTexts.serverFailedDescription"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const handleTableChange = (pagination: TablePaginationConfig) => {
		setCurrent(() => pagination.current ?? 1);
		setPageSize(() => pagination.pageSize ?? 5);
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const onExpand2 = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys2(expandedKeysValue);
	};

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters.map((x) => {
			return {
				key: x.key,
				title: t("students.filters." + x.title),
				children: x.children,
			};
		});
	};

	const translateCompanyFilters = (filters: Filter[]): Filter[] => {
		return filters
			.filter((x) => x.title !== "FacultyOfInterest")
			.map((x) => {
				return {
					key: x.key,
					title: t("admin." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("admin." + y.title),
						}))
					),
				};
			});
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("usersText.errorTexts.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getFilterForProfessor, isAdmin, isRector, isDean, isFacultyAdmin],
		() => {
			if (isAdmin || isRector || isDean || isFacultyAdmin) {
				return getProfessorFilters(i18n.language);
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isAdmin || isRector || isDean || isFacultyAdmin) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
		}
	);

	const { data: companyFilters } = useQuery(
		[getCompanyFilters, isAdmin, isFacultyAdmin, isRector],
		() => {
			if (isAdmin || isFacultyAdmin || isRector) return getCompaniesFilter();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isAdmin)
					if (!expandedKeys2 || expandedKeys2.length === 0)
						setExpandedKeys2(getAllKeys(response));
			},
		}
	);

	const openNotificationUpdateCompanyStatusError = (error: any) => {
		if (error) {
			openNotification(
				t("usersText.errorTexts.error"),
				t("admin.validateError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const changeCompanyState = (state: string, id: string) => {
		setSpinning(true);

		if (state === CompanyStateEnum.AdminApproved) {
			validateCompany(id)
				.then(async () => {
					await queryClient.invalidateQueries(getAllCompanies);
				})
				.catch((error: any) => openNotificationUpdateCompanyStatusError(error))
				.finally(() => {
					setSpinning(false);
					setValidateCompanyModalVisibility(false);
				});
		} else if (state === CompanyStateEnum.Active) {
			activateCompany(id)
				.then(async () => {
					await queryClient.invalidateQueries(getAllCompanies);
				})
				.catch((error: any) => openNotificationUpdateCompanyStatusError(error))
				.finally(() => {
					setSpinning(false);
					setActivateCompanyModalVisibility(false);
				});
		} else {
			invalidateCompany(id)
				.then(async () => {
					await queryClient.invalidateQueries(getAllCompanies);
				})
				.catch((error: any) => openNotificationUpdateCompanyStatusError(error))
				.finally(() => {
					setSpinning(false);
					setInvalidateCompanyModalVisibility(false);
				});
		}
	};

	const changeTab = (activeKey: any) => {
		setActiveTab(activeKey);
	};

	return (
		<Layout>
			<Row style={{ justifyContent: "center" }}>
				<Tabs
					centered
					tabBarStyle={{
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}
					activeKey={activeTab}
					onChange={changeTab}
				>
					{(isFacultyAdmin || isDepartmentDirector || isDean) && (
						<TabPane tab={t("admin.myStudents")} key="1">
							<MyStudentsTable
								location={location}
								existingFilters={checkedKeys2}
								oldSearchTerm={searchTerm}
								currentPage={currentPage}
								pageSize={pageSize}
								activeTab={activeTab}
							/>
						</TabPane>
					)}
					<TabPane tab={t("admin.students")} key="2">
						<StudentsTable />
					</TabPane>
					<TabPane
						tab={
							isAdmin || isRector ? t("admin.upbStaff") : t("admin.professors")
						}
						key="3"
					>
						<Row>
							{(isAdmin || isRector || isDean || isFacultyAdmin) && filters ? (
								<Col span={4}>
									<div className={styles.filters}>
										{filters && (
											<Tree
												checkable
												onCheck={onCheck}
												onExpand={onExpand}
												expandedKeys={expandedKeys}
												checkedKeys={checkedKeys}
												selectable={false}
												treeData={filtersToDataNodes(translateFilters(filters))}
												style={{ display: "flex", textAlign: "left" }}
											/>
										)}
									</div>
								</Col>
							) : null}
							<Col
								span={
									isAdmin || isRector || isDean || isFacultyAdmin
										? filters
											? 20
											: 24
										: 24
								}
								style={{ padding: "0 0 0 3%" }}
							>
								<Table
									dataSource={data?.data || []}
									columns={columns}
									pagination={{
										total: data?.totalCount,
										current: currentPage,
										pageSize: data?.pageSize,
										pageSizeOptions: ["10", "20", "50"],
										defaultPageSize: 10,
										hideOnSinglePage: data?.totalCount
											? data?.totalCount <= 10
											: true,
										showSizeChanger: true,
										locale: { items_per_page: t("configuration.pagination") },
										position: ["topRight", "bottomRight"],
									}}
									rowKey={getKey}
									loading={loading && !data}
									onChange={handleTableChange}
									scroll={{ x: 400 }}
								/>
							</Col>
						</Row>
					</TabPane>
					{(isAdmin ||
						isRector ||
						isFacultyAdmin ||
						isDepartmentDirector ||
						isDean) && (
						<TabPane tab={t("admin.companies")} key="4">
							<Row>
								<Col span={4}>
									<div className={styles.filters}>
										{companyFilters && (
											<Tree
												checkable
												onCheck={onCheck2}
												onExpand={onExpand2}
												expandedKeys={expandedKeys2}
												checkedKeys={checkedKeys2}
												selectable={false}
												treeData={filtersToDataNodes(
													translateCompanyFilters(companyFilters)
												)}
												style={{ display: "flex", textAlign: "left" }}
											/>
										)}
									</div>
								</Col>
								<Col span={20} style={{ padding: "0 0 0 3%" }}>
									<Table
										locale={locale}
										dataSource={companyData?.data || []}
										columns={companyColumns}
										pagination={{
											total: companyData?.totalCount,
											current: currentPage,
											pageSize: companyData?.pageSize,
											pageSizeOptions: ["10", "20", "50"],
											defaultPageSize: 10,
											hideOnSinglePage: companyData?.totalCount
												? companyData?.totalCount <= 10
												: true,
											showSizeChanger: true,
											locale: { items_per_page: t("configuration.pagination") },
											position: ["topRight", "bottomRight"],
										}}
										rowKey={getKey}
										loading={loadingCompanies && !companyData}
										onChange={handleTableChange}
										scroll={{ x: 400 }}
									/>
								</Col>
							</Row>
						</TabPane>
					)}
				</Tabs>
			</Row>
		</Layout>
	);
};

export default Users;
