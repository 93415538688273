import { Button, Form, Input, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import i18n from "../../../../../../i18n";
import styles from "../../../AddInternshipForm.module.scss";
import { InternshipCompetencesFormProps } from "./InternshipCompetencesForm.types";

const { List, Item } = Form;

const InternshipCompetencesForm = (props: InternshipCompetencesFormProps) => {
	const { t } = i18n;

	return (
		<List
			initialValue={props.initialValue}
			name={props.name}
			rules={props.rules}
		>
			{(fields, { add, remove }) => (
				<Row>
					<label style={{ marginBottom: "8px" }}>
						<span style={{ color: "#ff4d4f" }}>*&nbsp;</span>
						{t("competences.label") + ":"}
					</label>
					{fields.map(({ key, name, ...restField }) => (
						<Row
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "1em",
							}}
						>
							<Col span={window.innerWidth > 1100 ? 4 : 24}>
								<Item
									{...restField}
									name={[name, "competence"]}
									initialValue=""
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("competences.competence"),
											}),
										},
										{
											max: 255,
											message:
												t("usersText.maxFieldLength1") +
												255 +
												t("usersText.maxFieldLength2"),
										},
									]}
								>
									<Input
										disabled={props.disabled ?? false}
										placeholder={t("competences.competence")}
										style={{ display: "flex" }}
									/>
								</Item>
							</Col>
							<Col span={window.innerWidth > 1100 ? 4 : 24}>
								<Item
									{...restField}
									name={[name, "type"]}
									initialValue=""
									rules={[
										{
											required: true,
											whitespace: false,
											message: t("usersText.requiredField", {
												field: t("competences.type"),
											}),
										},
										{
											max: 255,
											message:
												t("usersText.maxFieldLength1") +
												255 +
												t("usersText.maxFieldLength2"),
										},
									]}
								>
									<Input
										disabled={props.disabled ?? false}
										placeholder={t("competences.type")}
									/>
								</Item>
							</Col>
							<Col span={window.innerWidth > 1100 ? 4 : 24}>
								<Item
									{...restField}
									name={[name, "place"]}
									initialValue=""
									rules={[
										{
											required: true,
											whitespace: false,
											message: t("usersText.requiredField", {
												field: t("competences.place"),
											}),
										},
										{
											max: 255,
											message:
												t("usersText.maxFieldLength1") +
												255 +
												t("usersText.maxFieldLength2"),
										},
									]}
								>
									<Input
										disabled={props.disabled ?? false}
										placeholder={t("competences.place")}
									/>
								</Item>
							</Col>
							<Col span={window.innerWidth > 1100 ? 4 : 24}>
								<Item
									{...restField}
									name={[name, "activity"]}
									initialValue=""
									rules={[
										{
											required: true,
											whitespace: false,
											message: t("usersText.requiredField", {
												field: t("competences.activity"),
											}),
										},
										{
											max: 1023,
											message:
												t("usersText.maxFieldLength1") +
												1023 +
												t("usersText.maxFieldLength2"),
										},
									]}
								>
									<Input
										disabled={props.disabled ?? false}
										placeholder={t("competences.activity")}
									/>
								</Item>
							</Col>
							<Col span={window.innerWidth > 1100 ? 4 : 24}>
								<Item
									{...restField}
									name={[name, "observations"]}
									initialValue=""
									rules={[
										{
											max: 4095,
											message:
												t("usersText.maxFieldLength1") +
												4095 +
												t("usersText.maxFieldLength2"),
										},
									]}
								>
									<Input
										disabled={props.disabled}
										placeholder={t("competences.observations")}
									/>
								</Item>
							</Col>
							{fields.length > 1 && !props.disabled && (
								<Col
									span={window.innerWidth > 1100 ? 1 : 24}
									style={{ display: "flex", justifyContent: "center" }}
								>
									<MinusCircleOutlined
										onClick={() => remove(name)}
										style={{
											display: "flex",
											alignItems: "center",
											height: window.innerWidth > 1100 ? "50%" : "auto",
											justifyContent:
												window.innerWidth > 1100 ? "flex-start" : "center",
										}}
									/>
								</Col>
							)}
						</Row>
					))}
					<Item>
						{!props.disabled && (
							<Button
								className={styles.addCompetence}
								style={{ marginTop: window.innerWidth > 1100 ? "1em" : "0" }}
								type="dashed"
								onClick={() => add()}
								block
								icon={<PlusOutlined />}
							>
								{t("competences.add")}
							</Button>
						)}
					</Item>
				</Row>
			)}
		</List>
	);
};

export default InternshipCompetencesForm;
