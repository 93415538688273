import Layout from "../../Containers/Layout";
import styles from "./ResearchProposal.module.scss";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import { Avatar, Col, List, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import {
	deleteProfessorResearchProposal,
	getProfessorResearchProposalById,
} from "../../Requests/professor-research-proposals-requests";
import {
	deleteCompanyResearchProposal,
	getCompanyResearchProposalById,
} from "../../Requests/company-research-proposals-requests";
import {
	getUserId,
	isUserLogged,
	useIsCompany,
	useIsProfessor,
	useIsStudent,
} from "../../utils/utilFunctions";
import {
	ErrorCodes,
	ResearchProposalDTO,
	ResearchProposalType,
} from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import ResearchProposalActions from "../ResearchOffers/OfferActions";
import { useQueryClient, useQuery } from "react-query";
import {
	getErrorFromResponse,
	useErrorCodeTranslation,
} from "../../utils/responseUtils";
import {
	getAllApplicationTypesCount,
	getSpecificResearchProposal,
} from "../../utils/reactQueriesConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	applyResearchProposal,
	canApplyCompany,
	canApplyProfessor,
} from "../../Requests/research-proposal-requests";

const ResearchProposal = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const location = useLocation<
		| {
				origin: string;
				filters: any;
				searchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
		  }
		| null
		| undefined
	>();
	const [researchProposal, setResearchProposal] =
		useState<ResearchProposalDTO>();
	const [applicable, setApplicable] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const isCompany = useIsCompany();
	const isProfessor = useIsProfessor();
	const history = useHistory();
	const queryClient = useQueryClient();
	const search = useLocation().search;
	const type = new URLSearchParams(search).get("type") as any;
	const [errorCode, setErrorCode] = useState<ErrorCodes | null>(
		ErrorCodes.CannotApplyAgain
	);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

	if (!id) {
		history.push("/");
	}

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("research.error"),
			t("research.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	useQuery(
		[getSpecificResearchProposal, isProfessor, isCompany, id],
		() =>
			type === ResearchProposalType.ByProfessor
				? getProfessorResearchProposalById(id)
				: getCompanyResearchProposalById(id),
		{
			onError: (err) => {
				history.push("/404");
				openGetErrorNotification(err);
			},
			onSuccess: (response) => {
				setResearchProposal(() => {
					return response;
				});
			},
		}
	);

	const canApplyToResearchProposal = () => {
		if (!researchProposal) return false;

		let promise: Promise<void>;
		if (isCompany) {
			promise = canApplyCompany(researchProposal?.researchProposalId);
		} else {
			promise = canApplyProfessor(researchProposal?.researchProposalId);
		}

		promise
			.then(() => {
				setApplicable(() => {
					return true;
				});
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);
				setApplicable(false);
				setErrorCode(errorMessage?.code || null);
			});

		return applicable;
	};

	const applyToResearchProposal = () => {
		if (!researchProposal) return;

		setSpinning(true);

		applyResearchProposal(researchProposal?.researchProposalId)
			.then(async () => {
				setApplicable(false);
				await queryClient.invalidateQueries(getSpecificResearchProposal);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("research.applyToResearchProposalSuccess"),
					t("research.applyToResearchProposalSuccessText"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((error) => {
				console.error(error);

				if (error) {
					if (error.status === StatusCodes.CONFLICT) {
						openNotification(
							t("research.applyToResearchProposalError"),
							t("research.applyToResearchProposalConflict"),
							NOTIFICATION_TYPES.ERROR
						);
					} else {
						openNotification(
							t("research.applyToResearchProposalError"),
							t("research.applyToResearchProposalOtherError"),
							NOTIFICATION_TYPES.ERROR
						);
					}
				}
			})
			.finally(() => {
				setSpinning(false);
				setModalVisibility(false);
			});
	};

	useEffect(() => {
		if (isUserLogged() && (isCompany || isProfessor)) {
			setApplicable(canApplyToResearchProposal());
		}
	}, [researchProposal?.researchProposalId, isCompany, isProfessor]);

	const applicationMessage = useErrorCodeTranslation(errorCode);

	const canEdit = (researchProposal: ResearchProposalDTO) => {
		if (
			isProfessor &&
			researchProposal?.type === ResearchProposalType.ByCompany
		)
			return false;
		if (
			isCompany &&
			researchProposal?.type === ResearchProposalType.ByProfessor
		)
			return false;

		return true;
	};

	const canDelete = (researchProposal: ResearchProposalDTO) => {
		if (
			isProfessor &&
			researchProposal?.type === ResearchProposalType.ByCompany
		)
			return false;
		if (
			isCompany &&
			researchProposal?.type === ResearchProposalType.ByProfessor
		)
			return false;

		return true;
	};

	const handleDelete = () => {
		(isUserLogged() && isProfessor
			? deleteProfessorResearchProposal(researchProposal?.id!)
			: deleteCompanyResearchProposal(researchProposal?.id!)
		)
			.then(async () => {
				await invalidateResearchProposalsQueries();
				openNotification(
					t("research.deleteProposal"),
					t("research.deleteResearchProposalSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error: any) => {
				openNotification(
					t("research.deleteProposal"),
					t("research.deleteResearchProposalError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setDeleteModalVisibility(false);

				let state = {
					existingFilters: location.state?.filters,
					searchTerm: location.state?.searchTerm,
					sortField: location.state?.sortField,
					sortDirection: location.state?.sortDirection,
					currentPage: location.state?.currentPage,
					pageSize: location.state?.pageSize,
					activeTab: location.state?.activeTab,
				};

				history.push(location.state!.origin, state);
			});
	};

	const invalidateResearchProposalsQueries = async () => {
		// await queryClient.invalidateQueries(getResearchProposalsFilters);
		await queryClient.invalidateQueries(getSpecificResearchProposal);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	return (
		<Layout>
			<Spin
				size="large"
				spinning={!researchProposal}
				tip={t("research.loading")}
			>
				{location.state && (
					<Row>
						<Col xs={17} sm={20} md={21} lg={22} xl={22} />
						<Col xs={7} sm={4} md={3} lg={2} xl={2}>
							<Link
								to={{
									pathname: location.state.origin,
									state: {
										existingFilters: location.state.filters,
										searchTerm: location.state.searchTerm,
										sortField: location.state.sortField,
										sortDirection: location.state.sortDirection,
										currentPage: location.state.currentPage,
										pageSize: location.state.pageSize,
										activeTab: location.state?.activeTab,
									},
								}}
							>
								<CustomButton
									paddingvertical={"19px"}
									boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
								>
									{t("profile.goBack")}
								</CustomButton>
							</Link>
						</Col>
					</Row>
				)}
				<div className={styles.center}>
					{researchProposal?.type === ResearchProposalType.ByCompany && (
						<div className={styles.subTitle}>
							<Col span={24} className={styles.customCol}>
								<Avatar src={researchProposal?.recruiterAvatar} />
							</Col>
							{researchProposal?.recruiterName}
						</div>
					)}

					{researchProposal?.type === ResearchProposalType.ByProfessor && (
						<div className={styles.subTitle}>
							<Col
								span={24}
								className={styles.customCol}
								style={{ textAlign: "center" }}
							>
								<p>
									{researchProposal?.type === ResearchProposalType.ByProfessor
										? t(
												"abbreviations." + researchProposal?.professionalTitle
										  ) + " "
										: ""}{" "}
									{researchProposal?.recruiterName}
								</p>
							</Col>
						</div>
					)}
					<div className={styles.title}>
						{researchProposal?.researchProposalName}
					</div>

					<List.Item
						className={styles.ListItem}
						key={researchProposal?.id + "researchProposal"}
						actions={[
							<ResearchProposalActions
								item={researchProposal!}
								activeTab={location.state?.activeTab}
							/>,
						]}
					/>

					{location.state && (
						<Row>
							<Col xs={0} sm={0} md={6} lg={6} xl={6} />
							<Col xs={8} sm={8} md={4} lg={4} xl={4} className="center">
								{(isProfessor &&
									researchProposal?.professorId === getUserId()) ||
								(isCompany && canEdit(researchProposal!)) ? (
									<div>
										<CustomButton
											shape="circle"
											type="text"
											paddingvertical={"19px"}
											title={t("research.editProposal")}
											style={{
												padding: "8px 0px",
												background: "none",
												color: theme.black,
												marginRight: "10%",
												marginTop: "0",
												boxShadow: "none",
												fontSize: "14px",
												height: "100%",
											}}
										>
											<Link
												to={{
													pathname:
														"/editare-propunere-cercetare/" +
														researchProposal?.id,
													state: {
														filters: location.state.filters,
														activeTab: location.state.activeTab,
														currentPage: location.state.currentPage,
														pageSize: location.state.pageSize,
														searchTerm: location.state.searchTerm,
														sortDirection: location.state.sortDirection,
														sortField: location.state.sortField,
													},
												}}
											>
												<FontAwesomeIcon icon={solid("edit")} />
												&nbsp;{t("research.editProposal")}
											</Link>
										</CustomButton>
									</div>
								) : null}
							</Col>
							<Col xs={8} sm={8} md={4} lg={4} xl={4} className="center">
								{(isProfessor &&
									researchProposal?.professorId === getUserId()) ||
								(isCompany && canDelete(researchProposal!)) ? (
									<div>
										<CustomButton
											style={{
												padding: "8px 0px",
												background: "none",
												color: theme.black,
												marginRight: "10%",
												marginTop: "0",
												boxShadow: "none",
												fontSize: "14px",
												height: "100%",
											}}
											onClick={() => setDeleteModalVisibility(true)}
											//title={researchProposal && researchProposal.enrolledStudentsCount > 0 ? t('researchProposals.deletionNotAllowed') : t('researchProposals.deleteResearchProposal')}
											icon={
												<FontAwesomeIcon
													icon={solid("trash")}
													style={{ paddingRight: "5%" }}
												/>
											}
											shape={"circle"}
											type={"text"}
											//disabled={researchProposal && researchProposal.enrolledStudentsCount > 0}
										>
											{t("research.deleteProposal")}
										</CustomButton>
										<ConfirmationModal
											modalText={t("research.deleteMessage")}
											handleFunction={() => {
												handleDelete();
											}}
											modalVisibility={deleteModalVisibility}
											title=""
											changeModalVisibility={() =>
												setDeleteModalVisibility(false)
											}
											spinning={spinning}
										/>
									</div>
								) : null}
							</Col>
							<Col xs={8} sm={8} md={4} lg={4} xl={4} className="center">
								{(isProfessor &&
									researchProposal?.professorId === getUserId()) ||
								(isCompany && canEdit(researchProposal!)) ? (
									<div>
										<CustomButton
											paddingvertical={"19px"}
											shape="circle"
											type="text"
											style={{
												padding: "8px 0px",
												background: "none",
												color: theme.black,
												marginRight: "10%",
												marginTop: "0",
												boxShadow: "none",
												fontSize: "14px",
												height: "100%",
											}}
											title={t("research.applicants")}
										>
											<Link
												to={{
													pathname: "/aplicanti-cercetare",
													state: {
														researchProposalId:
															researchProposal?.researchProposalId,
														researchProposalName:
															researchProposal?.researchProposalName,
													},
												}}
											>
												<FontAwesomeIcon icon={solid("users")} /> &nbsp;
												{t("research.applicants")}
											</Link>
										</CustomButton>
									</div>
								) : null}
							</Col>
						</Row>
					)}
				</div>
				{researchProposal?.keywords && researchProposal?.keywords.length > 0 ? (
					<>
						<div className={styles.subtitle}>{t("research.keywords")}</div>
						<List
							grid={{
								gutter: 30,
							}}
							dataSource={researchProposal?.keywords}
							renderItem={(item: string) => (
								<List.Item>
									<div className={styles.skill}>{item}</div>
								</List.Item>
							)}
						/>
					</>
				) : null}
				<div className={styles.subtitle}>{t("research.description")}</div>
				<div
					className={styles.description}
					dangerouslySetInnerHTML={{
						__html: researchProposal?.description || "",
					}}
				/>
				{!isUserLogged() ? (
					<div className={styles.center}>
						<Link to="/autentificare">
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								fontSize={"1rem"}
								width={"10rem"}
								paddingvertical={"1.2rem"}
								paddinghorizontal={"1.4rem"}
								marginleft={"3rem"}
							>
								{t("research.loginToApply")}
							</CustomButton>
						</Link>
					</div>
				) : (isProfessor ||
						(isCompany &&
							researchProposal?.type === ResearchProposalType.ByProfessor)) &&
				  !applicable ? (
					<div className={styles.message}>{applicationMessage}</div>
				) : (
					(isProfessor ||
						(isCompany &&
							researchProposal?.type === ResearchProposalType.ByProfessor)) &&
					applicable && (
						<div className={styles.center}>
							<div>
								<Row
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "baseline",
									}}
								>
									<CustomButton
										fontSize={"0.9rem"}
										style={{
											background: theme.secondColor,
											color: theme.white,
											marginRight: "2em",
											marginTop: "5%",
											boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
										}}
										onClick={() => setModalVisibility(true)}
									>
										{t("research.apply")}
									</CustomButton>
									{researchProposal?.url && (
										<a
											href={researchProposal.url}
											target="_blank"
											rel="noopener noreferrer"
										>
											<CustomButton
												fontSize={"0.9rem"}
												style={{
													background: theme.secondColor,
													color: theme.white,
													marginRight: "2em",
													marginTop: "5%",
													boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
												}}
											>
												{t("research.researchProposalLink")}
											</CustomButton>
										</a>
									)}
								</Row>
								<ConfirmationModal
									modalText={t("research.applyResearchProposalMessage")}
									handleFunction={applyToResearchProposal}
									modalVisibility={modalVisibility}
									title=""
									changeModalVisibility={() => setModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						</div>
					)
				)}
			</Spin>
		</Layout>
	);
};

export default ResearchProposal;
