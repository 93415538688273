import { useTranslation } from "react-i18next";
import Layout from "../../Containers/Layout";
import styles from "./Questions.module.scss";
import { Col, Row, Tabs } from "antd";
import { Collapse } from "antd";
import { theme } from "../../theme";
import { DownloadOutlined } from "@ant-design/icons";
import CustomButton from "../../CustomComponents/CustomButton";
import { useState } from "react";

const { Panel } = Collapse;
const { TabPane } = Tabs;

const Questions = () => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState("1"); // default to first tab
	const downloadLink =
		activeTab === "1"
			? "https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdENvbXBhbmlpLnBkZg==&version_id=null" //ghid companii
			: activeTab === "2"
			? "https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdFByb2Zlc29yaS5wZGY=&version_id=null" //ghid profesori
			: "https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdFN0dWRlbnRpLnBkZg==&version_id=null"; //ghid studenti

	const handleTabChange = (key: string) => {
		setActiveTab(key);
	};
	return (
		<Layout>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<Row>
						<Col span={6}>
							<h2>{t("questions.frequentQuestions")}</h2>
						</Col>
						<Col span={6}></Col>
						<Col span={6}></Col>
						<Col span={6}>
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								marginleft={"auto"}
								marginright={"auto"}
								marginbottom={"5%"}
								title={t("questions.userManual")}
							>
								<a href={downloadLink} target="_blank">
									<DownloadOutlined /> &nbsp;
									{t("questions.userManual")}{" "}
								</a>
							</CustomButton>
						</Col>
					</Row>

					<Tabs
						centered
						animated={{ inkBar: false }}
						tabBarStyle={{
							display: "flex",
							justifyContent: "space-evenly",
							alignItems: "center",
						}}
						className={styles.displayFlex}
						activeKey={activeTab}
						onChange={handleTabChange}
					>
						<TabPane tab={t("admin.companies")} key="1">
							<Collapse ghost>
								<Panel
									header={t("questions.newAccountQuestionForCompany")}
									key="1"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.newAccountAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.editProfileQuestionForCompany")}
									key="2"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.editProfileAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.addNewMemberQuestionForCompany")}
									key="3"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.addNewMemberAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.addInternshipQuestionForCompany")}
									key="4"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.addInternshipAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.offersStatusQuestionForCompany")}
									key="5"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.offersStatusAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.studentsTabQuestionForCompany")}
									key="6"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.studentsTabAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewApplicantsQuestionForCompany")}
									key="7"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewApplicantsAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.acceptApplicantQuestionForCompany")}
									key="8"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.acceptApplicantAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewApplicantProfileQuestionForCompany")}
									key="9"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewApplicantProfileAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.myStudentsTabQuestionForCompany")}
									key="10"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.myStudentsTabAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t(
										"questions.validatePracticeNotebookQuestionForCompany"
									)}
									key="11"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.validatePracticeNotebookAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t(
										"questions.editPracticeCertificateQuestionForCompany"
									)}
									key="12"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.editPracticeCertificateAnswerForCompany")}
									</p>
								</Panel>
							</Collapse>
						</TabPane>
						<TabPane tab={t("admin.professors")} key="2">
							<Collapse ghost>
								<Panel
									header={t("questions.loginAcademicUserQuestion")}
									key="1"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.loginAcademicUserAnswer")}
									</p>
								</Panel>
								<Panel
									header={t("questions.editProfileQuestionForProfessor")}
									key="2"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.editProfileAnswerForProfessor")}
									</p>
								</Panel>
								<Panel
									header={t("questions.addInternshipQuestionForProfessor")}
									key="3"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.addInternshipAnswerForProfessor")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewOfferTypesQuestionForProfessor")}
									key="4"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewOfferTypesAnswerForProfessor")}{" "}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewApplicantsQuestionForCompany")}
									key="5"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewApplicantsAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewApplicantsQuestionForProfessor")}
									key="6"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewApplicantsAnswerForProfessor")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewApplicantProfileQuestionForCompany")}
									key="7"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewApplicantProfileAnswerForCompany")}
									</p>
								</Panel>
								<Panel
									header={t("questions.studentsTabQuestionForCompany")}
									key="8"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.studentsTabAnswerForProfessor")}
									</p>
								</Panel>
								<Panel
									header={t("questions.invalidateCompanyQuestionForAdmin")}
									key="9"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.invalidateCompanyAnswerForAdmin")}
									</p>
								</Panel>
								<Panel
									header={t("questions.uploadDocumentsQuestionForAdmin")}
									key="10"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.uploadDocumentsAnswerForAdmin")}
									</p>
								</Panel>
								<Panel
									header={t(
										"questions.validatePracticeDocumentsQuestionForAdmin"
									)}
									key="11"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.validatePracticeDocumentsAnswerForAdmin")}
									</p>
								</Panel>
							</Collapse>
						</TabPane>
						<TabPane tab={t("admin.students")} key="3">
							<Collapse ghost>
								<Panel
									header={t("questions.loginAcademicUserQuestion")}
									key="1"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.loginAcademicUserAnswer")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewOfferQuestionForStudent")}
									key="2"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewOfferAnswerForStudent")}
									</p>
								</Panel>
								<Panel
									header={t("questions.editProfileQuestionForStudent")}
									key="3"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.editProfileAnswerForStudent")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewOfferTypesQuestionForStudent")}
									key="4"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewOfferTypesAnswerForStudent")}
									</p>
								</Panel>
								<Panel
									header={t("questions.applyInternshipQuestion")}
									key="5"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.applyInternshipAnswer")}
									</p>
								</Panel>
								<Panel
									header={t("questions.cantApplyToOfferQuestionForStudent")}
									key="6"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.cantApplyToOfferAnswerForStudent")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewMyApplicationsQuestionForStudent")}
									key="7"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewMyApplicationsAnswerForStudent")}
									</p>
								</Panel>
								<Panel
									header={t("questions.viewAcceptedOffersQuestionForStudent")}
									key="8"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.viewAcceptedOffersAnswerForStudent")}
									</p>
								</Panel>
								<Panel
									header={t("questions.editPracticeNotebookQuestionForStudent")}
									key="9"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.editPracticeNotebookAnswerForStudent")}
									</p>
								</Panel>
								<Panel
									header={t(
										"questions.editPracticeCertificateQuestionForStudent"
									)}
									key="10"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.editPracticeCertificateAnswerForStudent")}
									</p>
								</Panel>
								<Panel
									header={t(
										"questions.downloadPracticeNotebookQuestionForStudent"
									)}
									key="11"
									style={{ textAlign: "left" }}
								>
									<p style={{ textAlign: "left" }}>
										{t("questions.downloadPracticeNotebookAnswerForStudent")}
									</p>
								</Panel>
							</Collapse>
						</TabPane>
					</Tabs>
				</div>
			</div>
		</Layout>
	);
};
export default Questions;
