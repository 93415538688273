/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InternshipDocumentState {
    NotApplicable = 'NotApplicable',
    NotGenerated = 'NotGenerated',
    InProgress = 'InProgress',
    PendingCoordinatorSignature = 'PendingCoordinatorSignature',
    PendingDeanSignature = 'PendingDeanSignature',
    PendingDepartmentDirectorSignature = 'PendingDepartmentDirectorSignature',
    PendingStudyProgramCoordinatorSignature = 'PendingStudyProgramCoordinatorSignature',
    Finalized = 'Finalized'
}

export function InternshipDocumentStateFromJSON(json: any): InternshipDocumentState {
    return InternshipDocumentStateFromJSONTyped(json, false);
}

export function InternshipDocumentStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipDocumentState {
    return json as InternshipDocumentState;
}

export function InternshipDocumentStateToJSON(value?: InternshipDocumentState | null): any {
    return value as any;
}

