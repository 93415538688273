/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternshipDocumentState,
    InternshipDocumentStateFromJSON,
    InternshipDocumentStateFromJSONTyped,
    InternshipDocumentStateToJSON,
} from './InternshipDocumentState';

/**
 * 
 * @export
 * @interface InternshipDocumentUpdateDTO
 */
export interface InternshipDocumentUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentUpdateDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentUpdateDTO
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentUpdateDTO
     */
    bibliography?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentUpdateDTO
     */
    studentGroup?: string | null;
    /**
     * 
     * @type {InternshipDocumentState}
     * @memberof InternshipDocumentUpdateDTO
     */
    state?: InternshipDocumentState;
}

export function InternshipDocumentUpdateDTOFromJSON(json: any): InternshipDocumentUpdateDTO {
    return InternshipDocumentUpdateDTOFromJSONTyped(json, false);
}

export function InternshipDocumentUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipDocumentUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'bibliography': !exists(json, 'bibliography') ? undefined : json['bibliography'],
        'studentGroup': !exists(json, 'studentGroup') ? undefined : json['studentGroup'],
        'state': !exists(json, 'state') ? undefined : InternshipDocumentStateFromJSON(json['state']),
    };
}

export function InternshipDocumentUpdateDTOToJSON(value?: InternshipDocumentUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'bibliography': value.bibliography,
        'studentGroup': value.studentGroup,
        'state': InternshipDocumentStateToJSON(value.state),
    };
}

