/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PracticeConventionSignatureDTO,
    PracticeConventionSignatureDTOFromJSON,
    PracticeConventionSignatureDTOFromJSONTyped,
    PracticeConventionSignatureDTOToJSON,
} from './PracticeConventionSignatureDTO';
import {
    PracticeConventionState,
    PracticeConventionStateFromJSON,
    PracticeConventionStateFromJSONTyped,
    PracticeConventionStateToJSON,
} from './PracticeConventionState';

/**
 * 
 * @export
 * @interface PracticeConventionDTO
 */
export interface PracticeConventionDTO {
    /**
     * 
     * @type {string}
     * @memberof PracticeConventionDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeConventionDTO
     */
    internshipEnrollmentId?: string;
    /**
     * 
     * @type {PracticeConventionState}
     * @memberof PracticeConventionDTO
     */
    state?: PracticeConventionState;
    /**
     * 
     * @type {Date}
     * @memberof PracticeConventionDTO
     */
    createdAt?: Date;
    /**
     * 
     * @type {Array<PracticeConventionSignatureDTO>}
     * @memberof PracticeConventionDTO
     */
    signatures?: Array<PracticeConventionSignatureDTO> | null;
}

export function PracticeConventionDTOFromJSON(json: any): PracticeConventionDTO {
    return PracticeConventionDTOFromJSONTyped(json, false);
}

export function PracticeConventionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeConventionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'internshipEnrollmentId': !exists(json, 'internshipEnrollmentId') ? undefined : json['internshipEnrollmentId'],
        'state': !exists(json, 'state') ? undefined : PracticeConventionStateFromJSON(json['state']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'signatures': !exists(json, 'signatures') ? undefined : (json['signatures'] === null ? null : (json['signatures'] as Array<any>).map(PracticeConventionSignatureDTOFromJSON)),
    };
}

export function PracticeConventionDTOToJSON(value?: PracticeConventionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'internshipEnrollmentId': value.internshipEnrollmentId,
        'state': PracticeConventionStateToJSON(value.state),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'signatures': value.signatures === undefined ? undefined : (value.signatures === null ? null : (value.signatures as Array<any>).map(PracticeConventionSignatureDTOToJSON)),
    };
}

