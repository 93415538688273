/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyUserReportDTO,
    CompanyUserReportDTOFromJSON,
    CompanyUserReportDTOFromJSONTyped,
    CompanyUserReportDTOToJSON,
} from './CompanyUserReportDTO';

/**
 * 
 * @export
 * @interface CompanyUserReportDTOPagedResponse
 */
export interface CompanyUserReportDTOPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof CompanyUserReportDTOPagedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyUserReportDTOPagedResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyUserReportDTOPagedResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<CompanyUserReportDTO>}
     * @memberof CompanyUserReportDTOPagedResponse
     */
    data?: Array<CompanyUserReportDTO> | null;
}

export function CompanyUserReportDTOPagedResponseFromJSON(json: any): CompanyUserReportDTOPagedResponse {
    return CompanyUserReportDTOPagedResponseFromJSONTyped(json, false);
}

export function CompanyUserReportDTOPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUserReportDTOPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(CompanyUserReportDTOFromJSON)),
    };
}

export function CompanyUserReportDTOPagedResponseToJSON(value?: CompanyUserReportDTOPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'totalCount': value.totalCount,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(CompanyUserReportDTOToJSON)),
    };
}

