import { Col, Form, Radio, Row, Select, Spin } from "antd";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { EmailPriority, EmailSendStrategy, Role } from "../../../../../../Api";
import styles from "../../../AddEmailForm.module.scss";
import CustomForm from "../../../../../../CustomComponents/CustomForm";
import { theme } from "../../../../../../theme";
import CustomButton from "../../../../../../CustomComponents/CustomButton";
import CustomEditor from "../../../../../../CustomComponents/CustomEditor";
import { userYears } from "../../../../../../utils/constants";
import { useAddEmailFormController } from "../../../AddEmailForm.controller";
import { PublishEmailFormProps } from "./PublishEmailForm.types";

const { Group } = Radio;
const { Option } = Select;

export const PublishEmailForm = (props: PublishEmailFormProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<CustomForm
				form={props.form}
				layout={theme.layout}
				action="/newsletter"
				boxshadow={"none"}
			>
				<div className={styles.firstLine}>
					<Form.Item
						required
						name="emailSubject"
						label={t("emails.publishEmailForm.emailSubject") + ":"}
						rules={[props.yupSync]}
						initialValue={props.defaultValues.emailSubject}
					>
						<textarea
							rows={1}
							cols={100}
							placeholder={t("emails.publishEmailForm.emailSubject")}
						/>
					</Form.Item>

					<Row gutter={8}>
						<Col span={window.innerWidth > 1100 ? 12 : 16}>
							<Form.Item
								required
								name="priority"
								initialValue={props.defaultValues.priority}
								label={t("emails.publishEmailForm.priority") + ":"}
								rules={[props.yupSync]}
							>
								<Select
									className={styles.antSelect}
									allowClear
									style={{ width: "100%", textAlign: "left" }}
									placeholder={t("emails.publishEmailForm.priority")}
								>
									<Option key={EmailPriority.Low} value={EmailPriority.Low}>
										{t("emails.priorities." + EmailPriority.Low)}
									</Option>
									<Option
										key={EmailPriority.Normal}
										value={EmailPriority.Normal}
									>
										{t("emails.priorities." + EmailPriority.Normal)}
									</Option>
									<Option key={EmailPriority.High} value={EmailPriority.High}>
										{t("emails.priorities." + EmailPriority.High)}
									</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={window.innerWidth > 1100 ? 12 : 16}>
							<Form.Item
								required
								name="newsletterChannel"
								label={t("emails.publishEmailForm.newsletterChannel") + ":"}
								rules={[props.yupSync]}
							>
								<Select
									className={styles.antSelect}
									allowClear
									style={{ width: "100%", textAlign: "left" }}
									placeholder={t("emails.publishEmailForm.newsletterChannel")}
								>
									<Option key="Newsletter CRESCDI" value="Newsletter CRESCDI">
										Newsletter CRESCDI
									</Option>
									<Option
										key="Newsletter UNSTPB Connect"
										value="Newsletter UNSTPB Connect"
									>
										Newsletter UNSTPB Connect
									</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Form.Item
						required
						name="body"
						label={t("emails.publishEmailForm.body") + ":"}
						rules={[props.yupSync]}
						initialValue={props.defaultValues.body}
					>
						<CustomEditor
							onEditorChange={(body: string) => {
								props.form.setFieldsValue({
									...props.form.getFieldsValue(),
									body,
								});
							}}
						/>
					</Form.Item>

					<Form.Item
						name="sendStrategy"
						initialValue={
							props.isEditing
								? EmailSendStrategy.MultiUser
								: props.defaultValues.sendStrategy
						}
						label={t("emails.publishEmailForm.sendStrategy") + ":"}
					>
						<Group className={styles.checkbox}>
							<Radio
								value={EmailSendStrategy.RoleBased}
								className={styles.input_container}
							>
								{t("emails.publishEmailForm.roleBasedSelection")}
							</Radio>
							<Radio
								value={EmailSendStrategy.MultiUser}
								className={styles.input_container}
							>
								{t("emails.publishEmailForm.multiUserSelection")}
							</Radio>
						</Group>
					</Form.Item>

					{props.sendStrategyValue === EmailSendStrategy.RoleBased ? (
						<>
							<Form.Item
								required={
									props.sendStrategyValue === EmailSendStrategy.RoleBased
								}
								name="recipientRole"
								label={t("emails.publishEmailForm.recipientRole") + ":"}
								rules={[props.yupSync]}
							>
								<Select
									className={styles.antSelect}
									allowClear
									style={{ width: "100%", textAlign: "left" }}
									placeholder={t("emails.publishEmailForm.recipientRole")}
								>
									<Option key={Role.Professor} value={Role.Professor}>
										{t("emails.roles." + Role.Professor)}
									</Option>
									<Option key={Role.Student} value={Role.Student}>
										{t("emails.roles." + Role.Student)}
									</Option>
									<Option key={Role.Company} value={Role.Company}>
										{t("emails.roles." + Role.Company)}
									</Option>
								</Select>
							</Form.Item>
							{(props.recipientRoleValue === Role.Professor ||
								props.recipientRoleValue === Role.Student) && (
								<Form.Item
									name="faculties"
									label={t("emails.publishEmailForm.faculties") + ":"}
									tooltip={t("emails.publishEmailForm.facultyHelper")}
								>
									<Select
										className={styles.antSelect}
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										filterOption={false}
										onSearch={props.setFacultySearch}
										style={{ width: "100%", textAlign: "left" }}
										placeholder={t("emails.publishEmailForm.faculties")}
										notFoundContent={t(
											"emails.publishEmailForm.notFoundContent"
										)}
									>
										{props.facultyOptions}
									</Select>
								</Form.Item>
							)}
							{props.recipientRoleValue === Role.Professor && (
								<Form.Item
									name="departments"
									label={t("emails.publishEmailForm.departments") + ":"}
									tooltip={t("emails.publishEmailForm.departmentsHelper")}
								>
									<Select
										className={styles.antSelect}
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										filterOption={false}
										onSearch={props.setDepartmentSearch}
										style={{ width: "100%", textAlign: "left" }}
										placeholder={t("emails.publishEmailForm.departments")}
										notFoundContent={t(
											"emails.publishEmailForm.notFoundContent"
										)}
									>
										{props.departmentOptions}
									</Select>
								</Form.Item>
							)}
							{props.recipientRoleValue === Role.Student && (
								<Form.Item
									name="studyPrograms"
									label={t("emails.publishEmailForm.studyPrograms") + ":"}
									tooltip={t("emails.publishEmailForm.studyProgramsHelper")}
								>
									<Select
										className={styles.antSelect}
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										filterOption={false}
										onSearch={props.setStudyProgramSearch}
										style={{ width: "100%", textAlign: "left" }}
										placeholder={t("emails.publishEmailForm.studyPrograms")}
										notFoundContent={t(
											"emails.publishEmailForm.notFoundContent"
										)}
									>
										{props.studyProgramOptions}
									</Select>
								</Form.Item>
							)}
							{props.recipientRoleValue === Role.Student && (
								<Form.Item
									name="years"
									label={t("emails.publishEmailForm.years") + ":"}
									tooltip={t("emails.publishEmailForm.yearHelper")}
								>
									<Select
										className={styles.antSelect}
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										style={{ width: "100%", textAlign: "left" }}
										placeholder={t("emails.publishEmailForm.years")}
										notFoundContent={t(
											"emails.publishEmailForm.notFoundContent"
										)}
									>
										{userYears.map((year) => (
											<Option key={year} value={year}>
												{t("emails.years." + year)}
											</Option>
										))}
									</Select>
								</Form.Item>
							)}
						</>
					) : (
						<Form.Item
							required={props.sendStrategyValue === EmailSendStrategy.MultiUser}
							name="recipients"
							label={t("emails.publishEmailForm.recipients") + ":"}
							style={{ width: "100%", textAlign: "left" }}
							rules={[props.yupSync]}
						>
							<Select
								className={styles.antSelect}
								mode="multiple"
								maxTagCount="responsive"
								allowClear
								showSearch
								style={{ width: "100%", textAlign: "left" }}
								placeholder={t("emails.publishEmailForm.recipients")}
								filterOption={false}
								onSearch={props.setUserSearch}
								notFoundContent={
									props.isLoadingAcademicUsers ? <Spin size="small" /> : null
								}
							>
								{props.recipientsOptions}
							</Select>
						</Form.Item>
					)}
				</div>

				<div className={styles.secondLine}>
					<div className={styles.firstColumn}>
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: theme.green,
								color: theme.white,
								marginRight: "2em",
								marginTop: "0%",
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
							onClick={() => props.setCancelModalVisibility(true)}
						>
							{t("emails.publishEmailForm.cancel")}
						</CustomButton>
						<ConfirmationModal
							modalText={t("emails.cancelMessage")}
							handleFunction={props.handleCancel}
							modalVisibility={props.cancelModalVisibility}
							changeModalVisibility={() =>
								props.setCancelModalVisibility(false)
							}
							title=""
							spinning={props.spinning}
						/>
						<CustomButton
							htmlType="submit"
							fontSize={"0.9rem"}
							type={"primary"}
							style={{
								background: theme.secondColor,
								color: theme.white,
								marginRight: "10%",
								marginTop: "0%",
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
							onClick={() => props.setSaveModalVisibility(true)}
						>
							{t("emails.publishEmailForm.save")}
						</CustomButton>
						<ConfirmationModal
							modalText={t("emails.saveMessage")}
							handleFunction={props.handleSave}
							modalVisibility={props.saveModalVisibility}
							title=""
							changeModalVisibility={() => props.setSaveModalVisibility(false)}
							spinning={props.spinning}
						/>
					</div>
				</div>
			</CustomForm>
		</div>
	);
};
