import { InternshipApplicantsTableProps } from "./InternshipApplicantsTable.types";
import styles from "../../../../Views/ApplicantsView/ApplicantsView.module.scss";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import {
	getInterns,
	getTraineesFilters,
} from "../../../../../utils/reactQueriesConstants";
import {
	getInternsForEmployer,
	updateIsEnrollmentViewed,
} from "../../../../../Requests/internship-requests";
import { InternsForEmployerDTO, InternType } from "../../../../../Api";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../Notifications/NotificationsUtils";
import { usePagination } from "../../../../../Hooks";
import { useDispatch } from "react-redux";
import { studentIdSet } from "../../../../Student/StudentSlice";
import { useHistory } from "react-router-dom";
import { InternshipApplicantActions } from "./components/InternshipApplicantActions/InternshipApplicantActions";
import { useState } from "react";
import { AcceptApplicantModal } from "./components/modals/AcceptApplicantModal/AcceptApplicantModal";
import { FinalAcceptApplicantModal } from "./components/modals/FinalAcceptApplicantModal/FinalAcceptApplicantModal";
import { RejectApplicantModal } from "./components/modals/RejectApplicantModal/RejectApplicantModal";

export const InternshipApplicantsTable = (
	props: InternshipApplicantsTableProps
) => {
	const { t, i18n } = useTranslation();
	const { currentPage, pageSize, handleTablePaginationChange } = usePagination(
		1,
		10
	);
	const [selectedApplicant, setSelectedApplicant] =
		useState<InternsForEmployerDTO>({});
	const [selectedTutor, setSelectedTutor] = useState("");
	const [acceptModalVisibility, setAcceptModalVisibility] = useState(false);
	const [finalAcceptModalVisibility, setFinalAcceptModalVisibility] =
		useState(false);
	const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	let locale = {
		emptyText: t("tableText.noApplicants"),
	};

	const goToStudentProfile = (id: any, enrollmentId: any) => {
		updateViewApplication(enrollmentId, true);
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const updateViewApplication = (id: any, viewed: boolean) => {
		updateIsEnrollmentViewed(id, viewed).then(async () => {
			await queryClient.invalidateQueries(getInterns);
			await queryClient.invalidateQueries(getTraineesFilters);
		});
	};

	const columns = [
		{
			title: t("students.name"),
			key: "name",
			dataIndex: "name",
			render: (text: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<a
						onClick={() =>
							goToStudentProfile(record.id, record.internshipEnrollmentId)
						}
					>
						<b>{text}</b>
					</a>
				);
			},
		},
		{
			title: t("students.group"),
			key: "group",
			dataIndex: "group",
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			render: (_: string | undefined, record: InternsForEmployerDTO) => (
				<span>
					{record.personalEmail !== "" && record.personalEmail !== undefined
						? record.personalEmail
						: record.email}
				</span>
			),
		},
		{
			title: t("students.actions"),
			key: "actions",
			dataIndex: "actions",
			render: (_: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<InternshipApplicantActions
						record={record}
						setSelectedApplicant={setSelectedApplicant}
						setSelectedTutor={setSelectedTutor}
						setAcceptModalVisibility={setAcceptModalVisibility}
						setFinalAcceptModalVisibility={setFinalAcceptModalVisibility}
						setRejectModalVisibility={setRejectModalVisibility}
					/>
				);
			},
		},
	];

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		[
			getInterns,
			InternType.Applicant,
			currentPage,
			pageSize,
			i18n.language,
			props.internship.internshipName,
		],
		() => {
			return getInternsForEmployer(
				InternType.Applicant,
				undefined,
				currentPage,
				pageSize,
				[props.internship.internshipName]
			);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const invalidateQuery = async () => {
		await queryClient.invalidateQueries(getInterns);
	};

	const getKey = (record: any): string => {
		return record.id + record.internshipId;
	};

	return (
		<>
			<Table
				style={{ marginTop: 16 }}
				locale={locale}
				dataSource={data?.data ?? []}
				columns={columns}
				pagination={{
					total: data?.totalCount,
					current: data?.page,
					pageSize: data?.pageSize,
					pageSizeOptions: ["10", "20", "50"],
					defaultPageSize: 10,
					hideOnSinglePage: data?.totalCount ? data?.totalCount <= 10 : true,
					showSizeChanger: true,
					locale: { items_per_page: t("configuration.pagination") },
					position: ["topRight", "bottomRight"],
				}}
				rowKey={getKey}
				rowClassName={(record) =>
					!record.isEnrollmentViewed ? styles.customTableRow : ""
				}
				loading={loading && !data}
				onChange={handleTablePaginationChange}
			/>
			<AcceptApplicantModal
				selectedApplicant={selectedApplicant}
				selectedTutor={selectedTutor}
				modalVisibility={acceptModalVisibility}
				invalidateApplicantsQueries={invalidateQuery}
				setModalVisibility={setAcceptModalVisibility}
				setSelectedTutor={setSelectedTutor}
			/>
			<FinalAcceptApplicantModal
				selectedApplicant={selectedApplicant}
				modalVisibility={finalAcceptModalVisibility}
				invalidateApplicantsQueries={invalidateQuery}
				setModalVisibility={setFinalAcceptModalVisibility}
			/>
			<RejectApplicantModal
				selectedApplicant={selectedApplicant}
				modalVisibility={rejectModalVisibility}
				invalidateApplicantsQueries={invalidateQuery}
				setModalVisibility={setRejectModalVisibility}
			/>
		</>
	);
};
