/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyUniversityAccord,
    CompanyUniversityAccordFromJSON,
    CompanyUniversityAccordFromJSONTyped,
    CompanyUniversityAccordToJSON,
} from './CompanyUniversityAccord';
import {
    UniversityAccordSigningEnum,
    UniversityAccordSigningEnumFromJSON,
    UniversityAccordSigningEnumFromJSONTyped,
    UniversityAccordSigningEnumToJSON,
} from './UniversityAccordSigningEnum';

/**
 * 
 * @export
 * @interface UniversityAccordSignature
 */
export interface UniversityAccordSignature {
    /**
     * 
     * @type {string}
     * @memberof UniversityAccordSignature
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof UniversityAccordSignature
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UniversityAccordSignature
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UniversityAccordSignature
     */
    accordId?: string;
    /**
     * 
     * @type {UniversityAccordSigningEnum}
     * @memberof UniversityAccordSignature
     */
    signer?: UniversityAccordSigningEnum;
    /**
     * 
     * @type {string}
     * @memberof UniversityAccordSignature
     */
    fileName?: string | null;
    /**
     * 
     * @type {CompanyUniversityAccord}
     * @memberof UniversityAccordSignature
     */
    companyUniversityAccord?: CompanyUniversityAccord;
}

export function UniversityAccordSignatureFromJSON(json: any): UniversityAccordSignature {
    return UniversityAccordSignatureFromJSONTyped(json, false);
}

export function UniversityAccordSignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): UniversityAccordSignature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'accordId': !exists(json, 'accordId') ? undefined : json['accordId'],
        'signer': !exists(json, 'signer') ? undefined : UniversityAccordSigningEnumFromJSON(json['signer']),
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'companyUniversityAccord': !exists(json, 'companyUniversityAccord') ? undefined : CompanyUniversityAccordFromJSON(json['companyUniversityAccord']),
    };
}

export function UniversityAccordSignatureToJSON(value?: UniversityAccordSignature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'accordId': value.accordId,
        'signer': UniversityAccordSigningEnumToJSON(value.signer),
        'fileName': value.fileName,
        'companyUniversityAccord': CompanyUniversityAccordToJSON(value.companyUniversityAccord),
    };
}

