/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';
import {
    InternshipEngagementType,
    InternshipEngagementTypeFromJSON,
    InternshipEngagementTypeFromJSONTyped,
    InternshipEngagementTypeToJSON,
} from './InternshipEngagementType';

/**
 * 
 * @export
 * @interface InternshipLimits
 */
export interface InternshipLimits {
    /**
     * 
     * @type {string}
     * @memberof InternshipLimits
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InternshipLimits
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InternshipLimits
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InternshipLimits
     */
    facultyId?: string;
    /**
     * 
     * @type {InternshipEngagementType}
     * @memberof InternshipLimits
     */
    internshipType?: InternshipEngagementType;
    /**
     * 
     * @type {number}
     * @memberof InternshipLimits
     */
    maximumApplicants?: number;
    /**
     * 
     * @type {Faculty}
     * @memberof InternshipLimits
     */
    faculty?: Faculty;
}

export function InternshipLimitsFromJSON(json: any): InternshipLimits {
    return InternshipLimitsFromJSONTyped(json, false);
}

export function InternshipLimitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipLimits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'facultyId': !exists(json, 'facultyId') ? undefined : json['facultyId'],
        'internshipType': !exists(json, 'internshipType') ? undefined : InternshipEngagementTypeFromJSON(json['internshipType']),
        'maximumApplicants': !exists(json, 'maximumApplicants') ? undefined : json['maximumApplicants'],
        'faculty': !exists(json, 'faculty') ? undefined : FacultyFromJSON(json['faculty']),
    };
}

export function InternshipLimitsToJSON(value?: InternshipLimits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'facultyId': value.facultyId,
        'internshipType': InternshipEngagementTypeToJSON(value.internshipType),
        'maximumApplicants': value.maximumApplicants,
        'faculty': FacultyToJSON(value.faculty),
    };
}

