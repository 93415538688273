/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RequestResponse,
    RequestResponseFromJSON,
    RequestResponseToJSON,
    ResearchPartnersForProfessorOrCompanyDTOPagedResponse,
    ResearchPartnersForProfessorOrCompanyDTOPagedResponseFromJSON,
    ResearchPartnersForProfessorOrCompanyDTOPagedResponseToJSON,
    ResearchProposalAcceptDTO,
    ResearchProposalAcceptDTOFromJSON,
    ResearchProposalAcceptDTOToJSON,
    ResearchProposalEnrollmentStatus,
    ResearchProposalEnrollmentStatusFromJSON,
    ResearchProposalEnrollmentStatusToJSON,
} from '../models';

export interface ApiResearchProposalAcceptApplicationPostRequest {
    researchProposalAcceptDTO?: ResearchProposalAcceptDTO;
}

export interface ApiResearchProposalApplyResearchProposalIdPostRequest {
    researchProposalId: string;
}

export interface ApiResearchProposalCanApplyCompanyResearchProposalIdGetRequest {
    researchProposalId: string;
}

export interface ApiResearchProposalCanApplyProfessorResearchProposalIdGetRequest {
    researchProposalId: string;
}

export interface ApiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGetRequest {
    namePattern?: string;
    researchProposals?: Array<string>;
    enrollmentStatus?: Array<ResearchProposalEnrollmentStatus>;
    viewStatuses?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiResearchProposalGetPartnersForCompanyOrProfessorGetRequest {
    namePattern?: string;
    researchProposals?: Array<string>;
    enrollmentStatus?: Array<ResearchProposalEnrollmentStatus>;
    viewStatuses?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiResearchProposalRejectApplicationPostRequest {
    researchProposalAcceptDTO?: ResearchProposalAcceptDTO;
}

export interface ApiResearchProposalUpdateIsEnrollmentViewedIdPutRequest {
    id: string;
    isEnrollmentView?: boolean;
}

/**
 * 
 */
export class ResearchProposalApi extends runtime.BaseAPI {

    /**
     */
    async apiResearchProposalAcceptApplicationPostRaw(requestParameters: ApiResearchProposalAcceptApplicationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ResearchProposal/AcceptApplication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResearchProposalAcceptDTOToJSON(requestParameters.researchProposalAcceptDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResearchProposalAcceptApplicationPost(requestParameters: ApiResearchProposalAcceptApplicationPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiResearchProposalAcceptApplicationPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResearchProposalApplyResearchProposalIdPostRaw(requestParameters: ApiResearchProposalApplyResearchProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.researchProposalId === null || requestParameters.researchProposalId === undefined) {
            throw new runtime.RequiredError('researchProposalId','Required parameter requestParameters.researchProposalId was null or undefined when calling apiResearchProposalApplyResearchProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ResearchProposal/Apply/{researchProposalId}`.replace(`{${"researchProposalId"}}`, encodeURIComponent(String(requestParameters.researchProposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiResearchProposalApplyResearchProposalIdPost(requestParameters: ApiResearchProposalApplyResearchProposalIdPostRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiResearchProposalApplyResearchProposalIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResearchProposalCanApplyCompanyResearchProposalIdGetRaw(requestParameters: ApiResearchProposalCanApplyCompanyResearchProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.researchProposalId === null || requestParameters.researchProposalId === undefined) {
            throw new runtime.RequiredError('researchProposalId','Required parameter requestParameters.researchProposalId was null or undefined when calling apiResearchProposalCanApplyCompanyResearchProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ResearchProposal/CanApplyCompany/{researchProposalId}`.replace(`{${"researchProposalId"}}`, encodeURIComponent(String(requestParameters.researchProposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiResearchProposalCanApplyCompanyResearchProposalIdGet(requestParameters: ApiResearchProposalCanApplyCompanyResearchProposalIdGetRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiResearchProposalCanApplyCompanyResearchProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResearchProposalCanApplyProfessorResearchProposalIdGetRaw(requestParameters: ApiResearchProposalCanApplyProfessorResearchProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.researchProposalId === null || requestParameters.researchProposalId === undefined) {
            throw new runtime.RequiredError('researchProposalId','Required parameter requestParameters.researchProposalId was null or undefined when calling apiResearchProposalCanApplyProfessorResearchProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ResearchProposal/CanApplyProfessor/{researchProposalId}`.replace(`{${"researchProposalId"}}`, encodeURIComponent(String(requestParameters.researchProposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiResearchProposalCanApplyProfessorResearchProposalIdGet(requestParameters: ApiResearchProposalCanApplyProfessorResearchProposalIdGetRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiResearchProposalCanApplyProfessorResearchProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGetRaw(requestParameters: ApiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResearchPartnersForProfessorOrCompanyDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.researchProposals) {
            queryParameters['ResearchProposals'] = requestParameters.researchProposals;
        }

        if (requestParameters.enrollmentStatus) {
            queryParameters['EnrollmentStatus'] = requestParameters.enrollmentStatus;
        }

        if (requestParameters.viewStatuses) {
            queryParameters['ViewStatuses'] = requestParameters.viewStatuses;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ResearchProposal/GetAcceptedApplicationsForCompanyOrProfessor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResearchPartnersForProfessorOrCompanyDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGet(requestParameters: ApiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGetRequest = {}, initOverrides?: RequestInit): Promise<ResearchPartnersForProfessorOrCompanyDTOPagedResponse> {
        const response = await this.apiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResearchProposalGetPartnersForCompanyOrProfessorGetRaw(requestParameters: ApiResearchProposalGetPartnersForCompanyOrProfessorGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResearchPartnersForProfessorOrCompanyDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.researchProposals) {
            queryParameters['ResearchProposals'] = requestParameters.researchProposals;
        }

        if (requestParameters.enrollmentStatus) {
            queryParameters['EnrollmentStatus'] = requestParameters.enrollmentStatus;
        }

        if (requestParameters.viewStatuses) {
            queryParameters['ViewStatuses'] = requestParameters.viewStatuses;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ResearchProposal/GetPartnersForCompanyOrProfessor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResearchPartnersForProfessorOrCompanyDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiResearchProposalGetPartnersForCompanyOrProfessorGet(requestParameters: ApiResearchProposalGetPartnersForCompanyOrProfessorGetRequest = {}, initOverrides?: RequestInit): Promise<ResearchPartnersForProfessorOrCompanyDTOPagedResponse> {
        const response = await this.apiResearchProposalGetPartnersForCompanyOrProfessorGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResearchProposalRejectApplicationPostRaw(requestParameters: ApiResearchProposalRejectApplicationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ResearchProposal/RejectApplication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResearchProposalAcceptDTOToJSON(requestParameters.researchProposalAcceptDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResearchProposalRejectApplicationPost(requestParameters: ApiResearchProposalRejectApplicationPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiResearchProposalRejectApplicationPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResearchProposalUpdateIsEnrollmentViewedIdPutRaw(requestParameters: ApiResearchProposalUpdateIsEnrollmentViewedIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResearchProposalUpdateIsEnrollmentViewedIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.isEnrollmentView !== undefined) {
            queryParameters['isEnrollmentView'] = requestParameters.isEnrollmentView;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ResearchProposal/UpdateIsEnrollmentViewed/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResearchProposalUpdateIsEnrollmentViewedIdPut(requestParameters: ApiResearchProposalUpdateIsEnrollmentViewedIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResearchProposalUpdateIsEnrollmentViewedIdPutRaw(requestParameters, initOverrides);
    }

}
