/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CompanyDocumentStatusEnum {
    NotGenerated = 'NotGenerated',
    InProgress = 'InProgress',
    Finalized = 'Finalized',
    Invalid = 'Invalid'
}

export function CompanyDocumentStatusEnumFromJSON(json: any): CompanyDocumentStatusEnum {
    return CompanyDocumentStatusEnumFromJSONTyped(json, false);
}

export function CompanyDocumentStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDocumentStatusEnum {
    return json as CompanyDocumentStatusEnum;
}

export function CompanyDocumentStatusEnumToJSON(value?: CompanyDocumentStatusEnum | null): any {
    return value as any;
}

