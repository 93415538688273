import {
	AddInternshipFormController,
	AddInternshipFormProps,
} from "./AddInternshipForm.types";
import { useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { InternshipEngagementType, StudentYearEnum } from "../../../Api";
import { Form } from "antd";
import { AddInternshipFormModel } from "./models/AddInternshipFormModel";
import {
	getCurrentUniversityYear,
	getUserId,
	useGetCompanyId,
	useIsCompany,
	useIsDepartmentSecretary,
	useIsProfessor,
} from "../../../utils/utilFunctions";
import useDebounce from "../../../Hooks/debounce";
import { AddInternshipFormInit } from "./services/AddInternshipFormInit";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { useQuery, useQueryClient } from "react-query";
import {
	getFaculties,
	getFacultiesWithCompanyAccordList,
	getSpecificInternship,
	getSpecificProfessor,
} from "../../../utils/reactQueriesConstants";
import {
	getAllFaculties,
	getAllFacultiesWithASpecificCompanyAccord,
} from "../../../Requests/faculty-requests";
import { getProfessorById } from "../../../Requests/academic-user-requests";
import { getInternship } from "./AddInternshipForm.utils";
import {
	toCompanyInternship,
	toFormModel,
	toProfessorInternshipAsDepartmentSecretary,
	toProfessorInternshipAsProfessor,
} from "./transformers/model.transformers";
import { StatusCodes } from "http-status-codes";
import {
	userYears,
	userYearsBachelor,
	userYearsInternship,
	userYearsMaster,
	userYearsSummerSchool,
} from "../../../utils/constants";
import {
	addProfessorInternship,
	updateProfessorInternshipInfo,
} from "../../../Requests/professor-internship-requests";
import {
	addCompanyInternship,
	updateCompanyInternshipInfo,
} from "../../../Requests/company-internship-requests";
import { useTranslation } from "react-i18next";

export const useAddInternshipFormController = (
	props: AddInternshipFormProps
): AddInternshipFormController => {
	const { t } = useTranslation();
	const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
	const [saveModalVisibility, setSaveModalVisibility] = useState(false);
	const [location, setLocation] = useState("Bucharest, România");
	const [placeId, setPlaceId] = useState("ChIJT608vzr5sUARKKacfOMyBqw");
	const [disabledDateStart, setDisabledDateStart] = useState(moment);
	const [disabledDateEnd, setDisabledDateEnd] = useState(moment().add(1, "y"));
	const [facultySingleSelect, setFacultySingleSelect] = useState(true);
	const [defaultFaculty, setDefaultFaculty] = useState("");
	const [defaultFacultyName, setDefaultFacultyName] = useState("");
	const [selectedFaculties, setSelectedFaculties] = useState(
		new Array<string>()
	);
	const [selectedYears, setSelectedYears] = useState(
		new Array<StudentYearEnum>()
	);
	const [form] = Form.useForm<AddInternshipFormModel>();
	const currentUserId = getUserId();
	const engagementTypeValue = Form.useWatch("engagementType", form);
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const companyId = useGetCompanyId();
	const isDepartmentSecretary = useIsDepartmentSecretary();
	const [userSearch, setUserSearch] = useState("");
	const [facultySearch, setFacultySearch] = useState("");
	const [facultyOptions, setFacultyOptions] = useState<any>([]);
	const [facultyCoordinatorId, setFacultyCoordinatorId] =
		useState<any>(undefined);
	const debouncedUserSearch = useDebounce(userSearch, 1000);
	const { defaultValues, yupSync } = AddInternshipFormInit();
	const [spinning, setSpinning] = useState(false);
	const queryClient = useQueryClient();

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: allFacultiesList } = useQuery(
		[getFaculties],
		() => getAllFaculties("", 1, 20),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: facultiesWithCompanyAccordList } = useQuery(
		[getFacultiesWithCompanyAccordList],
		() =>
			isCompany
				? getAllFacultiesWithASpecificCompanyAccord(companyId, "", 1, 20)
				: [],
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const currentAcademicYear = useMemo(
		() => (moment().month() < 7 ? moment().year() - 1 : moment().year()),
		[]
	);

	useEffect(() => {
		// only when adding the engagementType can change - prevent this from happening on updates
		if (!props.internshipId) {
			form.setFieldsValue({
				...form.getFieldsValue(),
				faculties: [],
				departments: [],
				studyPrograms: [],
				years: [],
				availabilityYear: currentAcademicYear,
			});
		}
	}, [props.internshipId, form, currentAcademicYear]);

	const { data: professorUser } = useQuery(
		[getSpecificProfessor, isProfessor],
		async () => (isProfessor ? await getProfessorById(getUserId()) : null),
		{
			refetchOnWindowFocus: false,
			onError: (err) => {
				openGetErrorNotification(err);
			},
			onSuccess: (data) => {
				if (data) {
					setDefaultFaculty(data.faculty?.id!);
					setDefaultFacultyName(data.faculty?.facultyNameRo!);
					setSelectedFaculties([data.faculty?.id!]);
					form.setFieldsValue({
						...form.getFieldsValue(),
						faculties: data.faculty?.id ? [data.faculty?.id] : [],
					});
				}
			},
		}
	);

	const { data: internship } = useQuery(
		[getSpecificInternship, isProfessor, isCompany, props.internshipId],
		() => getInternship(isProfessor, isCompany, props.internshipId),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			onSuccess: (data) => {
				if (data) {
					form.setFieldsValue({
						...toFormModel(data),
						faculties: data.faculties.map((x) => x.id),
						departments: data.departments.map((x) => x.id),
						studyPrograms: data.studyPrograms?.map((x) => x.id),
						availabilityYear: data.availableFrom.getFullYear(),
					});
					setFacultyCoordinatorId(data.facultyCoordinatorId!);
					setSelectedFaculties(data.faculties.map((x) => x.id!)!);
					setSelectedYears(data.years);
					setFacultySingleSelect(
						data.engagementType !== InternshipEngagementType.Job
					);
					setLocation(data.location);
					setPlaceId(data.placeId);
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const isEditing = useMemo(
		() =>
			props.internshipId !== undefined &&
			props.internshipId !== "" &&
			!props.isDuplicating,
		[props.internshipId, props.isDuplicating]
	);

	const openSaveErrorNotification = (error: any) => {
		if (error.status && error.status === StatusCodes.FORBIDDEN) {
			openNotification(
				t("internships.error"),
				t("internships.cannotDecrementPositionsError"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("internships.error"),
				t("internships.saveDataError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const handleCancel = () => {
		setCancelModalVisibility(false);
		props.setRedirect(true);
	};

	const openSuccessEditNotificationAndRedirect = () => {
		props.setRedirect(true);
		openNotification(
			t("internships.editInternship"),
			t("internships.editInternshipSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const openSuccessAddNotificationAndRedirect = () => {
		props.setRedirect(true);
		openNotification(
			t("internships.addInternship"),
			t("internships.addInternshipSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const handleChangeLocation = (place: any) => {
		setLocation(place.description);
		setPlaceId(place.place_id);
	};

	const handleSelectedFaculties = (selected: string) => {
		form.setFieldsValue({
			...form.getFieldsValue(),
			departments: [],
			studyPrograms: [],
			years: [],
			totalPracticeHours: undefined,
		});

		setSelectedFaculties(Array.isArray(selected) ? selected : [selected]);
		queryClient.invalidateQueries([getFacultiesWithCompanyAccordList]);
		if (
			engagementTypeValue === InternshipEngagementType.Internship &&
			selectedYears &&
			selectedYears.length > 0
		) {
			const selectedFaculty = facultiesWithCompanyAccordList?.find(
				(f) => f.id === selected
			);
			const selectedYear = selectedYears[0];

			if (
				selectedFaculty &&
				selectedFaculty.practicePeriods &&
				selectedFaculty.practicePeriods.length > 0
			) {
				const selectedPracticePeriod = selectedFaculty.practicePeriods.find(
					(p) => p.practiceYear === selectedYear
				);

				form.setFieldsValue({
					...form.getFieldsValue(),
					totalPracticeHours: selectedPracticePeriod?.practiceNumberOfHours,
				});

				setDisabledDateStart(
					moment(selectedPracticePeriod?.practiceEvaluationStart) ?? moment()
				);
				setDisabledDateEnd(
					moment(selectedPracticePeriod?.practiceEvaluationEnd) ??
						moment().add(1, "y")
				);
			}
		}
	};

	const handleEngagementTypeChange = (value: InternshipEngagementType) => {
		const newValues = form.getFieldsValue();
		if (value === InternshipEngagementType.BachelorsInternship) {
			setFacultySingleSelect(true);
			newValues.period = 6;
			newValues.startingDate = moment();
		} else if (value === InternshipEngagementType.MastersInternship) {
			setFacultySingleSelect(true);
			newValues.period = 12;
			newValues.startingDate = moment();
		} else if (value === InternshipEngagementType.Scholarship) {
			setFacultySingleSelect(true);
			newValues.period = 10;
		} else if (
			value === InternshipEngagementType.Internship ||
			value === InternshipEngagementType.Job
		) {
			newValues.period = 3;
			setFacultySingleSelect(true);
		} else if (value === InternshipEngagementType.Event) {
			setFacultySingleSelect(false);
		}

		form.setFieldsValue({
			...newValues,
			faculties: [],
			departments: [],
			studyPrograms: [],
			years: [],
		});
	};

	const handleFacultyCoordinatorChange = (value: string) => {
		setFacultyCoordinatorId(value);
	};

	const handleSave = () => {
		const state = form.getFieldsValue();

		/* Set default period, start date and availability for Bachelors & Masters */
		if (state.engagementType === InternshipEngagementType.BachelorsInternship) {
			state.period = 6;
			state.startingDate = moment();
			state.availabilityAd = [
				moment(),
				moment(new Date(getCurrentUniversityYear() + 1, 9, 1)),
			];
		} else if (
			state.engagementType === InternshipEngagementType.MastersInternship
		) {
			state.period = 12;
			state.startingDate = moment();
			state.availabilityAd = [
				moment(),
				moment(new Date(getCurrentUniversityYear() + 1, 9, 1)),
			];
		} else if (state.engagementType === InternshipEngagementType.Event) {
			state.period = 0;
			state.availabilityAd = [moment(), state.startingDate];
			state.description = state.description;
		}

		/* The length of the description is in parameters */
		if (state.description.length - 7 <= 60000) {
			setSpinning(true);

			const years =
				selectedYears?.length > 0
					? selectedYears
					: state.engagementType ===
					  InternshipEngagementType.BachelorsInternship
					? userYearsBachelor
					: state.engagementType === InternshipEngagementType.MastersInternship
					? userYearsMaster
					: state.engagementType === InternshipEngagementType.SummerSchool
					? userYearsSummerSchool
					: state.engagementType === InternshipEngagementType.Job
					? userYearsInternship
					: state.engagementType === InternshipEngagementType.Internship
					? isProfessor
						? professorUser?.faculty?.practiceInternshipPeriods?.map(
								(p) => p.practiceYear!
						  )
						: facultiesWithCompanyAccordList !== undefined &&
						  facultiesWithCompanyAccordList.length > 0
						? facultiesWithCompanyAccordList![0].practicePeriods?.map(
								(p) => p.practiceYear!
						  )
						: []
					: userYears;

			if (isDepartmentSecretary) {
				let obj = toProfessorInternshipAsDepartmentSecretary(
					state,
					selectedFaculties,
					location,
					placeId,
					years
				);

				(props.internshipId && isEditing
					? updateProfessorInternshipInfo(props.internshipId, obj)
					: addProfessorInternship(obj)
				)
					.then(
						props.internshipId
							? openSuccessEditNotificationAndRedirect
							: openSuccessAddNotificationAndRedirect
					)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setSaveModalVisibility(false);
					});
			} else if (isProfessor && !isDepartmentSecretary) {
				/* Create body and send request to add/update ProfessorInternship */
				let obj = toProfessorInternshipAsProfessor(
					state,
					selectedFaculties,
					location,
					placeId,
					years
				);

				(props.internshipId && isEditing
					? updateProfessorInternshipInfo(props.internshipId, obj)
					: addProfessorInternship(obj)
				)
					.then(
						props.internshipId
							? openSuccessEditNotificationAndRedirect
							: openSuccessAddNotificationAndRedirect
					)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setSaveModalVisibility(false);
					});
			} else {
				/* Create body and send request to add/update CompanyInternship */
				let obj = toCompanyInternship(
					state,
					selectedFaculties,
					location,
					placeId,
					facultyCoordinatorId,
					years
				);

				(props.internshipId && isEditing
					? updateCompanyInternshipInfo(props.internshipId, obj)
					: addCompanyInternship(obj)
				)
					.then(
						props.internshipId
							? openSuccessEditNotificationAndRedirect
							: openSuccessAddNotificationAndRedirect
					)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setSaveModalVisibility(false);
					});
			}
		} else {
			/* The description is too long */
			openNotification(
				t("internships.addInternshipForm.addInternship"),
				t("internships.addInternshipForm.descriptionLengthError"),
				NOTIFICATION_TYPES.ERROR
			);
			setSaveModalVisibility(false);
		}
	};

	return {
		state: {
			form,
			facultySingleSelect,
			spinning,
			cancelModalVisibility,
			saveModalVisibility,
			engagementTypeValue,
			internshipId: props.internshipId,
			currentUserId,
			defaultFacultyName,
			debouncedUserSearch,
			facultyOptions,
			defaultFaculty,
			selectedFaculties,
			disabledDateStart,
			disabledDateEnd,
			location,
			facultySearch: facultySearch,
		},
		actions: {
			setFacultyOptions,
			setSaveModalVisibility,
			setCancelModalVisibility,
			handleCancel,
			handleSave,
			handleEngagementTypeChange,
			handleFacultyCoordinatorChange,
			handleChangeLocation,
			setFacultySearch,
			setUserSearch,
			handleSelectedFaculties,
			setSelectedYears,
			setDisabledDateStart,
			setDisabledDateEnd,
		},
		computed: {
			isEditing,
			isCompany,
			isProfessor,
			isDepartmentSecretary,
			internship,
			facultiesWithCompanyAccordList,
			professorUser,
			allFacultiesList,
			currentAcademicYear,
			yupSync,
			defaultValues,
		},
	};
};
