/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfessionalTitleEnum,
    ProfessionalTitleEnumFromJSON,
    ProfessionalTitleEnumFromJSONTyped,
    ProfessionalTitleEnumToJSON,
} from './ProfessionalTitleEnum';
import {
    ResearchProposalType,
    ResearchProposalTypeFromJSON,
    ResearchProposalTypeFromJSONTyped,
    ResearchProposalTypeToJSON,
} from './ResearchProposalType';

/**
 * 
 * @export
 * @interface ResearchProposalDTO
 */
export interface ResearchProposalDTO {
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    researchProposalId: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    researchProposalName: string;
    /**
     * 
     * @type {ResearchProposalType}
     * @memberof ResearchProposalDTO
     */
    type: ResearchProposalType;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    shortDescription: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResearchProposalDTO
     */
    keywords?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    url?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ResearchProposalDTO
     */
    availableFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ResearchProposalDTO
     */
    availableTo?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchProposalDTO
     */
    isArchived?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ResearchProposalDTO
     */
    numberOfPartners?: number;
    /**
     * 
     * @type {number}
     * @memberof ResearchProposalDTO
     */
    availableNumberOfPartners?: number;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    professorId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    recruiterName: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchProposalDTO
     */
    recruiterAvatar: string;
    /**
     * 
     * @type {ProfessionalTitleEnum}
     * @memberof ResearchProposalDTO
     */
    professionalTitle?: ProfessionalTitleEnum;
}

export function ResearchProposalDTOFromJSON(json: any): ResearchProposalDTO {
    return ResearchProposalDTOFromJSONTyped(json, false);
}

export function ResearchProposalDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResearchProposalDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'researchProposalId': json['researchProposalId'],
        'researchProposalName': json['researchProposalName'],
        'type': ResearchProposalTypeFromJSON(json['type']),
        'description': json['description'],
        'shortDescription': json['shortDescription'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'availableFrom': !exists(json, 'availableFrom') ? undefined : (json['availableFrom'] === null ? null : new Date(json['availableFrom'])),
        'availableTo': !exists(json, 'availableTo') ? undefined : (json['availableTo'] === null ? null : new Date(json['availableTo'])),
        'isArchived': !exists(json, 'isArchived') ? undefined : json['isArchived'],
        'numberOfPartners': !exists(json, 'numberOfPartners') ? undefined : json['numberOfPartners'],
        'availableNumberOfPartners': !exists(json, 'availableNumberOfPartners') ? undefined : json['availableNumberOfPartners'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'professorId': !exists(json, 'professorId') ? undefined : json['professorId'],
        'recruiterName': json['recruiterName'],
        'recruiterAvatar': json['recruiterAvatar'],
        'professionalTitle': !exists(json, 'professionalTitle') ? undefined : ProfessionalTitleEnumFromJSON(json['professionalTitle']),
    };
}

export function ResearchProposalDTOToJSON(value?: ResearchProposalDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'researchProposalId': value.researchProposalId,
        'researchProposalName': value.researchProposalName,
        'type': ResearchProposalTypeToJSON(value.type),
        'description': value.description,
        'shortDescription': value.shortDescription,
        'keywords': value.keywords,
        'url': value.url,
        'availableFrom': value.availableFrom === undefined ? undefined : (value.availableFrom === null ? null : value.availableFrom.toISOString()),
        'availableTo': value.availableTo === undefined ? undefined : (value.availableTo === null ? null : value.availableTo.toISOString()),
        'isArchived': value.isArchived,
        'numberOfPartners': value.numberOfPartners,
        'availableNumberOfPartners': value.availableNumberOfPartners,
        'companyId': value.companyId,
        'professorId': value.professorId,
        'recruiterName': value.recruiterName,
        'recruiterAvatar': value.recruiterAvatar,
        'professionalTitle': ProfessionalTitleEnumToJSON(value.professionalTitle),
    };
}

