/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface PracticeInternshipPeriodAddDTO
 */
export interface PracticeInternshipPeriodAddDTO {
    /**
     * 
     * @type {Date}
     * @memberof PracticeInternshipPeriodAddDTO
     */
    practiceEvaluationStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PracticeInternshipPeriodAddDTO
     */
    practiceEvaluationEnd?: Date;
    /**
     * 
     * @type {number}
     * @memberof PracticeInternshipPeriodAddDTO
     */
    practiceNumberOfHours?: number;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof PracticeInternshipPeriodAddDTO
     */
    practiceYear?: StudentYearEnum;
}

export function PracticeInternshipPeriodAddDTOFromJSON(json: any): PracticeInternshipPeriodAddDTO {
    return PracticeInternshipPeriodAddDTOFromJSONTyped(json, false);
}

export function PracticeInternshipPeriodAddDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeInternshipPeriodAddDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'practiceEvaluationStart': !exists(json, 'practiceEvaluationStart') ? undefined : (new Date(json['practiceEvaluationStart'])),
        'practiceEvaluationEnd': !exists(json, 'practiceEvaluationEnd') ? undefined : (new Date(json['practiceEvaluationEnd'])),
        'practiceNumberOfHours': !exists(json, 'practiceNumberOfHours') ? undefined : json['practiceNumberOfHours'],
        'practiceYear': !exists(json, 'practiceYear') ? undefined : StudentYearEnumFromJSON(json['practiceYear']),
    };
}

export function PracticeInternshipPeriodAddDTOToJSON(value?: PracticeInternshipPeriodAddDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'practiceEvaluationStart': value.practiceEvaluationStart === undefined ? undefined : (value.practiceEvaluationStart.toISOString()),
        'practiceEvaluationEnd': value.practiceEvaluationEnd === undefined ? undefined : (value.practiceEvaluationEnd.toISOString()),
        'practiceNumberOfHours': value.practiceNumberOfHours,
        'practiceYear': StudentYearEnumToJSON(value.practiceYear),
    };
}

