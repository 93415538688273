/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternshipDocument,
    InternshipDocumentFromJSON,
    InternshipDocumentFromJSONTyped,
    InternshipDocumentToJSON,
} from './InternshipDocument';
import {
    InternshipDocumentSigningEnum,
    InternshipDocumentSigningEnumFromJSON,
    InternshipDocumentSigningEnumFromJSONTyped,
    InternshipDocumentSigningEnumToJSON,
} from './InternshipDocumentSigningEnum';

/**
 * 
 * @export
 * @interface InternshipDocumentSignature
 */
export interface InternshipDocumentSignature {
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentSignature
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InternshipDocumentSignature
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InternshipDocumentSignature
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentSignature
     */
    internshipDocumentId?: string;
    /**
     * 
     * @type {InternshipDocumentSigningEnum}
     * @memberof InternshipDocumentSignature
     */
    signer?: InternshipDocumentSigningEnum;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentSignature
     */
    fileName?: string | null;
    /**
     * 
     * @type {InternshipDocument}
     * @memberof InternshipDocumentSignature
     */
    internshipDocument?: InternshipDocument;
}

export function InternshipDocumentSignatureFromJSON(json: any): InternshipDocumentSignature {
    return InternshipDocumentSignatureFromJSONTyped(json, false);
}

export function InternshipDocumentSignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipDocumentSignature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'internshipDocumentId': !exists(json, 'internshipDocumentId') ? undefined : json['internshipDocumentId'],
        'signer': !exists(json, 'signer') ? undefined : InternshipDocumentSigningEnumFromJSON(json['signer']),
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'internshipDocument': !exists(json, 'internshipDocument') ? undefined : InternshipDocumentFromJSON(json['internshipDocument']),
    };
}

export function InternshipDocumentSignatureToJSON(value?: InternshipDocumentSignature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'internshipDocumentId': value.internshipDocumentId,
        'signer': InternshipDocumentSigningEnumToJSON(value.signer),
        'fileName': value.fileName,
        'internshipDocument': InternshipDocumentToJSON(value.internshipDocument),
    };
}

