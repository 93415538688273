/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushNotificationSubscriptionDTO
 */
export interface PushNotificationSubscriptionDTO {
    /**
     * 
     * @type {string}
     * @memberof PushNotificationSubscriptionDTO
     */
    endpoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PushNotificationSubscriptionDTO
     */
    p256dh?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PushNotificationSubscriptionDTO
     */
    auth?: string | null;
}

export function PushNotificationSubscriptionDTOFromJSON(json: any): PushNotificationSubscriptionDTO {
    return PushNotificationSubscriptionDTOFromJSONTyped(json, false);
}

export function PushNotificationSubscriptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushNotificationSubscriptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'p256dh': !exists(json, 'p256dh') ? undefined : json['p256dh'],
        'auth': !exists(json, 'auth') ? undefined : json['auth'],
    };
}

export function PushNotificationSubscriptionDTOToJSON(value?: PushNotificationSubscriptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpoint': value.endpoint,
        'p256dh': value.p256dh,
        'auth': value.auth,
    };
}

