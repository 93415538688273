/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';

/**
 * 
 * @export
 * @interface StudentAppreciation
 */
export interface StudentAppreciation {
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciation
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudentAppreciation
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudentAppreciation
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciation
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciation
     */
    professorId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciation
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciation
     */
    message?: string | null;
    /**
     * 
     * @type {AcademicUser}
     * @memberof StudentAppreciation
     */
    student?: AcademicUser;
    /**
     * 
     * @type {AcademicUser}
     * @memberof StudentAppreciation
     */
    professor?: AcademicUser;
}

export function StudentAppreciationFromJSON(json: any): StudentAppreciation {
    return StudentAppreciationFromJSONTyped(json, false);
}

export function StudentAppreciationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentAppreciation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'professorId': !exists(json, 'professorId') ? undefined : json['professorId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'student': !exists(json, 'student') ? undefined : AcademicUserFromJSON(json['student']),
        'professor': !exists(json, 'professor') ? undefined : AcademicUserFromJSON(json['professor']),
    };
}

export function StudentAppreciationToJSON(value?: StudentAppreciation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'studentId': value.studentId,
        'professorId': value.professorId,
        'title': value.title,
        'message': value.message,
        'student': AcademicUserToJSON(value.student),
        'professor': AcademicUserToJSON(value.professor),
    };
}

