import { EmailActionsProps } from "./EmailListItem.types";
import { useTranslation } from "react-i18next";
import { Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { EmailPriority } from "../../../../../../Api";
import styles from "./EmailListItem.module.scss";
import { isUndefined } from "lodash";

const EmailActions = (props: EmailActionsProps) => {
	const { t } = useTranslation();

	const IconText = ({
		icon,
		text,
		tooltipText,
	}: {
		icon: JSX.Element;
		text: string;
		tooltipText: string;
	}) => (
		<Space title={tooltipText}>
			{icon}
			{text}
		</Space>
	);

	return (
		<>
			{props.item && (
				<div className={styles.actionsContainer}>
					<IconText
						icon={<FontAwesomeIcon icon={solid("user")} />}
						text={props.item.publisherName ?? ""}
						tooltipText={t("emails.publisher")}
						key="list-Publisher"
					/>
					<IconText
						icon={<FontAwesomeIcon icon={solid("envelope")} />}
						text={props.item.newsletterChannel!}
						tooltipText={t("emails.newsletterChannel")}
						key="list-NewsletterStatus"
					/>
					<IconText
						icon={
							<FontAwesomeIcon
								icon={
									props.item.priority === EmailPriority.Low
										? solid("circle-down")
										: props.item.priority === EmailPriority.Normal
										? solid("circle-info")
										: solid("circle-up")
								}
							/>
						}
						text={t("emails.priorities." + props.item.priority)}
						tooltipText={t("emails.publishEmailForm.priority")}
						key="list-Priority"
					/>
					<IconText
						icon={<FontAwesomeIcon icon={solid("users")} />}
						text={
							(!isUndefined(props.item.initialRecipientsCount) &&
							!isUndefined(props.item.currentRecipientsCount)
								? props.item.initialRecipientsCount -
								  props.item.currentRecipientsCount
								: 0) +
							"/" +
							props.item.initialRecipientsCount
						}
						tooltipText={t("emails.sendStatus")}
						key="list-SendStatus"
					/>
					<br />
				</div>
			)}
		</>
	);
};

export default EmailActions;
