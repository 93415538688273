/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddAnswersDTO,
    AddAnswersDTOFromJSON,
    AddAnswersDTOToJSON,
} from '../models';

export interface ApiAnswersSaveAnswersPostRequest {
    addAnswersDTO?: AddAnswersDTO;
}

/**
 * 
 */
export class AnswersApi extends runtime.BaseAPI {

    /**
     */
    async apiAnswersSaveAnswersPostRaw(requestParameters: ApiAnswersSaveAnswersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Answers/SaveAnswers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAnswersDTOToJSON(requestParameters.addAnswersDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAnswersSaveAnswersPost(requestParameters: ApiAnswersSaveAnswersPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiAnswersSaveAnswersPostRaw(requestParameters, initOverrides);
    }

}
