/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';
import {
    InternshipStudyProgram,
    InternshipStudyProgramFromJSON,
    InternshipStudyProgramFromJSONTyped,
    InternshipStudyProgramToJSON,
} from './InternshipStudyProgram';
import {
    StudentStudyProgram,
    StudentStudyProgramFromJSON,
    StudentStudyProgramFromJSONTyped,
    StudentStudyProgramToJSON,
} from './StudentStudyProgram';

/**
 * 
 * @export
 * @interface StudyProgram
 */
export interface StudyProgram {
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgram
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgram
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    studyCycle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    facultyId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    coordinatorId?: string | null;
    /**
     * 
     * @type {Faculty}
     * @memberof StudyProgram
     */
    faculty?: Faculty;
    /**
     * 
     * @type {AcademicUser}
     * @memberof StudyProgram
     */
    coordinator?: AcademicUser;
    /**
     * 
     * @type {Array<StudentStudyProgram>}
     * @memberof StudyProgram
     */
    studentStudyPrograms?: Array<StudentStudyProgram> | null;
    /**
     * 
     * @type {Array<InternshipStudyProgram>}
     * @memberof StudyProgram
     */
    internshipStudyPrograms?: Array<InternshipStudyProgram> | null;
    /**
     * 
     * @type {Array<AcademicUser>}
     * @memberof StudyProgram
     */
    practiceSupervisors?: Array<AcademicUser> | null;
}

export function StudyProgramFromJSON(json: any): StudyProgram {
    return StudyProgramFromJSONTyped(json, false);
}

export function StudyProgramFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyProgram {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'studyCycle': !exists(json, 'studyCycle') ? undefined : json['studyCycle'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'facultyId': !exists(json, 'facultyId') ? undefined : json['facultyId'],
        'coordinatorId': !exists(json, 'coordinatorId') ? undefined : json['coordinatorId'],
        'faculty': !exists(json, 'faculty') ? undefined : FacultyFromJSON(json['faculty']),
        'coordinator': !exists(json, 'coordinator') ? undefined : AcademicUserFromJSON(json['coordinator']),
        'studentStudyPrograms': !exists(json, 'studentStudyPrograms') ? undefined : (json['studentStudyPrograms'] === null ? null : (json['studentStudyPrograms'] as Array<any>).map(StudentStudyProgramFromJSON)),
        'internshipStudyPrograms': !exists(json, 'internshipStudyPrograms') ? undefined : (json['internshipStudyPrograms'] === null ? null : (json['internshipStudyPrograms'] as Array<any>).map(InternshipStudyProgramFromJSON)),
        'practiceSupervisors': !exists(json, 'practiceSupervisors') ? undefined : (json['practiceSupervisors'] === null ? null : (json['practiceSupervisors'] as Array<any>).map(AcademicUserFromJSON)),
    };
}

export function StudyProgramToJSON(value?: StudyProgram | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'studyCycle': value.studyCycle,
        'name': value.name,
        'domain': value.domain,
        'facultyId': value.facultyId,
        'coordinatorId': value.coordinatorId,
        'faculty': FacultyToJSON(value.faculty),
        'coordinator': AcademicUserToJSON(value.coordinator),
        'studentStudyPrograms': value.studentStudyPrograms === undefined ? undefined : (value.studentStudyPrograms === null ? null : (value.studentStudyPrograms as Array<any>).map(StudentStudyProgramToJSON)),
        'internshipStudyPrograms': value.internshipStudyPrograms === undefined ? undefined : (value.internshipStudyPrograms === null ? null : (value.internshipStudyPrograms as Array<any>).map(InternshipStudyProgramToJSON)),
        'practiceSupervisors': value.practiceSupervisors === undefined ? undefined : (value.practiceSupervisors === null ? null : (value.practiceSupervisors as Array<any>).map(AcademicUserToJSON)),
    };
}

