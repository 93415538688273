import { NewsletterEmailsViewProps } from "./NewsletterEmailsView.types";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { NewsletterSortFieldEnum, SortDirectionEnum } from "../../../Api";
import { Link, useHistory } from "react-router-dom";
import { useBeforeRender } from "../../../utils/utilFunctions";
import { useQuery } from "react-query";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { getPublishedNewsletterEmails } from "../../../Requests/newsletter-requests";
import { Button, Col, Drawer, Row } from "antd";
import CustomButton from "../../../CustomComponents/CustomButton";
import { theme } from "../../../theme";
import Search from "antd/lib/input/Search";
import CustomSort from "../../../CustomComponents/CustomSort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import styles from "./NewsletterEmailsView.module.scss";
import EmailsList from "./components/lists/EmailsList/EmailsList";
import { usePagination } from "../../../Hooks";

export const NewsletterEmailsView = (props: NewsletterEmailsViewProps) => {
	const { t } = useTranslation();
	const {
		currentPage,
		setCurrentPage,
		pageSize,
		setPageSize,
		handleListPaginationChange,
	} = usePagination(1, 10);
	const [sortField, setSortField] = useState(
		NewsletterSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [searchTerm, setSearchTerm] = useState("");
	const [visible, setVisible] = useState(false);
	const history = useHistory();

	const getOldLocationValues = () => {
		if (props.location.state !== undefined) {
			let { searchTerm, sortField, sortDirection, currentPage, pageSize }: any =
				props.location.state;

			setSearchTerm(searchTerm);
			setSortField(sortField);
			setSortDirection(sortDirection);
			setCurrentPage(currentPage);
			setPageSize(pageSize);
			history.replace({ ...history.location, state: undefined });
		}
	};

	useBeforeRender(() => getOldLocationValues(), [props.location]);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const openGetErrorNotification = (_error: any) => {
		console.error(_error);
		openNotification(
			t("emails.error"),
			t("emails.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: newsletterEmails, isLoading: loadingNewsletterEmails } =
		useQuery(
			[
				"getNewsletterEmails",
				currentPage,
				pageSize,
				sortField,
				sortDirection,
				searchTerm,
			],
			() =>
				getPublishedNewsletterEmails(
					searchTerm,
					currentPage,
					pageSize,
					sortField,
					sortDirection
				),
			{
				onError: openGetErrorNotification,
			}
		);

	const changeSortField = (value: any) => {
		setSortField(value);
	};

	const changeSortDirection = () => {
		if (sortDirection === SortDirectionEnum.Ascending) {
			setSortDirection(SortDirectionEnum.Descending);
		} else {
			setSortDirection(SortDirectionEnum.Ascending);
		}
	};

	return (
		<Row>
			<Col span={4} className={styles.filters}>
				<CustomButton
					backgroundcolor={theme.primaryColor}
					textcolor={theme.white}
					fontSize={"1rem"}
					paddingvertical={"1.2rem"}
					paddinghorizontal={"1.4rem"}
					marginleft={"auto"}
					marginright={"auto"}
				>
					<Link
						to={{
							pathname: "/editare-email/",
							state: {
								searchTerm: searchTerm,
								sortField: sortField,
								sortDirection: sortDirection,
								currentPage: currentPage,
								pageSize: pageSize,
							},
						}}
					>
						{t("emails.publishEmail")}
					</Link>
				</CustomButton>
				<Search
					defaultValue={searchTerm}
					className={styles.searchBox}
					placeholder={t("emails.search")}
					allowClear
					onSearch={setSearchTerm}
					style={{ width: 200 }}
				/>
				<CustomSort
					changeSortField={changeSortField}
					changeSortDirection={changeSortDirection}
					sortDirection={sortDirection}
					location={props.location}
					oldValue={sortField}
				/>
			</Col>
			<Drawer
				className={styles.opened}
				placement={"left"}
				closable={true}
				onClose={onClose}
				visible={visible}
				key={"left"}
				maskClosable={true}
				title={t("emails.filters.filters")}
			>
				<Search
					placeholder={t("emails.search")}
					allowClear
					onSearch={setSearchTerm}
				/>
				<CustomSort
					changeSortField={changeSortField}
					changeSortDirection={changeSortDirection}
					sortDirection={sortDirection}
					location={props.location}
				/>
			</Drawer>
			<Row justify="space-between" className={styles.filterButton}>
				<Col
					span={window.innerWidth > 350 ? 12 : 24}
					style={{
						display: "flex",
						justifyContent: window.innerWidth > 350 ? "flex-start" : "center",
						marginBottom: window.innerWidth < 351 ? "1rem" : 0,
					}}
				>
					<CustomButton
						backgroundcolor={theme.primaryColor}
						textcolor={theme.white}
						fontSize={"1rem"}
						paddingvertical={"1.2rem"}
						paddinghorizontal={"1.4rem"}
						margintop={"0"}
					>
						<Link
							to={{
								pathname: "/editare-email",
								state: {
									searchTerm,
									sortField,
									sortDirection,
									currentPage,
									pageSize,
								},
							}}
						>
							{t("emails.publishEmail")}
						</Link>
					</CustomButton>
				</Col>
				<Col
					span={window.innerWidth > 350 ? 12 : 24}
					style={{
						display: "flex",
						justifyContent: window.innerWidth < 351 ? "center" : "flex-start",
					}}
				>
					<Button
						onClick={showDrawer}
						style={{ outline: "none", border: "none", boxShadow: "none" }}
					>
						<FontAwesomeIcon
							icon={solid("filter")}
							style={{ color: theme.secondColor }}
						/>
						&nbsp;{t("emails.filters.filter")}
					</Button>
				</Col>
			</Row>
			<Col
				span={window.innerWidth > 1100 ? 20 : 24}
				style={{ padding: "0 0 0 3%" }}
			>
				<EmailsList
					data={newsletterEmails}
					loading={loadingNewsletterEmails}
					location={props.location}
					currentPage={currentPage}
					pageSize={pageSize}
					searchTerm={searchTerm}
					sortDirection={sortDirection}
					sortField={sortField}
					handleListChange={handleListPaginationChange}
				/>
			</Col>
		</Row>
	);
};
