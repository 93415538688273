import { Button, Divider, List } from "antd";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { UserSkill, SkillEndorsement } from "../../Api";
import {
	addSkillEndorsement,
	deleteSkillEndorsement,
} from "../../Requests/skill-endorsement-requests";
import { getUserId } from "../../utils/utilFunctions";
import {
	studentAddSkillEndorsement,
	studentDeleteSkillEndorsement,
} from "./StudentSlice";
import styles from "./Student.module.scss";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { theme } from "../../theme";

const getSkillEndorsement = (item: UserSkill) => {
	const endorsements = item.skillEndorsements?.filter(
		(x: SkillEndorsement) => x.professorId === getUserId()
	);

	if (endorsements) return endorsements[0];
};

const openSkillsEndorsementError = (_error: any) => {
	openNotification(
		t("account.error"),
		t("account.unknownError"),
		NOTIFICATION_TYPES.ERROR
	);
};

const makeOnClickButton = (item: UserSkill, dispatch: Dispatch<any>) => () => {
	const endorsement = getSkillEndorsement(item);

	if (endorsement) {
		deleteSkillEndorsement(endorsement.id!)
			.then(() =>
				dispatch(
					studentDeleteSkillEndorsement({
						id: endorsement.id,
						userSkillId: item.id,
					})
				)
			)
			.catch(openSkillsEndorsementError);
	} else {
		addSkillEndorsement(item.id!)
			.then((data) =>
				dispatch(studentAddSkillEndorsement({ ...data, userSkillId: item.id }))
			)
			.catch(openSkillsEndorsementError);
	}
};

const SkillEndorsements = (props: {
	skills: Array<UserSkill>;
	enableButtons: boolean;
}) => {
	const dispatch = useDispatch();

	return (
		<List
			grid={{
				gutter: 10,
			}}
			dataSource={props.skills}
			renderItem={(item: UserSkill) => (
				<List.Item>
					<Button
						className={styles.button}
						disabled={!props.enableButtons}
						onClick={makeOnClickButton(item, dispatch)}
						style={
							props.enableButtons
								? getSkillEndorsement(item)
									? {
											backgroundColor: theme.secondColor,
											borderColor: theme.secondColor,
									  }
									: {
											backgroundColor: theme.primaryColor,
											borderColor: theme.primaryColor,
									  }
								: { backgroundColor: "transparent", borderColor: "transparent" }
						}
					>
						{item.skill}
						<Divider
							type="vertical"
							style={{ borderRight: "1px solid white" }}
						/>
						{item.skillEndorsements?.length}&nbsp;
						{props.enableButtons ? (
							getSkillEndorsement(item) ? (
								<FontAwesomeIcon
									icon={regular("thumbs-up")}
									style={{ fontSize: 15 }}
								/>
							) : (
								<FontAwesomeIcon
									icon={solid("plus")}
									style={{ fontSize: 15 }}
								/>
							)
						) : (
							<FontAwesomeIcon
								icon={solid("thumbs-up")}
								style={{ fontSize: 15 }}
							/>
						)}
					</Button>
				</List.Item>
			)}
		/>
	);
};

export default SkillEndorsements;
