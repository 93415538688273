import Layout from "../../Containers/Layout";
import { Row, Col, Button, Drawer, Tabs } from "antd";
import { useEffect, useState } from "react";
import { getAllProfessorInternshipFilters } from "../../Requests/professor-internship-requests";
import { getAllCompanyInternshipFilters } from "../../Requests/company-internship-requests";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./ResearchOffers.module.scss";
import Search from "antd/lib/input/Search";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import {
	useBeforeRender,
	useIsCompany,
	useIsProfessor,
} from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	Filter,
	InternType,
	InternshipSortFieldEnum,
	SortDirectionEnum,
} from "../../Api";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQuery, useQueryClient } from "react-query";
import OffersList from "./OffersList";
import CustomFilter from "../../CustomComponents/CustomFilter";
import CustomSort from "../../CustomComponents/CustomSort";
import {
	getActiveResearchOffers,
	getActiveResearchOffersFilters,
	getMyResearchOffers,
	getMyResearchOffersFilters,
	getResearchApplicants,
	getResearchApplicantsFilters,
	getResearchApplications,
} from "../../utils/reactQueriesConstants";
import ApplicantsList from "./ApplicantsList";
import { getInternsFilters } from "../../Requests/internship-requests";
import { traineesFilters } from "../../utils/constants";
import { getAllCompanyResearchPropsals } from "../../Requests/company-research-proposals-requests";
import { getAllProfessorResearchPropsals } from "../../Requests/professor-research-proposals-requests";
import {
	getMyApplications,
	getResearchPartnersForCompanyOrProfessor,
} from "../../Requests/research-proposal-requests";
import ApplicationsList from "./ApplicationsList";

const { TabPane } = Tabs;

const getAllKeys = (filterItems: any[]) => {
	const keys: any[] = [];

	filterItems?.forEach((filter) => {
		keys.push(filter.key);

		filter.children.forEach((filterItem: any) => {
			keys.push(filterItem.key.split("/")[1]);
		});
	});

	return keys;
};

const ResearchOffers = (props: any) => {
	const { t, i18n } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState(
		InternshipSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [searchTerm, setSearchTerm] = useState("");
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState<any>([]);
	const [visible, setVisible] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const [activeTab, setActiveTab] = useState("1");
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const queryClient = useQueryClient();

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getMyResearchOffersFilters);
		await queryClient.invalidateQueries(getActiveResearchOffersFilters);
		await queryClient.invalidateQueries(getResearchApplicantsFilters);
		await queryClient.invalidateQueries(getMyResearchOffers);
		await queryClient.invalidateQueries(getActiveResearchOffers);
		await queryClient.invalidateQueries(getResearchApplicants);
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrentPage(1);
	};

	const getOldValues = () => {
		if (location.state !== undefined) {
			let {
				existingFilters,
				searchTerm,
				sortField,
				sortDirection,
				currentPage,
				pageSize,
				activeTab,
			}: any = location.state;

			onCheck(existingFilters);
			setSearchTerm(searchTerm);
			setSortField(sortField);
			setSortDirection(sortDirection);
			setCurrentPage(currentPage);
			setPageSize(pageSize);

			if (isProfessor) setActiveTab(activeTab);

			history.replace({ ...history.location, state: undefined });
		}
	};

	useBeforeRender(() => getOldValues(), [location]);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: myOffersFilters } = useQuery(
		[getMyResearchOffersFilters, isProfessor, isCompany],
		() =>
			isProfessor
				? getAllProfessorInternshipFilters()
				: getAllCompanyInternshipFilters(),
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: activeOffersFilters } = useQuery(
		[getActiveResearchOffersFilters, isCompany],
		() =>
			isProfessor
				? getAllProfessorInternshipFilters()
				: getAllCompanyInternshipFilters(),
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isProfessor) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: applicantsFilters } = useQuery(
		[getResearchApplicantsFilters, traineesFilters, i18n.language],
		() => {
			return getInternsFilters(
				traineesFilters,
				i18n.language,
				InternType.Applicant,
				searchTerm,
				currentPage,
				pageSize,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined
			);
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
		}
	);

	const { data: myOffers, isLoading: loadingMyOffers } = useQuery(
		[
			getMyResearchOffers,
			isProfessor,
			isCompany,
			searchTerm,
			currentPage,
			pageSize,
			sortField,
			sortDirection,
		],
		() =>
			isProfessor
				? getAllProfessorResearchPropsals(
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
				  )
				: getAllCompanyResearchPropsals(
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
				  ),
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: activeOffers, isLoading: loadingActiveOffers } = useQuery(
		[
			getActiveResearchOffers,
			isProfessor,
			isCompany,
			searchTerm,
			currentPage,
			pageSize,
			sortField,
			sortDirection,
		],
		() =>
			isProfessor
				? getAllCompanyResearchPropsals(
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
				  )
				: getAllProfessorResearchPropsals(
						searchTerm,
						currentPage,
						pageSize,
						sortField,
						sortDirection
				  ),
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: applicants, isLoading: loadingApplicants } = useQuery(
		[getResearchApplicants, currentPage, pageSize, searchTerm, i18n.language],
		() =>
			getResearchPartnersForCompanyOrProfessor(
				searchTerm,
				currentPage,
				pageSize,
				undefined,
				undefined,
				undefined
			),
		{
			onError: openGetErrorNotification,
		}
	);

	const { data: myApplications, isLoading: loadingMyApplications } = useQuery(
		[getResearchApplications, currentPage, pageSize, searchTerm, i18n.language],
		() =>
			getMyApplications(
				searchTerm,
				currentPage,
				pageSize,
				undefined,
				undefined,
				undefined
			),
		{
			onError: openGetErrorNotification,
		}
	);

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters?.map((x) => {
			if (x.key === "Location") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: y.title,
						}))
					),
				};
			} else if (x.key === "Applicability") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title:
								y.title === "True"
									? t("internships.filters.isViewOnly")
									: t("internships.filters.canApply"),
						}))
					),
				};
			} else if (x.key === "ArchivationState") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("internships.filters.archivationStates." + y.title),
						}))
					),
				};
			} else if (x.key === "ValidationState") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title:
								y.title === "True"
									? t("internships.filters.valid")
									: t("internships.filters.invalid"),
						}))
					),
				};
			} else {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("internships.filters." + y.title),
						}))
					),
				};
			}
		});
	};

	const changeSortField = (value: any) => {
		setSortField(value);
	};

	const changeSortDirection = () => {
		if (sortDirection === SortDirectionEnum.Ascending) {
			setSortDirection(SortDirectionEnum.Descending);
		} else {
			setSortDirection(SortDirectionEnum.Ascending);
		}
	};

	const changeTab = (activeKey: any) => {
		setActiveTab(activeKey);
		setCheckedKeys([]);
	};

	useEffect(() => {
		if (activeTab === "1") {
			onCheck(["ArchivationState@False"]);
		}
	}, [activeTab]);

	return (
		<Layout>
			<Row>
				<Col span={4} className={styles.filters}>
					{activeTab === "1" && (
						<CustomButton
							backgroundcolor={theme.primaryColor}
							textcolor={theme.white}
							fontSize={"1rem"}
							paddingvertical={"1.2rem"}
							paddinghorizontal={"1.4rem"}
							marginleft={"auto"}
							marginright={"auto"}
							margintop={"2rem"}
						>
							<Link
								to={{
									pathname: "/editare-propunere-cercetare/",
									state: {
										filters: checkedKeys,
										searchTerm: searchTerm,
										sortField: sortField,
										sortDirection: sortDirection,
										currentPage: currentPage,
										pageSize: pageSize,
										activeTab: activeTab,
									},
								}}
							>
								{t("internships.addInternship")}
							</Link>
						</CustomButton>
					)}
					{activeTab === "2" && (
						<>
							<Search
								defaultValue={searchTerm}
								className={styles.searchBox}
								placeholder={t("internships.search")}
								allowClear
								onSearch={setSearchTerm}
								style={{ width: 200 }}
							/>
							<CustomSort
								changeSortField={changeSortField}
								changeSortDirection={changeSortDirection}
								sortDirection={sortDirection}
								location={props.location}
								oldValue={sortField}
							/>
						</>
					)}
					<CustomFilter
						filters={
							activeTab === "1"
								? myOffersFilters!
								: activeTab === "2"
								? activeOffersFilters!
								: applicantsFilters
						}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Col>
				<Drawer
					className={styles.opened}
					placement={"left"}
					closable={true}
					onClose={onClose}
					visible={visible}
					key={"left"}
					maskClosable={true}
					title={t("internships.filters.filters")}
				>
					{activeTab === "2" && (
						<>
							<Search
								defaultValue={searchTerm}
								placeholder={t("internships.search")}
								allowClear
								onSearch={setSearchTerm}
							/>
							<CustomSort
								changeSortField={changeSortField}
								changeSortDirection={changeSortDirection}
								sortDirection={sortDirection}
								location={props.location}
							/>
						</>
					)}
					<CustomFilter
						filters={
							activeTab === "1"
								? myOffersFilters!
								: activeTab === "2"
								? activeOffersFilters!
								: applicantsFilters
						}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Drawer>
				<Row justify="space-between" className={styles.filterButton}>
					{activeTab === "1" && (
						<Col
							span={window.innerWidth > 350 ? 12 : 24}
							style={{
								display: "flex",
								justifyContent:
									window.innerWidth > 350 ? "flex-start" : "center",
								marginBottom: window.innerWidth < 351 ? "1rem" : 0,
							}}
						>
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								fontSize={"1rem"}
								paddingvertical={"1.2rem"}
								paddinghorizontal={"1.4rem"}
								margintop={"0"}
							>
								<Link to="/editare-propunere-cercetare">
									{t("internships.addInternship")}
								</Link>
							</CustomButton>
						</Col>
					)}
					<Col
						span={window.innerWidth > 350 ? 12 : 24}
						style={{
							display: "flex",
							justifyContent: window.innerWidth < 351 ? "center" : "flex-end",
						}}
					>
						<Button
							onClick={showDrawer}
							style={{ outline: "none", border: "none", boxShadow: "none" }}
						>
							<FontAwesomeIcon
								icon={solid("filter")}
								style={{ color: theme.secondColor }}
							/>
							&nbsp;{t("internships.filters.filter")}
						</Button>
					</Col>
				</Row>
				<Col
					span={window.innerWidth > 1100 ? 20 : 24}
					style={{ padding: "0 0 0 3%", marginTop: "20px" }}
				>
					<Tabs
						activeKey={activeTab}
						centered
						animated={{ inkBar: false }}
						tabBarStyle={{
							display: "flex",
							justifyContent: "space-evenly",
							alignItems: "center",
						}}
						className={styles.displayFlex}
						onChange={changeTab}
					>
						<TabPane tab={t("internships.myResearchOffers")} key="1">
							<OffersList
								data={myOffers}
								checkedKeys={checkedKeys}
								loading={loadingMyOffers}
								location={props.location}
								activeTab={"1"}
								currentPage={currentPage}
								pageSize={pageSize}
								searchTerm={searchTerm}
								sortDirection={sortDirection}
								sortField={sortField}
								handleListChange={handleListChange}
							/>
						</TabPane>
						<TabPane tab={t("internships.activeResearchOffers")} key="2">
							<OffersList
								data={activeOffers}
								checkedKeys={checkedKeys}
								loading={loadingActiveOffers}
								location={props.location}
								activeTab={"2"}
								currentPage={currentPage}
								pageSize={pageSize}
								searchTerm={searchTerm}
								sortDirection={sortDirection}
								sortField={sortField}
								handleListChange={handleListChange}
							/>
						</TabPane>
						<TabPane tab={t("internships.applicants")} key="3">
							<ApplicantsList
								data={applicants}
								checkedKeys={checkedKeys}
								loading={loadingApplicants}
								location={props.location}
								activeTab={"3"}
								currentPage={currentPage}
								pageSize={pageSize}
								searchTerm={searchTerm}
								sortDirection={sortDirection}
								sortField={sortField}
								handleListChange={handleListChange}
							/>
						</TabPane>
						<TabPane tab={t("research.myApplications")} key="4">
							<ApplicationsList
								data={myApplications}
								checkedKeys={checkedKeys}
								loading={loadingMyApplications}
								location={props.location}
								activeTab={"4"}
								currentPage={currentPage}
								pageSize={pageSize}
								searchTerm={searchTerm}
								sortDirection={sortDirection}
								sortField={sortField}
								handleListChange={handleListChange}
							/>
						</TabPane>
					</Tabs>
				</Col>
			</Row>
		</Layout>
	);
};

export default ResearchOffers;
