/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UniversityAccordStateEnum {
    Initiated = 'Initiated',
    PendingAdministratorValidation = 'PendingAdministratorValidation',
    PendingPersonnelValidation = 'PendingPersonnelValidation',
    AdministratorValidated = 'AdministratorValidated',
    AdministratorRejected = 'AdministratorRejected',
    PersonnelValidated = 'PersonnelValidated',
    PersonnelRejected = 'PersonnelRejected',
    PendingSignatures = 'PendingSignatures',
    Approved = 'Approved'
}

export function UniversityAccordStateEnumFromJSON(json: any): UniversityAccordStateEnum {
    return UniversityAccordStateEnumFromJSONTyped(json, false);
}

export function UniversityAccordStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UniversityAccordStateEnum {
    return json as UniversityAccordStateEnum;
}

export function UniversityAccordStateEnumToJSON(value?: UniversityAccordStateEnum | null): any {
    return value as any;
}

