import Layout from "../../Containers/Layout";
import { FacultySettingsForm } from "../Forms";

export const FacultySettingsPage = () => {
	return (
		<Layout>
			<FacultySettingsForm />
		</Layout>
	);
};
