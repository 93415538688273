/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Role {
    Admin = 'Admin',
    Dean = 'Dean',
    ViceDean = 'ViceDean',
    FacultyAdmin = 'FacultyAdmin',
    InternshipAdmin = 'InternshipAdmin',
    Professor = 'Professor',
    Student = 'Student',
    All = 'All',
    Rector = 'Rector',
    GeneralDirector = 'GeneralDirector',
    EconomicDirector = 'EconomicDirector',
    LegalCounselor = 'LegalCounselor',
    PreventiveFinancialControl = 'PreventiveFinancialControl',
    UniversityAccordResponsible = 'UniversityAccordResponsible',
    Company = 'Company',
    DepartmentDirector = 'DepartmentDirector',
    StudyProgramCoordinator = 'StudyProgramCoordinator',
    AdministrativeAccordsResponsible = 'AdministrativeAccordsResponsible',
    DepartmentSecretary = 'DepartmentSecretary',
    NewsletterAdmin = 'NewsletterAdmin',
    FacultyContactsResponsible = 'FacultyContactsResponsible',
    FeedbackAdmin = 'FeedbackAdmin'
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    return json as Role;
}

export function RoleToJSON(value?: Role | null): any {
    return value as any;
}

