/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDocument,
    CompanyDocumentFromJSON,
    CompanyDocumentFromJSONTyped,
    CompanyDocumentToJSON,
} from './CompanyDocument';
import {
    CompanyFacultyAccord,
    CompanyFacultyAccordFromJSON,
    CompanyFacultyAccordFromJSONTyped,
    CompanyFacultyAccordToJSON,
} from './CompanyFacultyAccord';
import {
    CompanyInternship,
    CompanyInternshipFromJSON,
    CompanyInternshipFromJSONTyped,
    CompanyInternshipToJSON,
} from './CompanyInternship';
import {
    CompanyPracticeSupervisor,
    CompanyPracticeSupervisorFromJSON,
    CompanyPracticeSupervisorFromJSONTyped,
    CompanyPracticeSupervisorToJSON,
} from './CompanyPracticeSupervisor';
import {
    CompanyStateEnum,
    CompanyStateEnumFromJSON,
    CompanyStateEnumFromJSONTyped,
    CompanyStateEnumToJSON,
} from './CompanyStateEnum';
import {
    CompanyUniversityAccord,
    CompanyUniversityAccordFromJSON,
    CompanyUniversityAccordFromJSONTyped,
    CompanyUniversityAccordToJSON,
} from './CompanyUniversityAccord';
import {
    CompanyUser,
    CompanyUserFromJSON,
    CompanyUserFromJSONTyped,
    CompanyUserToJSON,
} from './CompanyUser';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';
import {
    PreferredLanguageEnum,
    PreferredLanguageEnumFromJSON,
    PreferredLanguageEnumFromJSONTyped,
    PreferredLanguageEnumToJSON,
} from './PreferredLanguageEnum';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    commercialName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    cui?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    emailContact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    domainOfActivity?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Company
     */
    areasOfInterest?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    fax?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    siteUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    linkedInUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    logo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    imageFileName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    isContact?: boolean;
    /**
     * 
     * @type {CompanyStateEnum}
     * @memberof Company
     */
    state?: CompanyStateEnum;
    /**
     * 
     * @type {PreferredLanguageEnum}
     * @memberof Company
     */
    preferredLanguage?: PreferredLanguageEnum;
    /**
     * 
     * @type {Array<CompanyUniversityAccord>}
     * @memberof Company
     */
    universityAccords?: Array<CompanyUniversityAccord> | null;
    /**
     * 
     * @type {Array<CompanyInternship>}
     * @memberof Company
     */
    companyInternships?: Array<CompanyInternship> | null;
    /**
     * 
     * @type {Array<CompanyFacultyAccord>}
     * @memberof Company
     */
    companyFacultyAccords?: Array<CompanyFacultyAccord> | null;
    /**
     * 
     * @type {Array<CompanyDocument>}
     * @memberof Company
     */
    companyFacultyDocuments?: Array<CompanyDocument> | null;
    /**
     * 
     * @type {Array<CompanyUser>}
     * @memberof Company
     */
    users?: Array<CompanyUser> | null;
    /**
     * 
     * @type {Array<CompanyPracticeSupervisor>}
     * @memberof Company
     */
    companyPracticeSupervisors?: Array<CompanyPracticeSupervisor> | null;
    /**
     * 
     * @type {Array<Faculty>}
     * @memberof Company
     */
    facultiesOfInterest?: Array<Faculty> | null;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'commercialName': !exists(json, 'commercialName') ? undefined : json['commercialName'],
        'cui': !exists(json, 'cui') ? undefined : json['cui'],
        'emailContact': !exists(json, 'emailContact') ? undefined : json['emailContact'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'domainOfActivity': !exists(json, 'domainOfActivity') ? undefined : json['domainOfActivity'],
        'areasOfInterest': !exists(json, 'areasOfInterest') ? undefined : json['areasOfInterest'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'siteUrl': !exists(json, 'siteUrl') ? undefined : json['siteUrl'],
        'linkedInUrl': !exists(json, 'linkedInUrl') ? undefined : json['linkedInUrl'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'imageFileName': !exists(json, 'imageFileName') ? undefined : json['imageFileName'],
        'isContact': !exists(json, 'isContact') ? undefined : json['isContact'],
        'state': !exists(json, 'state') ? undefined : CompanyStateEnumFromJSON(json['state']),
        'preferredLanguage': !exists(json, 'preferredLanguage') ? undefined : PreferredLanguageEnumFromJSON(json['preferredLanguage']),
        'universityAccords': !exists(json, 'universityAccords') ? undefined : (json['universityAccords'] === null ? null : (json['universityAccords'] as Array<any>).map(CompanyUniversityAccordFromJSON)),
        'companyInternships': !exists(json, 'companyInternships') ? undefined : (json['companyInternships'] === null ? null : (json['companyInternships'] as Array<any>).map(CompanyInternshipFromJSON)),
        'companyFacultyAccords': !exists(json, 'companyFacultyAccords') ? undefined : (json['companyFacultyAccords'] === null ? null : (json['companyFacultyAccords'] as Array<any>).map(CompanyFacultyAccordFromJSON)),
        'companyFacultyDocuments': !exists(json, 'companyFacultyDocuments') ? undefined : (json['companyFacultyDocuments'] === null ? null : (json['companyFacultyDocuments'] as Array<any>).map(CompanyDocumentFromJSON)),
        'users': !exists(json, 'users') ? undefined : (json['users'] === null ? null : (json['users'] as Array<any>).map(CompanyUserFromJSON)),
        'companyPracticeSupervisors': !exists(json, 'companyPracticeSupervisors') ? undefined : (json['companyPracticeSupervisors'] === null ? null : (json['companyPracticeSupervisors'] as Array<any>).map(CompanyPracticeSupervisorFromJSON)),
        'facultiesOfInterest': !exists(json, 'facultiesOfInterest') ? undefined : (json['facultiesOfInterest'] === null ? null : (json['facultiesOfInterest'] as Array<any>).map(FacultyFromJSON)),
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'commercialName': value.commercialName,
        'cui': value.cui,
        'emailContact': value.emailContact,
        'address': value.address,
        'city': value.city,
        'country': value.country,
        'phone': value.phone,
        'domainOfActivity': value.domainOfActivity,
        'areasOfInterest': value.areasOfInterest,
        'fax': value.fax,
        'siteUrl': value.siteUrl,
        'linkedInUrl': value.linkedInUrl,
        'description': value.description,
        'logo': value.logo,
        'imageFileName': value.imageFileName,
        'isContact': value.isContact,
        'state': CompanyStateEnumToJSON(value.state),
        'preferredLanguage': PreferredLanguageEnumToJSON(value.preferredLanguage),
        'universityAccords': value.universityAccords === undefined ? undefined : (value.universityAccords === null ? null : (value.universityAccords as Array<any>).map(CompanyUniversityAccordToJSON)),
        'companyInternships': value.companyInternships === undefined ? undefined : (value.companyInternships === null ? null : (value.companyInternships as Array<any>).map(CompanyInternshipToJSON)),
        'companyFacultyAccords': value.companyFacultyAccords === undefined ? undefined : (value.companyFacultyAccords === null ? null : (value.companyFacultyAccords as Array<any>).map(CompanyFacultyAccordToJSON)),
        'companyFacultyDocuments': value.companyFacultyDocuments === undefined ? undefined : (value.companyFacultyDocuments === null ? null : (value.companyFacultyDocuments as Array<any>).map(CompanyDocumentToJSON)),
        'users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(CompanyUserToJSON)),
        'companyPracticeSupervisors': value.companyPracticeSupervisors === undefined ? undefined : (value.companyPracticeSupervisors === null ? null : (value.companyPracticeSupervisors as Array<any>).map(CompanyPracticeSupervisorToJSON)),
        'facultiesOfInterest': value.facultiesOfInterest === undefined ? undefined : (value.facultiesOfInterest === null ? null : (value.facultiesOfInterest as Array<any>).map(FacultyToJSON)),
    };
}

