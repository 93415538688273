import { Form, Modal, Select } from "antd";
import CustomModalFooter from "../../../../../../Containers/CustomModalFooter";
import CustomForm from "../../../../../../CustomComponents/CustomForm";
import { useTranslation } from "react-i18next";
import { SelectDocumentTypeModalProps } from "./SelectDocumentTypeModal.types";
import i18n from "../../../../../../i18n";

export const SelectDocumentTypeModal = (
	props: SelectDocumentTypeModalProps
) => {
	const { t } = useTranslation();
	return (
		<Modal
			visible={props.visible}
			onOk={props.onOk}
			onCancel={props.onCancel}
			title={props.title}
			width={
				window.innerWidth > 800
					? "35%"
					: window.innerWidth < 450
					? "80%"
					: "70%"
			}
			footer={
				<CustomModalFooter
					handleClose={props.onClose}
					handleSave={props.onSave}
					confirmButtonName={t("account.confirm")}
				/>
			}
		>
			<CustomForm form={props.form} layout="vertical">
				<Form.Item
					label={t("documents.selectDocumentType")}
					name="documentType"
					initialValue={props.defaultValue}
				>
					<Select
						onChange={props.onChange}
						options={[
							{ label: t("documents.pdf"), value: "PDF" },
							{ label: t("documents.word"), value: "DOCX" },
						]}
						allowClear={false}
						style={{ width: "auto" }}
					/>
				</Form.Item>
				<Form.Item
					label={t("documents.selectDepartment")}
					name="department"
					initialValue={props.defaultDepartmentValue}
				>
					<Select
						onChange={props.onDepartmentChange}
						allowClear={false}
						style={{ width: "100%" }}
					>
						{props.departments?.map((d) => (
							<Select.Option key={d.id} value={d.id}>
								{i18n.language === "en"
									? d.departmentNameEn
									: d.departmentNameRo}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</CustomForm>
		</Modal>
	);
};
