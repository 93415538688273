import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
	useIsAdministrativeAccordsResponsible,
	useIsDean,
	useIsFacultyContactsResponsible,
	useIsRector,
} from "../../../utils/utilFunctions";
import {
	deleteCompanyUser,
	getAllCompanyUsers,
} from "../../../Requests/company-users-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import CustomButton from "../../../CustomComponents/CustomButton";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQuery, useQueryClient } from "react-query";
import { getCompanyUsers } from "../../../utils/reactQueriesConstants";
import { Row, Table } from "antd";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { CompanyUserDTO } from "../../../Api";
import { FilterByTextInput } from "../../BaseComponents";
import { usePagination } from "../../../Hooks";
import { AddUpdateCompanyUserModalForm } from "../../Forms";
import { CompanyContactViewProps } from "./CompanyContactView.types";

export const CompanyContactsView = (props: CompanyContactViewProps) => {
	const { t } = useTranslation();
	const { currentPage, pageSize, handleTablePaginationChange } = usePagination(
		1,
		10
	);
	const [searchTerm, setSearchTerm] = useState("");
	const { companyId } = useParams<{ companyId: string }>();
	const [selectedUser, setSelectedUser] = useState<CompanyUserDTO | null>(null);
	const [removeUserModalVisibility, setRemoveUserModalVisibility] =
		useState(false);
	const [spinning, setSpinning] = useState(false);
	const isRector = useIsRector();
	const isDean = useIsDean();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const isFacultyContactsResponsible = useIsFacultyContactsResponsible();

	const locale = {
		emptyText: t("tableText.noCompanies"),
	};

	const queryClient = useQueryClient();
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getCompanyUsers);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteCompanyUser(id)
			.then(() => {
				invalidateUsersQuery();

				openNotification(
					t("account.userDeleted"),
					t("account.userDeletedMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
					openNotification(
						t("account.conflict"),
						t("account.notFoundUser"),
						NOTIFICATION_TYPES.ERROR
					);
				} else if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
					openNotification(
						t("account.error"),
						t("account.notAllowed"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
				setRemoveUserModalVisibility(false);
			});
	};

	const columns = [
		{
			title: (
				<FilterByTextInput
					placeholder={t("companies.name")}
					onSearch={setSearchTerm}
					style={{ width: 200 }}
				/>
			),
			dataIndex: "name",
			key: "name",
		},
		{
			title: t("account.email"),
			dataIndex: "email",
			key: "email",
		},
		{
			title: t("account.phone"),
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: t("account.function"),
			dataIndex: "_function",
			key: "function",
		},
		{
			title: t("account.department"),
			dataIndex: "department",
			key: "department",
		},
		{
			title: t("account.actions"),
			render: (_text: string | undefined, record: any) => (
				<div style={{ display: "inline-flex" }}>
					{record.isContact && (
						<AddUpdateCompanyUserModalForm type={"edit"} userId={record.id} />
					)}
					{record.isContact && (
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: "transparent",
								border: "none",
								outline: "none",
								color: theme.black,
								boxShadow: "none",
								padding: 0,
								marginTop: 0,
							}}
							onClick={() => {
								setSelectedUser(record);
								setRemoveUserModalVisibility(true);
							}}
							icon={<FontAwesomeIcon icon={solid("trash")} />}
							title={t("account.deleteUser")}
						/>
					)}
				</div>
			),
		},
	];

	const { data: users, isLoading } = useQuery(
		[getCompanyUsers, searchTerm, currentPage, pageSize],
		() => {
			return getAllCompanyUsers(
				searchTerm,
				currentPage,
				pageSize,
				companyId
			).catch(() =>
				openNotification(
					t("account.error"),
					t("usersText.errorTexts.failedUserGet"),
					NOTIFICATION_TYPES.ERROR
				)
			);
		}
	);

	return (
		<>
			<ConfirmationModal
				modalText={t("account.deleteUserMessage") + selectedUser?.name + "?"}
				handleFunction={() => handleDelete(selectedUser?.id ?? "")}
				modalVisibility={removeUserModalVisibility}
				title={selectedUser?.name ?? ""}
				changeModalVisibility={() => setRemoveUserModalVisibility(false)}
				spinning={spinning}
			/>
			{(isRector ||
				isAdministrativeAccordsResponsible ||
				isDean ||
				isFacultyContactsResponsible) && (
				<div style={{ width: "100%" }}>
					<Row
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<AddUpdateCompanyUserModalForm type={"add"} userId={companyId} />
						{props.location && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginTop: "4%",
								}}
							>
								<Link
									to={{
										pathname: "/companii-contact",
										state: {
											existingFilters: props.location.state.filters,
											oldSearchTerm: props.location.state.searchTerm,
											currentPage: props.location.state.currentPage,
											pageSize: props.location.state.pageSize,
										},
									}}
								>
									<CustomButton
										paddingvertical={"19px"}
										boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
									>
										{t("profile.goBack")}
									</CustomButton>
								</Link>
							</div>
						)}
					</Row>
					<Row style={{ width: "100%" }}>
						<Table
							style={{ width: "100%" }}
							locale={locale}
							dataSource={users?.data ?? new Array<CompanyUserDTO>()}
							columns={columns}
							pagination={{
								total: users?.totalCount,
								current: users?.page,
								pageSize: users?.pageSize,
								pageSizeOptions: ["10", "20", "50"],
								showSizeChanger: true,
								locale: { items_per_page: t("configuration.pagination") },
								position: ["topRight"],
								hideOnSinglePage: users?.totalCount
									? users?.totalCount <= 10
									: true,
							}}
							rowKey={(record) => record.id ?? ""}
							loading={isLoading}
							onChange={handleTablePaginationChange}
							scroll={{ x: 400 }}
						/>
					</Row>
				</div>
			)}
		</>
	);
};
