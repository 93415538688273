import styles from "../../../../Views/ApplicantsView/ApplicantsView.module.scss";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getInterns } from "../../../../../utils/reactQueriesConstants";
import { getInternsForEmployer } from "../../../../../Requests/internship-requests";
import { InternsForEmployerDTO, InternType } from "../../../../../Api";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../Notifications/NotificationsUtils";
import { usePagination } from "../../../../../Hooks";
import { InternshipMyStudentsTableProps } from "./InternshipMyStudentsTable.types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { studentIdSet } from "../../../../Student/StudentSlice";

export const InternshipMyStudentsTable = (
	props: InternshipMyStudentsTableProps
) => {
	const { t, i18n } = useTranslation();
	const { currentPage, pageSize, handleTablePaginationChange } = usePagination(
		1,
		10
	);
	const dispatch = useDispatch();
	const history = useHistory();

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	let locale = {
		emptyText: t("tableText.noApplicants"),
	};

	const columns = [
		{
			title: t("students.name"),
			key: "name",
			dataIndex: "name",
			render: (text: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<a onClick={() => goToStudentProfile(record.id)}>
						<b>{text}</b>
					</a>
				);
			},
		},
		{
			title: t("students.group"),
			key: "group",
			dataIndex: "group",
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			render: (_: string | undefined, record: InternsForEmployerDTO) => (
				<span>
					{record.personalEmail !== "" && record.personalEmail !== undefined
						? record.personalEmail
						: record.email}
				</span>
			),
		},
	];

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[
			getInterns,
			InternType.Trainee,
			currentPage,
			pageSize,
			i18n.language,
			props.internship.internshipName,
		],
		() => {
			return getInternsForEmployer(
				InternType.Trainee,
				undefined,
				currentPage,
				pageSize,
				[props.internship.internshipName]
			);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const getKey = (record: any): string => {
		return record.id + record.internshipId;
	};

	return (
		<Table
			style={{ marginTop: 16 }}
			locale={locale}
			dataSource={data?.data ?? []}
			columns={columns}
			pagination={{
				total: data?.totalCount,
				current: data?.page,
				pageSize: data?.pageSize,
				pageSizeOptions: ["10", "20", "50"],
				defaultPageSize: 10,
				hideOnSinglePage: data?.totalCount ? data?.totalCount <= 10 : true,
				showSizeChanger: true,
				locale: { items_per_page: t("configuration.pagination") },
				position: ["topRight", "bottomRight"],
			}}
			rowKey={getKey}
			rowClassName={(record) =>
				!record.isEnrollmentViewed ? styles.customTableRow : ""
			}
			loading={loading && !data}
			onChange={handleTablePaginationChange}
		/>
	);
};
