/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmailRecipientDTOPagedResponse,
    EmailRecipientDTOPagedResponseFromJSON,
    EmailRecipientDTOPagedResponseToJSON,
    NewsletterEmailDTO,
    NewsletterEmailDTOFromJSON,
    NewsletterEmailDTOToJSON,
    NewsletterEmailDTOPagedResponse,
    NewsletterEmailDTOPagedResponseFromJSON,
    NewsletterEmailDTOPagedResponseToJSON,
    NewsletterSortFieldEnum,
    NewsletterSortFieldEnumFromJSON,
    NewsletterSortFieldEnumToJSON,
    PublishUpdateEmailDTO,
    PublishUpdateEmailDTOFromJSON,
    PublishUpdateEmailDTOToJSON,
    SortDirectionEnum,
    SortDirectionEnumFromJSON,
    SortDirectionEnumToJSON,
} from '../models';

export interface ApiNewsletterGetEmailByIdIdGetRequest {
    id: string;
}

export interface ApiNewsletterGetEmailsForPublisherGetRequest {
    searchTerm?: string;
    sortField?: NewsletterSortFieldEnum;
    sortDirection?: SortDirectionEnum;
    page?: number;
    pageSize?: number;
}

export interface ApiNewsletterGetRecipientsForEmailIdGetRequest {
    id: string;
    searchTerm?: string;
    sortField?: NewsletterSortFieldEnum;
    sortDirection?: SortDirectionEnum;
    page?: number;
    pageSize?: number;
}

export interface ApiNewsletterInvalidateEmailIdDeleteRequest {
    id: string;
}

export interface ApiNewsletterPublishEmailPostRequest {
    publishUpdateEmailDTO?: PublishUpdateEmailDTO;
}

export interface ApiNewsletterToggleExpeditionEnabledIdPutRequest {
    id: string;
}

export interface ApiNewsletterUpdateEmailIdPutRequest {
    id: string;
    publishUpdateEmailDTO?: PublishUpdateEmailDTO;
}

/**
 * 
 */
export class NewsletterApi extends runtime.BaseAPI {

    /**
     */
    async apiNewsletterGetEmailByIdIdGetRaw(requestParameters: ApiNewsletterGetEmailByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NewsletterEmailDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiNewsletterGetEmailByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Newsletter/GetEmailById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterEmailDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiNewsletterGetEmailByIdIdGet(requestParameters: ApiNewsletterGetEmailByIdIdGetRequest, initOverrides?: RequestInit): Promise<NewsletterEmailDTO> {
        const response = await this.apiNewsletterGetEmailByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNewsletterGetEmailsForPublisherGetRaw(requestParameters: ApiNewsletterGetEmailsForPublisherGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NewsletterEmailDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['SearchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['SortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['SortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Newsletter/GetEmailsForPublisher`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterEmailDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiNewsletterGetEmailsForPublisherGet(requestParameters: ApiNewsletterGetEmailsForPublisherGetRequest = {}, initOverrides?: RequestInit): Promise<NewsletterEmailDTOPagedResponse> {
        const response = await this.apiNewsletterGetEmailsForPublisherGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNewsletterGetRecipientsForEmailIdGetRaw(requestParameters: ApiNewsletterGetRecipientsForEmailIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmailRecipientDTOPagedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiNewsletterGetRecipientsForEmailIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['SearchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['SortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['SortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Newsletter/GetRecipientsForEmail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailRecipientDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiNewsletterGetRecipientsForEmailIdGet(requestParameters: ApiNewsletterGetRecipientsForEmailIdGetRequest, initOverrides?: RequestInit): Promise<EmailRecipientDTOPagedResponse> {
        const response = await this.apiNewsletterGetRecipientsForEmailIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNewsletterInvalidateEmailIdDeleteRaw(requestParameters: ApiNewsletterInvalidateEmailIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiNewsletterInvalidateEmailIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Newsletter/InvalidateEmail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiNewsletterInvalidateEmailIdDelete(requestParameters: ApiNewsletterInvalidateEmailIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiNewsletterInvalidateEmailIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiNewsletterPublishEmailPostRaw(requestParameters: ApiNewsletterPublishEmailPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Newsletter/PublishEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublishUpdateEmailDTOToJSON(requestParameters.publishUpdateEmailDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiNewsletterPublishEmailPost(requestParameters: ApiNewsletterPublishEmailPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiNewsletterPublishEmailPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiNewsletterToggleExpeditionEnabledIdPutRaw(requestParameters: ApiNewsletterToggleExpeditionEnabledIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiNewsletterToggleExpeditionEnabledIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Newsletter/ToggleExpeditionEnabled/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiNewsletterToggleExpeditionEnabledIdPut(requestParameters: ApiNewsletterToggleExpeditionEnabledIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiNewsletterToggleExpeditionEnabledIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiNewsletterUpdateEmailIdPutRaw(requestParameters: ApiNewsletterUpdateEmailIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiNewsletterUpdateEmailIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Newsletter/UpdateEmail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PublishUpdateEmailDTOToJSON(requestParameters.publishUpdateEmailDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiNewsletterUpdateEmailIdPut(requestParameters: ApiNewsletterUpdateEmailIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiNewsletterUpdateEmailIdPutRaw(requestParameters, initOverrides);
    }

}
