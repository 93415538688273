/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InternshipDocumentAddDTO
 */
export interface InternshipDocumentAddDTO {
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentAddDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentAddDTO
     */
    internshipEnrollmentId: string;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentAddDTO
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentAddDTO
     */
    bibliography?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipDocumentAddDTO
     */
    studentGroup?: string | null;
}

export function InternshipDocumentAddDTOFromJSON(json: any): InternshipDocumentAddDTO {
    return InternshipDocumentAddDTOFromJSONTyped(json, false);
}

export function InternshipDocumentAddDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipDocumentAddDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'internshipEnrollmentId': json['internshipEnrollmentId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'bibliography': !exists(json, 'bibliography') ? undefined : json['bibliography'],
        'studentGroup': !exists(json, 'studentGroup') ? undefined : json['studentGroup'],
    };
}

export function InternshipDocumentAddDTOToJSON(value?: InternshipDocumentAddDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'internshipEnrollmentId': value.internshipEnrollmentId,
        'description': value.description,
        'bibliography': value.bibliography,
        'studentGroup': value.studentGroup,
    };
}

