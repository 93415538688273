import { Col, List, Row, Spin } from "antd";
import { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { getNewsletterEmailData } from "../../../utils/reactQueriesConstants";
import CustomButton from "../../../CustomComponents/CustomButton";
import { theme } from "../../../theme";
import styles from "./NewsletterEmailInfoView.module.scss";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import {
	deleteNewsletterEmail,
	getNewsletterEmailById,
	toggleEmailExpeditionEnabled,
} from "../../../Requests/newsletter-requests";
import { EmailRecipientsTable } from "./components/tables/EmailRecipientsTable";
import EmailActions from "../NewsletterEmailsView/components/listItems/EmailListItem/EmailActions";

export const NewsletterEmailInfoView = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const location = useLocation<
		| {
				origin: string;
				filters: any;
				searchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
		  }
		| null
		| undefined
	>();
	const [spinning, setSpinning] = useState(false);
	const history = useHistory();
	const queryClient = useQueryClient();
	const [
		updateEmailExpeditionModalVisibility,
		setUpdateEmailExpeditionModalVisibility,
	] = useState(false);
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

	if (!id) {
		history.push("/");
	}

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: email } = useQuery(
		[getNewsletterEmailData, id],
		() => getNewsletterEmailById(id),
		{
			onError: (err) => {
				history.push("/404");
				openGetErrorNotification(err);
			},
		}
	);

	const handleToggleExpeditionEnabled = () => {
		toggleEmailExpeditionEnabled(email?.id!)
			.then(async () => {
				await queryClient.invalidateQueries(getNewsletterEmailData);
				openNotification(
					t("emails.updateEmail"),
					t("emails.updateExpeditionStatusSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error: any) => {
				openNotification(
					t("emails.updateEmail"),
					t("emails.updateExpeditionStatusError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setUpdateEmailExpeditionModalVisibility(false);
			});
	};

	const handleDelete = () => {
		deleteNewsletterEmail(email?.id!)
			.then(async () => {
				openNotification(
					t("emails.deleteEmail"),
					t("emails.deleteEmailSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error: any) => {
				openNotification(
					t("emails.deleteEmails"),
					t("emails.deleteEmailsError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setDeleteModalVisibility(false);

				let state = {
					searchTerm: location.state?.searchTerm,
					sortField: location.state?.sortField,
					sortDirection: location.state?.sortDirection,
					currentPage: location.state?.currentPage,
					pageSize: location.state?.pageSize,
				};

				history.push(location.state!.origin, state);
			});
	};

	return (
		<Spin size="large" spinning={!email} tip={t("internships.loading")}>
			{location.state && (
				<Row>
					<Col xs={17} sm={20} md={21} lg={22} xl={22} />
					<Col xs={7} sm={4} md={3} lg={2} xl={2}>
						<Link
							to={{
								pathname: location.state.origin,
								state: {
									searchTerm: location.state.searchTerm,
									sortField: location.state.sortField,
									sortDirection: location.state.sortDirection,
									currentPage: location.state.currentPage,
									pageSize: location.state.pageSize,
								},
							}}
						>
							<CustomButton
								paddingvertical={"19px"}
								boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
							>
								{t("profile.goBack")}
							</CustomButton>
						</Link>
					</Col>
				</Row>
			)}
			<div className={styles.center}>
				<div className={styles.title}>{email?.subject}</div>
				<List.Item
					className={styles.ListItem}
					key={email?.id + "email"}
					actions={[<EmailActions item={email!} />]}
				/>
				{location.state && (
					<div
						style={{
							display: "inline-flex",
							justifyContent: "center",
							alignItems: "baseline",
						}}
					>
						<div>
							<CustomButton
								shape="circle"
								type="text"
								title={t("emails.edit")}
								style={{
									background: "none",
									color: theme.black,
									boxShadow: "none",
									fontSize: "14px",
								}}
								icon={
									<FontAwesomeIcon
										icon={solid("edit")}
										style={{ paddingRight: "5%" }}
									/>
								}
							>
								<Link
									to={{
										pathname: "/editare-email/" + email?.id,
										state: {
											currentPage: location.state.currentPage,
											pageSize: location.state.pageSize,
											searchTerm: location.state.searchTerm,
											sortDirection: location.state.sortDirection,
											sortField: location.state.sortField,
										},
									}}
								>
									{t("emails.edit")}
								</Link>
							</CustomButton>
						</div>
						{email?.currentRecipientsCount! > 0 && (
							<div>
								<CustomButton
									shape="circle"
									type="text"
									title={
										email?.expeditionEnabled
											? t("emails.stopExpedition")
											: t("emails.restartExpedition")
									}
									style={{
										background: "none",
										color: theme.black,
										boxShadow: "none",
										fontSize: "14px",
									}}
									onClick={() => setUpdateEmailExpeditionModalVisibility(true)}
									icon={
										<FontAwesomeIcon
											icon={
												email?.expeditionEnabled ? solid("stop") : solid("play")
											}
											style={{ paddingRight: "5%" }}
										/>
									}
								>
									{email?.expeditionEnabled
										? t("emails.stopExpedition")
										: t("emails.restartExpedition")}
								</CustomButton>
								<ConfirmationModal
									modalText={
										email?.expeditionEnabled
											? t("emails.stopEmailExpeditionMessage")
											: t("emails.restartEmailExpeditionMessage")
									}
									handleFunction={() => {
										handleToggleExpeditionEnabled();
									}}
									modalVisibility={updateEmailExpeditionModalVisibility}
									title=""
									changeModalVisibility={() =>
										setUpdateEmailExpeditionModalVisibility(false)
									}
									spinning={spinning}
								/>
							</div>
						)}
						<div>
							<CustomButton
								shape="circle"
								type="text"
								title={t("emails.deleteEmail")}
								style={{
									background: "none",
									color: theme.black,
									boxShadow: "none",
									fontSize: "14px",
								}}
								onClick={() => setDeleteModalVisibility(true)}
								icon={
									<FontAwesomeIcon
										icon={solid("trash")}
										style={{ paddingRight: "5%" }}
									/>
								}
							>
								{t("emails.deleteEmail")}
							</CustomButton>
							<ConfirmationModal
								modalText={t("emails.deleteEmailMessage")}
								handleFunction={() => {
									handleDelete();
								}}
								modalVisibility={deleteModalVisibility}
								title=""
								changeModalVisibility={() => setDeleteModalVisibility(false)}
								spinning={spinning}
							/>
						</div>
					</div>
				)}
			</div>
			<div className={styles.subtitle}>{t("emails.publishEmailForm.body")}</div>
			<div
				className={styles.description}
				dangerouslySetInnerHTML={{ __html: email?.body || "" }}
			/>
			<div className={styles.subtitle}>{t("emails.expeditionReport")}</div>
			<EmailRecipientsTable emailId={email?.id} />
		</Spin>
	);
};
