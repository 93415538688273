/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PracticeCertificateState {
    NotApplicable = 'NotApplicable',
    NotGenerated = 'NotGenerated',
    InProgress = 'InProgress',
    PendingPracticeRepresentativeSignature = 'PendingPracticeRepresentativeSignature',
    PendingInternshipAdminValidation = 'PendingInternshipAdminValidation',
    Finalized = 'Finalized'
}

export function PracticeCertificateStateFromJSON(json: any): PracticeCertificateState {
    return PracticeCertificateStateFromJSONTyped(json, false);
}

export function PracticeCertificateStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeCertificateState {
    return json as PracticeCertificateState;
}

export function PracticeCertificateStateToJSON(value?: PracticeCertificateState | null): any {
    return value as any;
}

