import { useState } from "react";
import styles from "./EmailRecipientsTable.module.scss";
import { Col, Row, Table } from "antd";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../Notifications/NotificationsUtils";
import { getEmailRecipients } from "../../../../../utils/reactQueriesConstants";
import { EmailRecipientDTO } from "../../../../../Api";
import { getNewsletterEmailRecipients } from "../../../../../Requests/newsletter-requests";

export const EmailRecipientsTable = (props: {
	emailId: string | undefined;
}) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(1);

	let locale = {
		emptyText: t("tableText.noRecipients"),
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("emails.error"),
			t("emails.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: recipients, isLoading } = useQuery(
		[getEmailRecipients, props.emailId, page, pageSize],
		() =>
			props.emailId
				? getNewsletterEmailRecipients(props.emailId, page, pageSize)
				: null,
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "recipientName",
			key: "recipientName",
		},
		{
			title: t("account.email"),
			dataIndex: "recipientEmail",
			key: "recipientEmail",
		},
		{
			title: t("emails.status"),
			dataIndex: "status",
			key: "status",
			render: (_text: string | undefined, record: EmailRecipientDTO) =>
				t("emails.statuses." + record.status),
		},
	];

	const handleTableChange = (pagination: any) => {
		setPage(() => pagination.current);
		setPageSize(() => pagination.pageSize);
	};

	return (
		<Col
			span={24}
			className={styles.container}
			style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}
		>
			<Row>
				<Col span={24}>
					<Table
						locale={locale}
						className={styles.usersTable}
						dataSource={recipients?.data ?? new Array<EmailRecipientDTO>()}
						columns={columns}
						pagination={{
							total: recipients?.totalCount,
							current: recipients?.page,
							pageSize: recipients?.pageSize,
							pageSizeOptions: ["10", "20", "50"],
							showSizeChanger: true,
							locale: { items_per_page: t("configuration.pagination") },
							position: ["topRight"],
							hideOnSinglePage: recipients?.totalCount
								? recipients?.totalCount <= 10
								: true,
						}}
						rowKey={(record) => record.id ?? ""}
						loading={isLoading}
						onChange={handleTableChange}
						scroll={{ x: 400 }}
					/>
				</Col>
			</Row>
		</Col>
	);
};
