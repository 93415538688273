/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyReportDTO,
    CompanyReportDTOFromJSON,
    CompanyReportDTOFromJSONTyped,
    CompanyReportDTOToJSON,
} from './CompanyReportDTO';

/**
 * 
 * @export
 * @interface CompanyReportDTOPagedResponse
 */
export interface CompanyReportDTOPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof CompanyReportDTOPagedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyReportDTOPagedResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyReportDTOPagedResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<CompanyReportDTO>}
     * @memberof CompanyReportDTOPagedResponse
     */
    data?: Array<CompanyReportDTO> | null;
}

export function CompanyReportDTOPagedResponseFromJSON(json: any): CompanyReportDTOPagedResponse {
    return CompanyReportDTOPagedResponseFromJSONTyped(json, false);
}

export function CompanyReportDTOPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyReportDTOPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(CompanyReportDTOFromJSON)),
    };
}

export function CompanyReportDTOPagedResponseToJSON(value?: CompanyReportDTOPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'totalCount': value.totalCount,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(CompanyReportDTOToJSON)),
    };
}

