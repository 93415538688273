import Layout from "../../Containers/Layout";
import { DiplomaStudentsView } from "../Views";

export const DiplomaStudentsPage = (props: { location: any }) => {
	const initialInternshipName = props.location.state?.internshipName;

	return (
		<Layout>
			<DiplomaStudentsView initialInternshipName={initialInternshipName} />
		</Layout>
	);
};
