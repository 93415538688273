import Layout from "../../Containers/Layout";
import { NewsletterEmailsView } from "../Views";

export const NewsletterEmailsPage = (props: { location: any }) => {
	return (
		<Layout>
			<NewsletterEmailsView location={props.location} />
		</Layout>
	);
};
