import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { AddInternshipForm } from "../Forms/AddInternshipForm";
import Layout from "../../Containers/Layout";

export const AddInternshipFormPage = (props: { location: any }) => {
	const [redirect, setRedirect] = useState(false);
	const { internshipId } = useParams<{ internshipId?: string }>();

	if (redirect) {
		return (
			<Redirect
				to={{
					pathname: "/propuneri",
					state: {
						existingFilters: props.location.state?.filters,
						searchTerm: props.location.state?.searchTerm,
						sortField: props.location.state?.sortField,
						sortDirection: props.location.state?.sortDirection,
						currentPage: props.location.state?.currentPage,
						pageSize: props.location.state?.pageSize,
						activeTab: props.location.state?.activeTab,
					},
				}}
			/>
		);
	} else {
		return (
			<Layout>
				<AddInternshipForm
					location={props.location}
					setRedirect={setRedirect}
					internshipId={internshipId}
					isDuplicating={props.location.state?.isDuplicating}
				/>
			</Layout>
		);
	}
};
