/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiPracticeProtocolGenerateDocxProtocolFacultyIdGetRequest {
    facultyId: string;
}

export interface ApiPracticeProtocolGeneratePDFProtocolFacultyIdGetRequest {
    facultyId: string;
}

/**
 * 
 */
export class PracticeProtocolApi extends runtime.BaseAPI {

    /**
     */
    async apiPracticeProtocolGenerateDocxProtocolFacultyIdGetRaw(requestParameters: ApiPracticeProtocolGenerateDocxProtocolFacultyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.facultyId === null || requestParameters.facultyId === undefined) {
            throw new runtime.RequiredError('facultyId','Required parameter requestParameters.facultyId was null or undefined when calling apiPracticeProtocolGenerateDocxProtocolFacultyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeProtocol/GenerateDocxProtocol/{facultyId}`.replace(`{${"facultyId"}}`, encodeURIComponent(String(requestParameters.facultyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeProtocolGenerateDocxProtocolFacultyIdGet(requestParameters: ApiPracticeProtocolGenerateDocxProtocolFacultyIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeProtocolGenerateDocxProtocolFacultyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeProtocolGeneratePDFProtocolFacultyIdGetRaw(requestParameters: ApiPracticeProtocolGeneratePDFProtocolFacultyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.facultyId === null || requestParameters.facultyId === undefined) {
            throw new runtime.RequiredError('facultyId','Required parameter requestParameters.facultyId was null or undefined when calling apiPracticeProtocolGeneratePDFProtocolFacultyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeProtocol/GeneratePDFProtocol/{facultyId}`.replace(`{${"facultyId"}}`, encodeURIComponent(String(requestParameters.facultyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeProtocolGeneratePDFProtocolFacultyIdGet(requestParameters: ApiPracticeProtocolGeneratePDFProtocolFacultyIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeProtocolGeneratePDFProtocolFacultyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
