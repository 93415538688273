/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PracticeInternshipAllocationReportDTO,
    PracticeInternshipAllocationReportDTOFromJSON,
    PracticeInternshipAllocationReportDTOFromJSONTyped,
    PracticeInternshipAllocationReportDTOToJSON,
} from './PracticeInternshipAllocationReportDTO';

/**
 * 
 * @export
 * @interface PracticeInternshipAllocationReportDTOPagedResponse
 */
export interface PracticeInternshipAllocationReportDTOPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof PracticeInternshipAllocationReportDTOPagedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PracticeInternshipAllocationReportDTOPagedResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PracticeInternshipAllocationReportDTOPagedResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<PracticeInternshipAllocationReportDTO>}
     * @memberof PracticeInternshipAllocationReportDTOPagedResponse
     */
    data?: Array<PracticeInternshipAllocationReportDTO> | null;
}

export function PracticeInternshipAllocationReportDTOPagedResponseFromJSON(json: any): PracticeInternshipAllocationReportDTOPagedResponse {
    return PracticeInternshipAllocationReportDTOPagedResponseFromJSONTyped(json, false);
}

export function PracticeInternshipAllocationReportDTOPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeInternshipAllocationReportDTOPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(PracticeInternshipAllocationReportDTOFromJSON)),
    };
}

export function PracticeInternshipAllocationReportDTOPagedResponseToJSON(value?: PracticeInternshipAllocationReportDTOPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'totalCount': value.totalCount,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(PracticeInternshipAllocationReportDTOToJSON)),
    };
}

