import { Link, useLocation } from "react-router-dom";
import { theme } from "../../theme";
import {
	isUserLogged,
	safeAnd,
	useIsAdmin,
	useIsAdministrativeAccordsResponsible,
	useIsCompaniesFeedbackEnabled,
	useIsCompany,
	useIsDean,
	useIsDepartmentDirector,
	useIsFacultyAdmin,
	useIsFacultyContactsResponsible,
	useIsFeedbackAdmin,
	useIsInternshipAdmin,
	useIsNewsletterAdmin,
	useIsPhdStudentsFeedbackEnabled,
	useIsProfessor,
	useIsProfessorsFeedbackEnabled,
	useIsRector,
	useIsStudent,
	useIsStudentsFeedbackEnabled,
	useIsStudyProgramCoordinator,
	useIsUPBAdministrativeUser,
} from "../../utils/utilFunctions";
import { useTranslation } from "react-i18next";
import {
	getCountAllApplicationTypes,
	getCountDiplomaStudents,
	getCountPracticeStudents,
	getCountStudentsTypes,
} from "../../Requests/internship-requests";
import { useQuery } from "react-query";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Components/Notifications/NotificationsUtils";
import { getAllCompanyCounters } from "../../Requests/company-requests";
import {
	getAllApplicationTypesCount,
	getAllCompanyCount,
	getDiplomaStudentsCount,
	getPracticeStudentsCount,
	getStudentsTypesCount,
} from "../../utils/reactQueriesConstants";

const HeadersLinks = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const isCompany = useIsCompany();
	const isProfessor = useIsProfessor();
	const isStudent = useIsStudent();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDean = useIsDean();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isAdmin = useIsAdmin();
	const isNewsletterAdmin = useIsNewsletterAdmin();
	const isUPBAdministrativeUser = useIsUPBAdministrativeUser();
	const isRector = useIsRector();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const isFacultyContactsResponsible = useIsFacultyContactsResponsible();

	const { data: allApplicationTypes } = useQuery(
		[getAllApplicationTypesCount, isStudent],
		async () => (isStudent ? await getCountAllApplicationTypes() : null),
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.applicationsCountError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
		}
	);

	const { data: isStudentsFeedbackEnabled } = useIsStudentsFeedbackEnabled();
	const { data: isPhdStudentsFeedbackEnabled } =
		useIsPhdStudentsFeedbackEnabled();
	const { data: isCompaniesFeedbackEnabled } = useIsCompaniesFeedbackEnabled();
	const { data: isProfessorsFeedbackEnabled } =
		useIsProfessorsFeedbackEnabled();
	const isFeedbackAdmin = useIsFeedbackAdmin();

	const { data: companyCounters } = useQuery(
		[getAllCompanyCount, isCompany],
		async () => (isCompany ? await getAllCompanyCounters() : null),
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.companyCountersError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
		}
	);

	const { data: studentsTypes } = useQuery(
		[getStudentsTypesCount, isCompany, isProfessor],
		async () =>
			isCompany || isProfessor ? await getCountStudentsTypes() : null,
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.studentsCountError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: practiceStudents } = useQuery(
		[getPracticeStudentsCount, isInternshipAdmin],
		async () => (isInternshipAdmin ? await getCountPracticeStudents() : null),
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.studentsCountError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: diplomaStudents } = useQuery(
		[
			getDiplomaStudentsCount,
			isDean,
			isDepartmentDirector,
			isStudyProgramCoordinator,
		],
		async () =>
			isDean || isDepartmentDirector || isStudyProgramCoordinator
				? await getCountDiplomaStudents()
				: null,
		{
			onError: () => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("header.studentsCountError"),
					NOTIFICATION_TYPES.ERROR
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	return (
		<>
			{isUserLogged() &&
			(isCompany ||
				isProfessor ||
				isFacultyAdmin ||
				isRector ||
				isDean ||
				isInternshipAdmin ||
				isAdmin ||
				isStudyProgramCoordinator) ? (
				<Link
					to="/propuneri"
					style={{
						fontWeight: location.pathname === "/propuneri" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/propuneri"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.internships")}
				</Link>
			) : null}
			{isUserLogged() && (isCompany || isProfessor) ? (
				<Link
					to="/propuneri-cercetare"
					style={{
						fontWeight:
							location.pathname === "/propuneri-cercetare" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/propuneri-cercetare"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.researchOffers")}
				</Link>
			) : null}
			{isUserLogged() && isStudent ? (
				<Link
					to="/propuneri-facultati"
					style={{
						fontWeight:
							location.pathname === "/propuneri-facultati" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/propuneri-facultati"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.facultyInternships")}
				</Link>
			) : null}
			{isUserLogged() && isStudent ? (
				<Link
					to="/propuneri-companii"
					style={{
						fontWeight:
							location.pathname === "/propuneri-companii" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/propuneri-companii"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.companyInternships")}
				</Link>
			) : null}
			{isUserLogged() && isStudent ? (
				<Link
					to="/evenimente"
					style={{
						fontWeight: location.pathname === "/evenimente" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/evenimente"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.events")}
				</Link>
			) : null}
			{isUserLogged() &&
			isCompany &&
			companyCounters?.practiceProtocols !== undefined &&
			companyCounters?.practiceProtocols > 0 ? (
				<Link
					to="/tabel-studenti"
					style={{
						fontWeight:
							location.pathname === "/tabel-studenti" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/tabel-studenti"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.students")}
				</Link>
			) : null}
			{isUserLogged() && (isCompany || isProfessor || isInternshipAdmin) ? (
				<Link
					to="/aplicanti"
					style={{
						fontWeight: location.pathname === "/aplicanti" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/aplicanti"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.applicants")}
				</Link>
			) : null}
			{isUserLogged() &&
			isCompany &&
			studentsTypes?.trainees !== undefined &&
			studentsTypes.trainees > 0 ? (
				<Link
					to="/studentii-mei"
					style={{
						fontWeight:
							location.pathname === "/studentii-mei" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/studentii-mei"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.myStudents")}
				</Link>
			) : null}
			{safeAnd([
				isUserLogged(),
				!isUPBAdministrativeUser,
				!isAdmin,
				!isFacultyAdmin,
				!isDean,
				!isDepartmentDirector,
				!isStudent,
				!isCompany,
			]) ? (
				<Link
					to="/studenti"
					style={{
						fontWeight: location.pathname === "/studenti" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/studenti"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.students")}
				</Link>
			) : null}
			{isUserLogged() &&
			(isDean || isDepartmentDirector || isStudyProgramCoordinator) &&
			diplomaStudents !== null &&
			diplomaStudents !== undefined &&
			diplomaStudents > 0 ? (
				<Link
					to="/studenti-diploma"
					style={{
						fontWeight:
							location.pathname === "/studenti-diploma" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/studenti-diploma"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.diplomaStudents")}
				</Link>
			) : null}
			{isUserLogged() &&
			isInternshipAdmin &&
			practiceStudents !== undefined &&
			practiceStudents !== null &&
			practiceStudents > 0 ? (
				<Link
					to="/studenti-practica"
					style={{
						fontWeight:
							location.pathname === "/studenti-practica" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/studenti-practica"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.practiceStudents")}
				</Link>
			) : null}
			{isUserLogged() &&
			isStudent &&
			allApplicationTypes?.allApplications !== undefined &&
			allApplicationTypes.allApplications > 0 ? (
				<Link
					to="/aplicarile-mele"
					style={{
						fontWeight:
							location.pathname === "/aplicarile-mele" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/aplicarile-mele"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.myInternships")}
				</Link>
			) : null}
			{isUserLogged() &&
			isStudent &&
			allApplicationTypes?.scholarshipApplication !== undefined &&
			allApplicationTypes.scholarshipApplication > 0 ? (
				<Link
					to="/bursele-mele"
					style={{
						fontWeight:
							location.pathname === "/bursele-mele" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/bursele-mele"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.myScholarships")}
				</Link>
			) : null}
			{isUserLogged() &&
			isStudent &&
			allApplicationTypes?.bachelorInternshipApplications !== undefined &&
			allApplicationTypes.bachelorInternshipApplications > 0 ? (
				<Link
					to="/lucrare-licenta"
					style={{
						fontWeight:
							location.pathname === "/lucrare-licenta" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/lucrare-licenta"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.myBachelorThesis")}
				</Link>
			) : null}
			{isUserLogged() &&
			isStudent &&
			allApplicationTypes?.masterInternshipApplications !== undefined &&
			allApplicationTypes.masterInternshipApplications > 0 ? (
				<Link
					to="/tema-cercetare"
					style={{
						fontWeight:
							location.pathname === "/tema-cercetare" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/tema-cercetare"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.myResearchThesis")}
				</Link>
			) : null}
			{isUserLogged() &&
			isStudent &&
			allApplicationTypes?.internshipApplications !== undefined &&
			allApplicationTypes.internshipApplications > 0 ? (
				<Link
					to="/stagiu-practica"
					style={{
						fontWeight:
							location.pathname === "/stagiu-practica" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/stagiu-practica"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.myInternship")}
				</Link>
			) : null}
			{isUserLogged() &&
			isStudent &&
			allApplicationTypes?.otherApplications !== undefined &&
			allApplicationTypes.otherApplications > 0 ? (
				<Link
					to="/alte-pozitii"
					style={{
						fontWeight:
							location.pathname === "/alte-pozitii" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/alte-pozitii"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.otherPositions")}
				</Link>
			) : null}
			{isUserLogged() &&
			isStudent &&
			allApplicationTypes?.oldApplications !== undefined &&
			allApplicationTypes.oldApplications > 0 ? (
				<Link
					to="/inrolari-anterioare"
					style={{
						fontWeight:
							location.pathname === "/inrolari-anterioare" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/inrolari-anterioare"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.previousEnrollments")}
				</Link>
			) : null}
			{isUserLogged() &&
			(isAdmin ||
				isFacultyAdmin ||
				isRector ||
				isDepartmentDirector ||
				isDean) ? (
				<Link
					to="/utilizatori"
					style={{
						fontWeight:
							location.pathname === "/utilizatori" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/utilizatori"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.users")}
				</Link>
			) : null}
			{isUserLogged() && (isCompany || isUPBAdministrativeUser) ? (
				<Link
					to="/acorduri-universitate"
					style={{
						fontWeight:
							location.pathname === "/acorduri-universitate"
								? "bold"
								: "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/acorduri-universitate"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.universityAccords")}
				</Link>
			) : null}
			{isUserLogged() &&
				(isDean || isDepartmentDirector || isStudyProgramCoordinator) && (
					<Link
						to="/rapoarte"
						style={{
							fontWeight: location.pathname === "/rapoarte" ? "bold" : "normal",
							color:
								window.innerWidth > 1720
									? location.pathname === "/rapoarte"
										? theme.primaryColor
										: "black"
									: theme.secondColor,
							fontSize: window.innerWidth > 1720 ? "125%" : "20px",
							padding: window.innerWidth > 1720 ? "0" : "5px",
						}}
					>
						{t("header.reports")}
					</Link>
				)}
			{isUserLogged() &&
				(isDean ||
					isFacultyContactsResponsible ||
					isRector ||
					isAdministrativeAccordsResponsible) && (
					<Link
						to="/companii-contact"
						style={{
							fontWeight:
								location.pathname === "/companii-contact" ? "bold" : "normal",
							color:
								window.innerWidth > 1720
									? location.pathname === "/companii-contact"
										? theme.primaryColor
										: "black"
									: theme.secondColor,
							fontSize: window.innerWidth > 1720 ? "125%" : "20px",
							padding: window.innerWidth > 1720 ? "0" : "5px",
						}}
					>
						{t("header.companies")}
					</Link>
				)}
			{isUserLogged() && isNewsletterAdmin && (
				<Link
					to="/newsletter"
					style={{
						fontWeight: location.pathname === "/newsletter" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/newsletter"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.newsletter")}
				</Link>
			)}
			{isUserLogged() && isStudentsFeedbackEnabled?.value && (
				<Link
					to="/students-feedback"
					style={{
						fontWeight:
							location.pathname === "/students-feedback" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/students-feedback"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.feedback")}
				</Link>
			)}
			{isUserLogged() && isPhdStudentsFeedbackEnabled?.value && (
				<Link
					to="/phd-feedback"
					style={{
						fontWeight:
							location.pathname === "/phd-feedback" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/phd-feedback"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.feedback")}
				</Link>
			)}
			{isUserLogged() && isProfessorsFeedbackEnabled?.value && (
				<Link
					to="/professors-feedback"
					style={{
						fontWeight:
							location.pathname === "/professors-feedback" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/professors-feedback"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.feedback")}
				</Link>
			)}
			{isUserLogged() && isCompaniesFeedbackEnabled?.value && (
				<Link
					to="/companies-feedback"
					style={{
						fontWeight:
							location.pathname === "/companies-feedback" ? "bold" : "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/companies-feedback"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.feedback")}
				</Link>
			)}
			{isUserLogged() && isFeedbackAdmin && (
				<Link
					to="/feedback-flags-administration"
					style={{
						fontWeight:
							location.pathname === "/feedback-flags-administration"
								? "bold"
								: "normal",
						color:
							window.innerWidth > 1720
								? location.pathname === "/feedback-flags-administration"
									? theme.primaryColor
									: "black"
								: theme.secondColor,
						fontSize: window.innerWidth > 1720 ? "125%" : "20px",
						padding: window.innerWidth > 1720 ? "0" : "5px",
					}}
				>
					{t("header.feedbackFlags")}
				</Link>
			)}
		</>
	);
};

export default HeadersLinks;
