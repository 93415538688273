import { Button, Modal, Input, Spin } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectStudent, selectStudentId } from "./StudentSlice";
import {
	addAppreciation,
	deleteAppreciation,
	getAppreciations,
	updateAppreciation,
} from "../../Requests/appreciations-requests";
import { StudentAppreciationDTO } from "../../Api";
import { getUserId } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import styles from "./Student.module.scss";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import CustomForm from "../../CustomComponents/CustomForm";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { useQuery, useQueryClient } from "react-query";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import CustomButton from "../../CustomComponents/CustomButton";
import { getStudentAppreciations } from "../../utils/reactQueriesConstants";
import CustomEditor from "../../CustomComponents/CustomEditor";

const AddUpdateAppreciationModal = () => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const student = useSelector(selectStudent);
	const studentId = useSelector(selectStudentId);
	const [form] = Form.useForm<{
		title: string;
		message: string;
	}>();
	const queryClient = useQueryClient();
	const [modalVisibility, setModalVisibility] = useState(false);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("studentPersonalData.error"),
			t("studentPersonalData.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: studentAppreciations } = useQuery(
		[getStudentAppreciations, student],
		() => getAppreciations(studentId!),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const getOwnAppreciation = (
		studentAppreciations?: StudentAppreciationDTO[]
	) => {
		const filtered = studentAppreciations?.filter(
			(x) => x.professorId === getUserId()
		);

		if (filtered) return filtered[0];
	};

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const handleAdd = () => {
		setSpinning(true);

		addAppreciation({ studentId: student?.id, ...form.getFieldsValue() })
			.then(async () => {
				await queryClient.invalidateQueries(getStudentAppreciations);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.CONFLICT) {
					openNotification(
						t("account.conflict"),
						t("account.alreadySentRecommendation"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const handleUpdate = () => {
		setSpinning(true);

		updateAppreciation({ studentId: student?.id, ...form.getFieldsValue() })
			.then(async () => {
				await queryClient.invalidateQueries(getStudentAppreciations);
			})
			.catch((_ex: any) => {
				openNotification(
					t("account.error"),
					t("account.unknownError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const handleDelete = (item: StudentAppreciationDTO) => () => {
		setSpinning(true);

		deleteAppreciation(item.id!)
			.then(async () => {
				await queryClient.invalidateQueries(getStudentAppreciations);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
					openNotification(
						t("account.error"),
						t("account.alreadyDeletedRecommendation"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
				setModalVisibility(false);
			});
	};

	return (
		<div style={{ paddingBottom: 20 }}>
			<Button
				className={styles.button}
				style={{ float: "right", fontSize: "100%", fontFamily: "Montserrat" }}
				onClick={handleShow}
			>
				{!getOwnAppreciation(studentAppreciations)
					? t("account.addRecommendation")
					: t("account.editRecommendation")}
			</Button>
			{getOwnAppreciation(studentAppreciations) && (
				<>
					<CustomButton
						fontSize={"0.9rem"}
						style={{
							float: "right",
							background: theme.primaryColor,
							color: theme.white,
							marginTop: "0%",
							boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
						}}
						onClick={() => setModalVisibility(true)}
					>
						{t("account.deleteRecommendation")}
					</CustomButton>
					<ConfirmationModal
						modalText={t("account.deleteRecommendationMessage")}
						handleFunction={handleDelete(
							getOwnAppreciation(studentAppreciations)!
						)}
						modalVisibility={modalVisibility}
						title=""
						changeModalVisibility={() => setModalVisibility(false)}
						spinning={spinning}
					/>
				</>
			)}
			<Modal
				visible={isModalVisible}
				onOk={() => form.submit()}
				onCancel={handleClose}
				title={t("account.addRecommendation")}
				width={window.innerWidth > 1215 ? "40%" : "80%"}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={() => form.submit()}
						confirmButtonName={t("account.save")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<Form.Provider
						onFormFinish={
							!getOwnAppreciation(studentAppreciations)
								? handleAdd
								: handleUpdate
						}
					>
						<CustomForm layout={theme.layout} form={form}>
							<Form.Item
								initialValue={
									getOwnAppreciation(studentAppreciations)?.title || ""
								}
								name="title"
								label={`${t("account.title")}:`}
							>
								<Input placeholder={t("account.title")} />
							</Form.Item>
							<Form.Item
								initialValue={
									getOwnAppreciation(studentAppreciations)?.message || ""
								}
								name="message"
								label={`${t("account.description")}:`}
								rules={[
									{
										required: true,
										message: t("account.requiredField", {
											field: t("account.description"),
										}),
										whitespace: true,
									},
								]}
							>
								<CustomEditor
									onEditorChange={(message: string) =>
										form.setFieldsValue({ ...form.getFieldsValue(), message })
									}
								/>
							</Form.Item>
						</CustomForm>
					</Form.Provider>
				</Spin>
			</Modal>
		</div>
	);
};

export default AddUpdateAppreciationModal;
