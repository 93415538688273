import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	Row,
	Select,
	Typography,
} from "antd";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import styles from "./FacultySettingsForm.module.scss";
import { theme } from "../../../theme";
import CustomButton from "../../../CustomComponents/CustomButton";
import {
	userYearsBachelor,
	professorInternshipTypes,
	dateFormatList,
} from "../../../utils/constants";
import { useFacultySettingsFormController } from "./FacultySettingsForm.controller";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;

export const FacultySettingsForm = () => {
	const { t, i18n } = useTranslation();
	const { state, actions } = useFacultySettingsFormController();

	return (
		<div className={styles.container}>
			<Form
				form={state.form}
				onFinish={actions.onFinish}
				style={{ width: "90%", margin: "auto" }}
			>
				<Typography style={{ textAlign: "left" }}>
					{t("facultySettings.setPracticePeriods")}:
				</Typography>
				<Form.List name="practiceInternshipPeriods">
					{(fields, { add, remove }, { errors }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<Row gutter={8}>
									<Col span={6}>
										<Form.Item
											{...restField}
											name={[name, "practiceYear"]}
											rules={[
												{
													required: true,
													message: t("facultySettings.missingYear"),
												},
											]}
										>
											<Select disabled={!state.editEnabled}>
												{userYearsBachelor.map((year) => (
													<Option key={"year" + year} value={year}>
														{year}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											{...restField}
											name={[name, "practiceEvaluationStart"]}
											rules={[
												{
													required: true,
													message: t("facultySettings.missingStartDate"),
												},
											]}
										>
											<DatePicker
												format={dateFormatList}
												style={{ width: "100%" }}
												value={moment(
													state.form.getFieldValue(
														"practiceInternshipPeriods.practiceEvaluationStart"
													)
												)}
												disabled={!state.editEnabled}
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											{...restField}
											name={[name, "practiceEvaluationEnd"]}
											rules={[
												{
													required: true,
													message: t("facultySettings.missingEndDate"),
												},
											]}
										>
											<DatePicker
												format={dateFormatList}
												style={{ width: "100%" }}
												disabled={!state.editEnabled}
											/>
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item
											name={[name, "practiceNumberOfHours"]}
											rules={[
												{
													required: true,
													message: t("facultySettings.missingNumberOfHours"),
												},
											]}
										>
											<Input
												disabled={!state.editEnabled}
												placeholder={t(
													"facultySettings.numberOfHoursPlaceholder"
												)}
											></Input>
										</Form.Item>
									</Col>
									<Col span={1}>
										{state.editEnabled && (
											<MinusCircleOutlined onClick={() => remove(name)} />
										)}
									</Col>
								</Row>
							))}
							{state.editEnabled && (
								<Row gutter={8}>
									<Col span={23}>
										<Form.Item>
											<Button
												type="dashed"
												onClick={() => add()}
												block
												icon={<PlusOutlined />}
											>
												{t("facultySettings.addPracticeLimit")}
											</Button>
										</Form.Item>
									</Col>
								</Row>
							)}
						</>
					)}
				</Form.List>

				<Typography style={{ textAlign: "left" }}>
					{t("facultySettings.setInternshipLimits")}:
				</Typography>
				<Form.List name="internshipLimits">
					{(fields, { add, remove }, { errors }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<div>
									<Row gutter={8}>
										<Col span={12}>
											<Form.Item
												{...restField}
												name={[name, "internshipType"]}
												rules={[
													{
														required: true,
														message: t("facultySettings.missingOfferType"),
													},
												]}
											>
												<Select disabled={!state.editEnabled}>
													{professorInternshipTypes.map((type) => (
														<Option key={"type" + type} value={type}>
															{t("internships.filters." + type)}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col span={11}>
											<Form.Item
												{...restField}
												name={[name, "maximumApplicants"]}
												rules={[
													{
														required: true,
														message: t(
															"facultySettings.missingApplicantsLimit"
														),
													},
												]}
											>
												<Input
													disabled={!state.editEnabled}
													placeholder={t(
														"facultySettings.maximumApplicantsPlaceholder"
													)}
												></Input>
											</Form.Item>
										</Col>
										<Col span={1}>
											{state.editEnabled && (
												<MinusCircleOutlined onClick={() => remove(name)} />
											)}
										</Col>
									</Row>
								</div>
							))}
							{state.editEnabled && (
								<Row gutter={8}>
									<Col span={23}>
										<Form.Item>
											<Button
												type="dashed"
												onClick={() => add()}
												block
												icon={<PlusOutlined />}
											>
												{t("facultySettings.addApplicantsLimit")}
											</Button>
										</Form.Item>
									</Col>
								</Row>
							)}
						</>
					)}
				</Form.List>
				<Row gutter={8}>
					<Col span={23}>
						<Form.Item
							label={t("facultySettings.decisionNumber")}
							name="decisionNumber"
						>
							<Input
								disabled={!state.editEnabled}
								placeholder={t("facultySettings.decisionNumberPlaceholder")}
							></Input>
						</Form.Item>
					</Col>
				</Row>
				{state.editEnabled && (
					<Row>
						<CustomButton
							htmlType="submit"
							fontSize={"0.9rem"}
							type={"primary"}
							style={{
								background: theme.secondColor,
								color: theme.white,
								marginTop: "0%",
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
						>
							{t("facultySettings.save")}
						</CustomButton>
						<CustomButton
							onClick={actions.handleCancel}
							fontSize={"0.9rem"}
							type={"primary"}
							style={{
								background: theme.green,
								color: theme.white,
								marginTop: "0%",
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
						>
							{t("facultySettings.cancel")}
						</CustomButton>
					</Row>
				)}
				{!state.editEnabled && (
					<CustomButton
						onClick={actions.handleEdit}
						fontSize={"0.9rem"}
						type={"primary"}
						style={{
							background: theme.secondColor,
							color: theme.white,
							marginRight: "10%",
							marginTop: "0%",
							boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
						}}
					>
						{t("facultySettings.edit")}
					</CustomButton>
				)}
			</Form>
		</div>
	);
};
