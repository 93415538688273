import {
	Avatar,
	Button,
	Checkbox,
	Col,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Table,
	TablePaginationConfig,
	Typography,
	Upload,
} from "antd";
import Layout from "../../Containers/Layout";
import { getProfessorById } from "../../Requests/academic-user-requests";
import { StatusCodes } from "http-status-codes";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	useGetCompanyId,
	getUserId,
	useIsProfessor,
	useIsCompany,
	isUserLogged,
	useIsFacultyAdmin,
	useIsDean,
	useIsInternshipAdmin,
	getKey,
} from "../../utils/utilFunctions";
import { getCompanyById } from "../../Requests/company-requests";
import { Link, Redirect, useLocation } from "react-router-dom";
import { getCompanyUserById } from "../../Requests/company-users-requests";
import { useTranslation } from "react-i18next";
import {
	CompanyDocumentStatusEnum,
	ErrorCodes,
	InternshipType,
	ProfessionalTitleEnum,
	Role,
} from "../../Api";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButton from "../../CustomComponents/CustomButton";
import { useQuery, useQueryClient } from "react-query";
import {
	getCompany,
	getCompanyUser,
	getDocuments,
	getFaculties,
	getProfessor,
} from "../../utils/reactQueriesConstants";
import PracticeSupervisorTable from "./PracticeSupervisorTable";
import SupervisedStudyProgramsTable from "./SupervisedStudyProgramsTable";
import FacultiesOfInterestTable from "./FacultiesOfInterestTable";
import {
	addNewDocument,
	deleteDocument,
	downloadDocument,
	getCompanyDocuments,
	rejectDocument,
	updateSkipOfferValidationStatus,
} from "../../Requests/company-document-requests";
import { useState } from "react";
import { getAllFaculties } from "../../Requests/faculty-requests";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { getErrorFromResponse } from "../../utils/responseUtils";
import CustomEditor from "../../CustomComponents/CustomEditor";
import moment from "moment";
import styles from "./Profile.module.scss";
import { downloadFile } from "../../utils/downloadUtils";
import ConfirmationModal from "../../Containers/ConfirmationModal";

const Profile = () => {
	const { t, i18n } = useTranslation();
	const location = useLocation<
		| {
				id: string;
				type: InternshipType;
				userType: Role;
				filters: any;
				searchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
				origin: string;
		  }
		| null
		| undefined
	>();
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDean = useIsDean();
	const isInternshipAdmin = useIsInternshipAdmin();
	const companyId = useGetCompanyId();
	const [adminFaculties, setAdminFaculties] = useState<any>([]);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [file, setFile] = useState<Blob>();
	const [documentType, setDocumentType] = useState<any>(null);
	const [selectedFaculty, setSelectedFaculty] = useState(undefined);
	const [selectedDocumentId, setSelectedDocumentId] = useState<any>(undefined);
	const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
	const [rejectionMessage, setRejectionMessage] = useState("");
	const queryClient = useQueryClient();
	const [facultySearch, setFacultySearch] = useState("");
	const [facultyOptions, setFacultyOptions] = useState<any>([]);

	const returnAction = t("universityAccord.uploadedFile");

	const onCheckboxChange = (event: any, id: string) => {
		updateSkipOfferValidationStatus(id)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.changeStatus"),
					t("documents.statusChangedSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.statusChangedFailed"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const openProfileErrorNotification = (ex: any) => {
		if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
			openNotification(
				t("account.error"),
				t("account.notFoundProfile"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("account.error"),
				t("account.unknownError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const deleteCompanyDocument = (documentId: string) => {
		deleteDocument(documentId)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.deleteDocument"),
					t("documents.deleteSuccessfully"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.deleteError"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const columns = [
		{
			title: t("documents.documentType"),
			dataIndex: "documentType",
			key: "documentType",
			render: (text: string | undefined, record: any) => {
				return <span>{t("documents." + record.documentType)}</span>;
			},
		},
		{
			title: t("documents.faculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
		},
		{
			title: t("documents.uploadDate"),
			dataIndex: "generationDate",
			key: "generationDate",
			render: (text: string | undefined, record: any) => {
				return (
					<span>{moment(record.generationDate).format("DD.MM.YYYY")}</span>
				);
			},
		},
		{
			title: t("documents.expirationDate"),
			dataIndex: "expirationDate",
			key: "expirationDate",
			render: (text: string | undefined, record: any) => {
				return (
					<span>{moment(record.expirationDate).format("DD.MM.YYYY")}</span>
				);
			},
		},
		{
			title: t("documents.status"),
			dataIndex: "documentStatus",
			key: "documentStatus",
			render: (text: string | undefined, record: any) => {
				return <span>{t("documents." + record.documentStatus)}</span>;
			},
		},
		{
			title: t("documents.canPublish"),
			dataIndex: "skipOfferValidation",
			key: "skipOfferValidation",
			render: (text: string | undefined, record: any) => {
				return getUserId().toString() === record.documentOwner ||
					record.facultyId == adminData?.faculty?.id ? (
					<Checkbox
						checked={record.skipOfferValidation}
						disabled={
							getUserId().toString() !== record.documentOwner &&
							record.facultyId != adminData?.faculty?.id
						}
						onChange={(event: any) => onCheckboxChange(event, record.id)}
					/>
				) : (
					<span>
						{record.skipOfferValidation
							? t("documents.yes")
							: t("documents.no")}
					</span>
				);
			},
		},
		{
			title: t("documents.actions"),
			dataIndex: "actions",
			key: "actions",
			render: (text: string | undefined, record: any) => (
				<div className={styles.modalContainer} key={"actions-" + record.id}>
					<Space>
						<div className={styles.modalBtnContainer}>
							<CustomButton
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								title={t("documents.download")}
								onClick={() =>
									downloadFile(
										downloadDocument(record.id!),
										t("documents." + record.documentType) +
											"_" +
											record.facultyNameRo +
											"_" +
											companyProfile?.name
									).catch(downloadFail)
								}
							>
								<FontAwesomeIcon
									icon={solid("download")}
									style={{ fontSize: "15px" }}
								/>
							</CustomButton>
						</div>
						{getUserId().toString() === record.documentOwner ? (
							<div className={styles.modalBtnContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setModalVisibility(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("trash")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.delete")}
								/>
								<ConfirmationModal
									modalText={t("documents.deleteWarning")}
									handleFunction={() => {
										deleteCompanyDocument(selectedDocumentId);
									}}
									modalVisibility={modalVisibility}
									title=""
									changeModalVisibility={() => setModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						) : null}
						{(getUserId().toString() === record.documentOwner &&
							isCompany &&
							record.documentStatus !== CompanyDocumentStatusEnum.Finalized) ||
						(!isCompany &&
							record.documentStatus === CompanyDocumentStatusEnum.InProgress &&
							record.facultyId == adminData?.faculty?.id) ? (
							<div className={styles.modalBtnContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setSelectedFaculty(record.facultyId);
										setDocumentType("practiceProtocol");
										setIsModalVisible(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("file-pen")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.uploadNewVersion")}
								/>
							</div>
						) : null}
						{record.documentStatus === CompanyDocumentStatusEnum.InProgress &&
						record.facultyId === adminData?.faculty?.id ? (
							<div className={styles.modalBtnContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setIsRejectModalVisible(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("ban")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.rejectDocument")}
								/>
							</div>
						) : null}
					</Space>
				</div>
			),
		},
	];

	const { data: professorProfile } = useQuery(
		[getProfessor, location, isProfessor],
		async () =>
			location.state && location.state.userType === Role.Professor
				? getProfessorById(location.state.id)
				: !location.state && isProfessor
				? getProfessorById(getUserId())
				: null,
		{
			onError: openProfileErrorNotification,
		}
	);

	const { data: companyUser } = useQuery(
		[getCompanyUser, location, isCompany, companyId],
		async () =>
			isCompany && (!location.state || location.state?.id === companyId)
				? getCompanyUserById(getUserId())
				: null,
		{
			onError: openProfileErrorNotification,
		}
	);

	const { data: companyProfile } = useQuery(
		[getCompany, location, isCompany, companyId],
		async () =>
			location.state && location.state.userType === Role.Company
				? getCompanyById(location.state.id)
				: !location.state && isCompany
				? getCompanyById(companyId)
				: null,
		{
			onError: openProfileErrorNotification,
		}
	);

	const data = professorProfile
		? {
				id: professorProfile.id,
				image: professorProfile.fullResImage ?? professorProfile.lowResImage,
				departmentNameRo: professorProfile.departmentNameRo,
				departmentNameEn: professorProfile.departmentNameEn,
				phone: professorProfile.phone,
				email: professorProfile.email,
				personalEmail: professorProfile.personalEmail,
				description: professorProfile.description,
				name: professorProfile.name,
				professionalTitle: professorProfile.professionalTitle,
				siteUrl: professorProfile.siteUrl,
		  }
		: companyProfile
		? {
				id: companyProfile.id,
				logo: companyProfile.fullResLogo ?? companyProfile.lowResLogo,
				domainOfActivity: companyProfile.domainOfActivity,
				phone: companyProfile.phone,
				email: companyProfile.email,
				description: companyProfile.description,
				name: companyProfile.commercialName,
				linkedInUrl: companyProfile.linkedInUrl,
				siteUrl: companyProfile.siteUrl,
		  }
		: null;

	const openNotificationErrorFetchDocuments = (_ex: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("documents.documentsFetchFailed"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: allFacultiesList } = useQuery(
		[getFaculties, isFacultyAdmin],
		() => (isFacultyAdmin ? getAllFaculties("", 1, 20) : []),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const fetchDataErrorNotification = (ex: any) => {
		if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
			openNotification(
				t("account.error"),
				t("account.fetchUserDataError"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("account.error"),
				t("account.unknownError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: adminData } = useQuery(
		[getProfessor, isFacultyAdmin],
		async () => (isFacultyAdmin ? getProfessorById(getUserId()) : null),
		{
			onError: fetchDataErrorNotification,
		}
	);

	const { data: documents, isLoading: loading } = useQuery(
		[
			getDocuments,
			location.state?.userType,
			location.state?.id,
			documentTypes,
			currentPage,
			pageSize,
			isFacultyAdmin,
		],
		() => {
			if (
				isFacultyAdmin &&
				location.state &&
				location.state.userType === Role.Company
			) {
				return getCompanyDocuments(
					location.state?.id,
					documentTypes,
					currentPage,
					pageSize
				);
			} else {
				return null;
			}
		},
		{
			onError: openNotificationErrorFetchDocuments,
			onSuccess: (data) => {
				if (data) {
					if (data?.data !== null && data?.data !== undefined) {
						let auxFaculties = [adminData?.faculty?.id];
						for (let i = 0; i < data?.data.length; i++) {
							let facultyId = data.data[i].facultyId;
							auxFaculties = auxFaculties?.filter((e) => e !== facultyId);
						}
						setAdminFaculties(auxFaculties);
					}
				}
			},
		}
	);

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setDocumentType(null);
		setSelectedFaculty(undefined);
		setFile(undefined);
		setSelectedDocumentId(undefined);
		setIsModalVisible(false);
		setIsRejectModalVisible(false);
	};

	const uploadDocument = (
		companyId: string,
		file: Blob,
		facultyId?: string,
		documentId?: string
	) => {
		addNewDocument(companyId, file, facultyId, documentId)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.uploadedDocument"),
					t("documents.uploadSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.uploadFail"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const handleOk = () => {
		setSpinning(true);
		if (
			location.state?.userType == Role.Company &&
			location.state?.id &&
			file !== undefined
		) {
			uploadDocument(
				location.state?.id,
				file,
				selectedFaculty,
				selectedDocumentId
			);
		}
		setDocumentType(null);
		setSelectedFaculty(undefined);
		setSpinning(false);
		setFile(undefined);
		setSelectedDocumentId(undefined);
		setIsModalVisible(false);
	};

	const onChange = (event: string) => {
		setDocumentType(event);
	};

	const handleSelectedFaculties = (selected: any) => {
		setSelectedFaculty(selected);
	};

	const rejectCompanyDocument = (documentId: string, message: string) => {
		setSpinning(true);

		rejectDocument(documentId, message)
			.then(() => {
				openNotification(
					t("documents.documentRejection"),
					t("documents.documentRejectionSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);

				setRejectionMessage("");
				setIsRejectModalVisible(false);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.DocumentNotFound:
						notificationMessage = t("documents.documentNotFound");
						break;
					case ErrorCodes.Forbidden:
						notificationMessage = t("documents.notAllowed");
						break;
					default:
						notificationMessage = t("documents.documentRejectionError");
						break;
				}

				openNotification(
					t("documents.documentRejection"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
			});
	};

	let locale = {
		emptyText: t("tableText.noDocuments"),
	};

	const handleTableChange = (pagination: TablePaginationConfig) => {
		setCurrent(() => pagination.current ?? 1);
		setPageSize(() => pagination.pageSize ?? 5);
	};

	if (!isUserLogged() && !location.state) {
		return <Redirect to="/404" />;
	} else {
		return (
			<Layout>
				<Spin
					size="large"
					spinning={!professorProfile && !companyProfile}
					tip={t("account.loading")}
				>
					<Row gutter={[0, 10]}>
						<Col span={window.innerWidth > 1215 ? 5 : 24}>
							{data?.image ? (
								<Avatar size={250} src={data?.image} />
							) : data?.logo ? (
								<Avatar size={250} src={data?.logo} />
							) : (
								<Avatar
									size={250}
									icon={
										<FontAwesomeIcon
											icon={solid("user")}
											title={t("account.userPicture")}
										/>
									}
								/>
							)}
						</Col>
						<Col
							span={window.innerWidth > 1215 ? 17 : 24}
							style={{ paddingLeft: "5%" }}
						>
							<Row
								gutter={[0, 20]}
								justify="start"
								style={{ textAlign: "left" }}
							>
								<Col span={24}>
									<div>
										<Typography
											style={{
												fontSize: 25,
												float: "left",
												marginRight: "5%",
												fontWeight: "bold",
											}}
										>
											<Typography.Text
												strong={true}
												style={{ color: theme.secondColor }}
											>
												{t("header.profile")} &nbsp;&nbsp;&nbsp;
												{(companyUser?.id === getUserId() ||
													professorProfile?.id === getUserId()) &&
												((isCompany &&
													companyUser &&
													(companyUser.isRepresentative ||
														companyUser.isAdmin)) ||
													isProfessor) ? (
													<Link
														to="/contul-meu"
														title={t("account.editProfile")}
													>
														<FontAwesomeIcon
															icon={solid("pen-to-square")}
															style={{
																width: "1.2em",
																height: "1.2em",
																color: theme.secondColor,
																cursor: "pointer",
															}}
														/>
													</Link>
												) : null}
											</Typography.Text>
										</Typography>
									</div>
								</Col>
								<Col span={24}>
									<Typography
										style={{
											fontSize: 23,
											float: "left",
											marginRight: "2%",
										}}
									>
										<Typography.Text strong={true}>
											{data?.professionalTitle &&
											data?.professionalTitle !==
												ProfessionalTitleEnum.NotApplicable
												? t("professionalTitle." + data?.professionalTitle)
												: ""}{" "}
											{data?.name}
										</Typography.Text>
									</Typography>
									{data?.email &&
										location.state?.userType !== Role.Company &&
										!isCompany &&
										data.id !== getUserId() && (
											<Button
												type="text"
												href={
													"https://teams.microsoft.com/l/chat/0/0?users=" +
													data?.email
												}
												target="_blank"
												rel="noopener noreferrer"
												style={{
													backgroundColor: "transparent",
													float: "left",
													padding: "0",
												}}
												title={t("students.teams")}
											>
												<FontAwesomeIcon
													icon={solid("comment")}
													style={{
														fontSize: 25,
														float: "left",
														marginRight: "10px",
													}}
												/>
											</Button>
										)}
									<Row
										gutter={[10, 4]}
										justify="start"
										style={{ textAlign: "left" }}
									>
										{data?.siteUrl && (
											<Col span={window.innerWidth > 1215 ? 1 : 3}>
												<Button
													type="text"
													href={data?.siteUrl}
													target="_blank"
													rel="noreferrer"
													style={{
														backgroundColor: "transparent",
														float: "left",
														padding: "0",
													}}
													title={t("profile.siteUrl")}
												>
													<FontAwesomeIcon
														icon={solid("link")}
														style={{
															fontSize: 25,
															float: "left",
															marginRight: "10px",
														}}
													/>
												</Button>
											</Col>
										)}
										{data?.linkedInUrl && (
											<Col span={window.innerWidth > 1215 ? 1 : 3}>
												<Button
													type="text"
													href={data?.linkedInUrl!}
													target="_blank"
													rel="noreferrer"
													style={{
														backgroundColor: "transparent",
														float: "left",
														padding: "0",
													}}
													title={t("students.linkedinUrl")}
												>
													<FontAwesomeIcon
														icon={brands("linkedin")}
														style={{
															fontSize: 25,
															float: "left",
															marginRight: "10px",
														}}
													/>
												</Button>
											</Col>
										)}
									</Row>
								</Col>
								{i18n.language === "ro" ? (
									data?.departmentNameRo ? (
										<Col span={24}>
											<Typography style={{ fontSize: 22, float: "left" }}>
												<Typography.Text strong={true}>
													{data?.departmentNameRo}
												</Typography.Text>
											</Typography>
										</Col>
									) : null
								) : data?.departmentNameEn ? (
									<Col span={24}>
										<Typography style={{ fontSize: 22, float: "left" }}>
											<Typography.Text strong={true}>
												{data?.departmentNameEn}
											</Typography.Text>
										</Typography>
									</Col>
								) : null}
								{data?.domainOfActivity && (
									<Col span={24}>
										<Typography style={{ fontSize: 22, float: "left" }}>
											<Typography.Text strong={true}>
												{t("signupText.activityDomain")}:{" "}
												{data?.domainOfActivity}
											</Typography.Text>
										</Typography>
									</Col>
								)}
								{data?.phone && (
									<Col span={24}>
										<Button
											type="text"
											href={"tel:" + data?.phone}
											style={{
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
										>
											<FontAwesomeIcon
												icon={solid("phone")}
												style={{
													fontSize: 25,
													float: "left",
													marginRight: "10px",
												}}
												title={t("account.phone")}
											/>
											<Typography style={{ fontSize: 20, float: "left" }}>
												<Typography.Text strong={true}>
													{data?.phone}
												</Typography.Text>
											</Typography>
										</Button>
									</Col>
								)}
								{data?.email && (
									<Col span={24}>
										<Button
											type="text"
											href={"mailto:" + data?.email}
											style={{
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
										>
											<FontAwesomeIcon
												icon={solid("envelope")}
												style={{
													fontSize: 25,
													float: "left",
													marginRight: "10px",
												}}
												title={t("account.email")}
											/>
											<Typography style={{ fontSize: 20, float: "left" }}>
												<Typography.Text strong={true}>
													&nbsp;{data?.email}
												</Typography.Text>
											</Typography>
										</Button>
										<Button
											type="text"
											style={{
												marginLeft: "10px",
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
											onClick={() => {
												navigator.clipboard
													.writeText(data?.email || "")
													.then(() => {
														openNotification(
															t("profile.emailCopied"),
															t("profile.emailCopiedMessage"),
															NOTIFICATION_TYPES.SUCCESS
														);
													});
											}}
										>
											<FontAwesomeIcon
												icon={solid("copy")}
												style={{
													fontSize: 25,
													float: "left",
													marginRight: "10px",
													marginBottom: "10px",
												}}
												title={t("account.copyToClipboard")}
											/>
										</Button>
									</Col>
								)}
								{data?.personalEmail && (
									<Col span={24}>
										<Button
											type="text"
											href={"mailto:" + data?.personalEmail}
											style={{
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
										>
											<FontAwesomeIcon
												icon={solid("envelope")}
												style={{
													fontSize: 25,
													float: "left",
													marginRight: "10px",
												}}
												title={t("account.email")}
											/>
											<Typography style={{ fontSize: 20, float: "left" }}>
												<Typography.Text strong={true}>
													&nbsp;{data?.personalEmail}
												</Typography.Text>
											</Typography>
										</Button>
									</Col>
								)}
							</Row>
							<div style={{ height: "5%" }} />
						</Col>
						<Col span={window.innerWidth > 1215 ? 2 : 24}>
							{location.state && (
								<Link
									to={{
										pathname: location.state.origin,
										state: {
											existingFilters: location.state.filters,
											searchTerm: location.state.searchTerm,
											sortField: location.state.sortField,
											sortDirection: location.state.sortDirection,
											currentPage: location.state.currentPage,
											pageSize: location.state.pageSize,
											activeTab: location.state?.activeTab,
										},
									}}
								>
									<CustomButton
										paddingvertical={"19px"}
										boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
									>
										{t("profile.goBack")}
									</CustomButton>
								</Link>
							)}
						</Col>
					</Row>
					<br />
					<br />
					<Row gutter={[0, 10]}>
						<div
							style={{
								fontSize: 20,
								textAlign: "left",
								fontStyle: "italic",
							}}
							dangerouslySetInnerHTML={{ __html: data?.description || "" }}
						/>
					</Row>
					<br />
					{isFacultyAdmin &&
						location.state &&
						location.state.userType === Role.Company && (
							<PracticeSupervisorTable companyId={location.state.id} />
						)}
					{(isFacultyAdmin || isDean) &&
						// own profile -> an admin/dean who also is an internship admin
						((!location.state && isInternshipAdmin) ||
							// someone else's profile who is an internship admin
							(location.state &&
								location.state.userType === Role.Professor &&
								professorProfile?.roles?.includes(Role.InternshipAdmin))) && (
							<SupervisedStudyProgramsTable
								supervisorId={location.state ? location.state.id : getUserId()}
							/>
						)}
					{isCompany && (
						<FacultiesOfInterestTable
							companyId={location.state ? location.state.id : getUserId()}
						/>
					)}
					{isFacultyAdmin &&
						location.state &&
						location.state.userType === Role.Company && (
							<>
								<Row
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "5%",
									}}
								>
									<h2>{t("documents.documents")}</h2>
								</Row>
								<Row style={{ marginBottom: "2em", marginTop: "2em" }}>
									<Col xs={24} sm={12} md={12} lg={12} xl={12}>
										<CustomButton
											paddingvertical={"19px"}
											boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
											style={{
												background: theme.secondColor,
												marginTop: "0",
												marginLeft: "2.5em",
											}}
											onClick={handleShow}
											disabled={adminFaculties?.length == 0}
										>
											{t("documents.uploadDocument")}
										</CustomButton>
										<Modal
											visible={isModalVisible}
											maskClosable={!spinning}
											onCancel={handleCancel}
											onOk={handleOk}
											title={""}
											width={window.innerWidth > 1215 ? "50%" : "80%"}
											footer={
												<CustomModalFooter
													handleClose={handleCancel}
													spinning={spinning}
													handleSave={handleOk}
													confirmButtonName={t("account.confirm")}
												/>
											}
										>
											<Spin spinning={spinning}>
												<p>{t("documents.selectDocumentText")}</p>
												<Row>
													{selectedDocumentId === undefined && (
														<Col span={24} style={{ marginBottom: "1em" }}>
															<Select
																value={documentType}
																onChange={onChange}
																placeholder={t("documents.selectDocumentType")}
																options={[
																	{
																		label: t("documents.practiceProtocol"),
																		value: "practiceProtocol",
																	},
																]}
																allowClear
																style={{ width: "18rem" }}
															/>
														</Col>
													)}
													{isCompany && selectedDocumentId === undefined && (
														<Col span={24}>
															<Select
																value={selectedFaculty}
																allowClear
																filterOption={false}
																onSearch={setFacultySearch}
																showSearch
																placeholder={t("documents.selectFaculty")}
																style={{ width: "18rem" }}
																onChange={handleSelectedFaculties}
															>
																{facultyOptions}
															</Select>
														</Col>
													)}
													<Col span={24}>
														<Upload
															accept=".pdf"
															action={(uploaded) => {
																setFile(uploaded);
																return returnAction;
															}}
															showUploadList={true}
															multiple={false}
															customRequest={({ onSuccess }) => {
																onSuccess && onSuccess("");
															}}
														>
															<CustomButton
																disabled={documentType === null}
																icon={
																	<FontAwesomeIcon icon={solid("upload")} />
																}
																marginbottom={"1em"}
																title={t("documents.uploadPdf")}
																style={{ marginTop: "2rem" }}
															>
																&nbsp;{t("documents.upload")}
															</CustomButton>
														</Upload>
													</Col>
												</Row>
											</Spin>
										</Modal>
										<Modal
											visible={isRejectModalVisible}
											onCancel={handleCancel}
											onOk={() =>
												rejectCompanyDocument(
													selectedDocumentId,
													rejectionMessage
												)
											}
											title={t("documents.documentRejection")}
											width={window.innerWidth > 1215 ? "50%" : "80%"}
											footer={
												<CustomModalFooter
													handleClose={handleCancel}
													spinning={spinning}
													handleSave={() =>
														rejectCompanyDocument(
															selectedDocumentId,
															rejectionMessage
														)
													}
													confirmButtonName={t("account.confirm")}
												/>
											}
										>
											<Spin spinning={spinning}>
												<p>{t("documents.rejectDocumentMessage")}</p>

												<CustomEditor
													content={rejectionMessage}
													onEditorChange={(newValue: string) =>
														setRejectionMessage(newValue)
													}
												/>
											</Spin>
										</Modal>
									</Col>
								</Row>
								<Row style={{ justifyContent: "center" }}>
									<Row>
										<Col span={24} style={{ padding: "0 0 0 3%" }}>
											<Table
												locale={locale}
												dataSource={documents?.data || []}
												columns={columns}
												pagination={{
													total: documents?.totalCount,
													current: currentPage,
													pageSize: documents?.pageSize,
													pageSizeOptions: ["10", "20", "50"],
													defaultPageSize: 10,
													hideOnSinglePage: documents?.totalCount
														? documents?.totalCount <= 10
														: true,
													showSizeChanger: true,
													locale: {
														items_per_page: t("configuration.pagination"),
													},
													position: ["topRight", "bottomRight"],
												}}
												rowKey={getKey}
												loading={loading && !data}
												onChange={handleTableChange}
												scroll={{ x: 400 }}
											/>
										</Col>
									</Row>
								</Row>
							</>
						)}
				</Spin>
			</Layout>
		);
	}
};

export default Profile;
