/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PracticalActivityDTO,
    PracticalActivityDTOFromJSON,
    PracticalActivityDTOFromJSONTyped,
    PracticalActivityDTOToJSON,
} from './PracticalActivityDTO';
import {
    PracticeNotebookState,
    PracticeNotebookStateFromJSON,
    PracticeNotebookStateFromJSONTyped,
    PracticeNotebookStateToJSON,
} from './PracticeNotebookState';

/**
 * 
 * @export
 * @interface PracticeNotebookAddDTO
 */
export interface PracticeNotebookAddDTO {
    /**
     * 
     * @type {string}
     * @memberof PracticeNotebookAddDTO
     */
    title: string;
    /**
     * 
     * @type {Array<PracticalActivityDTO>}
     * @memberof PracticeNotebookAddDTO
     */
    activities: Array<PracticalActivityDTO>;
    /**
     * 
     * @type {string}
     * @memberof PracticeNotebookAddDTO
     */
    internshipEnrollmentId: string;
    /**
     * 
     * @type {PracticeNotebookState}
     * @memberof PracticeNotebookAddDTO
     */
    state: PracticeNotebookState;
}

export function PracticeNotebookAddDTOFromJSON(json: any): PracticeNotebookAddDTO {
    return PracticeNotebookAddDTOFromJSONTyped(json, false);
}

export function PracticeNotebookAddDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeNotebookAddDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'activities': ((json['activities'] as Array<any>).map(PracticalActivityDTOFromJSON)),
        'internshipEnrollmentId': json['internshipEnrollmentId'],
        'state': PracticeNotebookStateFromJSON(json['state']),
    };
}

export function PracticeNotebookAddDTOToJSON(value?: PracticeNotebookAddDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'activities': ((value.activities as Array<any>).map(PracticalActivityDTOToJSON)),
        'internshipEnrollmentId': value.internshipEnrollmentId,
        'state': PracticeNotebookStateToJSON(value.state),
    };
}

