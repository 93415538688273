import { PropsWithChildren, useState } from "react";
import {
	ResearchPartnersForProfessorOrCompanyDTO,
	ResearchProposalEnrollmentType,
	ResearchProposalType,
	Role,
} from "../../Api";
import { Table } from "antd";
import styles from "./ApplicantsList.module.scss";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ResearchProposalProps } from "../../PropsLists/ResearchProposalProps";

type ColumnType<T> = {
	title: string | JSX.Element;
	key: string;
	dataIndex?: string;
	hidden?: boolean;
	render?: (text: string | undefined, record: T) => JSX.Element | string;
};

const ApplicationsList = (props: PropsWithChildren<ResearchProposalProps>) => {
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState("");

	let locale = {
		emptyText: t("tableText.noApplications"),
	};

	const FilterByNameInput = (
		<Search
			placeholder={t("students.name")}
			allowClear
			onSearch={setSearchTerm}
			style={{ width: 130 }}
		/>
	);

	const FilterByProposalNameInput = (
		<Search
			placeholder={t("internships.searchProposal")}
			allowClear
			onSearch={setSearchTerm}
			style={{ width: 200 }}
		/>
	);

	const columns: ColumnType<ResearchPartnersForProfessorOrCompanyDTO>[] = [
		{
			title: FilterByNameInput,
			key: "name",
			dataIndex: "name",
			render: (
				text: string | undefined,
				record: ResearchPartnersForProfessorOrCompanyDTO
			) => {
				return (
					<Link
						key="profile"
						to={{
							pathname: `/profil`,
							state: {
								id:
									record.enrollmentType ===
									ResearchProposalEnrollmentType.ByProfessor
										? record.professorId
										: record.companyId,
								userType:
									record.researchProposalType ===
									ResearchProposalType.ByProfessor
										? Role.Professor
										: Role.Company, // TBD check here
								filters: props.checkedKeys,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								activeTab: props.activeTab,
								origin: props.location.pathname,
							},
						}}
					>
						<b>
							{record.enrollmentType ===
							ResearchProposalEnrollmentType.ByCompany
								? record.companyName
								: record.professorName}
						</b>
					</Link>
				);
			},
		},
		{
			title: FilterByProposalNameInput,
			key: "researchProposalName",
			dataIndex: "researchProposalName",
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			render: (
				text: string | undefined,
				record: ResearchPartnersForProfessorOrCompanyDTO
			) => (
				<span>
					{record.enrollmentType === ResearchProposalEnrollmentType.ByCompany
						? record.companyUserEmail
						: record.professorEmail ?? record.professorPersonalEmail}
				</span>
			),
			hidden: window.innerWidth < 550,
		},
	].filter((item) => !item.hidden);

	const getKey = (record: any): string => {
		return record.researchProposalEnrollmentId + record.researchProposalId;
	};

	return (
		<Table
			locale={locale}
			dataSource={props.data?.data || []}
			columns={columns}
			pagination={{
				total: props.data?.totalCount,
				current: props.currentPage,
				pageSize: props.pageSize,
				pageSizeOptions: ["10", "20", "50"],
				showSizeChanger: true,
				locale: { items_per_page: t("configuration.pagination") },
				position: ["topRight", "bottomRight"],
				hideOnSinglePage: props.data?.totalCount
					? props.data?.totalCount <= 10
					: true,
				defaultPageSize: 10,
			}}
			rowKey={getKey}
			rowClassName={(record) =>
				!record.isEnrollmentViewed ? styles.customTableRow : ""
			}
			loading={props.loading && !props.data}
			onChange={props.handleListChange}
		/>
	);
};

export default ApplicationsList;
