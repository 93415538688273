import {
	CompanyApi,
	CompanyAddDTO,
	CompanyLoginDTO,
	CompanyUpdateDTO,
	CompanyStateEnum,
	CompanyDocumentStatusEnum,
} from "../Api";
import { getAuthConfiguration } from "./configuration";
import { arrayParamAjust } from "../utils/dataUtils";

const AuthCompanyApiFactory = () => new CompanyApi(getAuthConfiguration());

export const fetchCompanyData = (cui: number) => {
	return AuthCompanyApiFactory().apiCompanyGetByCuiCuiGet({ cui });
};

export const registerCompany = async (companyAddDTO: CompanyAddDTO) => {
	await new CompanyApi().apiCompanyRegisterPost({ companyAddDTO });
};

export const addCompanyContact = async (companyAddDTO: CompanyAddDTO) => {
	await AuthCompanyApiFactory().apiCompanyAddContactCompanyPost({
		companyAddDTO,
	});
};

export const loginCompany = async (companyLoginDTO: CompanyLoginDTO) => {
	return new CompanyApi().apiCompanyLoginPost({ companyLoginDTO });
};

export const refreshToken = async (refreshToken: string) => {
	return new CompanyApi().apiCompanyRefreshTokenPost({ body: refreshToken });
};

export const resendValidationEmail = async (companyEmail: string) => {
	await new CompanyApi().apiCompanyResendValidationEmailPost({
		body: companyEmail,
	});
};

export const getCompanyById = (id: string) => {
	return AuthCompanyApiFactory().apiCompanyGetByIdIdGet({ id });
};

export const invalidateCompany = (id: string) => {
	return AuthCompanyApiFactory().apiCompanyInvalidateIdPut({ id });
};

export const validateCompany = (id: string) => {
	return AuthCompanyApiFactory().apiCompanyValidateIdPut({ id });
};

export const activateCompany = (id: string) => {
	return AuthCompanyApiFactory().apiCompanyActivateIdPut({ id });
};

export const getCompanies = (
	namePattern?: string,
	companyStates?: Array<string>,
	documentStatus?: Array<string>,
	facultyOfInterest?: Array<string>,
	page?: number,
	pageSize?: number
) => {
	return AuthCompanyApiFactory().apiCompanyGetAllGet({
		namePattern,
		companyStates: arrayParamAjust(
			companyStates?.map(
				(e) => CompanyStateEnum[e as keyof typeof CompanyStateEnum]
			)
		),
		documentStatus: arrayParamAjust(
			documentStatus?.map(
				(e) =>
					CompanyDocumentStatusEnum[e as keyof typeof CompanyDocumentStatusEnum]
			)
		),
		facultyOfInterest: arrayParamAjust(facultyOfInterest),
		page,
		pageSize,
	});
};

export const updateCompanyInfo = async (
	id: string,
	companyUpdateDTO: CompanyUpdateDTO
) => {
	await AuthCompanyApiFactory().apiCompanyUpdateIdPut({ id, companyUpdateDTO });
};

export const getCompaniesFilter = () => {
	return AuthCompanyApiFactory().apiCompanyGetCompanyStateFiltersGet();
};

export const getAllCompanyCounters = () => {
	return AuthCompanyApiFactory().apiCompanyGetAllCountersGet();
};

export const allocateCompanyPracticeSupervisor = async (
	companyId: string,
	supervisorId: string
) => {
	await AuthCompanyApiFactory().apiCompanyAllocatePracticeSupervisorCompanyIdPut(
		{ companyId, body: supervisorId }
	);
};

export const deleteCompanyPracticeSupervisor = async (
	companyId: string,
	supervisorId: string
) => {
	await AuthCompanyApiFactory().apiCompanyDeletePracticeSupervisorCompanyIdDelete(
		{ companyId, body: supervisorId }
	);
};

export const deleteCompany = async (companyId: string) => {
	await AuthCompanyApiFactory().apiCompanyDeleteIdDelete({ id: companyId });
};

export const addFacultyOfInterest = (facultyId: string) => {
	return AuthCompanyApiFactory().apiCompanyAddFacultyOfInterestFacultyIdPut({
		facultyId,
	});
};

export const deleteFacultyOfInterest = (facultyId: string) => {
	return AuthCompanyApiFactory().apiCompanyDeleteFacultyOfInterestFacultyIdDelete(
		{ facultyId }
	);
};

export const getFacultiesOfInterest = (
	companyId: string,
	namePattern: string,
	page: number,
	pageSize: number
) => {
	return AuthCompanyApiFactory().apiCompanyGetFacultiesOfInterestCompanyIdGet({
		companyId,
		namePattern,
		page,
		pageSize,
	});
};
