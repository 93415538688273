/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PracticeCertificateAddDTO,
    PracticeCertificateAddDTOFromJSON,
    PracticeCertificateAddDTOToJSON,
    PracticeCertificateDTO,
    PracticeCertificateDTOFromJSON,
    PracticeCertificateDTOToJSON,
    PracticeCertificateUpdateDTO,
    PracticeCertificateUpdateDTOFromJSON,
    PracticeCertificateUpdateDTOToJSON,
    RequestResponse,
    RequestResponseFromJSON,
    RequestResponseToJSON,
} from '../models';

export interface ApiPracticeCertificateAddPostRequest {
    practiceCertificateAddDTO?: PracticeCertificateAddDTO;
}

export interface ApiPracticeCertificateApproveCertificateIdPutRequest {
    id: string;
}

export interface ApiPracticeCertificateDeleteIdDeleteRequest {
    id: string;
}

export interface ApiPracticeCertificateDownloadFileSignatureIdGetRequest {
    signatureId: string;
}

export interface ApiPracticeCertificateGenerateCertificateIdGetRequest {
    id: string;
}

export interface ApiPracticeCertificateGetForEnrollmentInternshipEnrollmentIdGetRequest {
    internshipEnrollmentId: string;
}

export interface ApiPracticeCertificateRejectCertificateIdPutRequest {
    id: string;
    comment?: string;
}

export interface ApiPracticeCertificateUpdateIdPutRequest {
    id: string;
    practiceCertificateUpdateDTO?: PracticeCertificateUpdateDTO;
}

export interface ApiPracticeCertificateUploadFinalizedInternshipEnrollmentIdPostRequest {
    internshipEnrollmentId: string;
    file?: Blob;
}

export interface ApiPracticeCertificateUploadSignedCertificateIdPostRequest {
    certificateId: string;
    file?: Blob;
}

/**
 * 
 */
export class PracticeCertificateApi extends runtime.BaseAPI {

    /**
     */
    async apiPracticeCertificateAddPostRaw(requestParameters: ApiPracticeCertificateAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PracticeCertificateAddDTOToJSON(requestParameters.practiceCertificateAddDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPracticeCertificateAddPost(requestParameters: ApiPracticeCertificateAddPostRequest = {}, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiPracticeCertificateAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeCertificateApproveCertificateIdPutRaw(requestParameters: ApiPracticeCertificateApproveCertificateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeCertificateApproveCertificateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/ApproveCertificate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeCertificateApproveCertificateIdPut(requestParameters: ApiPracticeCertificateApproveCertificateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeCertificateApproveCertificateIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeCertificateDeleteIdDeleteRaw(requestParameters: ApiPracticeCertificateDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeCertificateDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeCertificateDeleteIdDelete(requestParameters: ApiPracticeCertificateDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeCertificateDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeCertificateDownloadFileSignatureIdGetRaw(requestParameters: ApiPracticeCertificateDownloadFileSignatureIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.signatureId === null || requestParameters.signatureId === undefined) {
            throw new runtime.RequiredError('signatureId','Required parameter requestParameters.signatureId was null or undefined when calling apiPracticeCertificateDownloadFileSignatureIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/DownloadFile/{signatureId}`.replace(`{${"signatureId"}}`, encodeURIComponent(String(requestParameters.signatureId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeCertificateDownloadFileSignatureIdGet(requestParameters: ApiPracticeCertificateDownloadFileSignatureIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeCertificateDownloadFileSignatureIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeCertificateGenerateCertificateIdGetRaw(requestParameters: ApiPracticeCertificateGenerateCertificateIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeCertificateGenerateCertificateIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/GenerateCertificate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPracticeCertificateGenerateCertificateIdGet(requestParameters: ApiPracticeCertificateGenerateCertificateIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPracticeCertificateGenerateCertificateIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeCertificateGetForEnrollmentInternshipEnrollmentIdGetRaw(requestParameters: ApiPracticeCertificateGetForEnrollmentInternshipEnrollmentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeCertificateDTO>> {
        if (requestParameters.internshipEnrollmentId === null || requestParameters.internshipEnrollmentId === undefined) {
            throw new runtime.RequiredError('internshipEnrollmentId','Required parameter requestParameters.internshipEnrollmentId was null or undefined when calling apiPracticeCertificateGetForEnrollmentInternshipEnrollmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/GetForEnrollment/{internshipEnrollmentId}`.replace(`{${"internshipEnrollmentId"}}`, encodeURIComponent(String(requestParameters.internshipEnrollmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeCertificateDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiPracticeCertificateGetForEnrollmentInternshipEnrollmentIdGet(requestParameters: ApiPracticeCertificateGetForEnrollmentInternshipEnrollmentIdGetRequest, initOverrides?: RequestInit): Promise<PracticeCertificateDTO> {
        const response = await this.apiPracticeCertificateGetForEnrollmentInternshipEnrollmentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeCertificateRejectCertificateIdPutRaw(requestParameters: ApiPracticeCertificateRejectCertificateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeCertificateRejectCertificateIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.comment !== undefined) {
            queryParameters['comment'] = requestParameters.comment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/RejectCertificate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeCertificateRejectCertificateIdPut(requestParameters: ApiPracticeCertificateRejectCertificateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeCertificateRejectCertificateIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeCertificateUpdateIdPutRaw(requestParameters: ApiPracticeCertificateUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPracticeCertificateUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/Update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PracticeCertificateUpdateDTOToJSON(requestParameters.practiceCertificateUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPracticeCertificateUpdateIdPut(requestParameters: ApiPracticeCertificateUpdateIdPutRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiPracticeCertificateUpdateIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPracticeCertificateUploadFinalizedInternshipEnrollmentIdPostRaw(requestParameters: ApiPracticeCertificateUploadFinalizedInternshipEnrollmentIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.internshipEnrollmentId === null || requestParameters.internshipEnrollmentId === undefined) {
            throw new runtime.RequiredError('internshipEnrollmentId','Required parameter requestParameters.internshipEnrollmentId was null or undefined when calling apiPracticeCertificateUploadFinalizedInternshipEnrollmentIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/UploadFinalized/{internshipEnrollmentId}`.replace(`{${"internshipEnrollmentId"}}`, encodeURIComponent(String(requestParameters.internshipEnrollmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeCertificateUploadFinalizedInternshipEnrollmentIdPost(requestParameters: ApiPracticeCertificateUploadFinalizedInternshipEnrollmentIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeCertificateUploadFinalizedInternshipEnrollmentIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPracticeCertificateUploadSignedCertificateIdPostRaw(requestParameters: ApiPracticeCertificateUploadSignedCertificateIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.certificateId === null || requestParameters.certificateId === undefined) {
            throw new runtime.RequiredError('certificateId','Required parameter requestParameters.certificateId was null or undefined when calling apiPracticeCertificateUploadSignedCertificateIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/PracticeCertificate/UploadSigned/{certificateId}`.replace(`{${"certificateId"}}`, encodeURIComponent(String(requestParameters.certificateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPracticeCertificateUploadSignedCertificateIdPost(requestParameters: ApiPracticeCertificateUploadSignedCertificateIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPracticeCertificateUploadSignedCertificateIdPostRaw(requestParameters, initOverrides);
    }

}
