/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    CompanyUser,
    CompanyUserFromJSON,
    CompanyUserFromJSONTyped,
    CompanyUserToJSON,
} from './CompanyUser';
import {
    NewsletterEmail,
    NewsletterEmailFromJSON,
    NewsletterEmailFromJSONTyped,
    NewsletterEmailToJSON,
} from './NewsletterEmail';

/**
 * 
 * @export
 * @interface EmailRecipient
 */
export interface EmailRecipient {
    /**
     * 
     * @type {string}
     * @memberof EmailRecipient
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailRecipient
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmailRecipient
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmailRecipient
     */
    emailId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailRecipient
     */
    academicUserRecipientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailRecipient
     */
    companyUserRecipientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailRecipient
     */
    readonly recipientId?: string;
    /**
     * 
     * @type {NewsletterEmail}
     * @memberof EmailRecipient
     */
    email?: NewsletterEmail;
    /**
     * 
     * @type {AcademicUser}
     * @memberof EmailRecipient
     */
    academicUserRecipient?: AcademicUser;
    /**
     * 
     * @type {CompanyUser}
     * @memberof EmailRecipient
     */
    companyUserRecipient?: CompanyUser;
}

export function EmailRecipientFromJSON(json: any): EmailRecipient {
    return EmailRecipientFromJSONTyped(json, false);
}

export function EmailRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'emailId': !exists(json, 'emailId') ? undefined : json['emailId'],
        'academicUserRecipientId': !exists(json, 'academicUserRecipientId') ? undefined : json['academicUserRecipientId'],
        'companyUserRecipientId': !exists(json, 'companyUserRecipientId') ? undefined : json['companyUserRecipientId'],
        'recipientId': !exists(json, 'recipientId') ? undefined : json['recipientId'],
        'email': !exists(json, 'email') ? undefined : NewsletterEmailFromJSON(json['email']),
        'academicUserRecipient': !exists(json, 'academicUserRecipient') ? undefined : AcademicUserFromJSON(json['academicUserRecipient']),
        'companyUserRecipient': !exists(json, 'companyUserRecipient') ? undefined : CompanyUserFromJSON(json['companyUserRecipient']),
    };
}

export function EmailRecipientToJSON(value?: EmailRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'emailId': value.emailId,
        'academicUserRecipientId': value.academicUserRecipientId,
        'companyUserRecipientId': value.companyUserRecipientId,
        'email': NewsletterEmailToJSON(value.email),
        'academicUserRecipient': AcademicUserToJSON(value.academicUserRecipient),
        'companyUserRecipient': CompanyUserToJSON(value.companyUserRecipient),
    };
}

