import { useState } from "react";
import Layout from "../../Containers/Layout";
import styles from "./PracticeCertificate.module.scss";
import { Form, InputNumber, Checkbox, Select, Row, Col } from "antd";
import CustomForm from "../../CustomComponents/CustomForm";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	PracticeQualificationDTO,
	PracticeCertificateQualifier,
	PracticeCertificateAddDTO,
	PracticeCertificateState,
	PracticeCertificateUpdateDTO,
	InternshipType,
	ErrorCodes,
} from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
	addPracticeCertificate,
	getPracticeCertificateForInternshipEnrollment,
	updatePracticeCertificate,
} from "../../Requests/practice-certificate-requests";
import { criterions, qualifiers } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectStudentInternshipEnrollmentId } from "../Student/StudentSlice";
import { useQuery } from "react-query";
import { getInternshipEnrollmentById } from "../../Requests/internship-requests";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	getInternshipEnrollment,
	getPracticeCertificate,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";

const { Option } = Select;

const computeDefaultQualifications = () => {
	let defaultQualifications: PracticeQualificationDTO[] = [];

	for (let i = 0; i < criterions.length; i++) {
		defaultQualifications.push({
			criterion: criterions[i],
			qualifier: PracticeCertificateQualifier.Fb,
		});
	}

	return defaultQualifications;
};

const PracticeCertificate = () => {
	const { t } = useTranslation();
	const [complete, setComplete] = useState(false);
	const [qualifiersList, setQualifiersList] = useState<
		PracticeQualificationDTO[]
	>(computeDefaultQualifications());
	const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
	const [addModalVisibility, setAddModalVisibility] = useState(false);
	const internshipEnrollmentId = useSelector(
		selectStudentInternshipEnrollmentId
	);
	const history = useHistory();
	const [spinning, setSpinning] = useState(false);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveErrorNotification = async (error: any) => {
		const errorMessage = await getErrorFromResponse(error);

		let notificationMessage;

		switch (errorMessage?.code) {
			case ErrorCodes.PracticeCertificateAlreadyExists:
				notificationMessage = t("account.notFoundUser");
				break;
			case ErrorCodes.Forbidden:
				notificationMessage = t("errors.forbidden");
				break;
			case ErrorCodes.InternshipEnrollmentNotFound:
				notificationMessage = t("internships.enrollmentNotFound");
				break;
			case ErrorCodes.BadRequest:
				notificationMessage = t("documents.invalidData");
				break;
			default:
				notificationMessage = t("account.unknownError");
				break;
		}

		openNotification(
			t("practiceCertificate.error"),
			notificationMessage,
			NOTIFICATION_TYPES.ERROR
		);
		setAddModalVisibility(false);
	};

	const [state, setState] = useState({
		totalWorkHours: 0,
		workHoursPerDay: 8,
		observations: "",
	});

	const [form] = Form.useForm<{
		totalWorkHours: number;
		workHoursPerDay: number;
		observations: string | null;
	}>();

	const { data: internshipEnrollment } = useQuery(
		[getInternshipEnrollment, internshipEnrollmentId],
		() => getInternshipEnrollmentById(internshipEnrollmentId!),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			onSuccess: (data) => {
				if (data) {
					form.setFieldsValue({
						totalWorkHours: data.totalPracticeHours,
					});

					setState((prevState) => {
						return {
							...prevState,
							totalWorkHours: data.totalPracticeHours as number,
						};
					});
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: practiceCertificate } = useQuery(
		[getPracticeCertificate, internshipEnrollmentId],
		() =>
			getPracticeCertificateForInternshipEnrollment(internshipEnrollmentId!),
		{
			onError: (err: any) => {
				if (err.status !== 404) {
					openGetErrorNotification(err);
				}
			},
			onSuccess: (data) => {
				if (data.state !== PracticeCertificateState.InProgress) {
					history.push("/404");
				}
				if (data) {
					form.setFieldsValue({
						totalWorkHours: data.totalWorkHours,
						workHoursPerDay: data.workHoursPerDay,
						observations: data.observations,
					});

					setQualifiersList(data.qualifiers!);
					setComplete(data.state !== PracticeCertificateState.InProgress);
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const handleChangeInputs = (event: any, field: any) => {
		event.persist();
		setState({
			...state,
			[field]: event.target.value,
		});
	};

	const handleChangeNumberInputs = (value: any, field: any) => {
		setState({
			...state,
			[field]: value,
		});
	};

	const handleChangeCheckbox = (event: CheckboxChangeEvent) => {
		setComplete(event.target.checked);
	};

	const handleChangeQualifier = (event: any, index: number) => {
		const newQualifiersList = [...qualifiersList];
		newQualifiersList[index].qualifier = event;

		setQualifiersList(newQualifiersList);
	};

	const handleCancel = () => {
		setCancelModalVisibility(false);
		history.goBack();
	};

	const openSuccessNotificationAndRedirect = () => {
		history.goBack();
		openNotification(
			t("practiceCertificate.editPracticeCertificate"),
			t("practiceCertificate.editPracticeCertificateSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const renderQualifiers = () => {
		return qualifiersList.map((q, index) => (
			<Row>
				<Col xs={24} sm={24} md={24} lg={15} xl={15}>
					<label style={{ textAlign: "left" }}>
						{t("practiceCertificate.criterionsText." + q.criterion!)}
					</label>
				</Col>
				<Col xs={24} sm={24} md={24} lg={8} xl={8} className={styles.styledCol}>
					<Select
						allowClear
						style={{ width: "100%", textAlign: "left" }}
						onChange={(event) => handleChangeQualifier(event, index)}
						value={q.qualifier}
						placeholder={t(
							"practiceCertificate.addPracticeCertificateForm.grade"
						)}
					>
						{qualifiers.map((item, index) => (
							<Option value={item} key={"criterion" + index}>
								{t("practiceCertificate.qualifiers." + item)}
							</Option>
						))}
					</Select>
				</Col>
			</Row>
		));
	};

	const handleSave = () => {
		if (
			state.totalWorkHours > 0 &&
			state.workHoursPerDay > 0 &&
			state.workHoursPerDay <= 8 &&
			qualifiersList.length > 0
		) {
			setSpinning(true);

			if (practiceCertificate) {
				const obj: PracticeCertificateUpdateDTO = {
					qualifiers: qualifiersList,
					workHoursPerDay: state.workHoursPerDay,
					observations: state.observations,
					state: complete
						? practiceCertificate.internshipType === InternshipType.ByProfessor
							? PracticeCertificateState.PendingInternshipAdminValidation
							: PracticeCertificateState.PendingPracticeRepresentativeSignature
						: PracticeCertificateState.InProgress,
				};

				updatePracticeCertificate(practiceCertificate.id!, obj)
					.then(openSuccessNotificationAndRedirect)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setAddModalVisibility(false);
					});
			} else {
				const obj: PracticeCertificateAddDTO = {
					qualifiers: qualifiersList,
					internshipEnrollmentId: internshipEnrollmentId,
					workHoursPerDay: state.workHoursPerDay,
					observations: state.observations,
					state: complete
						? internshipEnrollment?.internshipType ===
						  InternshipType.ByProfessor
							? PracticeCertificateState.PendingInternshipAdminValidation
							: PracticeCertificateState.PendingPracticeRepresentativeSignature
						: PracticeCertificateState.InProgress,
				};

				addPracticeCertificate(obj)
					.then(openSuccessNotificationAndRedirect)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setAddModalVisibility(false);
					});
			}
		} else {
			openNotification(
				t("practiceCertificate.editPracticeCertificate"),
				t("usersText.requiredFieldsError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	return (
		<Layout>
			<div className={styles.container}>
				<CustomForm
					form={form}
					layout={theme.layout}
					action="/aplicanti"
					boxshadow={"none"}
				>
					<div className={styles.firstLine}>
						<Form.Item
							name="totalWorkHours"
							label={
								t(
									"practiceCertificate.addPracticeCertificateForm.totalNumberOfHours"
								) + ":"
							}
						>
							<InputNumber
								disabled
								className={styles.numberInput}
								placeholder={t(
									"practiceCertificate.addPracticeCertificateForm.totalNumberOfHoursPlaceholder"
								)}
								onChange={(event) =>
									handleChangeNumberInputs(event, "totalWorkHours")
								}
							/>
						</Form.Item>

						<Form.Item
							name="workHoursPerDay"
							initialValue={8}
							label={
								t(
									"practiceCertificate.addPracticeCertificateForm.workHoursPerDay"
								) + ":"
							}
						>
							<InputNumber
								className={styles.numberInput}
								placeholder={t(
									"practiceCertificate.addPracticeCertificateForm.workHoursPerDayPlaceholder"
								)}
								defaultValue={8}
								max={8}
								onChange={(event) =>
									handleChangeNumberInputs(event, "workHoursPerDay")
								}
							/>
						</Form.Item>

						{renderQualifiers()}

						<Form.Item
							name="observations"
							label={
								t(
									"practiceCertificate.addPracticeCertificateForm.observations"
								) + ":"
							}
						>
							<textarea
								rows={1}
								cols={100}
								placeholder={t(
									"practiceCertificate.addPracticeCertificateForm.observations"
								)}
								onChange={(event) => handleChangeInputs(event, "observations")}
							/>
						</Form.Item>

						<Checkbox
							checked={complete}
							onChange={(event) => handleChangeCheckbox(event)}
							style={{ textAlign: "left" }}
						>
							{t("practiceCertificate.addPracticeCertificateForm.markComplete")}
						</Checkbox>
					</div>
					<div className={styles.secondLine}>
						<div className={styles.firstColumn}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: theme.green,
									color: theme.white,
									marginRight: "2em",
									marginTop: "0%",
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
								onClick={() => setCancelModalVisibility(true)}
							>
								{t("practiceCertificate.addPracticeCertificateForm.cancel")}
							</CustomButton>
							<ConfirmationModal
								modalText={t(
									"practiceCertificate.addPracticeCertificateForm.cancelMessage"
								)}
								handleFunction={handleCancel}
								modalVisibility={cancelModalVisibility}
								title=""
								changeModalVisibility={() => setCancelModalVisibility(false)}
								spinning={spinning}
							/>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: theme.secondColor,
									color: theme.white,
									marginRight: "1rem",
									marginTop: "0%",
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
								onClick={() => setAddModalVisibility(true)}
							>
								{practiceCertificate
									? !complete
										? t(
												"practiceCertificate.addPracticeCertificateForm.updateDraftButton"
										  )
										: t(
												"practiceCertificate.addPracticeCertificateForm.updateButton"
										  )
									: !complete
									? t(
											"practiceCertificate.addPracticeCertificateForm.saveDraftButton"
									  )
									: t(
											"practiceCertificate.addPracticeCertificateForm.saveButton"
									  )}
							</CustomButton>
							<ConfirmationModal
								modalText={t(
									"practiceCertificate.addPracticeCertificateForm.saveMessage"
								)}
								handleFunction={handleSave}
								modalVisibility={addModalVisibility}
								title=""
								changeModalVisibility={() => setAddModalVisibility(false)}
								spinning={spinning}
							/>
						</div>
					</div>
				</CustomForm>
			</div>
		</Layout>
	);
};

export default PracticeCertificate;
