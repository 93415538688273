import { Select } from "antd";
import CustomSelect from "./CustomSelect";
import styles from "../Components/Internships/Internships.module.scss";
import { InternshipSortFieldEnum, SortDirectionEnum } from "../Api";
import CustomButton from "./CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useIsCompany } from "../utils/utilFunctions";
import { PropsWithChildren } from "react";
import { CustomSortProps } from "../PropsLists/CustomSortProps";

const { Option } = Select;

const CustomSort = (props: PropsWithChildren<CustomSortProps>) => {
	const { t } = useTranslation();
	const isCompany = useIsCompany();

	return (
		<div className={styles.sort}>
			<CustomSelect
				className={styles.customInput}
				defaultValue={props.oldValue || InternshipSortFieldEnum.PublishDate}
				onChange={props.changeSortField}
			>
				<Option value={InternshipSortFieldEnum.PublishDate}>
					{t("internships.sort.publishDate")}
				</Option>
				{props.location.pathname !== "/newsletter" && (
					<Option value={InternshipSortFieldEnum.Name}>
						{t("internships.sort.name")}
					</Option>
				)}
				{!(
					(props.location.pathname === "/propuneri" && isCompany) ||
					props.location.pathname === "/newsletter"
				) ? (
					<Option value={InternshipSortFieldEnum.CoordinatorName}>
						{t("internships.sort.coordinatorName")}
					</Option>
				) : null}
			</CustomSelect>
			<CustomButton
				onClick={props.changeSortDirection}
				className={styles.sortBtn}
				marginleft={"auto"}
				marginright={"auto"}
			>
				{props.sortDirection === SortDirectionEnum.Ascending ? (
					<FontAwesomeIcon icon={solid("arrow-up-short-wide")} />
				) : (
					<FontAwesomeIcon icon={solid("arrow-down-short-wide")} />
				)}
			</CustomButton>
		</div>
	);
};

export default CustomSort;
