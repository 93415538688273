/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PracticeCertificateCriterion {
    Disciplinary = 'Disciplinary',
    Work = 'Work',
    Knowledge = 'Knowledge',
    Interaction = 'Interaction'
}

export function PracticeCertificateCriterionFromJSON(json: any): PracticeCertificateCriterion {
    return PracticeCertificateCriterionFromJSONTyped(json, false);
}

export function PracticeCertificateCriterionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeCertificateCriterion {
    return json as PracticeCertificateCriterion;
}

export function PracticeCertificateCriterionToJSON(value?: PracticeCertificateCriterion | null): any {
    return value as any;
}

