/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InternshipAcceptDTO
 */
export interface InternshipAcceptDTO {
    /**
     * 
     * @type {string}
     * @memberof InternshipAcceptDTO
     */
    internshipId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternshipAcceptDTO
     */
    studentId?: string;
}

export function InternshipAcceptDTOFromJSON(json: any): InternshipAcceptDTO {
    return InternshipAcceptDTOFromJSONTyped(json, false);
}

export function InternshipAcceptDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipAcceptDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'internshipId': !exists(json, 'internshipId') ? undefined : json['internshipId'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
    };
}

export function InternshipAcceptDTOToJSON(value?: InternshipAcceptDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internshipId': value.internshipId,
        'studentId': value.studentId,
    };
}

