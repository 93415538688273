import Search from "antd/lib/input/Search";
import { FilterByTextInputProps } from "./FilterByTextInput.types";

export const FilterByTextInput = (props: FilterByTextInputProps) => {
	return (
		<Search
			placeholder={props.placeholder}
			allowClear
			onSearch={props.onSearch}
			style={props.style}
		/>
	);
};
