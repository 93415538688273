import {
	ActionKeyEnum,
	DiplomaStudentsViewProps,
} from "./DiplomaStudentsView.types";
import { Col, Dropdown, Form, Menu, MenuProps, Row, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	getUserId,
	useIsDean,
	useIsDepartmentDirector,
	useIsProfessor,
	useIsStudyProgramCoordinator,
} from "../../../utils/utilFunctions";
import {
	studentIdSet,
	studentInternshipEnrollmentIdSet,
} from "../../Student/StudentSlice";
import {
	addInternshipDocumentSignature,
	approveAllDiplomaDocuments,
	generateInternshipDocument,
} from "../../../Requests/internship-document-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import {
	InternsForEmployerDTO,
	InternshipDocumentState,
	InternshipEngagementType,
	InternshipType,
} from "../../../Api";
import { downloadFile, MimeType } from "../../../utils/downloadUtils";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQuery, useQueryClient } from "react-query";
import { getDiplomaStudents } from "../../../Requests/internship-requests";
import { SelectDocumentTypeModal } from "./components/modals/SelectDocumentTypeModal/SelectDocumentTypeModal";
import { FilterByTextInput } from "../../BaseComponents";
import { usePagination } from "../../../Hooks";
import {
	getFacultiesDepartments,
	getSpecificProfessor,
} from "../../../utils/reactQueriesConstants";
import { getDepartmentsForFaculties } from "../../../Requests/department-requests";
import {
	changeDepartmentForStudent,
	getProfessorById,
} from "../../../Requests/academic-user-requests";
import CustomButton from "../../../CustomComponents/CustomButton";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { theme } from "../../../theme";

export const DiplomaStudentsView = (props: DiplomaStudentsViewProps) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { currentPage, pageSize, handleTablePaginationChange } = usePagination(
		1,
		10
	);
	const [searchTerm, setSearchTerm] = useState("");
	const [visibleDocument, setVisibleDocument] = useState<
		InternsForEmployerDTO | undefined
	>(undefined);
	const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
	const [documentType, setDocumentType] = useState("PDF");
	const [defaultDepartment, setDefaultDepartment] = useState("");
	const [form] = Form.useForm<{
		documentType: string;
		department: string;
	}>();

	const isProfessor = useIsProfessor();
	const isDean = useIsDean();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isDepartmentDirector = useIsDepartmentDirector();
	const [bulkApproveModalVisibility, setBulkApproveModalVisibility] =
		useState(false);
	const [spinning, setSpinning] = useState(false);
	const internships = useMemo(
		() =>
			props.initialInternshipName === undefined
				? []
				: [props.initialInternshipName],
		[props.initialInternshipName]
	);

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const goToEditDocumentPage = (enrollmentId: any) => {
		dispatch(studentInternshipEnrollmentIdSet(enrollmentId));
		history.push("/editare-document-propunere");
	};

	const signInternshipDocument = (id: any) => {
		addInternshipDocumentSignature(id)
			.then(async () => {
				await queryClient.invalidateQueries("getInterns");
				await queryClient.invalidateQueries("getFilters");

				openNotification(
					t("students.approveInternshipDocument"),
					t("students.successApproveInternshipDocumentMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.approveInternshipDocument"),
					t("students.errorApproveInternshipDocumentMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const downloadInternshipDocumentFail = (_err: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadInternshipDocument = async (
		enrollment: InternsForEmployerDTO
	) => {
		let { documentType, department } = form.getFieldsValue();
		if (enrollment.departmentId !== department) {
			await changeDepartmentForStudent(enrollment.id!, department);
		}
		if (
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingCoordinatorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingStudyProgramCoordinatorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingDepartmentDirectorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingDeanSignature ||
			enrollment.internshipType === InternshipType.ByProfessor
		) {
			downloadFile(
				generateInternshipDocument(
					enrollment.internshipDocumentId!,
					documentType === "PDF" ? MimeType.pdf : MimeType.docx
				),
				t("students.internshipDocument"),
				documentType === "PDF" ? MimeType.pdf : MimeType.docx
			).catch(downloadInternshipDocumentFail);
		} else {
			downloadFile(
				generateInternshipDocument(
					enrollment.internshipDocumentLastSignatureId!,
					documentType === "PDF" ? MimeType.pdf : MimeType.docx
				),
				t("students.internshipDocument"),
				documentType === "PDF" ? MimeType.pdf : MimeType.docx
			).catch(downloadInternshipDocumentFail);
		}
	};

	const getActions = (
		record: InternsForEmployerDTO
	): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		if (
			(record.internshipEngagementType ===
				InternshipEngagementType.BachelorsInternship ||
				record.internshipEngagementType ===
					InternshipEngagementType.MastersInternship) &&
			record.internshipDocumentStatus !==
				InternshipDocumentState.NotGenerated &&
			record.internshipDocumentStatus !== InternshipDocumentState.InProgress
		) {
			if (
				isProfessor ||
				isDean ||
				isStudyProgramCoordinator ||
				isDepartmentDirector
			) {
				actions.push({
					label: t("students.downloadInternshipDocument"),
					key: ActionKeyEnum.DownloadInternshipDocument + "DiplomaStudents",
					icon: (
						<FontAwesomeIcon
							icon={solid("download")}
							style={{ fontSize: "15px" }}
						/>
					),
				});

				if (
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingCoordinatorSignature &&
						record.internshipEmployerId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingStudyProgramCoordinatorSignature &&
						record.documentSigningStudyProgramCoordId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingDepartmentDirectorSignature &&
						record.documentSigningDepartmentDirectorId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingDeanSignature &&
						record.documentSigningDeanId === getUserId())
				) {
					actions.push({
						label: t("students.editInternshipDocument"),
						key: ActionKeyEnum.EditInternshipDocument + "DiplomaStudents",
						icon: (
							<FontAwesomeIcon
								icon={solid("pencil")}
								style={{ fontSize: "15px" }}
							/>
						),
					});

					actions.push({
						label: t("students.validateInternshipDocument"),
						key: ActionKeyEnum.ValidateInternshipDocument + "DiplomaStudents",
						icon: (
							<FontAwesomeIcon
								icon={solid("circle-check")}
								style={{ fontSize: "15px" }}
							/>
						),
					});
				}
			}
		}

		return actions;
	};

	const onMenuClick = (e: any, record: InternsForEmployerDTO) => {
		if (e.key === ActionKeyEnum.EditInternshipDocument + "DiplomaStudents") {
			goToEditDocumentPage(record.internshipEnrollmentId);
		} else if (
			e.key ===
			ActionKeyEnum.DownloadInternshipDocument + "DiplomaStudents"
		) {
			setVisibleDocument(record);
			setDefaultDepartment(record.departmentId!);
			setIsDocumentModalVisible(true);
		} else if (
			e.key ===
			ActionKeyEnum.ValidateInternshipDocument + "DiplomaStudents"
		) {
			signInternshipDocument(record.internshipDocumentId);
		}
	};

	const columns = [
		{
			title: (
				<FilterByTextInput
					placeholder={t("students.name")}
					onSearch={setSearchTerm}
					style={{ width: 130 }}
				/>
			),
			dataIndex: "name",
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: (
				<FilterByTextInput
					placeholder={t("internships.searchProposal")}
					onSearch={setSearchTerm}
					style={{ width: 200 }}
				/>
			),
			dataIndex: "internshipName",
			key: "internshipName",
		},
		{
			title: t("students.studyProgram"),
			dataIndex: "studyProgram",
			key: "studyProgram",
			hidden: window.innerWidth < 550,
		},
		{
			title: t("students.year"),
			dataIndex: "year",
			key: "year",
			hidden: window.innerWidth < 550,
		},
		{
			title: t("internships.type"),
			dataIndex: "internshipEngagementType",
			key: "internshipEngagementType",
			hidden: window.innerWidth < 650,
			render: (_text: string | undefined, record: any) =>
				t("internships.filters." + record.internshipEngagementType),
		},
		{
			title: t("students.internshipDocumentStatus"),
			dataIndex: "internshipDocumentStatus",
			key: "internshipDocumentStatus",
			hidden: window.innerWidth < 1200,
			render: (_text: string | undefined, record: any) =>
				t(
					"students.internshipDocumentStatuses." +
						record.internshipDocumentStatus
				),
		},
		{
			title: t("students.actions"),
			dataIndex: "actions",
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	].filter((item) => !item.hidden);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		["getDiplomaStudents", searchTerm, currentPage, pageSize],
		() => {
			return getDiplomaStudents(
				searchTerm,
				currentPage,
				pageSize,
				[],
				[],
				[],
				[],
				[]
			);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	useEffect(() => {
		//props.location.state is set with the internshipId when the user clicks View Applicants in Internship Component
		//we'll use this id for filtering
		//the next line clears the id from props.location.state
		window.history.replaceState({}, document.title);
	}, [currentPage, pageSize, searchTerm, internships, i18n.language]);

	const getKey = (record: any): string => {
		return record.id + record.internshipId;
	};

	const onChange = (event: any) => {
		setDocumentType(event);
	};

	const onDepartmentChange = (event: any) => {
		setDefaultDepartment(event);
	};

	const handleModalClose = () => {
		setIsDocumentModalVisible(false);
		setDocumentType("PDF");
	};

	const handleModalFunction = useCallback(() => {
		if (visibleDocument) downloadInternshipDocument(visibleDocument);
	}, [visibleDocument]);

	const { data: currentUser } = useQuery([getSpecificProfessor], () => {
		return getProfessorById(getUserId());
	});

	const { data: departments } = useQuery(
		[getFacultiesDepartments, currentUser],
		() => {
			if (currentUser) {
				return getDepartmentsForFaculties([currentUser?.faculty?.id!]);
			}
		}
	);

	const handleBulkApprove = () => {
		setSpinning(true);

		approveAllDiplomaDocuments().then(async () => {
			openNotification(
				t("students.bulkApproveInternshipDocuments"),
				t("students.successBulkApproveInternshipDocumentsMessage"),
				NOTIFICATION_TYPES.SUCCESS
			);
			await queryClient.invalidateQueries("getDiplomaStudents");
			setSpinning(false);
			setBulkApproveModalVisibility(false);
		});
	};

	return (
		<>
			{(isDepartmentDirector || isDean) && (
				<Row style={{ marginTop: "5%", justifyContent: "start" }}>
					<Col style={{ padding: "0 0 0 1%" }}>
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: theme.primaryColor,
								color: theme.white,
								marginRight: "2em",
								marginTop: "0%",
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
							onClick={() => setBulkApproveModalVisibility(true)}
						>
							{t("internships.bulkApprove")}
						</CustomButton>
						<ConfirmationModal
							modalText={t("internships.bulkApproveMessage")}
							handleFunction={handleBulkApprove}
							modalVisibility={bulkApproveModalVisibility}
							changeModalVisibility={() => setBulkApproveModalVisibility(false)}
							title=""
							spinning={spinning}
						/>
					</Col>
				</Row>
			)}
			<Row style={{ marginTop: "1%", justifyContent: "center" }}>
				<Col style={{ padding: "0 0 0 1%" }}>
					<Table
						dataSource={data?.data || []}
						columns={columns}
						pagination={{
							total: data?.totalCount,
							current: currentPage,
							pageSize: data?.pageSize,
							pageSizeOptions: ["10", "20", "50"],
							defaultPageSize: 10,
							hideOnSinglePage: data?.totalCount
								? data?.totalCount <= 10
								: true,
							showSizeChanger: true,
							locale: { items_per_page: t("configuration.pagination") },
							position: ["topRight", "bottomRight"],
						}}
						rowKey={getKey}
						loading={loading && !data}
						onChange={handleTablePaginationChange}
						scroll={{ x: 400 }}
					/>
				</Col>
				<SelectDocumentTypeModal
					visible={isDocumentModalVisible}
					title={t("students.downloadInternshipDocument")}
					form={form}
					defaultValue={documentType}
					departments={departments!}
					defaultDepartmentValue={defaultDepartment}
					onDepartmentChange={onDepartmentChange}
					onOk={handleModalFunction}
					onCancel={handleModalClose}
					onClose={handleModalClose}
					onSave={handleModalFunction}
					onChange={onChange}
				/>
			</Row>
		</>
	);
};
