/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    EmailPriority,
    EmailPriorityFromJSON,
    EmailPriorityFromJSONTyped,
    EmailPriorityToJSON,
} from './EmailPriority';
import {
    EmailRecipient,
    EmailRecipientFromJSON,
    EmailRecipientFromJSONTyped,
    EmailRecipientToJSON,
} from './EmailRecipient';

/**
 * 
 * @export
 * @interface NewsletterEmail
 */
export interface NewsletterEmail {
    /**
     * 
     * @type {string}
     * @memberof NewsletterEmail
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterEmail
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterEmail
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof NewsletterEmail
     */
    publisherId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterEmail
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsletterEmail
     */
    body?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsletterEmail
     */
    newsletterChannel?: string | null;
    /**
     * 
     * @type {EmailPriority}
     * @memberof NewsletterEmail
     */
    priority?: EmailPriority;
    /**
     * 
     * @type {number}
     * @memberof NewsletterEmail
     */
    initialRecipientsCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterEmail
     */
    expeditionEnabled?: boolean;
    /**
     * 
     * @type {AcademicUser}
     * @memberof NewsletterEmail
     */
    publisher?: AcademicUser;
    /**
     * 
     * @type {Array<EmailRecipient>}
     * @memberof NewsletterEmail
     */
    recipients?: Array<EmailRecipient> | null;
}

export function NewsletterEmailFromJSON(json: any): NewsletterEmail {
    return NewsletterEmailFromJSONTyped(json, false);
}

export function NewsletterEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'publisherId': !exists(json, 'publisherId') ? undefined : json['publisherId'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'newsletterChannel': !exists(json, 'newsletterChannel') ? undefined : json['newsletterChannel'],
        'priority': !exists(json, 'priority') ? undefined : EmailPriorityFromJSON(json['priority']),
        'initialRecipientsCount': !exists(json, 'initialRecipientsCount') ? undefined : json['initialRecipientsCount'],
        'expeditionEnabled': !exists(json, 'expeditionEnabled') ? undefined : json['expeditionEnabled'],
        'publisher': !exists(json, 'publisher') ? undefined : AcademicUserFromJSON(json['publisher']),
        'recipients': !exists(json, 'recipients') ? undefined : (json['recipients'] === null ? null : (json['recipients'] as Array<any>).map(EmailRecipientFromJSON)),
    };
}

export function NewsletterEmailToJSON(value?: NewsletterEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'publisherId': value.publisherId,
        'subject': value.subject,
        'body': value.body,
        'newsletterChannel': value.newsletterChannel,
        'priority': EmailPriorityToJSON(value.priority),
        'initialRecipientsCount': value.initialRecipientsCount,
        'expeditionEnabled': value.expeditionEnabled,
        'publisher': AcademicUserToJSON(value.publisher),
        'recipients': value.recipients === undefined ? undefined : (value.recipients === null ? null : (value.recipients as Array<any>).map(EmailRecipientToJSON)),
    };
}

