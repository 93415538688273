/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CompanyInternshipState {
    Draft = 'Draft',
    PendingApproval = 'PendingApproval',
    Approved = 'Approved',
    Invalid = 'Invalid'
}

export function CompanyInternshipStateFromJSON(json: any): CompanyInternshipState {
    return CompanyInternshipStateFromJSONTyped(json, false);
}

export function CompanyInternshipStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyInternshipState {
    return json as CompanyInternshipState;
}

export function CompanyInternshipStateToJSON(value?: CompanyInternshipState | null): any {
    return value as any;
}

