/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyResearchProposalAddUpdateDTO
 */
export interface CompanyResearchProposalAddUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    numberOfPartners?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    url?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    keywords?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    availableFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    availableTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResearchProposalAddUpdateDTO
     */
    isArchived?: boolean;
}

export function CompanyResearchProposalAddUpdateDTOFromJSON(json: any): CompanyResearchProposalAddUpdateDTO {
    return CompanyResearchProposalAddUpdateDTOFromJSONTyped(json, false);
}

export function CompanyResearchProposalAddUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyResearchProposalAddUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'numberOfPartners': !exists(json, 'numberOfPartners') ? undefined : json['numberOfPartners'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'availableFrom': !exists(json, 'availableFrom') ? undefined : (new Date(json['availableFrom'])),
        'availableTo': !exists(json, 'availableTo') ? undefined : (new Date(json['availableTo'])),
        'isArchived': !exists(json, 'isArchived') ? undefined : json['isArchived'],
    };
}

export function CompanyResearchProposalAddUpdateDTOToJSON(value?: CompanyResearchProposalAddUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'shortDescription': value.shortDescription,
        'numberOfPartners': value.numberOfPartners,
        'url': value.url,
        'keywords': value.keywords,
        'availableFrom': value.availableFrom === undefined ? undefined : (value.availableFrom.toISOString()),
        'availableTo': value.availableTo === undefined ? undefined : (value.availableTo.toISOString()),
        'isArchived': value.isArchived,
    };
}

