/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhdReportDTO
 */
export interface PhdReportDTO {
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    studentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    facultyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    groupName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    domainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    studyYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r4?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r5?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r6?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r7?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r8?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r9?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r10?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r11?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r12?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r14?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r15?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r16?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r17?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r18?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r19?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r20?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r21?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r22?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r23?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r24?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r25?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r26?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r27?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r28?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r29?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r30?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r31?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r32?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r33?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r34?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r35?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r36?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r37?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r38?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r39?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r40?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r41?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhdReportDTO
     */
    r42?: string | null;
}

export function PhdReportDTOFromJSON(json: any): PhdReportDTO {
    return PhdReportDTOFromJSONTyped(json, false);
}

export function PhdReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhdReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentName': !exists(json, 'studentName') ? undefined : json['studentName'],
        'facultyName': !exists(json, 'facultyName') ? undefined : json['facultyName'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'domainName': !exists(json, 'domainName') ? undefined : json['domainName'],
        'studyYear': !exists(json, 'studyYear') ? undefined : json['studyYear'],
        'r1': !exists(json, 'r1') ? undefined : json['r1'],
        'r2': !exists(json, 'r2') ? undefined : json['r2'],
        'r3': !exists(json, 'r3') ? undefined : json['r3'],
        'r4': !exists(json, 'r4') ? undefined : json['r4'],
        'r5': !exists(json, 'r5') ? undefined : json['r5'],
        'r6': !exists(json, 'r6') ? undefined : json['r6'],
        'r7': !exists(json, 'r7') ? undefined : json['r7'],
        'r8': !exists(json, 'r8') ? undefined : json['r8'],
        'r9': !exists(json, 'r9') ? undefined : json['r9'],
        'r10': !exists(json, 'r10') ? undefined : json['r10'],
        'r11': !exists(json, 'r11') ? undefined : json['r11'],
        'r12': !exists(json, 'r12') ? undefined : json['r12'],
        'r13': !exists(json, 'r13') ? undefined : json['r13'],
        'r14': !exists(json, 'r14') ? undefined : json['r14'],
        'r15': !exists(json, 'r15') ? undefined : json['r15'],
        'r16': !exists(json, 'r16') ? undefined : json['r16'],
        'r17': !exists(json, 'r17') ? undefined : json['r17'],
        'r18': !exists(json, 'r18') ? undefined : json['r18'],
        'r19': !exists(json, 'r19') ? undefined : json['r19'],
        'r20': !exists(json, 'r20') ? undefined : json['r20'],
        'r21': !exists(json, 'r21') ? undefined : json['r21'],
        'r22': !exists(json, 'r22') ? undefined : json['r22'],
        'r23': !exists(json, 'r23') ? undefined : json['r23'],
        'r24': !exists(json, 'r24') ? undefined : json['r24'],
        'r25': !exists(json, 'r25') ? undefined : json['r25'],
        'r26': !exists(json, 'r26') ? undefined : json['r26'],
        'r27': !exists(json, 'r27') ? undefined : json['r27'],
        'r28': !exists(json, 'r28') ? undefined : json['r28'],
        'r29': !exists(json, 'r29') ? undefined : json['r29'],
        'r30': !exists(json, 'r30') ? undefined : json['r30'],
        'r31': !exists(json, 'r31') ? undefined : json['r31'],
        'r32': !exists(json, 'r32') ? undefined : json['r32'],
        'r33': !exists(json, 'r33') ? undefined : json['r33'],
        'r34': !exists(json, 'r34') ? undefined : json['r34'],
        'r35': !exists(json, 'r35') ? undefined : json['r35'],
        'r36': !exists(json, 'r36') ? undefined : json['r36'],
        'r37': !exists(json, 'r37') ? undefined : json['r37'],
        'r38': !exists(json, 'r38') ? undefined : json['r38'],
        'r39': !exists(json, 'r39') ? undefined : json['r39'],
        'r40': !exists(json, 'r40') ? undefined : json['r40'],
        'r41': !exists(json, 'r41') ? undefined : json['r41'],
        'r42': !exists(json, 'r42') ? undefined : json['r42'],
    };
}

export function PhdReportDTOToJSON(value?: PhdReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentName': value.studentName,
        'facultyName': value.facultyName,
        'groupName': value.groupName,
        'domainName': value.domainName,
        'studyYear': value.studyYear,
        'r1': value.r1,
        'r2': value.r2,
        'r3': value.r3,
        'r4': value.r4,
        'r5': value.r5,
        'r6': value.r6,
        'r7': value.r7,
        'r8': value.r8,
        'r9': value.r9,
        'r10': value.r10,
        'r11': value.r11,
        'r12': value.r12,
        'r13': value.r13,
        'r14': value.r14,
        'r15': value.r15,
        'r16': value.r16,
        'r17': value.r17,
        'r18': value.r18,
        'r19': value.r19,
        'r20': value.r20,
        'r21': value.r21,
        'r22': value.r22,
        'r23': value.r23,
        'r24': value.r24,
        'r25': value.r25,
        'r26': value.r26,
        'r27': value.r27,
        'r28': value.r28,
        'r29': value.r29,
        'r30': value.r30,
        'r31': value.r31,
        'r32': value.r32,
        'r33': value.r33,
        'r34': value.r34,
        'r35': value.r35,
        'r36': value.r36,
        'r37': value.r37,
        'r38': value.r38,
        'r39': value.r39,
        'r40': value.r40,
        'r41': value.r41,
        'r42': value.r42,
    };
}

