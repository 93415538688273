/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangePasswordDTO,
    ChangePasswordDTOFromJSON,
    ChangePasswordDTOToJSON,
    CompanyPasswordResetDTO,
    CompanyPasswordResetDTOFromJSON,
    CompanyPasswordResetDTOToJSON,
    CompanyUserAddUpdateDTO,
    CompanyUserAddUpdateDTOFromJSON,
    CompanyUserAddUpdateDTOToJSON,
    CompanyUserDTO,
    CompanyUserDTOFromJSON,
    CompanyUserDTOToJSON,
    CompanyUserDTOPagedResponse,
    CompanyUserDTOPagedResponseFromJSON,
    CompanyUserDTOPagedResponseToJSON,
    RequestResponse,
    RequestResponseFromJSON,
    RequestResponseToJSON,
} from '../models';

export interface ApiCompanyUsersActivateAccountIdPutRequest {
    id: string;
}

export interface ApiCompanyUsersAddContactCompanyIdPostRequest {
    companyId: string;
    companyUserAddUpdateDTO?: CompanyUserAddUpdateDTO;
}

export interface ApiCompanyUsersAddPostRequest {
    companyUserAddUpdateDTO?: CompanyUserAddUpdateDTO;
}

export interface ApiCompanyUsersChangePasswordPostRequest {
    changePasswordDTO?: ChangePasswordDTO;
}

export interface ApiCompanyUsersChangeRepresentativeUserIdPutRequest {
    id: string;
}

export interface ApiCompanyUsersDeleteIdDeleteRequest {
    id: string;
}

export interface ApiCompanyUsersGetAllGetRequest {
    namePattern?: string;
    companyId?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiCompanyUsersGetByIdIdGetRequest {
    id: string;
}

export interface ApiCompanyUsersListAllForCompanyGetRequest {
    companyId?: string;
}

export interface ApiCompanyUsersResetPasswordTokenPostRequest {
    token: string;
    changePasswordDTO?: ChangePasswordDTO;
}

export interface ApiCompanyUsersSendResetPasswordLinkPostRequest {
    companyPasswordResetDTO?: CompanyPasswordResetDTO;
}

export interface ApiCompanyUsersToggleAdminRoleIdPutRequest {
    id: string;
}

export interface ApiCompanyUsersToggleNotificationsIdPutRequest {
    id: string;
}

export interface ApiCompanyUsersUpdateIdPutRequest {
    id: string;
    companyUserAddUpdateDTO?: CompanyUserAddUpdateDTO;
}

/**
 * 
 */
export class CompanyUsersApi extends runtime.BaseAPI {

    /**
     */
    async apiCompanyUsersActivateAccountIdPutRaw(requestParameters: ApiCompanyUsersActivateAccountIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersActivateAccountIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ActivateAccount/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyUsersActivateAccountIdPut(requestParameters: ApiCompanyUsersActivateAccountIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyUsersActivateAccountIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyUsersAddContactCompanyIdPostRaw(requestParameters: ApiCompanyUsersAddContactCompanyIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiCompanyUsersAddContactCompanyIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/AddContact/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUserAddUpdateDTOToJSON(requestParameters.companyUserAddUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersAddContactCompanyIdPost(requestParameters: ApiCompanyUsersAddContactCompanyIdPostRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiCompanyUsersAddContactCompanyIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersAddPostRaw(requestParameters: ApiCompanyUsersAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUserAddUpdateDTOToJSON(requestParameters.companyUserAddUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersAddPost(requestParameters: ApiCompanyUsersAddPostRequest = {}, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiCompanyUsersAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersChangePasswordPostRaw(requestParameters: ApiCompanyUsersChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ChangePassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordDTOToJSON(requestParameters.changePasswordDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyUsersChangePasswordPost(requestParameters: ApiCompanyUsersChangePasswordPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyUsersChangePasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyUsersChangeRepresentativeUserIdPutRaw(requestParameters: ApiCompanyUsersChangeRepresentativeUserIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersChangeRepresentativeUserIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ChangeRepresentativeUser/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersChangeRepresentativeUserIdPut(requestParameters: ApiCompanyUsersChangeRepresentativeUserIdPutRequest, initOverrides?: RequestInit): Promise<CompanyUserDTO> {
        const response = await this.apiCompanyUsersChangeRepresentativeUserIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersDeleteIdDeleteRaw(requestParameters: ApiCompanyUsersDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyUsersDeleteIdDelete(requestParameters: ApiCompanyUsersDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyUsersDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyUsersGetAllGetRaw(requestParameters: ApiCompanyUsersGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['CompanyId'] = requestParameters.companyId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersGetAllGet(requestParameters: ApiCompanyUsersGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<CompanyUserDTOPagedResponse> {
        const response = await this.apiCompanyUsersGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersGetByIdIdGetRaw(requestParameters: ApiCompanyUsersGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersGetByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/GetById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersGetByIdIdGet(requestParameters: ApiCompanyUsersGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<CompanyUserDTO> {
        const response = await this.apiCompanyUsersGetByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersListAllForCompanyGetRaw(requestParameters: ApiCompanyUsersListAllForCompanyGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CompanyUserDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ListAllForCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyUserDTOFromJSON));
    }

    /**
     */
    async apiCompanyUsersListAllForCompanyGet(requestParameters: ApiCompanyUsersListAllForCompanyGetRequest = {}, initOverrides?: RequestInit): Promise<Array<CompanyUserDTO>> {
        const response = await this.apiCompanyUsersListAllForCompanyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersListAllGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CompanyUserDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ListAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyUserDTOFromJSON));
    }

    /**
     */
    async apiCompanyUsersListAllGet(initOverrides?: RequestInit): Promise<Array<CompanyUserDTO>> {
        const response = await this.apiCompanyUsersListAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersResetPasswordTokenPostRaw(requestParameters: ApiCompanyUsersResetPasswordTokenPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling apiCompanyUsersResetPasswordTokenPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ResetPassword/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordDTOToJSON(requestParameters.changePasswordDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyUsersResetPasswordTokenPost(requestParameters: ApiCompanyUsersResetPasswordTokenPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyUsersResetPasswordTokenPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyUsersSendResetPasswordLinkPostRaw(requestParameters: ApiCompanyUsersSendResetPasswordLinkPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/SendResetPasswordLink`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyPasswordResetDTOToJSON(requestParameters.companyPasswordResetDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyUsersSendResetPasswordLinkPost(requestParameters: ApiCompanyUsersSendResetPasswordLinkPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyUsersSendResetPasswordLinkPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyUsersToggleAdminRoleIdPutRaw(requestParameters: ApiCompanyUsersToggleAdminRoleIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersToggleAdminRoleIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ToggleAdminRole/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersToggleAdminRoleIdPut(requestParameters: ApiCompanyUsersToggleAdminRoleIdPutRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiCompanyUsersToggleAdminRoleIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersToggleNotificationsIdPutRaw(requestParameters: ApiCompanyUsersToggleNotificationsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersToggleNotificationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ToggleNotifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyUsersToggleNotificationsIdPut(requestParameters: ApiCompanyUsersToggleNotificationsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyUsersToggleNotificationsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyUsersUpdateIdPutRaw(requestParameters: ApiCompanyUsersUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/Update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUserAddUpdateDTOToJSON(requestParameters.companyUserAddUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersUpdateIdPut(requestParameters: ApiCompanyUsersUpdateIdPutRequest, initOverrides?: RequestInit): Promise<RequestResponse> {
        const response = await this.apiCompanyUsersUpdateIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
