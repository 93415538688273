import {
	AcademicUserApi,
	AcademicUserUpdateDTO,
	StudentYearEnum,
	AcademicUserUpdateRoleDTO,
} from "../Api";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthAcademicUserApiFactory = () =>
	new AcademicUserApi(getAuthConfiguration());

//de testat toate
export const getAllAcademicUsers = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	faculties?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllAcademicUsersGet({
		namePattern,
		faculties: arrayParamAjust(faculties),
		page,
		pageSize,
	});
};

export const getAllStudents = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	years?: string[],
	faculties?: string[],
	departments?: string[],
	studyPrograms?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllStudentsGet({
		namePattern,
		years: arrayParamAjust(
			years?.map((e) => StudentYearEnum[e as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		departments: arrayParamAjust(departments),
		studyPrograms: arrayParamAjust(studyPrograms),
		page,
		pageSize,
	});
};

export const getAllStudentsWhoAcceptGDPR = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	years?: string[],
	faculties?: string[],
	departments?: string[],
	studyPrograms?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllStudentsForNewCompanyGet(
		{
			namePattern,
			years: arrayParamAjust(
				years?.map((e) => StudentYearEnum[e as keyof typeof StudentYearEnum])
			),
			faculties: arrayParamAjust(faculties),
			departments: arrayParamAjust(departments),
			studyPrograms: arrayParamAjust(studyPrograms),
			page,
			pageSize,
		}
	);
};

export const getAllProfessors = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	faculties?: string[],
	departments?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllProfessorsGet({
		namePattern,
		faculties: arrayParamAjust(faculties),
		departments: arrayParamAjust(departments),
		page,
		pageSize,
	});
};

export const getAllAcademicUsersNonStudents = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	faculties?: string[],
	departments?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAcademicUsersNonStudentsGet(
		{
			namePattern,
			faculties: arrayParamAjust(faculties),
			departments: arrayParamAjust(departments),
			page,
			pageSize,
		}
	);
};

export const getAllAcademicUsersByDepartment = (departmentId: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGet(
		{ departmentId }
	);
};

export const getProfessorById = (id: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorByIdIdGet({
		id,
	});
};

export const getProfessorsByIds = (ids: string[]) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorsByIdsGet({
		ids: arrayParamAjust(ids),
	});
};

export const getStudentById = (id: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetStudentByIdIdGet({
		id,
	});
};

export const updateProfessorInfo = async (
	id: string,
	academicUserUpdateDTO: AcademicUserUpdateDTO
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUpdateProfessorIdPut({
		id,
		academicUserUpdateDTO,
	});
};

export const updateStudentInfo = async (
	id: string,
	academicUserUpdateDTO: AcademicUserUpdateDTO
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUpdateStudentIdPut({
		id,
		academicUserUpdateDTO,
	});
};

export const getStudentFilters = (language: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetStudentFiltersGet({
		language,
	});
};

export const getStudentFiltersForNewCompany = (language: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetStudentFiltersForCompanyGet(
		{ language }
	);
};

export const getProfessorFilters = (language: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorFiltersGet({
		language,
	});
};

export const updateAcademicUserRole = async (
	id: string,
	academicUserUpdateRoleDTO: AcademicUserUpdateRoleDTO
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUpdateAcademicUserRoleIdPut(
		{ id, academicUserUpdateRoleDTO }
	);
};

export const getRolesForUser = () => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetRolesForUserGet();
};

export const getGDPRDate = (id: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAcceptGDPRDateForStudentIdGet(
		{ id }
	);
};

export const acceptGDPRDate = (hasAcceptedPublicProfile: boolean) => {
	return AuthAcademicUserApiFactory().apiAcademicUserAcceptGDPRPut({
		hasAcceptedPublicProfile,
	});
};

export const getAvailableStudents = (educationCycle: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetStudentsFromDepartmentGet(
		{ educationCycle }
	);
};

export const getAvailablePracticeSupervisors = (companyId: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGet(
		{ companyId }
	);
};

export const getAllocatedPracticeSupervisors = (
	companyId: string,
	namePattern: string,
	page: number,
	pageSize: number
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGet(
		{ companyId, namePattern, page, pageSize }
	);
};
export const addSupervisedStudyProgram = async (
	supervisorId: string,
	studyProgramId: string
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserAddSupervisedStudyProgramSupervisorIdPut(
		{ supervisorId, body: studyProgramId }
	);
};

export const deleteSupervisedStudyProgram = async (
	supervisorId: string,
	studyProgramId: string
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDelete(
		{ supervisorId, body: studyProgramId }
	);
};

export const toggleAcademicUserNotifications = async () => {
	await AuthAcademicUserApiFactory().apiAcademicUserToggleNotificationsPut();
};

export const toggleOverdueStudentPractice = async (
	id: string,
	year: StudentYearEnum
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserToggleOverduePracticeIdPut({
		id,
		year,
	});
};

export const getAllSupervisors = async (namePattern: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllSupervisorsGet({
		namePattern,
	});
};

export const changeDepartmentForStudent = async (
	id: string,
	departmentId: string
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserChangeDepartmentForStudentIdPut(
		{ id, body: departmentId }
	);
};
