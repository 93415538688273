/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SortDirectionEnum {
    Ascending = 'Ascending',
    Descending = 'Descending'
}

export function SortDirectionEnumFromJSON(json: any): SortDirectionEnum {
    return SortDirectionEnumFromJSONTyped(json, false);
}

export function SortDirectionEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortDirectionEnum {
    return json as SortDirectionEnum;
}

export function SortDirectionEnumToJSON(value?: SortDirectionEnum | null): any {
    return value as any;
}

