import { useState } from "react";
import styles from "./Profile.module.scss";
import { Col, Row, Table } from "antd";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { ErrorCodes, ProfessorDTO } from "../../Api";
import Search from "antd/lib/input/Search";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	getAllocatedSupervisors,
	getAvailableSupervisors,
} from "../../utils/reactQueriesConstants";
import { getAllocatedPracticeSupervisors } from "../../Requests/academic-user-requests";
import { deleteCompanyPracticeSupervisor } from "../../Requests/company-requests";
import { getErrorFromResponse } from "../../utils/responseUtils";
import AddPracticeSupervisorModal from "./AddPracticeSupervisorModal";

const PracticeSupervisorTable = (props: { companyId: string }) => {
	const { t, i18n } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [removeUserModalVisibility, setRemoveUserModalVisibility] =
		useState(false);
	const [selectedUserId, setSelectedUserId] = useState<string>("");
	const [spinning, setSpinning] = useState(false);

	let locale = {
		emptyText: t("tableText.noPracticeSupervisors"),
	};

	const queryClient = useQueryClient();
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getAllocatedSupervisors);
		await queryClient.invalidateQueries(getAvailableSupervisors);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteCompanyPracticeSupervisor(props.companyId, id)
			.then(async () => {
				await invalidateUsersQuery();

				openNotification(
					t("account.supervisorDelete"),
					t("account.supervisorDeleteSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (ex: any) => {
				const error = await getErrorFromResponse(ex);

				openNotification(
					t("account.supervisorDelete"),
					error?.code === ErrorCodes.Forbidden
						? t("account.noPermissions")
						: error?.code === ErrorCodes.SupervisionsOngoing
						? t("account.supervisionsOngoing")
						: error?.code === ErrorCodes.CompanyNotFound
						? t("account.companyNotFound")
						: error?.code === ErrorCodes.SupervisorNotFound
						? t("account.supervisorNotFound")
						: t("account.supervisorDeleteError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setRemoveUserModalVisibility(false);
			});
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: supervisors, isLoading } = useQuery(
		[getAllocatedSupervisors, searchTerm, page, pageSize],
		() =>
			getAllocatedPracticeSupervisors(
				props.companyId,
				searchTerm,
				page,
				pageSize
			),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const FilterByNameInput = (
		<Search
			placeholder={t("account.name")}
			allowClear
			onSearch={(value) => {
				setSearchTerm(value);
				setPage(1);
			}}
			style={{ width: 200 }}
		/>
	);

	const columns = [
		{
			title: FilterByNameInput,
			dataIndex: "name",
			key: "name",
		},
		{
			title: t("account.email"),
			dataIndex: "email",
			key: "email",
		},
		{
			title: t("account.phone"),
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: t("account.department"),
			dataIndex: "department",
			key: "department",
			render: (_text: string | undefined, record: any) =>
				i18n.language === "ro"
					? record.departmentNameRo
					: record.departmentNameEn,
		},
		{
			title: t("account.actions"),
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<div className={styles.modalBtnContainer}>
						<>
							<div className={styles.modalContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedUserId(record.id);
										setRemoveUserModalVisibility(true);
									}}
									icon={<FontAwesomeIcon icon={solid("user-slash")} />}
									title={t("account.deleteSupervisor")}
								/>
								<ConfirmationModal
									modalText={
										t("account.deleteSupervisorMessage") +
										record.name +
										t("account.deleteSupervisorMessage2")
									}
									handleFunction={() => handleDelete(selectedUserId)}
									modalVisibility={removeUserModalVisibility}
									title=""
									changeModalVisibility={() =>
										setRemoveUserModalVisibility(false)
									}
									spinning={spinning}
								/>
							</div>
						</>
					</div>
				</div>
			),
		},
	];

	const handleTableChange = (pagination: any) => {
		setPage(() => pagination.current);
		setPageSize(() => pagination.pageSize);
	};

	return (
		<Col
			span={24}
			className={styles.container}
			style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}
		>
			<Row>
				<AddPracticeSupervisorModal companyId={props.companyId} />
			</Row>
			<Row>
				<Col span={24}>
					<Table
						locale={locale}
						className={styles.usersTable}
						dataSource={supervisors?.data ?? new Array<ProfessorDTO>()}
						columns={columns}
						pagination={{
							total: supervisors?.totalCount,
							current: supervisors?.page,
							pageSize: supervisors?.pageSize,
							pageSizeOptions: ["10", "20", "50"],
							showSizeChanger: true,
							locale: { items_per_page: t("configuration.pagination") },
							position: ["topRight"],
							hideOnSinglePage: supervisors?.totalCount
								? supervisors?.totalCount <= 10
								: true,
						}}
						rowKey={(record) => record.id ?? ""}
						loading={isLoading}
						onChange={handleTableChange}
						scroll={{ x: 400 }}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default PracticeSupervisorTable;
