import { Modal, Spin } from "antd";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../CustomComponents/CustomButton";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { RejectInternshipProps } from "../../PropsLists/RejectInternshipProps";
import CustomEditor from "../../CustomComponents/CustomEditor";

const RejectInternship = (props: PropsWithChildren<RejectInternshipProps>) => {
	const { t } = useTranslation();
	const [description, setDescription] = useState<string>("");
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<div>
			<CustomButton
				visible={props.modalVisibility}
				fontSize={"0.9rem"}
				icon={props.iconButton}
				type={props.typeButton}
				shape={props.shapeButton}
				style={props.styleProp}
				margintop={"0"}
				onClick={handleShow}
				title={props.title}
			>
				{props.nameButton}
			</CustomButton>
			<Modal
				visible={isModalVisible}
				onCancel={handleCancel}
				onOk={() => {
					props.handleFunction(description);
					setDescription("");
				}}
				title={props.title}
				width={window.innerWidth > 1215 ? "50%" : "80%"}
				footer={
					<CustomModalFooter
						handleClose={handleCancel}
						spinning={props.spinning}
						handleSave={() => {
							props.handleFunction(description);
							setDescription("");
						}}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={props.spinning}>
					<p>{props.modalText}</p>

					<CustomEditor
						content={description}
						onEditorChange={(newDescription: string) =>
							setDescription(newDescription)
						}
					/>
				</Spin>
			</Modal>
		</div>
	);
};

export default RejectInternship;
