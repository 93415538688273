/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrollmentStatus,
    EnrollmentStatusFromJSON,
    EnrollmentStatusFromJSONTyped,
    EnrollmentStatusToJSON,
} from './EnrollmentStatus';
import {
    InternshipDocumentState,
    InternshipDocumentStateFromJSON,
    InternshipDocumentStateFromJSONTyped,
    InternshipDocumentStateToJSON,
} from './InternshipDocumentState';
import {
    InternshipEngagementType,
    InternshipEngagementTypeFromJSON,
    InternshipEngagementTypeFromJSONTyped,
    InternshipEngagementTypeToJSON,
} from './InternshipEngagementType';
import {
    InternshipType,
    InternshipTypeFromJSON,
    InternshipTypeFromJSONTyped,
    InternshipTypeToJSON,
} from './InternshipType';
import {
    PracticeCertificateSigningEnum,
    PracticeCertificateSigningEnumFromJSON,
    PracticeCertificateSigningEnumFromJSONTyped,
    PracticeCertificateSigningEnumToJSON,
} from './PracticeCertificateSigningEnum';
import {
    PracticeCertificateState,
    PracticeCertificateStateFromJSON,
    PracticeCertificateStateFromJSONTyped,
    PracticeCertificateStateToJSON,
} from './PracticeCertificateState';
import {
    PracticeNotebookSigningEnum,
    PracticeNotebookSigningEnumFromJSON,
    PracticeNotebookSigningEnumFromJSONTyped,
    PracticeNotebookSigningEnumToJSON,
} from './PracticeNotebookSigningEnum';
import {
    PracticeNotebookState,
    PracticeNotebookStateFromJSON,
    PracticeNotebookStateFromJSONTyped,
    PracticeNotebookStateToJSON,
} from './PracticeNotebookState';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface InternsForEmployerDTO
 */
export interface InternsForEmployerDTO {
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    internshipId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly personalEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly cvUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly linkedinUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly departmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly departmentNameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly departmentNameEn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly facultyNameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly facultyNameEn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly internshipName?: string | null;
    /**
     * 
     * @type {InternshipType}
     * @memberof InternsForEmployerDTO
     */
    internshipType?: InternshipType;
    /**
     * 
     * @type {InternshipEngagementType}
     * @memberof InternsForEmployerDTO
     */
    internshipEngagementType?: InternshipEngagementType;
    /**
     * 
     * @type {Date}
     * @memberof InternsForEmployerDTO
     */
    startingDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InternsForEmployerDTO
     */
    applicationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof InternsForEmployerDTO
     */
    period?: number;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly internshipEmployerId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly internshipEmployerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly internshipTutorId?: string;
    /**
     * 
     * @type {EnrollmentStatus}
     * @memberof InternsForEmployerDTO
     */
    studentStatus?: EnrollmentStatus;
    /**
     * 
     * @type {EnrollmentStatus}
     * @memberof InternsForEmployerDTO
     */
    recruiterStatus?: EnrollmentStatus;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly practiceNotebookId?: string;
    /**
     * 
     * @type {PracticeNotebookState}
     * @memberof InternsForEmployerDTO
     */
    practiceNotebookStatus?: PracticeNotebookState;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly practiceNotebookLastSignatureId?: string;
    /**
     * 
     * @type {PracticeNotebookSigningEnum}
     * @memberof InternsForEmployerDTO
     */
    practiceNotebookLastSigner?: PracticeNotebookSigningEnum;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly practiceCertificateId?: string;
    /**
     * 
     * @type {PracticeCertificateState}
     * @memberof InternsForEmployerDTO
     */
    practiceCertificateStatus?: PracticeCertificateState;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly practiceCertificateLastSignatureId?: string;
    /**
     * 
     * @type {PracticeCertificateSigningEnum}
     * @memberof InternsForEmployerDTO
     */
    practiceCertificateLastSigner?: PracticeCertificateSigningEnum;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly internshipDocumentId?: string;
    /**
     * 
     * @type {InternshipDocumentState}
     * @memberof InternsForEmployerDTO
     */
    internshipDocumentStatus?: InternshipDocumentState;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly documentSigningStudyProgramCoordId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly documentSigningDepartmentDirectorId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly documentSigningDeanId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly internshipDocumentLastSignatureId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly internshipEnrollmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly studyProgram?: string | null;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof InternsForEmployerDTO
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly group?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InternsForEmployerDTO
     */
    availablePositions?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InternsForEmployerDTO
     */
    readonly enrollmentExpired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InternsForEmployerDTO
     */
    readonly isEnrollmentViewed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly professorInternshipId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternsForEmployerDTO
     */
    readonly companyInternshipId?: string;
}

export function InternsForEmployerDTOFromJSON(json: any): InternsForEmployerDTO {
    return InternsForEmployerDTOFromJSONTyped(json, false);
}

export function InternsForEmployerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternsForEmployerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'internshipId': !exists(json, 'internshipId') ? undefined : json['internshipId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'personalEmail': !exists(json, 'personalEmail') ? undefined : json['personalEmail'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'cvUrl': !exists(json, 'cvUrl') ? undefined : json['cvUrl'],
        'linkedinUrl': !exists(json, 'linkedinUrl') ? undefined : json['linkedinUrl'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'departmentNameRo': !exists(json, 'departmentNameRo') ? undefined : json['departmentNameRo'],
        'departmentNameEn': !exists(json, 'departmentNameEn') ? undefined : json['departmentNameEn'],
        'facultyNameRo': !exists(json, 'facultyNameRo') ? undefined : json['facultyNameRo'],
        'facultyNameEn': !exists(json, 'facultyNameEn') ? undefined : json['facultyNameEn'],
        'internshipName': !exists(json, 'internshipName') ? undefined : json['internshipName'],
        'internshipType': !exists(json, 'internshipType') ? undefined : InternshipTypeFromJSON(json['internshipType']),
        'internshipEngagementType': !exists(json, 'internshipEngagementType') ? undefined : InternshipEngagementTypeFromJSON(json['internshipEngagementType']),
        'startingDate': !exists(json, 'startingDate') ? undefined : (new Date(json['startingDate'])),
        'applicationDate': !exists(json, 'applicationDate') ? undefined : (new Date(json['applicationDate'])),
        'period': !exists(json, 'period') ? undefined : json['period'],
        'internshipEmployerId': !exists(json, 'internshipEmployerId') ? undefined : json['internshipEmployerId'],
        'internshipEmployerName': !exists(json, 'internshipEmployerName') ? undefined : json['internshipEmployerName'],
        'internshipTutorId': !exists(json, 'internshipTutorId') ? undefined : json['internshipTutorId'],
        'studentStatus': !exists(json, 'studentStatus') ? undefined : EnrollmentStatusFromJSON(json['studentStatus']),
        'recruiterStatus': !exists(json, 'recruiterStatus') ? undefined : EnrollmentStatusFromJSON(json['recruiterStatus']),
        'practiceNotebookId': !exists(json, 'practiceNotebookId') ? undefined : json['practiceNotebookId'],
        'practiceNotebookStatus': !exists(json, 'practiceNotebookStatus') ? undefined : PracticeNotebookStateFromJSON(json['practiceNotebookStatus']),
        'practiceNotebookLastSignatureId': !exists(json, 'practiceNotebookLastSignatureId') ? undefined : json['practiceNotebookLastSignatureId'],
        'practiceNotebookLastSigner': !exists(json, 'practiceNotebookLastSigner') ? undefined : PracticeNotebookSigningEnumFromJSON(json['practiceNotebookLastSigner']),
        'practiceCertificateId': !exists(json, 'practiceCertificateId') ? undefined : json['practiceCertificateId'],
        'practiceCertificateStatus': !exists(json, 'practiceCertificateStatus') ? undefined : PracticeCertificateStateFromJSON(json['practiceCertificateStatus']),
        'practiceCertificateLastSignatureId': !exists(json, 'practiceCertificateLastSignatureId') ? undefined : json['practiceCertificateLastSignatureId'],
        'practiceCertificateLastSigner': !exists(json, 'practiceCertificateLastSigner') ? undefined : PracticeCertificateSigningEnumFromJSON(json['practiceCertificateLastSigner']),
        'internshipDocumentId': !exists(json, 'internshipDocumentId') ? undefined : json['internshipDocumentId'],
        'internshipDocumentStatus': !exists(json, 'internshipDocumentStatus') ? undefined : InternshipDocumentStateFromJSON(json['internshipDocumentStatus']),
        'documentSigningStudyProgramCoordId': !exists(json, 'documentSigningStudyProgramCoordId') ? undefined : json['documentSigningStudyProgramCoordId'],
        'documentSigningDepartmentDirectorId': !exists(json, 'documentSigningDepartmentDirectorId') ? undefined : json['documentSigningDepartmentDirectorId'],
        'documentSigningDeanId': !exists(json, 'documentSigningDeanId') ? undefined : json['documentSigningDeanId'],
        'internshipDocumentLastSignatureId': !exists(json, 'internshipDocumentLastSignatureId') ? undefined : json['internshipDocumentLastSignatureId'],
        'internshipEnrollmentId': !exists(json, 'internshipEnrollmentId') ? undefined : json['internshipEnrollmentId'],
        'studyProgram': !exists(json, 'studyProgram') ? undefined : json['studyProgram'],
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'group': !exists(json, 'group') ? undefined : json['group'],
        'availablePositions': !exists(json, 'availablePositions') ? undefined : json['availablePositions'],
        'enrollmentExpired': !exists(json, 'enrollmentExpired') ? undefined : json['enrollmentExpired'],
        'isEnrollmentViewed': !exists(json, 'isEnrollmentViewed') ? undefined : json['isEnrollmentViewed'],
        'professorInternshipId': !exists(json, 'professorInternshipId') ? undefined : json['professorInternshipId'],
        'companyInternshipId': !exists(json, 'companyInternshipId') ? undefined : json['companyInternshipId'],
    };
}

export function InternsForEmployerDTOToJSON(value?: InternsForEmployerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'internshipId': value.internshipId,
        'internshipType': InternshipTypeToJSON(value.internshipType),
        'internshipEngagementType': InternshipEngagementTypeToJSON(value.internshipEngagementType),
        'startingDate': value.startingDate === undefined ? undefined : (value.startingDate.toISOString()),
        'applicationDate': value.applicationDate === undefined ? undefined : (value.applicationDate.toISOString()),
        'period': value.period,
        'studentStatus': EnrollmentStatusToJSON(value.studentStatus),
        'recruiterStatus': EnrollmentStatusToJSON(value.recruiterStatus),
        'practiceNotebookStatus': PracticeNotebookStateToJSON(value.practiceNotebookStatus),
        'practiceNotebookLastSigner': PracticeNotebookSigningEnumToJSON(value.practiceNotebookLastSigner),
        'practiceCertificateStatus': PracticeCertificateStateToJSON(value.practiceCertificateStatus),
        'practiceCertificateLastSigner': PracticeCertificateSigningEnumToJSON(value.practiceCertificateLastSigner),
        'internshipDocumentStatus': InternshipDocumentStateToJSON(value.internshipDocumentStatus),
        'year': StudentYearEnumToJSON(value.year),
        'availablePositions': value.availablePositions,
    };
}

