import { Modal, Spin, Form, Select } from "antd";
import { useState } from "react";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import styles from "./Profile.module.scss";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import CustomForm from "../../CustomComponents/CustomForm";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import {
	getAvailablePrograms,
	getSupervisedPrograms,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { ErrorCodes } from "../../Api";
import { addSupervisedStudyProgram } from "../../Requests/academic-user-requests";
import { getAvailableStudyPrograms } from "../../Requests/student-study-requests";

const { Option } = Select;

const AddSupervisedStudyProgramModal = (props: { supervisorId: string }) => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [form] = Form.useForm<{
		name: string;
	}>();

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: availableStudyPrograms } = useQuery(
		[getAvailablePrograms, props.supervisorId],
		() => getAvailableStudyPrograms(props.supervisorId),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const queryClient = useQueryClient();
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getAvailablePrograms);
		await queryClient.invalidateQueries(getSupervisedPrograms);
	};

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const saveChangesError = async (ex: any) => {
		const error = await getErrorFromResponse(ex);

		openNotification(
			t("account.studyProgramAdd"),
			error?.code === ErrorCodes.Forbidden
				? t("account.noPermissions")
				: error?.code === ErrorCodes.SupervisorNotFound
				? t("account.supervisorNotFound")
				: error?.code === ErrorCodes.StudyProgramNotFound
				? t("account.studyProgramNotFound")
				: error?.code === ErrorCodes.SupervisorAlreadyAllocated
				? t("account.studyProgramAlreadyAllocated")
				: t("account.studyProgramAddError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const missingFieldsError = () => {
		openNotification(
			t("account.saveError"),
			t("usersText.requiredFieldsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const handleAdd = () => {
		const state = form.getFieldsValue();

		if (!state.name) {
			missingFieldsError();
			return;
		}

		setSpinning(true);

		addSupervisedStudyProgram(props.supervisorId, state.name)
			.then(async () => {
				form.setFieldsValue({
					name: "",
				});
				await invalidateUsersQuery();
				openNotification(
					t("account.studyProgramAdd"),
					t("account.studyProgramAddSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(saveChangesError)
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	return (
		<div style={{ display: "flex", alignItems: "center", marginTop: "5%" }}>
			<CustomButton
				className={styles.addUserButton}
				backgroundcolor={theme.primaryColor}
				textcolor={theme.white}
				paddingvertical={"19px"}
				fontSize={"0.9rem"}
				boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
				marginbottom={"1em"}
				marginleft={"auto"}
				onClick={handleShow}
				title={t("account.addNewStudyProgram")}
			>
				{t("account.addStudyProgram")}
			</CustomButton>
			<Modal
				visible={isModalVisible}
				onOk={() => form.submit()}
				onCancel={handleClose}
				title={t("account.addStudyProgram")}
				width={window.innerWidth > 1215 ? "30%" : "80%"}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={() => form.submit()}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<Form.Provider onFormFinish={handleAdd}>
						<CustomForm layout={theme.layout} form={form}>
							<Form.Item
								required
								label={t("account.addStudyProgramForm.studyProgramName") + ":"}
								name="name"
								style={{ marginTop: "-10px", marginBottom: "10px" }}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("account.addStudyProgramForm.studyProgramName"),
										}),
									},
								]}
							>
								<Select
									className={styles.antSelect}
									allowClear
									style={{ width: "100%", textAlign: "left" }}
									placeholder={t(
										"account.addStudyProgramForm.studyProgramName"
									)}
								>
									{availableStudyPrograms?.length &&
										availableStudyPrograms?.length > 0 &&
										availableStudyPrograms?.map((sp) => (
											<Option key={sp.id} value={sp.id}>
												{sp.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</CustomForm>
					</Form.Provider>
				</Spin>
			</Modal>
		</div>
	);
};

export default AddSupervisedStudyProgramModal;
