/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    Internship,
    InternshipFromJSON,
    InternshipFromJSONTyped,
    InternshipToJSON,
} from './Internship';
import {
    InternshipParticipationType,
    InternshipParticipationTypeFromJSON,
    InternshipParticipationTypeFromJSONTyped,
    InternshipParticipationTypeToJSON,
} from './InternshipParticipationType';

/**
 * 
 * @export
 * @interface InternshipParticipationIntent
 */
export interface InternshipParticipationIntent {
    /**
     * 
     * @type {string}
     * @memberof InternshipParticipationIntent
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InternshipParticipationIntent
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InternshipParticipationIntent
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InternshipParticipationIntent
     */
    internshipId?: string;
    /**
     * 
     * @type {string}
     * @memberof InternshipParticipationIntent
     */
    studentId?: string;
    /**
     * 
     * @type {Internship}
     * @memberof InternshipParticipationIntent
     */
    internship?: Internship;
    /**
     * 
     * @type {AcademicUser}
     * @memberof InternshipParticipationIntent
     */
    student?: AcademicUser;
    /**
     * 
     * @type {InternshipParticipationType}
     * @memberof InternshipParticipationIntent
     */
    participationType?: InternshipParticipationType;
}

export function InternshipParticipationIntentFromJSON(json: any): InternshipParticipationIntent {
    return InternshipParticipationIntentFromJSONTyped(json, false);
}

export function InternshipParticipationIntentFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipParticipationIntent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'internshipId': !exists(json, 'internshipId') ? undefined : json['internshipId'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'internship': !exists(json, 'internship') ? undefined : InternshipFromJSON(json['internship']),
        'student': !exists(json, 'student') ? undefined : AcademicUserFromJSON(json['student']),
        'participationType': !exists(json, 'participationType') ? undefined : InternshipParticipationTypeFromJSON(json['participationType']),
    };
}

export function InternshipParticipationIntentToJSON(value?: InternshipParticipationIntent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'internshipId': value.internshipId,
        'studentId': value.studentId,
        'internship': InternshipToJSON(value.internship),
        'student': AcademicUserToJSON(value.student),
        'participationType': InternshipParticipationTypeToJSON(value.participationType),
    };
}

