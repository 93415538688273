import { Modal, Input, Spin, Form, Checkbox } from "antd";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../../../CustomComponents/CustomButton";
import CustomModalFooter from "../../../Containers/CustomModalFooter";
import CustomForm from "../../../CustomComponents/CustomForm";
import { AddUpdateCompanyUserModalFormProps } from "./AddUpdateCompanyUserModalForm.types";
import { useAddUpdateCompanyUserFormController } from "./AddUpdateCompanyUserModalForm.controller";

export const AddUpdateCompanyUserModalForm = (
	props: AddUpdateCompanyUserModalFormProps
) => {
	const { t } = useTranslation();
	const { state, actions, computed } =
		useAddUpdateCompanyUserFormController(props);

	return (
		<div style={{ display: "flex", alignItems: "center", marginTop: "5%" }}>
			{props.type === "edit" ? (
				<div>
					<FontAwesomeIcon
						icon={solid("user-pen")}
						onClick={actions.handleShow}
						style={{ cursor: "pointer", height: "1rem" }}
						title={t("account.editUser")}
					/>
				</div>
			) : (
				<CustomButton
					backgroundcolor={theme.primaryColor}
					textcolor={theme.white}
					paddingvertical={"19px"}
					fontSize={"0.9rem"}
					boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
					marginbottom={"1em"}
					marginleft={"auto"}
					onClick={actions.handleShow}
					title={t("account.addNewMember")}
				>
					{t("account.addUser")}
				</CustomButton>
			)}
			<Modal
				visible={state.isModalVisible}
				onOk={() => state.form.submit()}
				onCancel={actions.handleClose}
				title={
					props.type === "add" ? t("account.addUser") : t("account.editUser")
				}
				width={window.innerWidth > 1215 ? "30%" : "80%"}
				footer={
					<CustomModalFooter
						handleClose={actions.handleClose}
						spinning={state.spinning}
						handleSave={() => state.form.submit()}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={state.spinning}>
					<Form.Provider
						onFormFinish={
							props.type === "add" ? actions.handleAdd : actions.handleUpdate
						}
					>
						<CustomForm layout={theme.layout} form={state.form}>
							<Form.Item
								name="name"
								label={`${t("account.name")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.name}
								required
							>
								<Input placeholder={t("account.name")} />
							</Form.Item>

							<Form.Item
								name="email"
								label={`${t("account.email")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.email}
								required
							>
								<Input placeholder={t("account.email")} />
							</Form.Item>

							<Form.Item
								name="phone"
								label={`${t("account.phone")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.phone}
							>
								<Input placeholder={t("account.phone")} />
							</Form.Item>

							<Form.Item
								name="function"
								label={`${t("account.function")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.function}
								required
							>
								<Input placeholder={t("account.function")} />
							</Form.Item>

							<Form.Item
								name="department"
								label={`${t("account.department")}:`}
								initialValue={computed.defaultValues.department}
							>
								<Input placeholder={t("account.department")} />
							</Form.Item>
							{computed.isCompany && computed.isContact !== true && (
								<Form.Item name="isContact" valuePropName="checked">
									<Checkbox>{`${t("profile.contact")}`}</Checkbox>
								</Form.Item>
							)}
						</CustomForm>
					</Form.Provider>
				</Spin>
			</Modal>
		</div>
	);
};
