import { PracticeProtocolApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new PracticeProtocolApi(getAuthConfiguration());

export const generatePracticeProtocolPDF = (facultyId: string) => {
	return ApiFactory().apiPracticeProtocolGeneratePDFProtocolFacultyIdGet({
		facultyId,
	});
};

export const generatePracticeProtocolDocx = (facultyId: string) => {
	return ApiFactory().apiPracticeProtocolGenerateDocxProtocolFacultyIdGet({
		facultyId,
	});
};
