import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "../Components/Home/Home";
import CompanyInternships from "../Components/Internships/CompanyInternships";
import FacultyInternships from "../Components/Internships/FacultyInternships";
import StudentInternships from "../Components/Internships/StudentInternships";
import Register from "../Components/Register/Register";
import Authenticate from "../Components/Authenticate/Authenticate";
import Profile from "../Components/Profile/Profile";
import Account from "../Components/MyAccount/MyAccount";
import Internship from "../Components/InternshipInfo/InternshipInfo";
import Terms from "../Components/Terms/Terms";
import Privacy from "../Components/Privacy/Privacy";
import Questions from "../Components/Questions/Questions";
import Notifications from "../Components/Notifications/Notifications";
import Students from "../Components/Students/Students";
import MyStudents from "../Components/Students/MyStudents";
import MailValidation from "../Components/MailValidation/MailValidation";
import Student from "../Components/Student/Student";
import UniversityAccords from "../Components/UniversityAccords/UniversityAccords";
import PracticeNotebook from "../Components/PracticeNotebooks/PracticeNotebook";
import PracticeCertificate from "../Components/PracticeCertificate/PracticeCertificate";
import Users from "../Components/Admin/Users";
import NotFoundPage from "../Components/ErrorPage/NotFoundPage";
import InternshipDetailedView from "../Components/Internships/InternshipDetailedView";
import InternshipDocument from "../Components/Internships/InternshipDocument";
import Documents from "../Components/Admin/Documents";
import CompanyStudentsTable from "../Components/Students/CompanyStudentsTable";
import {
	isUserLogged,
	safeAnd,
	safeOr,
	useIsAdmin,
	useIsAdministrativeAccordsResponsible,
	useIsCompaniesFeedbackEnabled,
	useIsCompany,
	useIsDean,
	useIsDepartmentDirector,
	useIsFacultyAdmin,
	useIsFacultyContactsResponsible,
	useIsFeedbackAdmin,
	useIsInternshipAdmin,
	useIsNewsletterAdmin,
	useIsPhdStudentsFeedbackEnabled,
	useIsProfessor,
	useIsProfessorsFeedbackEnabled,
	useIsRector,
	useIsStudent,
	useIsStudentsFeedbackEnabled,
	useIsStudyProgramCoordinator,
	useIsUPBAdministrativeUser,
} from "../utils/utilFunctions";
import GuardedRoute from "./GuardedRoute";
import PrivateRoute from "./PrivateRoute";
import Report from "../Components/Report/Report";
import ResetPassword from "../Components/ResetPassword/ResetPassword";
import PracticeStudents from "../Components/PracticeStudents/PracticeStudents";
import ScrollToTop from "../utils/scrollToTop";
import {
	CompaniesPage,
	FacultySettingsPage,
	CompanyContactsPage,
	DiplomaStudentsPage,
	NewsletterEmailsPage,
	PreviousEnrollmentsPage,
	PublishEmailFormPage,
	ApplicantsPage,
	AddInternshipFormPage,
} from "../Components/Pages";
import AllEvents from "../Components/Events/AllEvents";
import BachelorStudentsForm from "../Components/Feedback/BachelorStudentsForm";
import PhdStudentsForm from "../Components/Feedback/PhdStudentsForm";
import ProfesorsForm from "../Components/Feedback/ProfesorsForm";
import CompaniesForm from "../Components/Feedback/CompaniesForm";
import AddResearchProposalForm from "../Components/AddResearchProposalForm/AddResearchProposalForm";
import ResearchOffers from "../Components/ResearchOffers/ResearchOffers";
import ResearchProposal from "../Components/ResearchOffers/ResearchProposal";
import { AddInternshipOfferForm } from "../Components/Forms/AddInternshipForm/components/forms/AddInternshipOfferForm/AddInternshipOfferForm";
import { NewsletterEmailInfoPage } from "../Components/Pages/NewsletterEmailInfoPage";
import { PublishEmailForm } from "../Components/Forms/AddEmailForm/components/forms/PublishEmailForm/PublishEmailForm";
import AdminFeatureFlagsView from "../Components/Feedback/AdminFeatureFlagsView";

const Router = () => {
	const isCompany = useIsCompany();
	const isProfessor = useIsProfessor();
	const isStudent = useIsStudent();
	const isUPBAdministrativeUser = useIsUPBAdministrativeUser();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDean = useIsDean();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isAdmin = useIsAdmin();
	const isNewsletterAdmin = useIsNewsletterAdmin();
	const isRector = useIsRector();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const isFacultyContactsResponsible = useIsFacultyContactsResponsible();

	const { data: isStudentsFeedbackEnabled } = useIsStudentsFeedbackEnabled();
	const { data: isPhdStudentsFeedbackEnabled } =
		useIsPhdStudentsFeedbackEnabled();
	const { data: isCompaniesFeedbackEnabled } = useIsCompaniesFeedbackEnabled();
	const { data: isProfessorsFeedbackEnabled } =
		useIsProfessorsFeedbackEnabled();
	const isFeedbackAdmin = useIsFeedbackAdmin();

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route path="/termeni" component={Terms} />
				<Route path="/politica&confidentialitate" component={Privacy} />
				<Route path="/intrebari-frecvente" component={Questions} />
				<Route path="/propunere/:id" component={Internship} />
				<Route path="/404" component={NotFoundPage} />
				<Route path="/profil" component={Profile} />

				<GuardedRoute exact path="/notificari" component={Notifications} />
				<GuardedRoute exact path="/contul-meu" component={Account} />
				<GuardedRoute exact path="/student" component={Student} />

				<PrivateRoute
					condition={() => !isUserLogged()}
					path="/inregistrare"
					component={Register}
				/>
				<PrivateRoute
					condition={() => !isUserLogged()}
					path="/autentificare"
					component={Authenticate}
				/>
				<PrivateRoute
					condition={() => !isUserLogged()}
					path="/mail-de-validare/:validationToken"
					component={MailValidation}
				/>
				<PrivateRoute
					condition={() => !isUserLogged()}
					path="/resetare-parola/:validationToken"
					component={ResetPassword}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path={"/editare-propunere/:internshipId"}
					component={AddInternshipFormPage}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path="/editare-propunere"
					component={AddInternshipFormPage}
				/>
				<PrivateRoute
					condition={() => isStudent}
					path="/aplicarile-mele"
					component={StudentInternships}
				/>
				<PrivateRoute
					condition={() => isStudent}
					path="/bursele-mele"
					component={StudentInternships}
				/>
				<PrivateRoute
					condition={() => isStudent}
					path="/lucrare-licenta"
					component={InternshipDetailedView}
				/>
				<PrivateRoute
					condition={() => isStudent}
					path="/tema-cercetare"
					component={InternshipDetailedView}
				/>
				<PrivateRoute
					condition={() => isStudent}
					path="/stagiu-practica"
					component={InternshipDetailedView}
				/>
				<PrivateRoute
					condition={() => isStudent}
					path="/alte-pozitii"
					component={StudentInternships}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path="/aplicanti"
					component={ApplicantsPage}
				/>
				<PrivateRoute
					condition={() =>
						safeAnd([
							isUserLogged(),
							!isUPBAdministrativeUser,
							!isAdmin,
							!isFacultyAdmin,
							!isDean,
							!isDepartmentDirector,
							!isStudent,
							!isCompany,
						])
					}
					path="/studenti"
					component={Students}
				/>
				<PrivateRoute
					condition={() => isCompany}
					path="/studentii-mei"
					component={MyStudents}
				/>
				<PrivateRoute
					condition={() => safeOr([isInternshipAdmin, isFacultyAdmin, isDean])}
					path="/studenti-practica"
					component={PracticeStudents}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isUPBAdministrativeUser])}
					path="/acorduri-universitate"
					component={UniversityAccords}
				/>
				<PrivateRoute
					condition={() =>
						safeOr([
							isRector,
							isAdmin,
							isDean,
							isFacultyAdmin,
							isDepartmentDirector,
						])
					}
					path="/utilizatori"
					component={Users}
				/>
				<PrivateRoute
					condition={() => isStudent}
					path="/editare-caiet-practica"
					component={PracticeNotebook}
				/>
				<PrivateRoute
					condition={() =>
						safeOr([
							isProfessor,
							isStudent,
							isDean,
							isStudyProgramCoordinator,
							isDepartmentDirector,
						])
					}
					path="/editare-document-propunere"
					component={InternshipDocument}
				/>
				<PrivateRoute
					condition={() =>
						safeOr([isDean, isDepartmentDirector, isStudyProgramCoordinator])
					}
					path="/rapoarte"
					component={Report}
				/>
				{/* TODO: add new role */}
				<PrivateRoute
					condition={() =>
						safeOr([
							isDean,
							isFacultyContactsResponsible,
							isRector,
							isAdministrativeAccordsResponsible,
						])
					}
					path="/companii-contact"
					component={CompaniesPage}
				/>
				<PrivateRoute
					condition={() =>
						safeOr([
							isDean,
							isFacultyContactsResponsible,
							isRector,
							isAdministrativeAccordsResponsible,
						])
					}
					path="/contacte/:companyId"
					component={CompanyContactsPage}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path="/editare-atestat-practica"
					component={PracticeCertificate}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path="/propuneri"
					component={isCompany ? CompanyInternships : FacultyInternships}
				/>
				<PrivateRoute
					condition={() => safeOr([isStudent, isFacultyAdmin])}
					path="/propuneri-companii"
					component={CompanyInternships}
				/>
				<PrivateRoute
					condition={() => safeOr([isStudent])}
					path="/propuneri-facultati"
					component={FacultyInternships}
				/>
				<PrivateRoute
					condition={() => safeOr([isStudent])}
					path="/evenimente"
					component={AllEvents}
				/>
				<PrivateRoute
					condition={() => isFacultyAdmin || isAdmin || isCompany}
					path="/documente-companie"
					component={Documents}
				/>
				<PrivateRoute
					condition={() => isCompany}
					path="/tabel-studenti"
					component={CompanyStudentsTable}
				/>
				<PrivateRoute
					condition={() =>
						safeOr([isDean, isDepartmentDirector, isStudyProgramCoordinator])
					}
					path="/studenti-diploma"
					component={DiplomaStudentsPage}
				/>
				<PrivateRoute
					condition={() => isNewsletterAdmin}
					path="/editare-email/:emailId"
					component={PublishEmailFormPage}
				/>
				<PrivateRoute
					condition={() => isNewsletterAdmin}
					path="/editare-email"
					component={PublishEmailFormPage}
				/>
				<PrivateRoute
					condition={() => isNewsletterAdmin}
					path="/newsletter"
					component={NewsletterEmailsPage}
				/>
				<PrivateRoute
					condition={() => isNewsletterAdmin}
					path="/newsletter-email/:id"
					component={NewsletterEmailInfoPage}
				/>
				<PrivateRoute
					condition={() => isStudent}
					path="/inrolari-anterioare"
					component={PreviousEnrollmentsPage}
				/>
				<PrivateRoute
					condition={() => isDean}
					path="/settings"
					component={FacultySettingsPage}
				/>

				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path="/propuneri-cercetare"
					component={ResearchOffers}
				/>

				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path={"/editare-propunere/:internshipId"}
					component={AddInternshipOfferForm}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path="/editare-propunere"
					component={AddInternshipOfferForm}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path={"/editare-propunere-cercetare/:researchProposalId"}
					component={AddResearchProposalForm}
				/>
				<PrivateRoute
					condition={() => safeOr([isCompany, isProfessor])}
					path="/editare-propunere-cercetare"
					component={AddResearchProposalForm}
				/>
				<Route path="/propunere-cercetare/:id" component={ResearchProposal} />

				{/* feedback form routes */}
				<PrivateRoute
					condition={() => isUserLogged() && isStudentsFeedbackEnabled?.value}
					path="/students-feedback"
					component={BachelorStudentsForm}
				/>
				<PrivateRoute
					condition={() =>
						isUserLogged() && isPhdStudentsFeedbackEnabled?.value
					}
					path="/phd-feedback"
					component={PhdStudentsForm}
				/>
				<PrivateRoute
					condition={() => isUserLogged() && isProfessorsFeedbackEnabled?.value}
					path="/professors-feedback"
					component={ProfesorsForm}
				/>
				<PrivateRoute
					condition={() => isUserLogged() && isCompaniesFeedbackEnabled?.value}
					path="/companies-feedback"
					component={CompaniesForm}
				/>
				<PrivateRoute
					condition={() => isUserLogged() && isFeedbackAdmin}
					path="/feedback-flags-administration"
					component={AdminFeatureFlagsView}
				/>

				{/*should always be the last one!*/}
				<Route path="*" component={NotFoundPage} />
			</Switch>
		</BrowserRouter>
	);
};

export default Router;
