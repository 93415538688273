import { Col, Form, Row, Image, Space, Spin } from "antd";
import logo from "../../Media/UPB-Connect.png";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";
import "antd/dist/antd.min.css";
import CustomForm from "../../CustomComponents/CustomForm";
import { CustomInput } from "../../CustomComponents/CustomInput";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useState } from "react";
import { resetCompanyUserPassword } from "../../Requests/company-users-requests";
import { StatusCodes } from "http-status-codes";
import Layout from "../../Containers/Layout";

const ResetPassword = () => {
	const { t } = useTranslation();
	const { validationToken } = useParams<{ validationToken: string }>();
	const [state, setState] = useState({ newPassword: "", confirmPassword: "" });
	const [spinning, setSpinning] = useState(false);
	const history = useHistory();

	const validateFields = () => {
		if (!state.newPassword || !state.confirmPassword) {
			openNotification(
				t("account.passwordChangeTitle"),
				t("usersText.requiredFieldsError"),
				NOTIFICATION_TYPES.ERROR
			);

			return false;
		}

		if (state.newPassword.length < 4) {
			openNotification(
				t("account.passwordChangeTitle"),
				t("signupText.messages.lengthPasswordError"),
				NOTIFICATION_TYPES.ERROR
			);
		}

		if (state.newPassword !== state.confirmPassword) {
			openNotification(
				t("account.passwordChangeTitle"),
				t("signupText.messages.mismatchedPasswords"),
				NOTIFICATION_TYPES.ERROR
			);

			return false;
		}

		return true;
	};

	const handleChange = (event: any, field: any) => {
		event.persist();

		setState((prevState) => {
			return {
				...prevState,
				[field]: event.target.value,
			};
		});
	};

	const handlePasswordChange = () => {
		if (!validateFields()) {
			return;
		}

		setSpinning(true);

		const reqBody = {
			newPassword: state.newPassword,
		};

		resetCompanyUserPassword(validationToken, reqBody)
			.then(() => {
				openNotification(
					t("account.passwordChangeTitle"),
					t("account.passwordChangeSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);

				history.push("/autentificare");
			})
			.catch((ex) => {
				if (ex.status && ex.status === StatusCodes.UNAUTHORIZED) {
					openNotification(
						t("resetPasswordText.passwordResetFailed"),
						t("resetPasswordText.errorExpire"),
						NOTIFICATION_TYPES.ERROR
					);
				} else if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
					openNotification(
						t("resetPasswordText.passwordResetFailed"),
						t("resetPasswordText.errorNotFound"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("resetPasswordText.passwordResetFailed"),
						t("resetPasswordText.errorOther"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
			});
	};

	return (
		<Layout>
			<Space size="large" direction="vertical">
				<Row justify={"center"}>
					<Col>
						<Image
							src={logo}
							preview={false}
							style={{
								width: window.innerWidth > 500 ? 400 : 200,
								height: 300,
								marginTop: "2rem",
							}}
						/>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col span={window.innerWidth > 1100 ? 8 : 24}>
						{t("account.changePasswordMessage")}
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col span={window.innerWidth > 1100 ? 8 : 24}>
						<CustomForm layout={theme.layout} boxshadow={"none"}>
							<Form.Item
								name="newPassword"
								label={`${t("account.newPassword")}:`}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("account.newPassword"),
										}),
									},
									{
										min: 4,
										message: t("signupText.messages.lengthPasswordError"),
									},
								]}
							>
								<CustomInput
									backgroundcolor={theme.white}
									type={"password"}
									onChange={(event: any) => handleChange(event, "newPassword")}
								/>
							</Form.Item>

							<Form.Item
								name="confirmPassword"
								label={`${t("account.confirmPassword")}:`}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("account.confirmPassword"),
										}),
									},
									{
										min: 4,
										message: t("signupText.messages.lengthPasswordError"),
									},
								]}
							>
								<CustomInput
									backgroundcolor={theme.white}
									type={"password"}
									onChange={(event: any) =>
										handleChange(event, "confirmPassword")
									}
								/>
							</Form.Item>

							<Spin spinning={spinning}>
								<CustomButton
									backgroundcolor={theme.primaryColor}
									textcolor={theme.white}
									onClick={handlePasswordChange}
								>
									{t("account.passwordChange")}
								</CustomButton>
							</Spin>
						</CustomForm>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col>
						<CustomButton
							backgroundcolor={theme.secondColor}
							textcolor={theme.white}
						>
							<Link to="/">{t("resetPasswordText.redirect")}</Link>
						</CustomButton>
					</Col>
				</Row>
			</Space>
		</Layout>
	);
};

export default ResetPassword;
