/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PracticeConventionSigningEnum,
    PracticeConventionSigningEnumFromJSON,
    PracticeConventionSigningEnumFromJSONTyped,
    PracticeConventionSigningEnumToJSON,
} from './PracticeConventionSigningEnum';

/**
 * 
 * @export
 * @interface PracticeConventionSignatureDTO
 */
export interface PracticeConventionSignatureDTO {
    /**
     * 
     * @type {PracticeConventionSigningEnum}
     * @memberof PracticeConventionSignatureDTO
     */
    signer?: PracticeConventionSigningEnum;
    /**
     * 
     * @type {Date}
     * @memberof PracticeConventionSignatureDTO
     */
    createdAt?: Date;
}

export function PracticeConventionSignatureDTOFromJSON(json: any): PracticeConventionSignatureDTO {
    return PracticeConventionSignatureDTOFromJSONTyped(json, false);
}

export function PracticeConventionSignatureDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeConventionSignatureDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signer': !exists(json, 'signer') ? undefined : PracticeConventionSigningEnumFromJSON(json['signer']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function PracticeConventionSignatureDTOToJSON(value?: PracticeConventionSignatureDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signer': PracticeConventionSigningEnumToJSON(value.signer),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

