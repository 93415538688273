import { Route, Redirect } from "react-router-dom";
import { isUserLogged } from "../utils/utilFunctions";
import React, { useEffect, useState } from "react";

const GuardedRoute = (props: any) => {
	const [permission, setPermission] = useState(null as any);

	useEffect(() => {
		function waitForPermission() {
			if (typeof isUserLogged() !== "undefined") {
				setPermission(isUserLogged());
			} else {
				setTimeout(waitForPermission, 250);
			}
		}

		waitForPermission();
	}, [permission, props]);

	if (permission != null)
		return permission ? <Route {...props} /> : <Redirect to="/404" />;

	return <></>;
};

export default GuardedRoute;
