import {
	NewsletterApi,
	NewsletterSortFieldEnum,
	PublishUpdateEmailDTO,
	SortDirectionEnum,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthNewsletterApiFactory = () =>
	new NewsletterApi(getAuthConfiguration());

export const getPublishedNewsletterEmails = (
	searchTerm: string,
	page: number,
	pageSize: number,
	sortField: NewsletterSortFieldEnum,
	sortDirection: SortDirectionEnum
) => {
	return AuthNewsletterApiFactory().apiNewsletterGetEmailsForPublisherGet({
		searchTerm,
		page,
		pageSize,
		sortField,
		sortDirection,
	});
};

export const getNewsletterEmailRecipients = (
	id: string,
	page: number,
	pageSize: number
) => {
	return AuthNewsletterApiFactory().apiNewsletterGetRecipientsForEmailIdGet({
		id,
		page,
		pageSize,
	});
};

export const getNewsletterEmailById = (id: string) => {
	return AuthNewsletterApiFactory().apiNewsletterGetEmailByIdIdGet({ id });
};

export const publishEmail = async (
	publishUpdateEmailDTO: PublishUpdateEmailDTO
) => {
	await AuthNewsletterApiFactory().apiNewsletterPublishEmailPost({
		publishUpdateEmailDTO,
	});
};

export const updateEmail = async (
	id: string,
	publishUpdateEmailDTO: PublishUpdateEmailDTO
) => {
	await AuthNewsletterApiFactory().apiNewsletterUpdateEmailIdPut({
		id,
		publishUpdateEmailDTO,
	});
};

export const toggleEmailExpeditionEnabled = async (id: string) => {
	await AuthNewsletterApiFactory().apiNewsletterToggleExpeditionEnabledIdPut({
		id,
	});
};

export const deleteNewsletterEmail = async (id: string) => {
	await AuthNewsletterApiFactory().apiNewsletterInvalidateEmailIdDelete({ id });
};
