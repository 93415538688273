/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FormTypeEnum,
    FormTypeEnumFromJSON,
    FormTypeEnumToJSON,
    QuestionDTO,
    QuestionDTOFromJSON,
    QuestionDTOToJSON,
} from '../models';

export interface ApiFeedbackQuestionsGetQuestionsByFormGetRequest {
    formType?: FormTypeEnum;
}

/**
 * 
 */
export class FeedbackQuestionsApi extends runtime.BaseAPI {

    /**
     */
    async apiFeedbackQuestionsGetQuestionsByFormGetRaw(requestParameters: ApiFeedbackQuestionsGetQuestionsByFormGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<QuestionDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.formType !== undefined) {
            queryParameters['FormType'] = requestParameters.formType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FeedbackQuestions/GetQuestionsByForm`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuestionDTOFromJSON));
    }

    /**
     */
    async apiFeedbackQuestionsGetQuestionsByFormGet(requestParameters: ApiFeedbackQuestionsGetQuestionsByFormGetRequest = {}, initOverrides?: RequestInit): Promise<Array<QuestionDTO>> {
        const response = await this.apiFeedbackQuestionsGetQuestionsByFormGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
