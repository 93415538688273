/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TraineesFilterEnum {
    Faculty = 'Faculty',
    EngagementType = 'EngagementType',
    Status = 'Status',
    StudyProgram = 'StudyProgram',
    Year = 'Year',
    View = 'View'
}

export function TraineesFilterEnumFromJSON(json: any): TraineesFilterEnum {
    return TraineesFilterEnumFromJSONTyped(json, false);
}

export function TraineesFilterEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TraineesFilterEnum {
    return json as TraineesFilterEnum;
}

export function TraineesFilterEnumToJSON(value?: TraineesFilterEnum | null): any {
    return value as any;
}

