import { useState } from "react";
import {
	Table,
	TablePaginationConfig,
	Row,
	Col,
	Drawer,
	Layout,
	Button,
	Dropdown,
	Menu,
	MenuProps,
	Modal,
	Spin,
	Typography,
} from "antd";
import {
	ErrorCodes,
	Filter,
	SimpleViewStudentDTO,
	StudentYearEnum,
} from "../../Api";
import {
	getAllStudents,
	getStudentFilters,
	toggleOverdueStudentPractice,
} from "../../Requests/academic-user-requests";
import { useHistory } from "react-router-dom";
import Search from "antd/lib/input/Search";
import styles from "./Students.module.scss";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { Key } from "antd/lib/table/interface";
import { studentIdSet } from "../Student/StudentSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	getAllKeys,
	getKey,
	useIsAdmin,
	useIsInternshipAdmin,
	useIsRector,
} from "../../utils/utilFunctions";
import CustomFilter from "../../CustomComponents/CustomFilter";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { useQuery, useQueryClient } from "react-query";
import {
	getStudents,
	getStudentsFilters,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";

enum ActionKeyEnum {
	ViewLinkedInAccount,
	ViewCV,
	MarkOverdueL2Practice,
	RemoveOverdueL2Practice,
	MarkOverdueL3Practice,
	RemoveOverdueL3Practice,
}

const StudentsTable = () => {
	const { t, i18n } = useTranslation();
	const isAdmin = useIsAdmin();
	const isRector = useIsRector();
	const isInternshipAdmin = useIsInternshipAdmin();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [faculties, setFaculties] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [studyPrograms, setStudyPrograms] = useState([]);
	const [years, setYears] = useState([]);
	const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
	const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
	const [visible, setVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [modalText, setModalText] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [handleFunction, setHandleFunction] = useState(() => () => {});
	const [spinning, setSpinning] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const queryClient = useQueryClient();

	const FilterByNameInput = (
		<Search
			placeholder={t("students.name")}
			allowClear
			onSearch={(value) => {
				setSearchTerm(value);
				setCurrent(1);
			}}
			className={styles.search}
		/>
	);

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const onClose = () => {
		setVisible(false);
	};

	const showDrawer = () => {
		setVisible(true);
	};

	const toggleOverduePractice = (studentId: string, year: StudentYearEnum) => {
		setSpinning(true);

		toggleOverdueStudentPractice(studentId, year)
			.then(async () => {
				openNotification(
					t("students.updatePracticeOverdueStatus"),
					t("students.updatePracticeOverdueStatusSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);

				await queryClient.invalidateQueries(getStudents);
				await queryClient.invalidateQueries(getStudentsFilters);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.UserNotFound:
						notificationMessage = t("students.studentNotFound");
						break;
					case ErrorCodes.Forbidden:
						notificationMessage = t("students.notAllowed");
						break;
					default:
						notificationMessage = t(
							"students.updatePracticeOverdueStatusError"
						);
						break;
				}

				openNotification(
					t("students.updatePracticeOverdueStatus"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const getActions = (record: SimpleViewStudentDTO): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		actions.push(
			{
				label: t("students.cvUrl"),
				key: ActionKeyEnum.ViewCV + "StudentsTables",
				icon: (
					<FontAwesomeIcon
						icon={solid("download")}
						style={{ fontSize: "15px" }}
					/>
				),
			},
			{
				label: t("students.viewLinkedIn"),
				key: ActionKeyEnum.ViewLinkedInAccount + "StudentsTables",
				icon: (
					<FontAwesomeIcon
						icon={solid("arrow-right")}
						style={{ fontSize: "15px" }}
					/>
				),
			}
		);

		if (
			isInternshipAdmin &&
			(record.year === StudentYearEnum.L3 ||
				record.year === StudentYearEnum.L4) &&
			record.facultyNameRo === "Facultatea de Inginerie Industrială și Robotică"
		) {
			if (!record.hasOverdueL2Practice) {
				actions.push({
					label: t("students.markOverdueL2Practice"),
					key: ActionKeyEnum.MarkOverdueL2Practice + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("circle-xmark")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			} else {
				actions.push({
					label: t("students.removeOverdueL2Practice"),
					key: ActionKeyEnum.RemoveOverdueL2Practice + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("circle-check")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			}
		}

		if (
			isInternshipAdmin &&
			(record.year === StudentYearEnum.L3 || record.year === StudentYearEnum.L4)
		) {
			if (!record.hasOverdueL3Practice) {
				actions.push({
					label: t("students.markOverdueL3Practice"),
					key: ActionKeyEnum.MarkOverdueL3Practice + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("circle-xmark")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			} else {
				actions.push({
					label: t("students.removeOverdueL3Practice"),
					key: ActionKeyEnum.RemoveOverdueL3Practice + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("circle-check")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			}
		}

		return actions;
	};

	const onMenuClick = (e: any, record: SimpleViewStudentDTO) => {
		if (e.key == ActionKeyEnum.ViewCV + "StudentsTables") {
			window.location.replace(record.cvUrl!);
		} else if (e.key == ActionKeyEnum.ViewLinkedInAccount + "StudentsTables") {
			window.location.replace(record.linkedinUrl!);
		} else if (
			e.key ==
			ActionKeyEnum.MarkOverdueL2Practice + "StudentsTables"
		) {
			setModalTitle(t("students.markOverdueL2Practice"));
			setModalText(
				t("students.markOverdueL2PracticeMessage") + record.name + "?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => toggleOverduePractice(record.id!, StudentYearEnum.L2)
			);
		} else if (
			e.key ==
			ActionKeyEnum.RemoveOverdueL2Practice + "StudentsTables"
		) {
			setModalTitle(t("students.removeOverdueL2Practice"));
			setModalText(
				t("students.removeOverdueL2PracticeMessage") + record.name + "?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => toggleOverduePractice(record.id!, StudentYearEnum.L2)
			);
		} else if (
			e.key ==
			ActionKeyEnum.MarkOverdueL3Practice + "StudentsTables"
		) {
			setModalTitle(t("students.markOverdueL3Practice"));
			setModalText(
				t("students.markOverdueL3PracticeMessage") + record.name + "?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => toggleOverduePractice(record.id!, StudentYearEnum.L3)
			);
		} else if (
			e.key ==
			ActionKeyEnum.RemoveOverdueL3Practice + "StudentsTables"
		) {
			setModalTitle(t("students.removeOverdueL3Practice"));
			setModalText(
				t("students.removeOverdueL3PracticeMessage") + record.name + "?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => toggleOverduePractice(record.id!, StudentYearEnum.L3)
			);
		}
	};

	const columns = [
		{
			title: FilterByNameInput,
			dataIndex: "name",
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("students.faculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
			hidden: (!isAdmin && !isRector) || window.innerWidth < 650,
		},
		{
			title: t("students.studyProgram"),
			dataIndex: "studyProgram",
			key: "studyProgram",
			hidden: isAdmin || isRector,
		},
		{
			title: t("students.year"),
			dataIndex: "year",
			key: "year",
		},
		{
			title: t("students.actions"),
			dataIndex: "actions",
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	].filter((item) => !item.hidden);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[
			getStudents,
			currentPage,
			pageSize,
			searchTerm,
			studyPrograms,
			years,
			faculties,
			departments,
			studyPrograms,
		],
		() => {
			return getAllStudents(
				searchTerm,
				currentPage,
				pageSize,
				years,
				faculties,
				departments,
				studyPrograms
			);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const { data: filters } = useQuery(
		[getStudentsFilters, i18n.language],
		() => {
			return getStudentFilters(i18n.language);
		},
		{
			onError: openGetErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
		}
	);

	const handleTableChange = (pagination: TablePaginationConfig) => {
		setCurrent(() => pagination.current ?? 1);
		setPageSize(() => pagination.pageSize ?? 5);
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrent(1);
		setFaculties(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Faculty@"))
				.map((e: string) => e.split("@")[1])
		);

		setDepartments(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Department@"))
				.map((e: string) => e.split("@")[1])
		);

		setYears(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Year@"))
				.map((e: string) => e.split("@")[1])
		);

		setStudyPrograms(
			checkedKeysValue
				.filter((e: string) => e.startsWith("StudyProgram@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters.map((x) => {
			if (
				x.key === "Faculty" ||
				x.key === "Department" ||
				x.key === "StudyProgram"
			) {
				return {
					key: x.key,
					title: t("students.filters." + x.title),
					children: x.children,
				};
			} else {
				return {
					key: x.key,
					title: t("students.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("students.filters." + y.title),
						}))
					),
				};
			}
		});
	};

	return (
		<Layout>
			<Row className={styles.filterButton}>
				<Button
					onClick={showDrawer}
					style={{ outline: "none", border: "none", boxShadow: "none" }}
				>
					<FontAwesomeIcon
						icon={solid("filter")}
						style={{ color: theme.secondColor }}
					/>
					&nbsp;{t("internships.filters.filter")}
				</Button>
			</Row>
			<Row>
				<Col span={4} className={styles.filters}>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Col>
				<Col xxl={20} xl={24} lg={24} md={23} style={{ padding: "0 0 0 1%" }}>
					<Table
						dataSource={data?.data || []}
						columns={columns}
						pagination={{
							total: data?.totalCount,
							current: currentPage,
							pageSize: data?.pageSize,
							pageSizeOptions: ["10", "20", "50"],
							defaultPageSize: 10,
							hideOnSinglePage: data?.totalCount
								? data?.totalCount <= 10
								: true,
							showSizeChanger: true,
							locale: { items_per_page: t("configuration.pagination") },
							position: ["topRight", "bottomRight"],
						}}
						rowKey={getKey}
						loading={loading}
						onChange={handleTableChange}
						scroll={{ x: 400 }}
					/>
					<Drawer
						className={styles.opened}
						placement={"left"}
						closable={true}
						onClose={onClose}
						visible={visible}
						key={"left"}
						maskClosable={true}
						title={t("internships.filters.filters")}
					>
						<CustomFilter
							filters={filters}
							onCheck={onCheck}
							checkedKeys={checkedKeys}
							expandedKeys={expandedKeys}
							onExpand={onExpand}
							translateFilters={translateFilters}
						/>
					</Drawer>
				</Col>
			</Row>
			<Modal
				visible={isModalVisible}
				onOk={handleFunction}
				onCancel={handleClose}
				title={modalTitle}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={handleFunction}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<Typography>{modalText}</Typography>
					</div>
				</Spin>
			</Modal>
		</Layout>
	);
};

export default StudentsTable;
