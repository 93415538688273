import { List } from "antd";
import {
	useIsAdmin,
	useIsCompany,
	useIsDean,
	useIsDepartmentDirector,
	useIsDepartmentSecretary,
	useIsFacultyAdmin,
	useIsInternshipAdmin,
	useIsProfessor,
	useIsStudent,
	useIsStudyProgramCoordinator,
	useIsUPBAdministrativeUser,
} from "../../utils/utilFunctions";
import { InternshipDTO } from "../../Api";
import { useTranslation } from "react-i18next";
import { useInternshipListController } from "./InternshipList.controller";
import { InternshipListProps } from "../../PropsLists/InternshipListProps";
import { PropsWithChildren } from "react";
import { InternshipListItem } from "./components/listItems/InternshipListItem/InternshipListItem";

const InternshipsList = (props: PropsWithChildren<InternshipListProps>) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const isDean = useIsDean();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isDepartmentSecretary = useIsDepartmentSecretary();
	const isCompany = useIsCompany();
	const isAdmin = useIsAdmin();
	const isUPBAdministrativeUser = useIsUPBAdministrativeUser();

	const { state, actions } = useInternshipListController(props);

	const areFacultyInternships =
		props.location.pathname === "/propuneri-facultati" ||
		(isProfessor &&
			props.location.pathname === "/propuneri" &&
			props.activeTab !== "2" &&
			props.activeTab !== "6");

	let locale = {
		emptyText: t("tableText.noOffers"),
	};

	return (
		<>
			<List
				locale={locale}
				itemLayout="vertical"
				size="large"
				pagination={{
					total: props.data?.totalCount,
					current: props.currentPage,
					pageSize: props.pageSize,
					pageSizeOptions: ["10", "20", "50"],
					showSizeChanger: true,
					locale: { items_per_page: t("configuration.pagination") },
					position: "both",
					hideOnSinglePage: props.data?.totalCount
						? props.data?.totalCount <= 10
						: true,
					onChange: props.handleListChange,
				}}
				loading={props.loading && !props.data}
				dataSource={props.data?.data || []}
				renderItem={(item: InternshipDTO) => (
					<InternshipListItem
						item={item}
						state={state}
						actions={actions}
						isProfessor={isProfessor}
						isDean={isDean}
						isStudent={isStudent}
						isFacultyAdmin={isFacultyAdmin}
						isInternshipAdmin={isInternshipAdmin}
						isDepartmentDirector={isDepartmentDirector}
						isStudyProgramCoordinator={isStudyProgramCoordinator}
						isDepartmentSecretary={isDepartmentSecretary}
						isCompany={isCompany}
						isAdmin={isAdmin}
						isUPBAdministrativeUser={isUPBAdministrativeUser}
						areFacultyInternships={areFacultyInternships}
						{...props}
					/>
				)}
			/>
		</>
	);
};

export default InternshipsList;
