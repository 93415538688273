/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EnrollmentStatus {
    Waiting = 'Waiting',
    ProfessorAccepted = 'ProfessorAccepted',
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

export function EnrollmentStatusFromJSON(json: any): EnrollmentStatus {
    return EnrollmentStatusFromJSONTyped(json, false);
}

export function EnrollmentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnrollmentStatus {
    return json as EnrollmentStatus;
}

export function EnrollmentStatusToJSON(value?: EnrollmentStatus | null): any {
    return value as any;
}

