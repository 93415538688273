/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyInternship,
    CompanyInternshipFromJSON,
    CompanyInternshipFromJSONTyped,
    CompanyInternshipToJSON,
} from './CompanyInternship';

/**
 * 
 * @export
 * @interface InternshipCompetence
 */
export interface InternshipCompetence {
    /**
     * 
     * @type {string}
     * @memberof InternshipCompetence
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InternshipCompetence
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InternshipCompetence
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof InternshipCompetence
     */
    index?: number;
    /**
     * 
     * @type {string}
     * @memberof InternshipCompetence
     */
    competence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipCompetence
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipCompetence
     */
    place?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipCompetence
     */
    activity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipCompetence
     */
    observations?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternshipCompetence
     */
    companyInternshipId?: string;
    /**
     * 
     * @type {CompanyInternship}
     * @memberof InternshipCompetence
     */
    companyInternship?: CompanyInternship;
}

export function InternshipCompetenceFromJSON(json: any): InternshipCompetence {
    return InternshipCompetenceFromJSONTyped(json, false);
}

export function InternshipCompetenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternshipCompetence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'index': !exists(json, 'index') ? undefined : json['index'],
        'competence': !exists(json, 'competence') ? undefined : json['competence'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'activity': !exists(json, 'activity') ? undefined : json['activity'],
        'observations': !exists(json, 'observations') ? undefined : json['observations'],
        'companyInternshipId': !exists(json, 'companyInternshipId') ? undefined : json['companyInternshipId'],
        'companyInternship': !exists(json, 'companyInternship') ? undefined : CompanyInternshipFromJSON(json['companyInternship']),
    };
}

export function InternshipCompetenceToJSON(value?: InternshipCompetence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'index': value.index,
        'competence': value.competence,
        'type': value.type,
        'place': value.place,
        'activity': value.activity,
        'observations': value.observations,
        'companyInternshipId': value.companyInternshipId,
        'companyInternship': CompanyInternshipToJSON(value.companyInternship),
    };
}

