import { EmailsListProps } from "./EmailsList.types";
import { EmailListItem } from "../../listItems/EmailListItem/EmailListItem";
import { NewsletterEmailDTO } from "../../../../../../Api";
import { List } from "antd";
import { useTranslation } from "react-i18next";

const EmailsList = (props: EmailsListProps) => {
	const { t } = useTranslation();

	let locale = {
		emptyText: t("tableText.noEmails"),
	};

	return (
		<List
			locale={locale}
			itemLayout="vertical"
			size="large"
			pagination={{
				total: props.data?.totalCount,
				current: props.currentPage,
				pageSize: props.pageSize,
				pageSizeOptions: ["10", "20", "50"],
				showSizeChanger: true,
				locale: { items_per_page: t("configuration.pagination") },
				position: "both",
				hideOnSinglePage: props.data?.totalCount
					? props.data?.totalCount <= 10
					: true,
				onChange: props.handleListChange,
			}}
			loading={props.loading && !props.data}
			dataSource={props.data?.data || []}
			renderItem={(item: NewsletterEmailDTO) => (
				<EmailListItem
					item={item}
					currentPage={props.currentPage}
					pageSize={props.pageSize}
					searchTerm={props.searchTerm}
					sortDirection={props.sortDirection}
					sortField={props.sortField}
					location={props.location}
				/>
			)}
		/>
	);
};

export default EmailsList;
