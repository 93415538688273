import { useState } from "react";
import styles from "./NavBar.module.scss";
import { Link, useLocation } from "react-router-dom";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import {
	getUserId,
	isUserLogged,
	useIsCompany,
	useIsStudent,
} from "../../utils/utilFunctions";
import { logout } from "../../utils/utilFunctions";
import { useTranslation } from "react-i18next";
import HeadersLinks from "./HeadersLinks";
import { studentIdSet } from "../../Components/Student/StudentSlice";
import { useDispatch } from "react-redux";
import { Drawer } from "antd";

const NavBarMobile = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const location = useLocation();
	const isStudent = useIsStudent();
	const isCompany = useIsCompany();

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	return (
		<div className={styles.navBar}>
			<Drawer
				className={styles.opened}
				placement={"top"}
				closable={true}
				onClose={onClose}
				visible={visible}
				size={"large"}
				key={"top"}
			>
				<div className={styles.opened}>
					<div className={styles.drawerHeaderContainer}>
						<HeadersLinks />
						{isUserLogged() && (
							<Link
								to="/notificari"
								style={{
									fontWeight:
										location.pathname === "/notificari" ? "bold" : "normal",
									color: theme.secondColor,
									fontSize: "20px",
									padding: "5px",
								}}
							>
								{t("header.notifications")}
							</Link>
						)}
					</div>
					<div className={styles.line} />
					<div className={styles.openLink}>
						{!isUserLogged() ? (
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								marginleft={"auto"}
								marginright={"auto"}
								marginbottom={"5%"}
							>
								<Link to="/autentificare">{t("header.login")}</Link>
							</CustomButton>
						) : !isStudent ? (
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								marginleft={"auto"}
								marginright={"auto"}
								marginbottom={"5%"}
								title={t("header.myAccount")}
							>
								<Link to="/profil">{t("header.myAccount")}</Link>
							</CustomButton>
						) : (
							<CustomButton
								backgroundcolor={theme.primaryColor}
								textcolor={theme.white}
								marginleft={"auto"}
								marginright={"auto"}
								marginbottom={"5%"}
								title={t("header.profile")}
							>
								<Link
									onClick={() => dispatch(studentIdSet(getUserId()))}
									to="/student"
								>
									{t("header.myAccount")}
								</Link>
							</CustomButton>
						)}
					</div>
					<div className={styles.openLink}>
						{!isUserLogged() ? (
							<CustomButton
								backgroundcolor={theme.secondColor}
								textcolor={theme.white}
								marginleft={"auto"}
								marginright={"auto"}
							>
								<Link to="/inregistrare">{t("header.signup")}</Link>
							</CustomButton>
						) : (
							<CustomButton
								backgroundcolor={theme.secondColor}
								textcolor={theme.white}
								onClick={() => logout(!isCompany, i18n)}
								marginleft={"auto"}
								marginright={"auto"}
								title={t("header.signout")}
							>
								<Link to="/">{t("header.logout")}</Link>
							</CustomButton>
						)}
					</div>
				</div>
			</Drawer>
			<span
				style={{ fontSize: "17px", cursor: "pointer" }}
				onClick={showDrawer}
			>
				<h1>&#9776;</h1>
			</span>
		</div>
	);
};

export default NavBarMobile;
