/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalStudentData,
    AdditionalStudentDataFromJSON,
    AdditionalStudentDataFromJSONTyped,
    AdditionalStudentDataToJSON,
} from './AdditionalStudentData';
import {
    Answer,
    AnswerFromJSON,
    AnswerFromJSONTyped,
    AnswerToJSON,
} from './Answer';
import {
    CompanyInternship,
    CompanyInternshipFromJSON,
    CompanyInternshipFromJSONTyped,
    CompanyInternshipToJSON,
} from './CompanyInternship';
import {
    CompanyPracticeSupervisor,
    CompanyPracticeSupervisorFromJSON,
    CompanyPracticeSupervisorFromJSONTyped,
    CompanyPracticeSupervisorToJSON,
} from './CompanyPracticeSupervisor';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';
import {
    EmailRecipient,
    EmailRecipientFromJSON,
    EmailRecipientFromJSONTyped,
    EmailRecipientToJSON,
} from './EmailRecipient';
import {
    InternshipEnrollment,
    InternshipEnrollmentFromJSON,
    InternshipEnrollmentFromJSONTyped,
    InternshipEnrollmentToJSON,
} from './InternshipEnrollment';
import {
    NewsletterEmail,
    NewsletterEmailFromJSON,
    NewsletterEmailFromJSONTyped,
    NewsletterEmailToJSON,
} from './NewsletterEmail';
import {
    PracticeConvention,
    PracticeConventionFromJSON,
    PracticeConventionFromJSONTyped,
    PracticeConventionToJSON,
} from './PracticeConvention';
import {
    ProfessionalTitleEnum,
    ProfessionalTitleEnumFromJSON,
    ProfessionalTitleEnumFromJSONTyped,
    ProfessionalTitleEnumToJSON,
} from './ProfessionalTitleEnum';
import {
    ProfessorInternship,
    ProfessorInternshipFromJSON,
    ProfessorInternshipFromJSONTyped,
    ProfessorInternshipToJSON,
} from './ProfessorInternship';
import {
    ProfessorInternshipCoTutor,
    ProfessorInternshipCoTutorFromJSON,
    ProfessorInternshipCoTutorFromJSONTyped,
    ProfessorInternshipCoTutorToJSON,
} from './ProfessorInternshipCoTutor';
import {
    PushNotificationSubscription,
    PushNotificationSubscriptionFromJSON,
    PushNotificationSubscriptionFromJSONTyped,
    PushNotificationSubscriptionToJSON,
} from './PushNotificationSubscription';
import {
    RepartitionResults,
    RepartitionResultsFromJSON,
    RepartitionResultsFromJSONTyped,
    RepartitionResultsToJSON,
} from './RepartitionResults';
import {
    ResearchProposalEnrollment,
    ResearchProposalEnrollmentFromJSON,
    ResearchProposalEnrollmentFromJSONTyped,
    ResearchProposalEnrollmentToJSON,
} from './ResearchProposalEnrollment';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import {
    SkillEndorsement,
    SkillEndorsementFromJSON,
    SkillEndorsementFromJSONTyped,
    SkillEndorsementToJSON,
} from './SkillEndorsement';
import {
    StudentAppreciation,
    StudentAppreciationFromJSON,
    StudentAppreciationFromJSONTyped,
    StudentAppreciationToJSON,
} from './StudentAppreciation';
import {
    StudentPersonalData,
    StudentPersonalDataFromJSON,
    StudentPersonalDataFromJSONTyped,
    StudentPersonalDataToJSON,
} from './StudentPersonalData';
import {
    StudentStudyProgram,
    StudentStudyProgramFromJSON,
    StudentStudyProgramFromJSONTyped,
    StudentStudyProgramToJSON,
} from './StudentStudyProgram';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';
import {
    StudyProgram,
    StudyProgramFromJSON,
    StudyProgramFromJSONTyped,
    StudyProgramToJSON,
} from './StudyProgram';
import {
    UniversityAccordReview,
    UniversityAccordReviewFromJSON,
    UniversityAccordReviewFromJSONTyped,
    UniversityAccordReviewToJSON,
} from './UniversityAccordReview';
import {
    UserSkill,
    UserSkillFromJSON,
    UserSkillFromJSONTyped,
    UserSkillToJSON,
} from './UserSkill';

/**
 * 
 * @export
 * @interface AcademicUser
 */
export interface AcademicUser {
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AcademicUser
     */
    cursId?: number;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    personalEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    cvUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    linkedinUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    gitUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    siteUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    researchField?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AcademicUser
     */
    areasOfInterest?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    imageFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    departmentId?: string;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof AcademicUser
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    group?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AcademicUser
     */
    hasOverdueL2Practice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AcademicUser
     */
    hasOverdueL3Practice?: boolean;
    /**
     * 
     * @type {ProfessionalTitleEnum}
     * @memberof AcademicUser
     */
    professionalTitle?: ProfessionalTitleEnum;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AcademicUser
     */
    roles?: Array<Role> | null;
    /**
     * 
     * @type {Department}
     * @memberof AcademicUser
     */
    department?: Department;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    gdprAcceptDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    publicProfileAcceptDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof AcademicUser
     */
    sendNotifications?: boolean;
    /**
     * 
     * @type {StudentPersonalData}
     * @memberof AcademicUser
     */
    studentPersonalData?: StudentPersonalData;
    /**
     * 
     * @type {AdditionalStudentData}
     * @memberof AcademicUser
     */
    additionalStudentData?: AdditionalStudentData;
    /**
     * 
     * @type {RepartitionResults}
     * @memberof AcademicUser
     */
    repartitionResults?: RepartitionResults;
    /**
     * 
     * @type {Array<PushNotificationSubscription>}
     * @memberof AcademicUser
     */
    pushNotificationSubscriptions?: Array<PushNotificationSubscription> | null;
    /**
     * 
     * @type {Array<UserSkill>}
     * @memberof AcademicUser
     */
    userSkills?: Array<UserSkill> | null;
    /**
     * 
     * @type {Array<SkillEndorsement>}
     * @memberof AcademicUser
     */
    givenSkillEndorsements?: Array<SkillEndorsement> | null;
    /**
     * 
     * @type {Array<StudentAppreciation>}
     * @memberof AcademicUser
     */
    receivedStudentAppreciations?: Array<StudentAppreciation> | null;
    /**
     * 
     * @type {Array<StudentAppreciation>}
     * @memberof AcademicUser
     */
    givenStudentAppreciations?: Array<StudentAppreciation> | null;
    /**
     * 
     * @type {Array<ProfessorInternship>}
     * @memberof AcademicUser
     */
    professorInternships?: Array<ProfessorInternship> | null;
    /**
     * 
     * @type {Array<ProfessorInternship>}
     * @memberof AcademicUser
     */
    professorInternshipsAsCoTutor?: Array<ProfessorInternship> | null;
    /**
     * 
     * @type {Array<ProfessorInternshipCoTutor>}
     * @memberof AcademicUser
     */
    professorInternshipCoTutors?: Array<ProfessorInternshipCoTutor> | null;
    /**
     * 
     * @type {Array<InternshipEnrollment>}
     * @memberof AcademicUser
     */
    internshipEnrollments?: Array<InternshipEnrollment> | null;
    /**
     * 
     * @type {Array<ResearchProposalEnrollment>}
     * @memberof AcademicUser
     */
    researchProposalEnrollments?: Array<ResearchProposalEnrollment> | null;
    /**
     * 
     * @type {Array<PracticeConvention>}
     * @memberof AcademicUser
     */
    practiceConventionsAsSupervisor?: Array<PracticeConvention> | null;
    /**
     * 
     * @type {Array<CompanyInternship>}
     * @memberof AcademicUser
     */
    companyInternshipsAsFacultyCoordinator?: Array<CompanyInternship> | null;
    /**
     * 
     * @type {Array<UniversityAccordReview>}
     * @memberof AcademicUser
     */
    universityAccordReviews?: Array<UniversityAccordReview> | null;
    /**
     * 
     * @type {Array<StudentStudyProgram>}
     * @memberof AcademicUser
     */
    studentStudyPrograms?: Array<StudentStudyProgram> | null;
    /**
     * 
     * @type {Array<StudyProgram>}
     * @memberof AcademicUser
     */
    studyProgramsAsCoordinator?: Array<StudyProgram> | null;
    /**
     * 
     * @type {Array<CompanyPracticeSupervisor>}
     * @memberof AcademicUser
     */
    companyPracticeSupervisors?: Array<CompanyPracticeSupervisor> | null;
    /**
     * 
     * @type {Array<StudyProgram>}
     * @memberof AcademicUser
     */
    supervisedStudyPrograms?: Array<StudyProgram> | null;
    /**
     * 
     * @type {Array<NewsletterEmail>}
     * @memberof AcademicUser
     */
    publishedNewsletterEmails?: Array<NewsletterEmail> | null;
    /**
     * 
     * @type {Array<EmailRecipient>}
     * @memberof AcademicUser
     */
    receivedNewsletterEmails?: Array<EmailRecipient> | null;
    /**
     * 
     * @type {Array<Answer>}
     * @memberof AcademicUser
     */
    feedbackAnswers?: Array<Answer> | null;
}

export function AcademicUserFromJSON(json: any): AcademicUser {
    return AcademicUserFromJSONTyped(json, false);
}

export function AcademicUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcademicUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cursId': !exists(json, 'cursId') ? undefined : json['cursId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'personalEmail': !exists(json, 'personalEmail') ? undefined : json['personalEmail'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'cvUrl': !exists(json, 'cvUrl') ? undefined : json['cvUrl'],
        'linkedinUrl': !exists(json, 'linkedinUrl') ? undefined : json['linkedinUrl'],
        'gitUrl': !exists(json, 'gitUrl') ? undefined : json['gitUrl'],
        'siteUrl': !exists(json, 'siteUrl') ? undefined : json['siteUrl'],
        'researchField': !exists(json, 'researchField') ? undefined : json['researchField'],
        'areasOfInterest': !exists(json, 'areasOfInterest') ? undefined : json['areasOfInterest'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'imageFileName': !exists(json, 'imageFileName') ? undefined : json['imageFileName'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'group': !exists(json, 'group') ? undefined : json['group'],
        'hasOverdueL2Practice': !exists(json, 'hasOverdueL2Practice') ? undefined : json['hasOverdueL2Practice'],
        'hasOverdueL3Practice': !exists(json, 'hasOverdueL3Practice') ? undefined : json['hasOverdueL3Practice'],
        'professionalTitle': !exists(json, 'professionalTitle') ? undefined : ProfessionalTitleEnumFromJSON(json['professionalTitle']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'department': !exists(json, 'department') ? undefined : DepartmentFromJSON(json['department']),
        'gdprAcceptDate': !exists(json, 'gdprAcceptDate') ? undefined : (json['gdprAcceptDate'] === null ? null : new Date(json['gdprAcceptDate'])),
        'publicProfileAcceptDate': !exists(json, 'publicProfileAcceptDate') ? undefined : (json['publicProfileAcceptDate'] === null ? null : new Date(json['publicProfileAcceptDate'])),
        'sendNotifications': !exists(json, 'sendNotifications') ? undefined : json['sendNotifications'],
        'studentPersonalData': !exists(json, 'studentPersonalData') ? undefined : StudentPersonalDataFromJSON(json['studentPersonalData']),
        'additionalStudentData': !exists(json, 'additionalStudentData') ? undefined : AdditionalStudentDataFromJSON(json['additionalStudentData']),
        'repartitionResults': !exists(json, 'repartitionResults') ? undefined : RepartitionResultsFromJSON(json['repartitionResults']),
        'pushNotificationSubscriptions': !exists(json, 'pushNotificationSubscriptions') ? undefined : (json['pushNotificationSubscriptions'] === null ? null : (json['pushNotificationSubscriptions'] as Array<any>).map(PushNotificationSubscriptionFromJSON)),
        'userSkills': !exists(json, 'userSkills') ? undefined : (json['userSkills'] === null ? null : (json['userSkills'] as Array<any>).map(UserSkillFromJSON)),
        'givenSkillEndorsements': !exists(json, 'givenSkillEndorsements') ? undefined : (json['givenSkillEndorsements'] === null ? null : (json['givenSkillEndorsements'] as Array<any>).map(SkillEndorsementFromJSON)),
        'receivedStudentAppreciations': !exists(json, 'receivedStudentAppreciations') ? undefined : (json['receivedStudentAppreciations'] === null ? null : (json['receivedStudentAppreciations'] as Array<any>).map(StudentAppreciationFromJSON)),
        'givenStudentAppreciations': !exists(json, 'givenStudentAppreciations') ? undefined : (json['givenStudentAppreciations'] === null ? null : (json['givenStudentAppreciations'] as Array<any>).map(StudentAppreciationFromJSON)),
        'professorInternships': !exists(json, 'professorInternships') ? undefined : (json['professorInternships'] === null ? null : (json['professorInternships'] as Array<any>).map(ProfessorInternshipFromJSON)),
        'professorInternshipsAsCoTutor': !exists(json, 'professorInternshipsAsCoTutor') ? undefined : (json['professorInternshipsAsCoTutor'] === null ? null : (json['professorInternshipsAsCoTutor'] as Array<any>).map(ProfessorInternshipFromJSON)),
        'professorInternshipCoTutors': !exists(json, 'professorInternshipCoTutors') ? undefined : (json['professorInternshipCoTutors'] === null ? null : (json['professorInternshipCoTutors'] as Array<any>).map(ProfessorInternshipCoTutorFromJSON)),
        'internshipEnrollments': !exists(json, 'internshipEnrollments') ? undefined : (json['internshipEnrollments'] === null ? null : (json['internshipEnrollments'] as Array<any>).map(InternshipEnrollmentFromJSON)),
        'researchProposalEnrollments': !exists(json, 'researchProposalEnrollments') ? undefined : (json['researchProposalEnrollments'] === null ? null : (json['researchProposalEnrollments'] as Array<any>).map(ResearchProposalEnrollmentFromJSON)),
        'practiceConventionsAsSupervisor': !exists(json, 'practiceConventionsAsSupervisor') ? undefined : (json['practiceConventionsAsSupervisor'] === null ? null : (json['practiceConventionsAsSupervisor'] as Array<any>).map(PracticeConventionFromJSON)),
        'companyInternshipsAsFacultyCoordinator': !exists(json, 'companyInternshipsAsFacultyCoordinator') ? undefined : (json['companyInternshipsAsFacultyCoordinator'] === null ? null : (json['companyInternshipsAsFacultyCoordinator'] as Array<any>).map(CompanyInternshipFromJSON)),
        'universityAccordReviews': !exists(json, 'universityAccordReviews') ? undefined : (json['universityAccordReviews'] === null ? null : (json['universityAccordReviews'] as Array<any>).map(UniversityAccordReviewFromJSON)),
        'studentStudyPrograms': !exists(json, 'studentStudyPrograms') ? undefined : (json['studentStudyPrograms'] === null ? null : (json['studentStudyPrograms'] as Array<any>).map(StudentStudyProgramFromJSON)),
        'studyProgramsAsCoordinator': !exists(json, 'studyProgramsAsCoordinator') ? undefined : (json['studyProgramsAsCoordinator'] === null ? null : (json['studyProgramsAsCoordinator'] as Array<any>).map(StudyProgramFromJSON)),
        'companyPracticeSupervisors': !exists(json, 'companyPracticeSupervisors') ? undefined : (json['companyPracticeSupervisors'] === null ? null : (json['companyPracticeSupervisors'] as Array<any>).map(CompanyPracticeSupervisorFromJSON)),
        'supervisedStudyPrograms': !exists(json, 'supervisedStudyPrograms') ? undefined : (json['supervisedStudyPrograms'] === null ? null : (json['supervisedStudyPrograms'] as Array<any>).map(StudyProgramFromJSON)),
        'publishedNewsletterEmails': !exists(json, 'publishedNewsletterEmails') ? undefined : (json['publishedNewsletterEmails'] === null ? null : (json['publishedNewsletterEmails'] as Array<any>).map(NewsletterEmailFromJSON)),
        'receivedNewsletterEmails': !exists(json, 'receivedNewsletterEmails') ? undefined : (json['receivedNewsletterEmails'] === null ? null : (json['receivedNewsletterEmails'] as Array<any>).map(EmailRecipientFromJSON)),
        'feedbackAnswers': !exists(json, 'feedbackAnswers') ? undefined : (json['feedbackAnswers'] === null ? null : (json['feedbackAnswers'] as Array<any>).map(AnswerFromJSON)),
    };
}

export function AcademicUserToJSON(value?: AcademicUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'cursId': value.cursId,
        'email': value.email,
        'personalEmail': value.personalEmail,
        'description': value.description,
        'phone': value.phone,
        'cvUrl': value.cvUrl,
        'linkedinUrl': value.linkedinUrl,
        'gitUrl': value.gitUrl,
        'siteUrl': value.siteUrl,
        'researchField': value.researchField,
        'areasOfInterest': value.areasOfInterest,
        'image': value.image,
        'imageFileName': value.imageFileName,
        'departmentId': value.departmentId,
        'year': StudentYearEnumToJSON(value.year),
        'group': value.group,
        'hasOverdueL2Practice': value.hasOverdueL2Practice,
        'hasOverdueL3Practice': value.hasOverdueL3Practice,
        'professionalTitle': ProfessionalTitleEnumToJSON(value.professionalTitle),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(RoleToJSON)),
        'department': DepartmentToJSON(value.department),
        'gdprAcceptDate': value.gdprAcceptDate === undefined ? undefined : (value.gdprAcceptDate === null ? null : value.gdprAcceptDate.toISOString()),
        'publicProfileAcceptDate': value.publicProfileAcceptDate === undefined ? undefined : (value.publicProfileAcceptDate === null ? null : value.publicProfileAcceptDate.toISOString()),
        'sendNotifications': value.sendNotifications,
        'studentPersonalData': StudentPersonalDataToJSON(value.studentPersonalData),
        'additionalStudentData': AdditionalStudentDataToJSON(value.additionalStudentData),
        'repartitionResults': RepartitionResultsToJSON(value.repartitionResults),
        'pushNotificationSubscriptions': value.pushNotificationSubscriptions === undefined ? undefined : (value.pushNotificationSubscriptions === null ? null : (value.pushNotificationSubscriptions as Array<any>).map(PushNotificationSubscriptionToJSON)),
        'userSkills': value.userSkills === undefined ? undefined : (value.userSkills === null ? null : (value.userSkills as Array<any>).map(UserSkillToJSON)),
        'givenSkillEndorsements': value.givenSkillEndorsements === undefined ? undefined : (value.givenSkillEndorsements === null ? null : (value.givenSkillEndorsements as Array<any>).map(SkillEndorsementToJSON)),
        'receivedStudentAppreciations': value.receivedStudentAppreciations === undefined ? undefined : (value.receivedStudentAppreciations === null ? null : (value.receivedStudentAppreciations as Array<any>).map(StudentAppreciationToJSON)),
        'givenStudentAppreciations': value.givenStudentAppreciations === undefined ? undefined : (value.givenStudentAppreciations === null ? null : (value.givenStudentAppreciations as Array<any>).map(StudentAppreciationToJSON)),
        'professorInternships': value.professorInternships === undefined ? undefined : (value.professorInternships === null ? null : (value.professorInternships as Array<any>).map(ProfessorInternshipToJSON)),
        'professorInternshipsAsCoTutor': value.professorInternshipsAsCoTutor === undefined ? undefined : (value.professorInternshipsAsCoTutor === null ? null : (value.professorInternshipsAsCoTutor as Array<any>).map(ProfessorInternshipToJSON)),
        'professorInternshipCoTutors': value.professorInternshipCoTutors === undefined ? undefined : (value.professorInternshipCoTutors === null ? null : (value.professorInternshipCoTutors as Array<any>).map(ProfessorInternshipCoTutorToJSON)),
        'internshipEnrollments': value.internshipEnrollments === undefined ? undefined : (value.internshipEnrollments === null ? null : (value.internshipEnrollments as Array<any>).map(InternshipEnrollmentToJSON)),
        'researchProposalEnrollments': value.researchProposalEnrollments === undefined ? undefined : (value.researchProposalEnrollments === null ? null : (value.researchProposalEnrollments as Array<any>).map(ResearchProposalEnrollmentToJSON)),
        'practiceConventionsAsSupervisor': value.practiceConventionsAsSupervisor === undefined ? undefined : (value.practiceConventionsAsSupervisor === null ? null : (value.practiceConventionsAsSupervisor as Array<any>).map(PracticeConventionToJSON)),
        'companyInternshipsAsFacultyCoordinator': value.companyInternshipsAsFacultyCoordinator === undefined ? undefined : (value.companyInternshipsAsFacultyCoordinator === null ? null : (value.companyInternshipsAsFacultyCoordinator as Array<any>).map(CompanyInternshipToJSON)),
        'universityAccordReviews': value.universityAccordReviews === undefined ? undefined : (value.universityAccordReviews === null ? null : (value.universityAccordReviews as Array<any>).map(UniversityAccordReviewToJSON)),
        'studentStudyPrograms': value.studentStudyPrograms === undefined ? undefined : (value.studentStudyPrograms === null ? null : (value.studentStudyPrograms as Array<any>).map(StudentStudyProgramToJSON)),
        'studyProgramsAsCoordinator': value.studyProgramsAsCoordinator === undefined ? undefined : (value.studyProgramsAsCoordinator === null ? null : (value.studyProgramsAsCoordinator as Array<any>).map(StudyProgramToJSON)),
        'companyPracticeSupervisors': value.companyPracticeSupervisors === undefined ? undefined : (value.companyPracticeSupervisors === null ? null : (value.companyPracticeSupervisors as Array<any>).map(CompanyPracticeSupervisorToJSON)),
        'supervisedStudyPrograms': value.supervisedStudyPrograms === undefined ? undefined : (value.supervisedStudyPrograms === null ? null : (value.supervisedStudyPrograms as Array<any>).map(StudyProgramToJSON)),
        'publishedNewsletterEmails': value.publishedNewsletterEmails === undefined ? undefined : (value.publishedNewsletterEmails === null ? null : (value.publishedNewsletterEmails as Array<any>).map(NewsletterEmailToJSON)),
        'receivedNewsletterEmails': value.receivedNewsletterEmails === undefined ? undefined : (value.receivedNewsletterEmails === null ? null : (value.receivedNewsletterEmails as Array<any>).map(EmailRecipientToJSON)),
        'feedbackAnswers': value.feedbackAnswers === undefined ? undefined : (value.feedbackAnswers === null ? null : (value.feedbackAnswers as Array<any>).map(AnswerToJSON)),
    };
}

