/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyDTOPagedResponse,
    CompanyDTOPagedResponseFromJSON,
    CompanyDTOPagedResponseToJSON,
    Faculty,
    FacultyFromJSON,
    FacultyToJSON,
    FacultySettingsDTO,
    FacultySettingsDTOFromJSON,
    FacultySettingsDTOToJSON,
} from '../models';

export interface ApiFacultyGetAllFacultiesWithASpecificCompanyAccordCompanyIdGetRequest {
    companyId: string;
    namePattern?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiFacultyGetAllGetRequest {
    namePattern?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiFacultyGetByIdIdGetRequest {
    id: string;
}

export interface ApiFacultyGetCompaniesForFacultyGetRequest {
    namePattern?: string;
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class FacultyApi extends runtime.BaseAPI {

    /**
     */
    async apiFacultyGetAllFacultiesWithASpecificCompanyAccordCompanyIdGetRaw(requestParameters: ApiFacultyGetAllFacultiesWithASpecificCompanyAccordCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Faculty>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiFacultyGetAllFacultiesWithASpecificCompanyAccordCompanyIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Faculty/GetAllFacultiesWithASpecificCompanyAccord/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FacultyFromJSON));
    }

    /**
     */
    async apiFacultyGetAllFacultiesWithASpecificCompanyAccordCompanyIdGet(requestParameters: ApiFacultyGetAllFacultiesWithASpecificCompanyAccordCompanyIdGetRequest, initOverrides?: RequestInit): Promise<Array<Faculty>> {
        const response = await this.apiFacultyGetAllFacultiesWithASpecificCompanyAccordCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFacultyGetAllGetRaw(requestParameters: ApiFacultyGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Faculty>>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Faculty/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FacultyFromJSON));
    }

    /**
     */
    async apiFacultyGetAllGet(requestParameters: ApiFacultyGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Faculty>> {
        const response = await this.apiFacultyGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFacultyGetByIdIdGetRaw(requestParameters: ApiFacultyGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Faculty>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFacultyGetByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Faculty/GetById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FacultyFromJSON(jsonValue));
    }

    /**
     */
    async apiFacultyGetByIdIdGet(requestParameters: ApiFacultyGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<Faculty> {
        const response = await this.apiFacultyGetByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFacultyGetCompaniesForFacultyGetRaw(requestParameters: ApiFacultyGetCompaniesForFacultyGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Faculty/GetCompaniesForFaculty`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiFacultyGetCompaniesForFacultyGet(requestParameters: ApiFacultyGetCompaniesForFacultyGetRequest = {}, initOverrides?: RequestInit): Promise<CompanyDTOPagedResponse> {
        const response = await this.apiFacultyGetCompaniesForFacultyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFacultyGetForUserGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FacultySettingsDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Faculty/GetForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FacultySettingsDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiFacultyGetForUserGet(initOverrides?: RequestInit): Promise<FacultySettingsDTO> {
        const response = await this.apiFacultyGetForUserGetRaw(initOverrides);
        return await response.value();
    }

}
