import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import logo from "../../Media/UPB-Connect-white.png";
import polilogo from "../../Media/image1.png";
import crescdi from "../../Media/crescdi.png";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	isUserLogged,
	useIsAdmin,
	useIsCompany,
	useIsDean,
	useIsDepartmentDirector,
	useIsDepartmentSecretary,
	useIsFacultyAdmin,
	useIsInternshipAdmin,
	useIsProfessor,
	useIsRector,
	useIsStudent,
	useIsStudyProgramCoordinator,
} from "../../utils/utilFunctions";

const Footer = () => {
	const { t } = useTranslation();
	const isCompany = useIsCompany();
	const isStudent = useIsStudent();
	const isProfessor = useIsProfessor();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isAdmin = useIsAdmin();
	const isDean = useIsDean();
	const isRector = useIsRector();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDepartmentSecretary = useIsDepartmentSecretary();

	return (
		<div id={"FooterContainer"} className={styles.footerContainer}>
			<div className={styles.firstline}>
				<div className={styles.firstColumn}>
					<img alt={"logo"} src={logo} />
				</div>
				<div className={styles.secondColumn}>
					<div className={styles.firstChild}>
						<h1>{t("footer.public")}</h1>
						<div className={styles.middle}>
							<div className={styles.first}>
								<Link to="/">
									<FontAwesomeIcon
										icon={solid("house")}
										title={t("header.home")}
									/>
								</Link>
								{isStudent && isUserLogged() ? (
									<Link to="/propuneri-companii">
										{" "}
										<FontAwesomeIcon
											icon={solid("building")}
											title={t("footer.companyInternships")}
										/>
									</Link>
								) : null}
								{isStudent && isUserLogged() ? (
									<Link to="/propuneri-facultati">
										{" "}
										<FontAwesomeIcon
											icon={solid("user-graduate")}
											title={t("footer.facultyInternships")}
										/>
									</Link>
								) : null}
								{isStudent && isUserLogged() ? (
									<Link to="/evenimente">
										{" "}
										<FontAwesomeIcon
											icon={solid("calendar-check")}
											title={t("footer.events")}
										/>
									</Link>
								) : null}
								{isCompany && isUserLogged() ? (
									<Link to="/propuneri">
										{" "}
										<FontAwesomeIcon
											icon={solid("building")}
											title={t("footer.companyInternships")}
										/>
									</Link>
								) : null}
								{(isProfessor ||
									isFacultyAdmin ||
									isAdmin ||
									isDean ||
									isRector ||
									isInternshipAdmin ||
									isStudyProgramCoordinator ||
									isDepartmentDirector ||
									isDepartmentSecretary) &&
								isUserLogged() ? (
									<Link to="/propuneri">
										{" "}
										<FontAwesomeIcon
											icon={solid("user-graduate")}
											title={t("footer.facultyInternships")}
										/>
									</Link>
								) : null}
								<Link to="/intrebari-frecvente">
									<FontAwesomeIcon
										icon={solid("circle-question")}
										title={t("footer.frequentQuestions")}
									/>
								</Link>
								<Link
									to={{
										pathname:
											"https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdFN0dWRlbnRpLnBkZg==&version_id=null",
									}}
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									<FontAwesomeIcon
										icon={solid("book-open-reader")}
										title={t("footer.studentsGuide")}
									/>
								</Link>
								<Link
									to={{
										pathname:
											"https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdENvbXBhbmlpLnBkZg==&version_id=null",
									}}
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									<FontAwesomeIcon
										icon={solid("book-open-reader")}
										title={t("footer.companiesGuide")}
									/>
								</Link>
								<Link
									to={{
										pathname:
											"https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdFByb2Zlc29yaS5wZGY=&version_id=null",
									}}
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									<FontAwesomeIcon
										icon={solid("book-open-reader")}
										title={t("footer.professorsGuide")}
									/>
								</Link>
							</div>
							<div className={styles.second}>
								<Link to="/">{t("footer.home")}</Link>
								{isStudent && isUserLogged() ? (
									<Link to="/propuneri-companii">
										{" "}
										{t("footer.companyInternships")}
									</Link>
								) : null}
								{isStudent && isUserLogged() ? (
									<Link to="/propuneri-facultati">
										{" "}
										{t("footer.facultyInternships")}
									</Link>
								) : null}
								{isStudent && isUserLogged() ? (
									<Link to="/evenimente"> {t("footer.events")}</Link>
								) : null}
								{isCompany && isUserLogged() ? (
									<Link to="/propuneri"> {t("footer.companyInternships")}</Link>
								) : null}
								{(isProfessor ||
									isFacultyAdmin ||
									isAdmin ||
									isDean ||
									isRector ||
									isInternshipAdmin ||
									isStudyProgramCoordinator ||
									isDepartmentDirector ||
									isDepartmentSecretary) &&
								isUserLogged() ? (
									<Link to="/propuneri"> {t("footer.facultyInternships")}</Link>
								) : null}
								<Link to="/intrebari-frecvente">
									{t("footer.frequentQuestions")}
								</Link>
								<Link
									to={{
										pathname:
											"https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdFN0dWRlbnRpLnBkZg==&version_id=null",
									}}
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									{t("footer.studentsGuide")}
								</Link>
								<Link
									to={{
										pathname:
											"https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdENvbXBhbmlpLnBkZg==&version_id=null",
									}}
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									{t("footer.companiesGuide")}
								</Link>
								<Link
									to={{
										pathname:
											"https://docs.google.com/document/d/1RHZgqsgPyKnjHejeZM-a_AAXElo3em3OfIW3mq7A47c/edit",
									}}
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									{t("footer.professorsGuide")}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.secondline}>
				<img
					src={polilogo}
					alt={"upb logo"}
					style={{ width: window.innerWidth > 1215 ? "8vw" : "15vw" }}
				/>
				<img
					src={crescdi}
					alt={"crescdi logo"}
					style={{ width: window.innerWidth > 1215 ? "15vw" : "35vw" }}
				/>
				<h1>{t("footer.copyright")}</h1>
				<div className={styles.middle}>
					<ul className={styles.footerLi}>
						<li>
							<Link to="/termeni">{t("footer.terms")}</Link>
						</li>
						<li>
							<Link to="/politica&confidentialitate">{t("footer.gdpr")}</Link>
						</li>
						<li>
							<a
								href="https://support.upb.ro/"
								target="_blank"
								rel={"noreferrer"}
								className="tnc__21mP0"
							>
								{t("footer.support")}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Footer;
