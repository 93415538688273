/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import {
    CompanyUser,
    CompanyUserFromJSON,
    CompanyUserFromJSONTyped,
    CompanyUserToJSON,
} from './CompanyUser';
import {
    ResearchProposal,
    ResearchProposalFromJSON,
    ResearchProposalFromJSONTyped,
    ResearchProposalToJSON,
} from './ResearchProposal';

/**
 * 
 * @export
 * @interface CompanyResearchProposal
 */
export interface CompanyResearchProposal {
    /**
     * 
     * @type {string}
     * @memberof CompanyResearchProposal
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyResearchProposal
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyResearchProposal
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyResearchProposal
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResearchProposal
     */
    companyUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResearchProposal
     */
    researchProposalId?: string;
    /**
     * 
     * @type {Company}
     * @memberof CompanyResearchProposal
     */
    company?: Company;
    /**
     * 
     * @type {CompanyUser}
     * @memberof CompanyResearchProposal
     */
    companyUser?: CompanyUser;
    /**
     * 
     * @type {ResearchProposal}
     * @memberof CompanyResearchProposal
     */
    researchProposal?: ResearchProposal;
}

export function CompanyResearchProposalFromJSON(json: any): CompanyResearchProposal {
    return CompanyResearchProposalFromJSONTyped(json, false);
}

export function CompanyResearchProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyResearchProposal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyUserId': !exists(json, 'companyUserId') ? undefined : json['companyUserId'],
        'researchProposalId': !exists(json, 'researchProposalId') ? undefined : json['researchProposalId'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'companyUser': !exists(json, 'companyUser') ? undefined : CompanyUserFromJSON(json['companyUser']),
        'researchProposal': !exists(json, 'researchProposal') ? undefined : ResearchProposalFromJSON(json['researchProposal']),
    };
}

export function CompanyResearchProposalToJSON(value?: CompanyResearchProposal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'companyId': value.companyId,
        'companyUserId': value.companyUserId,
        'researchProposalId': value.researchProposalId,
        'company': CompanyToJSON(value.company),
        'companyUser': CompanyUserToJSON(value.companyUser),
        'researchProposal': ResearchProposalToJSON(value.researchProposal),
    };
}

