import { Avatar, Checkbox, Col, List, Row, Select, Switch } from "antd";
import styles from "../Internships/Internships.module.scss";
import EventActions from "./EventActions";
import {
	useIsCompany,
	useIsDepartmentDirector,
	useIsDepartmentSecretary,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudent,
	useIsStudyProgramCoordinator,
} from "../../utils/utilFunctions";
import { Link } from "react-router-dom";
import { InternshipDTO, InternshipType } from "../../Api";
import { useTranslation } from "react-i18next";
import { EventListProps } from "../../PropsLists/EventListProps";
import { PropsWithChildren } from "react";
import { useEventListController } from "./EventList.controller";

const { Option } = Select;

const EventsList = (props: PropsWithChildren<EventListProps>) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isDepartmentSecretary = useIsDepartmentSecretary();
	const isCompany = useIsCompany();

	const { state, actions } = useEventListController(props);

	let locale = {
		emptyText: t("tableText.noOffers"),
	};

	return (
		<List
			locale={locale}
			itemLayout="vertical"
			size="large"
			pagination={{
				total: props.data?.totalCount,
				current: props.currentPage,
				pageSize: props.pageSize,
				pageSizeOptions: ["10", "20", "50"],
				showSizeChanger: true,
				locale: { items_per_page: t("configuration.pagination") },
				position: "both",
				hideOnSinglePage: props.data?.totalCount
					? props.data?.totalCount <= 10
					: true,
				onChange: props.handleListChange,
			}}
			loading={props.loading && !props.data}
			dataSource={props.data?.data || []}
			renderItem={(item: InternshipDTO) => (
				<List.Item
					className={styles.ListItem}
					style={{
						border:
							isProfessor &&
							!isDepartmentDirector &&
							item.type !== InternshipType.ByCompany &&
							item.isValid !== true
								? "1px solid #EF6873"
								: "1px solid lightgray",
					}}
					title={
						isProfessor &&
						!isDepartmentDirector &&
						item.type !== InternshipType.ByCompany &&
						item.isValid !== true
							? t("internships.invalidProposal")
							: ""
					}
					key={item.id}
					actions={[<EventActions item={item} activeTab={null} />].concat()}
					// adds content to the right of the list item, can be used for interaction buttons for professor
					// and company (i.e. delete, edit, view applicants)
					// extra={

					// }
				>
					<List.Item.Meta
						className={styles.listItemMeta}
						avatar={
							<Link
								to={{
									pathname: `/profil`,
									state: {
										id:
											item.type === InternshipType.ByCompany
												? item.companyId
												: item.professorId,
										type: item.type,
										filters: props.checkedKeys,
										searchTerm: props.searchTerm,
										sortField: props.sortField,
										sortDirection: props.sortDirection,
										currentPage: props.currentPage,
										pageSize: props.pageSize,
										origin: props.location.pathname,
									},
								}}
							>
								<Row>
									{item.type === InternshipType.ByCompany && (
										<Col
											span={24}
											className={styles.customCol}
											style={{ width: 90, textAlign: "left" }}
										>
											<Avatar src={item.recruiterAvatar} />
										</Col>
									)}
									{!isProfessor && (
										<Col
											span={24}
											className={styles.customCol}
											style={{ width: 90, textAlign: "left" }}
										>
											<p>
												{item.type === InternshipType.ByProfessor
													? t("abbreviations." + item.professionalTitle) + " "
													: ""}{" "}
												{item.recruiterName}
											</p>
										</Col>
									)}
								</Row>
							</Link>
						}
						title={
							<Row style={{ width: "100%" }}>
								<Col span={22} style={{ display: "flex" }}>
									<Link
										to={{
											pathname: "/propunere/" + item.id,
											search: "?type=" + item.type,
											state: {
												id: item.id,
												type: item.type,
												filters: props.checkedKeys,
												searchTerm: props.searchTerm,
												sortField: props.sortField,
												sortDirection: props.sortDirection,
												currentPage: props.currentPage,
												pageSize: props.pageSize,
												origin: props.location.pathname,
											},
										}}
										onClick={() => actions.updateViewCount(item.id, item.type)}
									>
										{item.internshipName}
									</Link>
								</Col>
							</Row>
						}
						description={item.skillsRequired?.join(", ")}
					/>
					{item.shortDescription}
				</List.Item>
			)}
		/>
	);
};

export default EventsList;
