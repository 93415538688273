import { useCallback, useEffect, useState } from "react";
import {
	ErrorCodes,
	Filter,
	InternshipType,
	PracticeCertificateSigningEnum,
	PracticeCertificateState,
	PracticeConventionState,
	PracticeNotebookSigningEnum,
	PracticeNotebookState,
	PracticeStudentsDTO,
} from "../../Api";
import {
	getPracticeStudents,
	getPracticeStudentsFilters,
	updateInternshipPracticeInLaboratory,
} from "../../Requests/internship-requests";
import {
	Button,
	Checkbox,
	Col,
	Drawer,
	Dropdown,
	Form,
	Input,
	Menu,
	MenuProps,
	Modal,
	Progress,
	Row,
	Select,
	Spin,
	Steps,
	Table,
	TablePaginationConfig,
	Typography,
} from "antd";
import Layout from "../../Containers/Layout";
import Search from "antd/lib/input/Search";
import { Link, useHistory, useLocation } from "react-router-dom";
import { studentIdSet } from "../Student/StudentSlice";
import { useDispatch } from "react-redux";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	approvePracticeNotebook,
	downloadPracticeNotebook,
	generatePracticeNotebook,
	rejectPracticeNotebook,
} from "../../Requests/practice-notebook-requests";
import {
	approvePracticeCertificate,
	downloadPracticeCertificate,
	generatePracticeCertificate,
	rejectPracticeCertificate,
} from "../../Requests/practice-certificate-requests";
import {
	downloadAll,
	downloadExcel,
	downloadFile,
} from "../../utils/downloadUtils";
import { useQuery, useQueryClient } from "react-query";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
	changeSupervisionStatus,
	downloadPracticeConvention,
	validatePracticeConvention,
} from "../../Requests/practice-convention-requests";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import {
	getCurrentUniversityYear,
	getUserId,
	useIsProfessor,
	useIsFacultyAdmin,
} from "../../utils/utilFunctions";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import {
	getCoTutorProfessors,
	getPracticeStudentsFilter,
	getPracticeStudentsInfo,
} from "../../utils/reactQueriesConstants";
import CustomForm from "../../CustomComponents/CustomForm";
import { getErrorFromResponse } from "../../utils/responseUtils";
import styles from "./PracticeStudents.module.scss";
import { theme } from "../../theme";
import CustomFilter from "../../CustomComponents/CustomFilter";
import { removeDiacritics } from "../../utils/dataUtils";
import CustomButton from "../../CustomComponents/CustomButton";
import type { ColumnsType } from "antd/es/table";
import { getAllSupervisors } from "../../Requests/academic-user-requests";
import useDebounce from "../../Hooks/debounce";
import { Option } from "antd/lib/mentions";

enum ActionKeyEnum {
	ValidatePracticeConvention,
	DownloadPracticeConvention,
	DownloadPracticeNotebook,
	ValidatePracticeNotebook,
	InvalidatePracticeNotebook,
	DownloadPracticeCertificate,
	ValidatePracticeCertificate,
	InvalidatePracticeCertificate,
	AddPracticeInLaboratory,
}

const PracticeStudents = () => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [internshipSearchTerm, setInternshipSearchTerm] = useState("");
	const [studentSearchTerm, setStudentSearchTerm] = useState("");
	const [employerSearchTerm, setEmployerSearchTerm] = useState("");
	const [supervisorSearchTerm, setSupervisorSearchTerm] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isAddPracticeModalVisible, setIsAddPracticeModalVisible] =
		useState(false);
	const [spinning, setSpinning] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [modalText, setModalText] = useState("");
	const [handleFunction, setHandleFunction] = useState(() => () => {});
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [visible, setVisible] = useState(false);
	const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [studyProgram, setStudyProgram] = useState([]);
	const [year, setYear] = useState([]);
	const [practiceNotebookStatus, setPracticeNotebookStatus] = useState([]);
	const [practiceCertificateStatus, setPracticeCertificateStatus] = useState(
		[]
	);
	const [universityYear, setUniversityYear] = useState([]);
	const [progress, setProgress] = useState(0);
	const [isNotebookModalVisible, setIsNotebookModalVisible] = useState(false);
	const [
		isPracticeCertificateModalVisible,
		setIsPracticeCertificateModalVisible,
	] = useState(false);
	const [notebookState, setNotebookState] = useState("");
	const [practiceCertificateState, setPracticeCertificateState] = useState("");
	const [downloading, setDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState(false);
	const [form] = Form.useForm<{
		practiceInLab: string;
	}>();
	const [rejectForm] = Form.useForm<{
		rejectComment: string;
	}>();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isProfessor = useIsProfessor();
	const [tutorSearch, setTutorSearch] = useState("");
	const debouncedUserSearch = useDebounce(tutorSearch, 1000);

	const FilterByNameInput = (
		<Search
			placeholder={t("students.name")}
			allowClear
			onSearch={(value) => {
				setStudentSearchTerm(value);
				setCurrent(1);
			}}
			style={{ width: 130 }}
		/>
	);

	const FilterByInternshipNameInput = (
		<Search
			placeholder={t("internships.searchProposal")}
			allowClear
			onSearch={(value) => {
				setInternshipSearchTerm(value);
				setCurrent(1);
			}}
			style={{ width: 130 }}
		/>
	);

	const FilterByEmployerNameInput = (
		<Search
			placeholder={t("students.employerName")}
			allowClear
			onSearch={(value) => {
				setEmployerSearchTerm(value);
				setCurrent(1);
			}}
			style={{ width: 130 }}
		/>
	);

	const { data: supervisors, isLoading: isLoadingCoTutorProfessors } = useQuery(
		[getCoTutorProfessors, isProfessor, debouncedUserSearch],
		async () => {
			if (!isProfessor) {
				return null;
			}
			return getAllSupervisors(debouncedUserSearch).then((e) =>
				e.map((e) => {
					return { id: e.id, name: e.name };
				})
			);
		},
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const FilterBySupervisorNameInput = (
		<Select
			className={styles.antSelect}
			value={supervisorSearchTerm}
			allowClear
			showSearch
			style={{ width: 130, textAlign: "left" }}
			placeholder={t("internships.addInternshipForm.professorCoTutor")}
			filterOption={false}
			onSearch={setTutorSearch}
			onChange={(value) => {
				setSupervisorSearchTerm(value);
				setTutorSearch("");
			}}
			notFoundContent={
				isLoadingCoTutorProfessors ? <Spin size="small" /> : null
			}
		>
			{[{ id: "all", name: "" }, ...(supervisors || [])].map((user) => (
				<Option key={user.id} value={user.name || ""}>
					{user.name || t("practiceStudents.all")}
				</Option>
			))}
		</Select>
	);

	const showNotebookInfo = (record: any) => {
		setNotebookState(record.practiceNotebookStatus);
		setIsNotebookModalVisible(true);
	};

	const showPracticeCertificateInfo = (record: any) => {
		setPracticeCertificateState(record.practiceCertificateStatus);
		setIsPracticeCertificateModalVisible(true);
	};

	const currentStatusNotebook = () => {
		if (notebookState === PracticeNotebookState.NotGenerated) {
			return 0;
		} else if (notebookState === PracticeNotebookState.InProgress) {
			return 1;
		} else if (notebookState === PracticeNotebookState.PendingTutorSignature) {
			return 2;
		} else if (
			notebookState === PracticeNotebookState.PendingInternshipAdminValidation
		) {
			return 3;
		} else if (notebookState === PracticeNotebookState.Finalized) {
			return 4;
		}
	};

	const currentStatusPracticeCertificate = () => {
		if (practiceCertificateState === PracticeCertificateState.NotGenerated) {
			return 0;
		} else if (
			practiceCertificateState === PracticeCertificateState.InProgress
		) {
			return 1;
		} else if (
			practiceCertificateState ===
			PracticeCertificateState.PendingPracticeRepresentativeSignature
		) {
			return 2;
		} else if (
			practiceCertificateState ===
			PracticeCertificateState.PendingInternshipAdminValidation
		) {
			return 3;
		} else if (
			practiceCertificateState === PracticeCertificateState.Finalized
		) {
			return 4;
		}
	};

	const handleNotebookModalCancel = () => {
		setIsNotebookModalVisible(false);
	};

	const handlePracticeCertificateModalCancel = () => {
		setIsPracticeCertificateModalVisible(false);
	};

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const downloadFailPracticeNotebook = (_err: any) => {
		openNotification(
			t("students.downloadPracticeNotebook"),
			t("practiceNotebook.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const validateConvention = (id: any) => {
		setSpinning(true);

		validatePracticeConvention(id)
			.then(async () => {
				await queryClient.invalidateQueries(getPracticeStudentsInfo);

				openNotification(
					t("students.practiceConventionValidation"),
					t("students.successValidatePracticeConventionMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.practiceConventionValidation"),
					t("students.errorApprovePracticeConventionMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const downloadNotebook = (record: PracticeStudentsDTO) => {
		if (
			record.practiceNotebookLastSigner ===
				PracticeNotebookSigningEnum.Student &&
			record.practiceNotebookStatus !==
				PracticeNotebookState.PendingTutorSignature
		) {
			downloadFile(
				downloadPracticeNotebook(record.practiceNotebookLastSignatureId!),
				t("students.practiceNotebook") + "_" + record.name + "_" + record.group
			).catch(downloadFailPracticeNotebook);
		} else {
			downloadFile(
				generatePracticeNotebook(record.practiceNotebookId!),
				t("students.practiceNotebook") + "_" + record.name + "_" + record.group
			).catch(downloadFailPracticeNotebook);
		}
	};

	const downloadFailPracticeConvention = async (error: any) => {
		const errorMessage = await getErrorFromResponse(error);

		let notificationMessage;

		switch (errorMessage?.code) {
			case ErrorCodes.ConventionNotFound:
				notificationMessage = t(
					"practiceConvention.messages.conventionNotFound"
				);
				break;
			case ErrorCodes.StudentNotFound:
				notificationMessage = t("practiceConvention.messages.studentNotFound");
				break;
			case ErrorCodes.DeanNotFound:
				notificationMessage = t(
					"practiceConvention.messages.supervisor.deanNotFound"
				);
				break;
			case ErrorCodes.SupervisorNotFound:
				notificationMessage = t(
					"practiceConvention.messages.supervisorNotFound"
				);
				break;
			case ErrorCodes.RepresentativeNotFound:
				notificationMessage = t(
					"practiceConvention.messages.representativeNotFound"
				);
				break;
			case ErrorCodes.MissingStudentPersonalData:
				notificationMessage = t(
					"practiceConvention.messages.supervisor.missingPersonalData"
				);
				break;
			default:
				notificationMessage = t("practiceConvention.downloadError");
				break;
		}

		openNotification(
			t("practiceConvention.error"),
			notificationMessage,
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadConvention = (record: any) => {
		downloadFile(
			downloadPracticeConvention(record.internshipEnrollmentId),
			t("practiceConvention.fileName") + "_" + record.name + "_" + record.group
		).catch(downloadFailPracticeConvention);
	};

	const approveNotebook = (id: any) => {
		setSpinning(true);

		approvePracticeNotebook(id)
			.then(async () => {
				await queryClient.invalidateQueries(getPracticeStudentsInfo);
				await queryClient.invalidateQueries(getPracticeStudentsFilter);

				openNotification(
					t("students.approvePracticeNotebook"),
					t("students.successApprovePracticeNotebookMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.approvePracticeNotebook"),
					t("students.errorApprovePracticeNotebookMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const rejectNotebook = (id: any) => {
		setSpinning(true);

		rejectPracticeNotebook(id, rejectForm.getFieldsValue().rejectComment)
			.then(async () => {
				await queryClient.invalidateQueries(getPracticeStudentsInfo);
				await queryClient.invalidateQueries(getPracticeStudentsFilter);

				openNotification(
					t("students.rejectPracticeNotebook"),
					t("students.successRejectPracticeNotebookMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.rejectPracticeNotebook"),
					t("students.errorRejectPracticeNotebookMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const downloadFailPracticeCertificate = (_err: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadCertificate = (record: PracticeStudentsDTO) => {
		if (
			record.practiceCertificateLastSigner ===
				PracticeCertificateSigningEnum.PracticeRepresentative ||
			record.practiceCertificateLastSigner ===
				PracticeCertificateSigningEnum.Student
		) {
			downloadFile(
				downloadPracticeCertificate(record.practiceCertificateLastSignatureId!),
				t("students.practiceCertificate") +
					"_" +
					record.name +
					"_" +
					record.group
			).catch(downloadFailPracticeCertificate);
		} else {
			downloadFile(
				generatePracticeCertificate(record.practiceCertificateId!),
				t("students.practiceCertificate") +
					"_" +
					record.name +
					"_" +
					record.group
			).catch(downloadFailPracticeCertificate);
		}
	};

	const approveCertificate = (id: any) => {
		setSpinning(true);

		approvePracticeCertificate(id)
			.then(async () => {
				await queryClient.invalidateQueries(getPracticeStudentsInfo);
				await queryClient.invalidateQueries(getPracticeStudentsFilter);

				openNotification(
					t("students.approvePracticeCertificate"),
					t("students.successApprovePracticeCertificateMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.approvePracticeCertificate"),
					t("students.errorApprovePracticeCertificateMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const rejectCertificate = (id: any) => {
		setSpinning(true);

		rejectPracticeCertificate(id, rejectForm.getFieldsValue().rejectComment)
			.then(async () => {
				await queryClient.invalidateQueries(getPracticeStudentsInfo);
				await queryClient.invalidateQueries(getPracticeStudentsFilter);

				openNotification(
					t("students.rejectPracticeCertificate"),
					t("students.successRejectPracticeCertificateMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.rejectPracticeCertificate"),
					t("students.errorRejectPracticeCertificateMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const addPractice = (item: PracticeStudentsDTO) => {
		setSpinning(true);
		if (item.internshipEnrollmentId) {
			updateInternshipPracticeInLaboratory(
				item.internshipEnrollmentId,
				form.getFieldsValue().practiceInLab
			)
				.then(async () => {
					openNotification(
						t("students.practiceInLaboratorySuccess"),
						t("students.addPracticeInTheLaboratorySuccess"),
						NOTIFICATION_TYPES.SUCCESS
					);
					await queryClient.invalidateQueries(getPracticeStudentsInfo);
					setSpinning(false);
				})
				.catch(() => {
					openNotification(
						t("practiceConvention.error"),
						t("students.addPracticeInTheLaboratoryError"),
						NOTIFICATION_TYPES.ERROR
					);
					setSpinning(false);
				});
		}
		setIsAddPracticeModalVisible(false);
	};

	const onChange = (e: CheckboxChangeEvent, record: PracticeStudentsDTO) => {
		changeSupervisionStatus(record.practiceConventionId!, e.target.checked)
			.then(async () => {
				await queryClient.invalidateQueries(getPracticeStudentsInfo);

				openNotification(
					t("students.supervisePracticeTitle"),
					e.target.checked
						? t("students.supervisePracticeSuccess")
						: t("students.unsupervisePracticeSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.Forbidden:
					case ErrorCodes.SupervisorNotFound:
						notificationMessage = t("errors.forbidden");
						break;
					case ErrorCodes.ConventionNotFound:
						notificationMessage = t(
							"practiceConvention.messages.conventionNotFound"
						);
						break;
					case ErrorCodes.SupervisorAlreadyAllocated:
						notificationMessage = t("students.alreadySupervisingEnrollment");
						break;
					case ErrorCodes.OtherSupervisorAssigned:
						notificationMessage = t("students.otherSupervisorAssigned");
						break;
					default:
						notificationMessage = t("students.supervisePracticeError");
						break;
				}

				openNotification(
					t("students.supervisePracticeTitle"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const getActions = (record: PracticeStudentsDTO): ItemType[] | undefined => {
		const isSupervisor = record.supervisorId === getUserId();

		let actions: MenuProps["items"] = [];

		// practice convention actions
		if (record.internshipType === InternshipType.ByCompany) {
			const disabledDownloadConvention = !isSupervisor && !isFacultyAdmin;
			const disabledDownloadConventionMessage =
				!isSupervisor && !isFacultyAdmin ? t("students.onlySupervisorCan") : "";

			actions.push({
				label: t("students.downloadPracticeConvention"),
				key: ActionKeyEnum.DownloadPracticeConvention,
				icon: (
					<FontAwesomeIcon
						icon={solid("download")}
						style={{ fontSize: "15px" }}
					/>
				),
				disabled: disabledDownloadConvention,
				title: disabledDownloadConventionMessage,
			});
		}

		if (record.internshipType === InternshipType.ByCompany) {
			const conventionFinalized =
				record.practiceConventionStatus === PracticeConventionState.Finalized;

			const disabledValidateConvention =
				(!isSupervisor && !isFacultyAdmin) || conventionFinalized;
			const disabledValidateConventionMessage =
				!isSupervisor && !isFacultyAdmin
					? t("students.onlySupervisorCan")
					: conventionFinalized
					? t("students.conventionAlreadyValidated")
					: "";

			actions.push({
				label: t("students.validatePracticeConvention"),
				key: ActionKeyEnum.ValidatePracticeConvention,
				icon: (
					<FontAwesomeIcon
						icon={solid("circle-check")}
						style={{ fontSize: "15px" }}
					/>
				),
				disabled: disabledValidateConvention,
				title: disabledValidateConventionMessage,
			});
		}

		// practice notebook actions
		const notebookFinalized =
			record.practiceNotebookStatus !== PracticeNotebookState.NotGenerated &&
			record.practiceNotebookStatus !== PracticeNotebookState.InProgress;
		const notebookReadyForValidation =
			record.practiceNotebookStatus ===
			PracticeNotebookState.PendingInternshipAdminValidation;
		const notebookValidated =
			record.practiceNotebookStatus === PracticeNotebookState.Finalized;

		const disableDownloadNotebook =
			(!isSupervisor && !isFacultyAdmin) || !notebookFinalized;
		const disableDownloadNotebookMessage =
			!isSupervisor && !isFacultyAdmin
				? t("students.onlySupervisorCan")
				: !notebookFinalized
				? t("students.notebookNotFinalized")
				: "";

		actions.push({
			label: t("students.downloadPracticeNotebook"),
			key: ActionKeyEnum.DownloadPracticeNotebook,
			icon: (
				<FontAwesomeIcon
					icon={solid("download")}
					style={{ fontSize: "15px" }}
				/>
			),
			disabled: disableDownloadNotebook,
			title: disableDownloadNotebookMessage,
		});

		const disableValidateNotebook =
			(!isSupervisor && !isFacultyAdmin) ||
			!notebookFinalized ||
			notebookValidated ||
			!notebookReadyForValidation;
		const disableValidateNotebookMessage =
			!isSupervisor && !isFacultyAdmin
				? t("students.onlySupervisorCan")
				: !notebookFinalized
				? t("students.notebookNotFinalized")
				: notebookValidated
				? t("students.notebookAlreadyValidated")
				: !notebookReadyForValidation
				? t("students.notebookNotReadyForValidation")
				: "";

		actions.push(
			{
				label: t("students.approvePracticeNotebook"),
				key: ActionKeyEnum.ValidatePracticeNotebook,
				icon: (
					<FontAwesomeIcon
						icon={solid("circle-check")}
						style={{ fontSize: "15px" }}
					/>
				),
				disabled: disableValidateNotebook,
				title: disableValidateNotebookMessage,
			},
			{
				label: t("students.rejectPracticeNotebook"),
				key: ActionKeyEnum.InvalidatePracticeNotebook,
				icon: (
					<FontAwesomeIcon icon={solid("ban")} style={{ fontSize: "15px" }} />
				),
				disabled: disableValidateNotebook,
				title: disableValidateNotebookMessage,
			}
		);

		// practice certificate actions
		const certificateFinalized =
			record.practiceCertificateStatus !==
				PracticeCertificateState.NotGenerated &&
			record.practiceCertificateStatus !== PracticeCertificateState.InProgress;
		const certificateReadyForValidation =
			record.practiceCertificateStatus ===
			PracticeCertificateState.PendingInternshipAdminValidation;
		const certificateValidated =
			record.practiceCertificateStatus === PracticeCertificateState.Finalized;

		const disableDownloadCertificate =
			(!isSupervisor && !isFacultyAdmin) || !certificateFinalized;
		const disableDownloadCertificateMessage =
			!isSupervisor && !isFacultyAdmin
				? t("students.onlySupervisorCan")
				: !certificateFinalized
				? t("students.certificateNotFinalized")
				: "";

		actions.push({
			label: t("students.downloadPracticeCertificate"),
			key: ActionKeyEnum.DownloadPracticeCertificate,
			icon: (
				<FontAwesomeIcon
					icon={solid("download")}
					style={{ fontSize: "15px" }}
				/>
			),
			disabled: disableDownloadCertificate,
			title: disableDownloadCertificateMessage,
		});

		const disableValidateCertificate =
			(!isSupervisor && !isFacultyAdmin) ||
			!certificateFinalized ||
			certificateValidated ||
			!certificateReadyForValidation;
		const disableValidateCertificateMessage =
			!isSupervisor && !isFacultyAdmin
				? t("students.onlySupervisorCan")
				: !certificateFinalized
				? t("students.certificateNotFinalized")
				: certificateValidated
				? t("students.certificateAlreadyValidated")
				: !certificateReadyForValidation
				? t("students.certificateNotReadyForValidation")
				: "";

		actions.push(
			{
				label: t("students.approvePracticeCertificate"),
				key: ActionKeyEnum.ValidatePracticeCertificate,
				icon: (
					<FontAwesomeIcon
						icon={solid("circle-check")}
						style={{ fontSize: "15px" }}
					/>
				),
				disabled: disableValidateCertificate,
				title: disableValidateCertificateMessage,
			},
			{
				label: t("students.rejectPracticeCertificate"),
				key: ActionKeyEnum.InvalidatePracticeCertificate,
				icon: (
					<FontAwesomeIcon icon={solid("ban")} style={{ fontSize: "15px" }} />
				),
				disabled: disableValidateCertificate,
				title: disableValidateCertificateMessage,
			}
		);

		//add practice hours in laboratory
		// TODO: disable practice in lab based on practice hours per faculty for the year of the internship
		const requiredPracticeHours =
			record.practiceHoursPerFaculty &&
			record.practiceHoursPerFaculty?.length > 0
				? record.practiceHoursPerFaculty.find(
						(p) => p.practiceYear === record.year
				  )?.practiceNumberOfHours
				: 360;
		const disableAddPracticeInLaboratory =
			(!isSupervisor && !isFacultyAdmin) ||
			(record.totalPracticeHours !== undefined &&
				record.totalPracticeHours >= requiredPracticeHours!) ||
			(record.laboratoryPracticeRoom !== undefined &&
				record.laboratoryPracticeRoom !== "");
		const disableAddPracticeInLaboratoryMessage =
			!isSupervisor && !isFacultyAdmin
				? t("students.onlySupervisorCan")
				: record.totalPracticeHours !== undefined &&
				  record.totalPracticeHours >= requiredPracticeHours!
				? t("students.practiceCondition")
				: record.laboratoryPracticeRoom !== undefined &&
				  record.laboratoryPracticeRoom !== ""
				? t("students.addPracticeInTheLaboratoryExists")
				: "";

		actions.push({
			label: t("students.addPracticeInTheLaboratory"),
			key: ActionKeyEnum.AddPracticeInLaboratory,
			icon: (
				<FontAwesomeIcon icon={solid("plus")} style={{ fontSize: "15px" }} />
			),
			disabled: disableAddPracticeInLaboratory,
			title: disableAddPracticeInLaboratoryMessage,
		});

		return actions;
	};

	const onMenuClick = (e: any, record: PracticeStudentsDTO) => {
		if (e.key == ActionKeyEnum.ValidatePracticeConvention) {
			setModalTitle(t("students.validatePracticeConvention"));
			setModalText(
				t("students.validateConventionMessage") +
					record.name +
					t("students.validateConventionMessage2") +
					record.internshipName +
					"?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => validateConvention(record.practiceConventionId)
			);
		} else if (e.key == ActionKeyEnum.DownloadPracticeConvention) {
			downloadConvention(record);
		} else if (e.key == ActionKeyEnum.DownloadPracticeNotebook) {
			downloadNotebook(record);
		} else if (e.key == ActionKeyEnum.ValidatePracticeNotebook) {
			setModalTitle(t("students.approvePracticeNotebook"));
			setModalText(
				t("students.approveNotebookMessage") +
					record.name +
					t("students.approveNotebookMessage2") +
					record.internshipName +
					"?"
			);
			setIsModalVisible(true);
			setHandleFunction(() => () => approveNotebook(record.practiceNotebookId));
		} else if (e.key == ActionKeyEnum.InvalidatePracticeNotebook) {
			setModalTitle(t("students.rejectPracticeNotebook"));
			setModalText(
				t("students.rejectNotebookMessage") +
					record.name +
					t("students.rejectNotebookMessage2") +
					record.internshipName +
					"? " +
					t("students.rejectComment")
			);
			setIsModalVisible(true);
			setHandleFunction(() => () => rejectNotebook(record.practiceNotebookId));
		} else if (e.key == ActionKeyEnum.DownloadPracticeCertificate) {
			downloadCertificate(record);
		} else if (e.key == ActionKeyEnum.ValidatePracticeCertificate) {
			setModalTitle(t("students.approvePracticeCertificate"));
			setModalText(
				t("students.approveCertificateMessage") +
					record.name +
					t("students.approveCertificateMessage2") +
					record.internshipName +
					"?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => approveCertificate(record.practiceCertificateId)
			);
		} else if (e.key == ActionKeyEnum.InvalidatePracticeCertificate) {
			setModalTitle(t("students.rejectPracticeCertificate"));
			setModalText(
				t("students.rejectCertificateMessage") +
					record.name +
					t("students.rejectCertificateMessage2") +
					record.internshipName +
					"? " +
					t("students.rejectComment")
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => rejectCertificate(record.practiceCertificateId)
			);
		} else if (e.key == ActionKeyEnum.AddPracticeInLaboratory) {
			setModalTitle(t("students.addPracticeInTheLaboratory"));
			setIsAddPracticeModalVisible(true);
			setHandleFunction(() => () => addPractice(record));
		}
	};

	const allColumns: ColumnsType<PracticeStudentsDTO> = [
		{
			title: FilterByNameInput,
			dataIndex: "name",
			key: "name",
			width: 200,
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("students.year"),
			dataIndex: "year",
			key: "year",
			width: 100,
			render: (_text: string | undefined, record: any) => (
				<div>{t("students.filters." + record.year)}</div>
			),
		},
		{
			title: t("students.group"),
			dataIndex: "group",
			key: "group",
			width: 100,
		},
		{
			title: t("students.studyProgram"),
			dataIndex: "studyProgram",
			key: "studyProgram",
			width: 200,
		},
		{
			title: FilterByEmployerNameInput,
			dataIndex: "employerName",
			key: "employerName",
			width: 200,
		},
		{
			title: FilterByInternshipNameInput,
			dataIndex: "internshipName",
			key: "internshipName",
			width: 200,
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/propunere/" + record.internshipId,
						search: "?type=" + record.internshipType,
						state: {
							id: record.internshipId,
							type: record.internshipType,
							origin: location.pathname,
						},
					}}
				>
					<b>{record.internshipName}</b>
				</Link>
			),
		},
		{
			title: FilterBySupervisorNameInput,
			dataIndex: "supervisor",
			key: "supervisor",
			width: 200,
			render: (_text: string | undefined, record: any) => (
				<div>
					{record.supervisorId && record.supervisorId !== getUserId() ? (
						record.supervisorName
					) : (
						<Checkbox
							onChange={(e) => onChange(e, record)}
							checked={record.supervisorId === getUserId()}
						>
							{t("students.supervisePractice")}
						</Checkbox>
					)}
				</div>
			),
		},
		{
			title: t("students.practiceHours"),
			dataIndex: "totalPracticeHours",
			key: "totalPracticeHours",
			width: 100,
			render: (_text: string | undefined, record: any) => (
				<div>
					{record.totalPracticeHours > 0 ? (
						<div>{record.totalPracticeHours}</div>
					) : (
						<div>-</div>
					)}
				</div>
			),
		},
		{
			title: t("students.practiceInLaboratory"),
			dataIndex: "laboratoryPracticeRoom",
			key: "laboratoryPracticeRoom",
			width: 200,
			render: (_text: string | undefined, record: any) => (
				<div>
					{record.laboratoryPracticeRoom != undefined &&
					record.laboratoryPracticeRoom !== "" ? (
						<div>{record.laboratoryPracticeRoom}</div>
					) : (
						<div>-</div>
					)}
				</div>
			),
		},
		{
			title: t("students.practiceNotebookStatus"),
			dataIndex: "practiceNotebookStatus",
			key: "practiceNotebookStatus",
			width: 200,
			render: (_text: string | undefined, record: any) => {
				return (
					<div
						onClick={() => showNotebookInfo(record)}
						style={{ cursor: "pointer" }}
					>
						{t("internships.filters." + record.practiceNotebookStatus)}
					</div>
				);
			},
		},
		{
			title: t("students.practiceCertificateStatus"),
			dataIndex: "practiceCertificateStatus",
			key: "practiceCertificateStatus",
			width: 200,
			render: (_text: string | undefined, record: any) => {
				return (
					<div
						onClick={() => showPracticeCertificateInfo(record)}
						style={{ cursor: "pointer" }}
					>
						{t("internships.filters." + record.practiceCertificateStatus)}
					</div>
				);
			},
		},
		{
			title: t("students.actions"),
			dataIndex: "actions",
			key: "actions",
			width: 100,
			fixed: "right",
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	];

	const columnsBelow550: ColumnsType<PracticeStudentsDTO> = [
		allColumns[0],
		allColumns[5],
		allColumns[6],
		allColumns[7],
		allColumns[8],
		allColumns[11],
	];

	const columnsBelow1200: ColumnsType<PracticeStudentsDTO> = [
		allColumns[0],
		allColumns[1],
		allColumns[2],
		allColumns[3],
		allColumns[4],
		allColumns[5],
		allColumns[6],
		allColumns[7],
		allColumns[8],
		allColumns[11],
	];

	const downloadPracticeStudentsList = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			t("files.practiceStudents"),
			allColumns
				.filter((e) => e.key !== "actions")
				.map((e) => {
					return {
						header:
							typeof e.title === "string"
								? removeDiacritics(e.title)
								: removeDiacritics(t("students." + e.key)),
						key: e.key!.toString(),
					};
				}),
			await downloadAll(
				(currentPage, pageSize) =>
					getPracticeStudents(
						internshipSearchTerm,
						studentSearchTerm,
						employerSearchTerm,
						supervisorSearchTerm,
						studyProgram,
						year,
						practiceNotebookStatus,
						practiceCertificateStatus,
						universityYear,
						currentPage,
						pageSize
					),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			)
		);
	}, [setDownloading, allColumns, t]);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("usersText.errorTexts.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		[
			getPracticeStudentsInfo,
			currentPage,
			pageSize,
			studentSearchTerm,
			internshipSearchTerm,
			employerSearchTerm,
			supervisorSearchTerm,
			studyProgram,
			year,
			practiceNotebookStatus,
			practiceCertificateStatus,
			universityYear,
		],
		() => {
			return getPracticeStudents(
				internshipSearchTerm,
				studentSearchTerm,
				employerSearchTerm,
				supervisorSearchTerm,
				studyProgram,
				year,
				practiceNotebookStatus,
				practiceCertificateStatus,
				universityYear,
				currentPage,
				pageSize
			);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const { data: filters } = useQuery(
		[
			getPracticeStudentsFilter,
			studentSearchTerm,
			internshipSearchTerm,
			employerSearchTerm,
			supervisorSearchTerm,
		],
		() => {
			return getPracticeStudentsFilters(
				internshipSearchTerm,
				studentSearchTerm,
				employerSearchTerm,
				supervisorSearchTerm,
				studyProgram
			);
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
		}
	);

	const getAllKeys = (filterItems: any[]) => {
		const keys: any[] = [];

		filterItems.forEach((filter) => {
			keys.push(filter.key);

			filter.children.forEach((filterItem: any) => {
				keys.push(filterItem.key.split("/")[1]);
			});
		});

		return keys;
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setCurrent(1);

		setStudyProgram(
			checkedKeysValue
				.filter((e: string) => e.startsWith("StudyProgram@"))
				.map((e: string) => e.split("@")[1])
		);

		setYear(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Year@"))
				.map((e: string) => e.split("@")[1])
		);

		setPracticeNotebookStatus(
			checkedKeysValue
				.filter((e: string) => e.startsWith("NotebookStatus@"))
				.map((e: string) => e.split("@")[1])
		);

		setPracticeCertificateStatus(
			checkedKeysValue
				.filter((e: string) => e.startsWith("CertificateStatus@"))
				.map((e: string) => e.split("@")[1])
		);

		setUniversityYear(
			checkedKeysValue
				.filter((e: string) => e.startsWith("UniversityYear@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const handleTableChange = (pagination: TablePaginationConfig) => {
		setCurrent(() => pagination.current ?? 1);
		setPageSize(() => pagination.pageSize ?? 10);
	};

	const getKey = (record: any): string => {
		return record.id + record.internshipId;
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		rejectForm.setFieldsValue({ rejectComment: "" });
		form.setFieldsValue({ practiceInLab: "" });
		setIsAddPracticeModalVisible(false);
	};

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters.map((x) => {
			if (x.key === "StudyProgram" || x.key === "UniversityYear") {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: x.children,
				};
			} else {
				return {
					key: x.key,
					title: t("internships.filters." + x.title),
					children: new Set(
						Array.from(x.children!).map((y) => ({
							key: y.key,
							title: t("internships.filters." + y.title),
						}))
					),
				};
			}
		});
	};

	useEffect(() => {
		onCheck([
			`UniversityYear@${getCurrentUniversityYear()}-${
				getCurrentUniversityYear() + 1
			}`,
		]);
	}, []);

	return (
		<Layout>
			<Row className={styles.filterButton}>
				<Button
					onClick={showDrawer}
					style={{ outline: "none", border: "none", boxShadow: "none" }}
				>
					<FontAwesomeIcon
						icon={solid("filter")}
						style={{ color: theme.secondColor }}
					/>
					&nbsp;{t("internships.filters.filter")}
				</Button>
			</Row>
			<Row>
				<Col span={4} className={styles.filters}>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Col>
				<Drawer
					className={styles.opened}
					placement={"left"}
					closable={true}
					onClose={onClose}
					visible={visible}
					key={"left"}
					maskClosable={true}
					title={t("internships.filters.filters")}
				>
					<CustomFilter
						filters={filters}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						expandedKeys={expandedKeys}
						onExpand={onExpand}
						translateFilters={translateFilters}
					/>
				</Drawer>

				<Col xxl={20} xl={24} style={{ padding: "0 0 0 1%" }}>
					<div className={styles.container}>
						<CustomButton
							onClick={downloadPracticeStudentsList}
							marginbottom={"1em"}
							margintop={"0"}
						>
							{t("reports.download")}
						</CustomButton>
						{downloading && (
							<Progress
								percent={progress}
								status={downloadError ? "exception" : undefined}
							/>
						)}
					</div>
					<div className={styles.container}>
						<Table
							dataSource={data?.data || []}
							columns={
								window.innerWidth >= 1200
									? allColumns
									: window.innerWidth >= 550
									? columnsBelow1200
									: columnsBelow550
							}
							pagination={{
								total: data?.totalCount,
								current: currentPage,
								pageSize: data?.pageSize,
								pageSizeOptions: ["10", "20", "50"],
								defaultPageSize: 10,
								hideOnSinglePage: data?.totalCount
									? data?.totalCount <= 10
									: true,
								showSizeChanger: true,
								locale: { items_per_page: t("configuration.pagination") },
								position: ["topRight", "bottomRight"],
							}}
							rowKey={getKey}
							loading={loading && !data}
							onChange={handleTableChange}
							scroll={{ x: window.innerWidth }}
						/>
					</div>
				</Col>
			</Row>
			<Modal
				visible={isModalVisible}
				onOk={handleFunction}
				onCancel={handleClose}
				title={modalTitle}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={handleFunction}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<Typography>{modalText}</Typography>
						{(modalTitle === t("students.rejectPracticeNotebook") ||
							modalTitle === t("students.rejectPracticeCertificate")) && (
							<CustomForm form={rejectForm} style={{ marginBottom: "0px" }}>
								<Form.Item
									name="rejectComment"
									rules={[
										{
											required: true,
											message: t("students.requiredField"),
										},
									]}
								>
									<Input
										placeholder={t("students.rejectCommentPlaceholder")}
										style={{ width: "100%", marginTop: "10px" }}
									/>
								</Form.Item>
							</CustomForm>
						)}
					</div>
				</Spin>
			</Modal>
			<Modal
				visible={isAddPracticeModalVisible}
				onOk={handleFunction}
				onCancel={handleCancel}
				title={modalTitle}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleCancel}
						spinning={spinning}
						handleSave={handleFunction}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<CustomForm form={form}>
							<Form.Item name="practiceInLab">
								<Input
									placeholder={t("students.practiceInLaboratory")}
									style={{ width: "100%" }}
								/>
							</Form.Item>
						</CustomForm>
					</div>
				</Spin>
			</Modal>
			<Modal
				visible={isNotebookModalVisible}
				onCancel={handleNotebookModalCancel}
				title={t("students.practiceNotebook")}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={null}
			>
				<Steps direction="vertical" current={currentStatusNotebook()}>
					<Steps.Step
						title={t("students.internshipDocumentStatuses.NotGenerated")}
						description={t("practiceNotebook.description.NotGeneratedCompany")}
					/>
					<Steps.Step
						title={t("students.internshipDocumentStatuses.InProgress")}
						description={t("practiceNotebook.description.InProgressCompany")}
					/>
					<Steps.Step
						title={t("internships.filters.PendingTutorSignature")}
						description={t(
							"practiceNotebook.description.PendingTutorSignatureCompany"
						)}
					/>
					<Steps.Step
						title={t("internships.filters.PendingInternshipAdminValidation")}
						description={
							t(
								"practiceNotebook.description.PendingInternshipAdminValidation1"
							) +
							t(
								"practiceNotebook.description.PendingInternshipAdminValidation2"
							)
						}
					/>
					<Steps.Step
						title={t("internships.filters.Finalized")}
						description={t("practiceNotebook.description.Finalized")}
					/>
				</Steps>
			</Modal>
			<Modal
				visible={isPracticeCertificateModalVisible}
				onCancel={handlePracticeCertificateModalCancel}
				title={t("students.practiceNotebook")}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={null}
			>
				<Steps
					direction="vertical"
					current={currentStatusPracticeCertificate()}
				>
					<Steps.Step
						title={t("students.internshipDocumentStatuses.NotGenerated")}
						description={t(
							"practiceCertificate.description.NotGeneratedCompany"
						)}
					/>
					<Steps.Step
						title={t("students.internshipDocumentStatuses.InProgress")}
						description={t("practiceCertificate.description.InProgressCompany")}
					/>
					<Steps.Step
						title={t(
							"internships.filters.PendingPracticeRepresentativeSignature"
						)}
						description={t(
							"practiceCertificate.description.PendingPracticeRepresentativeSignatureCompany"
						)}
					/>
					<Steps.Step
						title={t("internships.filters.PendingInternshipAdminValidation")}
						description={
							t(
								"practiceCertificate.description.PendingInternshipAdminValidation1"
							) +
							t(
								"practiceCertificate.description.PendingInternshipAdminValidation2"
							)
						}
					/>
					<Steps.Step
						title={t("internships.filters.Finalized")}
						description={t("practiceCertificate.description.Finalized")}
					/>
				</Steps>
			</Modal>
		</Layout>
	);
};

export default PracticeStudents;
