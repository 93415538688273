import {
	EnrollmentStatus,
	InternType,
	InternshipApi,
	InternshipEngagementType,
	InternshipParticipationIntentDTO,
	InternshipSortFieldEnum,
	InternshipStatus,
	InternshipType,
	PracticeCertificateState,
	PracticeNotebookState,
	SortDirectionEnum,
	StudentYearEnum,
} from "../Api";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthInternshipApiFactory = () =>
	new InternshipApi(getAuthConfiguration());

export const getAllInternshipsTypeEvent = (
	skills?: Array<string>,
	categories?: Array<string>,
	types?: Array<string>,
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthInternshipApiFactory().apiInternshipGetInternshipsTypeEventGet({
		skills: arrayParamAjust(skills),
		categories: arrayParamAjust(categories),
		types: arrayParamAjust(
			types?.map((e) => InternshipType[e as keyof typeof InternshipType])
		),
		searchTerm,
		page,
		pageSize,
		sortField,
		sortDirection,
	});
};

export const setInternshipParticipationType = (
	setParticipationIntentRequest: InternshipParticipationIntentDTO
) => {
	return AuthInternshipApiFactory().apiInternshipSetInternshipParticipationIntentByInternshipIdInternshipIdPost(
		{
			internshipId: setParticipationIntentRequest.internshipId!,
			internshipParticipationIntentDTO: setParticipationIntentRequest,
		}
	);
};

export const getAllInternshipTypeEventFilters = () => {
	return AuthInternshipApiFactory().apiInternshipGetInternshipTypeEventFiltersGet();
};
