import Layout from "../../Containers/Layout";
import { Row, Col, Radio, RadioChangeEvent, Spin, Select } from "antd";
import { useQuery, useQueryClient } from "react-query";
import {
	generateUniversityAccord,
	getUniversityAccords,
	getUniversityAccordsToSign,
	generateUniversityAccordDocx,
	getAccordsFilters,
} from "../../Requests/university-accords-requests";
import { downloadFile, MimeType } from "../../utils/downloadUtils";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useState } from "react";
import {
	getAllKeys,
	useIsAdministrativeAccordsResponsible,
	useIsCompany,
} from "../../utils/utilFunctions";
import { useTranslation } from "react-i18next";
import CustomButton from "../../CustomComponents/CustomButton";
import styles from "./UniversityAccords.module.scss";
import CustomSelect from "../../CustomComponents/CustomSelect";
import Search from "antd/lib/input/Search";
import { Filter } from "../../Api";
import { useLocation } from "react-router-dom";
import {
	getFaculties,
	getFiltersForAccords,
	getUniversityAccordsInfo,
} from "../../utils/reactQueriesConstants";
import { getAllFaculties } from "../../Requests/faculty-requests";
import {
	generatePracticeProtocolDocx,
	generatePracticeProtocolPDF,
} from "../../Requests/practice-protocol-requests";
import DocumentsTable from "../Admin/DocumentsTable";

const { Group } = Radio;
const { Option } = Select;

const UniversityAccords = () => {
	const { t, i18n } = useTranslation();
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const isCompany = useIsCompany();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const [accordType, setAccordType] = useState(
		isCompany || isAdministrativeAccordsResponsible ? "all" : "toSign"
	);
	const [generateLoading, setGenerateLoading] = useState(false);
	const [step, setStep] = useState(0);
	const [accordFormat, setAccordFormat] = useState("pdf");
	const [accord, setAccord] = useState("");
	const [namePattern, setNamePattern] = useState("");
	const [status, setStatus] = useState([] as any);
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState<any>([]);
	const location = useLocation();
	const [facultyId, setFacultyId] = useState("");
	const [facultyOptions, setFacultyOptions] = useState<any>([]);

	const downloadFail = (_err: any) => {
		const faculty = allFacultiesList?.filter((f) => f.id === facultyId)[0];
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError", {
				field:
					i18n.language === "en"
						? faculty?.nameEn ?? "the faculty"
						: faculty?.nameRo ?? "facultatea",
			}),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const pageLoadFailed = () => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.uploadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: allFacultiesList } = useQuery(
		[getFaculties],
		() => getAllFaculties("", 1, 20),
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const handleListChange = (newPage: number, newPageSize: number) => {
		setPage(() => newPage);
		setPageSize(() => newPageSize);
	};

	const queryClient = useQueryClient();
	const invalidateAccordsQuery = async () => {
		await queryClient.invalidateQueries(getUniversityAccordsInfo);
	};

	const onChangeGroup = (e: RadioChangeEvent) => {
		setAccordType(e.target.value);
	};

	const changeAccordType = async (value: string) => {
		setAccord(value);

		if (value !== "practiceProtocol") {
			setStep(step + 1);
		} else {
			await queryClient.invalidateQueries(getFaculties);
			setFacultyOptions(
				allFacultiesList
					?.filter((faculty) => faculty.nameRo !== "Altele")
					.map((faculty) => (
						<Option key={faculty.id} value={faculty.id}>
							{i18n.language === "en" ? faculty.nameEn : faculty.nameRo}
						</Option>
					))
			);
		}

		setStep(step + 1);
	};

	const changeFaculty = (value: any) => {
		setFacultyId(value);
		setStep(step + 1);
	};

	const changeAccordFormat = (value: any) => {
		setStep(step + 1);
		setAccordFormat(value);
	};

	const generateAccord = () => {
		setGenerateLoading(true);

		if (accord === "practiceProtocol") {
			if (accordFormat === "pdf") {
				downloadFile(
					generatePracticeProtocolPDF(facultyId),
					t("universityAccord.practiceProtocol")
				)
					.then(invalidateAccordsQuery)
					.catch(downloadFail)
					.finally(() => setGenerateLoading(false));
			} else {
				downloadFile(
					generatePracticeProtocolDocx(facultyId),
					t("universityAccord.practiceProtocol"),
					MimeType.docx
				)
					.then(invalidateAccordsQuery)
					.catch(downloadFail)
					.finally(() => setGenerateLoading(false));
			}
		} else {
			if (accordFormat === "pdf") {
				downloadFile(generateUniversityAccord(), t("universityAccord.accord"))
					.then(invalidateAccordsQuery)
					.catch(downloadFail)
					.finally(() => setGenerateLoading(false));
			} else {
				downloadFile(
					generateUniversityAccordDocx(),
					t("universityAccord.accord"),
					MimeType.docx
				)
					.then(invalidateAccordsQuery)
					.catch(downloadFail)
					.finally(() => setGenerateLoading(false));
			}
		}
	};

	const { data: universityAccords, isLoading } = useQuery(
		[
			getUniversityAccordsInfo,
			namePattern,
			status,
			page,
			pageSize,
			accordType,
			generateLoading,
		],
		() =>
			(accordType === "all"
				? getUniversityAccords(namePattern, status, page, pageSize)
				: getUniversityAccordsToSign(page, pageSize)
			).catch(pageLoadFailed)
	);

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
		setPage(1);
		setStatus(
			checkedKeysValue
				.filter((e: string) => e.startsWith("Status@"))
				.map((e: string) => e.split("@")[1])
		);
	};

	const onExpand = (expandedKeysValue: any) => {
		// We might need this on loading filters from BE
		setExpandedKeys(expandedKeysValue);
	};

	const translateFilters = (filters: Filter[]): Filter[] => {
		return filters?.map((x) => {
			return {
				key: x.key,
				title: t("universityAccord." + x.title),
				children: new Set(
					Array.from(x.children!).map((y) => ({
						key: y.key,
						title: t("universityAccord." + y.title),
					}))
				),
			};
		});
	};

	const getExistingFilters = () => {
		if (location.state !== undefined) {
			const { existingFilters }: any = location.state;
			onCheck(existingFilters);
			handleListChange(1, 10);
		}
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getFiltersForAccords],
		() => {
			return getAccordsFilters();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));

				getExistingFilters();
			},
		}
	);

	return (
		<Layout>
			{isCompany && (
				<Row gutter={10} style={{ marginLeft: "2rem", marginRight: "2rem" }}>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={24}
						xl={3}
						style={{ display: "flex", alignItems: "center" }}
					>
						<span style={{ marginTop: "8%" }}>
							{t("universityAccord.accordGeneration")}
						</span>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={4}>
						<CustomSelect
							className={styles.customInput}
							placeholder={t("universityAccord.selectAccordType")}
							onChange={changeAccordType}
						>
							{/* <Option value="practice">{t('universityAccord.practice')}</Option>
                        <Option value="internship">{t('universityAccord.internship')}</Option>
                        <Option value="scholarship">{t('universityAccord.scholarship')}</Option> */}
							<Option value="practiceProtocol">
								{t("universityAccord.practiceProtocol")}
							</Option>
						</CustomSelect>
					</Col>
					{step >= 1 && accord == "practiceProtocol" ? (
						<Col xs={24} sm={24} md={24} lg={24} xl={8}>
							<CustomSelect
								className={styles.customInput}
								placeholder={t("universityAccord.selectFacultyType")}
								onChange={changeFaculty}
							>
								{facultyOptions}
							</CustomSelect>
						</Col>
					) : null}
					{step >= 2 ? (
						<Col xs={24} sm={24} md={24} lg={24} xl={4}>
							<CustomSelect
								className={styles.customInput}
								placeholder={t("universityAccord.selectAccordFormat")}
								onChange={changeAccordFormat}
							>
								<Option value="pdf">PDF</Option>
								<Option value="docx">DOCX</Option>
							</CustomSelect>
						</Col>
					) : null}
					{step >= 3 ? (
						<Col
							xs={24}
							sm={24}
							md={24}
							lg={24}
							xl={4}
							style={{
								display: "flex",
								alignItems: "flex-end",
								justifyContent:
									window.innerWidth <= 1100 ? "center" : "flex-start",
							}}
						>
							<CustomButton onClick={generateAccord}>
								{t("universityAccord.generateAccord") +
									" (" +
									accordFormat +
									")"}
							</CustomButton>
						</Col>
					) : null}
					<Col>
						<Spin spinning={generateLoading} />
					</Col>
				</Row>
			)}
			{!isCompany && !isAdministrativeAccordsResponsible && (
				<Row style={{ marginBottom: "1em" }}>
					<Group
						onChange={onChangeGroup}
						value={accordType}
						style={{ textAlign: "left" }}
					>
						<Radio value="toSign">{t("universityAccord.toSign")}</Radio>
						<Radio value="all">{t("universityAccord.all")}</Radio>
					</Group>
				</Row>
			)}
			{!isCompany && (
				<Row>
					<Search
						className={styles.searchBox}
						placeholder={t("universityAccord.search")}
						allowClear
						onSearch={setNamePattern}
						style={{ width: 320 }}
					/>
				</Row>
			)}
			<DocumentsTable />
			{/* <Row style={{ marginTop: "2em" }}>
            <Col span={window.innerWidth > 1100 ? 4 : 24}>
                {filters && <Tree
                    checkable
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    expandedKeys={expandedKeys}
                    onExpand={onExpand}
                    selectable={false}
                    treeData={filtersToDataNodes(translateFilters(filters))}
                />}
            </Col>
            <Col span={window.innerWidth > 1100 ? 20 : 24}>
                <Spin spinning={generateLoading}>
                    <List
                        itemLayout="vertical"
                        size="small"
                        pagination={{
                            total: universityAccords?.totalCount,
                            current: page,
                            pageSize: pageSize,
                            pageSizeOptions: ["10", "25", "50", "100"],
                            showSizeChanger: true,
                            locale: { items_per_page: t('configuration.pagination') },
                            position: 'both',
                            hideOnSinglePage: universityAccords?.totalCount ? universityAccords?.totalCount <= 10 : true,
                            onChange: handleListChange
                        }}
                        loading={isLoading}
                        dataSource={universityAccords?.data || undefined}
                        renderItem={(item) => (
                            <List.Item
                                key={item.id} className={styles.listItem}>
                                <AccordElement item={item}/>
                            </List.Item>
                        )}
                    />
                </Spin>
            </Col>
        </Row> */}
		</Layout>
	);
};

export default UniversityAccords;
