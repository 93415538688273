import Layout from "../../Containers/Layout";
import { NewsletterEmailInfoView } from "../Views/NewsletterEmailInfoView";

export const NewsletterEmailInfoPage = (props: { location: any }) => {
	return (
		<Layout>
			<NewsletterEmailInfoView />
		</Layout>
	);
};
