/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudentAppreciationDTO
 */
export interface StudentAppreciationDTO {
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciationDTO
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciationDTO
     */
    professorId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciationDTO
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciationDTO
     */
    message?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StudentAppreciationDTO
     */
    receiveDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciationDTO
     */
    studentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciationDTO
     */
    professorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentAppreciationDTO
     */
    professorImage?: string | null;
}

export function StudentAppreciationDTOFromJSON(json: any): StudentAppreciationDTO {
    return StudentAppreciationDTOFromJSONTyped(json, false);
}

export function StudentAppreciationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentAppreciationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'professorId': !exists(json, 'professorId') ? undefined : json['professorId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'receiveDate': !exists(json, 'receiveDate') ? undefined : (new Date(json['receiveDate'])),
        'studentName': !exists(json, 'studentName') ? undefined : json['studentName'],
        'professorName': !exists(json, 'professorName') ? undefined : json['professorName'],
        'professorImage': !exists(json, 'professorImage') ? undefined : json['professorImage'],
    };
}

export function StudentAppreciationDTOToJSON(value?: StudentAppreciationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'studentId': value.studentId,
        'professorId': value.professorId,
        'title': value.title,
        'message': value.message,
        'receiveDate': value.receiveDate === undefined ? undefined : (value.receiveDate.toISOString()),
        'studentName': value.studentName,
        'professorName': value.professorName,
        'professorImage': value.professorImage,
    };
}

