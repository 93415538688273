/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PracticeConvention,
    PracticeConventionFromJSON,
    PracticeConventionFromJSONTyped,
    PracticeConventionToJSON,
} from './PracticeConvention';
import {
    PracticeConventionSigningEnum,
    PracticeConventionSigningEnumFromJSON,
    PracticeConventionSigningEnumFromJSONTyped,
    PracticeConventionSigningEnumToJSON,
} from './PracticeConventionSigningEnum';

/**
 * 
 * @export
 * @interface PracticeConventionSignature
 */
export interface PracticeConventionSignature {
    /**
     * 
     * @type {string}
     * @memberof PracticeConventionSignature
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PracticeConventionSignature
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PracticeConventionSignature
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PracticeConventionSignature
     */
    practiceConventionId?: string;
    /**
     * 
     * @type {PracticeConventionSigningEnum}
     * @memberof PracticeConventionSignature
     */
    signer?: PracticeConventionSigningEnum;
    /**
     * 
     * @type {string}
     * @memberof PracticeConventionSignature
     */
    fileName?: string | null;
    /**
     * 
     * @type {PracticeConvention}
     * @memberof PracticeConventionSignature
     */
    practiceConvention?: PracticeConvention;
}

export function PracticeConventionSignatureFromJSON(json: any): PracticeConventionSignature {
    return PracticeConventionSignatureFromJSONTyped(json, false);
}

export function PracticeConventionSignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeConventionSignature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'practiceConventionId': !exists(json, 'practiceConventionId') ? undefined : json['practiceConventionId'],
        'signer': !exists(json, 'signer') ? undefined : PracticeConventionSigningEnumFromJSON(json['signer']),
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'practiceConvention': !exists(json, 'practiceConvention') ? undefined : PracticeConventionFromJSON(json['practiceConvention']),
    };
}

export function PracticeConventionSignatureToJSON(value?: PracticeConventionSignature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'practiceConventionId': value.practiceConventionId,
        'signer': PracticeConventionSigningEnumToJSON(value.signer),
        'fileName': value.fileName,
        'practiceConvention': PracticeConventionToJSON(value.practiceConvention),
    };
}

