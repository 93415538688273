/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhdReportDTO,
    PhdReportDTOFromJSON,
    PhdReportDTOFromJSONTyped,
    PhdReportDTOToJSON,
} from './PhdReportDTO';

/**
 * 
 * @export
 * @interface PhdReportDTOPagedResponse
 */
export interface PhdReportDTOPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof PhdReportDTOPagedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PhdReportDTOPagedResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PhdReportDTOPagedResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<PhdReportDTO>}
     * @memberof PhdReportDTOPagedResponse
     */
    data?: Array<PhdReportDTO> | null;
}

export function PhdReportDTOPagedResponseFromJSON(json: any): PhdReportDTOPagedResponse {
    return PhdReportDTOPagedResponseFromJSONTyped(json, false);
}

export function PhdReportDTOPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhdReportDTOPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(PhdReportDTOFromJSON)),
    };
}

export function PhdReportDTOPagedResponseToJSON(value?: PhdReportDTOPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'totalCount': value.totalCount,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(PhdReportDTOToJSON)),
    };
}

