/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyLoginResponseDTO,
    CompanyLoginResponseDTOFromJSON,
    CompanyLoginResponseDTOFromJSONTyped,
    CompanyLoginResponseDTOToJSON,
} from './CompanyLoginResponseDTO';
import {
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageFromJSONTyped,
    ErrorMessageToJSON,
} from './ErrorMessage';

/**
 * 
 * @export
 * @interface CompanyLoginResponseDTORequestResponse
 */
export interface CompanyLoginResponseDTORequestResponse {
    /**
     * 
     * @type {CompanyLoginResponseDTO}
     * @memberof CompanyLoginResponseDTORequestResponse
     */
    response?: CompanyLoginResponseDTO;
    /**
     * 
     * @type {ErrorMessage}
     * @memberof CompanyLoginResponseDTORequestResponse
     */
    errorMessage?: ErrorMessage;
}

export function CompanyLoginResponseDTORequestResponseFromJSON(json: any): CompanyLoginResponseDTORequestResponse {
    return CompanyLoginResponseDTORequestResponseFromJSONTyped(json, false);
}

export function CompanyLoginResponseDTORequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyLoginResponseDTORequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': !exists(json, 'response') ? undefined : CompanyLoginResponseDTOFromJSON(json['response']),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : ErrorMessageFromJSON(json['errorMessage']),
    };
}

export function CompanyLoginResponseDTORequestResponseToJSON(value?: CompanyLoginResponseDTORequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': CompanyLoginResponseDTOToJSON(value.response),
        'errorMessage': ErrorMessageToJSON(value.errorMessage),
    };
}

