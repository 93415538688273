/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddAnswerDTO,
    AddAnswerDTOFromJSON,
    AddAnswerDTOFromJSONTyped,
    AddAnswerDTOToJSON,
} from './AddAnswerDTO';
import {
    FormTypeEnum,
    FormTypeEnumFromJSON,
    FormTypeEnumFromJSONTyped,
    FormTypeEnumToJSON,
} from './FormTypeEnum';

/**
 * 
 * @export
 * @interface AddAnswersDTO
 */
export interface AddAnswersDTO {
    /**
     * 
     * @type {Array<AddAnswerDTO>}
     * @memberof AddAnswersDTO
     */
    answers: Array<AddAnswerDTO>;
    /**
     * 
     * @type {FormTypeEnum}
     * @memberof AddAnswersDTO
     */
    formType?: FormTypeEnum;
}

export function AddAnswersDTOFromJSON(json: any): AddAnswersDTO {
    return AddAnswersDTOFromJSONTyped(json, false);
}

export function AddAnswersDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAnswersDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answers': ((json['answers'] as Array<any>).map(AddAnswerDTOFromJSON)),
        'formType': !exists(json, 'formType') ? undefined : FormTypeEnumFromJSON(json['formType']),
    };
}

export function AddAnswersDTOToJSON(value?: AddAnswersDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answers': ((value.answers as Array<any>).map(AddAnswerDTOToJSON)),
        'formType': FormTypeEnumToJSON(value.formType),
    };
}

